import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import moment from 'moment';
import * as RM from 'ramda';


import PagesAuthentication from '../../helpers/PagesAuthentication';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';
import { uploadProfileLogo } from '../../helpers/AWSUpload';

import FieldInput from '../../components/FieldInput';
//import PasswordInput from '../../components/PasswordInput';

import { showHeader, showFooter, pageTitle, showLoader, reloadProfile, reloadAccountsDB, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';
import QueryUtilities from '../../helpers/QueryUtilities';
import { blockWordsAndEmailsValidation } from '../../helpers/VerificationUtilities';
import CommonProfileLogo from '../../components/common/CommonProfileLogo';
import MobileSelection from '../../components/MobileSelection';
import SessionUtilities from '../../helpers/SessionUtilities';
import { copyUserProfieLogo, setProfileTeamInvitation, updateTeamProfileAdmin, updateTeamProfileDetail, uploadTeamLogo } from '../../helpers/QueryTeamUtilities';


const FormTeam = ({ match }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    const TOKEN_PLUS = process.env.REACT_APP_chat_api_token_bizcom;
    const limitCharacters = Utilities.companyAboutWordsLimit;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    let globalAccountDetail = {};
    let defaultFormValues = {
        team_id: 0,
        team_first_name: '',
        team_last_name: '',
        team_email: '',
        //password : '',
        //password_confirm : '',

        team_department: '',
        team_position: '',

        team_department_assign: '',

        team_internal_chat_status: 0,
        team_is_listing: 0,
        team_external_availability: 0,
        team_is_admin: 0,

        team_contact_number_code_id: 0,
        team_contact_number: '',
        team_contact_number_status: 0,

        team_about: ''
    }
    //const defaultLocation = Utilities.defaultLocation();

    let globalcompanyID = 0;
    let globalCompanyAccount = {};

    const [formValues, setFormValues] = useState(defaultFormValues);

    const [formAction, setFormAction] = useState('add');
    const [domainName, setDomainName] = useState('');
    const [companyAccount, setCompanyAccount] = useState({});
    const [companyDepartments, setCompanyDepartments] = useState({});

    const [logoProfile, setLogoProfile] = useState('');
    const [profileLogoValue, setProfileLogoValue] = useState('');

    const [companyID, setCompanyID] = useState(0);
    const [personalDetail, setPersonalDetail] = useState({});
    const [profileDetail, setProfileDetail] = useState({});
    const [accountDetail, setAccountDetail] = useState({});
    const [allowDelete, setAllowDelete] = useState(true);
    const [showDepartmentList, setShowDepartmentList] = useState(false);
    const [isTeamAdmin, setIsTeamAdmin] = useState(false);

    const [departmentNames, setDepartmentNames] = useState('');
    const [selectedDepartmentsID, setSelectedDepartmentsID] = useState([]);

    const [departmentNamesOld, setDepartmentNamesOld] = useState('');
    const [selectedDepartmentsIDOld, setSelectedDepartmentsIDOld] = useState([]);


    const [showAboutSection, setShowAboutSection] = useState(false);
    const [aboutContentPrev, setAboutContentPrev] = useState('');

    const [countries, setCountries] = useState([]);

    const selectImage = () => {
        jQuery('input#input_select_file').trigger('click');
    }

    const previewImage = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            var reader = new FileReader();
            reader.onload = function (e) {

                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setLogoProfile(e.target.result);
                    //jQuery('.avatar-preview').css('background-image', 'url(' +  e.target.result + ')');
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setProfileLogoValue(e.target.files[0]);

        } else {
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();

        let value = e.target.value;
        let errorCounter = 0, formValuesTemp = formValues;

        if (fieldName === 'team_first_name') {
            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ team_first_name: value } };
        } else if (fieldName === 'team_last_name') {
            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ team_last_name: value } };
        } else if (fieldName === 'team_email') {
            formValuesTemp = { ...formValuesTemp, ...{ team_email: value } };
        } else if (fieldName === 'password') {
            formValuesTemp = { ...formValuesTemp, ...{ password: value } };

        } else if (fieldName === 'password_confirm') {
            formValuesTemp = { ...formValuesTemp, ...{ password_confirm: value } };

        } else if (fieldName === 'team_department') {
            formValuesTemp = { ...formValuesTemp, ...{ team_department: value } };
        } else if (fieldName === 'team_position') {
            formValuesTemp = { ...formValuesTemp, ...{ team_position: value } };

        } else if (fieldName === 'team_department_assign') {
            formValuesTemp = { ...formValuesTemp, ...{ team_department_assign: value } };

        } else if (fieldName === 'team_is_admin') {
            value = parseInt(formValuesTemp.team_is_admin) === 2 ? 0 : 2;
            formValuesTemp = { ...formValuesTemp, ...{ team_is_admin: value } };

        } else if (fieldName === 'team_contact_number') {

            let team_contact_number = value.replace(' ', '');
            if (!Utilities.isEmpty(team_contact_number) && !Utilities.contactNumberValidate(team_contact_number)) {
                //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(fieldName);
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ team_contact_number: parseInt(team_contact_number) || '' } };

        } else if (fieldName === 'team_contact_number_status') {
            value = parseInt(formValuesTemp.team_contact_number_status) === 1 ? 0 : 1;

            if (value === 1 && Utilities.isEmpty(formValues.team_contact_number || '')) {
                Utilities.messagePopup('Warning', TitlesLabels.alertMessages.mobileNumberShouldNotBeEmpty);
                //jQuery('[name="team_contact_number"]').closest('.form-group').addClass('error');
                value = 0;
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_status: value } };
        }

        if (errorCounter === 0) {
            //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
            Utilities.removeFormGroupClass(fieldName);
        }

        setFormValues(formValuesTemp);

        if (!Utilities.isEmpty(fieldName) && ['team_contact_number_status'] && value === 1) {
            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.mobileTeamActivationWarningMessage,
                overlayClassName: 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmContactNumber(fieldName, 0) },
                    { label: 'continue', onClick: () => confirmContactNumber(fieldName, 1) }
                ],
                onClickOutside: () => confirmContactNumber(fieldName, 0),
                onKeypressEscape: () => confirmContactNumber(fieldName, 0),
            });
        }

        if (!Utilities.isEmpty(fieldName) && ['team_is_admin'].includes(fieldName) && value === 2) {
            confirmAlert({
                title: '',
                message: TitlesLabels.confirmMessages.wishGrantManagementAccess,
                overlayClassName: 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmManaementAccess(0) },
                    { label: 'continue', onClick: () => confirmManaementAccess(2) }
                ],
                onClickOutside: () => confirmManaementAccess(0),
                onKeypressEscape: () => confirmManaementAccess(0),
            });
        }

    }

    const confirmContactNumber = (fieldName = '', value = 0) => {

        if (!Utilities.isEmpty(fieldName) && ['team_contact_number_status']) {
            let formValuesTemp = formValues;

            if (fieldName === 'team_contact_number_status') {
                formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_status: parseInt(value) } };

            }
            setFormValues(formValuesTemp);
        }
    }

    const confirmManaementAccess = (status = 0) => {
        setFormValues({ ...formValues, team_is_admin: status });
    }

    const pushValueVisiblity = (fieldName) => {

        if (!Utilities.isEmpty(fieldName)) {

            let formValuesTemp = formValues;
            if (fieldName === 'team_is_listing') {
                let valueTemp = (parseInt(formValuesTemp.team_is_listing) === 0) ? 1 : 0;
                formValuesTemp = { ...formValuesTemp, ...{ team_is_listing: valueTemp } };

            } else if (fieldName === 'team_internal_chat_status') {
                let valueTemp = (parseInt(formValuesTemp.team_internal_chat_status) === 0) ? 1 : 0;
                formValuesTemp = { ...formValuesTemp, ...{ team_internal_chat_status: valueTemp } };


            } else if (fieldName === 'team_external_availability') {
                let valueTemp = (parseInt(formValuesTemp.team_external_availability) === 0) ? 1 : 0;
                formValuesTemp = { ...formValuesTemp, ...{ team_external_availability: valueTemp } };

            }

            setFormValues(formValuesTemp);
        }

    }


    const submitTeam = async (e) => {
        e.preventDefault();

        const button_name = 'btn-submit-member';
        const loginAccountID = (PagesAuthentication.getUserLoggedinDetail().account_id ?? '').toString();

        Utilities.elementDisabledStatus(button_name, false);

        if (companyID === 0) { return false; }

        const formData = new FormData();
        formData.append('team_id', formValues.team_id);
        formData.append('company_id', companyID);
        formData.append('domain', domainName);


        let errorCounter = 0, responseChecker = false;
        const fieldsChecker = ['team_first_name', 'team_last_name', 'team_department', 'team_position', 'team_is_admin'];

        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {

                if (Utilities.isEmpty(value)) {
                    //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(key);
                    errorCounter++;

                } else {

                    if (key === 'team_email') {
                        if (Utilities.checkSpecialChar(formValues.team_email)) {
                            //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            Utilities.addFormGroupClass(key);
                            errorCounter++;
                        } else {
                            //jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            Utilities.removeFormGroupClass(key);
                            formData.append(key, value);
                        }

                    } else if (['team_first_name', 'team_last_name'].includes(key)) {
                        responseChecker = Utilities.inputCharacterChecker(value, 'general');
                        if (responseChecker === false) {
                            //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            Utilities.addFormGroupClass(key);
                            errorCounter++;
                        } else {
                            //jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            Utilities.removeFormGroupClass(key);
                            formData.append(key, value);
                        }
                    } else {
                        //jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                        Utilities.removeFormGroupClass(key);
                        formData.append(key, value);
                    }
                }
            }
        });

        if (!Utilities.isEmpty(formValues.password)) {

            /*  if ( formValues.password.length<=5){
                 Utilities.messagePopup('Error', Utilities.labelPasswordFiveCharacters );
                 return false;
             } */

            const passwordValidation = Utilities.passwordValidation(formValues.password);
            if (passwordValidation.response === false) {
                //jQuery('[name="password"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(`password`);
                return false;
            }

            if (formValues.password !== formValues.password_confirm) {
                Utilities.messagePopup('Error', Utilities.labelPasswordConfirmNotSame)
                return false;
            }

            formData.append('password', formValues.password);
        }

        let team_contact_number = formValues.team_contact_number || '';

        if (!Utilities.isEmpty(team_contact_number)) {
            if (!Utilities.contactNumberValidate(team_contact_number)) {
                //jQuery('[name="team_contact_number"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(`team_contact_number`);
                errorCounter++;

            } else {
                //jQuery('[name="team_contact_number"]').closest('.form-group').removeClass('error');
                Utilities.removeFormGroupClass(`team_contact_number`);
            }
        } else {
            if (parseInt(formValues.team_contact_number_status) === 1) {
                Utilities.messagePopup('Warning', TitlesLabels.alertMessages.mobileNumberShouldNotBeEmpty);
                errorCounter++;
            }
        }

        if (errorCounter > 0) { return false; }


        // RESTRICTED WORDS VALIDATION
        // TYPES : 1=>email, 2=>name, 3=>keywords
        let wordsRequestArray = [];
        wordsRequestArray.push(formValues.team_email);
        wordsRequestArray.push(formValues.team_first_name);
        wordsRequestArray.push(formValues.team_last_name);

        Utilities.elementDisabledStatus(button_name, true);
        const responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('2', wordsRequestArray);
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(responseRestrictedValidationsDB.status) === 0) {
            Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_nameEmail);
            return false;
        }

        // END RESTRICTED WORDS VALIDATION


        // UPLOAD LOGO
        let fileLogoUploadRes = {}, logoFileName = '', companyIDValue = (companyID ?? companyAccount.account_id).toString();
        const logo_value = document.getElementById('input_select_file').value;
        const team_account_id = parseInt(formValues.team_id ?? 0) === 0 ? personalDetail.account_id : formValues.team_id;

        if (!Utilities.isEmpty(logo_value) && !Utilities.isEmpty(profileLogoValue)) {

            const formLogoData = new FormData();
            formLogoData.append('api_token', TOKEN_SESSION.toString());
            formLogoData.append('master_account_id', loginAccountID.toString());
            formLogoData.append('company_account_id', companyIDValue);
            formLogoData.append('team_account_id', team_account_id.toString());
            formLogoData.append('file', profileLogoValue);

            Utilities.elementDisabledStatus(button_name, true);
            fileLogoUploadRes = await uploadTeamLogo(formLogoData);

            Utilities.elementDisabledStatus(button_name, false);

            if (Object.keys(fileLogoUploadRes).length > 0) {
                logoFileName = fileLogoUploadRes.result.team_image_result.file_name ?? '';
            }

        } else {

            // ONLY FOR ADDING
            if (parseInt(formValues.team_id ?? 0) === 0) {

                if (!Utilities.isEmpty(personalDetail.profile_logo ?? '')) {

                    Utilities.elementDisabledStatus(button_name, true);
                    fileLogoUploadRes = await copyUserProfieLogo({
                        api_token: TOKEN_SESSION.toString(),
                        company_account_id: companyIDValue,
                        master_account_id: personalDetail.account_id.toString(),
                    });
                    Utilities.elementDisabledStatus(button_name, false);


                    if (Object.keys(fileLogoUploadRes).length > 0) {
                        logoFileName = fileLogoUploadRes.result.team_image_result.file_name ?? '';
                    }

                } else {

                    Utilities.messagePopup('error', TitlesLabels.alertMessages.imageMandatory);
                    return false;
                }

            }

        }

        let passval = {}, resonseDB = {};

        // ADD + SEND INVITATION
        if (parseInt(formValues.team_id ?? 0) === 0) {
            passval = {
                api_token: TOKEN_SESSION.toString(),
                account_id: loginAccountID,
                company_id: companyIDValue,
                profile_account_id: personalDetail.account_id.toString(),
                first_name: formValues.team_first_name.toString(),
                last_name: formValues.team_last_name.toString(),
                profile_logo: logoFileName.toString(),
                division: formValues.team_department.toString(),
                position: formValues.team_position.toString(),
                contact_number_code_id: (formValues.team_contact_number_code_id || '').toString(),
                contact_number: (formValues.team_contact_number || '').toString(),
                contact_number_status: (formValues.team_contact_number_status || '0').toString(),
                profile_info: formValues.team_about ?? '',
                department_list: selectedDepartmentsID.toString(),                       // department id pass with comma
                internal_status: formValues.team_internal_chat_status.toString(),
                external_status: formValues.team_is_listing.toString(),
                work_account_status: formValues.team_external_availability.toString(),
                management_status: formValues.team_is_admin.toString()
            }


            Utilities.elementDisabledStatus(button_name, true);
            resonseDB = await setProfileTeamInvitation(passval);
            Utilities.elementDisabledStatus(button_name, false);

            if (Object.keys(resonseDB).length > 0) {
                if (parseInt(resonseDB.success ?? 0) === 1) {
                    Utilities.messagePopup('success', TitlesLabels.alertMessages.invitationSuccessfullySent);
                    history.push(`/${UrlSlugs.settings_manageTeam}/${btoa(accountDetail.account_id)}`);
                } else {
                    Utilities.messagePopup('error', errorMsg);
                }
            } else {
                Utilities.messagePopup('error', errorMsg);
            }


            // ADMIN or TEAM MEMBER
        } else {



            if (isTeamAdmin === true) {
                passval = {
                    api_token: TOKEN_SESSION.toString(),
                    account_id: accountDetail.account_id.toString(), // team_account_id.toString(), //formValues.team_id.toString(), //loginAccountID,
                    company_id: companyIDValue,
                    first_name: formValues.team_first_name.toString(),
                    last_name: formValues.team_last_name.toString(),
                    division: formValues.team_department.toString(),
                    position: formValues.team_position.toString(),
                    contact_number_code_id: (formValues.team_contact_number_code_id || '').toString(),
                    contact_number: (formValues.team_contact_number || '').toString(),
                    contact_number_status: (formValues.team_contact_number_status || '0').toString(),
                    profile_info: formValues.team_about ?? '',
                    department_list: selectedDepartmentsID.toString(),
                    internal_status: formValues.team_internal_chat_status.toString(),
                    external_status: formValues.team_is_listing.toString(),
                    work_account_status: formValues.team_external_availability.toString()
                }

                Utilities.elementDisabledStatus(button_name, true);
                resonseDB = await updateTeamProfileAdmin(passval);
                Utilities.elementDisabledStatus(button_name, false);


            } else {

                passval = {
                    api_token: TOKEN_SESSION.toString(),
                    account_id: loginAccountID.toString(),
                    company_id: companyIDValue,
                    team_account_id: team_account_id.toString(),
                    first_name: formValues.team_first_name.toString(),
                    last_name: formValues.team_last_name.toString(),
                    division: formValues.team_department.toString(),
                    position: formValues.team_position.toString(),
                    contact_number_code_id: (formValues.team_contact_number_code_id || '').toString(),
                    contact_number: (formValues.team_contact_number || '').toString(),
                    contact_number_status: (formValues.team_contact_number_status || '0').toString(),
                    profile_info: formValues.team_about ?? '',
                    department_list: selectedDepartmentsID.toString(),
                    internal_status: formValues.team_internal_chat_status.toString(),
                    external_status: formValues.team_is_listing.toString(),
                    work_account_status: formValues.team_external_availability.toString(),
                    management_status: formValues.team_is_admin.toString()
                }

                Utilities.elementDisabledStatus(button_name, true);
                resonseDB = await updateTeamProfileDetail(passval);
                Utilities.elementDisabledStatus(button_name, false);
            }

            if (Object.keys(resonseDB).length > 0) {
                if (parseInt(resonseDB.success ?? 0) === 1) {
                    Utilities.messagePopup('success', TitlesLabels.alertMessages.successfullyUpdated);

                    if (isTeamAdmin === true) {
                        dispatch(reloadProfile(Date.now()));
                        dispatch(reloadAccountsDB(2));
                    }
                } else {
                    Utilities.messagePopup('error', errorMsg);
                }
            } else {
                Utilities.messagePopup('error', errorMsg);
            }


        }


    }


    const confirmDeletion = () => {
        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wishDeleteTeamMember,
            buttons: [
                { label: 'No', onClick: () => { } },
                { label: 'Yes', onClick: () => { deleteTeam() } }
            ]
        });
    }

    const deleteTeam = async () => {
        const id = formValues.team_id;
        const TOKEN_SESSION = SessionUtilities.getSessionToken();

        if (parseInt(id) > 0) {

            const dataValues = {
                api_token: TOKEN_SESSION.toString(),    //TOKEN_PLUS.toString(),
                account_id: accountDetail.account_id.toString(),
                company_id: profileDetail.team_company_id.toString(),
                team_account_id: id.toString()
            }

            //console.log('deleteTeam');
            //console.log(ApiUrls.teamProfileRemove, dataValues);

            dispatch(showLoader(1));

            await axios
                .post(ApiUrls.teamProfileRemove, dataValues)
                .then(response => {
                    dispatch(showLoader(0));

                    if (response.status === 201) {
                        const dataResponse = response.data;
                        if (parseInt(dataResponse.success) === 1) {
                            history.push(`/${UrlSlugs.settings_manageTeam}/${btoa(accountDetail.account_id)}`);

                        } else {
                            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotDeleteMember);
                        }
                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }
                })
                .catch((err) => {
                    Utilities.messagePopup('error', errorMsg);
                    dispatch(showLoader(0));
                });
        }
    }


    const cancelSelection = () => {
        setShowDepartmentList(false);


        setDepartmentNames(departmentNamesOld);
        setSelectedDepartmentsID(selectedDepartmentsIDOld);
    }

    const toggleActiveClass = (e, department) => {
        let departmentIDsTemp = [...selectedDepartmentsID];
        let id = department.group_id.toString();

        !(departmentIDsTemp.includes(id)) ? departmentIDsTemp.push(id) : (departmentIDsTemp = [...Utilities.removeValueInArray(departmentIDsTemp, id)]);
        departmentIDsTemp = Utilities.arrayUnique(departmentIDsTemp);


        setSelectedDepartmentsID(departmentIDsTemp);

        let department_names = [];
        Object.entries(companyDepartments).map(([key, row]) => {
            if (departmentIDsTemp.includes(row.group_id.toString())) {
                department_names.push(row.department_name.toString());
            }
        });

        const selectedCounter = parseInt(department_names.length);
        department_names = department_names.join(',');

        department_names = (department_names.length > 30) ? department_names.substring(0, 20) + '...' : department_names;

        if (selectedCounter > 2) {
            department_names = department_names + ' +' + (selectedCounter - 2);
        }

        setDepartmentNames(department_names);

    }

    const submitTeamAbout = () => {
        const charCounter = Utilities.wordsCounter(formValues.team_about);
        if (parseInt(charCounter) > limitCharacters) {
            Utilities.messagePopup('error', `Only ${limitCharacters} words are allowed!`);
            return false;
        }
        setShowAboutSection(false);
    }

    const getTeamDetail = (id, action = 'add') => {

        if (parseInt(id) > 0 && parseInt(globalcompanyID) > 0) {
            setCompanyID(globalcompanyID);

            axios
                .post(ApiUrls.departmentListByAccountID, { id: globalcompanyID })
                .then(response => {
                    if (response.status === 200) {
                        const dataResponse = response.data;

                        if (parseInt(dataResponse.status) === 1) {
                            let departmentsDB = dataResponse.departments;

                            if (departmentsDB.length > 0) {
                                if ('department_name' in departmentsDB[0]) {
                                    //const byName = RM.ascend(RM.prop('company_name')); key senstive
                                    const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('department_name')));
                                    departmentsDB = RM.sort(byName, departmentsDB);
                                }
                            }

                            setCompanyDepartments(departmentsDB);

                            axios
                                .post(ApiUrls.teamDetail, { id: id, company_id: globalcompanyID, show_departments: 1 })
                                .then(response => {

                                    if (response.status === 200) {
                                        const dataResponse = response.data;
                                        if (parseInt(dataResponse.status) === 1) {

                                            const dataDetail = dataResponse.detail;
                                            const departments_assigned = dataResponse.departments;

                                            let formValuesTemp = formValues,
                                                team_contact_number = dataDetail.hasOwnProperty('team_contact_number') ? parseInt(dataDetail.team_contact_number || '') : '',
                                                team_contact_number_code_id = dataDetail.hasOwnProperty('team_contact_number_code_id') ? parseInt(dataDetail.team_contact_number_code_id || 0) : 0;

                                            team_contact_number_code_id = team_contact_number_code_id || (globalCompanyAccount.country_id || 0);

                                            if (action === 'edit') {
                                                formValuesTemp = {
                                                    ...formValues,
                                                    team_id: dataDetail.account_id,
                                                    team_first_name: dataDetail.team_first_name,
                                                    team_last_name: dataDetail.team_last_name,
                                                    team_department: (typeof dataDetail.team_department === 'undefined') ? '' : dataDetail.team_department,
                                                    team_position: dataDetail.team_position,
                                                    team_email: dataDetail.team_email.toString().split('@')[0],
                                                    team_internal_chat_status: parseInt(dataDetail.team_internal_chat_status || 0),
                                                    team_is_listing: parseInt(dataDetail.team_is_listing || 0),
                                                    team_external_availability: parseInt(dataDetail.team_external_availability || 0),
                                                    team_is_admin: dataDetail.hasOwnProperty('team_is_admin') ? parseInt(dataDetail.team_is_admin || 0) : 0,
                                                    team_about: dataDetail.team_about ?? '',

                                                    team_contact_number_code_id: team_contact_number_code_id,
                                                    team_contact_number: team_contact_number,
                                                    team_contact_number_status: dataDetail.hasOwnProperty('team_contact_number_status') ? parseInt(dataDetail.team_contact_number_status || 0) : 0,
                                                }



                                                /* let team_external_availability = dataDetail.hasOwnProperty('team_external_availability') ? parseInt(dataDetail.team_external_availability || 0) : 0;
                                                if ( parseInt(dataDetail.team_is_admin || 0)===1 ){
                                                    team_external_availability = 0;
                                                }
                                                formValuesTemp = { ...formValuesTemp, team_external_availability : team_external_availability }; */

                                                const selectedCounter = Object.keys(departments_assigned).length;

                                                if (selectedCounter > 0) {
                                                    setSelectedDepartmentsID(Object.keys(departments_assigned));
                                                    setSelectedDepartmentsIDOld(Object.keys(departments_assigned));

                                                    let department_names = [];
                                                    Object.entries(departments_assigned).map(([key, row]) => {
                                                        department_names.push(row.department_name.toString());
                                                    });

                                                    department_names = department_names.join(',');
                                                    department_names = (department_names.length > 30) ? department_names.substring(0, 20) + '...' : department_names;

                                                    if (selectedCounter > 2) {
                                                        department_names = department_names + ' +' + (selectedCounter - 2);
                                                    }

                                                    setDepartmentNames(department_names);
                                                    setDepartmentNamesOld(department_names);
                                                }

                                                let allowDeletion = parseInt(dataDetail.team_is_admin || 0) === 1 ? false : true;
                                                if (parseInt(globalAccountDetail.account_id) === parseInt(dataDetail.account_id)) {
                                                    allowDeletion = false;
                                                }
                                                setAllowDelete(allowDeletion);

                                                let teamAdmin = parseInt(dataDetail.team_is_admin || 0) === 1 ? true : false;
                                                if (parseInt(globalAccountDetail.team_is_admin || 0) === 2) {
                                                    teamAdmin = true;
                                                }
                                                setIsTeamAdmin(teamAdmin);

                                                getCountries(team_contact_number_code_id);

                                            } else {

                                                formValuesTemp = {
                                                    ...formValues,
                                                    team_id: 0,
                                                    team_first_name: dataDetail.first_name,
                                                    team_last_name: dataDetail.last_name,
                                                    team_department: (typeof dataDetail.team_department === 'undefined') ? '' : dataDetail.team_department,
                                                    team_is_admin: 0,
                                                    team_about: dataDetail.team_about ?? '',

                                                    team_contact_number_code_id: team_contact_number_code_id,
                                                    team_contact_number: '',
                                                    team_contact_number_status: 0,

                                                }

                                                setPersonalDetail(dataDetail);

                                                getCountries(team_contact_number_code_id);
                                            }


                                            setFormValues(formValuesTemp);
                                            setProfileDetail(dataDetail);

                                            let profileDetail = Utilities.formatProfileDetail(dataDetail);
                                            let profileLogo = DEFAUL_AVATAR;

                                            if (action === 'edit') {
                                                if (!Utilities.isEmpty(profileDetail.logo_path ?? '')) {
                                                    profileLogo = profileDetail.logo_url;
                                                }
                                            } else {
                                                if (!Utilities.isEmpty(profileDetail.profile_logo ?? '')) {
                                                    profileLogo = profileDetail.logo_url;
                                                }
                                            }


                                            /* console.log(profileDetail);
                                            console.log(profileLogo) */

                                            setLogoProfile(profileLogo);

                                        }
                                    }
                                })
                                .catch((err) => {

                                });
                        }
                    }
                })
                .catch((err) => {

                });

        }
    }

    const setUpAboutContent = () => {
        setAboutContentPrev(formValues.team_about);
        setShowAboutSection(true);
        setAllowDelete(false);

        setTimeout(() => {
            jQuery('textarea#team_about').focus();
        }, 200);
    }

    const cancelAboutContent = () => {
        setFormValues({ ...formValues, team_about: aboutContentPrev });
        setShowAboutSection(false);

        if (formValues.team_is_admin === 0) {
            setAllowDelete(true);
        }
    }

    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }



    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const getCountries = async (providedMobileCode = 0) => {
        providedMobileCode = parseInt(providedMobileCode);

        const countriesResponse = await QueryUtilities.countryList();
        if (countriesResponse.length > 0) {

            let optionList = [], formValuesTemp = formValues;
            Object.entries(countriesResponse).map(([key, row]) => {
                optionList = [...optionList, { value: row.id, label: row.country_name }];

                if (providedMobileCode > 0) {

                    if (parseInt(providedMobileCode) === parseInt(row.id)) {
                        /* formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_code_id : parseInt(row.id) } };
                        setFormValues(formValuesTemp); */

                    }
                }

            });

            setCountries(countriesResponse);
        }

    }


    const changeCountryCodeMobile = (passValues, fieldName = '') => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_code_id: parseInt(passValues.id) } };
        setFormValues(formValuesTemp);
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    useEffect(() => {

        PagesAuthentication.getUserLoggedin();

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.teamMember}`));

        dispatch(showHeader(1));
        dispatch(showFooter(0));

        const pathname = window.location.pathname;
        const segment = pathname.split('/');
        const actionName = segment[segment.length - 3];


        let reqAccountID = match.params.mid;
        if (typeof reqAccountID !== 'undefined') {
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            if (reqAccountID > 0) {

                // ASSOCIATED ACCOUNTS
                let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                if (accountsList !== null) {
                    accountsList = JSON.parse(accountsList);

                    if (Object.keys(accountsList).length > 0) {
                        Object.entries(accountsList).map(([acctid, acctdetail]) => {

                            const account_id = parseInt(acctdetail.account_id);
                            if (account_id === reqAccountID) {

                                let accountProfile = Utilities.formatProfileDetail(acctdetail);
                                let team_external_availability = accountProfile.hasOwnProperty('team_external_availability') ? parseInt(accountProfile.team_external_availability || 0) : 0;
                                if (parseInt(accountProfile.team_is_admin) === 1) {
                                    team_external_availability = 0;
                                }

                                accountProfile = { ...accountProfile, team_external_availability: team_external_availability.toString() };
                                setAccountDetail(accountProfile);
                                globalAccountDetail = accountProfile;

                                let profileCompanyTemp = Utilities.workToCompany(accountProfile);
                                setCompanyAccount(profileCompanyTemp);
                                globalCompanyAccount = profileCompanyTemp;

                                setCompanyID(profileCompanyTemp.account_id);
                                globalcompanyID = profileCompanyTemp.account_id;

                                const companyDomainRequest = Utilities.isEmpty(profileCompanyTemp.company_display_name) ? profileCompanyTemp.company_name : profileCompanyTemp.company_display_name;
                                let domainNameTemp = Utilities.removeSpecialChar(companyDomainRequest);
                                domainNameTemp = domainNameTemp.toString().toLowerCase();

                                setDomainName(domainNameTemp);

                            }
                        });

                    }

                }
            }
        }

        let reqID = match.params.id;
        if (typeof reqID !== 'undefined') {
            reqID = atob(reqID);

            if (parseInt(reqID) > 0 && actionName === 'edit') {
                setFormAction('edit');
            }

            getTeamDetail(reqID, actionName);

        }

    }, []);

    return (
        <div className="team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => triggerSetting()}>{TitlesLabels.settings}</div>
                            <div>{TitlesLabels.general.profiles}</div>
                            <div>{companyAccount.hasOwnProperty('name') ? companyAccount.name : ''}</div>
                            <Link to={`/${UrlSlugs.settings_manageTeam}/${btoa(accountDetail.account_id)}`}>{TitlesLabels.navigations.teamMembers}</Link>
                            <div className="current">{formAction} {TitlesLabels.navigations.team}</div>
                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            {(formAction === 'edit' && allowDelete) && <div className="btn-icon btn-trash" onClick={() => confirmDeletion()}></div>}
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()}></div>

                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content">
                <div className="container pt-5">

                    {
                        showAboutSection === true ?
                            <div className="col-md-10 mx-auto pt-1 form-theme text-lowercase">
                                <div className="form-group">
                                    <textarea id="team_about" name="team_about" className="form-control" rows="11" placeholder=" " value={formValues.team_about} onChange={(e) => setFormValues({ ...formValues, ...{ team_about: e.target.value } })}></textarea>
                                    <label className="form-label">about</label>
                                </div>

                                <div className="d-flex align-items-center justify-content-center text-center text-color-gray fs-13-important">
                                    <div>
                                        <div>maximum {Utilities.number_format(limitCharacters.toString())} words</div>
                                        <div className="text-color-blue cursor-pointer" onClick={() => setFormValues({ ...formValues, team_about: '' })}>clear</div>
                                    </div>
                                </div>


                                <div className="mt-5 button-group-boolean  col-md-5 mx-auto ">
                                    <button type="button" className="btn-theme-black" onClick={() => cancelAboutContent()}>cancel</button>
                                    <button type="button" className="btn-theme-black" onClick={() => submitTeamAbout()}>submit</button>
                                </div>
                            </div>
                            :
                            <form id="form-work-detail" className={`col-md-10 mx-auto pt-1 form-theme text-lowercase mt-n3 ${(showDepartmentList ? 'd-none' : '')}`} autoComplete="off">

                                <input type="file" id="input_select_file" name="file" className="invisible" accept={Utilities.attributeImageAcceptExtension} readOnly onChange={(e) => previewImage(e)} ></input>

                                <div className={`form-team-wrapper`}>

                                    <div className="avatar-wrap avatar-md-wrap">
                                        <div className={`avatar-preview  border logo-size-200 logo-size-sm-200 mx-auto `} style={{ backgroundImage: `url(${logoProfile})` }} ></div>

                                        <div className='profile-logo-change text-center'>
                                            <button type="button" className="btn-select-file bg-theme-green font-gotham-bold border-radius text-white outline-none border-0 fs-16 mx-auto mt-3 py-2 px-4" onClick={() => selectImage()}>change</button>
                                            <div className="fs-13-important text-center pt-3 color-theme-grey">{Utilities.labelUploadSpecs}</div>
                                        </div>

                                    </div>
                                    <div className="pt-4"></div>

                                    <div className='row'>

                                        <div className='col-md-6'>
                                            <FieldInput inputName="team_first_name" inputLabel="First Name" inputValue={formValues.team_first_name} pushValue={pushValue} checkerType="general" />
                                        </div>
                                        <div className='col-md-6'>
                                            <FieldInput inputName="team_last_name" inputLabel="Last Name" inputValue={formValues.team_last_name} pushValue={pushValue} checkerType="general" />
                                        </div>


                                        <div className='col-md-6'>

                                            <div className="form-group">
                                                <input type="text" name="team_department" className="form-control" placeholder=" " autoComplete="off" value={formValues.team_department} onChange={(e) => pushValue(e, 'team_department')} ></input>
                                                <label className="form-label">Company Division</label>
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className="form-group ">
                                                <input type="text" name="team_position" className="form-control" placeholder=" " autoComplete="off" value={formValues.team_position} onChange={(e) => pushValue(e, 'team_position')} ></input>
                                                <label className="form-label">Company Position</label>
                                            </div>
                                        </div>


                                        <div className='col-md-6'>
                                            <MobileSelection inputValue={formValues.team_contact_number} countryListProvided={countries} inputLabel="Work mobile number" countryCodeProvided={formValues.team_contact_number_code_id} inputListingId="mobile-input-list" inputName="team_contact_number" pushValue={pushValue} placeholderExtra={`(optional)`} changeCountryCode={changeCountryCodeMobile} extraClasses={`has-off-on`}>
                                                <input type="checkbox" className="checkbox-onoff" value={formValues.team_contact_number_status} checked={formValues.team_contact_number_status === 1} onChange={(e) => pushValue(e, 'team_contact_number_status')} ></input>
                                            </MobileSelection>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className="form-group optional" onClick={() => setShowDepartmentList(true)}>
                                                <input type="text" name="team_department_assign" className="form-control cursor-pointer" disabled placeholder=" " value={departmentNames} onChange={(e) => pushValue(e, 'team_department_assign')} autoComplete="off" ></input>
                                                <label className="form-label">Assigned Chat</label>
                                                <div className="optional-label cursor-pointer">Add/Delete</div>
                                            </div>
                                        </div>

                                        <div className='col-md-6'>

                                            <div className="form-group optional active">
                                                <div className="form-control" onClick={() => setUpAboutContent()}>view</div>
                                                <label className="form-label">profile info</label>
                                                <div className="optional-label">Optional</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-group active pb-3 ">
                                        <div className="form-control">
                                            <div className="d-flex mt-2 checkbox-radio-default align-items-center">
                                                <div className="align-items-center d-flex"><input type="checkbox" selected className='border-0' name="listing" value={formValues.team_internal_chat_status} onChange={() => pushValueVisiblity('team_internal_chat_status')} checked={formValues.team_internal_chat_status === 1} ></input><span className="ml-2">Internal</span></div>
                                                <div className="ml-4 align-items-center d-flex"><input type="checkbox" className='border-0' name="external" value={formValues.team_is_listing} onChange={() => pushValueVisiblity('team_is_listing')} checked={formValues.team_is_listing === 1} ></input><span className="ml-2">External</span></div>

                                                {
                                                    [1, 2].includes(parseInt(accountDetail.team_is_admin)) &&
                                                    <div className="ml-4 align-items-center d-flex"><input type="checkbox" name="external" className='border-0' value={formValues.team_external_availability} onChange={() => pushValueVisiblity('team_external_availability')} checked={formValues.team_external_availability === 1} ></input><span className="ml-2">Disable work chat</span></div>
                                                }

                                            </div>
                                        </div>
                                        <label className="form-label">Allow Visibility</label>
                                    </div>

                                    {
                                        (parseInt(accountDetail.team_is_admin) === 1 && parseInt(accountDetail.account_id) !== parseInt(formValues.team_id)) &&
                                        <div className="form-group  has-off-on border-0">
                                            <div className="form-control text-blue-placehoder">management access</div>
                                            <input type="checkbox" className="checkbox-onoff" name="team_is_admin" value={formValues.team_is_admin} checked={formValues.team_is_admin === 2} onChange={(e) => pushValue(e, 'team_is_admin')}></input>
                                        </div>
                                    }


                                </div>

                                <div className="button-wrap text-lowercase text-center col-md-5 mx-auto my-5 pt-4">


                                    {
                                        formAction === 'edit' ?
                                            <button type="button" id="btn-submit-member" className="btn-theme-black text-lowercase button-has-loader" onClick={(e) => submitTeam(e)}>Update</button>
                                            :
                                            <button type="button" id="btn-submit-member" className="btn-theme-black text-lowercase button-has-loader" onClick={(e) => submitTeam(e)}>Submit</button>
                                    }


                                </div>

                            </form>

                    }


                    {
                        showDepartmentList === true &&
                        <div className="department-list-wrap">
                            <ul id="listing-departments" className="global-listing-profiles with-checked-selected mt-4">
                                {
                                    Object.entries(companyDepartments).map(([key, row]) =>
                                        <li key={key} data-keyword={row.department_name} className={`cursor-pointer ${((selectedDepartmentsID.includes(row.group_id.toString())) ? 'selected' : '')}`} onClick={(e) => toggleActiveClass(e, row)} >
                                            <CommonProfileLogo classAttr={`profile-image logo-size-100 logo-size-md-85 mx-auto`} profileDetail={{ ...companyAccount, name: companyAccount.company_name }} />
                                            <div className="profile-name  notranslate font-gotham-bold mt-2 fs-18 color-theme-dark">{row.department_name}</div>
                                        </li>
                                    )
                                }
                            </ul>

                            <div className="col-md-6 mx-auto  pt-5 mt-5">

                                <div className="button-group-boolean">
                                    <button type="button" className="btn-theme-black text-lowercase" onClick={() => cancelSelection()} >Cancel</button>
                                    <button type="button" className="btn-theme-black text-lowercase button-has-loader" onClick={() => setShowDepartmentList(false)} >Save</button>
                                </div>

                            </div>

                        </div>
                    }

                </div>
            </div>
        </div>

    )
}

export default FormTeam;