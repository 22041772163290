import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TitlesLabels from '../../helpers/TitlesLabels';
import PagesAuthentication from '../../helpers/PagesAuthentication';

import { updateCompanyFollowStatus } from '../../helpers/FollowersNotices';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';
import UrlSlugs from '../../helpers/UrlSlugs';
import Utilities from '../../helpers/Utilities';
import SessionUtilities from '../../helpers/SessionUtilities';


const FollowersNotices = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const [userLoginDetail, setUserLoginDetail] = useState({});

    const handleClickUpdateCompanyFollowStatus = async () => {
        const follow_status = 1;

        const passval = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: userLoginDetail.account_id.toString(),
            account_type: userLoginDetail.account_type.toString(),
            company_id: userLoginDetail.company_detail.account_id.toString(),
            //flag: follow_status.toString()
        };

        const button_name = `btn-submit-activation`;
        Utilities.elementDisabledStatus(button_name, true);
        const responseDb = await updateCompanyFollowStatus(passval);
        Utilities.elementDisabledStatus(button_name, false);

        if (Object.keys(responseDb).length === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }

        if (parseInt(responseDb.success) === 1) {
            Utilities.messagePopup('success', responseDb.successMessage);

            let userLoginDetailTemp = {
                ...userLoginDetail,
                company_detail: { ...userLoginDetail.company_detail, follow_status: follow_status.toString() }
            };

            PagesAuthentication.setLoginAccountDetail(userLoginDetailTemp);
            SessionUtilities.updateCompanyDetail(userLoginDetailTemp.company_detail);

            history.push(`/${UrlSlugs.settings_notificationManagement}`);

        } else {
            Utilities.messagePopup('error', responseDb.errorMessage);
        }

    }

    useEffect(() => {

        PagesAuthentication.getUserLoggedin();

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        //console.log(userLoginDetailTemp);
        if (parseInt(userLoginDetailTemp.account_type) !== 4) {
            history.push(`/${UrlSlugs.dashboard}`);
            return false;

        } else {

            // REDIRECT to notification list
            if (userLoginDetailTemp.hasOwnProperty('company_detail')) {
                if (parseInt(userLoginDetailTemp.company_detail.follow_status ?? 0) >= 1) {
                    history.push(`/${UrlSlugs.settings_notificationManagement}`);
                    return false;
                }
            }
        }

        setUserLoginDetail(userLoginDetailTemp);

        dispatch(pageTitle(`${TitlesLabels.navigations.followersNotices}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

    }, []);

    return (
        <div className="important-information-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className="current">{TitlesLabels.navigations.followersNotices}</div>

                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>

            </div>


            <div className="main-content">
                <div className="container">

                    <div className="col-md-6 mx-auto px-0">
                        <div className="py-5">
                            <p className="line-height-1-5">your followers and notification module is currently not active. by activating, the follow button on your company public profile will appear which allow clients to follow your company. it will allow you to send them notifications and any advertising material. please be aware that this is the only place where you may advertise and only to those following your company. we advise you to use this sparingly and sensibly and not spam your followers. please remember that they can “unfollow” at a click and you will have no further way of reaching them</p>
                        </div>

                        <div className="mt-5 pt-5">
                            <button id="btn-submit-activation" type="button" className="btn-theme-black button-has-loader" onClick={() => handleClickUpdateCompanyFollowStatus()}>activate</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default FollowersNotices;