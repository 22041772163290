import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import {   useParams } from 'react-router-dom';

import TitlesLabels from "../../helpers/TitlesLabels";
import { pageTitle  } from "../../actions";

import DailyGlanceEventsFeaturedLayout from "./DailyGlanceEventsFeaturedLayout";
import { getListFeatured } from "../../helpers/QueryDailyGlanceFeatured";
import {   DailyGlanceFeaturedItemTemplate, DailyGlanceFeaturedListLoader } from "./DailyGlanceArticleWidgets";
import NoRecordAvailable from "../../components/common/NoRecordAvailable";
import { handleClickAdverstisement } from "../../helpers/UtilitiesDailyGlance";


const DailyGlanceFeatured = () => {

    const dispatch = useDispatch();
    const { dateYYmmdd } = useParams();

    let result_Db = '';

    const [ showListLoader , setShowListLoader ] = useState(true);
    const [ articleList , setArticleList ] = useState([]);
    const [ dateSelected , setDateSelected ] = useState('');

    const getingArtcileList = async () => {

        setShowListLoader(true);
        result_Db = await getListFeatured({ view_all : 1});
        setShowListLoader(false);

        if ( parseInt(result_Db.status??0)===1 ){
            
            if ( parseInt(result_Db.count??0)>0 ){
                setArticleList(result_Db.list??[]);
            }
        }
    }


    useEffect( async () => {
        
        dispatch( pageTitle(`${TitlesLabels.general.featuredArticles}`) );
        getingArtcileList();
    
        try {
            let dateYYmmddParam = atob(dateYYmmdd);
            setDateSelected(dateYYmmddParam);

        } catch(e) {
      
        }

    }, [] );



    return (
        <DailyGlanceEventsFeaturedLayout  menuTitle={TitlesLabels.general.featuredArticles}  typeName="featured">
            <div className="col-lg-10 mx-auto">
            
            {
                showListLoader ?
                    <DailyGlanceFeaturedListLoader />
                :
                <>
                    {
                        Object.keys(articleList).length===0 ?
                            <NoRecordAvailable />
                        :
                            <div className="list-article-height-auto">
                                <ul   className="ul-global-list ul-dglance-list cursor-pointer text-lowercase font-gotham-book fs-14 fs-md-14">
                                {
                                    Object.entries(articleList).map( ([key, row] ) =>
                                        <DailyGlanceFeaturedItemTemplate detail={row} displayType={`list`} key={key} dateSelected={dateSelected} />
                                    )
                                }
                                </ul>
                            </div>
                    }
                </>
            }

            {
                Object.keys(articleList).length>0 &&
                <div className="ads-image mt-3"  style={{ backgroundImage: `url('${articleList[0].ads_image??''}')`}} onClick={ () => handleClickAdverstisement( articleList[0],1, dispatch ) }></div>
            }
            
            </div>
        </DailyGlanceEventsFeaturedLayout>
            
    )
}

export default DailyGlanceFeatured;