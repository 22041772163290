import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { pageTitle, showFooter, showHeader, showMainSettings } from '../../../actions';
import TitlesLabels from '../../../helpers/TitlesLabels';

import Utilities from '../../../helpers/Utilities';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import SidebarDepartmentProfileSelection from './SidebarDepartmentProfileSelection';
import { submitSendMessage } from '../../../helpers/QueryDepartmentBulkMessage';
import SessionUtilities from '../../../helpers/SessionUtilities';


const DeparmentBulkMessageForm = () => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;


    let defaultFormValues = {
        message: ''
    }

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [userLoginDetail, setUserLoginDetail] = useState({});
    const [showProfileSelection, setShowProfileSelection] = useState(false);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'message') {
            formValuesTemp = { ...formValuesTemp, ...{ message: value } };
        }

        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);
    }


    const handleClearInput = () => {
        setFormValues(defaultFormValues);
    }


    const validateForm = async () => {

        let errorCounter = 0;
        setShowProfileSelection(false);

        Object.entries(formValues).map(([key, value]) => {
            if (['message'].includes(key)) {
                if (Utilities.isEmpty(value)) {
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            }
        });

        if (errorCounter > 0) { return false; }

        setShowProfileSelection(true);

    }


    const handleSelectedMembers = async (membersArr = []) => {

        if (membersArr.length === 0) {
            setShowProfileSelection(false);

        } else {

            const button_name = `btn-bulk-send`;
            const accountConversIdArr = [];


            Object.values(membersArr).map(row => {
                accountConversIdArr.push(`${row.convers_id.toString()}-${row.account_id.toString()}-${row.account_type.toString()}`);
            });

            let conversIdValue = accountConversIdArr.join(",");
            const TOKEN_SESSION = SessionUtilities.getSessionToken();

            const passval = {
                api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
                account_id: userLoginDetail.account_id.toString(),
                convers_id: conversIdValue.toString(),
                message: formValues.message.toString()
            };

            Utilities.elementDisabledStatus(button_name, true);
            const sendMessageResponse = await submitSendMessage(passval);
            Utilities.elementDisabledStatus(button_name, false);


            setShowProfileSelection(false);
            handleClearInput();

            if (Object.keys(sendMessageResponse).length === 0) {
                Utilities.messagePopup('error', errorMsg);
                return false;
            }

            if (parseInt(sendMessageResponse.success) === 1) {
                Utilities.messagePopup('success', TitlesLabels.alertMessages.messageHasBeenSend); //sendNotificationResponse.successMessage


            } else {
                Utilities.messagePopup('success', sendMessageResponse.errorMessage);
            }

        }


    }

    useEffect(() => {

        PagesAuthentication.getUserLoggedin();

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        setUserLoginDetail(userLoginDetailTemp);

        dispatch(pageTitle(`${TitlesLabels.navigations.notificationManagement}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));


        return () => {
            setShowProfileSelection(false);
        }

    }, []);



    return (
        <div className="department-bulk-message-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div>{TitlesLabels.settings}</div>
                            <div className="current">{TitlesLabels.navigations.bulkMessage}</div>

                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettings(new Date()))} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content position-relative">
                <div className="container pt-5 px-5  form-theme">

                    {showProfileSelection && <SidebarDepartmentProfileSelection handleSelectedMembers={handleSelectedMembers} />}

                    <div className='fs-20 pb-3 mb-4 font-gotham-bold border-bottom'>send message</div>

                    <div className="col-md-6">

                        <div className="form-group border-0">
                            <textarea name="message" className="form-control" rows="10" placeholder=" " value={formValues.message} onChange={(e) => pushValue(e, 'message')}></textarea>
                            <label className="form-label">type here</label>
                        </div>

                        <div className="text-center fs-13 fs-md-13 mt-n2">
                            <div><span className='cursor-pointer color-theme-green' onClick={() => handleClearInput()}>clear</span></div>
                        </div>


                        {
                            !showProfileSelection &&
                            <div className="my-3 pt-3">
                                <button type="button" className=" btn-theme-black" onClick={() => validateForm()}>send</button>
                            </div>
                        }

                    </div>

                </div>
            </div>
        </div>
    )
}

export default DeparmentBulkMessageForm;