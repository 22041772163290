import React, { useState } from 'react';
import DirectorySearchNoRecord from './DirectorySearchNoRecord';
import DirectorySearchItemTemplate from './DirectorySearchItemTemplate';
import DirectorySearchLoaderProfile from './DirectorySearchLoaderProfile';
import Utilities from '../../helpers/Utilities';
import DirectorySearchKeywordsTemplate from './DirectorySearchKeywordsTemplate';
import DirectorySearchBackNavigation from './DirectorySearchBackNavigation';
import { getPofilesByIds } from '../../helpers/QueryDirectorySearch';
import DirectorySearchProfileListing from './DirectorySearchProfileListing';
import { getCounterKeywordAllCities, getCounterKeywordAllStates, getCounterKeywordAllSuburb, getCounterKeywordByArea } from '../../helpers/QueryDirectorySearchKeywords';

const DirectorySearchKeywordsCountries = (props) => {

    let listResultProfiles = [], dataRequest = {}, resDb_listResult = {}, list_keywords = [], profile_count = 0, profile_ids = '', list_result = [], all_keywords = [];

    const listCountries = props.listResult.country_list ?? [];
    const allKeywords = props.listResult.all_keywords ?? [];
    const profileCount = props.listResult.profile_count ?? 0;
    const profileIds = props.listResult.profile_ids ?? 0;

    const countryId = parseInt(props.countryid ?? 0);
    const stateId = parseInt(props.stateid ?? 0);
    const cityId = parseInt(props.cityid ?? 0);
    const areaId = parseInt(props.areaid ?? 0);
    const typeId = parseInt(props.typeid ?? 0);
    const keyword = (props.keyword ?? '').toString();
    const enableSortDistance = props.enableSortDistance ?? false;

    const defaultLocation = {
        country_id: countryId.toString(),
        state_id: stateId.toString(),
        city_id: cityId.toString(),
        area_id: areaId.toString(),
    }

    const [steps, setSteps] = useState(0);

    const [boolShowStates, setBoolShowStates] = useState(false);
    const [boolShowCities, setBoolShowCities] = useState(false);
    const [boolShowSuburbs, setBoolShowSuburbs] = useState(false);
    const [boolShowKeywords, setBoolShowKeywords] = useState(false);
    const [boolShowProfileResult, setBoolShowProfileResult] = useState(false);
    const [boolShowLoading, setBoolShowLoading] = useState(false);

    const [detailCountry, setCountryDetail] = useState({});
    const [detailState, setStateDetail] = useState({});
    const [detailCity, setCityDetail] = useState({});
    const [detailArea, setAreaDetail] = useState({});
    const [detailKeyword, setDetailKeyword] = useState({});

    const [totalKeywordState, setTotalKeywordState] = useState(0);
    const [totalKeywordCity, setTotalKeywordCity] = useState(0);
    const [totalKeywordSuburb, setTotalKeywordSuburb] = useState(0);

    const [listResultStates, setListResultStates] = useState([]);
    const [listResultCities, setListResultCities] = useState([]);
    const [listResultSuburbs, setListResultSuburbs] = useState([]);
    const [listResulProfiles, setListResulProfiles] = useState([]);
    const [listResultKeywords, setListResultKeywords] = useState([]);


    const handleClickShowAllProfiles = (step = 0, profileidsProvides = '') => {
        setSteps(step);

        // initilal, click show all profiles
        if (step === 2) {
            handleClickGetProfiles({ profile_ids: profileidsProvides });

            // country - state list  , click show all profiles
        } else if (step === 5) {
            handleClickGetProfiles({ profile_ids: profileidsProvides });

            // country->states->cities-> click show all profiles
        } else if (step === 8) {
            handleClickGetProfiles({ profile_ids: profileidsProvides });

            // country->states->cities->suburbs click show all profiles
        } else if (step === 11) {
            handleClickGetProfiles({ profile_ids: profileidsProvides });

            // country->states->cities->suburbs->words->all-profiles
        } else if (step === 13) {
            handleClickGetProfiles({ profile_ids: profileidsProvides });
        }

    }

    const gettingCountryAllStates = async (detail = {}) => {

        if (Object.keys(detail).length === 0) { return false; }

        setSteps(3);

        dataRequest = {
            country_id: (detail.country_id ?? '0').toString(),
            state_id: '0',
            city_id: '0',
            area_id: '0',
            type: typeId.toString(),
            keyword: keyword.toString(),
            direct_search: "1"
        };

        list_result = [];

        setBoolShowLoading(true);
        resDb_listResult = await getCounterKeywordAllStates(dataRequest);
        setBoolShowLoading(false);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                list_result = resDb_listResult.list ?? [];
                profile_count = resDb_listResult.profile_count ?? 0;
                profile_ids = resDb_listResult.profile_ids ?? '';
                all_keywords = resDb_listResult.all_keywords ?? '';
            }
        }

        setCountryDetail({ ...detail, all_keywords: all_keywords });
        setListResultStates(list_result);
        setBoolShowStates(true);
        setBoolShowKeywords(false);
        setBoolShowProfileResult(false);

    }

    const gettingStateAllCities = async (detail = {}) => {

        if (Object.keys(detail).length === 0) { return false; }

        setSteps(6);
        setBoolShowStates(false);

        dataRequest = {
            country_id: (detailCountry.country_id ?? '0').toString(),
            state_id: (detail.state_id ?? '0').toString(),
            city_id: '0',
            area_id: '0',
            type: typeId.toString(),
            keyword: keyword.toString(),
            direct_search: "1"
        };

        list_result = [];

        setBoolShowLoading(true);
        resDb_listResult = await getCounterKeywordAllCities(dataRequest);
        setBoolShowLoading(false);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                list_result = resDb_listResult.list ?? [];
                profile_count = resDb_listResult.profile_count ?? 0;
                profile_ids = resDb_listResult.profile_ids ?? '';
                all_keywords = resDb_listResult.all_keywords ?? '';
            }
        }

        setStateDetail({ ...detail, all_keywords: all_keywords });
        setListResultCities(list_result);
        setBoolShowCities(true);
        setBoolShowKeywords(false);
        setBoolShowProfileResult(false);

    }


    const gettingCityAllSuburbs = async (detail = {}) => {

        if (Object.keys(detail).length === 0) { return false; }

        setSteps(9);
        setBoolShowCities(false);

        dataRequest = {
            country_id: (detailCountry.country_id ?? '0').toString(),
            state_id: (detailState.state_id ?? '0').toString(),
            city_id: (detail.city_id ?? '0').toString(),
            area_id: '0',
            type: typeId.toString(),
            keyword: keyword.toString(),
            direct_search: "1"
        };

        list_result = [];

        setBoolShowLoading(true);
        resDb_listResult = await getCounterKeywordAllSuburb(dataRequest);
        setBoolShowLoading(false);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                list_result = resDb_listResult.list ?? [];
                profile_count = resDb_listResult.profile_count ?? 0;
                profile_ids = resDb_listResult.profile_ids ?? '';
                all_keywords = resDb_listResult.all_keywords ?? '';
            }
        }

        setCityDetail({ ...detail, all_keywords: all_keywords });
        setListResultSuburbs(list_result);
        setBoolShowSuburbs(true);
        setBoolShowCities(false);
        setBoolShowKeywords(false);
        setBoolShowProfileResult(false);

    }


    const gettingKeywordsArea = async (detail = {}) => {

        if (Object.keys(detail).length === 0) { return false; }

        setSteps(12);
        setBoolShowSuburbs(false);

        dataRequest = {
            country_id: (detailCountry.country_id ?? '0').toString(),
            state_id: (detailState.state_id ?? '0').toString(),
            city_id: (detailCity.city_id ?? '0').toString(),
            area_id: (detail.area_id ?? '0').toString(),
            type: "8",
            keyword: keyword.toString(),
            location_type: 2
        }

        setBoolShowLoading(true);
        resDb_listResult = await getCounterKeywordByArea(dataRequest);
        setBoolShowLoading(false);


        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                all_keywords = resDb_listResult.list ?? [];
                profile_count = resDb_listResult.profile_count ?? 0;
                profile_ids = resDb_listResult.profile_ids ?? '';
            }
        }

        setAreaDetail({ ...detail, all_keywords: all_keywords });
        setListResultKeywords(all_keywords);
        setBoolShowKeywords(true);
        setBoolShowProfileResult(false);

    }


    const gettingAllKeywords = async (step = 0) => {
        setSteps(step);

        // initial show all
        if (step === 1) {

            setBoolShowKeywords(true);
            setListResultKeywords(allKeywords);

            // showing all - all states by country selected
        } else if (step === 4) {
            setBoolShowStates(false);
            setBoolShowKeywords(true);
            setListResultKeywords(detailCountry.all_keywords);

            //country->states_>cities->show-all(keywords)
        } else if (step === 7) {
            setBoolShowCities(false);
            setBoolShowKeywords(true);
            setListResultKeywords(detailState.all_keywords);

            //country->states_>cities->sububrs->show-all(keywords)
        } else if (step === 10) {
            setBoolShowSuburbs(false);
            setBoolShowKeywords(true);
            setListResultKeywords(detailCity.all_keywords);
        }

    }



    const handleClickGetProfiles = async (detail = {}) => {


        const profileIdsAvailable = (detail.profile_ids ?? '').toString();
        if (Utilities.isEmpty(profileIdsAvailable)) {
            return false;
        }

        setDetailKeyword(detail);

        dataRequest = {
            account_ids: profileIdsAvailable.toString(),
            account_type: 3
        }
        listResultProfiles = [];

        setBoolShowKeywords(false);
        setBoolShowProfileResult(false);

        setBoolShowLoading(true);
        resDb_listResult = await getPofilesByIds(dataRequest);
        setBoolShowLoading(false);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listResultProfiles = resDb_listResult.list ?? [];
            }
        }

        setListResulProfiles(listResultProfiles);
        setBoolShowProfileResult(true);

        //console.log(steps);
        // initial show all keywords
        if (steps === 1) {
            setSteps(2);

            // country->show-all(kewword)->keyword selected
        } if (steps === 4) {
            setSteps(5);

            // country->states->show-all(kewword)->keyword selected
        } if (steps === 7) {
            setSteps(8);

            // country->states->->cities->sububs->show-all(kewword)->keyword selected
        } if (steps === 10) {
            setSteps(11);

            // country->states->->cities->sububs->keyword->profiles
        } if (steps === 12) {
            setSteps(13);

        }


    }


    const handleClickBack = () => {
        //console.log(steps);

        // initial state = show all keywords
        if (steps === 1) {
            setBoolShowKeywords(false);

            // initial state = show all profiles
        } else if (steps === 2) {
            setBoolShowKeywords(true);
            setSteps(1);

            // country->states
        } else if (steps === 3) {
            setBoolShowStates(false);
            setSteps(0);

            // country->states->keywords
        } else if (steps === 4) {
            setBoolShowStates(true);
            setBoolShowKeywords(false);
            setSteps(3);

            // country->states->keywords->profiles
        } else if (steps === 5) {
            setBoolShowKeywords(true);
            setSteps(4);

            // country->states->->cities
        } else if (steps === 6) {
            setBoolShowStates(true);
            setBoolShowCities(false)
            setSteps(3);

            // country->states->->cities->all-words
        } else if (steps === 7) {
            setBoolShowKeywords(false);
            setBoolShowCities(true);
            setSteps(6);

            // country->states->->cities->all-words->all-profiles
        } else if (steps === 8) {
            setBoolShowKeywords(true);
            setSteps(7);

            // country->states->->cities->sububs
        } else if (steps === 9) {
            setBoolShowSuburbs(false);
            setBoolShowCities(true);
            setSteps(6);

            // country->states->->cities->sububs->all keywords
        } else if (steps === 10) {
            setBoolShowSuburbs(true);
            setBoolShowKeywords(false);
            setSteps(9);

            // country->states->->cities->sububs->all keywords->showall-profiles
        } else if (steps === 11) {
            setBoolShowKeywords(true);
            setSteps(10);

            // country->states->->cities->sububs->keywords
        } else if (steps === 12) {
            setBoolShowKeywords(false);
            setBoolShowSuburbs(true);
            setSteps(9);

            // country->states->->cities->sububs->keywords->all profiles
        } else if (steps === 13) {
            setBoolShowKeywords(true);
            setSteps(12);

        }

        setBoolShowLoading(false);
        setBoolShowProfileResult(false);


    }


    if (listCountries.length === 0) {
        return <DirectorySearchNoRecord />

    } else {

        return (
            <div className='tab-list-states'>

                {/* -------------------- country listing -------------------- */}
                {
                    (!boolShowLoading && !boolShowStates && !boolShowCities && !boolShowSuburbs && !boolShowKeywords && !boolShowProfileResult) &&
                    <ul className="states-listing" >
                        <li onClick={() => gettingAllKeywords(1, profileIds)}>
                            <div>
                                <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                <div><span className="counter fs-16 fs-md-14">{profileCount}</span></div>
                            </div>
                        </li>
                        {
                            Object.entries(listCountries).map(([key, row]) =>
                                <li key={key} onClick={() => gettingCountryAllStates(row)}>
                                    <DirectorySearchItemTemplate name={row.country_name ?? ''} counter={row.counter ?? '0'} /></li>
                            )
                        }
                    </ul>
                }


                {/* -------------------- states listing -------------------- */}
                {
                    (!boolShowLoading && boolShowStates && !boolShowCities && !boolShowSuburbs && !boolShowKeywords && !boolShowProfileResult) &&
                    <>
                        <DirectorySearchBackNavigation
                            handleClick={handleClickBack}
                            text={detailCountry.country_name ?? ''}
                        />
                        <ul className="states-listing" >
                            <li onClick={() => gettingAllKeywords(4, detailCountry.profile_ids ?? '')}>
                                <div>
                                    <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                    <div><span className="counter fs-16 fs-md-14">{detailCountry.counter ?? 0}</span></div>
                                </div>
                            </li>
                            {
                                Object.entries(listResultStates).map(([key, row]) =>
                                    <li key={key} onClick={() => gettingStateAllCities(row)}>
                                        <DirectorySearchItemTemplate name={row.state_name ?? ''} counter={row.counter ?? '0'} /></li>
                                )
                            }
                        </ul>
                    </>

                }



                {/* -------------------- city listing -------------------- */}
                {
                    (!boolShowLoading && !boolShowStates && boolShowCities && !boolShowSuburbs && !boolShowKeywords && !boolShowProfileResult) &&
                    <>
                        <DirectorySearchBackNavigation
                            handleClick={handleClickBack}
                            text={`
                                        ${detailState.state_name ?? ''}
                                        ${(detailState.state_name ?? '') !== '' ? ', ' + detailCountry.country_name ?? '' : ''}
                                    `}
                        />
                        <ul className="states-listing" >
                            <li onClick={() => gettingAllKeywords(7, detailState.profile_ids ?? '')}>
                                <div>
                                    <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                    <div><span className="counter fs-16 fs-md-14">{detailState.counter ?? 0}</span></div>
                                </div>
                            </li>
                            {
                                Object.entries(listResultCities).map(([key, row]) =>
                                    <li key={key} onClick={() => gettingCityAllSuburbs(row)} >
                                        <DirectorySearchItemTemplate name={row.city_name ?? ''} counter={row.counter ?? '0'} /></li>
                                )
                            }
                        </ul>
                    </>

                }


                {/* -------------------- suburb listing -------------------- */}

                {
                    (!boolShowLoading && !boolShowStates && !boolShowCities && boolShowSuburbs && !boolShowKeywords && !boolShowProfileResult) &&
                    <>
                        <DirectorySearchBackNavigation
                            handleClick={handleClickBack}
                            text={`
                                        ${detailCity.city_name ?? ''}
                                        ${(detailCity.city_name ?? '') !== '' ? ', ' + detailState.state_name ?? '' : ''}
                                        ${(detailState.state_name ?? '') !== '' ? ', ' + detailCountry.country_name ?? '' : ''}
                                    `}
                        />
                        <ul className="states-listing" >
                            <li onClick={() => gettingAllKeywords(10, detailCity.profile_ids ?? '')}>
                                <div>
                                    <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                    <div><span className="counter fs-16 fs-md-14">{detailCity.counter ?? 0}</span></div>
                                </div>
                            </li>
                            {
                                Object.entries(listResultSuburbs).map(([key, row]) =>
                                    <li key={key} onClick={() => gettingKeywordsArea(row)} >
                                        <DirectorySearchItemTemplate name={row.area_name ?? ''} counter={row.counter ?? '0'} /></li>
                                )
                            }
                        </ul>
                    </>
                }



                {/* -------------------- words listing -------------------- */}
                {
                    (!boolShowLoading && !boolShowStates && !boolShowCities && !boolShowSuburbs && boolShowKeywords && !boolShowProfileResult) &&
                    <>
                        <DirectorySearchBackNavigation
                            handleClick={handleClickBack}
                            text={`
                                        ${detailKeyword.word ?? Utilities.labelShowAll}
                                        ${(detailKeyword.word ?? '') !== '' ? ', ' + detailArea.area_name : ''}
                                        ${(detailArea.area_name ?? '') !== '' ? ', ' + detailCity.city_name : ''}
                                        ${(detailCity.city_name ?? '') !== '' ? ', ' + detailState.state_name : ''}
                                        ${(detailState.state_name ?? '') !== '' ? ', ' + detailCountry.country_name : ''}
                                    `}
                        />
                        <ul className="states-listing" >
                            {
                                steps === 1 ?
                                    <li onClick={() => handleClickShowAllProfiles(2, profileIds)}>
                                        <div>
                                            <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                            <div><span className="counter fs-16 fs-md-14">{profileCount ?? 0}</span></div>
                                        </div>
                                    </li>
                                    :
                                    steps === 4 ?
                                        <li onClick={() => handleClickShowAllProfiles(5, detailCountry.profile_ids ?? '')}>
                                            <div>
                                                <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                                <div><span className="counter fs-16 fs-md-14">{profileCount ?? 0}</span></div>
                                            </div>
                                        </li>
                                        :
                                        steps === 7 ?
                                            <li onClick={() => handleClickShowAllProfiles(8, detailState.profile_ids ?? '')}>
                                                <div>
                                                    <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                                    <div><span className="counter fs-16 fs-md-14">{detailState.counter ?? 0}</span></div>
                                                </div>
                                            </li>
                                            :
                                            steps === 10 ?
                                                <li onClick={() => handleClickShowAllProfiles(11, detailCity.profile_ids ?? '')}>
                                                    <div>
                                                        <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                                        <div><span className="counter fs-16 fs-md-14">{detailCity.counter ?? 0}</span></div>
                                                    </div>
                                                </li>
                                                :
                                                steps === 12 ?
                                                    <li onClick={() => handleClickShowAllProfiles(13, detailArea.profile_ids ?? '')}>
                                                        <div>
                                                            <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                                            <div><span className="counter fs-16 fs-md-14">{detailArea.counter ?? 0}</span></div>
                                                        </div>
                                                    </li>
                                                    :
                                                    <></>

                            }
                            <li style={{ minHeight: 0, padding: 0 }}></li>
                        </ul>

                        <ul className="list-keywords" >
                            {
                                Object.entries(listResultKeywords).map(([key, row]) =>
                                    <DirectorySearchKeywordsTemplate highlightKeywordInResults={props.highlightKeywordInResults} detail={row} handleClick={handleClickGetProfiles} />
                                )
                            }
                        </ul>
                    </>

                }


                {/* -------------------- profile listing -------------------- */}
                {
                    (!boolShowLoading && !boolShowStates && !boolShowCities && !boolShowSuburbs && !boolShowKeywords && boolShowProfileResult) &&
                    <>
                        <DirectorySearchBackNavigation
                            handleClick={handleClickBack}
                            text={`
                                        ${detailKeyword.word ?? Utilities.labelShowAll}
                                        ${(detailArea.area_name ?? '') !== '' ? ', ' + detailArea.area_name : ''}
                                        ${(detailCity.area_name ?? '') !== '' ? ', ' + detailCity.city_name : ''}
                                        ${(detailState.city_name ?? '') !== '' ? ', ' + detailState.state_name : ''}
                                        ${(detailCountry.state_name ?? '') !== '' ? ', ' + detailCountry.country_name : ''}
                                    `} />
                        <DirectorySearchProfileListing listProfiles={listResulProfiles} locationTabId={2} highlightKeywordInResults={props.highlightKeywordInResults} previewProfileImage={props.previewProfileImage} handleClickProfile={props.handleClickProfile} enableSortDistance={enableSortDistance} />
                    </>
                }


                {/* -------------------- loader -------------------- */}
                {boolShowLoading && <DirectorySearchLoaderProfile />}


            </div>

        )

    }

}


export default DirectorySearchKeywordsCountries;