const API_URL = process.env.REACT_APP_api_url;
const API_CHAT_URL = process.env.REACT_APP_chat_api_url;
const API_CHAT_DOMAIN = process.env.REACT_APP_chat_api_domain;
const BACKEND_URL = process.env.REACT_APP_backend_url;

class ApiUrlsHelper {

    // DEEP LINK //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    deepLink = `https://l.beonchat.com`


    // DOWNLOAD IMAGE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    awsDownloadImage = `${BACKEND_URL}/awsdownloadImage?file=`


    // LOCATION /////////////////////////////////////////////////////////////
    externalIPLookUp = `http://www.geoplugin.net/php.gp` //`https://extreme-ip-lookup.com/json/`
    externalIPgeolocationDB = `https://geolocation-db.com/json/` // make user put the slash (/) in the end


    // MAP LOCATION //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getCoordinatesByLocation = `${API_URL}/userlocation/getCoordinatesByLocation`
    getLocationByCoordinates = `${API_URL}/userlocation/getLocationByCoordinates`

    // GENERAL
    globalCountryList = `${API_URL}/globalqueries/countryList`


    // VERIFICATION ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    verificationMobileExistance = `${API_URL}/verification/mobileExistance`
    verificationEmailExistance = `${API_URL}/verification/emailExistance`
    verificationForgotChangePassword = `${API_URL}/verification/forgotChangePassword`
    verificationForgotPasswordAccessKeyExistance = `${API_URL}/verification/forgotPasswordAccessKeyExistance`
    verificationFirstLastNameExistance = `${API_URL}/verification/firstLastNameCheckerBasicProfile`

    verificationCompanyEmailExistance = `${API_URL}/verification/emailCompanyExistance`
    verificationCompanyMobileExistance = `${API_URL}/verification/mobileCompanyExistance`

    blockWordsAndEmailsValidation = `${API_URL}/verification/blockWordsAndEmailsValidation`
    checkPasswordExistanceCompanyBulkCredential = `${API_URL}/verification/checkPasswordExistanceCompanyBulkCredential`

    updateCompanyBulkCrecential = `${API_URL}/verification/updateCompanyBulkCrecential`
    updateMobilesendVerification = `${API_CHAT_URL}/web/send_verification_code_new_mobilenumber`    ///profile/send_verification_code_new_mobilenumber`


    // SOCIAL ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    loginSocialCredential = `${API_URL}/user/getSocialCredentialAccount`


    // SECURITY ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    securityEmailValidation = `${API_URL}/securityprofile/getCurrentCredentailEmail`
    securityEmailUpdate = `${API_URL}/securityprofile/updateCredentailEmail`
    securityPasswordValidation = `${API_URL}/securityprofile/checkCredentailPasswordValidity`
    securityPasswordUpdate = `${API_URL}/securityprofile/updateCredentailPassword`
    securityMobileValidation = `${API_URL}/securityprofile/getCurrentCredentailMobile`
    securityMobileUpdate = `${API_URL}/securityprofile/updateCredentailMobile`


    // SENDING EMAILS ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    sendingEmailAppReports = `${API_URL}/sendemail/appReports`
    sendingProfileSignupVerification = `${API_CHAT_URL}/web/send_signup_verification_code`  //profile/send_signup_verification`
    sendingEmailSystem = `${API_CHAT_URL}/web/send_system_email`   //general/send_system_email`
    sendingEmailGeneral = `${API_CHAT_URL}/web/send_general_email` //general/send_system_email`


    // CAMPAIGN ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getCampaignDetail = `${API_URL}/campaign/getCampaignDetail`
    campaignUpdateClicksAccount = `${API_URL}/campaign/updateClicksAccount`

    submitPersonalInfoTemp = `${API_URL}/profile/submitPersonalInfoTemp`


    // CAMPAIGN OWN ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    generateGetOwnReferralLink = `${API_URL}/campainreferral/generateGetOwnReferralLink`
    getRefferalDetailByAccountId = `${API_URL}/campainreferral/getRefferalDetailByAccountId`
    getReferralDetail = `${API_URL}/campainreferral/getReferralDetail`
    refferalUpdateClicksAccount = `${API_URL}/campainreferral/updateClicksAccount`


    // DIRECTORY SEARCH ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    directorySearchMainCounters = `${API_URL}/directoryprofilesearch/counters`
    directorySearchProfilesByLocation = `${API_URL}/directoryprofilesearch/searchProfilesLocation`
    directorySearchSuburbByCity = `${API_URL}/directoryprofilesearch/listAreasByCity`
    directorySearchStatesByCountry = `${API_URL}/directoryprofilesearch/listStatesByCountry`
    directorySearchProfilesByIds = `${API_URL}/directoryprofilesearch/listProfilesByIds`
    directorySearchCitiesByState = `${API_URL}/directoryprofilesearch/listCitiesByState`
    directorySearchCountriesProfiles = `${API_URL}/directoryprofilesearch/listCountriesProfiles`
    directorySearchPersonalEmails = `${API_URL}/directoryprofilesearch/listProfilesPersonalEmails`

    directorySearchKeywordCounterAllWordsByLocation = `${API_URL}/directorykeywordsearch/counterAllWordsByLocation`
    directorySearchKeywordCounterKeywordByArea = `${API_URL}/directorykeywordsearch/counterKeywordByArea`
    directorySearchKeywordCounterKeywordAllSuburb = `${API_URL}/directorykeywordsearch/counterAllSuburbs`
    directorySearchKeywordCounterKeywordAllStates = `${API_URL}/directorykeywordsearch/counterAllStates`
    directorySearchKeywordCounterKeywordAllCities = `${API_URL}/directorykeywordsearch/counterAllCities`
    directorySearchKeywordCounterKeywordSelectedCities = `${API_URL}/directorykeywordsearch/counterSelectedCities`
    directorySearchKeywordCounterKeywordAllCountries = `${API_URL}/directorykeywordsearch/counterAllCountries`

    directorySearchCategoryCompanyCounters = `${API_URL}/directoryprofilecategorysearch/counters`




    // COMPANY REGISTRATION ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    checkPermissionToRegisterCompany = `${API_CHAT_URL}/company_profile/check_create_company_request_status`    //bizcom/check_create_company_request_status`
    sendRequestForCompanyCreation = `${API_CHAT_URL}/company_profile/send_create_company_request`   //bizcom/send_create_company_request`
    removeRejectAcceptCompanyCreation = `${API_CHAT_URL}/company_profile/accept_reject_remove_create_company_request`   //bizcom/accept_reject_remove_company_create_request`


    // DEPARTMENT BULK MESSAGE  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatConversationListShare = `${API_CHAT_URL}/chat/conversation_share_list`   //bizchat/conversation_list_share`
    bizchatSendDeptBulkMessage = `${API_CHAT_URL}/chat/send_bulk_department_messages`   //bizchat/send_deprtment_conv_bulk_messages`


    // FORGOT PASSWORD ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileResetPassword = `${API_CHAT_URL}/device/reset_password` //profile/reset_password`

    // GENERATE PROFILE LOGIN HISTORY ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    prrofileLoginHistoryGenerate = `${API_URL}/user/generateProfileLoginHistory` //profile/reset_password`

    // CATEGORY SEARCH ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileGetCategoryList = `${API_CHAT_URL}/company_profile/get_company_category_list`    //profile/get_category_list`


    // URL META DATA /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    utilitiesGetUrlMetaData = `${API_URL}/utilities/getUrlMetaData`
    //utilitiesGetUrlMetaData = `${API_BIZCOM_URL}/jazechat/getUrlMetaData`


    // QUICK LINKS /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizcomSearchQuickLink = `${API_CHAT_URL}/get_quicklink_list`   //bizcom/search_quicklink`


    // USER //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    userGetByCredentail = `${API_URL}/user/getByCredentail`
    userCheckEmailExistance = `${API_URL}/user/checkEmailExistance`
    userGenerateSendVerificationCode = `${API_URL}/user/generateSendVerificationCode`
    userVerifyCode = `${API_URL}/user/verifyCode`

    userLocationSaveLocation = `${API_URL}/userlocation/saveLocation`
    userLocationGetUserLocationById = `${API_URL}/userlocation/getUserLocationById`

    userUpdateLoginHistory = `${API_URL}/user/updateLoginHistory`


    // MASTER SEARCH //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileSearchCategoryProfileIdsByCategoryId = `${API_URL}/profilesearchcategory/profileIdsByCategoryId`
    profileSearchCategoryProfileIdsByCategoryName = `${API_URL}/profilesearchcategory/profileIdsByCategoryName`
    profileSearchCategoryByCategoryName = `${API_URL}/profilesearchcategory/byCategoryName`
    getTodayCompanyToday = `${API_CHAT_URL}/search/get_comp_count`  //bizcom/get_today_comp_count`


    // UPLOAD LOGO AWS//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    awsUploadFilesProfileImage = `${API_URL}/awsuploadfiles/uploadProfileImage`
    awsUploadFilescompanyBranchImage = `${API_URL}/awsuploadfiles/uploadCompanyBranchImage`


    // PROFILES //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profile = `${API_URL}/profile/save`
    profilegetByID = `${API_URL}/profile/getByID`
    profileGetToolStatus = `${API_URL}/profile/getToolStatus`
    profileSignupTeamDetail = `${API_URL}/profile/signupTeamDetail`
    profileUploadLogo = `${API_URL}/profile/uploadLogo`
    profileUdpatePersonalBasic = `${API_URL}/profile/udpatePersonalBasic`
    profileDeleteUserDetails = `${API_CHAT_URL}/web/delete_user_profile`     //profile/delete_user_details`


    profileUpdateEmailMobileCredentail = `${API_URL}/user/updateEmailMobileCredential`


    // LINKED PORFILES //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    linkProfilesTeamList = `${API_URL}/linkprofiles/teamList`
    linkProfilesTeamAssign = `${API_URL}/linkprofiles/teamAssign`
    linkProfilesAssignedStatusByIDs = `${API_URL}/linkprofiles/assignedStatusByIDs`



    // COMPANY //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    companyOthers = `${API_URL}/company/companyOthers`
    companyUpdateDetail = `${API_URL}/company/updateDetail`
    companyTeamList = `${API_URL}/company/teamList`
    companySubmitCompanyDetail = `${API_URL}/company/submitCompanyDetail`


    // SEARCH
    searchCompanyByMobile = `${API_URL}/companysearch/bymobile`;

    // MAIN SEARCH //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    mainSearchStatesByCountry = `${API_URL}/mainsearch/statesByCountry`
    mainSearch = `${API_URL}/mainsearch/search`
    territoryAllSearch = `${API_URL}/territory/allSearch`
    getCompanyRegisteredNew = `${API_CHAT_URL}/search/get_comp_monthly_reg_list`    //bizcom/company_month_reg_list`


    // GOTO LINKS //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getGotoLinksHeadings = `${API_CHAT_URL}/company_profile/get_goto_link_list` //profile/link_company_category_list
    gotoLinksManageHeadings = `${API_CHAT_URL}/company_profile/manage_goto_link_category` // new added
    getGotoLinksProfiles = `${API_CHAT_URL}/company_profile/manage_goto_link_company_list`  //profile/link_company_account_list`


    // DAILY GLANCE  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getDailyGlanceFollowingCompaniesIds = `${API_URL}/dailyglance/following-companies-ids`
    getDailyGlanceListByDate = `${API_URL}/dailyglance/list-by-date`
    getDailyGlanceListDates = `${API_URL}/dailyglance/list-dates`

    getDailyGlanceListIncomingEvents = `${API_URL}/dailyglance/list-incoming-events`
    getDailyGlanceListFeatured = `${API_URL}/dailyglance/list-featured`

    getDailyGlancePremiumDailyAds = `${API_URL}/dailyglance/company-premium-daily-ads`
    getDailyGlancePremiumDailyAdsMarkRead = `${API_URL}/dailyglance/company-premium-daily-ads-mark-read`

    dailyGlanceContactFormSend = `${API_CHAT_URL}/glance/send_contact_message` //followers_notice/send_glance_contact_message`
    dailyGlanceFeedbackFormSend = `${API_CHAT_URL}/glance/send_feedback_message`    //followers_notice/send_glance_feedback_message`
    dailyGlanceFeedbackNewsUploadAttachments = `${API_URL}/awsuploadfiles/uploadNewsFeedArticleImages`
    dailyGlanceNewsFormSend = `${API_CHAT_URL}/glance/post_news_feed`   //followers_notice/post_glance_news_details`
    dailyGlanceEventsFormSend = `${API_CHAT_URL}/glance/post_events_feed`   //followers_notice/post_glance_events_details`

    glanceNotificationList = `${API_CHAT_URL}/glance/notification_list`
    glanceNotificationFeedFlashList = `${API_CHAT_URL}/glance/notification_feed_list`
     glanceNotificationUnreadCounter = `${API_URL}/dailyglance/glance-notification-unread-counter`
    glanceNotificationListMarkRead = `${API_CHAT_URL}/glance/read_notification`



    // CONTACT SEARCH //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    searchContactsByCityId = `${API_URL}/searchcontacts/bycity`


    // CATEGORIES //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    mainCategorySearchByKeywords = `${API_URL}/maincategories/searchByKeywords`
    mainCategoryGetSubCategoriesByParentId = `${API_URL}/maincategories/getSubCategoriesByParentId`
    mainCategoryGetById = `${API_URL}/maincategories/getById`


    // KEYWORDS //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    mainCompanyKeywordsSearchByKeywords = `${API_URL}/companykeywords/searchByKeywords`
    mainCompanyKeywordsSearchByKeywordsShowStates = `${API_URL}/companykeywords/searchByKeywordsShowStates`
    mainCompanyKeywordsGetProfileByIds = `${API_URL}/companykeywords/getProfileByIds`



    // LOCATIONS //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    country = `${API_URL}/country/list`
    stateList = `${API_URL}/state/list`
    cityList = `${API_URL}/city/list`
    cityDetailById = `${API_URL}/city/detailById`
    citySearchByCityId = `${API_URL}/city/searchByCityId`
    citySearchByCityName = `${API_URL}/city/searchByCityName`
    citySearchByCityNameFromAPI = `${API_URL}/city/searchByCityNameFromAPI`
    citySearhByName = `${API_URL}/city/searchByName`

    areaList = `${API_URL}/area/list`
    areaLocationByAreaId = `${API_URL}/area/locationByAreaId`

    cityAreaSearhByName = `${API_URL}/userlocation/searchAreaCityByName`


    // DOCUMENT //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    documentGetDocumentByGroupIDs = `${API_URL}/document/getDocumentByGroupIDs`
    documentTransferFileToConversFolder = `${API_URL}/document/transferFileToConversFolder`
    documentGetEncodedFile = `${API_URL}/document/getEncodedFile`


    bizcomDocumentsLibrary = `${API_CHAT_DOMAIN}/general/documents/documents_library` //`${API_CHAT_URL}/bizcom_documents_library`
    bizcomDocumentsManage = `${API_CHAT_DOMAIN}/general/documents/manage_documents_library`
    //bizcomUploadLibrary = `${API_CHAT_URL}/plus/documents/upload_library`
    bizcomUploadLibrary = `${API_CHAT_DOMAIN}/general/documents/upload_library`   //${API_CHAT_URL}/documents/upload_library`


    // CHAT //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    chatUploadAttachmentTransfer = `${API_URL}/chat/uploadAttachmentTransfer`
    chatUploadMedia = `${API_URL}/chat/uploadMedia`
    chatUploadAudio = `${API_URL}/chat/uploadAudio`


    // DEPARTMENTD //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    departmentListByAccountID = `${API_URL}/department/listByAccountID`
    departmentGetAccess = `${API_URL}/department/getAccess`
    departmentAddUpdate = `${API_URL}/department/addUpdateDepartment`
    departmentDelete = `${API_URL}/department/delete`
    departmentGetDetail = `${API_URL}/department/getDetail`
    departmentGetDetailByCompanyDepartmentID = `${API_URL}/department/getDetailByCompanyDepartmentID`


    departmentAccessGetDepartments = `${API_URL}/departmentaccess/getdepartments`
    departmentAccessAccessible = `${API_URL}/departmentaccess/accessible`
    departmentAccessLink = `${API_URL}/departmentaccess/link`
    departmentAccessUnlink = `${API_URL}/departmentaccess/unlink`
    departmentAccessDeleteLinked = `${API_URL}/departmentaccess/deletelinked`


    // TEAM //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    teamList = `${API_URL}/team/list`
    teamAddUpdateDetail = `${API_URL}/team/addUpdateDetail`
    teamDetail = `${API_URL}/team/detail`
    teamRemove = `${API_URL}/team/remove`

    teamProfileRemove = `${API_CHAT_URL}/team_profile/delete_team_details`  //profile/remove_team_profile`


    // MENU / NOTIFICATION //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    teamMenuNotificationAccessUpdate = `${API_URL}/team/menuNotificationAccessUpdate`


    // DIVERT //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileAssignTeamProfile = `${API_CHAT_URL}/menu/manage_team_divert`   //profile/assign_team_profile`


    // DASHBOARD CONVERSATION LISTING  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    menuHomeList = `${API_CHAT_URL}/menu/home_menu_list`
    bizcomWebHomeDetails = `${API_CHAT_URL}/web/beonchat_home_details`      //bizcom/bizcom_web_home_details`
    bizchatConversationList = `${API_CHAT_URL}/web/beonchat_home_details       //bizcom/bizcom_web_home_details`
    bizchatSendTeamInvitation = `${API_CHAT_URL}/web/send_team_invitation`  //bizchat/send_team_invitation`
    bizcomHomeConversationList = `${API_CHAT_URL}/chat/get_home_conversation_list`      //bizcom/bizcom_home_conversation_list`
    //bizchatConversationList = `${API_CHAT_URL}/bizchat/conversation_list`


    bizchatTransferConversation = `${API_CHAT_URL}/chat/transfer_conversation`  //bizchat/transfer_conversation`
    bizchatCreateBizcomConversation = `${API_CHAT_URL}/chat/beonchat_talk_to_team`  //bizchat/create_bizcom_conversation`
    bizcomWebInternalHomeDetail = `${API_CHAT_URL}/web/beonchat_internal_home_details`          //bizcom/web_internal_home_details`

    bizchatCreateBroadcastDetails = `${API_CHAT_URL}/internal_conv/create_broadcast_details`    //bizchat/create_broadcastdetails`
    bizchatUpdateBroadcastDetails = `${API_CHAT_URL}/internal_conv/update_broadcast_details`    //bizchat/update_broadcastdetails`
    bizchatDeleteBroadcastDetails = `${API_CHAT_URL}/internal_conv/delete_broadcast_details`    //bizchat/delete_broadcastdetails`
    bizchatGetBroadcastMembersDetails = `${API_CHAT_URL}/internal_conv/get_broadcast_details`   //bizchat/get_broadcast_members_details`


    bizchatSendBroadcastMessage = `${API_CHAT_URL}/internal_conv/send_broadcast_message`    //bizchat/send_broadcast_message`
    bizchatSetReadStatus = `${API_CHAT_URL}/chat/set_message_read_status`   //bizchat/set_read_status`


    bizchatGetConversationNotes = `${API_CHAT_URL}/chat/get_conversation_notes` //bizchat/get_conversation_notes`
    bizchatUpdateConversationNotes = `${API_CHAT_URL}/chat/manage_conversation_notes`   //bizchat/update_conversation_notes`

    bizchatGetTemplateMessage = `${API_CHAT_URL}/menu/get_saved_messages_list`     //bizchat/get_template_message`
    bizchatUpdateTemplateMessage = `${API_CHAT_URL}/menu/manage_saved_messages`    //bizchat/update_template_message`

    bizchatGetAllMedia = `${API_CHAT_URL}/chat/get_media_list`      //bizchat/get_all_media`
    bizchatMuteConversation = `${API_CHAT_URL}/chat/mute_conversation`  //bizchat/mute_conversation`
    bizchatReportConversation = `${API_CHAT_URL}/chat/report_conversation`      //bizchat/report_conversation`

    bizchatForwardConversation = `${API_CHAT_URL}/chat/forward_conversation`        //bizchat/forward_conversation`
    bizchatDeleteMediaFile = `${API_CHAT_URL}/chat/delete_media_file`       //bizchat/delete_media_file`
    bizchatConversationBlockUnblock = `${API_CHAT_URL}/chat/block_unblock_conversation`     //bizchat/conversation_blockunblock`

    bizchatClearConversation = `${API_CHAT_URL}/chat/clear_conversation`        //bizchat/clear_conversation`


    // DEPARTMENT FORMS  ------------------------------------------------------------------------------------------------------------------


    // this old urls ------------------------------------------------------------------------------------------------------------------
    //bizchatRequestReservationComplaintsDetail = `${API_CHAT_URL}/bizchat/request_reservation_complaints_details`
    //bizchatAcceptRejectReservationCompaints = `${API_CHAT_URL}/bizchat/acceptreject_reservation_complaints`
    //bizchatGetReservationComplaintsDetails = `${API_CHAT_URL}/bizchat/get_reservation_complaints_details`
    // end old urls ------------------------------------------------------------------------------------------------------------------


    bizchatRequestAppointmentComplaint = `${API_CHAT_URL}/a_r_f/create_appointment_reservation_feedback_request`    //bizchat/request_appointment_reservation_complaint`
    bizchatGetAppointmentReservationComplaintsDetails = `${API_CHAT_URL}/bizchat/get_appointment_reservation_complaints_details`
    bizchatCancelAppointmentReservation = `${API_CHAT_URL}/a_r_f/cancel_appointment_reservation`    //bizchat/cancel_appointment_reservation`
    bizchatAcceptAppointmentReservation = `${API_CHAT_URL}/a_r_f/accept_appointment_reservation_details`    //bizchat/accept_appointment_reservation`
    bizchatAmendAppointmentReservation = `${API_CHAT_URL}/a_r_f/amend_appointment_reservation`  //bizchat/amend_appointment_reservation`
    bizchatRequestComplaintReply = `${API_CHAT_URL}/bizchat/request_complaint_replay` // no longer use.....
    diaryGetAppointmentReservationDetails = `${API_CHAT_URL}/a_r_f/get_appointment_reservation_feedback_details`    //diary/get_appointment_reservation_details`


    // DEPARTMENT DEFAULT MESSAGE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileUpdateDepartmentDefaultMessage = `${API_CHAT_URL}/menu/manage_department_default_message`    //profile/update_department_default_message`
    profileDepartmentFormStatus = `${API_CHAT_URL}/menu/update_department_form_status`  //profile/update_department_form_status`
    profileActivateTeamAppointmentStatus = `${API_CHAT_URL}/menu/update_appointment_request_status`    //profile/activate_team_appointment_status`
    profileActivateTeamRestrictAccess = `${API_CHAT_URL}/menu/update_restrict_access_status`        //profile/activate_team_restrict_access`


    //profileGetProfileQrCode = `${API_CHAT_URL}/profile/get_profile_qr_code`
    profileGetProfileQrCode = `${API_CHAT_URL}/get_profile_qrcode`     //profile/get_profile_qrcode`
    profileGetProfileQrCodeDownload = `${API_CHAT_URL}/get_profile_qr_code`     //profile/get_profile_qrcode`

    profileGetAutomatedReplayMessage = `${API_CHAT_URL}/menu/get_automated_reply_message`   //profile/get_automated_replay_message`
    profileUpdateAutomatedReplayMessage = `${API_CHAT_URL}/menu/manage_automated_reply_message`     //profile/update_automated_replay_message`

    profileAcceptRejectTeamInvitation = `${API_CHAT_URL}/team_profile/accept_reject_remove_team_invitation`     //profile/accept_reject_team_invitation`
    profileTeamSendInvitation = `${API_CHAT_URL}/team_profile/send_team_invitation`     // NEW ADDED
    profileTeamCopyUserProfileLogo = `${API_CHAT_URL}/team_profile/copy_user_profile_logo`     // NEW ADDED
    profileTeamLogo = `${API_CHAT_URL}/team_profile/upload_team_logo`       // NEW ADDED
    profileTeamProfileUpdateDetails = `${API_CHAT_URL}/team_profile/update_team_admin_profile_details`  // NEW ADDED
    profileTeamProfileManageDetails = `${API_CHAT_URL}/team_profile/manage_team_details`  // NEW ADDED


    // CHAT //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatGetConversationDetails = `${API_CHAT_URL}/chat/conversation_details`     //bizchat/get_conversation_details`
    bizchatReverseAccessteamCommunication = `${API_CHAT_URL}/chat/reverse_team_request_access`      //bizchat/reverse_access_team_communication`
    bizchatResendAccessTeamCommunication = `${API_CHAT_URL}/chat/resend_team_request_access`      //bizchat/resend_access_team_communication`
    bizchatBeonchatConversationDetails = `${API_CHAT_URL}/chat/conversation_details`        //bizchat/beonchat_conversation_details`
    profileSendShareQRCode = `${API_CHAT_URL}/chat/send_qr_code` // ADDED / NEW


    // CHAT COMMUNICATION //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatAcceptRejectAccessCommunication = `${API_CHAT_URL}/chat/accept_reject_company_contact_request_user`      //bizchat/accept_reject_access_company_user_communication`
    bizchatReverseAccessCommunicationUserCommunication = `${API_CHAT_URL}/chat/reverse_denial_request_company_contact_user`     //bizchat/reverse_access_company_user_communication`
    bizchatResendAccessUserCommunication = `${API_CHAT_URL}/chat/resend_company_contact_request_user`       //bizchat/resend_access_company_user_communication`
    chatFollowerNoticeCancelGlanceRequest = `${API_CHAT_URL}/glance/cancel_request_feed`        //followers_notice/cancel_glance_request`


    // CHAT GROUP - CHAT //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatCheckGroupNameExist = `${API_CHAT_URL}/bizchat/check_group_name_exist`
    bizchatUploadGroupLogo = `${API_CHAT_URL}/internal_conv/upload_group_logo`      //bizchat/upload_group_logo`
    bizchatCreateGroup = `${API_CHAT_URL}/internal_conv/create_group_details`       //bizchat/create_group`
    bizchatLeaveGroup = `${API_CHAT_URL}/internal_conv/leave_group`     //bizchat/leave_group`
    bizchatRemoveFromGroup = `${API_CHAT_URL}/internal_conv/remove_group_member`     // NEW ADDED
    bizchatGroupDetail = `${API_CHAT_URL}/internal_conv/get_group_details`      //bizchat/group_details`
    bizchatDeleteGroupDetails = `${API_CHAT_URL}/internal_conv/delete_group_details`    //bizchat/delete_group_details`
    bizchatUpdateGroupMemberAdminStatus = `${API_CHAT_URL}/internal_conv/update_group_admin_status`     //bizchat/update_group_member_admin_status`
    bizchatUpdateGroupDetails = `${API_CHAT_URL}/internal_conv/update_group_details`        //bizchat/update_group_details`
    bizchatDeleteGroupConversation = `${API_CHAT_URL}/internal_conv/delete_group_conversation`      //bizchat/delete_group_conversation`


    // MENU / PRICE LIST //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileManageCatalogDetails = `${API_CHAT_URL}/menu/manage_company_menu_details`        //profile/manage_catalog_details`
    profileGetCatalogDetails = `${API_CHAT_URL}/menu/get_company_menu_details` // new added
    profileUploadCatalogFile = `${API_CHAT_URL}/menu/upload_company_menu_file`      //profile/uploadCatalogFile`

    // TEAM RESTRICTION //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatContactRequest = `${API_CHAT_URL}/chat/contact_request`  //bizchat/contact_request`
    chatInternalBroadcastDetail = `${API_CHAT_URL}/chat/internal_broadcast_conv_details`  // NEW ADDED
    chatInternalGroupDetail = `${API_CHAT_URL}/chat/internal_group_conv_details`  // NEW ADDED

    // TEAM RESTRICTION //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizchatContactRequestTeam = `${API_CHAT_URL}/chat/contact_request_team`     //bizchat/contact_request_team`
    bizchatRequestAccessTeamCommunication = `${API_CHAT_URL}/chat/contact_request_team_access`        //bizchat/request_access_team_communication`
    bizchatAcceptRejectAccessTeamCommunication = `${API_CHAT_URL}/chat/accept_reject_team_request_access`       //bizchat/accept_reject_access_team_communication`
    searchTeamProfileResult = `${API_CHAT_URL}/search/team_profile_result`      // NEW ADD


    // TALK TO US //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizcomTalkToBizcomAssist = `${API_CHAT_URL}/chat/beonchat_talk_to_team`     //bizchat/talk_to_bizcom_assist`


    // CALENDAR //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    calendarGetCounterByDates = `${API_URL}/calendardiary/getCounterByDates`
    calendarGetAppointmentsByIds = `${API_URL}/calendardiary/getAppointmentsByIds`
    calendarGetAppointmentsByDate = `${API_URL}/calendardiary/getAppointmentsByDate`


    // FOLLOWING //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    followingUpdateStatus = `${API_URL}/following/updateFollowingStatus`



    // NOTICES //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    profileUpdateCompanyFollowStatus = `${API_CHAT_URL}/search/follow_company`      //profile/update_company_follow_status`
    followersNoticeList = `${API_CHAT_URL}/notification/notification_notice_list`       //followers_notice/notice_list`
    noticeCompanyFollowers = `${API_CHAT_URL}/notification/followers_list`      //followers_notice/company_followers_list`
    noticeSendNotification = `${API_CHAT_URL}/notification/send_notification`       //followers_notice/send_follow_notification`
    noticeUploadFile = `${API_CHAT_URL}/notification/upload_file`       //followers_notice/upload_file`
    noticeUpdateCompanyFollowers = `${API_CHAT_URL}/notification/manage_notification_followers` //followers_notice/update_company_followers`

    followersNotificationList = `${API_CHAT_URL}/notification/notification_home`        //followers_notice/home_notification_list`
    followersFollowingList = `${API_CHAT_URL}/notification/following_list`      //followers_notice/following_list`
    followersUnfollowCompany = `${API_CHAT_URL}/notification/unfollow_company_profile`      //followers_notice/unfollow_company`
    followersMuteCompany = `${API_URL}/companyfollownotificationsetting/mute-setting`
    followersMuteCompanyDetail = `${API_URL}/companyfollownotificationsetting/mute-setting-detail`

    // BIZCOM ASSIST //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    bizcomMarketingTools = `${API_CHAT_URL}/beonchat_marketing_tools`       //bizcom/beonchat_marketing_tools`
    bizcomVideos = `${API_CHAT_URL}/beonchat_videos`       //bizcom/beonchat_videos`


    // COUNTERS //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    counterByType = `${API_URL}/counter/byType`


    // GROUPS //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    companyBroadcastLeaveGroup = `${API_CHAT_URL}/company_group/leave_group`        //company_broadcast/leave_group`
    bizchatCompanyBroadcastDetails = `${API_CHAT_URL}/company_group/conversation_details`       //bizchat/company_broadcast_conversation_details`
    companyBroadcastConversationMembersList = `${API_CHAT_URL}/company_group/members_list`      //company_broadcast/conversation_members_list`
    companyBroadcastAcceptRejectGroupRequest = `${API_CHAT_URL}/company_group/accept_reject_group_request`      //company_broadcast/accept_reject_group_request`
    //companyBroadcastUpdateConversationMembers = `${API_CHAT_URL}/company_group/suspend_members`     //company_broadcast/update_conversation_members`
    companyGroupSuspendMembers = `${API_CHAT_URL}/company_group/suspend_members`        // NEW ADDED
    companyGroupRemoveMembers = `${API_CHAT_URL}/company_group/remove_members`           // NEW ADDED
    companyBroadcastConversationsTeamMembersList = `${API_CHAT_URL}/company_group/authorised_team_list`     //company_broadcast/conversation_team_members_list`
    companyBroadcastCompanyGroupsList = `${API_CHAT_URL}/company_group/comp_groups_list`    //company_broadcast/company_broadcast_list` // HAVE CODES BUT CHECK IF UNUSEDD
    companyBroadcastAdminMembersList = `${API_CHAT_URL}/company_group/company_team_list`         //company_broadcast/admin_members_list`
    companyBroadcastCompanyCreateGroups = `${API_CHAT_URL}/company_group/create_comp_groups`            //company_broadcast/create_company_broadcast_details`
    companyBroadcastCompanyUpdateGroups = `${API_CHAT_URL}/company_group/update_comp_groups`            //company_broadcast/update_company_broadcast_details`
    companyBroadcastCompanyDeleteGroups = `${API_CHAT_URL}/company_group/delete_comp_groups`        //company_broadcast/delete_company_broadcast_details`
    companyBroadcastCompanyGroupsListBizchat = `${API_CHAT_URL}/search/company_groups_list`     //company_group/comp_groups_list`         //company_broadcast/company_group_list_bizchat`
    companyBroadcastJoinOpenGroup = `${API_CHAT_URL}/company_group/join_group`          //company_broadcast/join_open_group`
    companyBroadcastCompanyGroupsSendRequest = `${API_CHAT_URL}/company_group/send_request_join_group`          //company_broadcast/send_request_join`
    companyBroadcastCompanyGroupsJoinWithPasscode = `${API_CHAT_URL}/company_group/join_group_passcode`         //company_broadcast/join_group_passcode`
    companyBroadcastCompanyGroupsRemoveJoinRequest = `${API_CHAT_URL}/company_group/remove_group_request`       //company_broadcast/remove_join_request`


    // COMPANY GROUPS //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    companyGroupsGetGroupListByTeamMembersListId = `${API_URL}/companygroups/getGroupListByTeamMembersListId`


    // COMPANY BRANCH  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    saveCompanyBranchDetail = `${API_URL}/companybranch/saveCompanyBranchDetail`
    getCompanyBranchesByMasterId = `${API_URL}/companybranch/getCompanyBranchesByMasterId`
    getCompanyBranchesByIds = `${API_URL}/companybranch/getCompanyBranchesByIds`
    getCompaniesByAccountIds = `${API_URL}/company/getCompaniesByAccountIds`
    addCompanyIdToMasterById = `${API_URL}/companybranch/addCompanyIdToMasterById`
    awsCopyDefaultBranchLogoToCompany = `${API_URL}/awsuploadfiles/copyDefaultBranchLogoToCompany`


}

export default new ApiUrlsHelper();

