import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios'

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import CommonProfileLogo from '../common/CommonProfileLogo';

const ProfileDetail = (props) => {

    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const [profileSelected, setProfileSelected] = useState({});

    // MODAL
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);


    const getProfileDetail = (reqID) => {

        if (parseInt(reqID) > 0) {
            axios
                .post(ApiUrls.profilegetByID, { id: reqID })
                .then(response => {
                    const resStatus = response.status;

                    if (resStatus === 200) {
                        const resData = response.data;
                        const resStatus = parseInt(resData.status);

                        if (resStatus === 1) {
                            const profileInfo = Utilities.formatProfileDetail(resData.detail);
                            if (parseInt(profileInfo.account_type) === 3) {
                                setProfileSelected(profileInfo);
                                setShow(true);
                            }
                        }
                    }

                })
                .catch((err) => {

                });
        }
    }


    useEffect(() => {
        const requestID = props.requestID;
        if (parseInt(requestID || 0) > 0) {
            getProfileDetail(requestID);
        }

    }, [props.requestID]);

    return (

        <Modal show={show} onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="lg" >
            <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
            <Modal.Body>

                <div className="profile-selected-assoc text-center text-lowercase">

                    <div className="col-6 mx-auto">
                        <div className="profile-header text-left  mb-4">
                            {/* <div className={`profile-image ${ ( ( profileSelected.hasOwnProperty('plus_status') && parseInt(profileSelected.plus_status)===1 ) ? 'badge-plus' : '') }`} style={{ backgroundImage:  `url(${profileSelected.logo_url ?? DEFAUL_AVATAR})` }}></div> */}
                            <CommonProfileLogo classAttr={`profile-image ${((profileSelected.hasOwnProperty('plus_status') && parseInt(profileSelected.plus_status) === 1) ? 'badge-plus' : '')}`} profileDetail={profileSelected} />
                            <div>
                                <div className="profile-name notranslate">{profileSelected.name}</div>
                                {(profileSelected.hasOwnProperty('category') && profileSelected.category !== '') && <div className="profile-label">{profileSelected.category}</div>}
                            </div>
                        </div>

                        <div className="profile-address mb-5">{profileSelected.address}</div>

                        <div className="mb-5">
                            {
                                parseInt(profileSelected.account_type) === 3 ?
                                    <ul className="profile-departments">
                                        <li><Link to={`/${UrlSlugs.chat}/${btoa(profileSelected.account_id)}/3`} onClick={() => handleClose()} >general</Link></li>
                                        <li><Link to={`/${UrlSlugs.departments}/${btoa(profileSelected.account_id)}`} onClick={() => handleClose()} >departments</Link></li>
                                        <li><Link to={`/${UrlSlugs.teamMembers}/${btoa(profileSelected.account_id)}`} onClick={() => handleClose()} >team members</Link></li>
                                    </ul>
                                    :
                                    <Link to={`/${UrlSlugs.chat}/${btoa(profileSelected.account_id)}`} onClick={() => handleClose()} className="btn-theme-black" >confirm</Link>
                            }
                        </div>

                    </div>

                </div>

            </Modal.Body>
        </Modal>
    )
}

export default ProfileDetail;
