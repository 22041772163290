export const social_links = {
    facebook: `https://www.facebook.com/beonchat`,
    youtube: `https://www.youtube.com/channel/UCTgsW-IB7wyBcMNiAOHgBSA`,
    twitter: `https://twitter.com/BeOnChat`,
    instagram: `https://www.instagram.com/beonchat`,
    tiktok: `#`,
    whatsapp: `https://wa.me/27693282028`
}

export const video_links = {
    beonchat: `https://s3.ap-south-1.amazonaws.com/image.directory.beonchat/beonchat/beonchat_asset/videos/Beonchat`,
    beonchat_youtube: `https://www.youtube.com/embed/d2PBpDCvBMQ`,
    //beonchat_new : `https://s3.ap-south-1.amazonaws.com/image.directory.beonchat/beonchat/beonchat_asset/videos/Beonchat_intro.mp4`,
    beonchat_new: `https://www.youtube.com/embed/JVozJyTSDCk`,
    //beonchat_business_professional : `https://s3.ap-south-1.amazonaws.com/image.directory.beonchat/beonchat/beonchat_asset/videos/Beonchat_businesses_and_professionals.mp4`
    beonchat_business_professional: `https://www.youtube.com/embed/_wwhuAnRL3M`
}

export const chatWelcomeVideos_links = {
    search_business: `https://www.youtube.com/shorts/LoVV_s3tIEk`,
    create_company: `https://www.youtube.com/shorts/K_VPEEFGaQ8`,
    joining_school: `https://youtube.com/shorts/PWgsTBhBG4E`
}