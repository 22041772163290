import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const WelcomeMessage = (props) => {

    const siteTitle = process.env.REACT_APP_site_title;
    const [profileLoggedin, setProfileLoggedin] = useState({});

    useEffect(() => {
        setProfileLoggedin(props.profileLoggedin);

    }, [props]);

    return (

        <Modal show={props.showPopup} onHide={props.handleClosePopup} animation={false} className="modal-dialog-welcome" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName='modal-dialog-theme' >
            <Modal.Header className="border-bottom-0"></Modal.Header>
            <Modal.Body className="px-5 pb-5">

                <div className="text-lowercase px-4 welcome-message-section">

                    <div className="text-center">
                        <img src={`${process.env.REACT_APP_img_folder}/logo/logo-dark.svg`} alt="logo" width="150" />
                    </div>
                    <div className='pt-4 mb-2'></div>
                    <div className="text-lowercase text-center">

                        <p className="font-gotham-bold fs-20">hi {profileLoggedin.name}!<br />welcome to {siteTitle}!</p>
                        <div className='pb-3'></div>

                        <p className='font-gotham-book fs-18 line-height-1-5'>you have successfully created your personal account profile. would you like to create a profile for your business?</p>

                    </div>

                    <div className="button-group-boolean mt-5">
                        <div className="btn-theme-black" onClick={() => props.handleClosePopup(0)}>no</div>
                        <div className="btn-theme-black" onClick={() => props.handleClosePopup(1)}>yes</div>
                    </div>

                </div>

            </Modal.Body>
        </Modal>
    )
}

export default WelcomeMessage;
