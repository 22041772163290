import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TitlesLabels from '../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle } from '../actions';
import PagesAuthentication from '../helpers/PagesAuthentication';

const NotFoundPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        PagesAuthentication.getUserLoggedin();

        dispatch(pageTitle(TitlesLabels.siteTitles.pageNotFound));
        dispatch(showHeader(0));
        dispatch(showFooter(0));


    }, []);

    return (
        <div className="position-fixed w-100 h-100 d-flex align-items-center justify-content-center ">
            <div className="container">
                <div className="col-8 mx-auto text-center">
                    <img src={`${process.env.REACT_APP_img_folder}/404.svg`} width="100%"></img>
                    <h2 className="text-uppercase my-5">Oops! Page not found</h2>

                    <div className=" d-flex align-items-center justify-content-center ">
                        <a href="#" className="cursor-pointer" onClick={() => history.goBack()}>go back</a>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;