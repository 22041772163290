import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import * as RM from 'ramda';


import Utilities from '../helpers/Utilities';
import UrlSlugs from '../helpers/UrlSlugs';
import HeaderChat from './HeaderChat';
import { getInternalProfileUnreadCounter, getInternalProfiles } from '../helpers/InternalUtilities';

const HeaderInternal = (props) => {

    const history = useHistory();

    const internalConversationList = useSelector(state => state.passConversList);
    let passInternalProfile = useSelector(state => state.passInternalProfile);
    passInternalProfile = Utilities.workToCompany(passInternalProfile);

    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const headerType = props.headerType ?? 1;

    const [associatedAccounts, setAssociatedAccounts] = useState([]);

    const switchProfile = (profile = {}) => {
        if (!RM.isEmpty(profile)) {

            
            /* const companyId = parseInt(profile.company_id);
            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);

            if ( ! RM.isEmpty(accountsList) ){
                accountsList = JSON.parse(accountsList);
                
                let work_account_id = 0;
                Object.entries(accountsList).map( ([key, row] ) => {
                    if ( row.hasOwnProperty('team_company_id') && parseInt(row.account_type || 0)===4 ){
                        if ( parseInt(row.team_company_id)===companyId ){
                            work_account_id = row.account_id;
                        }
                    }
                });

                history.push(`/${ UrlSlugs.internalChat }/${ btoa( parseInt(work_account_id) ) }`);
            } */

            history.push(`/${UrlSlugs.internalChat}/${btoa(parseInt(profile.account_id ?? 0))}`);

        }

    }

    const handleLogoNavigation = () => {

        let segment = Utilities.getUrlSegments();
        const slugFirst = segment.shift().toString().toLowerCase();
        if (slugFirst === 'chat-conversation') {
            history.push(`/${UrlSlugs.internalChat}/${segment[0]}`);
            return false;
        }

        history.push(`/${UrlSlugs.dashboard}`)
    }


    const setupListing = (internalConversationList = {}) => {
        let internalProfiles = getInternalProfiles(), internalProfilesWithTemp = [], loopId = '', loopCount = 0;
        const profileWithUnreadMesasges = getInternalProfileUnreadCounter(internalConversationList.home_internal_conv_account_list??{});

        internalProfiles.map(prof => {
            loopId = `${(prof.hasOwnProperty('company_detail')) ? prof.company_detail.account_id + '_' : ''}${prof.account_id}`;
            loopCount = 0;
            if (profileWithUnreadMesasges.hasOwnProperty(loopId)) {
                loopCount = profileWithUnreadMesasges[loopId].toString();
            }

            internalProfilesWithTemp.push({ ...prof, unread_count: loopCount.toString() });

        });

        internalProfiles = [...internalProfilesWithTemp];

        if (internalProfiles.length > 0) {
            if ('company_name' in internalProfiles[0]) {
                const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('company_name')));
                internalProfiles = RM.sort(byName, internalProfiles);
            }
        }


        setAssociatedAccounts(internalProfiles);
    }


    useEffect(async () => {
        setupListing(internalConversationList);
    }, [internalConversationList]);


    return (
        <>
            <header className="main-header main-header-chat text-lowercase">
                <div className="main-header-content">

                    <div className="main-logo text-left">
                        <div className="cursor-pointer" onClick={() => handleLogoNavigation()} >  <img src={`${process.env.REACT_APP_img_folder}/logo/logo-light.svg`} className="logo-img" /></div>
                    </div>


                    <div className="user-navigate chat-internal-header">


                        <div className="profile-selection-list">

                            <Dropdown className="dropdown-user-selection">
                                <Dropdown.Toggle variant={`dropdown-user-current ${Object.keys(associatedAccounts).length === 1 ? 'cursor-auto' : ''}`} id="dropdown-basic">
                                    <div className={`logo-size-90 fs-22-important ${Utilities.classHideInitialBg(passInternalProfile.logo)}`} style={{ backgroundImage: `url(${Utilities.showHideProfileImage(passInternalProfile.logo)})` }}>{Utilities.showHideInitial(passInternalProfile.logo, passInternalProfile.name ?? '')}</div>
                                </Dropdown.Toggle>

                                {
                                    Object.keys(associatedAccounts).length > 1 &&

                                    <Dropdown.Menu >
                                        {
                                            Object.entries(associatedAccounts).map(([key, row]) =>
                                                <Dropdown.Item href="#" key={key} className={`${parseInt(row.company_id) === parseInt(passInternalProfile.account_id) ? 'current-item' : ''}`} onClick={() => switchProfile(row)}>
                                                    <div className="profile-item">

                                                        <div className={`avatar-image-wrap`}>
                                                            <div className={`avatar-image`} style={{ backgroundImage: `url(${Utilities.showHideProfileImage(row.company_detail.logo_url)})`, borderRightColor: Utilities.bgcolorHideInitialBg(row.company_detail.logo_url) }} >{Utilities.showHideInitial(row.company_detail.logo_url, row.company_detail.lname)}</div>
                                                        </div>

                                                        <div>
                                                            <div className="user-label">{row.company_name}</div>
                                                            <div className="user-name notranslate">{row.company_detail.category_name ?? ''}</div>
                                                        </div>

                                                        {parseInt(row.unread_count ?? 0) > 0 && <span className="counter"><em>{Utilities.counterMore(row.unread_count ?? 0)}</em></span>}

                                                    </div>
                                                </Dropdown.Item>
                                            )
                                        }
                                    </Dropdown.Menu >

                                }

                            </Dropdown>

                        </div>

                        <div className="user-current-navigate font-gotham-book fs-20 color-theme-grey">
                            <div className={`user-name user-label font-gotham-bold  fs-25`}>Internal Chat</div>
                            <div className={`department-current user-label-4 `}>{passInternalProfile.hasOwnProperty('name') ? passInternalProfile.name : ''}</div>
                        </div>



                    </div>

                    <div className="logo-sub"></div>

                </div>

            </header>

            {
                [3, 5].includes(parseInt(headerType)) &&
                <HeaderChat headerType={headerType} />
            }
        </>
    )
}

export default HeaderInternal;