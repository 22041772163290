import React, { useEffect, useState } from "react";
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import Utilities from "../../helpers/Utilities";
import SessionUtilities from "../../helpers/SessionUtilities";
import UrlSlugs from "../../helpers/UrlSlugs";


const HeaderDailyGlanceGrey = (props) => {

    const history = useHistory();
    const defaultLocation = Utilities.defaultLocation();
    const dateSelected = props.dateSelected ?? {};

    const [currentLocation, setCurrentLocation] = useState(defaultLocation);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [currentDateTitle, setCurrentDateTitle] = useState('');


    const redirectDailyGlancePage = () => {
        history.push(`/${UrlSlugs.notificationDailyGlance}`);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // INITIAL
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const init = () => {
        let userLocation = SessionUtilities.getUserLocation() ?? {};
        if (Object.keys(userLocation).length > 0) {
            setCurrentLocation(userLocation);
        }

        const currentDateTimeTemp = moment().format(Utilities.dateTimeServerStr),
            currentDateTitleTemp = moment().format(Utilities.dateTimeGlanceLabelStr);


        setCurrentDateTime(currentDateTimeTemp);
        setCurrentDateTitle(currentDateTitleTemp);;
    }


    useEffect(() => {
        init();

    }, []);


    return (
        <div className="header-daily-glance-grey text-lowercase">
            <div className="container d-flex align-items-center justify-content-center">
                <div className="text-white font-gotham-bold py-2  cursor-pointer" onClick={() => redirectDailyGlancePage()}>

                    <div className="d-flex align-center justify-content-center">
                        <div className="text-right">
                            <div className="d-flex align-items-center justify-content-end fs-13-important  mb-n4">
                                <div className="mt-n3">
                                    lenasia
                                    {/* { currentLocation.area_name ? currentLocation.area_name : currentLocation.city_name } */}
                                </div>
                                <div className="ml-2 pl-1"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-circle.svg`} alt="logo" width="50" /></div>
                            </div>
                            <div className="fs-27 title-glance d-flex align-items-center line-height-1" style={{ letterSpacing: `-1px` }}>
                                <span className="font-myriad-regular fs-57">@</span>
                                <span className="text-theme-green">a</span>
                                glance
                            </div>
                        </div>
                    </div>

                    {
                        !Utilities.isEmpty(currentDateTitle ?? '') &&
                        <div className="fs-9-important font-gotham-bold text-center cursor-pointer" >{currentDateTitle ?? ''}</div>
                    }

                </div>
            </div>
        </div>
    )

}

export default HeaderDailyGlanceGrey;