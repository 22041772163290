import React, { useEffect } from 'react';
import {  useHistory } from 'react-router-dom';

import PagesAuthentication from '../../helpers/PagesAuthentication';

const Login = () => {

    const history = useHistory();

    useEffect( () => {
        PagesAuthentication.logoutUser();
        history.push('/' );
    }, []);

    return (<div></div>)
}

export default Login;