import { mainSearchReducer, pageReducer, footerReducer, headerReducer, loaderReducer, reloadProfileReducer, globalNotificationCounterReducer, switchProfileReducer, reloadAccountsDBReducer, showChatSearchReducer, passProfileReducer, passInternalProfileReducer, passChatProfileReducer, chatSidebarBlockReducer, showLanguageSelectionPopupReducer, accountsWithNotificationReducer, globalProfileReducer, shareQRCodeReducer, showNoteIndicatorReducer, passConversIDReducer, passImageUrlReducer, passChatMessageTextReducer, passConversDetailReducer, bizcomplusSwitchAccountReducer, changeCityPopupReducer, applyChangeLocationReducer, newCityIDRequestReducer, reloadDepartmentsReducer, passQrcodeDetailReducer, passForwardConversIdReducer, passDeleteMediaListReducer, passConversListReducer, toggleAutomatedReplyReducer, passDocumentDetailReducer, passDocumentIdReducer, refershDashboadConversationReducer, showMainSettingsReducer, showDepartmentSettingsReducer, passConversationMessageReducer, toggleDepartmentDefaultMessageReducer, toggleDepartmentFormsReducer, showPopupCompanyCategoryReducer, passCompanyCategoryDetailReducer, toggleMenuPriceListReducer, viewMenuPricePopupReducer, passChatTeamProfileRestrictionDetailReducer, passPushNotificationDetailReducer, showPopupTermsConditionsReducer, showPopupQuickLinksReducer, showPopupVideoReducer, showPopupDeleteAccountReducer, showPopupContactSearchReducer, blockUnblockChatReducer, passCompanyGroupsMembersIdsReducer, booleanChatReInitializeConversationReducer, booleanShowWelcomeVideoAnimationReducer, booleanShowCompanyGroupShortcutReducer, showDirectoryProfilePopupReducer, showPopupSocialMediaLibraryReducer } from './pages';
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    showHeader: headerReducer,
    showLoader: loaderReducer,
    showFooter: footerReducer,
    showMainSearch: mainSearchReducer,
    pageTitle: pageReducer,
    reloadProfile: reloadProfileReducer,
    globalNotificationCounter: globalNotificationCounterReducer,
    reloadAccountsDB: reloadAccountsDBReducer,
    switchProfile: switchProfileReducer,
    globalProfile: globalProfileReducer,
    showChatSearch: showChatSearchReducer,
    passProfile: passProfileReducer,
    passInternalProfile: passInternalProfileReducer,
    passChatProfile: passChatProfileReducer,
    chatSidebarBlock: chatSidebarBlockReducer,
    showLanguageSelectionPopup: showLanguageSelectionPopupReducer,
    accountsWithNotification: accountsWithNotificationReducer,
    shareQRCode: shareQRCodeReducer,
    showNoteIndicator: showNoteIndicatorReducer,
    //allowManageNote : allowManageNoteReducer,
    passConversID: passConversIDReducer,
    passImageUrl: passImageUrlReducer,
    passChatMessageText: passChatMessageTextReducer,
    passConversDetail: passConversDetailReducer,
    bizcomplusSwitchAccount: bizcomplusSwitchAccountReducer,
    changeCityPopup: changeCityPopupReducer,
    applyChangeLocation: applyChangeLocationReducer,
    newCityIDRequest: newCityIDRequestReducer,
    reloadDepartments: reloadDepartmentsReducer,
    passQrcodeDetail: passQrcodeDetailReducer,
    passForwardConversId: passForwardConversIdReducer,
    passDeleteMediaList: passDeleteMediaListReducer,
    passConversList: passConversListReducer,
    toggleAutomatedReply: toggleAutomatedReplyReducer,
    toggleMenuPriceList: toggleMenuPriceListReducer,
    passDocumentDetail: passDocumentDetailReducer,
    passDocumentId: passDocumentIdReducer,
    refershDashboadConversation: refershDashboadConversationReducer,
    showMainSettings: showMainSettingsReducer,
    showDepartmentSettings: showDepartmentSettingsReducer,
    passConversationMessage: passConversationMessageReducer,
    toggleDepartmentDefaultMessage: toggleDepartmentDefaultMessageReducer,
    toggleDepartmentForms: toggleDepartmentFormsReducer,

    showPopupCompanyCategory: showPopupCompanyCategoryReducer,
    passCompanyCategoryDetail: passCompanyCategoryDetailReducer,
    viewMenuPricePopup: viewMenuPricePopupReducer,

    passChatTeamProfileRestrictionDetail: passChatTeamProfileRestrictionDetailReducer,
    passPushNotificationDetail: passPushNotificationDetailReducer,
    showPopupTermsConditions: showPopupTermsConditionsReducer,

    showPopupQuickLinks: showPopupQuickLinksReducer,
    showPopupVideo: showPopupVideoReducer,

    showPopupDeleteAccount: showPopupDeleteAccountReducer,
    showPopupContactSearch: showPopupContactSearchReducer,

    blockUnblockChat: blockUnblockChatReducer,
    passCompanyGroupsMembersIds: passCompanyGroupsMembersIdsReducer,
    booleanChatReInitializeConversation: booleanChatReInitializeConversationReducer,
    booleanShowWelcomeVideoAnimation: booleanShowWelcomeVideoAnimationReducer,
    booleanShowCompanyGroupShortcut: booleanShowCompanyGroupShortcutReducer,

    showDirectoryProfilePopup: showDirectoryProfilePopupReducer,
    showPopupSocialMediaLibrary: showPopupSocialMediaLibraryReducer,
});

export default allReducers;
