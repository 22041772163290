import React, { useEffect } from 'react';
import jQuery from 'jquery';

import { social_links } from '../helpers/Constants';
import UrlSlugs from '../helpers/UrlSlugs';

const Footer = () => {

    const siteTitle = process.env.REACT_APP_site_title; 

    useEffect(() => {
        jQuery('body').removeClass('mb-0');
    }, []);

    return (
        <footer className="footer-app text-lowercase text-left">
           <div className="container container-app pt-4 pb-3 my-1">

               <div className="row mt-1">
                    
                    <div className="col-3">
                        <h3 className='fs-18 font-gotham-bold'>Explore</h3>
                        <ul className="footer-nav fs-15">
                            <li><a href={`${ UrlSlugs.page_helpCenter }`} target="_blank">Help Center</a></li>
                            <li><a href={`${ UrlSlugs.page_termsConditions }`} target="_blank">Terms of Use</a></li>
                            <li><a href={`${ UrlSlugs.page_privacyPolicy }`} target="_blank">Privacy Policy</a></li>
                            <li><a href={`${ UrlSlugs.page_disclaimer }`} target="_blank">Disclaimer</a></li>
                            <li><a href={`${ UrlSlugs.page_enduserLicense }`} target="_blank">end user license agreement</a></li>
                        </ul>
                    </div>

                    <div className="col-3">
                        <h3 className='fs-18 font-gotham-bold'>Download</h3>
                        <ul className="footer-nav fs-15">
                            <li><a href={ UrlSlugs.link_appStore }  target="_blank" >iphone</a></li>
                            <li><a href={ UrlSlugs.link_playStore }  target="_blank" >android</a></li>
                            <li><a href={ UrlSlugs.link_appGallery } target="_blank">huawei</a></li>
                        </ul>
                    </div>

                    <div className="col-3">
                        <h3 className='fs-18 font-gotham-bold'>company</h3>
                        <ul className="footer-nav fs-15">
                            <li><a href={`${ UrlSlugs.page_beonchat }`}>{siteTitle}.com</a></li>
                            <li><a href={`${ UrlSlugs.page_bizcom_about }`}>about</a></li>
                            <li><a href={`${ UrlSlugs.page_bizcom_getInTouch }`}>get in touch</a></li>
                        </ul>
                    </div>

                    <div className="col-3">
                        <h3 className='fs-18 font-gotham-bold'>follow us on</h3>
                        <ul className="footer-social mt-3">
                            <li><a href={ social_links.facebook }  rel="noreferrer" alt="facebook" title="facebook" target="_blank" className="facebook"></a></li>
                            {/* <li><a href="https://www.linkedin.com"  rel="noreferrer" alt="linkedin" title="linkedin" target="_blank" className="linkedin"></a></li> */}
                            <li><a href={ social_links.instagram }  rel="noreferrer" alt="instagram" title="instagram" target="_blank" className="instagram"></a></li>
                            {/* <li><a href={ social_links.twitter }  rel="noreferrer" alt="twitter" title="twitter" target="_blank" className="twitter"></a></li> */}
                            <li><a href={ social_links.youtube }  rel="noreferrer" alt="youtube" title="youtube" target="_blank" className="youtube"></a></li>
                        </ul>

                        <div className='footer-copyright'>
                            <img src={`${process.env.REACT_APP_img_folder}/logo/logo.svg`} className="footer-logo mt-3" />
                            <div className="mt-2 font-gotham-book fs-12 fs-md-12 text-right">copyright &copy; {  new Date().getFullYear() }</div>
                        </div>
                        
                    </div>

               </div>
           </div>
        </footer>
    )
}

export default Footer;