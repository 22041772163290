import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import jQuery from 'jquery';

import TitlesLabels from '../../../helpers/TitlesLabels';
import { pageTitle, showFooter, showHeader, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../../actions';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import Utilities from '../../../helpers/Utilities';
import { getGogoLinksHeadings, manageGotoHeadings } from '../../../helpers/QueryGotoLinks';
import NoRecordAvailable from '../../../components/common/NoRecordAvailable';
import FieldInput from '../../../components/FieldInput';
import UrlSlugs from '../../../helpers/UrlSlugs';
import SessionUtilities from '../../../helpers/SessionUtilities';



const ManageGotoLinksHeadings = ({ match }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;

    const usersAssociated = PagesAuthentication.getUsersAssociated();
    const masterDetail = PagesAuthentication.getMasterUserDetailDetail();

    let dataDb_result = {}, dataParams = {};

    const [workProfile, setWorkProfile] = useState({});
    const [companyProfile, setCompanyProfile] = useState({});
    const [listHeadings, setListHeadings] = useState([]);

    const [actionType, setActionType] = useState(0);
    const [showProcessing, setShowProcessing] = useState(false);
    const [groupIdsSelected, setGroupIdsSelected] = useState([]);

    const [boolShowOptionPopup, setBoolShowOptionPopup] = useState(false);
    const [boolShowAddPopup, setBoolShowAddPopup] = useState(false);

    const [defaultSearchValue, setDefaultSearchValue] = useState('');




    const handleOptonClosePopup = () => setBoolShowOptionPopup(false);
    const handleClikMenu = () => setBoolShowOptionPopup(true);

    const handleAddClosePopup = () => setBoolShowAddPopup(false);

    const gettingHeadingList = async (companyDetail = companyProfile) => {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();

        dataParams = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (masterDetail.account_id ?? 0).toString(),
            company_id: (companyDetail.account_id ?? 0).toString()
        }

        setShowProcessing(true);
        dataDb_result = await getGogoLinksHeadings(dataParams);
        setShowProcessing(false);

        if (Object.keys(dataDb_result ?? []).length > 0) {
            setListHeadings(dataDb_result);
        } else {
            setListHeadings([]);
        }

    }


    const deleteSelectedGroups = async () => {

        if (Object.keys(groupIdsSelected).length === 0) { return false; }

        const button_name = `btn-submit-delete`;
        const TOKEN_SESSION = SessionUtilities.getSessionToken();

        // DELETE
        dataParams = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (masterDetail.account_id ?? 0).toString(),
            company_id: (companyProfile.account_id ?? 0).toString(),
            flag: "2",
            title: "",
            group_id: groupIdsSelected.join(',').toString()
        }

        Utilities.elementDisabledStatus(button_name, true);
        dataDb_result = await manageGotoHeadings(dataParams);
        Utilities.elementDisabledStatus(button_name, false);

        handleClickCancelDeletion(0);
        setGroupIdsSelected([]);

        if (Object.keys(dataDb_result ?? []).length > 0) {
            setListHeadings(dataDb_result);
        } else {
            setListHeadings([]);
        }
    }

    const toggleActiveClassMenu = (e, groupdetail = {}) => {
        if (actionType !== 2) { return false; }

        let groupIdsSelectedTemp = groupIdsSelected;
        let id = groupdetail.group_id.toString();

        !(groupIdsSelectedTemp.includes(id)) ? groupIdsSelectedTemp.push(id) : (groupIdsSelectedTemp = [...Utilities.removeValueInArray(groupIdsSelectedTemp, id)]);
        groupIdsSelectedTemp = Utilities.arrayUnique(groupIdsSelectedTemp);

        setGroupIdsSelected(groupIdsSelectedTemp);
    }


    const handleClickMenu = (actionType = 0) => {
        actionType = parseInt(actionType);
        setActionType(actionType);

        if (actionType === 1) {
            setBoolShowAddPopup(true);
        }

        handleOptonClosePopup();
    }


    const handleClickCancelDeletion = () => {
        setActionType(0);
    }


    const handleClickAddSubmit = async (headingName = '') => {

        if (Utilities.isEmpty(headingName)) { return false; }

        const button_name = `btn-submit-add`;
        const TOKEN_SESSION = SessionUtilities.getSessionToken();

        // ADD
        dataParams = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (masterDetail.account_id ?? 0).toString(),
            company_id: (companyProfile.account_id ?? 0).toString(),
            flag: "1",
            title: headingName.toString(),
            group_id: "0"
        }

        Utilities.elementDisabledStatus(button_name, true);
        dataDb_result = await manageGotoHeadings(dataParams);
        Utilities.elementDisabledStatus(button_name, false);

        handleAddClosePopup();
        handleClickCancelDeletion(0);
        setGroupIdsSelected([]);

        if (Object.keys(dataDb_result ?? []).length > 0) {
            setListHeadings(dataDb_result);
        } else {
            setListHeadings([]);
        }

    }

    const handelClickRedirectGoto = () => {
        history.push(`/${UrlSlugs.settings_manageGotoLinks}/${btoa(workProfile.account_id ?? 0)}`);
    }


    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }


    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#list-headings > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    useEffect(() => {

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.headings}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        return () => {
            setBoolShowOptionPopup(false);
        }

    }, []);



    useEffect(() => {

        let reqAccountID = match.params.wordid || "0";
        if (reqAccountID !== "0") {
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            let companyProfileTemp = {}, userLoginDetailTemp = {};

            Object.entries(usersAssociated).map(([acctid, acctdetail]) => {
                if (reqAccountID === parseInt(acctdetail.account_id ?? 0)) {
                    userLoginDetailTemp = Utilities.formatProfileDetail(acctdetail);
                    companyProfileTemp = Utilities.workToCompany(userLoginDetailTemp);
                }
            });


            setWorkProfile(userLoginDetailTemp);
            setCompanyProfile(companyProfileTemp);
            gettingHeadingList(companyProfileTemp);

        }


    }, [match.params.wordid]);



    return (
        <div className="manage-goto-links ">

            <div className="breadcrumbs breadcrumbs-chat">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => dispatch(showMainSettingsReducer(new Date()))}>{TitlesLabels.settings}</div>
                            {companyProfile.hasOwnProperty('name') && <div>{companyProfile.name}</div>}
                            <div className="cursor-pointer" onClick={() => handelClickRedirectGoto()}>{TitlesLabels.siteTitles.gotoLinks}</div>
                            <div className="current cursor-pointer">{TitlesLabels.general.headings}</div>

                        </li>
                        <li className="btn-option">
                            {
                                Object.keys(listHeadings).length > 0 && <div className="btn-icon btn-magnify" onClick={() => toggleSeachBox()} title={TitlesLabels.general.search}></div>
                            }
                            <div className="btn-icon btn-menu-two" onClick={() => handleClikMenu()} title={TitlesLabels.general.manage} ></div>
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>
            </div>


            <div className="main-content">

                {
                    Object.keys(listHeadings ?? []).length > 0 &&
                    <div className="content-subnav-wrap profile-search-wrap d-none  py-4">
                        <div className="container px-5">
                            <div className="profile-search-wrap bg-white d-flex align-items-center form-theme  w-100 border-radius">
                                <div className='form-group mb-0 border-0  w-100 '>
                                    <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} placeholder="type here" autoComplete='off' />
                                </div>
                                {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => resetSearchValue()}>clear</span></div>}
                            </div>

                        </div>
                    </div>
                }

                <div className="container py-5">


                    <div className='col-lg-9 mx-auto'>

                        {
                            showProcessing ?
                                <ul className="ul-global-listing">
                                    {
                                        [...Array(6).keys()].map((key) =>
                                            <li key={key} className="global-preloader  py-2">
                                                <div className="bg-gray col-5 pt-3 border-radius-loader"></div>
                                                <div className="ml-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                            </li>
                                        )
                                    }
                                </ul>
                                :
                                <>
                                    {
                                        Object.keys(listHeadings ?? []).length === 0 ?
                                            <NoRecordAvailable />
                                            :
                                            <ul id="list-headings" className={`ul-global-listing list-has-check font-gotham-bold fs-18 ${actionType === 2 ? '' : 'list-hide-checks'}`}>
                                                {
                                                    Object.entries(listHeadings ?? []).map(([key, row]) =>
                                                        <li key={key}
                                                            data-keyword={row.title}
                                                            className={`${((groupIdsSelected.includes(row.group_id.toString())) ? 'selected' : '')}`}
                                                            onClick={(e) => toggleActiveClassMenu(e, row)} >
                                                            <div className='notranslate ' data-name={row.title}>{row.title ?? ''}</div>
                                                        </li>
                                                    )
                                                }

                                            </ul>
                                    }


                                    {
                                        (actionType === 2 && Object.keys(listHeadings ?? []).length > 0) &&
                                        <div className="button-group-boolean pt-5 mt-4 col-md-6 mx-auto">
                                            <button type="button" className="btn-theme-black button-has-loader" onClick={() => handleClickCancelDeletion()}>cancel</button>
                                            <button type="button" id="btn-submit-delete" className={`btn-theme-black button-has-loader ${Object.keys(groupIdsSelected).length === 0 ? 'opacity-05 cursor-notallow' : ''}`} onClick={() => deleteSelectedGroups()}>submit</button>
                                        </div>
                                    }

                                </>

                        }


                    </div>
                </div>
            </div>


            <ManageGotoLinksOptionPopup showPopup={boolShowOptionPopup} handleClosePopUp={handleOptonClosePopup} handleClickMenu={handleClickMenu} />
            <ManageGotoLinksAddHeadingPopup showPopup={boolShowAddPopup} handleClosePopUp={handleAddClosePopup} handleClickSubmit={handleClickAddSubmit} />

        </div>
    )
}

export default ManageGotoLinksHeadings;



const ManageGotoLinksOptionPopup = (props) => {

    return <Modal id="popup-manage-gotolink-option" show={props.showPopup ?? false} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme "  >
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body className='px-5'>


            <ul className='cursor-pointer font-gotham-bold fs-18 text-center mt-n4 mb-5'>
                <li className='py-3 border-bottom' onClick={() => props.handleClickMenu(1)}>add heading</li>
                <li className='py-3' onClick={() => props.handleClickMenu(2)}>delete headings</li>
            </ul>


        </Modal.Body>
    </Modal>
}



const ManageGotoLinksAddHeadingPopup = (props) => {

    let defaultValues = { heading_name: '' };
    const [formValues, setFormValues] = useState(defaultValues);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'heading_name') {
            formValuesTemp = { ...formValuesTemp, ...{ heading_name: value } };
        }

        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);
    }

    const handleClickAddSubmit = () => {

        let errorCounter = 0;
        const fieldsChecker = ['heading_name'];

        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {
                if (Utilities.isEmpty(value)) {
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            }
        });

        if (errorCounter > 0) { return false; }

        props.handleClickSubmit(formValues.heading_name ?? '');
    }

    useEffect(() => {
        setFormValues(defaultValues);
    }, [props]);


    return <Modal id="popup-manage-gotolink-add-heading" show={props.showPopup ?? false} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme "  >
        <Modal.Header closeButton className="border-bottom-0">
            <h5 className="font-gotham-bold fs-25 text-lowercase text-center w-100 my-3" >add headings</h5>
        </Modal.Header>
        <Modal.Body className='px-5 pb-5 pt-4 form-theme text-lowercase'>

            <div className='pb-3'>
                <FieldInput inputName="heading_name" inputLabel="heading name" inputValue={formValues.heading_name} pushValue={pushValue} checkerType="general" boolAutoFocus={true} />
            </div>

            <button type="button" id="btn-submit-add" className="btn-theme-black button-has-loader" onClick={() => handleClickAddSubmit()}>submit</button>

        </Modal.Body>
    </Modal>
}
