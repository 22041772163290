import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import TitlesLabels from '../../helpers/TitlesLabels';
import UrlSlugs from '../../helpers/UrlSlugs';
import { video_links } from '../../helpers/Constants';


const siteTitle = process.env.REACT_APP_site_title;
const IMGFolder = process.env.REACT_APP_img_folder;

const CampaignLayout = (props) => {

    const props_classContent = props.classContent ?? "";
    const props_classWrap = props.classWrap ?? "";

    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const [popupVideoUrl, setPopupVideoUrl] = useState('');
    const [iframeIsLoading, setIframeIsLading] = useState(false);

    const handleShowVideoPopUp = (videoUrl = '') => {
        setShowVideoPopup(true);
        setIframeIsLading(true);
        setPopupVideoUrl(videoUrl);

    }

    const handleOnLoadIFrame = (boolStatus = false) => {
        setIframeIsLading(boolStatus);
    }

    const handleCloseVideoPopUp = () => {
        setShowVideoPopup(false);
    }


    return (
        <div className='signup-company-login text-white d-flex align-items-center justify-content-center py-5 py-lg-5'>
            <div className='container signup-company-container px-5 px-lg-0'>
                <div className='row'>
                    <div className='col-lg-7'>

                        <div className='mb-4 mb-lg-5'>
                            <div className='d-flex alig-justify-content justify-content-center justify-content-lg-start text-center'>
                                <div>
                                    <div className='mb-3'><img src={`${IMGFolder}/logo/logo-circle.svg`} width={110} className="logo-icon" /></div>
                                    <img src={`${IMGFolder}/logo/logo.svg`} className="logo-name" width={210} />
                                </div>
                            </div>
                        </div>

                        <div className="line-height-1-2 font-gotham-bold   fs-45   pb-md-2  desciption-heading text-center text-lg-left">
                            <span className="color-theme-green">a free business<br className='d-none d-lg-inline' />directory and chat</span> platform connecting people and business
                        </div>

                        <div className="btn-front-options d-flex my-3 mt-md-4 mb-md-4 justify-content-center justify-content-lg-start">
                            <div className="cursor-pointer" onClick={() => handleShowVideoPopUp(video_links.beonchat_new)} >
                                <img src={`${IMGFolder}/icons/white/outline-circle-play.svg`} width="40" />
                                <span className="font-gotham-bold fs-18 text-white ml-3 text-lowercase">understanding {siteTitle}</span>
                            </div>
                        </div>


                        <div className="download-links tap-connect  mt-md-5 text-white">
                            <div className="mb-3 text-center text-lg-left">available on</div>
                            <AppLinkHtml />
                        </div>


                    </div>


                    <div className={`col-lg-5 pl-lg-4  pt-5 pt-lg-0  px-0 ${props_classContent}`}>
                        <div className={`login-option-wrap  w-100 ${props_classWrap} d-flex align-items-center justify-content-center ml-ml-5 py-5 py-lg-2`}>

                            <div className='px-5 px-md-5 mx-md-3'>
                                {props.children}

                            </div>
                        </div>

                    </div>


                </div>
            </div>

            <Modal id="popup-watch-video" show={showVideoPopup} onHide={handleCloseVideoPopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="lg" contentClassName="bg-theme-green border-0" backdropClassName="modal-backdrop-gray- opacity-1-important">
                <Modal.Header closeButton className="border-0 popup-watch-video-header"></Modal.Header>
                <Modal.Body className="p-0 bg-theme-green font-gotham-medium text-center fs-20  popup-watch-video-body  overflow-hidden">

                    {
                        (popupVideoUrl || video_links.beonchat_youtube).split('youtube.').length > 1 ?
                            <iframe width="100.5%" height="450" style={{ marginLeft: -2 }} src={`${popupVideoUrl || video_links.beonchat_youtube}?autoplay=1`} onLoad={() => handleOnLoadIFrame(false)} class={`popup-watch-video-iframe  ${iframeIsLoading ? 'opacity-0' : ''}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            :
                            <video id="video-app-player" width="100.5%" style={{ marginLeft: -2, objectFit: 'fill' }} controls autoplay="">
                                <source src={`${popupVideoUrl || video_links.beonchat_youtube}`} type="video/mp4" />
                            </video>
                    }

                </Modal.Body>
            </Modal>


        </div>
    )
}

export default CampaignLayout;



const AppLinkHtml = () => {
    return (
        <>
            <ul className="d-none d-sm-flex justify-content-center justify-content-lg-start">
                <li className="mr-3 mr-lg-4">
                    <a href={UrlSlugs.link_playStore} target="_blank" className="btn-app-download"><img src={`${IMGFolder}/icons/white/google-play-logo.svg`} width="24" />
                        <span className="ml-2 font-gotham-bold fs-18 text-white">{TitlesLabels.general.appStore_googlePlay}</span>
                    </a>
                </li>
                <li className="mr-3 mr-lg-4">
                    <a href={UrlSlugs.link_appStore} target="_blank" className="btn-app-download"><img src={`${IMGFolder}/icons/white/apple-store-logo.svg`} width="24" />
                        <span className="ml-2 font-gotham-bold fs-18 text-white">{TitlesLabels.general.appStore_appleStore}</span>
                    </a>
                </li>
                <li>
                    <a href={UrlSlugs.link_appGallery} target="_blank" className="btn-app-download"><img src={`${IMGFolder}/icons/white/huawei-store-logo.svg`} width="24" />
                        <span className="ml-2 font-gotham-bold fs-18 text-white">{TitlesLabels.general.appStore_huaweiGallery}</span>
                    </a>
                </li>
            </ul>

            <ul className="d-flex justify-content-center just d-sm-none">
                <li className="mr-3">
                    <a href={UrlSlugs.link_playStore} target="_blank" style={{ width: 'inherit' }}>
                        <img src={`${IMGFolder}/icons/white/circle-outline-google-store.svg`} width="37" />
                    </a>
                </li>
                <li className="mr-3">
                    <a href={UrlSlugs.link_appStore} target="_blank" style={{ width: 'inherit' }}>
                        <img src={`${IMGFolder}/icons/white/circle-outline-apple-store.svg`} width="37" />
                    </a>
                </li>
                <li>
                    <a href={UrlSlugs.link_appGallery} target="_blank" style={{ width: 'inherit' }}>
                        <img src={`${IMGFolder}/icons/white/circle-outline-huawei-store.svg`} width="37" />
                    </a>
                </li>
            </ul>

        </>
    )
}