import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import jQuery from 'jquery';
//import moment from 'moment';

import PagesAuthentication from '../../helpers/PagesAuthentication';
import Utilities from '../../helpers/Utilities';
import ChatUtilities from '../../helpers/ChatUtilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, passImageUrl, refershDashboadConversation as refershDashboadConversationReducer, showDepartmentSettings as showDepartmentSettingsReducer, passConversList, showPopupQuickLinks as showPopupQuickLinksReducer, showLoader } from '../../actions';
import QueryChatUtilities from '../../helpers/QueryChatUtilities';
import SessionUtilities from '../../helpers/SessionUtilities';
import QueryUtilities from '../../helpers/QueryUtilities';
import MainSearchProfile from '../../components/popups/MainSearchProfile';
//import CommonProfileLogo from '../components/common/CommonProfileLogo';
import DashboardProfileListing from '../../components/common/DashboardProfileListing';
import DashboardProfileSearch from '../../components/common/DashboardProfileSearch';
import { gettingMenuSettinListStatus } from '../../helpers/QueryDashboardUtilities';


const DepartmentDashboard = ({ match }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const switchAccount = useSelector(state => state.switchProfile);
    const refershDashboadConversation = useSelector(state => state.refershDashboadConversation);

    const [paginationLoader, setPaginationLoader] = useState(false);
    const [paginationNumber, setPaginationNumber] = useState(0);
    const [globalPassvalConversation, setGlobalPassvalConversation] = useState({});

    const [contentLoaderClass, setContentLoaderClass] = useState('animate');
    //const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [showEmpty, setShowEmpty] = useState(false);
    const [listProfiles, setListProfiles] = useState([]);
    const [profileSort, setProfileSort] = useState(false);

    const [loggedInProfile, setLoggedInProfile] = useState({});
    const [rowProfileSelected, setRowProfileSelected] = useState({});

    const [allConversationResults, setAllConversationResults] = useState({});

    const [profileSelectedDirectory, setProfileSelectedDirectorySearch] = useState({});
    const [showProfileSelectedDirectory, setShowProfileSelectedDirectory] = useState(false);

    const handleCloseProfileDirectory = () => {
        setProfileSelectedDirectorySearch({});
        setShowProfileSelectedDirectory(false);
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            //setDefaultSearchValue(value);
            searchFilter(value);
        }
    }


    const resetSearchValue = () => {
        //setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const triggerSetting = () => {
        dispatch(showDepartmentSettingsReducer(new Date()));
    }

    const previewProfileImage = (logoUrl) => {
        if (!Utilities.isEmpty(logoUrl)) {
            dispatch(passImageUrl(logoUrl));
        }
    }

    const toggleMainSearch = (action = 0) => {
        //dispatch( showMainSearch(action) );
        history.push(`/${UrlSlugs.page_directorySearch}/${btoa(action)}`);
    }

    const noConversation = () => {
        setContentLoaderClass('');
        setListProfiles([]);
        setShowEmpty(true);
    }

    const showChat = async (profileChat = {}) => {

        setRowProfileSelected(profileChat);

        const passval = {
            api_token: TOKEN_SESSION.toString(), // TOKEN.toString(),
            account_id: loggedInProfile.account_id.toString(),
            account_type: loggedInProfile.account_type.toString(),
            convers_id: profileChat.convers_id.toString(),
        }


        const conversationDetailResponse = await QueryChatUtilities.getBeonchatConversationDetail(passval);
        if (Object.keys(conversationDetailResponse).length === 0) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGetConversationDetails);
            return false;
        }

        if (parseInt(conversationDetailResponse.success) === 0) {
            Utilities.messagePopup('error', conversationDetailResponse.errorMessage);
            return false;

        }

        let profileChatConversation = conversationDetailResponse.result.beonchat_conversation ?? conversationDetailResponse.result.conversation_details;
        let passValues = ChatUtilities.encryptChatDetail(profileChatConversation);
        ChatUtilities.saveAccountsConversationDetail(profileChatConversation, 'department dashboard');

        history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(profileChatConversation.account_id))}/${passValues}`);
    }

    const getRecentConversation = async (account = {}, refresh = 0) => {
        refresh = parseInt(refresh);

        //const dasboardLoadedStatus = parseInt(SessionUtilities.getDashboadLoadedStatus());

        if (refresh === 0) {
            setContentLoaderClass('animate');
            //SessionUtilities.setDashboadLoadedStatus(1);

        }

        /* if ( dasboardLoadedStatus===1 ){
            
            const dashboadLoadedListTemp = SessionUtilities.getDashboadLoadedListTemp();
            if (dashboadLoadedListTemp.length>0 ){
                setTimeout( () => {
                    setContentLoaderClass('');
                    setListProfiles([...dashboadLoadedListTemp]);
                }, 300);
            
                setShowEmpty(false);
            }
        } */

        let companyDetail = localStorage.getItem(process.env.REACT_APP_session_company_detail)??{};
        if (companyDetail !== null) {
            companyDetail = JSON.parse(companyDetail);
        }

        let loginDetail = localStorage.getItem(process.env.REACT_APP_session_department_active);
        let passValues = {};
        if (loginDetail !== null) {
            loginDetail = JSON.parse(loginDetail);

            setLoggedInProfile(loginDetail);

            /* let master_account_id = account.account_id;
            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
            if ( accountsList!==null ){
                accountsList = JSON.parse(accountsList);
                accountsList = Object.values(accountsList);
                master_account_id = accountsList[0].account_id;
            } */

            passValues = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: companyDetail.account_id.toString(),
                account_type: companyDetail.account_type.toString(),
                master_account_id: companyDetail.account_id.toString(),
                flag: "6",
                group_id: loginDetail.group_id.toString(),
            }

            setGlobalPassvalConversation(passValues);

            await axios
                .post(ApiUrls.bizcomWebHomeDetails, passValues)
                .then(response => {

                    setContentLoaderClass('');
                    dispatch(refershDashboadConversationReducer(''));

                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {

                            const conversationResult = response.data.result.beonchat_home_details ?? {};

                            dispatch(passConversList(conversationResult));

                            setAllConversationResults(conversationResult);

                            if (Object.keys(conversationResult.home_conversation_list ?? []).length > 0) {

                                let conversationListingTemp = [];
                                Object.entries(conversationResult.home_conversation_list).map(([key, row]) => {
                                    conversationListingTemp.push(Utilities.formatConversationList(row));
                                });

                                setListProfiles(conversationListingTemp);
                                setShowEmpty(false);

                                /* SessionUtilities.setDashboadLoadedListTemp(conversationListingTemp);
                                SessionUtilities.setDashboadLoadedStatus(1); */

                            } else {
                                noConversation();
                            }

                        } else {
                            noConversation();
                        }
                    } else {
                        noConversation();
                    }

                })
                .catch((err) => {
                    PagesAuthentication.logoutDirectly();

                    noConversation();
                    dispatch(refershDashboadConversationReducer(''));
                });

        }

        passValues = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: companyDetail.account_id.toString(),
            account_type: companyDetail.account_type.toString(),
            flag: "6",
            group_id: loginDetail.group_id.toString(),
        }

        const menuSettingRes = await gettingMenuSettinListStatus(passValues);
        SessionUtilities.setLocalSessionMenuSettingsStatus(menuSettingRes);

    }


    const blockOrCounter = (chatDetail) => {
        let html_return = '';

        if (chatDetail.hasOwnProperty('contact_settings') && parseInt(chatDetail.contact_settings.mute_status_settings.mute_status || 0) === 1) {
            html_return = <div className="onmute-icon"></div>
        }

        if (chatDetail.hasOwnProperty('conversa_list') && Object.keys(chatDetail.conversa_list ?? {}).length > 0) {
            const conversa_list = chatDetail.conversa_list;

            if (conversa_list.hasOwnProperty('block_status')) {
                const blockValue = parseInt(conversa_list.block_status.block_in_status || 0) + parseInt(conversa_list.block_status.block_out_status || 0);

                if (parseInt(blockValue) > 0) {
                    html_return = <span className="blocked-icon"></span>
                }
            }
        }

        if (chatDetail.hasOwnProperty('chat_count') && parseInt(chatDetail.chat_count || 0) > 0) {
            html_return = <> {html_return} <div className="unread-counter">{Utilities.counterMore(chatDetail.chat_count)}</div></>
        }

        return html_return;
    }



    const paginationContacts = async (elemId = '') => {
        const reachedScrollTopBool = Utilities.getElementSrollPosition(elemId);
        if (reachedScrollTopBool === true && paginationLoader === false) {

            let paginationNumberTemp = paginationNumber + 1;
            const passval = { ...globalPassvalConversation, page: paginationNumberTemp.toString() };

            setPaginationLoader(true);
            const contactListResponse = await QueryChatUtilities.getContactPagination(passval);
            setPaginationLoader(false);

            if (contactListResponse.length > 0) {

                let conversationListingTemp = [];
                Object.entries(contactListResponse ?? []).map(([key, row]) => {
                    conversationListingTemp.push(Utilities.formatConversationList(row));
                });

                setListProfiles([...listProfiles, ...conversationListingTemp]);
                setPaginationNumber(paginationNumberTemp);

                SessionUtilities.setDashboadLoadedListTemp(conversationListingTemp);

                jQuery(`#${elemId}`).stop().animate({
                    scrollTop: parseInt(document.querySelector(`#${elemId}`).scrollTop) + 200

                }, 500, '', function () { });
            }

        }
    }


    const getCompanyDetailDirectory = async (account_id = 0) => {
        account_id = parseInt(account_id);

        if (account_id > 0) {

            dispatch(showLoader(1));
            let responseProfileDetail = await QueryUtilities.getProfileDetail(account_id);
            dispatch(showLoader(0));

            if (Object.keys(responseProfileDetail).length === 0) {
                return false;
            }

            responseProfileDetail = {
                ...responseProfileDetail,
                ...responseProfileDetail.location
            }

            setProfileSelectedDirectorySearch(responseProfileDetail);
            setShowProfileSelectedDirectory(true);

            history.push(`/${UrlSlugs.departmentDashboard}`);

        }
    }

    useEffect(async () => {
        dispatch(pageTitle(TitlesLabels.siteTitles.departmentDashboard));
        dispatch(showHeader(5));
        dispatch(showFooter(1));

        PagesAuthentication.getUserLoggedin();

        await getRecentConversation();

        // PROFILE DIRECTORY SEACH 
        let paramsDirectConnectAccountId = match.params.accountid ?? '';
        if (!Utilities.isEmpty(paramsDirectConnectAccountId)) {
            await getCompanyDetailDirectory(atob(paramsDirectConnectAccountId));
        }

    }, []);

    useEffect(() => {

        if (!Utilities.isEmpty(switchAccount.toString()) && parseInt(switchAccount) > 0) {

            /* SessionUtilities.setDashboadLoadedStatus(0);
            SessionUtilities.setDashboadLoadedListTemp([]); */

            getRecentConversation();
        }

    }, [switchAccount]);

    useEffect(() => {
        if (!Utilities.isEmpty(refershDashboadConversation)) {
            getRecentConversation({}, 1);
        }

    }, [refershDashboadConversation]);


    return (
        <div className="dashboad-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li className="btn-option">
                            <div></div>
                        </li>

                        {
                            Utilities.isEmpty(contentLoaderClass) &&
                            <li className="btn-option">
                                <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                                <div className="btn-icon btn-magnify" onClick={() => toggleMainSearch(3)}></div>
                                <div className="btn-icon btn-gear" onClick={() => triggerSetting()}></div>
                            </li>
                        }
                    </ul>
                </div>


            </div>

            <div className="main-content">


                {
                    Utilities.isEmpty(contentLoaderClass) &&
                    <div className="content-subnav-wrap">
                        <div className="container py-2 d-flex align-items-center justify-content-between">
                            <div className="btn-new-chat btn-icon-wrap cursor-pointer fs-16 border-radius" onClick={() => toggleMainSearch(1)}>new chat</div>

                            <div className="profile-search-wrap bg-white d-flex align-items-center ml-3 form-theme  w-100 border-radius overflow-hidden pl-0 side-right">
                                <DashboardProfileSearch pushValue={pushValue} resetSearchValue={resetSearchValue} placeholder="search current chat" />
                            </div>

                        </div>
                    </div>
                }


                <div className="container">

                    {
                        !Utilities.isEmpty(contentLoaderClass) &&
                        <>
                            <div className="mb-3 global-preloader">
                                <div className="btn-dummy-new-chat"></div>
                            </div>
                            <ul className="dashboard-list-preloader mt-3">
                                {
                                    [...Array(6 + (Math.floor(Math.random() * 3))).keys()].map((key) =>
                                        <li key={key}>
                                            <div className="profile-image"></div>
                                            <div></div>
                                            <div></div>
                                        </li>
                                    )
                                }
                            </ul>
                        </>
                    }


                    {
                        Utilities.isEmpty(contentLoaderClass) &&
                        <>


                            {
                                showEmpty === false ?
                                    Object.keys(listProfiles).length > 0 &&
                                    <>
                                        <ul id="listing-profiles" className={`list-profile-messages max-scroll mt-3 ${!Utilities.isEmpty(refershDashboadConversation) ? 'processing-latest-loader' : ''}`} onScroll={(e) => paginationContacts('listing-profiles')}>
                                            {
                                                Object.entries(listProfiles).map(([key, row]) =>
                                                    <DashboardProfileListing key={key} row={row} rowProfileSelected={rowProfileSelected} showChat={showChat} blockOrCounter={blockOrCounter} previewProfileImage={previewProfileImage} />

                                                )
                                            }

                                        </ul>
                                        {paginationLoader && <div className="content-loader list-loader animate"></div>}
                                    </>
                                    :
                                    <div className="text-center text-lowercase no-record-label no-chats-label">{Utilities.labelNoChatActive}</div>
                            }

                        </>
                    }


                </div>

            </div>

            <MainSearchProfile handleShowProfilePopup={showProfileSelectedDirectory} handleCloseProfilePopup={handleCloseProfileDirectory} profileSelected={profileSelectedDirectory} showStatus={3} />

        </div>
    )
}

export default DepartmentDashboard;