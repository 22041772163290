import React, { useEffect, useState } from 'react';
import {  useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TitlesLabels from '../../helpers/TitlesLabels';
import { pageTitle, showFooter, showHeader } from '../../actions';


import UrlSlugs from '../../helpers/UrlSlugs';
import CampaignReferralPopup from '../../components/popups/CampaignReferralPopup';
import PagesAuthentication from '../../helpers/PagesAuthentication';

const CampaignReferral = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [ showPopup, setShowPopup ] = useState(true);

    const handleClosePopUp = () => {
        history.push(`/${ UrlSlugs.campaign_downloadApp}`);
        setShowPopup(false);
    }

    useEffect( () => {
        dispatch( pageTitle( TitlesLabels.siteTitles.referral ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        const masterAccountSession = PagesAuthentication.getUserLoggedinDetail();
        if ( Object.keys(masterAccountSession??{}).length===0 ){
            history.push(`/${ UrlSlugs.login}`);
        }

    }, [] );

    return (
        <CampaignReferralPopup showPopup={showPopup} handleClosePopUp={handleClosePopUp} />
    )
}

export default CampaignReferral;