import axios from 'axios';
import ApiUrls from './ApiUrls';


class QueryMenuPriceUtilities {

    getDetail = async (passValue = {}) => {
        let response = {};
        if (Object.entries(passValue).length > 0) {
            try {

                //console.log('getDetail');
                //console.log(ApiUrls.profileGetCatalogDetails);
                //console.log(passValue);

                const urlRequest = ApiUrls.profileGetCatalogDetails;
                const detailRes = await axios.post(urlRequest, passValue);
                response = detailRes.data.result.company_menu_details??{};
               
            } catch (e) {
                response = {};
            }
        }

        return response;
    }

    manageDetail = async (passValue = {}) => {
        let response = {};
        if (Object.entries(passValue).length > 0) {
            try {

                //console.log('manageDetail');
                //console.log(ApiUrls.profileManageCatalogDetails);
                //console.log(passValue);

                const urlRequest = ApiUrls.profileManageCatalogDetails;
                const detailRes = await axios.post(urlRequest, passValue);
                response = detailRes.data.result.company_menu_details??{};

            } catch (e) {
                response = {};
            }
        }

        return response;
    }

    uploadFile = async (formData) => {

        let returnResponse = {};
        try {

            //console.log('uploadFile');
            //console.log(ApiUrls.profileUploadCatalogFile);

            const urlRequest = ApiUrls.profileUploadCatalogFile;
            const documentRes = await axios.post(urlRequest, formData);
            returnResponse = documentRes.data;
            //console.log(returnResponse);

        } catch (e) {
            returnResponse = {};
        }

        return returnResponse;

    }

}

export default new QueryMenuPriceUtilities();

