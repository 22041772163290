import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { social_links } from '../../helpers/Constants';
//import { isMobile } from 'react-device-detect';
import UrlSlugs from '../../helpers/UrlSlugs';
import DownloadAppPopup from './DownloadAppPopup';
import Utilities from '../../helpers/Utilities';


const isMobile = Utilities.isMobileByScreenSize();

const CampaignDownloadAppPopup = (props) => {

    const imgFolder = process.env.REACT_APP_img_folder;

    const [showDownloadPopup, setShowDownloadPopup] = useState(false);

    const handleOnClickDownload = () => {
        if (isMobile) {
            window.open(UrlSlugs.link_appStorePlay, '_self');

        } else {
            setShowDownloadPopup(true);
        }
    }


    const handleCloseDownloadPopUp = () => {
        setShowDownloadPopup(false);
    }


    useEffect(() => {

        setShowDownloadPopup(false);

    }, [props]);

    return (
        <>
            <Modal id="popup-forgot-password" show={props.showPopup} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-dialog-theme "  >
                <Modal.Body className='mb-4 mx-0 px-0 overflow-hidden'>
                    <div className='d-flex justify-content-center  bg-black pt-4 pt-md-5 mt-n3'>
                        <img src={`${imgFolder}/icons/white/outline-cross-border.svg`} className='btn-modal-close zindex-1' onClick={props.handleClosePopUp} />
                        <div className='col-10 col-sm-8 col-md-6'><img src={`${imgFolder}/mobile-half.png`} style={{ width: '-webkit-fill-available' }} /></div>
                    </div>
                    <div className=' text-center pt-4 px-5'>
                        <div className='font-montserrat-bold fs-28' style={{ letterSpacing: -1.4 }}>get the beonchat app</div>
                        <button type="button" className="btn-theme-green text-lowercase button-has-loader col-sm-6 mx-auto mt-4 mb-3" onClick={() => handleOnClickDownload()}>download</button>

                        <div className='font-montserrat-bold fs-18 mb-3' style={{ letterSpacing: -0.9 }}>follow us</div>
                        <div>
                            <a href={social_links.facebook} target='_blank' className='mx-2'><img src={`${imgFolder}/icons/social/black/facebook.svg`} width={40} /></a>
                            <a href={social_links.instagram} target='_blank' className='mx-2'><img src={`${imgFolder}/icons/social/black/instagram.svg`} width={40} /></a>
                        </div>

                    </div>

                </Modal.Body>

            </Modal>

            <DownloadAppPopup showPopup={showDownloadPopup} handleClosePopup={handleCloseDownloadPopUp} />
        </>

    )
}

export default CampaignDownloadAppPopup;
