import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios'
import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';

const CitySearch = (props) => {

    const [defaultValue, setdefaultValue] = useState('');
    const [keywordValue, setKeywordValue] = useState('');
    const [cityList, setCityList] = useState([]);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'city-name') {
            setdefaultValue(value);
        }
    }

    const searchCity = async () => {

        const button_name = 'from-group-city';
        Utilities.elementDisabledStatus(button_name, false);

        let keyword = document.getElementById('input_city_keyword').value || '';

        if (typeof keyword !== 'undefined' && parseInt(keyword.length) > 2) {

            Utilities.elementDisabledStatus(button_name, true);

            await axios
                .post(ApiUrls.citySearhByName, { keyword: keyword })
                .then(response => {
                    if (response.status === 200) {
                        setCityList(response.data);
                    }

                    Utilities.elementDisabledStatus(button_name, false);
                })
                .catch((err) => {
                    Utilities.elementDisabledStatus(button_name, false);
                });
        }

    }


    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // Send Axios request here
            if (!Utilities.isEmpty(keywordValue)) {
                searchCity();
            }

        }, 1000);

        return () => {
            clearTimeout(delayDebounceFn);
        };

    }, [keywordValue]);


    useEffect(() => {
        return () => {
            setKeywordValue('');
        };
    }, []);

    return (

        <Modal id="popup-city-selection" show={props.showCityPopup} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme "  >
            <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
            <Modal.Body>

                <div className="popup-city-selection  text-lowercase form-theme mt-n5 pt-1 mx-4 col-md-11 mx-auto">

                    <div className="font-gotham-bold fs-25 text-center  border-bottom-0 mt-4 mb-4">Search City</div>

                    <div id="from-group-city" className="form-group button-has-loader">
                        <input type="text" id="input_city_keyword" className="form-control text-lowercase" placeholder=" " value={defaultValue} onChange={(e) => { setKeywordValue(e.target.value); pushValue(e, 'city-name') }} autoComplete="off" autoFocus ></input>
                        <label className="form-label">City</label>
                    </div>


                    {
                        cityList.length > 0 ?
                            <ul className="city-list mb-4 font-gotham-book fs-18 fs-md-18">
                                {
                                    Object.entries(cityList).map(([key, row]) =>
                                        <li key={key} onClick={() => props.passCityDetail(row)} className="label-color-gray justify-content-between px-2">
                                            <div className="city-name font-gotham-book text-black">{row.city_name}</div>
                                            <div className='d-flex align-items-center fs-16 fs-md-16'>
                                                <div>{row.state_name}</div>
                                                <div className='ml-3'>{row.country_name}</div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                            :
                            <div className="text-center label-color-gray mt-3 mb-5">{Utilities.labelCitiesAvailable}</div>
                    }


                </div>

            </Modal.Body>
        </Modal>
    )
}

export default CitySearch;
