import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import jQuery from "jquery";
import { confirmAlert } from 'react-confirm-alert';

import Utilities from '../../helpers/Utilities';
import TitlesLabels from '../../helpers/TitlesLabels';
import { updateMobilesendVerification } from '../../helpers/SecurityProfile';
import { showLoader } from '../../actions';

const MobileAccountVerificationPopup = (props) => {

    const dispatch = useDispatch();

    let globalRecordTimer = '';
    const inputPincodeStyle = { width: 65, height: 74 };
    const props_verificationParams = props.verificationParams ?? {};
    const props_verificationCode = props.verificationCode ?? {};

    const [countDown, setCountDown] = useState('05:00');
    const [showResendButton, setShowResendButton] = useState(false);
    const [timerInterval, setTimerInterval] = useState('');
    const [hiddenMobileNumber, setHiddenMobileNumber] = useState('');
    const [codeResponse, setCodeResponse] = useState('');
    const [verificationCode, setVerificationCode] = useState(props_verificationCode);


    const codeFocus = (e, type = '') => {
        let value = e.target.value;
        if (!Utilities.isEmpty(value)) {
            jQuery(e.target).next('input').val('').focus();
        }
        if (type === 'last') {
            jQuery('input#dummy').focus();
            verifyCodes();
        }
    }



    const codeBack = (e) => {
        const key = parseInt(e.keyCode || e.charCode);

        if (key === 8) {
            jQuery(e.target).prev('input').val('').focus();
        }
    }


    const codeReset = (e) => {
        jQuery(e.target).val('');
    }



    const resendPincode = async (hideLoaderBool = false) => {

        stopTimer();
        timerCountDown(0);
        timerCountDown();

        jQuery('.codes-input input').val('');
        document.getElementById('code_1').focus();
        setVerificationCode('');

        const button_name = 'btn-resend-code';
        Utilities.elementDisabledStatus(button_name, false);


        !hideLoaderBool && dispatch(showLoader(1));
        Utilities.elementDisabledStatus(button_name, true);

        const response = await updateMobilesendVerification(props_verificationParams);

        !hideLoaderBool && dispatch(showLoader(0));
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(response.success ?? 0) === 0) {
            Utilities.messagePopup('Error', response.errorMessage);
            return false;
        }

        confirmAlert({
            title: 'success',
            message: response.successMessage.toString(),
            overlayClassName: 'normal-font-style warning',
            closeOnClickOutside: false,
            closeOnEscape: false,
            buttons: [
                {
                    label: 'close', onClick: () => {
                        /* stopTimer();
                        timerCountDown(0);
                        timerCountDown(); */
                    }
                }
            ]
        });


        setShowResendButton(false);
        setVerificationCode((response.result.update_number_verif_result.verif_code ?? '').toString());

    }

    const verifyCodes = async () => {

        if (showResendButton) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.titlesUpSendOtherCode);
            return false;
        }

        let input_codes = '';
        jQuery('.codes-input input').each(function () {
            input_codes += jQuery(this).val();
        });

        if (!Utilities.isEmpty(input_codes)) {
            if (input_codes.toString() === verificationCode.toString()) {
                handleClosePopup();
                props.submitNewMobileNumber();

            } else {
                Utilities.messagePopup('error', TitlesLabels.alertMessages.incorrectCode);
            }
        }


    }



    const resendPincodeConfirm = () => {
        confirmAlert({
            title: 'warning',
            message: TitlesLabels.confirmMessages.areYouSureResendCode,
            overlayClassName: 'normal-font-style warning',
            buttons: [
                { label: 'no', onClick: () => { } },
                { label: 'yes', onClick: () => resendPincode() }
            ]
        });

    }

    const timerCountDown = (action = 1) => {

        let countDownTemp = '05:00'; //countDown;
        if (action === 0) {
            stopTimer();
        }

        let timer_split = countDownTemp.split(":");
        let min_split = parseInt(timer_split[0]);
        let sec_split = parseInt(timer_split[1]);

        globalRecordTimer = setInterval(function () {

            sec_split = parseInt((sec_split === 0) ? 60 : sec_split);
            sec_split--;

            let sec_str = sec_split.toString();
            if (sec_str.length === 1) { sec_str = '0' + sec_str; }

            if (sec_split === 59) { min_split--; }

            let min_str = min_split.toString();
            if (min_str.length === 1) { min_str = '0' + min_str; }

            let timer_init = min_str + ':' + sec_str;
            setCountDown(timer_init);

            if (timer_init === '00:00') {
                stopTimer();
                clearInterval(globalRecordTimer);
                setShowResendButton(true);
                //Utilities.messagePopup('error', TitlesLabels.alertMessages.titlesUpSendOtherCode );
            }

            if (action === 0) {
                clearInterval(timerInterval);
                clearInterval(globalRecordTimer);
            }

        }, 1000);

        if (action === 0) {
            clearInterval(timerInterval);
            clearInterval(globalRecordTimer);
        } else {
            setTimerInterval(globalRecordTimer);
        }
    }


    const stopTimer = () => {
        setCountDown('00:00');
        clearInterval(timerInterval);
        clearInterval(globalRecordTimer);
    }


    const handleClosePopup = () => {
        props.handleClosePopup();
        setShowResendButton(false);
        setCodeResponse('');
        stopTimer();
        timerCountDown(0);
    }


    const formattedEmailMobileText = () => {

        let mobileNumberHiddenTemp = [];

        if (!Utilities.isEmpty(props_verificationParams.phone_number ?? '')) {
            let mobileNumber = props_verificationParams.phone_number;
            [...Array(mobileNumber.length).keys()].map(num => {
                mobileNumberHiddenTemp.push(parseInt(num) < (parseInt(mobileNumber.length) - 4) ? 'x' : mobileNumber[num]);
            });
        }
        setHiddenMobileNumber(mobileNumberHiddenTemp.join(''));

    }

    useEffect(() => {


        if (props.showPopup ?? true) {
            stopTimer();
            timerCountDown(0);
            timerCountDown();
            setShowResendButton(false);
            setCodeResponse('');
            setVerificationCode(props_verificationCode);
            formattedEmailMobileText();
        }


    }, [props]);

    return (
        <Modal id="security-mobile-verification-popup" show={props.showPopup} onHide={() => handleClosePopup()} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme">
            <Modal.Header closeButton className='border-0 '></Modal.Header>
            <Modal.Body className="px-5 pb-5 text-center  pt-0 mt-n2">

                <div className='px-3'>
                    <div className='font-gotham-bold fs-15 fs-md-22'>account verification</div>
                    <div className='mt-2 color-theme-grey'>please enter the 4-digit verification code sent to your mobile number +{props_verificationParams.phone_code ?? ''}{hiddenMobileNumber}</div>

                    <div className="login-form  text-lowercase form-theme ">

                        <input type="text" id="dummy" className="opacity-0" readOnly ></input>

                        <div className="codes-wrap py-4">
                            <div className="codes-input">
                                <input type="text" id="code_1" name="code[]" onKeyUp={(e) => codeFocus(e)} onClick={(e) => codeReset(e)} autoFocus autoComplete="new-password" className='border-0 bg-gray text-center mr-2 fs-25' style={inputPincodeStyle}></input>
                                <input type="text" name="code[]" onKeyDown={(e) => codeBack(e)} onKeyUp={(e) => codeFocus(e)} onClick={(e) => codeReset(e)} autoComplete="new-password" className='border-0 bg-gray text-center mr-2 fs-25' style={inputPincodeStyle}></input>
                                <input type="text" name="code[]" onKeyDown={(e) => codeBack(e)} onKeyUp={(e) => codeFocus(e)} onClick={(e) => codeReset(e)} autoComplete="new-password" className='border-0 bg-gray text-center mr-2 fs-25' style={inputPincodeStyle}></input>
                                <input type="text" name="code[]" onKeyDown={(e) => codeBack(e)} onKeyUp={(e) => codeFocus(e, 'last')} onClick={(e) => codeReset(e)} autoComplete="new-password" className='border-0 bg-gray text-center fs-25' style={inputPincodeStyle} ></input>
                            </div>
                        </div>


                        <div className='text-center col-md-9 mx-auto color-theme-grey fs-16'>
                            {
                                !showResendButton ?
                                    <div id="timer" className="timer-wrap">{countDown}</div>
                                    :
                                    <button type="button" id="btn-resend-code" className="btn-theme-black button-has-loader   mx-auto" onClick={() => resendPincode(true)}>resend</button>
                            }

                        </div>
                    </div>


                    {
                        !showResendButton &&
                        <div className='mt-5'>
                            <div className='color-theme-warning'>didn’t receive a code? <span className='text-black font-gotham-bold cursor-pointer' onClick={() => resendPincodeConfirm()}>resend</span></div>
                        </div>
                    }


                </div>

            </Modal.Body>


        </Modal>
    )
}

export default MobileAccountVerificationPopup;
