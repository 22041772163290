import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ScrollContainer from 'react-indiana-drag-scroll'
import moment from 'moment';

import PagesAuthentication from "../../helpers/PagesAuthentication";
import TitlesLabels from "../../helpers/TitlesLabels";
import { pageTitle, showDirectoryProfilePopup, showFooter, showHeader, showLoader, showMainSettings as showMainSettingsReducer, viewMenuPricePopup } from "../../actions";
import UrlSlugs from "../../helpers/UrlSlugs";
import HeaderDailyGlanceBlack from "./HeaderDailyGlanceBlack";
import Utilities from "../../helpers/Utilities";
import { DailyGlanceArticleItemTemplate, DailyGlanceArticleLoader, DailyGlanceCategoryLoader, DailyGlanceEventItemTemplate, DailyGlanceFeaturedItemTemplate, RegisterProfileItemTemplate, handleClickAds } from "./DailyGlanceArticleWidgets";
import { checkingFollowDailyGlanceStatus, getListByDate, getListDates, getNotificationListApi, glanceNotificationUnreadCounterApi } from "../../helpers/QueryDailyGlance";
import NoRecordAvailable from "../../components/common/NoRecordAvailable";
import { getListEvents } from "../../helpers/QueryDailyGlanceEvents";
import { getListFeatured } from "../../helpers/QueryDailyGlanceFeatured";
import { getCompanyNewRegistered } from "../../helpers/QueryDirectoryRegisteredNew";
import QueryMasterSearchUtlities from "../../helpers/QueryMasterSearchUtlities";
import { handleClickAdverstisement } from "../../helpers/UtilitiesDailyGlance";
import { DailyGlanceMainPopup, DailyGlancePremiumAdsPopup } from "./DailyGlancePopups";
import SessionUtilities from "../../helpers/SessionUtilities";


const DailyGlanceHome = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const APP_TITLE = process.env.REACT_APP_site_title;
    const ADS_CompanyId = process.env.REACT_APP_notification_glance_signup_company_id ?? '0';

    let globalArticleList = [],
        result_Db = {},
        passValues = {};

    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    const defaultLocation = Utilities.defaultLocation();
    const currentCityId = defaultLocation.city_id ?? 0;
    const currentAreaId = defaultLocation.area_id ?? 0;

    const [currentTimeStamp, setCurrentTimeStamp] = useState(Date.now());
    const [selectedArticle, setSelectedArticle] = useState({});
    const [showListLoader, setShowListLoader] = useState(true);
    const [showCategoryLoader, setShowCategoryLoader] = useState(true);
    const [articleList, setArticleList] = useState([]);
    const [articleListAll, setArticleListAll] = useState([]);

    const [eventList, setEventList] = useState([]);
    const [eventTotalPage, setEventTotalPage] = useState(0);
    const [featuredList, setFeaturedList] = useState([]);
    const [featuredTotalPage, setFeaturedTotalPage] = useState(0);
    const [registeredList, setRegisteredList] = useState([]);
    const [registeredTotal, setRegisteredTotal] = useState(0);
    const [signupsAdsImage, setSignupsAdsImage] = useState('');

    const [categoryList, setCategoryList] = useState([]);
    const [categorySelected, setCategorySelected] = useState({});
    const [dateSelected, setDateSelected] = useState('');
    const [flagType, setFlagType] = useState(1);

    const [showOptionPopup, setShowOptionPopup] = useState(false);
    const [notificationUnreadCounter, setNotificationUnreadCounter] = useState(0);

    const handleCloseOptionPopUp = () => {
        setShowOptionPopup(false);
    }

    const handleShowOptionPopUp = () => {
        setShowOptionPopup(true);
    }


    const handleClickEvents = async () => {
        setFlagType(2);
        setArticleList([]);
        setSelectedArticle({});

        if (Object.keys(eventList).length > 0) {
            globalArticleList = eventList;

            setArticleList(eventList);
            setSelectedArticle(eventList[0] ?? {});
        }

    }

    const handleClickSignups = () => {
        setFlagType(3);
        setArticleList([]);
        setSelectedArticle({});

        if (Object.keys(registeredList).length > 0) {

            const limitListSignUps = [];
            Object.entries(registeredList).map(([key, row]) => {
                if (parseInt(key) <= 4) {
                    limitListSignUps.push(row);
                }
            });

            globalArticleList = limitListSignUps;
            setArticleList(limitListSignUps);
            setSelectedArticle({
                ads_flag: '1',
                ads_company_id: ADS_CompanyId.toString(),
                ads_image: signupsAdsImage.toString()
            });

        }

    }


    const handleClickFeatured = async () => {
        setFlagType(4);
        setArticleList([]);
        setSelectedArticle({});

        if (Object.keys(featuredList).length > 0) {

            setArticleList(featuredList);
            setSelectedArticle(featuredList[0] ?? {});

        }

    }



    const handleClickCategory = (categoryDetail = categorySelected, dateChoosen = dateSelected) => {

        if (Object.keys(categoryDetail).length > 0) {
            setFlagType(1);
            setCategorySelected(categoryDetail);

            setArticleList([]);
            setSelectedArticle({});
            setShowListLoader(true);

            setTimeout(() => {
                setShowListLoader(false);

                const listByCategory = articleListAll.filter(row => parseInt(row.today_category_id ?? -2) === parseInt(categoryDetail.category_id ?? 0));

                globalArticleList = listByCategory;
                setArticleList(listByCategory);
                setSelectedArticle({});

                showAds();

            }, 500);
        }

    }


    const gettingArticles = async (datePicked = dateSelected) => {

        setArticleList([]);
        setSelectedArticle({});
        setShowListLoader(true);
        setShowCategoryLoader(true);

        const currentDate = moment().format(Utilities.dateGlanceFormatStr); // '2023-09-28';

        if (!Utilities.isEmpty(currentDate)) {

            setDateSelected(!Utilities.isEmpty(datePicked) ? datePicked : currentDate);

            result_Db = await getListByDate({ date_selected: currentDate ?? '' });
            if (parseInt(result_Db.status ?? 0) === 1) {

                const articleAll = result_Db.list ?? [];
                const categories = result_Db.categories ?? [];
                const categoryInfo = Object.keys(categories).length > 0 ? categories[0] : {};

                const listByCategory = articleAll.filter(row => parseInt(row.today_category_id ?? -2) === parseInt(categoryInfo.category_id ?? 0));

                globalArticleList = listByCategory;

                setArticleList(listByCategory);
                setArticleListAll(articleAll);

                setCategoryList(categories);
                setCategorySelected(categoryInfo);

                setSignupsAdsImage(result_Db.ads_signup_url ?? '');

                if (Object.keys(articleAll).length > 0) {
                    setSelectedArticle(articleAll[0]);
                }

                showAds();
            }
        }

        setShowListLoader(false);
        setShowCategoryLoader(false);
    }


    const showAds = () => {

        setTimeout(() => {

            let observer = new IntersectionObserver(handlerSetUpScrollEffect);
            Object.entries(globalArticleList).map(([key, row]) => {
                observer.observe(document.getElementById(`section-article-${row.id ?? 0}`));
            });

        }, 500);

    }


    const handlerSetUpScrollEffect = (entries, observer) => {
        for (let entry of entries) {
            //console.log(entry);
            const sectionId = entry.target.id;
            const articleKey = sectionId.split('-').slice(-1)[0] ?? 0;

            if (entry.isIntersecting) {
                console.log(`yes!! ${entry.target.id ?? ''}`);
                setSelectedArticle({});
                setCurrentTimeStamp(Date.now());

                const articleDetail = globalArticleList.filter(detail => parseInt(detail.id ?? 0) === parseInt(articleKey))[0] ?? {};
                if (Object.keys(articleDetail).length > 0) {
                    setSelectedArticle(articleDetail);
                }

            } else {
                //console.log(`no! ${entry.target.id??''}`);
            }
        }
    }

    const handleShowProfile = async (profile = {}) => {
        if (Object.keys(profile).length > 0) {
            dispatch(showLoader(1));
            const profileDetail = await QueryMasterSearchUtlities.getProfileDetail(profile.account_id ?? 0);
            dispatch(showDirectoryProfilePopup(profileDetail.list[profile.account_id.toString()]));

        }
    }


    const getEventsAndFeturedList = async () => {

        result_Db = await getListEvents({ view_all: 0 });
        if (parseInt(result_Db.status ?? 0) === 1) {

            if (parseInt(result_Db.count ?? 0) > 0) {
                setEventList(result_Db.list ?? []);
                setEventTotalPage(result_Db.page_last ?? 0);
            }
        }


        result_Db = await getListFeatured({ view_all: 0, per_page: 6 });
        if (parseInt(result_Db.status ?? 0) === 1) {

            if (parseInt(result_Db.count ?? 0) > 0) {
                setFeaturedList(result_Db.list ?? []);
                setFeaturedTotalPage(result_Db.page_last ?? 0);
            }
        }

        if (currentCityId > 0 && parseInt(profileLoggedin.account_id ?? 0) > 0) {
            const TOKEN_SESSION = SessionUtilities.getSessionToken();
            const passval = {
                api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
                account_id: profileLoggedin.account_id.toString(),
                city_id: (currentCityId ?? 0).toString(),
                area_id: (currentAreaId ?? 0).toString()
            }

            result_Db = await getCompanyNewRegistered(passval);
            if (Object.keys(result_Db).length > 0) {
                setRegisteredList(result_Db ?? []);
                setRegisteredTotal(Object.keys(result_Db ?? []).length);
            }

        }


    }



    const handleClickCategorySelection = async (categoryInfo = {}) => {
        if (Object.keys(categoryInfo).length > 0) {

            const listByCategory = articleListAll.filter(row => parseInt(row.today_category_id ?? -2) === parseInt(categoryInfo.category_id ?? 0));

            if (Object.keys(listByCategory).length > 0) {
                const detail = listByCategory[0];
                const readMoreUrl = `/${UrlSlugs.notificationDailyGlanceArticle}/${btoa(detail.id)}/${btoa(detail.date ?? '')}`;
                history.push(readMoreUrl);
            }

        }
    }

    const getNotificationUnreadCounter = async () => {
        //console.log("getNotificationList");
        const responseCounter = await glanceNotificationUnreadCounterApi(profileLoggedin.account_id);
        setNotificationUnreadCounter(parseInt(responseCounter ?? 0));
    }

    useEffect(async () => {

        PagesAuthentication.getUserLoggedin();

        dispatch(pageTitle(`${TitlesLabels.general.atAGlance}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        const glanceStatus = await checkingFollowDailyGlanceStatus();
        if (!glanceStatus) {
            history.push(`/${UrlSlugs.settings_notifications}`);
            return false;
        }


        await getEventsAndFeturedList();
        await gettingArticles();
        await getNotificationUnreadCounter();

    }, []);



    return (
        <>
            <div className="header-daily-glance">

                <div className="breadcrumbs  breadcrumbs-chat">
                    <div className="container breadcrumbs-content">
                        <ul className="breadcrumbs-nav">
                            <li></li>
                            <li className="btn-option">

                                <Link to={`/${UrlSlugs.glanceNotification}`} className="btn-icon btn-bell" title={TitlesLabels.general.notifications}>
                                    {parseInt(notificationUnreadCounter) > 0 && <span className="counter">{notificationUnreadCounter}</span>}
                                </Link>
                                <div className="btn-icon btn-icon btn-menu-two" title={TitlesLabels.navigations.categories} onClick={() => handleShowOptionPopUp()}></div>
                                <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>

                            </li>
                        </ul>
                    </div>

                </div>

                <HeaderDailyGlanceBlack />

                <div className="main-content position-relative">
                    <div className="container pt-4">

                        <div className="col-lg-10 mx-auto">

                            {
                                showCategoryLoader ?
                                    <div className="d-flex align-center justify-content-center mb-2">
                                        <DailyGlanceCategoryLoader />
                                    </div>
                                    :
                                    Object.keys(categoryList).length > 0 &&

                                    <ScrollContainer className="text-center mb-2" activationDistance={5}>
                                        <ul className="ul-category-selection fs-20 font-gotham-bold text-lowercase">
                                            {
                                                Object.entries(categoryList).map(([key, row]) =>
                                                    <li
                                                        key={key}
                                                        className={`${parseInt(categorySelected.category_id ?? -2) === parseInt(row.category_id ?? 0) && flagType === 1 ? 'selected' : ''}`}
                                                        onClick={() => handleClickCategory(row)}
                                                    >{parseInt(row.category_id ?? -1) === -1 ? 'today' : (row.category_name ?? '')}</li>
                                                )
                                            }
                                            {
                                                parseInt(eventTotalPage ?? 0) > 0 &&
                                                <li
                                                    onClick={() => handleClickEvents()}
                                                    className={`${flagType === 2 ? 'selected' : ''}`}
                                                >events</li>
                                            }

                                            {
                                                parseInt(registeredTotal ?? 0) > 0 &&
                                                <li
                                                    onClick={() => handleClickSignups()}
                                                    className={`${flagType === 3 ? 'selected' : ''}`}
                                                >{TitlesLabels.general.signUps ?? ''}</li>
                                            }


                                            {
                                                parseInt(featuredTotalPage ?? 0) > 0 &&
                                                <li
                                                    onClick={() => handleClickFeatured()}
                                                    className={`${flagType === 4 ? 'selected' : ''}`}
                                                >featured articles</li>
                                            }

                                        </ul>
                                    </ScrollContainer >
                            }


                            <div className="article-main-content mt-4">

                                <div id="section-article-list" className="section-articles fs-18">

                                    {
                                        showListLoader ?
                                            <DailyGlanceArticleLoader />
                                            :
                                            <>
                                                {
                                                    Object.keys(articleList).length === 0 ?
                                                        <NoRecordAvailable />
                                                        :
                                                        <>
                                                            {
                                                                flagType === 1 ?
                                                                    Object.entries(articleList).map(([key, row]) =>
                                                                        <section key={key} id={`section-article-${row.id ?? '0'}`} className="section-article">
                                                                            <DailyGlanceArticleItemTemplate detail={row} key={key} />
                                                                        </section>
                                                                    )
                                                                    : flagType == 2 ?
                                                                        <section className="section-article">
                                                                            <ul className="ul-global-list ul-dglance-list cursor-pointer text-lowercase font-gotham-book fs-14 fs-md-14">
                                                                                {
                                                                                    Object.entries(articleList).map(([key, row]) =>
                                                                                        <DailyGlanceEventItemTemplate detail={row} displayType={`list`} key={key} dateSelected={dateSelected ?? ''} />
                                                                                    )
                                                                                }
                                                                            </ul>
                                                                            {
                                                                                parseInt(eventTotalPage ?? 0) > 1 &&
                                                                                <div className="pt-2 d-flex align-items-center justify-content-center">
                                                                                    <Link to={`/${UrlSlugs.notificationDailyGlanceEvents}/${btoa(dateSelected ?? '')}`}>more</Link>
                                                                                </div>
                                                                            }
                                                                        </section>
                                                                        : flagType == 3 ?
                                                                            <section className="section-article text-lowercase ">
                                                                                <div className="fs-20 font-gotham-bold border-bottom pb-3 border-theme-gray">we’re growing! see who’s joined the {APP_TITLE ?? ''} family…</div>

                                                                                <div className=" cursor-pointer text-lowercase font-gotham-book fs-14 fs-md-14">
                                                                                    <ul className="ul-global-list">
                                                                                        {
                                                                                            Object.entries(articleList).map(([key, row]) =>
                                                                                                <RegisterProfileItemTemplate detail={row} dateSelected={dateSelected ?? ''} handleShowProfile={handleShowProfile} />
                                                                                            )
                                                                                        }
                                                                                    </ul>
                                                                                </div>

                                                                                {
                                                                                    parseInt(registeredTotal ?? 0) > 5 &&
                                                                                    <div className="pt-2 d-flex align-items-center justify-content-center">
                                                                                        <Link to={`/${UrlSlugs.notificationDailyGlanceSignUps}/${btoa(dateSelected ?? '')}`}>more</Link>
                                                                                    </div>
                                                                                }
                                                                            </section>
                                                                            : flagType == 4 ?
                                                                                <section className="section-article">

                                                                                    <ul className="ul-global-list   cursor-pointer text-lowercase font-gotham-book fs-14 fs-md-14">
                                                                                        {
                                                                                            Object.entries(articleList).map(([key, row]) =>
                                                                                                <DailyGlanceFeaturedItemTemplate detail={row} displayType={`list`} key={key} dateSelected={dateSelected ?? ''} />
                                                                                            )
                                                                                        }
                                                                                    </ul>
                                                                                    {
                                                                                        parseInt(featuredTotalPage ?? 0) > 1 &&
                                                                                        <div className="pt-2 d-flex align-items-center justify-content-center">
                                                                                            <Link to={`/${UrlSlugs.notificationDailyGlanceFeatured}/${btoa(dateSelected.date ?? '')}`}>more</Link>
                                                                                        </div>
                                                                                    }
                                                                                </section>
                                                                                : <></>

                                                            }
                                                        </>

                                                }
                                            </>

                                    }
                                </div>

                                {
                                    showListLoader ?
                                        <div className="global-preloader bg-grey"></div>
                                        :

                                        Object.entries(articleList).map(([key, row]) =>
                                            <div className={`ads-image ${(parseInt(selectedArticle.id ?? -1)) == parseInt(row.id) ? '' : 'd-none'}`} key={key} id={`section-article-${row.id ?? '0'}`} onClick={() => handleClickAdverstisement(row, 1, dispatch)}><img src={!Utilities.isEmpty(row.ads_image ?? '') ? row.ads_image + '?lastmod=' + currentTimeStamp : ''}  className="h-100"/></div>)

                                }

                            </div>

                        </div>

                    </div>
                </div>

            </div>


            <DailyGlanceMainPopup showPopup={showOptionPopup} handleClosePopUp={handleCloseOptionPopUp} listCategories={categoryList} totalPageEvent={eventTotalPage} totalPageFeatured={featuredTotalPage} totalRegistered={registeredTotal} selectedCategory={categorySelected} selectedDate={btoa(dateSelected ?? '')} handleClickCategorySelection={handleClickCategorySelection} />

            <DailyGlancePremiumAdsPopup />

        </>

    )
}

export default DailyGlanceHome;
