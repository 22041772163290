import axios from 'axios';
import ApiUrls from './ApiUrls';

//const API_PLUS_TOKEN = process.env.REACT_APP_chat_api_token;

export const getCampainDetail = async (passval = {}) => {
    let responseDetail = { status: -1 };

    if (Object.entries(passval).length > 0) {
        try {
            //console.log(ApiUrls.getCampaignDetail , passval);
            const responseURL = await axios.post(ApiUrls.getCampaignDetail, passval);
            //console.log(responseURL);

            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const updateClicksAccount = async (passval = {}) => {
    let responseDetail = { status: -1 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.campaignUpdateClicksAccount, passval);


            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}