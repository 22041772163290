import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

import PagesAuthentication from '../../helpers/PagesAuthentication';
import TitlesLabels from '../../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer, showLoader, showPopupSocialMediaLibrary as showPopupSocialMediaLibraryReducer } from '../../actions';
import UrlSlugs from '../../helpers/UrlSlugs';

import BranchCompanyInformation from './BranchCompanyInformation';
import CompanyBranchList from './CompanyBranchList';
import { getCompanyBranchesByIds } from '../../helpers/QueryCompanyBranch';

const CompanyTabOption = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const tabOptionArray = { 1: 'company info', 2: 'branch info' };
    let { typename, branchId } = useParams();
    branchId = (branchId ?? '0') !== '0' ? atob(branchId) : '0';

    const activeOptionDefault = parseInt(typename === `informtion-detail` ? 1 : 2);

    const [loginDetail, setLoginDetail] = useState({});
    const [activeOption, setActiveOption] = useState(activeOptionDefault);
    const [mainBranchDetail, setMainBranchDetail] = useState({});
    const [showSocialOptionBool, setShowSocialOptionBool] = useState(false);

    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }

    const handleOnClickTabOption = (tabId = 1) => {

        const editId = parseInt(branchId) > 0 ? `/${btoa(branchId)}` : '';

        if (tabId === 1) {
            history.push(`/${UrlSlugs.companyBranch_informatioDetail}${editId}`);
        } else if (tabId === 2) {

            if (parseInt(branchId) > 0) {
                history.push(`/${UrlSlugs.companyBranch_manageBranch}${editId}`);
            }
        }
    }


    const updateSocialOptionManage = (statusBool = false) => {
        setShowSocialOptionBool(statusBool);
    }


    const tabContentHtml = () => {
        let htmlReturn = '';

        if (activeOption === 1) {
            htmlReturn = <BranchCompanyInformation loginDetail={loginDetail} branchId={branchId} mainBranchDetail={mainBranchDetail} updateSocialOptionManage={updateSocialOptionManage} />

        } else if (activeOption === 2) {
            htmlReturn = <CompanyBranchList loginDetail={loginDetail} branchId={branchId} mainBranchDetail={mainBranchDetail} />;

        }

        return htmlReturn;
    }


    const setupMainBranchDetail = async (bId = 0) => {
        if (parseInt(bId) > 0) {

            dispatch(showLoader(1));
            const responseBranchList = await getCompanyBranchesByIds(bId.toString());
            dispatch(showLoader(0));

            if (parseInt(responseBranchList.status) === 0) {
                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.alertMessages.noRecordFound,
                    overlayClassName: 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => redirectToHome() }
                    ],
                    onClickOutside: () => redirectToHome(),
                    onKeypressEscape: () => redirectToHome(),
                });

                return false;
            }

            setMainBranchDetail(responseBranchList);
        }
    }

    const redirectToHome = () => {
        history.push(`/${UrlSlugs.dashboard}`);
    }


    const redirectNewBranch = () => {
        if (parseInt(branchId) > 0) {
            history.push(`/${UrlSlugs.companyBranch_companyForm}/${btoa(branchId)}`);
        }
    }


    useEffect(() => {

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.branchCompanyInfo}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        setLoginDetail(PagesAuthentication.getMasterUserDetailDetail())

    }, []);


    useEffect(() => {
        setActiveOption(activeOptionDefault);
    }, [typename]);


    useEffect(() => {
        if (parseInt(branchId) > 0) {
            setupMainBranchDetail(branchId);
        }
    }, [branchId]);


    return (
        <div className="profile-security-page chat-template-messages-content">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => triggerSetting()}>{TitlesLabels.settings}</div>
                            <div>{TitlesLabels.general.profiles}</div>
                            <div>{TitlesLabels.navigations.manageCompany}</div>
                            <div className={`${!showSocialOptionBool ? 'current' : ''}`}>{TitlesLabels.navigations.branchInfo}</div>
                            {
                                showSocialOptionBool &&
                                <div className="current">{TitlesLabels.navigations.socialMediaLibrary}</div>
                            }
                        </li>
                        <li className="btn-option">
                            {
                                showSocialOptionBool &&
                                <div className="btn-icon btn-plus" onClick={() => dispatch(showPopupSocialMediaLibraryReducer(true))} title={TitlesLabels.navigations.addSocial}></div>
                            }
                            {activeOption === 2 && <div className="btn-icon btn-plus cursor-pointer" onClick={() => redirectNewBranch()}></div>}
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()}></div>
                        </li>
                    </ul>
                </div>
            </div>


            <div className="main-content">
                <div className="container">


                    <div className="col-md-7 mx-auto form-col-2  fs-18 pt-4">
                        {
                            Object.entries(tabOptionArray).map(([key, labelName]) =>
                                <div key={key} className={` py-3 text-center ${parseInt(key) === activeOption ? 'font-gotham-bold border-bottom border-theme-colored border-theme-width-2' : 'color-theme-grey'} ${parseInt(key) === 2 ? (parseInt(branchId) === 0 ? 'cursor-notallow' : 'cursor-pointer') : 'cursor-pointer'} `} onClick={(e) => handleOnClickTabOption(parseInt(key))} >{labelName}</div>
                            )
                        }
                    </div>

                    <div className={`col-md-${activeOption === 1 ? '11' : ''} mx-auto mt-3`}>
                        <div className=" text-lowercase form-theme mt-5 px-5">
                            {tabContentHtml()}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CompanyTabOption;