import axios from 'axios';
import ApiUrls from './ApiUrls';


export const getMainCounters = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchMainCounters, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getCategoryCompanyCounters = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchCategoryCompanyCounters, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getPofilesByIds = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchProfilesByIds, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getProfileByLocation = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            //console.log(ApiUrls.directorySearchProfilesByLocation, passval);
            const responseURL = await axios.post(ApiUrls.directorySearchProfilesByLocation, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getSuburbListByCity = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchSuburbByCity, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getStatesByCountry = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchStatesByCountry, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}



export const getCitiesByState = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            console.log(ApiUrls.directorySearchCitiesByState, passval);
            const responseURL = await axios.post(ApiUrls.directorySearchCitiesByState, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}



export const getCountriesProfiles = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchCountriesProfiles, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}



export const getCategoriesByKeyword = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.mainCategorySearchByKeywords, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}



export const getCategoryDetailById = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.mainCategoryGetSubCategoriesByParentId, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getPersonalProfilesByEmail = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchPersonalEmails, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}

