import axios from 'axios';
import ApiUrls from './ApiUrls';


export const getListEvents = async (passval = {}) => {

    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.getDailyGlanceListIncomingEvents, passval);
            if (parseInt(responseURL.status) === 200) {
                if (parseInt(responseURL.data.status) === 1) {
                    responseDetail = { ...responseDetail, status: 1, ...responseURL.data };
                }
            }

        } catch (e) { }
    }

    return responseDetail;

}
