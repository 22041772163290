import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios'

import PagesAuthentication from '../helpers/PagesAuthentication';
import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';
import ConnectTeamMember from '../components/popups/ConnectTeamMember';
import ChatUtilities from '../helpers/ChatUtilities';
import QueryUtilities from '../helpers/QueryUtilities';
import CommonProfileLogo from '../components/common/CommonProfileLogo';
import DashboardProfileSearch from '../components/common/DashboardProfileSearch';


const TeamMembers = ({ match }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const clsParamTeamId = match.params.teamid ?? '';

    const [listProfiles, setListProfiles] = useState([]);
    const [companyProfile, setCompanyProfile] = useState([]);
    //const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [showEmpty, setShowEmpty] = useState(false);
    const [contentLoaderClass, setContentLoaderClass] = useState('animate');
    const [loggedInProfile, setLoggedInProfile] = useState({});
    const [profileSelected, setProfileSelected] = useState({});

    // MODAL
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (profile) => {
        setProfileSelected(profile);
        setShow(true);
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            //setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }


    const resetSearchValue = () => {
        //setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const toggleMainSearch = (action = 0) => {
        //dispatch( showMainSearch(action) );
        history.push(`/${UrlSlugs.page_directorySearch}/${btoa(action)}`);
    }

    const getMembers = async (accountID) => {

        if (parseInt(accountID) > 0) {
            let teamId = 0, memberList = [], teamDetail = {}, passval = { company_id: accountID, show_company: 1, show_company_address: 1, listing_status: 1 };


            await axios
                .post(ApiUrls.teamList, passval)
                .then(response => {

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus === 1) {
                            if (Utilities.isEmpty(dataResponse.list)) {
                                setShowEmpty(true);

                            } else {
                                setShowEmpty(false);

                                Object.entries(dataResponse.list).map(([id, memberdetail]) => {
                                    memberList = [...memberList, Utilities.formatProfileDetail(memberdetail)];
                                });

                                setListProfiles(memberList);

                                if (!Utilities.isEmpty(clsParamTeamId)) {
                                    teamId = parseInt(atob(clsParamTeamId));

                                    if (teamId > 0) {
                                        teamDetail = memberList.filter(row => parseInt(row.account_id) === teamId)[0] ?? {};
                                        if (Object.keys(teamDetail).length > 0) {

                                            handleShow(teamDetail);
                                            history.push(`/${UrlSlugs.teamMembers}/${btoa(accountID)}`);
                                        }
                                    }
                                }

                            }
                        } else {
                            setShowEmpty(true);
                        }

                        setCompanyProfile(dataResponse.company_detail);
                        dispatch(pageTitle(`${TitlesLabels.siteTitles.teamMembers} | ${dataResponse.company_detail.company_name}`));

                    }

                    setContentLoaderClass('');
                })
                .catch((err) => {
                    setShowEmpty(true);
                    setContentLoaderClass('');
                });
        }

    }


    const showChat = async (profile) => {

        const button_name = 'btn-confirm-chat';
        Utilities.elementDisabledStatus(button_name, true);

        const contactRequestValues = ChatUtilities.contactRequest(loggedInProfile, profile, 0, 0);
        const conversationDetailRes = await QueryUtilities.getConversationDetail(contactRequestValues.url, contactRequestValues.values);
        const conversation = conversationDetailRes.result.conversation_details ?? {};

        Utilities.elementDisabledStatus(button_name, false);

        if (Object.entries(conversation).length > 0) {
            let chatPassValue = ChatUtilities.encryptChatDetail(conversation);

            ChatUtilities.saveAccountsConversationDetail(conversation, 'page - team list');
            history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation.account_id))}/${chatPassValue}`);
        }

        //history.push(`/${UrlSlugs.chat}/${btoa( parseInt(profile.account_id) )}`);
    }


    useEffect(async () => {

        let reqID = match.params.id;
        if (typeof reqID !== 'undefined') {
            reqID = atob(reqID);

            if (parseInt(reqID) > 0) {

                setContentLoaderClass('animate');
                setListProfiles([]);

                await getMembers(reqID);
            }
        }

    }, [match.params.id]);


    useEffect(() => {
        PagesAuthentication.getUserLoggedin();

        let loginType = parseInt(localStorage.getItem(process.env.REACT_APP_session_logintype) ?? 1);

        dispatch(showHeader(loginType === 2 ? 5 : 1));
        dispatch(showFooter(0));

        const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();
        setLoggedInProfile(userLoginDetail ?? {});

    }, []);

    return (
        <div className="team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className="cursor-pointer" onClick={() => toggleMainSearch(1)}>{TitlesLabels.navigations.search}</div>
                            {companyProfile.hasOwnProperty('company_name') && <div>{companyProfile.company_name}</div>}
                            <div className="current">{TitlesLabels.navigations.teamMembers}</div>
                        </li>
                        <li className="btn-option"> </li>
                    </ul>
                </div>
            </div>

            <div className="main-content">

                {
                    Object.keys(listProfiles ?? []).length > 0 &&
                    <div className="content-subnav-wrap profile-search-wrap  py-4">
                        <div className="container px-5">
                            <div className="profile-search-wrap bg-white d-flex align-items-center form-theme  w-100 border-radius">
                                <DashboardProfileSearch pushValue={pushValue} resetSearchValue={resetSearchValue} />
                                {/* <div className='form-group mb-0 border-0  w-100 '>
                                        <input type="text" name="input_name" id="input_name" className="form-input input-search" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="type here" autoComplete='off' />
                                    </div>
                                    { ! Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={ () => resetSearchValue() }>clear</span></div> } */}
                            </div>

                        </div>
                    </div>
                }

                {!Utilities.isEmpty(contentLoaderClass) && <div className="content-subnav-wrap profile-search-wrap  py-4"></div>}

                <div className="container pt-4">

                    {
                        !Utilities.isEmpty(contentLoaderClass) &&
                        <ul className="ul-global-list px-5 mt-4">
                            {
                                [...Array(6).keys()].map((key) =>
                                    <li key={key} className="global-preloader  py-2">
                                        <div className="logo-size-60 bg-gray logo-size-md-60 border-0"></div>
                                        <div>
                                            <div className="bg-gray col-4 pt-3 border-radius-loader"></div>
                                            <div className="mt-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    }

                    {
                        Object.entries(listProfiles).length > 0 &&
                        <ul id="listing-profiles" className={`listing-profiles ul-global-list text-lowercase my-4 px-5 ${Object.keys(listProfiles).length === 0 ? 'd-none' : ''} `}>
                            {
                                Object.entries(listProfiles).map(([key, row]) =>
                                    <li key={key} data-keyword={row.name} onClick={() => handleShow(row)} className='cursor-pointer'>
                                        {/*  <div className={`profile-image fs-22-important logo-size-60 logo-size-md-60`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url)  }}>{ Utilities.showHideInitial(row.logo_url, row.name) }</div> */}
                                        <CommonProfileLogo classAttr={`profile-image fs-22 fs-md-16 logo-size-60 logo-size-md-60`} profileDetail={row} />

                                        <div className='line-height-1'>
                                            <div className="profile-name font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1 notranslate" data-name={row.name}>{row.name} </div>
                                            <div className="profile-label font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.team_position}</div>
                                        </div>

                                    </li>
                                )
                            }
                        </ul>
                    }

                    {showEmpty && <div className="text-center text-lowercase no-record-label mt-5">{Utilities.labelTeamAvailable}</div>}

                </div>
            </div>

            <ConnectTeamMember show={show} handleClose={handleClose} profileSelected={profileSelected} companyProfile={companyProfile} showChat={showChat} />


        </div>
    )
}

export default TeamMembers;