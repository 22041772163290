import axios from 'axios';
import ApiUrls from './ApiUrls';


export const checkPermissionToRegisterCompany = async (passval = {}) => {
    let responseDetail = '0';

    if (Object.entries(passval).length > 0) {
        try {
            //console.log('checkPermissionToRegisterCompany');
            //console.log(ApiUrls.checkPermissionToRegisterCompany);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.checkPermissionToRegisterCompany, passval);
            //console.log(responseURL);
            if (parseInt(responseURL.status) === 201) {
                responseDetail = responseURL.data.result.create_company_request_status ?? '0';
            }

        } catch (e) {
            console.log(e);
        }
    }
    
    return responseDetail;
}