import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import jQuery from 'jquery';


import PagesAuthentication from "../../helpers/PagesAuthentication";
import TitlesLabels from "../../helpers/TitlesLabels";
import { pageTitle, showFooter, showHeader, showMainSettings as showMainSettingsReducer } from "../../actions";
import UrlSlugs from "../../helpers/UrlSlugs";


import { DailyGlanceArticleItemTemplate, DailyGlanceArticleLoader } from "./DailyGlanceArticleWidgets";
import Utilities from "../../helpers/Utilities";
import { checkingFollowDailyGlanceStatus, getListByDate } from "../../helpers/QueryDailyGlance";
import { getCompanyNewRegistered } from "../../helpers/QueryDirectoryRegisteredNew";
import { getListEvents } from "../../helpers/QueryDailyGlanceEvents";
import { getListFeatured } from "../../helpers/QueryDailyGlanceFeatured";
import { handleClickAdverstisement } from "../../helpers/UtilitiesDailyGlance";
import { DailyGlanceMainPopup } from "./DailyGlancePopups";
import SessionUtilities from "../../helpers/SessionUtilities";


const DailyGlanceArticle = ({ match }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    let widthArticle = 950, result_Db = {};


    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    const defaultLocation = Utilities.defaultLocation();
    const currentCityId = defaultLocation.city_id ?? 0;
    const currentAreaId = defaultLocation.area_id ?? 0;


    const [showListLoader, setShowListLoader] = useState(true);
    const [delayLoader, setDelayLoader] = useState(false);
    const [articleList, setArticleList] = useState([]);
    const [articleListAll, setArticleListAll] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryDetailSelected, setCategoryDetailSelected] = useState({});
    const [dateSelected, setDateSelected] = useState({});
    const [articleWContainer, setArticleWContainer] = useState(widthArticle);
    const [registeredTotal, setRegisteredTotal] = useState(0);

    const [eventTotalPage, setEventTotalPage] = useState(0);
    const [featuredTotalPage, setFeaturedTotalPage] = useState(0);

    const [showOptionPopup, setShowOptionPopup] = useState(false);


    const handleCloseOptionPopUp = () => {
        setShowOptionPopup(false);
    }

    const handleShowOptionPopUp = () => {
        setShowOptionPopup(true);
    }



    const gettingArticles = async (articleId = 0, articleDate = dateSelected, categInfo = categoryDetailSelected) => {
        articleId = parseInt(articleId);
        let articleIndex = 0;

        //if (!Utilities.isEmpty(articleDate)) {

        setArticleList([]);
        setShowListLoader(true);
        setDelayLoader(false);

        const currentDate = moment().format(Utilities.dateGlanceFormatStr); // '2023-09-28';

        result_Db = await getListByDate({ date_selected: currentDate ?? '' });
        setShowListLoader(false);

        if (parseInt(result_Db.status ?? 0) === 1) {
            setDelayLoader(true);

            const articleAll = result_Db.list ?? [];
            const categories = result_Db.categories ?? [];

            let categoryInfo = categInfo;

            if (Object.keys(categInfo).length === 0) {
                categoryInfo = articleAll.filter(row =>
                    (row.id ?? '').toString() === (articleId ?? '').toString()
                )[0];
            }

            const listByCategory = articleAll.filter(row => parseInt(row.today_category_id ?? -2) === parseInt(categoryInfo.today_category_id ?? 0));

            Object.entries(listByCategory).map(([key, row]) => {
                if ((row.id ?? '').toString() === (articleId ?? '').toString()) {
                    articleIndex = key;
                }
            });

            setArticleListAll(articleAll);
            setArticleList(listByCategory);
            setCategoryList(categories);
            setCategoryDetailSelected({
                category_id: categoryInfo.today_category_id ?? '',
                category_name: categoryInfo.today_category_name ?? ''
            });

            settingUpItems(articleIndex);

        }

        //}

    }

    const gettingRegisteredCounter = async () => {
        if (currentCityId > 0 && parseInt(profileLoggedin.account_id ?? 0) > 0) {
            const TOKEN_SESSION = SessionUtilities.getSessionToken();
            const passval = {
                api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
                account_id: profileLoggedin.account_id.toString(),
                city_id: (currentCityId ?? 0).toString(),
                area_id: (currentAreaId ?? 0).toString()
            }

            result_Db = await getCompanyNewRegistered(passval);
            if (Object.keys(result_Db).length > 0) {
                setRegisteredTotal(Object.keys(result_Db ?? []).length);
            }

        }
    }


    const redrectToGlanceHome = () => {
        history.push(`/${UrlSlugs.settings_notifications}`);
    }

    const settingUpItems = (indexDefault = 0) => {
        indexDefault = parseInt(indexDefault);

        //article-main-content
        let widthArticleContent = jQuery('#article-main-content').width();
        widthArticleContent = parseInt(widthArticleContent);
        setArticleWContainer(widthArticleContent);


        const slider = document.querySelector('.slider-items');
        let isDown = false;
        let startX;
        let scrollLeft;

        setTimeout(() => {

            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                //console.log(walk);
                //console.log(x);
            });

            // set index
            if (indexDefault >= 0) {
                slider.scrollLeft = widthArticleContent * indexDefault;
            }

            setDelayLoader(false);

        }, 1000);

    }


    const getEventsAndFeturedList = async () => {

        result_Db = await getListEvents({ view_all: 0 });
        if (parseInt(result_Db.status ?? 0) === 1) {

            if (parseInt(result_Db.count ?? 0) > 0) {
                setEventTotalPage(result_Db.page_last ?? 0);
            }
        }


        result_Db = await getListFeatured({ view_all: 0, per_page: 6 });
        if (parseInt(result_Db.status ?? 0) === 1) {

            if (parseInt(result_Db.count ?? 0) > 0) {
                setFeaturedTotalPage(result_Db.page_last ?? 0);
            }
        }

    }

    const handleClickCategorySelection = async (categoryInfo = {}) => {
        if (Object.keys(categoryInfo).length > 0) {
            const listByCategory = articleListAll.filter(row => parseInt(row.today_category_id ?? -2) === parseInt(categoryInfo.category_id ?? 0));

            if (Object.keys(listByCategory).length > 0) {
                const detail = listByCategory[0];
                const readMoreUrl = `/${UrlSlugs.notificationDailyGlanceArticle}/${btoa(detail.id)}/${btoa(detail.date ?? '')}`;
                history.push(readMoreUrl);
            }

        }
    }


    useEffect(async () => {

        PagesAuthentication.getUserLoggedin();

        dispatch(pageTitle(`${TitlesLabels.general.atAGlance}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        const glanceStatus = await checkingFollowDailyGlanceStatus();
        if (!glanceStatus) {
            redrectToGlanceHome();
        }

    }, []);


    useEffect(async () => {

        handleCloseOptionPopUp();

        let articleIdParam = match.params.articleid ?? '',
            articleDateParam = match.params.dateYYmmdd ?? '';
        if (!Utilities.isEmpty(articleIdParam) && !Utilities.isEmpty(articleDateParam)) {

            try {

                articleIdParam = atob(articleIdParam);
                articleDateParam = atob(articleDateParam);

                if (parseInt(articleIdParam ?? 0) > 0) {
                    setDateSelected(articleDateParam);

                    await gettingArticles(articleIdParam, articleDateParam, {});
                    await gettingRegisteredCounter();
                    await getEventsAndFeturedList();

                } else {
                    redrectToGlanceHome();
                }

            } catch (e) {
                redrectToGlanceHome();
            }
        } else {
            redrectToGlanceHome();
        }

    }, [match.params.articleid]);



    return (
        <>
            <div className="header-daily-glance">

                <div className="breadcrumbs  breadcrumbs-chat">
                    <div className="container breadcrumbs-content">
                        <ul className="breadcrumbs-nav">
                            <li className="btn-option">
                                <div onClick={() => history.goBack()} className="btn-icon btn-arrow-left" title={TitlesLabels.general.atAGlance}></div>
                                <Link to={`/${UrlSlugs.notificationDailyGlance}`}>{TitlesLabels.general.atAGlance}</Link>
                                <div className="current">{parseInt(categoryDetailSelected.category_id ?? -1) === -1 ? 'today' : categoryDetailSelected.category_name ?? ''}</div>
                            </li>
                            <li className="btn-option">

                                <div className="btn-icon btn-icon btn-menu-two" title={TitlesLabels.navigations.categories} onClick={() => handleShowOptionPopUp()}></div>
                                <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>

                            </li>
                        </ul>
                    </div>

                </div>

                <div className="main-content position-relative">
                    <div className="container pt-5">

                        <div id="article-main-content" className="col-lg-11 mx-auto" >
                            {
                                delayLoader &&
                                <>
                                    <DailyGlanceArticleLoader />
                                    <div className="ads-image mt-5 global-preloader bg-grey-important"></div>
                                </>
                            }

                            {
                                showListLoader ?
                                    <>
                                        <DailyGlanceArticleLoader />
                                        <div className="ads-image mt-5 global-preloader bg-grey-important"></div>
                                    </>
                                    :
                                    <div className="slider-items" style={{ gridTemplateColumns: `repeat(${articleList.length}, ${articleWContainer}px)`, width: `${articleWContainer}px`, opacity: delayLoader ? 0 : 1 }}>
                                        {
                                            Object.entries(articleList).map(([key, row]) =>
                                                <div className="slide-item  px-4" style={{ width: `${articleWContainer}px` }} key={key}>
                                                    <div className=" article-content-description">
                                                        <DailyGlanceArticleItemTemplate detail={row} readMore={false} showSlider={true} />
                                                    </div>

                                                    <div className="ads-image mt-5" style={{ backgroundImage: `url('${row.ads_image ?? ''}')` }} onClick={() => handleClickAdverstisement(row, 1, dispatch)}></div>
                                                </div>
                                            )
                                        }
                                    </div>
                            }



                        </div>

                    </div>
                </div>

            </div>

            <DailyGlanceMainPopup showPopup={showOptionPopup} handleClosePopUp={handleCloseOptionPopUp} listCategories={categoryList} totalPageEvent={eventTotalPage} totalPageFeatured={featuredTotalPage} totalRegistered={registeredTotal} selectedCategory={categoryDetailSelected} selectedDate={btoa(dateSelected ?? '')} handleClickCategorySelection={handleClickCategorySelection} />

        </>

    )
}

export default DailyGlanceArticle;
