import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DirectorySearchNoRecord from './DirectorySearchNoRecord';
import DirectorySearchKeywordsTemplate from './DirectorySearchKeywordsTemplate';
import DirectorySearchLoaderProfile from './DirectorySearchLoaderProfile';
import Utilities from '../../helpers/Utilities';
import DirectorySearchBackNavigation from './DirectorySearchBackNavigation';
import { getPofilesByIds } from '../../helpers/QueryDirectorySearch';
import DirectorySearchProfileListing from './DirectorySearchProfileListing';
import { showLoader } from '../../actions';

const DirectorySearchKeywordsListing = (props) => {

    const dispatch = useDispatch();

    let listResultProfiles = [], dataRequest = {}, resDb_listResult = {};

    const listWords = props.listResults.list_words ?? [];
    const locationTabId = props.locationTabId ?? 2;
    const profileCount = props.listResults.profile_count ?? 0;
    const profileIds = props.listResults.profile_ids ?? 0;
    const enableSortDistance = props.enableSortDistance ?? false;


    const [steps, setSteps] = useState(0);
    const [wordSelected, setWordSelected] = useState({});

    const [boolShowProfileResult, setBoolShowProfileResult] = useState(false);
    const [boolShowLoading, setBoolShowLoading] = useState(false);

    const [listResulProfiles, setListResulProfiles] = useState([]);


    const handleClickGetProfiles = async (detail = {}) => {

        const profileIdsAvailable = (detail.profile_ids ?? '').toString();
        if (Utilities.isEmpty(profileIdsAvailable)) {
            return false;
        }

        if (detail.profile_ids.split(',').length === 1) {
            dataRequest = {
                account_ids: detail.profile_ids.toString(),
                account_type: 3,
            }


            dispatch(showLoader(1));
            resDb_listResult = await getPofilesByIds(dataRequest);
            dispatch(showLoader(0));

            if (Object.keys(resDb_listResult).length > 0) {
                if (parseInt(resDb_listResult.count ?? 0) > 0) {
                    listResultProfiles = resDb_listResult.list ?? [];

                    if (Object.keys(listResultProfiles[0] ?? {}).length > 0) {
                        props.handleClickProfile(listResultProfiles[0]);
                    }

                    return false;
                }
            }

        }

        setSteps(1);

        setWordSelected(detail);

        setBoolShowLoading(true);
        setBoolShowProfileResult(false);

        dataRequest = {
            account_ids: profileIdsAvailable,
            account_type: 3
        }

        resDb_listResult = await getPofilesByIds(dataRequest);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listResultProfiles = resDb_listResult.list ?? [];
            }
        }

        setListResulProfiles(listResultProfiles);
        setBoolShowLoading(false);
        setBoolShowProfileResult(true);


    }


    const handleClickBack = () => {

        if (steps === 1) {
            setBoolShowLoading(false);
            setBoolShowProfileResult(false);
            setSteps(0);
        }
    }



    if (listWords.length === 0) {
        return <DirectorySearchNoRecord />

    } else {
        return (
            <div className='tab-list-states'>

                {/* -------------------- word listing -------------------- */}
                {
                    (!boolShowProfileResult && !boolShowLoading) &&
                    <>
                        {/* <ul className="states-listing" >
                                    <li onClick={ () => handleClickGetProfiles({ profile_ids : profileIds??'' }) } >
                                        <div>
                                            <div className='font-gotham-bold'>{ Utilities.labelShowAll }</div>
                                            <div><span className="counter fs-16 fs-md-14">{profileCount??0}</span></div>
                                        </div>
                                    </li>
                                    <li style={{ minHeight : 0, padding : 0}}></li>
                                </ul> */}
                        <ul className="list-keywords" >
                            {
                                Object.entries(listWords).map(([key, row]) =>
                                    <DirectorySearchKeywordsTemplate highlightKeywordInResults={props.highlightKeywordInResults} detail={row} handleClick={handleClickGetProfiles} />
                                )
                            }
                        </ul>
                    </>
                }



                {/* -------------------- profile listing -------------------- */}
                {
                    (boolShowProfileResult && !boolShowLoading) &&
                    <>
                        <DirectorySearchBackNavigation handleClick={handleClickBack} text={wordSelected.word ?? Utilities.labelShowAll} />
                        <DirectorySearchProfileListing listProfiles={listResulProfiles} locationTabId={1} highlightKeywordInResults={props.highlightKeywordInResults} previewProfileImage={props.previewProfileImage} handleClickProfile={props.handleClickProfile} enableSortDistance={enableSortDistance} />
                    </>

                }


                {/* -------------------- loader -------------------- */}
                {boolShowLoading && <DirectorySearchLoaderProfile />}

            </div>
        )

    }

}


export default DirectorySearchKeywordsListing;