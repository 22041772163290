import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { showHeader, showFooter, pageTitle } from '../actions';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';

const CompanyCondition = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const siteTitle = process.env.REACT_APP_site_title;

    const [acceptCondition, setAcceptCondition] = useState(false);

    const pushCondition = () => {
        setAcceptCondition(acceptCondition ? false : true);
    }

    const submitAcceptance = () => {
        history.push(`/${UrlSlugs.signup_companyAddress}`);

    }

    useEffect(() => {
        dispatch(pageTitle(TitlesLabels.siteTitles.importantInformation));
        dispatch(showHeader(0));
        dispatch(showFooter(0));


    }, []);

    return (
        <div className='signup-page with-footer centerialized   text-lowercase'>

            <div className='signup-wrap position-relative h-100 d-flex align-items-center pb-5 justify-content-center px-4 p-md-0'>

                <div>
                    <SignupHeader title={TitlesLabels.general.signup_importantInformation} />

                    <div className='py-4'></div>

                    <ul className='text-left listing-system-bullet fs-18 color-theme-grey'>
                        <li className="mb-3">{siteTitle} was designed to ensure simplistic ease for users to connect with you as a business.</li>
                        <li className="mb-3">a non-negotiable condition is that connectivity is not abused by businesses in spamming and sending unsolicited advertisements to connected users or other businesses and professionals that you wish to do business with.</li>
                        <li>this is an infringement of their privacy and will result in very strong censure if their rights are violated.</li>
                    </ul>


                </div>

                <div className='signup-button position-absolute w-100 bottom-0 start-0 mb-3'>

                    <div className='pt-5 pb-4 checkbox-radio-default checkbox-radio-check-icon d-flex align-items-center justify-content-center color-theme-grey'>
                        <input type="checkbox" name="acceptance" checked={acceptCondition} onChange={() => pushCondition()} ></input>
                        <span className="ml-2 text-black font-gotham-book-16 cursor-pointer" onClick={() => pushCondition()} >do you agree?</span>
                    </div>

                    <button type="button" id="btn-submit-personal-condition" className="btn-theme-black button-has-loader col-4 mx-auto" onClick={(e) => submitAcceptance()} disabled={acceptCondition ? false : true}>continue</button>
                </div>

            </div>

            <div className='pb-5 pt-5 pt-md-0'>
                <Link to={`/${UrlSlugs.signup_companyKeywords}`}>{TitlesLabels.general.exitWithoutSaving}</Link>
            </div>

        </div>
    )
}

export default CompanyCondition;