import React from 'react';

import CommonProfileLogo from '../common/CommonProfileLogo';
import QueryMasterSearchUtlities from '../../helpers/QueryMasterSearchUtlities';
import Utilities from '../../helpers/Utilities';

const DirectorySearchProfileTemplate = (props) => {
    const profile = props.profile ?? {};
    const keyId = props.keyId ?? 0;

    let returmHtml = <></>;

    if (Object.keys(profile).length > 0) {
        const locationTabId = parseInt(props.locationTabId ?? 2);

        const accountType = parseInt(profile.account_type ?? 1);
        const branchStatus = parseInt(profile.branch_status ?? 0);
        const name = accountType === 3 ? profile.company_name : profile.name;

        let locationAddressFormatted = QueryMasterSearchUtlities.formatAdressByLocationType(locationTabId, profile);

        if (branchStatus > 0) {
            if (!Utilities.isEmpty(profile.branch_name ?? '')) {
                locationAddressFormatted = profile.branch_name + ', ' + locationAddressFormatted;
            }
        }

        let categoryName = '';
        if (profile.hasOwnProperty('category') && profile.category !== '') {
            if (profile.category !== 'undefined') {
                categoryName = profile.category;
            }
        }


        returmHtml = <li key={keyId} className="font-gotham-book fs-16 fs-md-16">
            <div className="profile-wrap" onClick={() => props.handleClickProfile(profile)}>
                <div className="profile-image-wrap">
                    <CommonProfileLogo classAttr={`profile-image fs-14-important logo-size-60 logo-size-md-60`} profileDetail={profile} handlePreviewProfileImage={props.previewProfileImage} preventOnclick={true} />
                </div>
                <div className='line-height-1'>
                    <div className="profile-name notranslate  font-gotham-bold fs-20 fs-sm-20 mb-1" dangerouslySetInnerHTML={{ __html: props.highlightKeywordInResults(name) }}></div>
                    {!Utilities.isEmpty(categoryName) && <div className="profile-label color-theme-grey">{categoryName}</div>}
                </div>

                <div className="company-address-detail-wrap color-theme-grey d-flex align-items-center justify-content-end">
                    {(profile.hasOwnProperty('team_company_name') && !Utilities.isEmpty(profile.team_company_name)) && <div className="profile-work-label mr-2 notranslate">{profile.team_company_name}</div>}
                    <div className="profile-address">{locationAddressFormatted}</div>
                </div>

                <div className="hidden-detail d-none">
                    <div className="name notranslate">{name}</div>
                    <div className="logo">{profile.logo_url}</div>
                    <div className="address">{locationAddressFormatted}</div>
                </div>

            </div>
        </li>

    }

    return returmHtml;

}


export default DirectorySearchProfileTemplate;