import axios from 'axios';
import ApiUrls from './ApiUrls';
import SessionUtilities from './SessionUtilities';

const API_PLUS_TOKEN = process.env.REACT_APP_chat_api_token;

export const talkToUsConversation = async (master_account_id = 0) => {
    let responseDetail = {};

    if (parseInt(master_account_id) > 0) {
        try {
            const TOKEN_SESSION = SessionUtilities.getSessionToken();
            const passval = {
                api_token: TOKEN_SESSION.toString(), //API_PLUS_TOKEN.toString(),
                account_id: master_account_id.toString()
            };
            const responseURL = await axios.post(ApiUrls.bizcomTalkToBizcomAssist, passval);
            if (parseInt(responseURL.data.success) === 1) {
                responseDetail = responseURL.data.result;
            }


        } catch (e) {

        }
    }

    return responseDetail;
}

export const getMarketingTools = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        passval = { ...passval, api_token: TOKEN_SESSION.toString() };

        //console.log('checkMobileNumberExistance');
        //console.log(ApiUrls.verificationMobileExistance);
        //console.log(passval);

        try {
            const responseURL = await axios.post(ApiUrls.bizcomMarketingTools, passval);
            responseDetail = responseURL.data;

        } catch (e) {

        }
    }

    return responseDetail;
}

export const getAppVideos = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        passval = { ...passval, api_token: TOKEN_SESSION.toString() };

        try {
            const responseURL = await axios.post(ApiUrls.bizcomVideos, passval);
            responseDetail = responseURL.data;
            console.log(responseDetail);
        } catch (e) {

        }
    }

    return responseDetail;
}