import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import Utilities from '../../helpers/Utilities';

const ChatTeamRestrictionRejection = (props) => {

    const limitCharacters = Utilities.teamRestrictionReasonLimit;

    const [showPopup, setShowPopup] = useState(false);
    const [initialMessage, setInitialMessage] = useState('');

    const handleClosePopup = () => {
        props.handleTeamRestrictionRejectConfirmation(false);
    }

    const teamRestrictionStatusProper = () => {

        const charCounter = Utilities.charactersCounter(initialMessage);
        if (charCounter > limitCharacters) {
            Utilities.messagePopup('error', `Only ${limitCharacters} characters are allowed!`);
            return false;
        }

        props.handleTeamRestrictionStatusProper(2, initialMessage.toString());
    }

    useEffect(() => {
        setShowPopup(props.showPopup);

        return () => {
            setInitialMessage('');
        }

    }, [props]);


    return (

        <Modal id="popup-chat-team-profile-restriction-rejecttion" show={showPopup} onHide={handleClosePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme" backdrop="static" keyboard={false}>
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4">{Utilities.labelReasonForRejection}</Modal.Header>
            <Modal.Body>

                <div className="col-md-10 mx-auto form-theme mt-2 mb-4">

                    <div className="form-group border-0">
                        <textarea name="initial_message" className="form-control" autoFocus rows="6" placeholder=" " value={initialMessage} onChange={(e) => setInitialMessage(e.target.value)}></textarea>
                        <label className="form-label bg-transparent">type here</label>
                    </div>

                    <div className="text-center font-gotham-book fs-md-14">
                        <div className="text-color-gray">{Utilities.number_format(limitCharacters.toString())} characters</div>
                        <div className="text-color-blue cursor-pointer" onClick={() => setInitialMessage('')}>clear</div>
                    </div>

                    <div className="pt-4 mt-2 mx-auto">
                        <button id="btn-confirm-team-rejection" type="button" className="btn-theme-black button-has-loader" onClick={() => teamRestrictionStatusProper()}>submit</button>
                    </div>

                </div>


            </Modal.Body>
        </Modal>
    )
}

export default ChatTeamRestrictionRejection;
