import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import TitlesLabels from '../helpers/TitlesLabels';
import PagesAuthentication from '../helpers/PagesAuthentication';


import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../actions';
import Utilities from '../helpers/Utilities';
import UrlSlugs from '../helpers/UrlSlugs';
import ApiUrls from '../helpers/ApiUrls';
import CommonProfileLogo from '../components/common/CommonProfileLogo';
import SessionUtilities from '../helpers/SessionUtilities';


const ProfileFormDeletion = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const TOKEN_BIZCOM = process.env.REACT_APP_chat_api_token_bizcom;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const minWords = 5;
    const errorMsg = process.env.REACT_APP_message_error_process;

    const defaultFormValues = {
        reason: '',
        password: ''
    }

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [personalAccount, setPersonalAccount] = useState({});

    const [showPopupPassword, setShowPopupPassword] = useState(false);
    const handleClosePopUpPassword = () => setShowPopupPassword(false);

    const [showPopupMessage, setShowPopupMessage] = useState(false);
    const handleClosePopUpMessage = () => {
        setShowPopupMessage(false);

        PagesAuthentication.logoutUser();
        history.push('/');

    }


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'reason') {
            formValuesTemp = { ...formValuesTemp, ...{ reason: value } };
        } else if (fieldName === 'password') {
            formValuesTemp = { ...formValuesTemp, ...{ password: value } };
        }

        jQuery('[name="' + fieldName + '"]').closest('.form-group').removeClass('error');
        setFormValues(formValuesTemp);

    }

    const handleClickSubmitReason = () => {
        const wordsCounter = Utilities.wordsCounter(formValues.reason);
        if (parseInt(wordsCounter) < minWords) {
            Utilities.messagePopup('', `reason should be minimum of ${minWords} words`);
            return false;
        }

        setFormValues({ ...formValues, ...{ password: '' } });
        setShowPopupPassword(true);
    }

    const handleClearReset = () => {
        setFormValues(defaultFormValues);
    }

    const handleSubmitPassword = async () => {

        if (Utilities.isEmpty(formValues.password)) {
            jQuery('[name="password"]').closest('.form-group').addClass('error');
            return false;
        }

        const button_name = `btn-submit-account-deactivation`;
        Utilities.elementDisabledStatus(button_name, true);

        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        const passval = {
            api_token: TOKEN_SESSION.toString(),    //TOKEN_BIZCOM.toString(),
            account_id: personalAccount.account_id.toString(),
            reason: formValues.reason.toString(),
            password: formValues.password.toString()
        };

        console.log('deleteuser');
        console.log(ApiUrls.profileDeleteUserDetails);
        console.log(passval);

        //return false;
        await axios
            .post(ApiUrls.profileDeleteUserDetails, passval)
            .then(response => {
                console.log(response);

                Utilities.elementDisabledStatus(button_name, false);

                if (parseInt(response.status) === 201) {
                    if (parseInt(response.data.success) === 1) {

                        PagesAuthentication.logoutUser();
                        handleClosePopUpPassword();
                        setShowPopupMessage(true);

                    } else {
                        Utilities.messagePopup('error', response.data.errorMessage);
                    }
                }

            })
            .catch((err) => {
                Utilities.messagePopup('error', errorMsg);
                Utilities.elementDisabledStatus(button_name, false);
            });

    }


    useEffect(() => {
        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.deactivateProfile}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        // ASSOCIATED ACCOUNTS
        let accountCounter = 0;
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {

                Object.entries(accountsList).map(([acctid, acctdetail]) => {
                    const account_type = parseInt(acctdetail.account_type);
                    if (account_type === 1) {
                        setPersonalAccount(acctdetail);
                    }

                    accountCounter++;
                });
            }
        }

        if (accountCounter > 1) {
            history.push(`/${UrlSlugs.dashboard}`);
        }

    }, []);

    return (
        <div className="profile-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div>{TitlesLabels.settings}</div>
                            <div>{TitlesLabels.navigations.managePersonalProfile}</div>
                            <div className="current">{TitlesLabels.navigations.deactivateAccount}</div>
                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>

                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content pt-4">
                <div className="container">

                    <form className="col-md-10 mx-auto pt-4 text-lowercase form-theme" autoComplete="off">

                        <div className="form-group active border-bottom-1">
                            <textarea name="last_name" className="form-control" placeholder="type here..." autoFocus rows="12" value={formValues.reason} onChange={(e) => pushValue(e, 'reason')} autoComplete="off" ></textarea>
                            <label className="form-label">reason for leaving <span className="text-pink ml-2">required*</span></label>
                        </div>
                        <div className='text-center'><span className='color-theme-green cursor-pointer fs-13 fs-md-15' onClick={() => handleClearReset()}>clear</span></div>

                        <div className='text-center'>
                            <button type="button" className="btn-theme-black mt-5 col-md-4 mx-auto" onClick={() => handleClickSubmitReason()}>submit</button>
                        </div>

                    </form>

                </div>
            </div>


            <Modal id="popup-delete-password" show={showPopupPassword} onHide={handleClosePopUpPassword} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme"  >
                <Modal.Header closeButton className="pt-4 border-0"></Modal.Header>
                <Modal.Body className="pb-4 profile-selected-assoc pt-0 mt-n3">

                    <div className="col-10 mx-auto text-lowercase  form-theme">

                        <div className="profile-header text-center  mb-4">
                            {/* <div className="profile-image logo-size-100 mx-auto"  style={{ backgroundImage : `url(${ personalAccount.logo_url || DEFAUL_AVATAR })`}}></div> */}
                            <CommonProfileLogo classAttr={`profile-image logo-size-100 mx-auto`} profileDetail={personalAccount} />

                            <div className='mt-2'>
                                <div className="profile-name  font-gotham-bold fs-18 fs-md-18">{personalAccount.name}</div>
                                <div className="profile-label font-gotham-book fs-15 fs-md-15">{Utilities.accountTypeLabels(personalAccount.account_type)}</div>
                            </div>
                        </div>

                        <div className=" pt-2 px-1">
                            <div className="form-group text-center">
                                <input type="password" name="password" className="form-control  text-center" placeholder=" " value={formValues.password} onChange={(e) => pushValue(e, 'password')} autoComplete="new-password" ></input>
                                <label className="form-label ">Password</label>
                            </div>
                        </div>


                        <div className="button-group-boolean pb-4 pt-3">
                            <button type="button" className="btn-theme-black" onClick={() => handleClosePopUpPassword()}>cancel</button>
                            <button type="button" id="btn-submit-account-deactivation" className=" btn-theme-black button-has-loader" onClick={() => handleSubmitPassword()} >submit</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <Modal id="popup-delete-password" show={showPopupMessage} onHide={handleClosePopUpMessage} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme"  >
                <Modal.Header closeButton className="pt-4 border-0"></Modal.Header>
                <Modal.Body className="pb-4 text-center font-gotham-book-14 text-gray-2">

                    <div className="col-11 mx-auto pb-4 mt-n4">
                        <div className="fs-16 text-pink font-gotham-bold fs-22">your account has now been successfully deactivated!</div>

                        <div className="py-4 fs-18">please remember you may reactivate your account in the next 30 days by logging in. should you not, your account be be permanently deleted. we wish you well and trust your experience on the platform was a positive one.</div>

                        <button type="button" className="btn-theme-black mt-3" onClick={() => handleClosePopUpMessage()}>close</button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default ProfileFormDeletion;