import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios'
import jQuery from 'jquery';
import moment from 'moment';
import { blockWordsAndEmailsValidation, checkEmailExistance, checkMobileNumberExistance, firstLastNameExistanceBasicProfile } from '../../helpers/VerificationUtilities';

import { showLoader, showHeader, showFooter, pageTitle, showPopupTermsConditions } from '../../actions';
import CitySearchPopup from '../../components/popups/CitySearch';
import Utilities from '../../helpers/Utilities';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

import PasswordInput from '../../components/PasswordInput';
import MobileSelection from '../../components/MobileSelection';
import SignupHeader from '../../layout/SignupHeader';

const CampaignPersonalPassword = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const defaultLocation = Utilities.defaultLocation();
    const [countryList, setCountryList] = useState([]);

    let defaultFormValues = {
        mobile_number: '',
        country_code: defaultLocation.country_id,
        password: '',
        password_confirm: '',
    }


    const [formValues, setFormValues] = useState(defaultFormValues);
    const [showPasswordChecker, setShowPasswordChecker] = useState(false);
    const [showPasswordConfirmChecker, setShowPasswordConfirmChecker] = useState(false);


    const changeCountryCode = (passValues) => {
        let formValuesTemp = { ...formValues, ...{ mobile_phonecode: passValues.phonecode, country_code: passValues.id } };
        setFormValues(formValuesTemp);
    }

    const getCountries = async () => {
        let formValuesTemp = formValues, sessionLocationTemp = {};

        await axios
            .post(ApiUrls.country, {})
            .then(response => {
                if (response.status === 200) {
                    setCountryList(response.data);

                    Object.entries(response.data).map(([key, row]) => {
                        if (parseInt(row.id) === parseInt(defaultFormValues.country_code)) {

                            sessionLocationTemp = PagesAuthentication.getDeviceLocation();

                            if (Object.keys(sessionLocationTemp).length > 0) {
                                formValuesTemp = { ...formValuesTemp, ...{ mobile_phonecode: row.phonecode.toString(), city_name: sessionLocationTemp.city_name ?? '', city_id: sessionLocationTemp.city_id ?? 0 } };
                            }
                        }
                    });

                    setFormValues(formValuesTemp);
                }
            })
            .catch((err) => {

            });
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0, showPasswordCheck = false, showPasswordConfirmCheck = false;

        if (fieldName === 'password') {
            showPasswordCheck = true;
            formValuesTemp = { ...formValuesTemp, ...{ password: value } };

        } else if (fieldName === 'password_confirm') {
            showPasswordConfirmCheck = true;
            formValuesTemp = { ...formValuesTemp, ...{ password_confirm: value } };
        }


        if (errorCounter === 0) {
            Utilities.removeFormGroupClass(fieldName);
        }

        setShowPasswordChecker(showPasswordCheck);
        setShowPasswordConfirmChecker(showPasswordConfirmCheck);
        setFormValues(formValuesTemp);
    }


    const submitDetail = () => {

        let errorCounter = 0;
        let formFieldsRequired = formValues;

        jQuery('#form-personal-credential .error').removeClass('error');

        Object.entries(formFieldsRequired).map(([key, value]) => {

            if (Utilities.isEmpty(value) && !['email'].includes(key)) {
                Utilities.addFormGroupClass(key);
                errorCounter++;
            }
        });

        if (errorCounter > 0) { return false; }

        if (!Utilities.isEmpty(formValues.password)) {
            const passwordValidation = Utilities.passwordValidation(formValues.password);
            if (passwordValidation.response === false) {
                Utilities.addFormGroupClass('password');
                return false;
            }


            if (formValues.password !== formValues.password_confirm) {
                Utilities.messagePopup('Error', Utilities.labelPasswordConfirmNotSame);
                return false;
            }
        }

        const session_signupPersonalInfo = PagesAuthentication.getSignupPersonalInfo();
        let formValuesTemp = {
            ...session_signupPersonalInfo,
            ...formValues,
        }

        PagesAuthentication.setSignupPersonalInfo(formValuesTemp);

        history.push(`/${UrlSlugs.signup_personalCondition}`);
    }

    useEffect(async () => {

        dispatch(pageTitle(TitlesLabels.siteTitles.credentialDetails));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        await getCountries();

        // APPLY SIGNUP PERSONAL
        const session_signupPersonalInfo = PagesAuthentication.getSignupPersonalInfo();
        if (Object.keys(session_signupPersonalInfo).length > 0) {

            let formValuesTemp = {
                ...formValues,
                country_code: session_signupPersonalInfo.country_code ?? formValues.country_code,
                mobile_number: session_signupPersonalInfo.mobile_number ?? '',
            }

            setFormValues(formValuesTemp);

        }



    }, [])

    return (
        <>
            <div className='signup-page with-footer centerialized   text-lowercase'>

                <div className='signup-wrap position-relative h-100 d-flex align-items-center pb-5 justify-content-center px-4 p-sm-0'>
                    <div className='w-100'>
                        <SignupHeader title="credential details" />

                        <div className='py-4'></div>

                        <form id="form-personal-credential" className="text-lowercase form-theme text-left" autoComplete="off">


                            <div className='row'>

                                <div className='col-12 col-sm-6'>
                                    <MobileSelection inputValue={formValues.mobile_number} countryListProvided={countryList} inputLabel="Mobile Number" countryCodeProvided={formValues.country_code} inputListingId="list-country-current" inputName="mobile_number" pushValue={pushValue} changeCountryCode={changeCountryCode} minNumber={6} maxNumber={9} inputClass={`opacity-05`} contentClass={`content-disabled cursor-notallow`} />
                                </div>

                            </div>

                            <div className='row'>
                                <div className='col-12 col-sm-6'>
                                    <PasswordInput inputName="password" inputLabel="Password" inputValue={formValues.password} pushValue={pushValue} showChecker={showPasswordChecker} />
                                </div>
                                <div className='col-12 col-sm-6'>
                                    <PasswordInput inputName="password_confirm" inputLabel="Confirm Password" inputValue={formValues.password_confirm} pushValue={pushValue} showChecker={showPasswordConfirmChecker} />
                                </div>
                            </div>

                        </form>

                    </div>

                    <div className='signup-button position-absolute w-100 bottom-0 start-0  mb-3'>

                        <button type="submit" id="btn-submit-personal-info" className="btn-theme-black text-lowercase button-has-loader col-4 mx-auto" onClick={() => submitDetail()}>Next</button>
                    </div>


                </div>


                <div className='pb-5 pt-5 pt-md-0'>
                    <Link to={`/${UrlSlugs.signup_campaignPersonalInfo}`} className='fs-18'>{TitlesLabels.general.exitWithoutSaving}</Link>
                </div>

            </div>


        </>
    )
}

export default CampaignPersonalPassword;