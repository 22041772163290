import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { pageTitle, showFooter, showHeader, showMainSettings as showMainSettingsReducer } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';
import PagesAuthentication from '../helpers/PagesAuthentication';
import { getGotoLinkCompanies } from '../helpers/QueryGotoLinks';
import Utilities from '../helpers/Utilities';
import NoRecordAvailable from '../components/common/NoRecordAvailable';
import CommonProfileLogo from '../components/common/CommonProfileLogo';
import SessionUtilities from '../helpers/SessionUtilities';

const TOKEN = process.env.REACT_APP_chat_api_token;
const errorMsg = process.env.REACT_APP_message_error_process;

const GotoLinks = ({ match }) => {

    const dispatch = useDispatch();

    let dataDb_result = {}, dataParams = {};
    const masterDetail = PagesAuthentication.getMasterUserDetailDetail();

    const [showProcessing, setShowProcessing] = useState(false);

    const [allLinkedCompanies, setAllLinkedCompanies] = useState([]);
    const [allHeadings, setAllHeadings] = useState([]);

    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }

    //flag : 0 = list, 1 : add, 2 : update 3 : delete
    const gettingGotoLinksList = async (companyId = 0) => {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        dataParams = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (masterDetail.account_id ?? 0).toString(),
            company_id: (companyId ?? 0).toString(),
            flag: "0",
            group_id: "",
            link_account_id: "",
            link_account_name: "",
            category_id: ""
        }

        setShowProcessing(true);
        dataDb_result = await getGotoLinkCompanies(dataParams);
        setShowProcessing(false);

        if (Object.keys(dataDb_result).length > 0) {
            setAllLinkedCompanies(dataDb_result.company_list ?? []);
            setAllHeadings(dataDb_result.category_list ?? []);

        } else {
            setAllLinkedCompanies([]);
            setAllHeadings([]);
        }

    }


    const listProfilesHtml = (headingDetail = {}) => {
        const headingId = (headingDetail.group_id ?? 0).toString();
        const filteredProfiles = allLinkedCompanies.filter(profile => profile.category_id.toString() === headingId.toString());
        if (Object.keys(filteredProfiles).length > 0) {

            return (
                <>
                    <div className={`font-gotham-bold fs-20 ${headingId > 0 ? 'mt-4 pt-2' : ''}`}>{headingDetail.title ?? ''}</div>
                    <ul className={`listing-profiles ul-global-list`}>
                        {
                            Object.entries(filteredProfiles).map(([key, row]) =>
                                <li key={key} data-keyword={row.company_display_name}>
                                    <div className="profile-image-wrap">
                                        <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={{ ...row, name: row.company_name, logo_url: row.company_logo }} />
                                    </div>
                                    <div className='line-height-1'>
                                        <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1" data-name={row.company_display_name}>{row.company_display_name} </div>
                                        <div className="profile-label font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.company_category_name}</div>
                                        <div className="profile-label font-gotham-book fs-14 fs-md-14 color-theme-grey mt-1">{row.company_location}</div>
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                </>
            )
        }

    }


    useEffect(() => {

        dispatch(pageTitle(`${TitlesLabels.siteTitles.gotoLinks}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

    }, []);


    useEffect(() => {

        let paramSelectedCompanyid = match.params.compid ?? '';

        if (!Utilities.isEmpty(paramSelectedCompanyid)) {
            paramSelectedCompanyid = parseInt(atob(paramSelectedCompanyid));
            gettingGotoLinksList(paramSelectedCompanyid);

        }

    }, [match.params.compid]);



    return (
        <div className="link-profiles-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className="current">{TitlesLabels.navigations.gotoLinks}</div>
                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>


            </div>

            <div className="main-content">
                <div className="container  py-5 ">

                    <div className='col-lg-11 mx-auto'>

                        {
                            showProcessing ?
                                <ul className="ul-global-listing">
                                    {
                                        [...Array(6).keys()].map((key) =>
                                            <li key={key} className="global-preloader  py-2">
                                                <div className="bg-gray col-5 pt-3 border-radius-loader"></div>
                                                <div className="ml-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                            </li>
                                        )
                                    }
                                </ul>
                                :
                                <>
                                    {

                                        Object.keys(allLinkedCompanies).length === 0 ?
                                            <NoRecordAvailable />

                                            :

                                            <ul className='text-lowercase'>
                                                {
                                                    Object.entries(allHeadings ?? []).map(([key, row]) =>
                                                        <li key={key} className='border-0' >
                                                            {listProfilesHtml(row)}
                                                        </li>
                                                    )
                                                }

                                            </ul>

                                    }
                                </>
                        }
                    </div>

                </div>
            </div>

        </div>
    )

}

export default GotoLinks;
