import React, { useState } from 'react';
import { getPofilesByIds, getProfileByLocation } from '../../helpers/QueryDirectorySearch';
import Utilities from '../../helpers/Utilities';
import DirectorySearchNoRecord from './DirectorySearchNoRecord';
import DirectorySearchBackNavigation from './DirectorySearchBackNavigation';
import DirectorySearchProfileListing from './DirectorySearchProfileListing';
import DirectorySearchLoaderProfile from './DirectorySearchLoaderProfile';
import DirectorySearchItemTemplate from './DirectorySearchItemTemplate';


const DirectorySearchProfilesSuburbsByCity = (props) => {

    let listResultProfiles = [], dataRequest = {}, resDb_listResult = {};

    const areaList = props.listResult.area_list ?? [];
    const profileCount = props.listResult.profile_count ?? 0;
    const profileAllIds = props.listResult.profile_ids ?? '';

    const countryId = parseInt(props.countryid ?? 0);
    const stateId = parseInt(props.stateid ?? 0);
    const cityId = parseInt(props.cityid ?? 0);
    const areaId = parseInt(props.areaid ?? 0);
    const typeId = parseInt(props.typeid ?? 0);
    const searchCategoryId = parseInt(props.searchCategoryId ?? 0);
    const searchCategoryDetail = props.searchCategoryDetail ?? {};
    const enableSortDistance = props.enableSortDistance ?? false;

    let category_search_status = 0;
    if (Object.keys(searchCategoryDetail).length > 0 && searchCategoryId === 0) {
        category_search_status = 1;
    }

    const keyword = (props.keyword ?? '').toString();

    const defaultLocation = {
        country_id: countryId.toString(),
        state_id: stateId.toString(),
        city_id: cityId.toString(),
        area_id: areaId.toString(),
    }

    const [boolShowProfileResult, setBoolShowProfileResult] = useState(false);
    const [boolShowLoading, setBoolShowLoading] = useState(false);
    const [listResulProfiles, setListResulProfiles] = useState([]);
    const [headerNavigationText, setHeaderNavigationText] = useState(Utilities.labelShowAll);


    const gettingProfilesByIds = async (profileids = '') => {

        if (Utilities.isEmpty(profileids)) { return false; }

        setBoolShowLoading(true);
        setBoolShowProfileResult(false);

        dataRequest = {
            account_ids: profileids.toString(),
            account_type: typeId.toString(),
        }

        resDb_listResult = await getPofilesByIds(dataRequest);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listResultProfiles = resDb_listResult.list ?? [];
            }
        }

        setListResulProfiles(listResultProfiles);
        setBoolShowLoading(false);
        setBoolShowProfileResult(true);

    }

    const gettingProfiles = async (areaId = -1, navText = Utilities.labelShowAll) => {
        areaId = parseInt(areaId);

        setBoolShowLoading(true);
        setBoolShowProfileResult(false);

        dataRequest = {
            country_id: defaultLocation.country_id.toString(),
            state_id: defaultLocation.state_id.toString(),
            city_id: defaultLocation.city_id.toString(),
            type: typeId.toString(),
            category_id: searchCategoryId.toString(),
            keyword: keyword.toString(),
            category_search: category_search_status.toString()
        }

        if (areaId > -1) {
            dataRequest = {
                ...dataRequest,
                area_id: areaId,
                direct_area_query: '1'
            };
        }

        console.log(dataRequest);
        resDb_listResult = await getProfileByLocation(dataRequest);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listResultProfiles = resDb_listResult.list ?? [];
            }
        }

        setListResulProfiles(listResultProfiles);
        setBoolShowLoading(false);
        setBoolShowProfileResult(true);
        setHeaderNavigationText((searchCategoryId > 0 ? (searchCategoryDetail.cate_name ?? '' !== '' ? searchCategoryDetail.cate_name + ', ' : '') : '') + navText);

    }


    const handleClickBack = () => {
        setBoolShowLoading(false);
        setBoolShowProfileResult(false);
    }

    const handleClickCategoryBack = () => {
        props.handleClickCategoryBack();
    }


    return (
        <div className='tab-list-cities'>

            {/* -------------------- suburb listing -------------------- */}
            {
                (!boolShowProfileResult && !boolShowLoading) &&
                <>
                    {
                        Object.keys(areaList).length === 0 ?
                            <DirectorySearchNoRecord />
                            :
                            <>
                                {
                                    Object.keys(searchCategoryDetail).length > 0 && <DirectorySearchBackNavigation handleClick={handleClickCategoryBack} text={searchCategoryDetail.cate_name ?? ''} />
                                }
                                <ul className="states-listing">
                                    <li onClick={() => gettingProfilesByIds(profileAllIds)}>
                                        <div>
                                            <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                            <div><span className="counter fs-16 fs-md-14">{profileCount}</span></div>
                                        </div>
                                    </li>
                                    {
                                        Object.entries(areaList).map(([key, row]) =>
                                            parseInt(row.counter) > 0 && <li key={key} onClick={() => gettingProfiles(row.area_id, row.area_name ?? '')} ><DirectorySearchItemTemplate name={row.area_name ?? ''} counter={row.counter ?? '0'} /></li>
                                        )
                                    }

                                </ul>
                            </>
                    }
                </>
            }


            {/* -------------------- profile listing -------------------- */}
            {
                (boolShowProfileResult && !boolShowLoading) &&
                <>
                    <DirectorySearchBackNavigation handleClick={handleClickBack} text={headerNavigationText} />
                    <DirectorySearchProfileListing listProfiles={listResulProfiles} locationTabId={2} highlightKeywordInResults={props.highlightKeywordInResults} previewProfileImage={props.previewProfileImage} handleClickProfile={props.handleClickProfile} enableSortDistance={enableSortDistance} />
                </>
            }


            {/* -------------------- loader -------------------- */}
            {boolShowLoading && <DirectorySearchLoaderProfile />}


        </div>
    )

}


export default DirectorySearchProfilesSuburbsByCity;