import React, { useState } from 'react';
import DirectorySearchNoRecord from './DirectorySearchNoRecord';
import Utilities from '../../helpers/Utilities';
import { getCategoryDetailById } from '../../helpers/QueryDirectorySearch';
import DirectorySearchLoaderCategories from './DirectorySearchLoaderCategories';

const DirectorySearchCategories = (props) => {

    let listResultProfiles = [], dataRequest = {}, resDb_listResult = {};

    const countryId = parseInt(props.countryid ?? 0);
    const stateId = parseInt(props.stateid ?? 0);
    const cityId = parseInt(props.cityid ?? 0);
    const areaId = parseInt(props.areaid ?? 0);
    const typeId = parseInt(props.typeid ?? 0);
    const searchCategoryId = parseInt(props.searchCategoryId ?? 0);

    const categoryList = props.categoryList ?? [];
    const keyword = (props.keyword ?? '').toString();

    const defaultLocation = {
        country_id: countryId.toString(),
        state_id: stateId.toString(),
        city_id: cityId.toString(),
        area_id: areaId.toString(),
    }

    const [selectedCategory, setSelectedCategory] = useState({});
    const [listCategories, setListCategories] = useState(categoryList);

    const [showLoaderCategory, setShowLoaderCategory] = useState(false);


    const formattedCategoryName = (name = '') => {
        if (!Utilities.isEmpty(name)) {
            name = name.toString().toLowerCase();
            name = name.replace(keyword, '<span class="keyword-string">' + keyword + '</span>');
        }
        return name;
    }


    const gettingSubCategoryById = async (categoryDetail = {}) => {

        setSelectedCategory(categoryDetail);
        const categoryId = parseInt(categoryDetail.group_id ?? 0),
            categoryParentId = parseInt(categoryDetail.parent_id ?? 0);

        if (categoryId > 0) {

            if (categoryParentId > 0) {

                setListCategories([]);
                setShowLoaderCategory(true);
                resDb_listResult = await getCategoryDetailById({ id: categoryId });
                setShowLoaderCategory(false);


                if (Object.keys(resDb_listResult).length > 0) {

                    // show more subcategories
                    if (Object.keys(resDb_listResult.list ?? []).length > 0) {
                        setListCategories(resDb_listResult.list);

                        // getting profiles
                    } else {

                        categoryDetail = {
                            ...categoryDetail,
                            group_id: '0'
                        }
                        props.handleClickCategoryDetail(categoryDetail)

                    }
                }



                // search by company keyword direct on company table
            } else {

                props.handleClickCategoryDetail(categoryDetail)

            }

        } else {
            props.handleClickCategoryDetail(categoryDetail)
        }

    }

    return (
        <div className="section-category text-lowercase text-center pt-4">

            {
                Object.keys(categoryList).length === 0 ?
                    <DirectorySearchNoRecord />
                    :
                    <ul className="list-categories">
                        {
                            Object.entries(listCategories).map(([key, row]) =>
                                <li key={key} onClick={() => gettingSubCategoryById(row)}>
                                    <div className="category-image" style={{ backgroundImage: `url(${row.category_image_url ?? ''})` }}>
                                        {!Utilities.isEmpty(row.category_image_url ?? '') ? '' : Utilities.getInitialNameCategory(row.cate_name)}
                                    </div>
                                    <div className="font-gotham-bold fs-18 fs-md-18 ellipsis-2 line-height-1-2 pt-2 mb-1" dangerouslySetInnerHTML={{ __html: formattedCategoryName(row.cate_name) }}></div>
                                    {
                                        (typeof row.parent_id !== 'undefined' && parseInt(row.parent_id) > 0 && typeof row.parent_detail.cate_name !== 'undefined') &&
                                        <div className="font-gotham-book  fs-16 fs-md-16 color-theme-grey">{row.parent_detail.cate_name}</div>
                                    }
                                </li>
                            )
                        }

                    </ul>
            }

            {showLoaderCategory && <DirectorySearchLoaderCategories />}

        </div>
    )

}


export default DirectorySearchCategories;