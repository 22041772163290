import React, { memo, useState } from "react";
import Utilities from "../../helpers/Utilities";


const DashboardProfileSearch = (props) => {

    const searchPlaceHolder = props.placeholder ?? 'type here';
    const [defaultSearchValue, setDefaultSearchValue] = useState('');


    return (
        <>
            <div className='form-group mb-0 border-0  w-100 '>
                <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => { props.pushValue(e, 'search-keyword'); setDefaultSearchValue(e.target.value); }} placeholder={searchPlaceHolder} autoComplete='off' />
            </div>
            {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => { props.resetSearchValue(); setDefaultSearchValue(''); }}>clear</span></div>}
        </>
    )
}

export default memo(DashboardProfileSearch);
