import UrlSlugs from "./UrlSlugs";

const daysExpiry = 2;

class PagesAuthenticationHelper {

    daysExpiry = () => { return 1; }

    checkLoginStatus = () => {
        const SELF = this;
        const loginSession = localStorage.getItem(process.env.REACT_APP_session_login);
        const sessionUserLogin = JSON.parse(loginSession);

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        const sesstionLoginType = JSON.parse(loginType);

        /*if ( sessionUserLogin !== null && sesstionLoginType !== null){
            const pathRoute = parseInt(sesstionLoginType)===1 ? `/${ UrlSlugs.dashboard }` : `/${ UrlSlugs.departmentDashboard }`;
            window.location.href = pathRoute;
        }*/


        if (sessionUserLogin !== null && sesstionLoginType !== null) {

            const loginSessionStarted = localStorage.getItem(process.env.REACT_APP_session_login_started);
            if (loginSessionStarted !== null && loginSessionStarted !== '') {
                const dayDiff = SELF.daysDifference(Date.now(), loginSessionStarted);
                //console.log(dayDiff);

                // it will automatically loggedout after 1 day
                if (parseInt(dayDiff) >= daysExpiry) {
                    SELF.logoutUser();
                    window.location.reload();
                }
            }

        }


    }


    getSignupPersonalInfo = () => {
        let responseDetail = {};
        let storageDetail = localStorage.getItem(process.env.REACT_APP_session_signup_personalinfo);
        if (storageDetail !== null) {
            responseDetail = JSON.parse(storageDetail);
        }
        return responseDetail;

    }

    setSignupPersonalInfo = (dataProvied = {}) => {
        localStorage.setItem(process.env.REACT_APP_session_signup_personalinfo, JSON.stringify(dataProvied));
    }



    getSignupSocialDetail = () => {
        let responseDetail = {};
        let socialConnect = localStorage.getItem(process.env.REACT_APP_session_signup_social_connect);
        if (socialConnect !== null) {
            responseDetail = JSON.parse(socialConnect);
        }
        return responseDetail;
    }

    setSignupSocialDetail = (dataProvied = {}) => {
        localStorage.setItem(process.env.REACT_APP_session_signup_social_connect, JSON.stringify(dataProvied));
    }


    deleteSignupSocialDetail = () => {
        localStorage.removeItem(process.env.REACT_APP_session_signup_social_connect);
    }


    getDeviceLocation = () => {
        let responseDetail = {};
        let storageDetail = sessionStorage.getItem(process.env.REACT_APP_session_location);
        if (storageDetail !== null) {
            responseDetail = JSON.parse(storageDetail);
        }
        return responseDetail;
    }

    setLoginAccountDetail = (profile_data = {}) => {

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginProfileInfo !== null) {
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if (Object.keys(loginProfileInfo).length > 0) {
                localStorage.setItem(
                    process.env.REACT_APP_session_login,
                    JSON.stringify(profile_data)
                );
            }
        }
    }


    getUserLoggedinDetail = () => {
        const loginSession = localStorage.getItem(process.env.REACT_APP_session_login);
        const sessionUserLogin = JSON.parse(loginSession);

        return sessionUserLogin;
    }


    getMasterUserDetailDetail = () => {
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts), sessionMasterDetail = {};
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {
                Object.entries(accountsList).map(([acctid, acctdetail]) => {
                    const account_type = parseInt(acctdetail.account_type);
                    if (account_type === 1) {
                        sessionMasterDetail = acctdetail;
                    }
                });
            }
        }

        return sessionMasterDetail;
    }

    getDirectorySearchType = () => {
        let searchType = sessionStorage.getItem(process.env.REACT_APP_session_search_type);
        searchType = searchType ?? JSON.parse(searchType);

        return searchType;
    }

    updateDirectorySearch = (status = 1) => {
        status = status || 1;
        sessionStorage.setItem(
            process.env.REACT_APP_session_search_type,
            JSON.stringify(status)
        );
    }

    getUsersAssociated = () => {
        let returns = [];

        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {
                returns = accountsList;
            }
        }

        return returns;
    }


    getMasterAccountDetail = () => {
        let returns = {};

        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {
                returns = Object.values(accountsList).filter(row => parseInt(row.account_type) === 1);
                returns = returns[0];
            }
        }

        return returns;
    }


    getUserLoggedin = () => {
        const SELF = this;

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);

        if (sesstionLoginType === null) {
            SELF.logoutUser();
            window.location.href = `/${UrlSlugs.login}`;
            return false;
        }

        const loginSession = localStorage.getItem(process.env.REACT_APP_session_login);
        const sessionUserLogin = JSON.parse(loginSession);

        const loginSessionStarted = localStorage.getItem(process.env.REACT_APP_session_login_started);
        if (loginSessionStarted !== null && loginSessionStarted !== '') {
            const dayDiff = SELF.daysDifference(Date.now(), loginSessionStarted);
            //console.log(dayDiff);

            // it will automatically loggedout after 1 day
            if (parseInt(dayDiff) >= daysExpiry) {
                SELF.logoutUser();
                window.location.href = `/${UrlSlugs.login}`;
                return false;

            } else {
                localStorage.setItem(
                    process.env.REACT_APP_session_login_started,
                    Date.now()
                );
            }

        } else {
            localStorage.setItem(
                process.env.REACT_APP_session_login_started,
                Date.now()
            );
        }

        if (sessionUserLogin === null) {
            window.location.href = `/${UrlSlugs.login}`;
        }


        // REDIRECT TO RIGHT DASHBOARD , just incase restriction is not appied in routes ( app.js )
        const pathname = window.location.pathname;
        let pathnameArray = pathname.split('/');

        pathnameArray = pathnameArray.filter(Boolean);
        const lastUrlSegment = pathnameArray.slice(-1).pop().toString().toLowerCase();

        sesstionLoginType = parseInt(sesstionLoginType);
        if (sesstionLoginType === 1 && ['departmentdashboard', 'linked-departments'].includes(lastUrlSegment)) {
            window.location.href = `/${UrlSlugs.dashboard}`;

        } else if (sesstionLoginType === 2 && ['dashboard'].includes(lastUrlSegment)) {
            window.location.href = `/${UrlSlugs.departmentDashboard}`;
        }

    }


    logoutUser = () => {
        //const loginSession = localStorage.getItem(process.env.REACT_APP_session_login);
        //const sessionUserLogin = JSON.parse(loginSession);

        //if ( sessionUserLogin !== null ){
        localStorage.removeItem(process.env.REACT_APP_session_logintype);
        localStorage.removeItem(process.env.REACT_APP_session_login_signup);
        //localStorage.removeItem(process.env.REACT_APP_session_signup_companyGeneratedId);

        localStorage.removeItem(process.env.REACT_APP_session_login);
        localStorage.removeItem(process.env.REACT_APP_session_login_started);

        localStorage.removeItem(process.env.REACT_APP_session_accounts);
        localStorage.removeItem(process.env.REACT_APP_session_internal); // can remove but need to clear browser values
        localStorage.removeItem(process.env.REACT_APP_session_signup_personalinfo);
        localStorage.removeItem(process.env.REACT_APP_session_department);

        localStorage.removeItem(process.env.REACT_APP_session_welcome_mesage);
        localStorage.removeItem(process.env.REACT_APP_session_bizplus_welcome);
        localStorage.removeItem(process.env.REACT_APP_user_location);
        localStorage.removeItem(process.env.REACT_APP_sesion_conversation_profiles_loaded_boolen);

        sessionStorage.removeItem(process.env.REACT_APP_session_login_company_bulk_credential);

        //sessionStorage.removeItem(process.env.REACT_APP_session_location);
        sessionStorage.removeItem(process.env.REACT_APP_session_account_conversations_detail);
        sessionStorage.removeItem(process.env.REACT_APP_session_convers_start_ids);

        sessionStorage.removeItem(process.env.REACT_APP_session_lang);
        sessionStorage.removeItem(process.env.REACT_APP_session_notification_accounts);

        sessionStorage.removeItem(process.env.REACT_APP_session_jazenet_request_profileid);
        sessionStorage.removeItem(process.env.REACT_APP_session_plus_account_id);

        sessionStorage.removeItem(process.env.REACT_APP_session_last_conversation_message);

        sessionStorage.removeItem(process.env.REACT_APP_sesion_dashboard_loaded);
        sessionStorage.removeItem(process.env.REACT_APP_sesion_dashboard_templist);

        sessionStorage.removeItem(process.env.REACT_APP_sesion_dashboardinternal_loaded);
        sessionStorage.removeItem(process.env.REACT_APP_sesion_dashboardinternal_templist);

        localStorage.removeItem(process.env.REACT_APP_session_signup_social_connect);


        sessionStorage.removeItem(process.env.REACT_APP_sesion_dashboardinternal_profile_selected);

        //sessionStorage.removeItem(process.env.REACT_APP_session_signup_companyGeneratedId);
        sessionStorage.removeItem(process.env.REACT_APP_session_manage_team_current_company_detail);

        sessionStorage.removeItem(process.env.REACT_APP_session_signup_campaign_detail);


        // DIRECTORY
        sessionStorage.removeItem(process.env.REACT_APP_session_directory_location);

        // SIGNUP
        sessionStorage.removeItem(process.env.REACT_APP_session_signup_companyinfo_applink);
        sessionStorage.removeItem(process.env.REACT_APP_session_signup_companyinfo_referral);


        // DEPARTENTS
        localStorage.removeItem(process.env.REACT_APP_session_company_departments);
        localStorage.removeItem(process.env.REACT_APP_session_company_detail);
        localStorage.removeItem(process.env.REACT_APP_session_department_active);


        localStorage.removeItem(process.env.REACT_APP_session_divert_reply);
        localStorage.removeItem(process.env.REACT_APP_session_divert_notification);

        // REMOVE FCM Token
        //localStorage.removeItem(process.env.REACT_APP_fs_fcm_token);


        // LOGIN
        localStorage.removeItem(process.env.REACT_APP_session_from_login_status);
        localStorage.removeItem(process.env.REACT_APP_session_token);


        sessionStorage.removeItem(process.env.REACT_APP_notification_glance_signup_followed_glance_status);

        //}
    }


    logoutDirectly() {
        const SELF = this;
        SELF.logoutUser();
        window.location.href = "/";
    }

    daysDifference = (timestamp1, timestamp2) => {
        let difference = timestamp1 - timestamp2;
        let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);

        return daysDifference;
    }


}

export default new PagesAuthenticationHelper();

