import axios from 'axios';
import ApiUrls from './ApiUrls';


export const getConversationCompanyRequest = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {

            //console.log('getConversationCompanyRequest');
            //console.log(ApiUrls.sendRequestForCompanyCreation);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.sendRequestForCompanyCreation, passval);
            //console.log(responseURL);
            if (parseInt(responseURL.status) === 201) {
                //responseDetail =  responseURL.data.result.beonchat_conversation??{};
                responseDetail = responseURL.data ?? {};
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const removeRejectAcceptCompanyCreation = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            //console.log(ApiUrls.removeRejectAcceptCompanyCreation, passval);

            const responseURL = await axios.post(ApiUrls.removeRejectAcceptCompanyCreation, passval);
            if (parseInt(responseURL.status) === 201) {
                responseDetail = responseURL.data ?? {};
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}