export const showHeader = (nr) =>{
  return{
    type : 'SHOWHEADER',
    payload : nr
  }
}

export const showFooter = (nr) =>{
  return{
    type : 'SHOWFOOTER',
    payload : nr
  }
}

export const pageTitle = (nr) =>{
  return{
    type : 'PAGETITLE',
    payload : nr
  }
}

export const showMainSearch = (nr) =>{
  return{
    type : 'SHOWMAINSEARCH',
    payload : nr
  }
}

export const showLoader = (nr) =>{
  return{
    type : 'SHOWLOADER',
    payload : nr
  }
}

export const reloadProfile = (nr) =>{
  return{
    type : 'RELOADPROFILE',
    payload : nr
  }
}

export const globalNotificationCounter = (nr) =>{
  return{
    type : 'GLOBALNOTIFICATIONCOUNTER',
    payload : nr
  }
}

export const globalProfile = (nr) =>{
  return{
    type : 'GLOBALPROFILE',
    payload : nr
  }
}


export const switchProfile = (nr) =>{
  return{
    type : 'SWITCHPROFILE',
    payload : nr
  }
}

export const reloadAccountsDB = (nr) =>{
  return{
    type : 'RELOADACCOUNTSDB',
    payload : nr
  }
}


export const showChatSearch = (nr) =>{
  return{
    type : 'SHOWCHATSEARCH',
    payload : nr
  }
}

export const passInternalProfile = (nr) =>{
  return{
    type : 'PASSINTERNALPROFILE',
    payload : nr
  }
}

export const passChatProfile = (nr) =>{
  return{
    type : 'PASSCHATPROFILE',
    payload : nr
  }
}


export const passProfile = (nr) =>{
  return{
    type : 'PASSPROFILE',
    payload : nr
  }
}


export const chatSidebarBlock = (nr) =>{
  return{
    type : 'CHATSIDEBARBLOCK',
    payload : nr
  }
}


export const showLanguageSelectionPopup = (nr) =>{
  return{
    type : 'SHOWLANGUAGESELECTION',
    payload : nr
  }
}


export const accountsWithNotification = (nr) =>{
  return{
    type : 'ACCOUNTSWITHNOTIFICATION',
    payload : nr
  }
}


export const shareQRCode = (nr) =>{
  return{
    type : 'SHAREQRCODE',
    payload : nr
  }
}

export const showNoteIndicator = (nr) =>{
  return{
    type : 'SHOWNOTEINDICATOR',
    payload : nr
  }
}

/* export const allowManageNote = (nr) =>{
  return{
    type : 'ALLOWMANAGENOTE',
    payload : nr
  }
} */

export const passConversID = (nr) =>{
  return{
    type : 'PASSCONVERSID',
    payload : nr
  }
}

export const passImageUrl = (nr) =>{
  return{
    type : 'PASSIMAGEURL',
    payload : nr
  }
}

export const passQrcodeDetail = (nr) =>{
  return{
    type : 'PASSQRCODETAIL',
    payload : nr
  }
}


export const passChatMessageText = (nr) =>{
  return{
    type : 'PASSCHATMESSAGETEXT',
    payload : nr
  }
}

export const passConversationMessage = (nr) =>{
  return{
    type : 'PASSCONVERSATIONMESSAGE',
    payload : nr
  }
}

export const passConversDetail = (nr) =>{
  return{
    type : 'PASSCONVERSDETAIL',
    payload : nr
  }
}

export const passConversList = (nr) =>{
  return{
    type : 'PASSCONVERSLIST',
    payload : nr
  }
}


export const bizcomplusSwitchAccount = (nr) =>{
  return{
    type : 'BIZCOMPLUSWITCHACCOUNT',
    payload : nr
  }
}

export const changeCityPopup = (nr) =>{
  return{
    type : 'CHANGECITYPOPUP',
    payload : nr
  }
}

export const applyChangeLocation = (nr) =>{
  return{
    type : 'APPLYCHANGELOCATION',
    payload : nr
  }
}

export const newCityIDRequest = (nr) =>{
  return{
    type : 'NEWCITYIDREQUEST',
    payload : nr
  }
}

export const reloadDepartments = (nr) =>{
  return{
    type : 'RELOADDEPARTMENTS',
    payload : nr
  }
}

export const passForwardConversId = (nr) =>{
  return{
    type : 'PASSFORWARDCONVERSID',
    payload : nr
  }
}

export const passDeleteMediaList = (nr) =>{
  return{
    type : 'PASSDELETEMEDIALIST',
    payload : nr
  }
}

export const toggleAutomatedReply = (nr) =>{
  return{
    type : 'TOGGLEAUTOMATEDREPLY',
    payload : nr
  }
}

export const toggleMenuPriceList = (nr) =>{
  return{
    type : 'TOGGLEMENUPRICELIST',
    payload : nr
  }
}

export const viewMenuPricePopup = (nr) =>{
  return{
    type : 'VIEWMENUPRICEPOPUP',
    payload : nr
  }
}


export const toggleDepartmentDefaultMessage = (nr) =>{
  return{
    type : 'TOGGLEDEPARTMENTDEFAULTMESSAGE',
    payload : nr
  }
}

export const toggleDepartmentForms = (nr) =>{
  return{
    type : 'TOGGLEDEPARTMENTFORMS',
    payload : nr
  }
}

export const passDocumentDetail = (nr) =>{
  return{
    type : 'PASSDOCUMENTDETAIL',
    payload : nr
  }
}

export const passDocumentId = (nr) =>{
  return{
    type : 'PASSDOCUMENTID',
    payload : nr
  }
}

export const refershDashboadConversation = (nr) =>{
  return{
    type : 'REFRESHDASHBOARDCONVERSATION',
    payload : nr
  }
}

export const showMainSettings = (nr) =>{
  return{
    type : 'SHOWMAINSETTINGS',
    payload : nr
  }
}


export const showDepartmentSettings = (nr) =>{
  return{
    type : 'SHOWDEPARTMENTSETTINGS',
    payload : nr
  }
}

export const showPopupCompanyCategory = (nr) =>{
  return{
    type : 'SHOWPOPUPCOMPANYCATEGORY',
    payload : nr
  }
}


export const passCompanyCategoryDetail = (nr) =>{
  return{
    type : 'PASSCOMPANYCATEGORYDETAIL',
    payload : nr
  }
}

export const passChatTeamProfileRestrictionDetail = (nr) =>{
  return{
    type : 'PASSCHATTEAMPROGILERESTRICTIONDETAIL',
    payload : nr
  }
}

export const passPushNotificationDetail = (nr) =>{
  return{
    type : 'PASSPUSHNOTIFICATIONDETAIL',
    payload : nr
  }
}

export const showPopupTermsConditions = (nr) =>{
  return{
    type : 'SHOWPOPUPTERMSCONDITIONS',
    payload : nr
  }
}

export const showPopupQuickLinks = (nr) =>{
  return{
    type : 'SHOWPOPUPQUICKLINKS',
    payload : nr
  }
}

export const showPopupDeleteAccount = (nr) =>{
  return{
    type : 'SHOWPOPUPDELETEACCOUNT',
    payload : nr
  }
}

export const showPopupVideo = (nr) =>{
  return{
    type : 'SHOWPOPUPVIDEO',
    payload : nr
  }
}


export const showPopupContactSearch = (nr) =>{
  return{
    type : 'SHOWPOPUPCONTACTSEARCH',
    payload : nr
  }
}


export const blockUnblockChat = (nr) =>{
  return{
    type : 'BLOCKUNBLOCKCHAT',
    payload : nr
  }
}


export const passCompanyGroupsMembersIds = (nr) =>{
  return{
    type : 'PASSCOMPANYGROUPSMEMBERSIDS',
    payload : nr
  }
}

export const booleanChatReInitializeConversation = (nr) =>{
  return{
    type : 'BOOLEANCHATREINITIALIZECONVERSATION',
    payload : nr
  }
}

export const booleanShowWelcomeVideoAnimation = (nr) =>{
  return{
    type : 'BOOLEANSHOWWELCOMEVIDEOANIMATION',
    payload : nr
  }
}

export const booleanShowCompanyGroupShortcut = (nr) =>{
  return{
    type : 'BOOLEANSHOWCOMPANYGROUPSHORTCUTREDUCER',
    payload : nr
  }
}

export const showDirectoryProfilePopup = (nr) =>{
  return{
    type : 'SHOWDIRECTORYPROFILEPOPUP',
    payload : nr
  }
}

export const showPopupSocialMediaLibrary = (nr) =>{
  return{
    type : 'SHOWPOPUPSOCIALMEDIALIBRARY',
    payload : nr
  }
}