import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
//import { useHistory } from 'react-router-dom';

import TitlesLabels from '../../helpers/TitlesLabels';
import Utilities from '../../helpers/Utilities';
import { getConversationCompanyRequest } from '../../helpers/QueryChatCompanyRequest';
import ChatUtilities from '../../helpers/ChatUtilities';
import UrlSlugs from '../../helpers/UrlSlugs';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import FieldInput from '../FieldInput';
import SessionUtilities from '../../helpers/SessionUtilities';



const CompanyCreationRequestPopup = (props) => {

    //const history = useHistory();
    const props_directToChat = props.directToChat ?? true;
    const siteTitle = process.env.REACT_APP_site_title;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const maxCharacters = 2000;

    const defaultFormValues = {
        message: '',
        consultant_name: '',
        company_name: ''
    };

    const [formValues, setFormValues] = useState(defaultFormValues);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'consultant_name') {
            formValuesTemp = { ...formValuesTemp, ...{ consultant_name: value } };

        } else if (fieldName === 'company_name') {
            formValuesTemp = { ...formValuesTemp, ...{ company_name: value } };

        } else if (fieldName === 'message') {

            const charactersCounter = Utilities.charactersCounter(value);
            if (parseInt(charactersCounter) > maxCharacters) {
                Utilities.messagePopup('error', `Only ${maxCharacters} characters allowed!`);
                return false;
            }

            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ message: value } };

        }

        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);
    }

    const clearMessage = () => {
        setFormValues(defaultFormValues);
    }


    const submitCompanyRequestCreation = async () => {

        const fieldsChecker = ['company_name', 'message'];
        let errorCounter = 0;

        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {
                if (Utilities.isEmpty(value)) {
                    Utilities.addFormGroupClass(key);
                    errorCounter++;

                }
            }
        });

        if (errorCounter > 0) { return false; }

        const button_name = 'btn-request-continue';
        Utilities.elementDisabledStatus(button_name, false);


        if (Utilities.isEmpty(formValues.message)) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.messageIsRequired);
            return false;
        }

        const charactersCounter = Utilities.charactersCounter(formValues.message);
        if (parseInt(charactersCounter) > maxCharacters) {
            Utilities.messagePopup('error', `Only ${maxCharacters} characters allowed!`);
            return false;
        }


        const masterProfileSession = PagesAuthentication.getMasterUserDetailDetail();

        const passval = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (masterProfileSession.account_id ?? '0').toString(),
            consultant_name: formValues.consultant_name.toString(),
            company_name: formValues.company_name.toString(),
            message: formValues.message
        }


        Utilities.elementDisabledStatus(button_name, true);
        const conversationDB = await getConversationCompanyRequest(passval);
        Utilities.elementDisabledStatus(button_name, false);


        if (Object.keys(conversationDB).length > 0) {

            if (parseInt(conversationDB.success) === 0) {
                Utilities.messagePopup('error', conversationDB.successMessage);
                return false;
            }


            if (props_directToChat) {
                const conversation = conversationDB.result.beonchat_conversation_details??{};
                let chatPassValue = ChatUtilities.encryptChatDetail(conversation);

                ChatUtilities.saveAccountsConversationDetail(conversation);
                //history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(conversation.account_id) )}/${chatPassValue}`);
                window.location.href = `/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation.account_id))}/${chatPassValue}`;

            } else {

                if (parseInt(conversationDB.success) === 1) {
                    Utilities.messagePopup('success', TitlesLabels.alertMessages.requestSent);
                }
            }


            props.handleSubmit();

        } else {
            Utilities.messagePopup('error', errorMsg);
        }

    }


    useEffect(() => {

        setFormValues(defaultFormValues);

    }, [props]);



    return (

        <Modal id="popup-company-creation-request" show={props.showPopup} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-dialog-theme "  >
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4 d-flex"></Modal.Header>
            <Modal.Body className="px-4 px-md-5 mt-n5 pb-5">

                <div className='font-gotham-bold fs-25 text-center '>
                    {TitlesLabels.general.request}<br />
                    <div className='fs-20 fs-md-18 font-gotham-bold color-theme-grey mt-n1'>{TitlesLabels.general.additionalCompany}</div>
                </div>

                <div className="px-2 mx-auto  form-theme  pt-4">


                    <FieldInput inputName="company_name" inputLabel="additional company name" inputValue={formValues.company_name} pushValue={pushValue} checkerType="general" />

                    <FieldInput inputName="consultant_name" inputLabel={`${siteTitle} consultant (if applicable)`} inputValue={formValues.consultant_name} pushValue={pushValue} checkerType="general" />

                    <div className="form-group border-0 active px-0">
                        <textarea id="request_message" name="message" className="form-control text-tranform-initial px-0" rows="7" placeholder={`type here (max ${maxCharacters} characters)`} value={formValues.message} onChange={(e) => pushValue(e, 'message')} ></textarea>
                        <label className="form-label px-0">message</label>
                    </div>


                    <div className="mt-5">
                        <div className='mb-3 text-center fs-md-14'><span className='cursor-pointer text-color-blue' onClick={() => clearMessage()}>clear</span></div>
                        <button type="button" id="btn-request-continue" className="btn-theme-black button-has-loader" onClick={() => submitCompanyRequestCreation()}>submit</button>
                    </div>

                </div>

            </Modal.Body>

        </Modal>
    )
}

export default CompanyCreationRequestPopup;
