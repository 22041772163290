import axios from 'axios';
import ApiUrls from './ApiUrls';


export const uploadProfileLogo = async (formData) => {
    let responseDetail = { status: 0 };

    try {
        const responseURL = await axios.post(ApiUrls.awsUploadFilesProfileImage, formData);
        if (parseInt(responseURL.status) === 200) {
            responseDetail = { ...responseURL.data };
        }


    } catch (e) {

    }

    return responseDetail;
}


export const uploadCompanyBranchImage = async (formData) => {
    let responseDetail = { status: 0 };

    try {
        const responseURL = await axios.post(ApiUrls.awsUploadFilescompanyBranchImage, formData);
        if (parseInt(responseURL.status) === 200) {
            responseDetail = { ...responseURL.data };
        }


    } catch (e) {

    }

    return responseDetail;
}
