import axios from 'axios';
import ApiUrls from './ApiUrls';

export const getCompanyAppLinks = async (companyId = 0) => {

    let responseDetail = { status: 0 };

    if (parseInt(companyId) > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.companyOthers, { company_id: companyId, types: "app-links" });

            if (parseInt(responseURL.status) === 200) {
                const app_links = responseURL.data.app_links ?? {};
                if (Object.keys(app_links).length > 0 && parseInt(app_links.status ?? 0) == 1) {
                    responseDetail = { ...responseDetail, status: 1, ...app_links };
                }

            }

        } catch (e) { }
    }

    return responseDetail;

}


export const getListCompanyByMobile = async (code = '', number = '') => {

    let responseDetail = []

    code = parseInt(code);
    number = parseInt(number);

    if (parseInt(code) > 0 && parseInt(number) > 0) {

        try {
            console.log(ApiUrls.searchCompanyByMobile);
            const responseURL = await axios.post(ApiUrls.searchCompanyByMobile, { code: code, number: number });
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;

            }

        } catch (e) { }
    }
    return responseDetail;

}