import React from 'react';
import {  useHistory } from 'react-router-dom';
import UrlSlugs from '../helpers/UrlSlugs';

const SignupHeader = ( props ) => {

    const history = useHistory();

    const assetImgPath = process.env.REACT_APP_img_folder;
    
    const handleReturnHome = () => {
        let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login)??{};
        loginBasicInfo = Object.keys(loginBasicInfo).length>0 ? JSON.parse(loginBasicInfo) : {};

        if ( Object.keys(loginBasicInfo).length>0 ){
            
            let loginTypeStorage = localStorage.getItem(process.env.REACT_APP_session_logintype)??1;
            loginTypeStorage = parseInt( Object.keys(loginTypeStorage).length>0 ? JSON.parse(loginTypeStorage) : 1 );
            
            if ( loginTypeStorage===2 ){
                history.push(`/${UrlSlugs.departmentDashboard}`);
            }else{
                history.push(`/${UrlSlugs.dashboard}`);
            }
  
        }else{
            history.push(`/`);
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div className={`mb-4 ${ (props.classWrapAttr??'')!=='' ? props.classWrapAttr : ''}`}>
            <div className='cursor-pointer' onClick={ handleReturnHome }><img src={`${assetImgPath}/logo/logo-dark.svg`}  alt="logo" className='signup-logo' /></div>
            { (props.title??'')!=='' && <div className={`font-gotham-bold fs-20 mt-4 ${ (props.classAttr??'')!=='' ? props.classAttr : ''}`}>{props.title}</div> }
        </div>
    )
}

export default SignupHeader;
