import Utilities from '../helpers/Utilities';

const siteTitle = process.env.REACT_APP_site_title;
const companyName = process.env.REACT_APP_company_name;


export const loginDetailTemplate = async (dataProvided = {}) => {
    const windowNavigator = window.navigator;

    const globalDateFull = new Date();
    const globalCurrentDayNum = globalDateFull.getDate();
    const globalCurrentMonthNum = globalDateFull.getMonth();

    const dateText = Utilities.months(globalCurrentMonthNum) + ' ' + globalCurrentDayNum + ' ' + Utilities.returnTimeFormat();
    let locationArray = [];

    if (!Utilities.isEmpty(dataProvided.location.city_name ?? '')) {
        locationArray.push(dataProvided.location.city_name);
    }

    if (!Utilities.isEmpty(dataProvided.location.country_name ?? '')) {
        locationArray.push(dataProvided.location.country_name);
    }

    let locationText = locationArray.length === 0 ? '' : '- ' + locationArray.join(', ');

    let templateHtml = `<table  style="font-family: Arial, Helvetica, sans-serif;color:#231F20;font-size: 14px;" width="600" cellspacing="0" cellpadding="0" border="0" align="center">
                            <tr><td style="padding-top:20px;"><img src="https://s3.ap-south-1.amazonaws.com/image.directory.beonchat/beonchat/beonchat_asset/logo_beonchat.png" width="120"/></td></tr>
                            <tr>
                                <td>
                                    <table width="100%" cellspacing="0" cellpadding="0" border="0" >
                                        <tr>
                                            <td style="padding:0 50px 10px 50px;text-align: center;">
                                                <table width="80%" cellspacing="0" cellpadding="0" border="0" align="center">
                                                    <tr>
                                                        <td style="text-align:center;padding: 20px;border-bottom:1px solid #e1e1e1">
                                                            <p style="font-size:20px;line-height: 1em;"><strong>We noticed a new login,</strong></p>
                                                            <p style="font-size:18px;line-height: 1.2em;"><strong>${dataProvided.name}</strong></p>
                                                            <p style="color: gray;font-size:16px;line-height: 1.2em;">We've noticed a login from a device that don't usually use.</p>
                                                            <img src="https://s3.ap-south-1.amazonaws.com/image.directory.beonchat/beonchat/beonchat_asset/monitor-icon.jpg" width="80" height="" style="border:2px solid #231F20;border-radius: 50%;margin: 15px 0;"/>
                                                            <p style="font-size:18px;line-height: 1.2em;"><strong>${windowNavigator.userAgentData.platform}. ${windowNavigator.browserDetection}${locationText}</strong></p>
                                                            <p style="color: gray;font-size:16px;line-height: 1.2em;"><strong>${dateText}</strong></p>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-size:12px;padding: 10px 0;color: gray;text-align: center;">
                                    <strong>You received this email to let you know about important changes to you ${siteTitle} account and services<br />
                                    &copy; ${new Date().getFullYear()} ${companyName}.</strong>
                                </td>
                            </tr>
                        </table>`;

    return templateHtml;
}
