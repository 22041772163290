import React from 'react';

const DirectorySearchItemTemplate = (props) => {
    return <div>
        <div>{props.name ?? ''}</div>
        <div><span className="counter fs-16 fs-md-14">{props.counter ?? '0'}</span></div>
    </div>

}


export default DirectorySearchItemTemplate;