import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import TitlesLabels from '../../helpers/TitlesLabels';
import Utilities from '../../helpers/Utilities';


const CompanyCreationRejectPopup = (props) => {


    const maxCharacters = 2000;

    const defaultFormValues = { message: '' };

    const [formValues, setFormValues] = useState(defaultFormValues);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'message') {

            const charactersCounter = Utilities.charactersCounter(value);
            if (parseInt(charactersCounter) > maxCharacters) {
                Utilities.messagePopup('error', `Only ${maxCharacters} characters allowed!`);
                return false;
            }

            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ message: value } };

        }

        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);
    }

    const clearMessage = () => {
        setFormValues(defaultFormValues);
    }


    const submitRejection = async () => {

        if (Utilities.isEmpty(formValues.message)) {
            Utilities.messagePopup('error', TitlesLabels.general.messageIsMandatory);
            return false;
        }

        const charactersCounter = Utilities.charactersCounter(formValues.message);
        if (parseInt(charactersCounter) > maxCharacters) {
            Utilities.messagePopup('error', `Only ${maxCharacters} characters allowed!`);
            return false;
        }

        props.handleSubmit(formValues.message);

    }


    useEffect(() => {


    }, []);



    return (

        <Modal id="popup-company-creation-request" show={props.showPopup} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-dialog-theme "  >
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4">{TitlesLabels.general.reasonForRejection}</Modal.Header>
            <Modal.Body className="px-4 px-md-5 pb-5">

                <div className="px-2 mx-auto  form-theme">

                    <div className="form-group border-0 active px-0">
                        <textarea id="request_message" name="message" className="form-control text-tranform-initial px-0" rows="7" placeholder={`type here (max ${maxCharacters} characters)`} value={formValues.message} onChange={(e) => pushValue(e, 'message')} ></textarea>
                        <label className="form-label px-0">message</label>
                    </div>


                    <div className="mt-5">
                        <div className='mb-3 text-center fs-md-14'><span className='cursor-pointer text-color-blue' onClick={() => clearMessage()}>clear</span></div>
                        <button type="button" id="btn-request-continue" className="btn-theme-black button-has-loader" onClick={() => submitRejection()}>submit</button>
                    </div>

                </div>

            </Modal.Body>

        </Modal>
    )
}

export default CompanyCreationRejectPopup;
