import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

import Utilities from '../../helpers/Utilities';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import TitlesLabels from '../../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer, showLoader } from '../../actions';
import { companyGroupsList, createSubmitGroup, deleteGroup, getTeamMembersByCompanyId, updateSubmitGroup } from '../../helpers/QueryChatGroups';
import TeamMembersSelectionListingTemplate from '../../components/TeamMembersSelectionListingTemplate';
import SessionUtilities from '../../helpers/SessionUtilities';


const ManageCompanyGroups = ({ match }) => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const maxWords = 2000;

    const usersAssociated = PagesAuthentication.getUsersAssociated();

    /* let userLoginDetail = PagesAuthentication.getUserLoggedinDetail();
    const usersAssociated = PagesAuthentication.getUsersAssociated();
    let companyProfile = {}; */

    /* Object.entries(usersAssociated).map( ( [acctid, acctdetail] ) => {
        if (reqAccountID===parseInt(acctdetail.account_id??0)){
            userLoginDetail = Utilities.formatProfileDetail(acctdetail);
            companyProfile = Utilities.workToCompany(userLoginDetail);
        }
    }); */
    //console.log(companyProfile, userLoginDetail, reqAccountID);
    //const companyProfile = parseInt(userLoginDetail.account_type??0)===4 ?  Utilities.workToCompany(userLoginDetail) : {};

    const defaultValue = {
        group_id: 0,
        group_name: "",
        permission_status: 0,
        security_code: "",
        default_message: "",
        admin_members_list: "",
        team_members_list: ""
    };
    const defaultTeamsValue = { authorised: '', admin: '' };

    const [userLoginDetail, setUserLoginDetail] = useState({});
    const [companyProfile, setCompanyProfile] = useState({});

    const [formValues, setFormValues] = useState(defaultValue);
    const [formTeamsValues, setFormTeamsValues] = useState(defaultTeamsValue);
    const [groupList, setGroupList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [formAction, setFormAction] = useState('');
    const [groupDetail, setGroupDetail] = useState({});
    const [rowSelected, setRowSelected] = useState(-1);

    const [listMembers, setListMembers] = useState([]);
    const [listProfiles, setListProfiles] = useState([]);
    const [listProfilesAuthorised, setListProfilesAuthorised] = useState([]);
    const [listProfilesAdmins, setListProfilesAdmins] = useState([]);

    const [memberSelectedIDs, setMemberSelectedIDs] = useState([]);
    const [adminSelectedIDs, setAdminSelectedIDs] = useState([]);
    const [authorisedSelectedIDs, setAuthorisedSelectedIDs] = useState([]);

    const [showPassword, setShowPassword] = useState(false);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'group_name') {
            formValuesTemp = { ...formValuesTemp, ...{ group_name: value } };

        } else if (fieldName === 'security_code') {
            value = value.split('').length > 6 ? formValues.security_code : value;
            formValuesTemp = { ...formValuesTemp, ...{ security_code: value } };

            if (value.split('').length < 6) {
                Utilities.addFormGroupClass('security_code');
            } else {
                Utilities.removeFormGroupClass('security_code');
            }

        } else if (fieldName === 'default_message') {

            const wordsCounter = Utilities.wordsCounter(value);
            if (parseInt(wordsCounter) > maxWords) {
                Utilities.messagePopup('error', `Only ${maxWords} words is allowed!`);
                return false;
            }
            formValuesTemp = { ...formValuesTemp, ...{ default_message: value } };
        }

        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);
    }

    const toggleGroupAccess = (status = 0) => {
        const securityCode = Object.keys(groupDetail).length > 0 ? groupDetail.security_code : '';
        setFormValues({ ...formValues, permission_status: status, security_code: securityCode.toString() });
    }

    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }


    const returnToListResetForm = () => {
        setFormValues(defaultValue);
        setGroupDetail({});

        getGroupMembers(defaultValue.group_id);
        setFormTeamsValues(defaultTeamsValue);

        setMemberSelectedIDs([]);
        setAdminSelectedIDs([]);
        setAuthorisedSelectedIDs([]);

        setListProfilesAuthorised([]);
        setListProfilesAdmins([]);

        setShowPassword(false);
    }

    const addEditForm = (action, detailGroup = {}) => {

        if (action === 'add') {
            returnToListResetForm()

        } else if (action === 'edit') {
            setGroupDetail(detailGroup);
            setFormValues(detailGroup);
            getGroupMembers(detailGroup.group_id);
            setShowPassword(false);

            let teamNames = formTeamsValues, memberIdsArray = [];

            if (parseInt(detailGroup.team_members_count ?? 0) > 0) {
                teamNames = { ...teamNames, authorised: namesTemplating(detailGroup.team_members_list) };

                detailGroup.team_members_list.map(row => {
                    memberIdsArray.push(row.account_id.toString());
                });
                setAuthorisedSelectedIDs(memberIdsArray);
                //setListProfilesAuthorised(detailGroup.team_members_list);

            }

            memberIdsArray = [];
            if (parseInt(detailGroup.admin_members_count ?? 0) > 0) {
                teamNames = { ...teamNames, admin: namesTemplating(detailGroup.admin_members_list) };

                detailGroup.admin_members_list.map(row => {
                    memberIdsArray.push(row.account_id.toString());
                });
                setAdminSelectedIDs(memberIdsArray);
                setListProfilesAdmins(detailGroup.admin_members_list);
            }

            setFormTeamsValues(teamNames);

        } else if (action === 'members-authorised') {
            setMemberSelectedIDs(authorisedSelectedIDs);
            setListProfiles(listProfilesAuthorised);


        } else if (action === 'members-admins') {
            setListProfiles(listProfilesAdmins);
            setMemberSelectedIDs(adminSelectedIDs);

        } else {
            setRowSelected(-1);
            setFormValues(defaultValue);
            setGroupDetail({});
        }
        setFormAction(action);
    }

    const toggleSelected = (key) => {
        let rowSelectedTemp = (key === rowSelected) ? -1 : key;
        setRowSelected(rowSelectedTemp);
    }

    const reloadList = async () => {
        await getGroupList();
        addEditForm('');
        setRowSelected(-1);
    }

    const getGroupList = async (userId = 0, companyId = 0) => {
        userId = parseInt(userId);
        companyId = parseInt(companyId);

        //if ( parseInt(companyProfile.account_id??0)===0 ){ return false; }
        //console.log(userId, companyId);

        // flag :  0: all, 1: active, 2: suspend, 3: pending
        const paramMembers = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (userId > 0 ? userId : userLoginDetail.account_id ?? 0).toString(),
            company_id: (companyId > 0 ? companyId : companyProfile.account_id ?? 0).toString(),
        }

        //console.log(paramMembers);

        setIsLoading(true);
        const responseList = await companyGroupsList(paramMembers);
        setIsLoading(false);

        if (Object.entries(responseList).length === 0 || parseInt(responseList.success ?? 0) === 0) {
            return false;
        }

        const groupsListRes = responseList.result.comp_groups_list.group_list ?? [];
        setGroupList(groupsListRes);


    }

    const addEditDeleteSubmit = async (action) => {

        action = parseInt(action);
        let group_id = 0, paramGroups = {}, adminUpdateArray = [], authorisedUpdareArray = [], loopAccountId = "0", responseGroup = {};

        if (Object.keys(groupDetail).length > 0) {
            group_id = groupDetail.group_id;
        }

        let errorCounter = 0
        if ([1, 2].includes(action)) {

            Object.entries(formValues).map(([key, value]) => {
                if (['group_name', 'default_message'].includes(key)) {
                    if (Utilities.isEmpty(value)) {
                        Utilities.addFormGroupClass(key);
                        errorCounter++;
                    } else {
                        Utilities.removeFormGroupClass(key);
                    }
                }
            });

            Object.entries(formTeamsValues).map(([key, value]) => {
                if (['admin', 'authorised'].includes(key)) {
                    if (Utilities.isEmpty(value)) {
                        Utilities.addFormGroupClass(`team_${key}`);
                        errorCounter++;
                    } else {
                        Utilities.removeFormGroupClass(`team_${key}`);
                    }
                }
            });

            if (parseInt(formValues.permission_status) === 1) {
                if (Utilities.isEmpty(formValues.security_code)) {
                    Utilities.addFormGroupClass('security_code');
                    errorCounter++;
                } else {

                    if (formValues.security_code.toString().split('').length < 6) {
                        Utilities.messagePopup('error', TitlesLabels.alertMessages.passcodeInvalidNumericLength);
                        Utilities.addFormGroupClass('security_code');
                        errorCounter++;
                    }
                }
            } else {
                Utilities.removeFormGroupClass('security_code');
            }

            if (errorCounter > 0) {
                return false;
            }

            paramGroups = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: (userLoginDetail.account_id ?? 0).toString(),
                company_id: (companyProfile.account_id ?? 0).toString(),
                group_name: formValues.group_name.toString(),
                permission_status: formValues.permission_status.toString(),
                security_code: parseInt(formValues.permission_status) === 0 ? "" : formValues.security_code.toString(),
                default_message: formValues.default_message.toString(),
                admin_members_list: "",
                team_members_list: ""
            }

            if (action === 1) {
                paramGroups = { ...paramGroups, admin_members_list: adminSelectedIDs.join(","), team_members_list: authorisedSelectedIDs.join(",") };
            } else {
                authorisedUpdareArray = [];
                paramGroups = { ...paramGroups, group_id: formValues.group_id.toString() };

                // TEAM / AUTHORISED
                listProfilesAuthorised.map(row => {
                    loopAccountId = row.account_id.toString();
                    if (authorisedSelectedIDs.includes(loopAccountId)) {
                        authorisedUpdareArray.push({ account_id: loopAccountId, status: '1' });
                    }
                });
                groupDetail.team_members_list.map(roww => {
                    loopAccountId = roww.account_id.toString();
                    if (!authorisedSelectedIDs.includes(loopAccountId)) {
                        authorisedUpdareArray.push({ account_id: loopAccountId, status: '0' });
                    }
                });

                // ADMIN
                //console.log(listProfilesAuthorised, listProfilesAdmins);

                listProfilesAdmins.map(row => {
                    loopAccountId = row.account_id.toString();
                    if (adminSelectedIDs.includes(loopAccountId)) {
                        adminUpdateArray.push({ account_id: loopAccountId, status: '1' });
                    }
                });
                groupDetail.admin_members_list.map(roww => {
                    loopAccountId = roww.account_id.toString();
                    if (!adminSelectedIDs.includes(loopAccountId)) {
                        adminUpdateArray.push({ account_id: loopAccountId, status: '0' });
                    }
                });

                paramGroups = { ...paramGroups, admin_members_list: adminUpdateArray, team_members_list: authorisedUpdareArray };
            }

            //console.log(paramGroups);
            //return false;

            const button_name = `btn-${action === 1 ? 'submit' : 'update'}-groups`;
            Utilities.elementDisabledStatus(button_name, true);

            if (action === 1) {
                responseGroup = await createSubmitGroup(paramGroups);
            } else {
                responseGroup = await updateSubmitGroup(paramGroups);
            }

            Utilities.elementDisabledStatus(button_name, false);

            if (Object.entries(responseGroup).length === 0) {
                Utilities.messagePopup('error', errorMsg);
                return false;
            }

            if (parseInt(responseGroup.success) === 0) {
                Utilities.messagePopup('error', responseGroup.errorMessage);
                return false;
            }

            Utilities.messagePopup('success', action === 1 ? TitlesLabels.alertMessages.successfullyCreated : TitlesLabels.alertMessages.successfullyUpdated);


        } else if (action === 3) {

            if (group_id > 0) {

                paramGroups = {
                    api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                    account_id: (userLoginDetail.account_id ?? 0).toString(),
                    company_id: (companyProfile.account_id ?? 0).toString(),
                    group_id: group_id.toString(),
                }

                dispatch(showLoader(1));
                responseGroup = await deleteGroup(paramGroups);
                dispatch(showLoader(0));

                if (Object.entries(responseGroup).length === 0) {
                    Utilities.messagePopup('error', errorMsg);
                    return false;
                }

                if (parseInt(responseGroup.success) === 0) {
                    Utilities.messagePopup('error', responseGroup.errorMessage);
                    return false;
                }

                Utilities.messagePopup('success', TitlesLabels.alertMessages.successfullyDeleted);

            }

        }


        reloadList();
        returnToListResetForm();

    }

    const getGroupMembers = async (groupId = 0) => {

        const paramMembers = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (userLoginDetail.account_id ?? 0).toString(),
            company_id: (companyProfile.account_id ?? 0).toString(),
            group_id: (groupId || 0).toString(),
        }

        console.log((companyProfile.account_id ?? 0).toString());
        const responseList = await getTeamMembersByCompanyId(paramMembers);
        if (Object.entries(responseList).length === 0 || parseInt(responseList.success ?? 0) === 0) {
            setListProfiles([]);
            return false;
        }

        //const teamsListing = responseList.result.broadcast_team_members_list;
        const teamsListing = responseList.result.company_group_team_list ?? [];
        setListProfilesAuthorised(teamsListing);
        setListMembers(teamsListing);
        //setListProfiles(teamsListing);

    }

    const namesTemplating = (arrayProvided = []) => {
        let counter = arrayProvided.length;
        let names = counter > 0 ? arrayProvided[0].name : '';
        if (counter > 1) {
            names = `${names} +${counter - 1}`;
        }
        return names;
    }

    const handleClickInfo = (typeName = '') => {
        if (!Utilities.isEmpty(typeName)) {

            let messageContent = '';

            if (typeName === 1) {
                messageContent = TitlesLabels.alertMessages.groupAccessInfo;
            } else if (typeName === 2) {
                messageContent = TitlesLabels.alertMessages.groupAccessPasscodeInfo;
            } else if (typeName === 3) {
                messageContent = TitlesLabels.alertMessages.groupAccessGroupAdminInfo;
            }

            if (!Utilities.isEmpty(messageContent)) {
                Utilities.messagePopup('', messageContent);
            }
        }
    }


    const confirmDeletion = () => {
        confirmAlert({
            title: '',
            message: `${TitlesLabels.confirmMessages.wishToDelete} this group?`,
            buttons: [
                { label: 'No', onClick: () => { } },
                { label: 'Yes', onClick: () => { addEditDeleteSubmit(3) } }
            ]
        });
    }

    const cancelTeamSelection = () => {

        if (parseInt(formValues.group_id ?? 0) === 0) {
            setFormAction('add');
        } else {
            setFormAction('edit');
        }
    }

    const submitTeamSelection = (memberProvided = []) => {

        let memberidsArray = [], teamNames = formTeamsValues, adminTeamNamesArray = [], adminTeamArray = [], adminidsArray = [];
        memberProvided.map(row => {
            memberidsArray.push(row.account_id.toString());
            adminTeamArray.push(row);
        });


        if (formAction === 'members-authorised') {
            teamNames = { ...teamNames, authorised: namesTemplating(memberProvided) };
            setAuthorisedSelectedIDs(memberidsArray);

            if (Object.keys(listProfilesAdmins).length > 0) {
                memberProvided.map(row => {
                    if (adminSelectedIDs.includes(row.account_id.toString())) {
                        adminidsArray.push(row.account_id.toString());
                        adminTeamNamesArray.push(row);
                    }
                });
                setAdminSelectedIDs(adminidsArray);

                teamNames = { ...teamNames, admin: namesTemplating(adminTeamNamesArray) };
            }

            setListProfilesAdmins(adminTeamArray);


        } else if (formAction === 'members-admins') {
            teamNames = { ...teamNames, admin: namesTemplating(memberProvided) };
            setAdminSelectedIDs(memberidsArray);
        }

        setFormTeamsValues(teamNames);
        cancelTeamSelection();
    }

    const backToList = () => {
        returnToListResetForm();
        addEditForm('');
        setRowSelected(-1);
    }

    const pageContent = () => {

        if (['add', 'edit'].includes(formAction)) {

            return <section className="col-md-10 mx-auto mt-5 pt-3 checkbox-radio-default checkbox-radio-check-icon">

                <div className="form-theme text-lowercase">

                    <div className=' form-col-2 gap-none'>
                        <div className="form-group ">
                            <input type="text" name="group_name" className="form-control text-tranform-initial" placeholder=" " autoFocus autoComplete="off" value={formValues.group_name} onChange={(e) => pushValue(e, 'group_name')} ></input>
                            <label className="form-label">Group Name</label>
                        </div>


                        <div className="form-group optional" onClick={() => addEditForm('members-authorised')}>
                            <input type="text" name="team_authorised" className="form-control text-lowercase cursor-pointer" placeholder=" " value={formTeamsValues.authorised} autoComplete="off" readOnly ></input>
                            <label className="form-label">authorised team</label>
                            <div className="optional-label cursor-pointer"><span>Add/Delete</span></div>
                        </div>

                        <div className="form-group optional">
                            <div onClick={() => addEditForm('members-admins')}>
                                <input type="text" name="team_admin" className="form-control text-lowercase cursor-pointer" placeholder=" " value={formTeamsValues.admin} autoComplete="off" readOnly ></input>
                                <label className="form-label">group admin/s</label>
                            </div>
                            <div className="optional-label cursor-pointer d-flex align-items-center"><span className="mr-3">Add/Delete</span> <span className="input-label-icons cursor-pointer info-bolder-icon" onClick={() => handleClickInfo(3)}></span></div>
                        </div>

                        <div className="form-group active optional">
                            <label className="form-label">group access</label>
                            <div className="d-flex align-items-center py-2 fs-16">
                                <div className="d-flex align-items-center cursor-pointer" onClick={() => toggleGroupAccess(1)} ><input type="checkbox" className="mr-2" checked={`${parseInt(formValues.permission_status ?? 0) === 1 ? 'checked' : ''}`} /> restricted</div>
                                <div className="d-flex align-items-center ml-5 cursor-pointer" onClick={() => toggleGroupAccess(0)} ><input type="checkbox" className="mr-2" checked={`${parseInt(formValues.permission_status ?? 0) === 0 ? 'checked' : ''}`} /> open</div>
                            </div>
                            <div className="optional-label d-flex"><span className="input-label-icons cursor-pointer info-bolder-icon" onClick={() => handleClickInfo(1)}></span></div>
                        </div>

                        {
                            parseInt(formValues.permission_status ?? 0) === 1 &&
                            <div className="form-group optional">
                                <input type={`${showPassword ? 'number' : 'password'}`} name="security_code" className="form-control text-tranform-initial" placeholder=" " autoComplete="off" value={formValues.security_code} onChange={(e) => pushValue(e, 'security_code')} ></input>
                                <label className="form-label">Security passcode (numeric)</label>
                                <div className="optional-label d-flex">
                                    <span className={`input-label-icons cursor-pointer mr-2 ${showPassword ? 'eye-cross-icon' : 'eye-icon'}`} onClick={() => setShowPassword(showPassword ? false : true)}></span>
                                    <span className="input-label-icons cursor-pointer info-bolder-icon" onClick={() => handleClickInfo(2)}></span>
                                </div>
                            </div>
                        }


                    </div>


                    <div className="form-group border-0 mt-3">
                        <textarea name="default_message" className="form-control scroll-none text-tranform-initial bg-gray-important border-radius p-3" placeholder="default message" rows="7" autoComplete="off" value={formValues.default_message} onChange={(e) => pushValue(e, 'default_message')} ></textarea>
                        {/* <label className="form-label">Default Message</label> */}
                    </div>
                    <div className="text-right mt-n2"><span className="text-theme-green fs-14 fs-md-14">maximum {maxWords} words</span></div>


                    <div className="pt-4 pb-5 col-md-6 mx-auto button-group-boolean">
                        <button type="button" className="btn-theme-black" onClick={() => addEditForm('')}>cancel</button>
                        {
                            formAction === 'add' ?
                                <button type="button" id="btn-submit-groups" className="btn-theme-black button-has-loader" onClick={() => addEditDeleteSubmit(1)}>submit</button>
                                :
                                <button type="button" id="btn-update-groups" className="btn-theme-black button-has-loader" onClick={() => addEditDeleteSubmit(2)}>update</button>
                        }
                    </div>

                </div>

            </section>

        } else if (['members-admins', 'members-authorised'].includes(formAction)) {
            return <section className="checkbox-radio-default checkbox-radio-check-icon">
                <TeamMembersSelectionListingTemplate listProfiles={listProfiles} submitTeamSelection={submitTeamSelection} memberSelectedIDs={memberSelectedIDs} />
            </section>
        }
    }

    useEffect(() => {

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.companyGroups}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();
        //getGroupList();

    }, []);



    useEffect(async () => {

        let reqAccountID = match.params.mid || "0";
        if (reqAccountID !== "0") {
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);


            let companyProfileTemp = {}, userLoginDetailTemp = {};

            Object.entries(usersAssociated).map(([acctid, acctdetail]) => {
                if (reqAccountID === parseInt(acctdetail.account_id ?? 0)) {
                    userLoginDetailTemp = Utilities.formatProfileDetail(acctdetail);
                    companyProfileTemp = Utilities.workToCompany(userLoginDetailTemp);
                }
            });

            setUserLoginDetail(userLoginDetailTemp);
            setCompanyProfile(companyProfileTemp);

            getGroupList(userLoginDetailTemp.account_id, companyProfileTemp.account_id);
        }

    }, [match.params.mid]);

    return (
        <div className="special-message-info-page chat-template-messages-content-fq">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => triggerSetting()}>{TitlesLabels.settings}</div>
                            <div>{TitlesLabels.general.profiles}</div>
                            {companyProfile.hasOwnProperty('name') && <div>{companyProfile.name}</div>}
                            <div className="current cursor-pointer" onClick={() => backToList()}>{TitlesLabels.navigations.companyGroups}</div>

                        </li>
                        <li className="btn-option">
                            {
                                parseInt(formValues.group_id) === 0 ?
                                    <div className="btn-icon btn-plus" title={TitlesLabels.navigations.add} onClick={() => addEditForm('add')}></div>
                                    :
                                    <div className="btn-icon btn-trash" title={TitlesLabels.navigations.delete} onClick={() => confirmDeletion()}></div>
                            }

                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>
            </div>


            <div className="main-content">
                <div className="container">

                    {
                        Utilities.isEmpty(formAction) ?
                            <section className="pt-5 px-md-5">

                                {
                                    isLoading ?
                                        <ul className="ul-special-messages-preloader">
                                            {
                                                [...Array(3).keys()].map((key) => <li key={key}></li>)
                                            }
                                        </ul>
                                        :
                                        <ul className="template-list-option">
                                            {
                                                Object.entries(groupList).map(([key, row]) =>
                                                    <li key={key} className={`${rowSelected === key ? 'selected' : ''}`} onClick={() => toggleSelected(key)}>

                                                        <div className="temp-wrap" >
                                                            <div className="temp-title">{row.group_name}</div>
                                                            <div className="temp-msg color-theme-grey">{row.default_message}</div>
                                                        </div>

                                                        {
                                                            rowSelected === key &&
                                                            <div className="mt-3">
                                                                <button type="button" className="btn-theme-black col-md-2" onClick={() => addEditForm('edit', row)}>edit</button>
                                                            </div>
                                                        }

                                                    </li>
                                                )
                                            }
                                        </ul>
                                }

                                {
                                    (isLoading === false && Object.keys(groupList).length === 0) &&
                                    <div className="text-center text-lowercase no-record-label mt-5">{TitlesLabels.general.noRecordAvailable}</div>
                                }

                            </section>
                            :
                            pageContent()
                    }

                </div>
            </div>
        </div>
    )
}

export default ManageCompanyGroups;