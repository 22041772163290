import React, { useEffect, useState } from 'react';
import axios from 'axios';

import MobileSelection from '../../components/MobileSelection';
import ApiUrls from '../../helpers/ApiUrls';
//import Utilities from '../helpers/Utilities';

const LoginInputSelection = ( props ) => {

    const props_tabOption = parseInt(props.tabType??1);

    const [ loginView, setLoginView ] = useState(props.loginType ?? 0);
    const [ countryList, setCountryList ] = useState([]);

    const handeClick_loginView = ( type = 0) => {
        setLoginView(type);
        props.setLoginView(type);
    }

    const getCountries = async () => {
        await axios
            .post( ApiUrls.country , {} )
            .then( response => {
                if ( response.status===200 ){
                    setCountryList(response.data);
                }
            })
            .catch( (err) => {});
    }

    const changeCountryCode = ( passValues, inputName = '' ) => {
        //console.log(passValues);

        if( typeof(props.pushValuePhonecode) === 'function') {
            props.pushValuePhonecode( passValues.id.toString() );
        }

        if( typeof(props.changeCountryCode) === 'function') {
            props.changeCountryCode( passValues);
        }
        
        
    }

    

    const loginInputHtml = ( loginType = 0 ) => {
        let inputHmtml = <></>

        if ( loginType===1){
            inputHmtml = <div className="form-group">
                            <input type="text" name="email" className={`form-control  text-center ${ props.classNameInputAttr ?? '' }`} placeholder=" " autoFocus value={ (props.formValues.email ?? '').replace(' ', '') } onChange={ (e) => props.pushValue(e, 'email') }  autoComplete="new-password" ></input>
                            <label className={`form-label  text-center  ${ props.classNameInputLabelAttr ?? '' }`}>Email</label>
                        </div>

        }else if (loginType===2){
            inputHmtml = <>
                             <MobileSelection  alignment={`center`}  inputValue={props.formValues.phone_number} countryListProvided={countryList} inputLabel="phone number" countryCodeProvided={props.formValues.phone_code} inputListingId="list-country-current" inputName="phone_number" pushValue={props.pushValue} changeCountryCode={changeCountryCode} showPlaceholder={false} autoFocus={true} showDropdownArrow={true} dropDownClass={`show-arrow-important ${props_tabOption===2? 'text-left' : ''} `} />
                        </>
        }

        return (
            <>
                {
                    props_tabOption===2 ?
                        <div class="d-flex align-items-center justify-content-around mt-2 px-md-5 checkbox-radio-default">
                            <div class="text-size-semi-medium pb-4 d-flex font-gotham-bold  cursor-pointer" onClick={ () => handeClick_loginView(1) }>
                                <input type="radio" name="tab" checked={loginType===1?true:false} /><span class="ml-2">email</span>
                            </div>
                            <div class="text-size-semi-medium pb-4 ml-3 ml-md-0 d-flex font-gotham-bold  cursor-pointer" onClick={ () => handeClick_loginView(2) }>
                                <input type="radio" name="tab-option"checked={loginType===2?true:false} /><span class="ml-2">mobile number</span>
                            </div>
                        </div>
                    :
                        <div className="tab-login border-radius cursor-pointer mb-4 font-gotham-bold fs-18">
                            <div className={`${loginType===1 ? 'active' : ''}`} onClick={ () => handeClick_loginView(1) }>email</div>
                            <div className={`${loginType===2 ? 'active' : ''}`}  onClick={ () => handeClick_loginView(2) }>phone no</div>
                        </div>

                }
               

               
                    
                { inputHmtml }
            </>
        )
    }

    useEffect( () => {
        getCountries();
    }, []);

    useEffect( () => {
        setLoginView( props.loginType ?? 0 );
    }, [props.loginType]);


    return (
       <>
            { loginInputHtml(loginView) }
       </>
    )
}

export default LoginInputSelection;