import axios from 'axios';
import ApiUrls from './ApiUrls';

class QueryChatUtilities {

    getConversationProfileListingLoadingStatus = () => {
        const SESSION_PROFILE_LOADED_BOOLEAN = process.env.REACT_APP_sesion_conversation_profiles_loaded_boolen;
        return localStorage.getItem(SESSION_PROFILE_LOADED_BOOLEAN) ?? '0';
    }

    updateConversationProfileListingLoadingStatus = (statusBoolen = true) => {
        const SESSION_PROFILE_LOADED_BOOLEAN = process.env.REACT_APP_sesion_conversation_profiles_loaded_boolen;
        localStorage.setItem(SESSION_PROFILE_LOADED_BOOLEAN, statusBoolen);
    }

    getContactPagination = async (passValues = {}) => {
        let responseDetail = [];

        if (Object.entries(passValues).length > 0) {
            try {
                /* console.log('getContactPagination');
                console.log(ApiUrls.bizcomHomeConversationList);
                console.log(passValues); */

                const serverResponse = await axios.post(ApiUrls.bizcomHomeConversationList, passValues);
                const responseDetailTemp = serverResponse.data;
                if (parseInt(responseDetailTemp.success) === 1) {
                    if ((responseDetailTemp.result.home_conversation_list ?? []).length > 0) {
                        responseDetail = responseDetailTemp.result.home_conversation_list;
                    }
                }
            } catch (e) {

            }
        }
        return responseDetail;
    }

    getBeonchatGroupsConversationDetail = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                /* console.log('getBeonchatGroupsConversationDetail');
                console.log(ApiUrls.bizchatCompanyBroadcastDetails);
                console.log(passValues); */

                const serverResponse = await axios.post(ApiUrls.bizchatCompanyBroadcastDetails, passValues);
                responseDetail = serverResponse.data;
                //console.log(responseDetail);

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }


    getBeonchatConversationDetail = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {
                const serverResponse = await axios.post(ApiUrls.bizchatBeonchatConversationDetails, passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    getConversationDetail = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {
                const serverResponse = await axios.post(ApiUrls.bizchatGetConversationDetails, passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    getAppointmentReservationDetail = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                //console.log('getAppointmentReservationDetail');
                //console.log(ApiUrls.diaryGetAppointmentReservationDetails);
                //console.log(passValues);


                const serverResponse = await axios.post(ApiUrls.diaryGetAppointmentReservationDetails, passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    requestAppointmentComplaint = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                /*  console.log('requestAppointmentComplaint');
                 console.log(ApiUrls.bizchatRequestAppointmentComplaint);
                 console.log(passValues); */

                const serverResponse = await axios.post(ApiUrls.bizchatRequestAppointmentComplaint, passValues);
                responseDetail = serverResponse.data;
                //console.log(responseDetail);

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    cancelAppointmentReservation = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                /* console.log('cancelAppointmentReservation');
                console.log(ApiUrls.bizchatCancelAppointmentReservation);
                console.log(passValues); */

                const serverResponse = await axios.post(ApiUrls.bizchatCancelAppointmentReservation, passValues);
                responseDetail = serverResponse.data;
                //console.log(responseDetail);

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    acceptAppointmentReservation = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                /*  console.log('acceptAppointmentReservation');
                 console.log(ApiUrls.bizchatAcceptAppointmentReservation);
                 console.log(passValues); */

                const serverResponse = await axios.post(ApiUrls.bizchatAcceptAppointmentReservation, passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    amendAppointmentReservation = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                /*  console.log('amendAppointmentReservation');
                 console.log(ApiUrls.bizchatAmendAppointmentReservation);
                 console.log(passValues); */

                const serverResponse = await axios.post(ApiUrls.bizchatAmendAppointmentReservation, passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    requestComplaintReply = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {
                const serverResponse = await axios.post(ApiUrls.bizchatRequestComplaintReply, passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    acceptRejectAccessTeamCommunication = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                /* console.log('acceptRejectAccessTeamCommunication');
                console.log(ApiUrls.bizchatAcceptRejectAccessTeamCommunication);
                console.log(passValues); */

                const serverResponse = await axios.post(ApiUrls.bizchatAcceptRejectAccessTeamCommunication, passValues);
                responseDetail = serverResponse.data;

                //console.log(responseDetail);

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    blockUnblockAccess = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                console.log('blockUnblockAccess');
                console.log(ApiUrls.bizchatReverseAccessteamCommunication);
                console.log(passValues);

                const serverResponse = await axios.post(ApiUrls.bizchatReverseAccessteamCommunication, passValues);
                responseDetail = serverResponse.data;
                console.log(responseDetail);

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }


    reverseAccessCommunication = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                /* console.log('reverseAccessCommunication');
                console.log(ApiUrls.bizchatReverseAccessCommunicationUserCommunication);
                console.log(passValues); */

                const serverResponse = await axios.post(ApiUrls.bizchatReverseAccessCommunicationUserCommunication, passValues);
                responseDetail = serverResponse.data;
                //console.log(responseDetail);

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    resendTeamAccessComm = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                /* console.log('resendTeamAccessComm');
                console.log(ApiUrls.bizchatResendAccessTeamCommunication);
                console.log(passValues); */

                const serverResponse = await axios.post(ApiUrls.bizchatResendAccessTeamCommunication, passValues);
                responseDetail = serverResponse.data;

                //console.log(responseDetail);

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    acceptRejectAccessCommunication = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {
                const serverResponse = await axios.post(ApiUrls.bizchatAcceptRejectAccessCommunication, passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

    resendUserComm = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {
                const serverResponse = await axios.post(ApiUrls.bizchatResendAccessUserCommunication, passValues);
                responseDetail = serverResponse.data;

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }


    cancelGlanceRequest = async (passValues = {}) => {
        let responseDetail = {};

        if (Object.entries(passValues).length > 0) {
            try {

                /* console.log('cancelGlanceRequest');
                console.log(ApiUrls.chatFollowerNoticeCancelGlanceRequest);
                console.log(passValues); */

                const serverResponse = await axios.post(ApiUrls.chatFollowerNoticeCancelGlanceRequest, passValues);
                responseDetail = serverResponse.data;

                //console.log(responseDetail);

            } catch (e) {
                responseDetail = {};
            }
        }
        return responseDetail;
    }

}



export default new QueryChatUtilities();

