import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { askPermissionToReceiveNotifications } from '../../components/firebase/PushNotification';
import {  useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import jQuery from 'jquery';

import CryptoJS from 'crypto-js';

import { showHeader, showFooter, pageTitle, globalNotificationCounter as globalNotificationCounterReducer } from '../../actions';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';
import { sendEmailGeneral } from '../../helpers/SendingEmails';
import { loginDetailTemplate } from '../../helpers/EmailTemplates';

import PasswordInput from '../../components/PasswordInput';
import LoginInputSelection from './LoginInputSelection';
import LoginConnect from './LoginConnect';
import SessionUtilities from '../../helpers/SessionUtilities';
import PagesAuthentication from '../../helpers/PagesAuthentication';

const LoginClean = ({match}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const initialMessage = Utilities.labelUsernamePasswordNotMatched;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    //const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const defaultLocation = Utilities.defaultLocation();

    const [ formValues, setFormValues ] = useState({ account_id : '0', email : '', password : '', fcm_token : '', utc_date : '' , brower_id : '', phone_code : defaultLocation.country_id.toString(), phone_number : '' });
    const [ defaultResMessage, setDefaultResMessage ] = useState({ status : false, msg : initialMessage });
    const [ loginType, setLoginType ] = useState(0); // 0: selection, 1: email, 2: mobile

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='email'){
            setFormValues({...formValues, ...{ email : value } });
        }else  if ( fieldName==='password'){
            setFormValues({...formValues, ...{ password : value } });
        }else  if ( fieldName==='phone_number'){
            setFormValues({...formValues, ...{ phone_number : value } });
        }
    }

    const showResMessageWarning = () => {
        let timer = setTimeout( () => { 
            setDefaultResMessage({ status : false , msg : '' });
            clearTimeout(timer);
        }, 2000);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        const btn_name = 'btn-login-user';
        Utilities.elementDisabledStatus(btn_name, false );

        setDefaultResMessage( { status : 0, msg : errorMsg} );
        
        const usernameTemp = parseInt(loginType)===2 ? formValues.phone_number : formValues.email;
        const userAccountId = parseInt(formValues.account_id ?? 0);

    
        if ( userAccountId===0 ){
            if ( Utilities.isEmpty(usernameTemp) || Utilities.isEmpty(formValues.password) ){
                setDefaultResMessage({ status :true, msg : Utilities.labelUsernamePasswordMandatory });
                showResMessageWarning();
                Utilities.elementDisabledStatus(btn_name, false );

                return false;
            }
        }


            Utilities.elementDisabledStatus(btn_name, true );
                
                const windowNavigator = window.navigator;
                const generatedToken = Utilities.generateToken().toString();

                await axios
                    .post( ApiUrls.userGetByCredentail , {
                                                        ...formValues,
                                                        login_choose : loginType.toString(),
                                                        browser_name: windowNavigator.appVersion.toLowerCase(),
                                                        token_request: generatedToken
                                                    })
                    .then( async (response) => {
                        const resStatus = response.status;

                        Utilities.elementDisabledStatus(btn_name, false );

                        if ( resStatus===200 ){
                            const resData = response.data;
                            const resStatus = parseInt(resData.status);
                            const resLoginType = parseInt(resData.logintype);

                            if ( resStatus===1){

                                if ( parseInt(resData.detail.status)===1 ){
                                    
                            
                                    // REMOVE WELCOME MESSAGE
                                    localStorage.removeItem(process.env.REACT_APP_session_welcome_mesage);

                                    SessionUtilities.setSessionToken(generatedToken);

                                    localStorage.setItem(
                                        process.env.REACT_APP_session_logintype,
                                        JSON.stringify(resLoginType)
                                    );

                                    localStorage.setItem(
                                        process.env.REACT_APP_session_login,
                                        JSON.stringify(resData.detail)
                                    );
                                    
                                    localStorage.setItem(
                                        process.env.REACT_APP_session_login_started,
                                        Date.now()
                                    );
                                    
                                    if ( resLoginType===1 ){
                                        
                                        localStorage.setItem(
                                            process.env.REACT_APP_session_accounts,
                                            JSON.stringify(resData.accounts)
                                        );

                                        // SENDENG EMAIl LOGIN SECURITY
                                        if ( parseInt(resData.new_device)===1 ){
                                            let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_session_location);
                                            if ( sessionLocation!==null ){
                                                sessionLocation = JSON.parse(sessionLocation);
                                            }

                                            let emailData = { ...resData.detail, location : { ...sessionLocation } };
                
                                            Utilities.elementDisabledStatus(btn_name, true );
                                            await sendEmailGeneral({
                                                api_token: generatedToken.toString(),
                                                account_id: (PagesAuthentication.getUserLoggedinDetail().account_id??'').toString(),
                                                to_email : formValues.email.toString(),
                                                subject : TitlesLabels.general.security_alert.toString(),
                                                message : (await loginDetailTemplate( emailData )).toString()
                                            });
                                            Utilities.elementDisabledStatus(btn_name, false );
                                        }

                                        parent.location = UrlSlugs.dashboard;

                                    }else if ( resLoginType===2 ){

                                        localStorage.setItem(
                                            process.env.REACT_APP_session_department_active,
                                            JSON.stringify(resData.detail)
                                        );
                                        

                                        localStorage.setItem(
                                            process.env.REACT_APP_session_company_detail,
                                            JSON.stringify(resData.company_detail)
                                        );

                                        localStorage.setItem(
                                            process.env.REACT_APP_session_company_departments,
                                            JSON.stringify(resData.departments)
                                        );

                                        parent.location = UrlSlugs.departmentDashboard;
                                    }

                                }else{
                                    setDefaultResMessage({ status : true , msg : Utilities.labelAccountNotActive }) ;
                                    showResMessageWarning();
                                }
                            
                            }else if ( resStatus===2 ) {
                                setDefaultResMessage({ status : true, msg : Utilities.labelCouldNotLoginCompanyAccount });
                                showResMessageWarning();

                            }else{
                                setDefaultResMessage({ status : true, msg : initialMessage });
                                showResMessageWarning();

                            }

                        }else{
                            setDefaultResMessage({ status : true, msg : initialMessage});
                            showResMessageWarning();
                        } 
            
                    })
                    .catch( (err) => {
                        setDefaultResMessage({ status : true, msg : initialMessage});
                        showResMessageWarning();

                        Utilities.elementDisabledStatus(btn_name, false );
                    });


    }

    const udpateFormValues = async () => {

        const timeUTC = moment.utc();
        let formValuesTemp = formValues;

        let fcmToken = await askPermissionToReceiveNotifications();
        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString(); 
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex);
        
        if ( Utilities.isEmpty(fcmToken) ){
            const fcmTokenTemp = localStorage.getItem( process.env.REACT_APP_fs_fcm_token );
            fcmToken = JSON.parse(fcmTokenTemp) || '';

        }else{
            localStorage.setItem(
                process.env.REACT_APP_fs_fcm_token,
                JSON.stringify(fcmToken)
            );
        }

        formValuesTemp = {...formValuesTemp,
                            ...{ 
                                fcm_token : fcmToken.toString(),
                                utc_date : dateFormatted.toString(),
                                brower_id : browserUniqueID.toString(),
                            }
                        };
        //console.log(formValuesTemp);
        setFormValues(formValuesTemp);
    }

    const handleClick_loginView = ( type = 0 ) => {
        setFormValues({...formValues, ...{ email : '', phone_number : '' } });
        setLoginType(type);
    }

    const pushValuePhonecode = ( providedValue = '') =>{
        console.log(providedValue);
        setFormValues({...formValues, ...{ phone_code : providedValue } });
    }

    const switchLoginView = () => {
        let htmlView = '';

        if ( loginType===1 ){
            htmlView = <div className="mt-n2"><span className="text-blue cursor-pointer" onClick={ () => handleClick_loginView(2) }>login with phone number</span></div>

        }else if ( loginType===2 ){
            htmlView = <div className="mt-n2"><span className="text-blue cursor-pointer" onClick={ () => handleClick_loginView(1) }>login with email</span></div>
        }

        return htmlView;
    }


    const loginSocialDetail = ( dataProvided = {}, status = 0 ) => {
        
        if ( Object.keys(dataProvided).length===0 ){
            setDefaultResMessage( { status : true, msg : TitlesLabels.alertMessages.loginInvalidCredential } );
            hideLoginWarningMessage();
            return false;
        }

        // IF NO SOCIAL BUT HAS CREDENTIAL
        if ( parseInt(status)===2 ){
            setDefaultResMessage( { status : true, msg : TitlesLabels.alertMessages.loginAlreadyWithPassword } );

            setFormValues({...formValues, ...{ account_id : dataProvided.account_id ?? '', email : dataProvided.email ?? '' } });
            setLoginType(1);
            hideLoginWarningMessage();

            return false;
        }

        // IF HAVE SOCIAL CREDENTIAL
        setFormValues({...formValues, ...{ account_id : dataProvided.account_id ?? '', email : dataProvided.app_access_token ?? '' } });
        jQuery('#btn-login-user').trigger('click');
    }

    const hideLoginWarningMessage = () => {
        setTimeout( () => {
            setDefaultResMessage({ ...defaultResMessage, status : false });
        }, 5000);
    }

    useEffect( async () => {

        dispatch( pageTitle( TitlesLabels.siteTitles.login ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );

        udpateFormValues();

        dispatch( globalNotificationCounterReducer(0) );

        setTimeout( () => {
            jQuery('body').css({ marginBottom : 0, backgroundColor: 'transparent' });
        }, 1000);
       

    }, []);


    useEffect( async () => {

        let reqID = match.params.id;
        if ( typeof reqID!=='undefined' ){
            reqID = atob(reqID);
            
            if ( parseInt(reqID)>0 ){
                sessionStorage.setItem( process.env.REACT_APP_session_jazenet_request_profileid, reqID );
            }
        }

        udpateFormValues();

    }, [match.params.id] );


    return (
        <div className="login-page">
            <form className="login-form mx-auto  text-lowercase form-theme" autoComplete="off">
                        
                <div className="mt-4">
                    <LoginInputSelection loginType={loginType} formValues={formValues}  pushValue={pushValue} pushValuePhonecode={pushValuePhonecode} setLoginView={setLoginType}/>
                </div>

                <PasswordInput inputName="password" inputLabel="Password" inputClass="text-center" inputLabelClass="text-center" inputValue={ formValues.password } pushValue={pushValue} />

                { switchLoginView() }

                <div className={ `process-message py-1 text-center ${ defaultResMessage.status===true ? '' : 'invisible' }` }>{ defaultResMessage.status===true ? defaultResMessage.msg : initialMessage }</div>

                
                <div className="d-flex justify-content-center">
                    <LoginConnect loginSocialDetail={loginSocialDetail}/>
                </div>
                
                <div className="button-wrap text-lowercase text-center mt-4">
                    <div className="mb-3">
                        <button type="submit" id="btn-login-user" className="btn-theme-black text-lowercase button-has-loader" onClick={ (e) => submitForm(e) }>Login</button>
                    </div>
                
                </div>

            </form>
        </div>
    )
}

export default LoginClean;