import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Utilities from '../../helpers/Utilities';

import TimePicker from '../TimePicker';

const TimePickerSelection = (props) => {


    const timeFormatted = Utilities.returnTimeFormat();
    const showPopUp = props.showPopUp ?? false;
    const providedTime = props.providedTime ?? timeFormatted;

    const [requestTime, setRequestTime] = useState(providedTime);


    const handleClosePopUp = () => {
        if (typeof props.handleClosePopUp !== 'undefined') {
            props.handleClosePopUp(requestTime || timeFormatted);
        }
    }



    return (

        <Modal id="popup-timepicker-selection" show={showPopUp} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" backdrop="static" centered dialogClassName="modal-dialog-datepicker" backdropClassName="z-index-1111" className="z-index-1111" >
            <Modal.Header className="font-gotham-bold fs-25 text-center d-inline pt-4 text-lowercase border-0">select time</Modal.Header>
            <Modal.Body className='modal-body pt-0 pb-4 px-4'>

                <TimePicker setRequestTime={setRequestTime} />

                <div className='mt-4  d-flex alin-items-center justify-content-center'>
                    <button type="button" className='btn-circle-cross mx-3' title="close" onClick={() => handleClosePopUp()} />
                    <button type="button" className='btn-circle-checked mx-3' title="submit" onClick={() => handleClosePopUp()} />
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default TimePickerSelection;
