import axios from 'axios';
import ApiUrls from './ApiUrls';

export const gettingMenuSettinListStatus = async (passval) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {

            const responseURL = await axios.post(ApiUrls.menuHomeList, passval);
            if (parseInt(responseURL.status) === 201) {
                responseDetail = responseURL.data.result ?? {};
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}