import React from 'react';
import { Link } from 'react-router-dom';
import UrlSlugs from '../../helpers/UrlSlugs';

const CampaignPersonalOptionCompanyContent = () => {


    return (
        <>
            <div className='text-center font-gotham-bold fs-20 mb-3 mb-4'>business account</div>

            <div className=" text-size-medium mb-4 text-center ">
                <div className='color-theme-red font-gotham-bold mb-1'>please note</div>
                <div className='font-gotham-book fs-17 fs-md-16 color-theme-green'>company accounts can only be created by <br className='d-none d-lg-inline' />existing users who have a personal account</div>
            </div>

            <div className='text-center font-gotham-bold pb-lg-3'>
                <div className='mb-4'>if you do not have a personal <br className='d-none d-lg-inline' />account, please create one by clicking the <br className='d-none d-lg-inline' />button below</div>
                <Link to={`/${UrlSlugs.signup_campaignPersonalInfo}`} className="btn-theme-green text-lowercase button-has-loader col-sm-9 mx-auto">create account</Link>
                <div className='py-md-1'></div>



                <div className='my-4 position-relative'>
                    <span className='px-2 color-theme'>or</span>
                    <div className='border-top position-absolute w-100' style={{ top: `45%` }}></div>
                </div>

                <div className='mb-4'>click below to sign in if you are an existing user</div>
                <Link t to={`/${UrlSlugs.signup_personalLogin}`} className="btn-theme-black bg-white text-lowercase button-has-loader col-sm-9 mx-auto">
                    <span className="text-black">Sign In</span>
                </Link>

            </div>
        </>
    );
}

export default CampaignPersonalOptionCompanyContent;
