import React, { useState } from 'react';
import Utilities from '../../helpers/Utilities';
import { getCitiesByState, getPofilesByIds, getSuburbListByCity } from '../../helpers/QueryDirectorySearch';
import DirectorySearchItemTemplate from './DirectorySearchItemTemplate';
import DirectorySearchBackNavigation from './DirectorySearchBackNavigation';
import DirectorySearchProfileListing from './DirectorySearchProfileListing';
import DirectorySearchLoaderProfile from './DirectorySearchLoaderProfile';
import DirectorySearchNoRecord from './DirectorySearchNoRecord';

const DirectorySearchProfilesStatesCities = (props) => {
    let listResultProfiles = [], dataRequest = {}, resDb_listResult = {};

    const stateList = props.listResult.state_list ?? [];
    const profileCount = props.listResult.profile_count ?? 0;
    const profileAllIds = props.listResult.profile_ids ?? '';

    const countryId = parseInt(props.countryid ?? 0);
    const stateId = parseInt(props.stateid ?? 0);
    const cityId = parseInt(props.cityid ?? 0);
    const areaId = parseInt(props.areaid ?? 0);
    const typeId = parseInt(props.typeid ?? 0);
    const searchCategoryId = parseInt(props.searchCategoryId ?? 0);
    const searchCategoryDetail = props.searchCategoryDetail ?? {};
    const keyword = (props.keyword ?? '').toString();
    const enableSortDistance = props.enableSortDistance ?? false;

    const categoryName = searchCategoryId > 0 ? (searchCategoryDetail.cate_name ?? '' !== '' ? searchCategoryDetail.cate_name + ', ' : '') : '';

    let category_search_status = 0;
    if (Object.keys(searchCategoryDetail).length > 0 && searchCategoryId === 0) {
        category_search_status = 1;
    }

    const defaultLocation = {
        country_id: countryId.toString(),
        state_id: stateId.toString(),
        city_id: cityId.toString(),
        area_id: areaId.toString(),
        category_search: category_search_status.toString()
    }

    const [steps, setSteps] = useState(0);

    const [boolShowProfileResult, setBoolShowProfileResult] = useState(false);
    const [boolShowLoading, setBoolShowLoading] = useState(false);
    const [boolShowCityResult, setBoolShowCityResult] = useState(false);
    const [boolShowSuburbResult, setBoolShowSuburbResult] = useState(false);

    const [stateSelected, setStateSelected] = useState({});
    const [citySelected, setCitySelected] = useState({});
    const [areaSelected, setAreaSelected] = useState({});
    const [profileCountState, setProfileCountState] = useState(0);
    const [profileCountCity, setProfileCountCity] = useState(0);

    const [listResulProfiles, setListResulProfiles] = useState([]);
    const [listResultCities, setListResultCities] = useState([]);
    const [listResultSuburbs, setListResultSuburbs] = useState([]);
    const [headerNavigationText, setHeaderNavigationText] = useState(Utilities.labelShowAll);

    const gettingProfiles = async (step = 0, profileids = '', navText = Utilities.labelShowAll) => {

        setSteps(step);
        //console.log(step);
        if ([1].includes(step)) {
            setStateSelected({});
            setCitySelected({});
            setAreaSelected({});

            setBoolShowCityResult(false);
            setBoolShowSuburbResult(false);

        } else if ([3, 4].includes(step)) {
            setBoolShowCityResult(false);
            setBoolShowSuburbResult(false);

        } else if ([5].includes(step)) {
            setBoolShowSuburbResult(false);
        }

        setBoolShowProfileResult(false);
        setBoolShowLoading(true);

        dataRequest = {
            account_ids: profileids.toString(),
            account_type: typeId.toString(),
        }

        resDb_listResult = await getPofilesByIds(dataRequest);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listResultProfiles = resDb_listResult.list ?? [];
            }
        }

        setListResulProfiles(listResultProfiles);
        setBoolShowLoading(false);
        setBoolShowProfileResult(true);
        setHeaderNavigationText(navText);

    }




    const gettingCities = async (stateDetail = {}) => {
        ////console.log(stateDetail);
        setSteps(2);

        setProfileCountState(stateDetail.counter ?? 0);
        setStateSelected(stateDetail);
        setCitySelected({});
        setListResultCities([]);

        let listCities = [];

        dataRequest = {
            ...defaultLocation,
            state_id: (stateDetail.state_id ?? 0).toString(),
            type: typeId.toString(),
            category_id: searchCategoryId.toString(),
            keyword: keyword.toString(),
        }

        ////console.log(dataRequest);

        setBoolShowCityResult(true);
        setBoolShowLoading(true);
        setBoolShowProfileResult(false);

        resDb_listResult = await getCitiesByState(dataRequest);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listCities = resDb_listResult.list ?? [];
            }
        }

        setListResultCities(listCities);
        setBoolShowCityResult(true);
        setBoolShowLoading(false);
    }



    const gettingSuburbByCity = async (cityDetail = {}) => {

        setSteps(4);

        setProfileCountCity(cityDetail.counter ?? 0);
        setCitySelected(cityDetail);

        setBoolShowCityResult(false);
        setBoolShowLoading(true);
        setBoolShowProfileResult(false);

        let suburbList = [];

        dataRequest = {
            ...defaultLocation,
            state_id: (stateSelected.state_id ?? 0).toString(),
            city_id: (cityDetail.city_id ?? 0).toString(),
            area_id: "0",
            category_id: searchCategoryId.toString(),
            type: typeId.toString(),
            keyword: keyword.toString(),
        }

        resDb_listResult = await getSuburbListByCity(dataRequest);
        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                suburbList = resDb_listResult.list ?? [];

            }
        }


        setListResultSuburbs(suburbList);
        setBoolShowSuburbResult(true);
        setBoolShowLoading(false);

    }


    const handleClickBack = () => {
        //console.log(steps);
        if ([1, 2].includes(steps)) {
            setBoolShowCityResult(false);
            setBoolShowSuburbResult(false);
            setBoolShowProfileResult(false);
            setBoolShowLoading(false);

            setSteps(0);

        } else if ([3, 4].includes(steps)) {
            setBoolShowCityResult(true);
            setBoolShowSuburbResult(false);
            setBoolShowProfileResult(false);
            setBoolShowLoading(false);

            setSteps(2);

        } else if ([5].includes(steps)) {

            setBoolShowCityResult(false);
            setBoolShowSuburbResult(true);
            setBoolShowProfileResult(false);
            setBoolShowLoading(false);

            setSteps(4);

        } else {
            setBoolShowCityResult(true);
            setBoolShowLoading(false);
            setBoolShowProfileResult(false);
            setCitySelected({});
        }
    }

    const handleClickCategoryBack = () => {
        props.handleClickCategoryBack();
    }


    return (
        <div className='tab-list-states'>
            {/* -------------------- state listing -------------------- */}
            {
                (!boolShowProfileResult && !boolShowLoading && !boolShowCityResult && !boolShowSuburbResult) &&
                <>
                    {
                        Object.keys(stateList).length === 0 ?
                            <DirectorySearchNoRecord />
                            :
                            <>
                                {
                                    Object.keys(searchCategoryDetail).length > 0 && <DirectorySearchBackNavigation handleClick={handleClickCategoryBack} text={searchCategoryDetail.cate_name ?? ''} />
                                }
                                <ul className="states-listing">
                                    <li onClick={() => gettingProfiles(1, profileAllIds, Utilities.labelShowAll)}>
                                        <div>
                                            <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                            <div><span className="counter fs-16 fs-md-14">{profileCount}</span></div>
                                        </div>
                                    </li>
                                    {
                                        Object.entries(stateList).map(([key, row]) =>
                                            parseInt(row.counter) > 0 && <li key={key} onClick={() => gettingCities(row)} ><DirectorySearchItemTemplate name={row.state_name ?? ''} counter={row.counter ?? '0'} /></li>
                                        )
                                    }

                                </ul>
                            </>
                    }
                </>
            }


            {/* -------------------- city listing -------------------- */}
            {
                (!boolShowProfileResult && !boolShowLoading && boolShowCityResult && !boolShowSuburbResult) &&
                <>
                    <DirectorySearchBackNavigation handleClick={handleClickBack} text={stateSelected.state_name ?? headerNavigationText}>
                        {categoryName}
                    </DirectorySearchBackNavigation>

                    <ul className="states-listing">
                        <li onClick={() => gettingProfiles(3, stateSelected.profile_ids, stateSelected.state_name ?? headerNavigationText)} >
                            <div>
                                <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                <div><span className="counter fs-16 fs-md-14">{profileCountState}</span></div>
                            </div>
                        </li>
                        {
                            Object.entries(listResultCities).map(([key, row]) =>
                                parseInt(row.counter) > 0 && <li key={key} onClick={() => gettingSuburbByCity(row)}><DirectorySearchItemTemplate name={row.city_name ?? ''} counter={row.counter ?? '0'} /></li>
                            )
                        }
                    </ul>
                </>
            }



            {/* -------------------- suburb listing -------------------- */}
            {
                (!boolShowProfileResult && !boolShowLoading && !boolShowCityResult && boolShowSuburbResult) &&
                <>
                    <DirectorySearchBackNavigation handleClick={handleClickBack} text={`${citySelected.city_name ?? ''}, ${stateSelected.state_name ?? ''}`}>
                        {categoryName}
                    </DirectorySearchBackNavigation>

                    <ul className="states-listing">
                        <li onClick={() => gettingProfiles(5, citySelected.profile_ids, `${citySelected.city_name ?? ''}, ${stateSelected.state_name ?? ''}`)} >
                            <div>
                                <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                <div><span className="counter fs-16 fs-md-14">{profileCountCity}</span></div>
                            </div>
                        </li>

                        {
                            Object.entries(listResultSuburbs).map(([key, row]) =>
                                parseInt(row.counter) > 0 && <li key={key} onClick={() => gettingProfiles(5, row.profile_ids, `${row.area_name ?? ''}, ${citySelected.city_name ?? ''}, ${stateSelected.state_name ?? ''}`)} >
                                    <DirectorySearchItemTemplate name={row.area_name ?? ''} counter={row.counter ?? '0'} /></li>
                            )
                        }
                    </ul>
                </>
            }



            {/* -------------------- profile listing -------------------- */}
            {
                (boolShowProfileResult && !boolShowLoading && !boolShowCityResult) &&
                <>
                    <DirectorySearchBackNavigation handleClick={handleClickBack} text={headerNavigationText}>
                        {categoryName}
                    </DirectorySearchBackNavigation>
                    <DirectorySearchProfileListing listProfiles={listResulProfiles} locationTabId={4} highlightKeywordInResults={props.highlightKeywordInResults} previewProfileImage={props.previewProfileImage} handleClickProfile={props.handleClickProfile} enableSortDistance={enableSortDistance} />
                </>
            }

            {/* -------------------- loader -------------------- */}
            {boolShowLoading && <DirectorySearchLoaderProfile />}

        </div>
    )

}


export default DirectorySearchProfilesStatesCities;