import axios from 'axios';
import ApiUrls from './ApiUrls';

export const getGogoLinksHeadings = async (passval = {}) => {

    let responseDetail = [];

    if (Object.keys(passval).length > 0) {

        try {
            //console.log('getGogoLinksHeadings');
            //console.log(ApiUrls.getGotoLinksHeadings);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.getGotoLinksHeadings, passval);
            if (parseInt(responseURL.status) === 201) {
                if (parseInt(responseURL.data.success) === 1) {
                    responseDetail = responseURL.data.result.company_goto_link_list.category_list ?? [];
                    responseDetail = responseDetail.filter(row => parseInt(row.group_id ?? 0) > 0);
                }

            }


        } catch (e) { }
    }

    return responseDetail;

}

export const manageGotoHeadings = async (passval = {}) => {

    let responseDetail = [];

    if (Object.keys(passval).length > 0) {

        try {
            //console.log('manageGotoHeadings');
            //console.log(ApiUrls.gotoLinksManageHeadings);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.gotoLinksManageHeadings, passval);
            //console.log(responseURL);

            if (parseInt(responseURL.status) === 201) {
                if (parseInt(responseURL.data.success) === 1) {
                    responseDetail = responseURL.data.result.company_goto_category_list ?? [];

                }

            }


        } catch (e) { }
    }

    return responseDetail;

}




export const getGotoLinkCompanies = async (passval = {}) => {

    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        //console.log('getGotoLinkCompanies');
        //console.log(ApiUrls.getGotoLinksProfiles);
        //console.log(passval);

        const responseURL = await axios.post(ApiUrls.getGotoLinksProfiles, passval);

        if (parseInt(responseURL.status) === 201) {
            if (parseInt(responseURL.data.success) === 1) {
                responseDetail = responseURL.data.result.company_goto_link_list ?? {};
            }

        }
    }

    return responseDetail;

}



export const submitGotoLinkCompanyDetail = async (passval = {}) => {

    let responseDetail = [];

    if (Object.keys(passval).length > 0) {


    }

    return responseDetail;

}