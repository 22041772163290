import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import axios from 'axios'

import Utilities from '../helpers/Utilities';
import { uploadProfileLogo } from '../helpers/AWSUpload';

import { showLoader, showHeader, showFooter, pageTitle } from '../actions';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';
import SessionUtilities from '../helpers/SessionUtilities';
import { redirectToDownloadAppFromCampaign } from '../helpers/UtilitiesCampaign';
import { followGlanceCompany, sendJazenetBeonchatMessage } from '../helpers/QueryPersonalSignup';

const PersonalUploadLogo = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN = process.env.REACT_APP_chat_api_token;

    const [emailAddress, setEmailAddress] = useState('');
    const [passwordUser, setPasswordUser] = useState('');
    const [logoProfile, setLogoProfile] = useState('');
    const [logoProfileValue, setLogoProfileValue] = useState('');
    const [accountID, setAccountID] = useState(0);

    const selectImage = () => {
        jQuery('input#input_select_file').trigger('click');
    }

    const previewImage = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            var reader = new FileReader();
            reader.onload = function (e) {

                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setLogoProfileValue(e.target.result);
                    //setLogoProfile(e.target.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setLogoProfile(e.target.files[0]);

        } else {
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    const sendJazenetMessage = async (profile) => {

        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        const passval = { api_token: TOKEN_SESSION.toString(), account_id: profile.account_id.toString() };

        dispatch(showLoader(1));
        await sendJazenetBeonchatMessage(passval);
        await followGlanceCompany(profile.account_id.toString(), profile.city_id.toString());
        dispatch(showLoader(0));

        history.push(`/${UrlSlugs.dashboard}`);
    }

    const loginUser = async () => {
        if (!Utilities.isEmpty(emailAddress) && !Utilities.isEmpty(passwordUser)) {
            dispatch(showLoader(1));

            const windowNavigator = window.navigator;
            const generatedToken = Utilities.generateToken().toString();

            await axios
                .post(ApiUrls.userGetByCredentail, {
                    email: emailAddress,
                    password: passwordUser,
                    browser_name: windowNavigator.appVersion.toLowerCase(),
                    token_request: generatedToken
                })
                .then(response => {

                    dispatch(showLoader(0));
                    const resStatus = response.status;
                    if (resStatus === 200) {

                        const resData = response.data;
                        const resLoginType = parseInt(resData.logintype);

                        if (parseInt(resData.status) === 1) {

                            //if ( parseInt(resData.detail.status)===1 ){

                            SessionUtilities.setSessionToken(generatedToken);

                            localStorage.setItem(
                                process.env.REACT_APP_session_logintype,
                                JSON.stringify(resLoginType)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login,
                                JSON.stringify(resData.detail)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_started,
                                Date.now()
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_accounts,
                                JSON.stringify(resData.accounts)
                            );


                            SessionUtilities.setSignupWelcomeMessage(1);

                            // CHECK IF THERE IS CAMPAIN -> redirect to signup company information
                            const signupCampaignDetail = SessionUtilities.getSignupCampaign();
                            if (Object.keys(signupCampaignDetail ?? {}).length > 0) {
                                const campaign_pagetype = parseInt(signupCampaignDetail.type ?? 0);
                                if (campaign_pagetype > 0) {

                                    redirectToDownloadAppFromCampaign(history);
                                    return false;

                                }
                            }


                            SessionUtilities.removeSignupCampaignDownload();
                            //localStorage.setItem( process.env.REACT_APP_session_welcome_mesage, 1 );
                            sendJazenetMessage(resData.detail);
                            //history.push('/' + UrlSlugs.dashboard );

                            /* }else{
                            Utilities.messagePopup('error', 'Account is not active!'  );
                            } */

                        } else {
                            Utilities.messagePopup('error', errorMsg);
                        }
                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }

                })
                .catch((err) => {
                    Utilities.messagePopup('error', errorMsg);
                    dispatch(showLoader(0));
                });
        }

    }

    const submitProfileImage = async (e) => {
        e.preventDefault();

        const logo_value = document.getElementById('input_select_file').value;

        if (Utilities.isEmpty(logo_value) || Utilities.isEmpty(logoProfile)) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.imageMandatory);
            return false;
        }

        if (accountID === 0) { return false; }

        dispatch(showLoader(1));

        const formLogoData = new FormData();
        formLogoData.append('account_id', accountID);
        formLogoData.append('file', logoProfile);

        const responseUpload = await uploadProfileLogo(formLogoData);
        dispatch(showLoader(0));

        //loginUser();

        if (parseInt(responseUpload.status) === 1) {
            loginUser();
        } else {
            Utilities.messagePopup('error', errorMsg);
        }

    }

    useEffect(() => {

        dispatch(pageTitle(TitlesLabels.siteTitles.profilePhoto));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        let signupPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_signup_personalinfo);
        if (signupPersonalInfo !== null) {
            signupPersonalInfo = JSON.parse(signupPersonalInfo);
            if (Object.keys(signupPersonalInfo).length > 0) {
                setEmailAddress(signupPersonalInfo.email);
                setPasswordUser(signupPersonalInfo.password);
                setAccountID(signupPersonalInfo.account_id);
            }
        }

    }, []);

    return (
        <div className='signup-page with-footer centerialized   text-lowercase fs-18'>

            <div className='signup-wrap position-relative h-100 d-flex align-items-center pb-5 justify-content-center'>
                <div>
                    <SignupHeader title="profile photo" />

                    <div className='py-2'></div>

                    <form autoComplete="off">
                        <input type="file" id="input_select_file" name="file" className="invisible w-100" accept={Utilities.attributeImageAcceptExtension} readOnly onChange={(e) => previewImage(e)} ></input>

                        <div className="logo-size-200 mx-auto" style={{ backgroundImage: `url(${logoProfileValue})` }} > </div>

                        <div className='profile-logo-change'>
                            <button type="button" className="btn-select-file font-gotham-bold bg-theme-green border-radius text-white outline-none border-0 fs-16 mx-auto " onClick={() => selectImage()} style={{ height: 30, width: 100 }}>change</button>
                            <div className='fs-13-important text-center pt-3 color-theme-grey'>{Utilities.labelUploadSpecs}</div>
                        </div>

                    </form>

                    <div className='py-2'></div>

                </div>

                <div className='signup-button position-absolute w-100 bottom-0 start-0  mb-3'>
                    <button type="button" className="btn-theme-black button-has-loader col-4 mx-auto" onClick={(e) => submitProfileImage(e)}>submit</button>
                </div>

            </div>

            <div className='pb-5  pt-5 pt-md-0'>
                <Link to={`/${UrlSlugs.signup_verification}`}>{TitlesLabels.general.exitWithoutSaving}</Link>
            </div>

        </div>
    )
}

export default PersonalUploadLogo;