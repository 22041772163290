import axios from 'axios';
import ApiUrls from './ApiUrls';


export const checkEmailSocialAccount = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.loginSocialCredential, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }


        } catch (e) {

        }
    }

    return responseDetail;
}


export const updateCompanyBulkCredentialDetail = async (account_id = 0) => {

    let responseDetail = { status: 0 }, passval = { account_id: account_id };

    let sessionStorage_companyBulkCredential = sessionStorage.getItem(process.env.REACT_APP_session_login_company_bulk_credential);
    sessionStorage_companyBulkCredential = (sessionStorage_companyBulkCredential ?? '') === '' ? {} : JSON.parse(sessionStorage_companyBulkCredential);

    let localStorage_sessionLogin = localStorage.getItem(process.env.REACT_APP_session_login);
    localStorage_sessionLogin = (localStorage_sessionLogin ?? '') === '' ? {} : JSON.parse(localStorage_sessionLogin);

    if (Object.keys(sessionStorage_companyBulkCredential).length > 0) {
        passval = { ...passval, id: (sessionStorage_companyBulkCredential.id ?? 0) }
    }


    if (Object.keys(localStorage_sessionLogin).length > 0) {
        passval = { ...passval, account_id: (localStorage_sessionLogin.account_id ?? 0) }
    }

    //console.log(passval);

    if (Object.keys(passval).length === 2) {

        if (parseInt(passval.id) > 0 && parseInt(passval.account_id) > 0) {
            try {

                const responseURL = await axios.post(ApiUrls.updateCompanyBulkCrecential, passval);
                if (parseInt(responseURL.status) === 200) {
                    responseDetail = responseURL.data;

                    sessionStorage.removeItem(process.env.REACT_APP_session_login_company_bulk_credential);
                }


            } catch (e) {

            }
        }
    }

    return responseDetail;
}
