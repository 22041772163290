import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios'

import { showHeader, showFooter, pageTitle, showLoader } from '../actions';
import PagesAuthentication from '../helpers/PagesAuthentication';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import ApiUrls from '../helpers/ApiUrls';
import { sendVerificationCode } from '../helpers/SendingEmails';
import SignupHeader from '../layout/SignupHeader';
import Utilities from '../helpers/Utilities';

import { updateCompanyBulkCredentialDetail } from '../helpers/QueryLogin';
import SessionUtilities from '../helpers/SessionUtilities';

const PersonalCondition = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const siteTitle = process.env.REACT_APP_site_title;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const msgSendError = TitlesLabels.alertMessages.couldNptSendEmailTryAgain;

    const [acceptCondition, setAcceptCondition] = useState(false);

    const pushCondition = () => {
        setAcceptCondition(acceptCondition ? false : true);
    }

    const handleExit = () => {

        let redirectUrl = UrlSlugs.signup_persoanlInfo;

        /*  const session_signupCampaign = SessionUtilities.getSignupCampaign();
         if ( Object.keys(session_signupCampaign).length>0 ){
             redirectUrl =  UrlSlugs.signup_campaignPersonalPassword;
 
             const socialSignupConnect = PagesAuthentication.getSignupSocialDetail();
             if ( Object.keys(socialSignupConnect).length>0 ){
                 redirectUrl =  UrlSlugs.signup_campaignPersonalInfo;
             }
             
         } */

        history.push(`/${redirectUrl}`);
    }

    const submitAcceptance = async () => {

        let signupPersonalInfo = PagesAuthentication.getSignupPersonalInfo() ?? {};
        const generatedToken = Utilities.generateToken().toString();

        if (Object.keys(signupPersonalInfo).length > 0) {

            const socialSignupConnect = PagesAuthentication.getSignupSocialDetail();

            signupPersonalInfo = { ...signupPersonalInfo, signin_type: '0', token_request: generatedToken };

            if (Object.keys(socialSignupConnect).length > 0) {
                signupPersonalInfo = { ...signupPersonalInfo, signin_type: socialSignupConnect.type.toString(), signin_connect: socialSignupConnect };
            }


            const signupCampaignDetail = SessionUtilities.getSignupCampaign();
            if (Object.keys(signupCampaignDetail).length > 0) {
                const campaign_pagetype = parseInt(signupCampaignDetail.type ?? 0);

                if (campaign_pagetype > 0) {
                    signupPersonalInfo = {
                        ...signupPersonalInfo,
                        campaign_id: signupCampaignDetail.id.toString(),
                        campaign_type: signupCampaignDetail.category.toString(),
                    };
                }

            }

            dispatch(showLoader(1));

            await axios
                .post(ApiUrls.profile, signupPersonalInfo)
                .then(async (response) => {


                    dispatch(showLoader(0));

                    if (response.status === 200) {
                        const createdID = parseInt(response.data);

                        SessionUtilities.setSessionToken(generatedToken);

                        signupPersonalInfo = { ...signupPersonalInfo, ...{ account_id: createdID } };

                        PagesAuthentication.setSignupPersonalInfo(signupPersonalInfo);
                       

                        /* const passvalVerification = {
                            api_token: API_TOKEN.toString(),
                            flag: "1",
                            account_id: createdID.toString(),
                            name: signupPersonalInfo.first_name + ' ' + signupPersonalInfo.last_name,
                            email: signupPersonalInfo.email,
                            phone_number: '+' + signupPersonalInfo.mobile_phonecode.toString() + signupPersonalInfo.mobile_number.toString(),
                            code: signupPersonalInfo.pincode.toUpperCase()
                        }; */

                        const passvalVerification = {
                            api_token: generatedToken.toString(),
                            account_id: createdID.toString(),
                            phone_number: signupPersonalInfo.mobile_phonecode.toString() + signupPersonalInfo.mobile_number.toString(),
                            verif_code: signupPersonalInfo.pincode.toUpperCase()
                        };

                        dispatch(showLoader(1));
                        const responSendingVeification = await sendVerificationCode(passvalVerification);
                        dispatch(showLoader(0));

                        if (Object.keys(responSendingVeification).length === 0) {
                            Utilities.messagePopup('error', msgSendError);
                            return false;
                        }

                        if (parseInt(responSendingVeification.success) === 1) {

                            dispatch(showLoader(1));
                            await updateCompanyBulkCredentialDetail(createdID);
                            dispatch(showLoader(0));

                            history.push(`/${UrlSlugs.signup_verification}`);

                        } else {
                            Utilities.messagePopup('Error', responSendingVeification.errorMessage);
                        }

                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }


                })
                .catch((err) => {
                    Utilities.messagePopup('error', errorMsg);
                    dispatch(showLoader(0));

                });
        }

    }

    useEffect(() => {
        dispatch(pageTitle(TitlesLabels.siteTitles.importantInformation));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        PagesAuthentication.checkLoginStatus();

    }, []);

    return (
        <div className='signup-page with-footer centerialized   text-lowercase'>

            <div className='signup-wrap position-relative h-100 d-flex align-items-center pb-5 justify-content-center px-4 p-md-0'>

                <div>
                    <SignupHeader title="important information / conditions of use" classAttr="color-theme-warning" />


                    <div className='py-4'></div>

                    <ul className='text-left listing-system-bullet fs-18 color-theme-grey'>
                        <li className="mb-3">{siteTitle} is an open business communication platform allowing all businesses to onboard. we cannot verify and authenticate the credibility of any enterprise or professional enlisting. please insure you are vigilant when transacting with any party.</li>
                        <li className="mb-3">{siteTitle} does not allow connectivity between two personal end users and allows for connectivity only between end users and businesses and/or other persons in their professional capacity.</li>
                        <li className="mb-3">your acceptance as a user is conditional on you undertaking to use {siteTitle} as a commercial transacting tool and not to use it as a platform to espouse any other political, religious, social or personal views.</li>
                        <li>we do not allow for your profile to be found by businesses in order to protect you from spamming and unsolicited adverts. as a consumer we value your privacy and empower you to make initial contact with a business.</li>
                    </ul>

                </div>

                <div className='signup-button position-absolute w-100 bottom-0 start-0  mb-3'>

                    <div className='pt-5 pb-4 checkbox-radio-default checkbox-radio-check-icon d-flex align-items-center justify-content-center color-theme-grey'>
                        <input type="checkbox" name="acceptance" checked={acceptCondition} onChange={() => pushCondition()} ></input>
                        <span className="ml-2 text-black font-gotham-book-16 cursor-pointer" onClick={() => pushCondition()}>do you agree?</span>
                    </div>

                    <button type="button" id="btn-submit-personal-condition" className="btn-theme-black button-has-loader  col-4 mx-auto" onClick={(e) => submitAcceptance()} disabled={acceptCondition ? false : true}>continue</button>
                </div>

            </div>



            <div className='pb-5 pt-5 pt-md-0'>
                <a href='#' onClick={() => handleExit()}>{TitlesLabels.general.exitWithoutSaving}</a>
            </div>

        </div>
    )
}

export default PersonalCondition;