import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Utilities from '../../helpers/Utilities';
import CommonProfileLogo from '../common/CommonProfileLogo';

const DepartmentChatConfirm = (props) => {

    const boolShow = props.show ?? true;
    const departmentDetail = props.departmentDetail ?? {};
    const propsCompanyProfile = props.companyProfile ?? {};

    const [companyProfile, setCompanyProfile] = useState(propsCompanyProfile);

    const handleClose = () => {
        if (typeof props.handleClose !== "undefined") {
            props.handleClose();
        }
    }

    const handleConfirmAction = () => {

        if (typeof props.handleConfirmAction !== "undefined") {
            props.handleConfirmAction(departmentDetail.form_status ?? 0);
        }
    }

    const formattedAddress = () => {
        let add1 = (propsCompanyProfile.address_1 ?? '').toLowerCase();
        let add2 = (propsCompanyProfile.address_2 ?? '').toLowerCase();
        let add1Array = [], add2Array = [], address1Array = [];

        add1Array = add1.split(', ');
        add2Array = add2.split(', ');

        add1Array.map(val => {
            if (!add2Array.includes(val)) {
                address1Array.push(val);
            }
        });

        setCompanyProfile({ ...propsCompanyProfile, address_1: address1Array.join(', ') });
    }

    useEffect(() => {
        formattedAddress();

    }, [props]);

    return (
        <Modal show={boolShow} onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme ">
            <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
            <Modal.Body>
                <div className="profile-selected-assoc text-center text-lowercase font-gotham-book mt-n3">

                    <div className="col-11 mx-auto">
                        <div className="profile-header text-left mb-4">
                            <CommonProfileLogo classAttr={`profile-image fs-22-important logo-size-200 logo-size-md-120 mx-auto ${((companyProfile.hasOwnProperty('plus_status') && parseInt(companyProfile.plus_status || 0) === 1) ? 'badge-plus' : '')}`} profileDetail={companyProfile} />

                            <div className='text-center mt-3'>
                                <div className="profile-name notranslate  font-gotham-bold fs-30 fs-md-22">{companyProfile.name ?? ''}</div>
                                <div className="profile-label fs-25 fs-md-18 color-theme-grey">{departmentDetail.department_name ?? ''}</div>
                            </div>
                        </div>

                        <div className="profile-address mb-5 fs-16 fs-md-16">
                            <div>{companyProfile.address_1 ?? ''}</div>
                            <div>{companyProfile.address_2 ?? ''}</div>

                            {!Utilities.isEmpty(departmentDetail.contact_number ?? '') && <div className="font-gotham-book-16 mt-3 color-theme-green">{departmentDetail.contact_no ?? ''}</div>}

                        </div>

                        <div className="mb-4">
                            <button id="btn-chat-department" type="button" className="btn-theme-black button-has-loader" onClick={() => handleConfirmAction()} >connect</button>
                        </div>

                    </div>

                </div>

            </Modal.Body>
        </Modal>
    )
}

export default DepartmentChatConfirm;