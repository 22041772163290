import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

import UrlSlugs from '../../helpers/UrlSlugs';
import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import QueryMasterSearchUtlities from '../../helpers/QueryMasterSearchUtlities';

import { passChatTeamProfileRestrictionDetail as passChatTeamProfileRestrictionDetailReducer, showLoader as showLoaderReducer } from '../../actions';
import CommonProfileLogo from '../common/CommonProfileLogo';
import SessionUtilities from '../../helpers/SessionUtilities';
import PagesAuthentication from '../../helpers/PagesAuthentication';


const ConnectTeamMember = (props) => {

    let countryList = {};
    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN_CHAT = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const profileSelectedProps = props.profileSelected ?? {};
    const companyProfile = props.companyProfile ?? {};
    const teamRestrictAccess = parseInt(profileSelectedProps.team_restrict_access_status ?? 0);

    const [profileLoggedin, setProfileLoggedin] = useState({});
    const [profileSelected, setProfileSelected] = useState(profileSelectedProps);
    const [expandAboutSection, setExpandAboutSection] = useState(false);
    const [teamRestrictAccessStatus, setTeamRestrictAccessStatus] = useState(teamRestrictAccess);

    const showChat = async (profileSelected) => {

        const button_name = 'btn-confirm-chat';

        if (parseInt(teamRestrictAccessStatus) === 1) {

            /* Utilities.elementDisabledStatus(button_name, true);

            const passValuesRes = {
                api_token: TOKEN_SESSION.toString(), //TOKEN_CHAT.toString(),
                account_id: profileLoggedin.account_id.toString(),
                account_type: profileLoggedin.account_type.toString(),
                account_department_id: profileLoggedin.hasOwnProperty('group_id') ? profileLoggedin.group_id.toString() : '0',
                company_id: companyProfile.account_id.toString(),
                receiver_account_id: profileSelected.account_id.toString(),
                restrict_status: teamRestrictAccessStatus.toString()
            };

            const responseContactTeam = await QueryMasterSearchUtlities.contactRequestTeamDetail(passValuesRes);
            Utilities.elementDisabledStatus(button_name, false);


            if (Object.entries(responseContactTeam).length === 0) {
                Utilities.messagePopup('error', errorMsg);
                return false;
            }

            if (parseInt(responseContactTeam.success) === 0) {
                Utilities.messagePopup('error', responseContactTeam.errorMessage);
                return false;
            }

            const conversationStatus = parseInt(responseContactTeam.result.bizchat_team_conversation.conversation_status || 0);

            if (conversationStatus === 0) {
                dispatch(passChatTeamProfileRestrictionDetailReducer(profileSelected));
                typeof props.handleClose === undefined ? false : props.handleClose();

            } else {

                typeof props.showChat === undefined ? false : props.showChat(profileSelected);
            } */

            profileSelected = { ...profileSelected, company_id: companyProfile.account_id.toString() };

            dispatch(passChatTeamProfileRestrictionDetailReducer(profileSelected));
            typeof props.handleClose === undefined ? false : props.handleClose();


            return false;
        }


        typeof props.showChat === undefined ? false : props.showChat(profileSelected);
    }


    const updateProfileDetail = async () => {

        let profile = profileSelectedProps;

        // 1 = new chat , 3 - directory search
        const searchTypeStatus = parseInt(PagesAuthentication.getDirectorySearchType() ?? 1);
        //console.log(`DirectorySearchType: ${searchTypeStatus}`);


        dispatch(showLoaderReducer(1));
        const passval = {
            api_token: TOKEN_SESSION.toString(),
            account_id: profileLoggedin.account_id.toString(),
            account_department_id: profileLoggedin.hasOwnProperty('group_id') ? profileLoggedin.group_id.toString() : '0',
            profile_id: profile.account_id.toString(),
            flag: searchTypeStatus === 3 ? "2" : searchTypeStatus.toString()
        }
        const consDetailRes = await QueryMasterSearchUtlities.requestAccessTeamCommunicationDetails(passval);
        const restrictStatus = parseInt(consDetailRes.result.search_team_profile_result.restrict_status ?? 0);
        setTeamRestrictAccessStatus(restrictStatus);
        //console.log(restrictStatus);

        await getCountries();

        dispatch(showLoaderReducer(0));


        // TEAM MEMBER
        if (parseInt(profile.account_type) === 4) {
            if (parseInt(profile.team_contact_number_status ?? 0) === 1) {

                let contact_number_mobile = '', contact_prefix = '', phone_code = '', has_contact = 0;

                contact_number_mobile = profile.team_contact_number.replace(' ', '').trim();
                if (profile.hasOwnProperty('team_contact_number_code_id')) {
                    phone_code = profile.team_contact_number_code_id;

                    if (parseInt(phone_code) > 0) {
                        contact_prefix = '+' + countryList[phone_code]['phonecode'];
                        contact_number_mobile = contact_prefix + contact_number_mobile;
                        has_contact++;
                    }
                } else {
                    contact_number_mobile = '';
                }


                profile = {
                    ...profile,
                    has_profile_contact: has_contact,
                    contact_number_mobile: contact_number_mobile
                }

            }
        }

        setProfileSelected(profile);

    }

    const getCountries = async () => {

        await axios
            .post(ApiUrls.country, {})
            .then(response => {
                if (response.status === 200) {
                    const countriesList = response.data;

                    let country_list = {};
                    Object.entries(countriesList).map(([key, row]) => {
                        country_list[row.id] = row;
                    });
                    countryList = country_list;
                }
            })
            .catch((err) => {

            });
    }


    useEffect(() => {

        if (Object.keys(props.profileSelected ?? {}).length > 0) {
            updateProfileDetail();
        }

        // LOGGEDIN ACCOUNTS
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginProfileInfo !== null) {
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if (Object.keys(loginProfileInfo).length > 0) {
                const profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setProfileLoggedin(profileLoggedinTemp);
            }
        }

        return () => {

        }

    }, [props.profileSelected]);

    return (

        <Modal id="popup-team-profile" show={props.show ?? false} onHide={props.handleClose ?? ''} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme ">
            <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
            <Modal.Body className='main-search-profle-body'>

                <div className="profile-selected-assoc text-center text-lowercase font-gotham-book mt-n3">

                    <div className="col-11 mx-auto">

                        {
                            parseInt(profileSelected.account_type) === 3 ?
                                <>
                                    <div className="profile-header text-left mb-4">
                                        {/* <div className={`profile-image fs-22-important logo-size-200 logo-size-md-120 mx-auto`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(profileSelected.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(profileSelected.logo_url) }}>{ Utilities.showHideInitial(profileSelected.logo_url, profileSelected.name) }</div>       */}
                                        <CommonProfileLogo classAttr={`profile-image fs-22-important logo-size-200 logo-size-md-120 mx-auto`} profileDetail={profileSelected} />

                                        <div>
                                            <div className="profile-name notranslate  font-gotham-bold fs-30 fs-md-22">{profileSelected.name}</div>
                                            {(profileSelected.hasOwnProperty('category') && profileSelected.category !== '') && <div className="profile-label fs-25 fs-md-18 color-theme-grey">{profileSelected.category}</div>}
                                        </div>
                                    </div>

                                    <div className="profile-address mb-5 fs-16 fs-md-16">{companyProfile.hasOwnProperty('address') ? companyProfile.address : ''}</div>

                                    <div className="mb-4">
                                        <ul className="profile-departments-buttons">
                                            <li><Link to={`/${UrlSlugs.departments}/${btoa(companyProfile.account_id)}`} onClick={() => props.show(false)} ><div className='profile-option-icons grid-icon mb-2'></div>departments</Link></li>
                                            <li><Link to={`/${UrlSlugs.teamMembers}/${btoa(companyProfile.account_id)}`} onClick={() => props.show(false)} ><div className='profile-option-icons groups-people-icon mb-2'></div>team</Link></li>
                                        </ul>
                                    </div>
                                </>

                                :
                                <>
                                    <div className="text-center">
                                        <div className={`logo-size-75 profile-team-logo mx-auto fs-22-important logo-size-200 logo-size-md-120 mx-auto`} style={{ backgroundImage: `url(${Utilities.showHideProfileImage(profileSelected.logo_url)})`, backgroundColor: Utilities.bgcolorHideInitialBg(profileSelected.logo_url) }}>{Utilities.showHideInitial(profileSelected.logo_url, profileSelected.name)}</div>
                                        <div className=" mt-2">
                                            <div className="font-gotham-book-16 notranslate  font-gotham-bold fs-30 fs-md-22">{profileSelected.name}</div>
                                            {(profileSelected.hasOwnProperty('category') && profileSelected.category !== '') && <div className="profile-label fs-25 fs-md-18 color-theme-grey">{profileSelected.category}</div>}
                                        </div>
                                    </div>

                                    {
                                        Object.keys(companyProfile).length > 0 &&
                                        <div className='d-flex justify-content-center'>
                                            <div className="profile-header-company d-flex align-items-center text-left pt-3 py-4 my-3">
                                                {/* <div className="fs-22-important logo-size-90 logo-size-md-80" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(companyProfile.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(companyProfile.logo_url) }}>{ Utilities.showHideInitial(companyProfile.logo_url, companyProfile.name) }</div> */}

                                                <CommonProfileLogo classAttr={`fs-22-important logo-size-90 logo-size-md-80`} profileDetail={companyProfile} />

                                                <div className="line-height-1-2 ml-3">
                                                    <div className="font-gotham-bold fs-25 fs-md-18 notranslate">{companyProfile.hasOwnProperty('name') ? companyProfile.name : ''}</div>
                                                    {(companyProfile.hasOwnProperty('category_name') && companyProfile.category_name !== '') && <div className="profile-label fs-20 fs-md-16 color-theme-grey">{companyProfile.category_name}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    }


                                    {
                                        (
                                            parseInt(profileSelected.has_profile_contact ?? 0) === 1 &&
                                            !Utilities.isEmpty(profileSelected.contact_number_mobile ?? '') &&
                                            parseInt(teamRestrictAccessStatus) === 0
                                        ) &&
                                        <div className=" text-center mt-n2 color-theme-green pb-3">{profileSelected.contact_number_mobile}</div>
                                    }

                                    {
                                        (
                                            profileSelected.hasOwnProperty('team_about') &&
                                            !Utilities.isEmpty(profileSelected.team_about ?? '') &&
                                            (profileSelected.team_about ?? '') !== 'undefined' &&

                                            parseInt(teamRestrictAccessStatus) === 0

                                        ) &&
                                        <div className="py-2">

                                            <div className="text-color-blue">
                                                <span className="cursor-pointer font-gotham-book fs-14 fs-md-14" onClick={() => setExpandAboutSection(expandAboutSection === true ? false : true)}>view profile</span>
                                            </div>

                                            {
                                                expandAboutSection === true &&
                                                <div>

                                                    <div id="popup-profile-about" className="white-space mt-4 mb-3 section-about fs-16 fs-md-16" dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(profileSelected.team_about) }}></div>
                                                </div>
                                            }

                                        </div>
                                    }

                                    {
                                        parseInt(teamRestrictAccessStatus) === 1 &&
                                        <div className="line-height-1 font-gotham-bold fs-14 fs-md-14 mt-2 mb-4 pb-2">
                                            <div className="btn-icon-restricted mx-auto"></div>
                                            <div className="color-theme-red mt-2 pt-1">restricted access</div>
                                        </div>
                                    }

                                    <div className={`mb-4  ${Object.keys(companyProfile).length === 0 ? 'mt-5' : 'mt-4'}`}>
                                        <div id="btn-confirm-chat" onClick={() => showChat(profileSelected)} className="btn-theme-black cursor-pointer button-has-loader" >confirm</div>
                                    </div>


                                </>
                        }

                    </div>

                </div>

            </Modal.Body>
        </Modal>
    )
}

export default ConnectTeamMember;
