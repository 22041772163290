import axios from 'axios';
import ApiUrls from './ApiUrls';

export const copyUserProfieLogo = async (passValue = {}) => {
    let response = {};
    if (Object.keys(passValue).length > 0) {
        try {

            /* console.log('copyUserProfieLogo');
            console.log(ApiUrls.profileTeamCopyUserProfileLogo);
            console.log(passValue); */

            const profileDetailRes = await axios.post(ApiUrls.profileTeamCopyUserProfileLogo, passValue);
            response = profileDetailRes.data;

            //console.log(response);

        } catch (e) {

        }

    }
    return response;
}


export const uploadTeamLogo = async (formData) => {
    let response = {};
    try {

        /* console.log('uploadTeamLogo');
        console.log(ApiUrls.profileTeamLogo);
        console.log(formData); */

        const profileDetailRes = await axios.post(ApiUrls.profileTeamLogo, formData);
        response = profileDetailRes.data;

        //console.log(response);

    } catch (e) {

    }

    return response;
}


export const setProfileTeamInvitation = async (passValue = {}) => {
    let response = {};
    if (Object.keys(passValue).length > 0) {
        try {

            const profileDetailRes = await axios.post(ApiUrls.profileTeamSendInvitation, passValue);
            response = profileDetailRes.data;


        } catch (e) {

        }

    }
    return response;
}


export const updateTeamProfileAdmin = async (passValue = {}) => {
    let response = {};
    if (Object.keys(passValue).length > 0) {
        try {

            const profileDetailRes = await axios.post(ApiUrls.profileTeamProfileUpdateDetails, passValue);
            response = profileDetailRes.data;


        } catch (e) {

        }

    }
    return response;
}


export const updateTeamProfileDetail = async (passValue = {}) => {
    let response = {};
    if (Object.keys(passValue).length > 0) {
        try {

            const profileDetailRes = await axios.post(ApiUrls.profileTeamProfileManageDetails, passValue);
            response = profileDetailRes.data;


        } catch (e) {

        }

    }
    return response;
}