import moment from 'moment';
import React from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";

const DatePickerSelection = (props) => {

    const showPopUp = props.showPopUp ?? false;
    const requestDate = props.selectedDate ?? new Date();

    const handleDateChange = (date) => {
        if (typeof props.handleClosePopUp !== 'undefined') {
            props.handleClosePopUp(date);
        }
    }

    const handleClosePopUp = () => {
        if (typeof props.handleClosePopUp !== 'undefined') {
            props.handleClosePopUp(requestDate);
        }
    }


    return (

        <Modal id="popup-datepicker-selection" show={showPopUp} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-dialog-datepicker" backdropClassName="z-index-1111" className="z-index-1111" >
            <Modal.Header className="border-bottom-0"></Modal.Header>
            <Modal.Body className='modal-body pt-0 mt-n4'>

                <div className='form-theme-datepicker d-flex align-items-center justify-content-center text-lowercase'>
                    <DatePicker inline className="form-control" dateFormat="yyyy-MM-dd" selected={requestDate} onChange={(date) => handleDateChange(date)} minDate={moment().toDate()} />
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default DatePickerSelection;
