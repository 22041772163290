import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import { Dropdown } from 'react-bootstrap';

import TitlesLabels from '../../../helpers/TitlesLabels';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import UrlSlugs from '../../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer } from '../../../actions';
import { getCompanyFollowers, updateCompanyFollowers } from '../../../helpers/FollowersNotices';
import Utilities from '../../../helpers/Utilities';
import CommonProfileLogo from '../../../components/common/CommonProfileLogo';
import SessionUtilities from '../../../helpers/SessionUtilities';

const FollowersSupendRemove = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    let globalPageType = '';

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const [userLoginDetail, setUserLoginDetail] = useState({});
    const [breadcrumbsName, setBreadcrumbsName] = useState('');
    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [listProfiles, setListProfiles] = useState([]);
    const [showProcessing, setShowProcessing] = useState(true);
    const [pageType, setPageType] = useState('');
    const [toggleAll, setToggleAll] = useState(false);

    const [memberIDsSelected, setMemberIDsSelected] = useState([]);
    const [memberAcctIDs, setMemberAcctIDs] = useState([]);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const toggleMemberAll = () => {

        if (toggleAll === true) {
            setMemberIDsSelected([]);
        } else {
            setMemberIDsSelected([...memberAcctIDs]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const toggleSeachBox = () => {
        jQuery('.profile-search-wrap').toggleClass('d-none');
        jQuery('#input_name').val('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getCompanyFollowerList = async (profile = {}) => {

        const passval = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: profile.account_id.toString(),
            company_id: profile.company_detail.account_id.toString(),
            flag: '0'
        };

        setShowProcessing(true);
        const companyFollowersResponse = await getCompanyFollowers(passval);
        setShowProcessing(false);

        console.log(companyFollowersResponse);
        if (Object.keys(companyFollowersResponse).length === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }

        if (parseInt(companyFollowersResponse.success) === 1) {
            const followers = companyFollowersResponse.result.notification_followers_list ?? [];
            setListProfiles(followers);

            let memberIDsSelectedTemp = [], memberIDsTemp = [];
            Object.values(followers).map(row => {
                memberIDsTemp.push(row.account_id.toString());
            });

            setMemberAcctIDs(memberIDsTemp);

            if (globalPageType === 'suspend') {

                Object.values(followers).map(row => {
                    if (parseInt(row.status) === 2) {
                        memberIDsSelectedTemp.push(row.account_id.toString());
                    }
                });

                setMemberIDsSelected(memberIDsSelectedTemp);
            }

        } else {
            Utilities.messagePopup('error', companyFollowersResponse.errorMessage);
        }
    }

    const toggleActiveClass = (e, member = {}) => {
        let memberIDsTemp = memberIDsSelected;
        let id = member.account_id.toString();

        !(memberIDsTemp.includes(id)) ? memberIDsTemp.push(id) : (memberIDsTemp = [...Utilities.removeValueInArray(memberIDsTemp, id)]);
        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);

        setMemberIDsSelected(memberIDsTemp);
        setToggleAll(memberIDsTemp.length === memberAcctIDs.length ? true : false);
    }

    const handleClickSubmitStatus = async () => {

        let actionFlag = -1,
            alertMessage = '',
            membersDiff = [],
            membersLoop = {
                active_list: '',
                suspend_list: '',
                delete_list: ''
            };

           

        const button_name = 'btn-submit-status';

        if (pageType === 'suspend') {
            actionFlag = 1;
            alertMessage = TitlesLabels.alertMessages.followersHasBeenSuspended;
        } else if (pageType === 'remove') {
            actionFlag = 0;
            alertMessage = TitlesLabels.alertMessages.followersHasBeenRemoved;
        }

        if (Utilities.isEmpty(pageType) || actionFlag === -1) { return false; }

        if (pageType === 'remove') {
            membersLoop = {
                ...membersLoop,
                delete_list: memberIDsSelected.join(',')
            }
        } else if (pageType === 'suspend') {
            membersDiff = memberAcctIDs.filter((id) => !memberIDsSelected.includes(id)); 
            membersLoop = {
                ...membersLoop,
                active_list: membersDiff.join(','),
                suspend_list: memberIDsSelected.join(',')
            }
        }

        /* if (pageType === 'remove') {
            memberIDsSelected.map(acctid => {
                membersLoop.push({ account_id: acctid.toString(), status: actionFlag.toString() });
            });
        } else if (pageType === 'suspend') {

            let accountIDLoop = '';
            Object.values(listProfiles).map(row => {
                accountIDLoop = row.account_id.toString();

                if (memberIDsSelected.includes(accountIDLoop)) {
                    membersLoop.push({
                        account_id: accountIDLoop,
                        status: '2'
                    });

                } else {

                    if (parseInt(row.status) === 2) {
                        membersLoop.push({
                            account_id: accountIDLoop,
                            status: '1'
                        });
                    }
                }
            });

        } */

        if (memberIDsSelected.length === 0 && pageType === 'remove' ) {
            Utilities.messagePopup('warning', TitlesLabels.alertMessages.noProfileSelected);
            return false;
        }

        const passval = {
            api_token: TOKEN_SESSION.toString(),    //API_TOKEN.toString(),
            account_id: userLoginDetail.account_id.toString(),
            company_id: userLoginDetail.company_detail.account_id.toString(),
            flag: actionFlag.toString(), //flag  :  0 = remove, 1 = suspend
            members_list: membersLoop
        };

        Utilities.elementDisabledStatus(button_name, true);
        const responseActionDb = await updateCompanyFollowers(passval);
        Utilities.elementDisabledStatus(button_name, false);

        if (Object.keys(responseActionDb).length === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }

        if (parseInt(responseActionDb.success) === 1) {
            Utilities.messagePopup('success', alertMessage);
            history.push(`/${UrlSlugs.settings_notificationFollowers}`);
            //await getCompanyFollowerList(userLoginDetail);

        } else {
            Utilities.messagePopup('error', responseActionDb.errorMessage);
        }

    }

    const handleRedirect = (url = '') => {

        if (!Utilities.isEmpty(url)) {
            history.push(`/${url}`);
        }
    }

    useEffect(() => {

        PagesAuthentication.getUserLoggedin();

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        if (parseInt(userLoginDetailTemp.account_type) !== 4) {
            history.push(`/${UrlSlugs.dashboard}`);
            return false;
        } else {

            // RETURN to activation page
            if (userLoginDetailTemp.hasOwnProperty('company_detail')) {
                if (parseInt(userLoginDetailTemp.company_detail.follow_status ?? 0) === 0) {
                    history.push(`/${UrlSlugs.settings_followersNotices}`);
                    return false;
                }
            }
        }

        getCompanyFollowerList(userLoginDetailTemp)
        setUserLoginDetail(userLoginDetailTemp);

        let segment = Utilities.getUrlSegments();
        let urlSlug = segment.slice(-1)[0];
        let tabTitle = TitlesLabels.navigations.suspendFollower;

        let pageTypeName = 'suspend';
        if (urlSlug === 'notification-followers-remove') {
            tabTitle = TitlesLabels.navigations.removeFollower;
            pageTypeName = 'remove';
        }

        globalPageType = pageTypeName;
        setPageType(pageTypeName);
        setBreadcrumbsName(tabTitle);

        dispatch(pageTitle(`${TitlesLabels.navigations.followersList} | ${tabTitle}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));


    }, []);

    return (
        <div className="notication-company-followers-page team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <Link to={`/${UrlSlugs.settings_notificationManagement}`}>{TitlesLabels.navigations.notificationManagement}</Link>
                            <Link to={`/${UrlSlugs.settings_notificationFollowers}`}>{TitlesLabels.navigations.followersList}</Link>
                            <div className="current">{breadcrumbsName}</div>

                        </li>
                        <li className="btn-option">
                            {
                                Object.keys(listProfiles).length > 0 &&
                                <div className="btn-icon btn-magnify" onClick={() => toggleSeachBox()} title={TitlesLabels.general.searchProfile}></div>
                            }

                            <Dropdown className="dropdown-user-selection">
                                <Dropdown.Toggle variant="dropdown-user-current btn-icon-wrap" >
                                    <div className="btn-icon btn-dots-vertical" title={TitlesLabels.general.notificationOption}></div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-medium">
                                    <div onClick={() => handleRedirect(UrlSlugs.settings_notificationFollowersSuspend)} className="py-2 px-4 dropdown-item font-gotham-book fs-18 fs-md-16" role="button">suspend follower</div>
                                    <div onClick={() => handleRedirect(UrlSlugs.settings_notificationFollowersRemove)} className="py-2 px-4 dropdown-item font-gotham-book fs-18 fs-md-16" role="button">remove follower</div>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content position-relative">

                <div className="content-subnav-wrap profile-search-wrap d-none">
                    <div className="container py-2 d-flex align-items-center px-5">
                        <div className="profile-search-wrap bg-white d-flex align-items-center ml-3 form-theme  w-100 border-radius">
                            <div className='form-group mb-0 border-0  w-100 '>
                                <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} placeholder="type here" autoComplete='off' />
                            </div>
                            {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => resetSearchValue()}>clear</span></div>}
                        </div>

                    </div>
                </div>

                <div className="container pt-4">

                    {
                        showProcessing ?
                            <ul className="ul-global-list px-5">
                                {
                                    [...Array(12).keys()].map((key) =>
                                        <li key={key} className="global-preloader  py-2">
                                            <div className="logo-size-60 bg-gray logo-size-md-60 border-0"></div>
                                            <div>
                                                <div className="bg-gray col-4 pt-3 border-radius-loader"></div>
                                                <div className="mt-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                            :
                            <>
                                {
                                    Object.keys(listProfiles).length === 0 ?
                                        <div className="text-center text-lowercase no-record-label mt-5">{Utilities.labelProfileAvailable}</div>
                                        :
                                        <div className='px-5  checkbox-radio-default checkbox-radio-check-icon form-theme'>

                                            <div className="d-flex align-items-center justify-content-between my-3">
                                                <div className='font-gotham-bold fs-20 fs-16'>select all</div>
                                                <div className="mr-n1"><input type="checkbox" checked={`${toggleAll ? 'checked' : ''}`} onChange={(e) => toggleMemberAll()} /></div>
                                            </div>

                                            <ul id="listing-profiles" className={`listing-profiles  ul-global-list notranslate list-has-check`}>
                                                {
                                                    Object.entries(listProfiles).map(([key, row]) =>
                                                        <li key={key} data-keyword={row.name} className={`${memberIDsSelected.includes(row.account_id.toString()) ? 'selected' : ''}`} onClick={(e) => toggleActiveClass(e, row)}>
                                                            <div className="profile-image-wrap">
                                                                {/* <div className={`profile-image logo-size-60 logo-size-md-60`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo)  }}>{ Utilities.showHideInitial(row.logo, row.name) }</div> */}
                                                                <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={row} />
                                                            </div>
                                                            <div className='line-height-1'>
                                                                <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1" data-name={row.name}>{row.name} </div>
                                                                <div className="profile-label font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.title}</div>
                                                            </div>

                                                        </li>
                                                    )
                                                }
                                            </ul>

                                            <div className="button-group-boolean col-md-5 mx-auto pt-5 mt-5">
                                                <Link to={`/${UrlSlugs.settings_notificationFollowers}`} className="btn-theme-black">cancel</Link>
                                                <button id="btn-submit-status" type="button" className="btn-theme-black button-has-loader" onClick={() => handleClickSubmitStatus()}>submit</button>
                                            </div>

                                        </div>
                                }
                            </>
                    }

                </div>
            </div>
        </div>
    )
}

export default FollowersSupendRemove;