import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';

import Utilities from '../helpers/Utilities';
import ChatUtilities from '../helpers/ChatUtilities';
import ApiUrls from '../helpers/ApiUrls';

import { passConversDetail, chatSidebarBlock } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';
import CommonProfileLogo from './common/CommonProfileLogo';
import SessionUtilities from '../helpers/SessionUtilities';


const ChatTransfer = (props) => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const API_CHAT_TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    let defaultFormValues = { message: '' };
    const maxAttachmentAllowed = 10;

    const [formValues, setFormValues] = useState(defaultFormValues);

    const [companyDetail, setCompanyDetail] = useState({});
    const [listTeams, setListTeams] = useState([]);
    const [listDepartments, setListDepartments] = useState([]);

    const [loadingTeams, setLoadingTeams] = useState(true);
    const [loadingDepartments, setLoadingDepartments] = useState(true);

    const [transferTeamID, setTransferTeamID] = useState(0);
    const [transferDeptID, setTransferDeptID] = useState(0);

    const [listTypeName, setListTypeName] = useState('team members');
    const [listType, setListType] = useState('team');
    const [transferWho, setTransferWho] = useState(1);

    const [sectionType, setSectionType] = useState('selection');

    const [profileSelected, setProfileSelected] = useState({ name: '', title: '', logo_url: '' });
    const [attachments, setAttachments] = useState([]);


    const pushValue = (e, fieldName, detail = {}) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let profileRequest = {};

        if (fieldName === 'transferteam') {
            setTransferTeamID(parseInt(value));
            setTransferWho(1);

            profileRequest = {
                logo_url: detail.logo_url,
                name: detail.name,
                title: detail.team_position,
            }

        } else if (fieldName === 'transferdepartment') {
            setTransferDeptID(parseInt(value));
            setTransferWho(2);

            profileRequest = {
                logo_url: companyDetail.logo_url,
                name: detail.department_name,
                title: companyDetail.name,
            }

        } else if (fieldName === 'message') {
            formValuesTemp = { ...formValuesTemp, ...{ message: value } };
        }

        if (Object.keys(profileRequest).length > 0) {
            setProfileSelected(profileRequest);
        }

        if (Object.keys(formValuesTemp).length > 0) {
            //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
            Utilities.removeFormGroupClass(fieldName);
            setFormValues(formValuesTemp);
        }

    }

    const getTeamMembers = (companyID) => {
        if (parseInt(companyID) > 0) {
            axios
                .post(ApiUrls.teamList, { company_id: companyID })
                .then(response => {

                    setLoadingTeams(false);

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus === 1) {
                            if (Utilities.isEmpty(dataResponse.list)) {
                                setListTeams([]);
                            } else {

                                let teamList = [];
                                Object.entries(dataResponse.list).map(([key, row]) => {
                                    if (parseInt(props.activeProfile.account_id) !== parseInt(row.account_id)) {
                                        teamList = [...teamList, row];
                                    }
                                });

                                setListTeams(teamList);
                            }
                        }
                    } else {
                        setListTeams([]);
                    }
                })
                .catch((err) => {
                    setListTeams([]);
                    setLoadingTeams(false);
                });
        }
    }

    const getDepartments = (companyID) => {
        if (parseInt(companyID) > 0) {
            axios
                .post(ApiUrls.departmentListByAccountID, { id: companyID, show_company: 1 })
                .then(response => {

                    setLoadingDepartments(false);

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);
                        const companyDetailTemp = dataResponse.company_detail;

                        setCompanyDetail(companyDetailTemp);

                        if (dataStatus === 1) {
                            if (Utilities.isEmpty(dataResponse.departments)) {
                                setListDepartments([]);
                            } else {

                                let deptList = [];
                                Object.entries(dataResponse.departments).map(([key, row]) => {
                                    if (parseInt(props.activeProfile.group_id) !== parseInt(row.group_id)) {
                                        deptList = [...deptList, row];
                                    }
                                });
                                setListDepartments(deptList);
                            }
                        } else {
                            setListDepartments([]);
                        }
                    }

                })
                .catch((err) => {
                    setListDepartments([]);
                    setLoadingDepartments(false);
                });
        }

    }

    const handleCloseTransferContent = () => {
        props.handleCloseTransfer();
        dispatch(chatSidebarBlock(''));
    }


    const handleViewType = (viewType = 'team', typeName = '') => {

        viewType = viewType.toString().toLowerCase();
        if (viewType === 'team') {
            getTeamMembers(props.activeProfile.team_company_id);
        } else if (viewType === 'department') {
            getDepartments(props.activeProfile.team_company_id);
        }

        setListTypeName(typeName);
        setTransferTeamID(0);
        setTransferDeptID(0);
        setListType(viewType);
    }


    const submitTransferAttachments = async () => {

        let transferDocumentsReturn = {};

        if (parseInt(attachments.length) > 0) {

            try {
                const passValue = {
                    account_id: props.activeProfile.account_id.toString(),
                    company_id: props.activeProfile.team_company_id.toString(),
                    attachments: attachments
                }
                const urlRequest = ApiUrls.chatUploadAttachmentTransfer;
                const toolStatus = await axios.post(urlRequest, passValue);
                transferDocumentsReturn = toolStatus;

            } catch (e) {
                transferDocumentsReturn = {};
            }

        }
        return transferDocumentsReturn;
    }

    const submitTransferProper = async () => {

        const button_name = 'btn-submit-transfer';
        Utilities.elementDisabledStatus(button_name, false);

        if (Utilities.isEmpty(formValues.message)) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.messageIsRequired);
            return false;
        }

        if (parseInt(props.conversID) > 0 && parseInt(props.activeProfile.account_id) > 0) {

            Utilities.elementDisabledStatus(button_name, true);

            let receiver_team_account_id = parseInt(transferWho) === 1 ? transferTeamID : 0;
            let department_id = parseInt(transferWho) === 2 ? transferDeptID : 0;

            const attachentsAvailable = await submitTransferAttachments();

            let attachmentResult = [];
            if (attachentsAvailable.status === 200) {
                const attachmentResponse = attachentsAvailable.data;

                if (parseInt(attachmentResponse.status) === 1) {
                    attachmentResult = attachmentResponse.attachments;
                }

                Utilities.elementDisabledStatus(button_name, false);

            }


            let account_department_id = 0;
            let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);

            if (loginProfileInfo !== null) {
                loginProfileInfo = JSON.parse(loginProfileInfo);
                if (Object.keys(loginProfileInfo).length > 0) {

                    let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                    if (profileLoggedinTemp.hasOwnProperty('group_id')) {
                        account_department_id = parseInt(profileLoggedinTemp.group_id || 0);
                    }
                }
            }


            /* const passValue = {
                api_token: API_CHAT_TOKEN.toString(),
                convers_id: props.conversID.toString(),
                account_id: props.activeProfile.account_id.toString(),
                company_id: props.activeProfile.team_company_id.toString(),
                receiver_team_account_id: receiver_team_account_id.toString(),
                department_id: department_id.toString(),
                transf_message: formValues.message.toString(),
                account_department_id: account_department_id.toString(),
                transf_attachments: attachmentResult
            } */


            const passValue = {
                api_token: TOKEN_SESSION.toString(),
                convers_id: props.conversID.toString(),
                account_id: props.activeProfile.account_id.toString(),
                account_department_id: account_department_id.toString(),

                company_id: props.activeProfile.team_company_id.toString(),
                receiver_team_account_id: receiver_team_account_id.toString(),
                receiver_department_id: department_id.toString(),
                transf_message: formValues.message.toString(),
                transf_attachments: attachmentResult
            }

            Utilities.elementDisabledStatus(button_name, true);

            /* console.log('submitTransferProper');
            console.log(ApiUrls.bizchatTransferConversation);
            console.log(passValue); */

            await axios
                .post(ApiUrls.bizchatTransferConversation, passValue)
                .then(response => {

                    console.log(response);
                    Utilities.elementDisabledStatus(button_name, false);

                    if (response.status === 201) {
                        const dataResponse = response.data;


                        if (parseInt(dataResponse.success) === 1) {
                            Utilities.messagePopup('success', response.data.successMessage);

                            const conversid = props.conversID;
                            let currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail(conversid);
                            currentConversationDetailTemp = { ...currentConversationDetailTemp, ...{ access_status: '0' } };

                            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp);

                            dispatch(passConversDetail(currentConversationDetailTemp));

                            handleCloseTransferContent();

                        } else {
                            Utilities.messagePopup('error', response.data.errorMessage);
                        }

                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }

                    ChatUtilities.scrollBottomConversation();

                })
                .catch((err) => {
                    Utilities.elementDisabledStatus(button_name, false);
                    ChatUtilities.scrollBottomConversation();
                });


        }
    }

    const submitSelection = () => {

        if (parseInt(props.conversID) > 0 && parseInt(props.activeProfile.account_id) > 0) {

            const total_sum_id = parseInt(transferTeamID) + parseInt(transferDeptID);
            if (parseInt(total_sum_id) === 0) {
                Utilities.messagePopup('error', TitlesLabels.alertMessages.noItemSelected);
                return false;
            }

            setAttachments([]);
            setSectionType('message');
        }
    }

    const selectFile = () => {
        jQuery('input#transfer_attachment').trigger('click');
    }

    const previewAttachment = (e) => {
        let fileLocation = e.target.value;
        const fileValue = e.target.files[0];
        let fileLocationArr = fileLocation.toString().split("\\");

        if (parseInt(attachments.length) === parseInt(maxAttachmentAllowed)) {
            Utilities.messagePopup('error', `maximum of ${maxAttachmentAllowed} attachments allowed!`);
            jQuery(e.target).val('');
            return false;
        }

        var reader = new FileReader();
        reader.onload = function (e) {
            const filebase64 = e.target.result;
            const attachmentsTemp = [...attachments, { name: fileLocationArr.slice(-1)[0].toString(), base64: filebase64 }];

            setAttachments(attachmentsTemp);
            jQuery(e.target).val('');
        };

        reader.readAsDataURL(fileValue);
    }



    const removeAttachment = (e, key = -1) => {
        key = parseInt(key);

        let attachmentsTemp = attachments;
        if (key > -1) {
            attachmentsTemp.splice(key, 1);
        }
        attachmentsTemp = [...attachmentsTemp];
        setAttachments(attachmentsTemp);

    }

    useEffect(() => {

        if (Object.keys(props.activeProfile ?? {}).length > 0) {
            getTeamMembers(props.activeProfile.team_company_id);
        }

    }, [props.activeProfile]);

    return (
        <div className="container">
            {
                sectionType === 'message' &&

                <div className="col-md-10 mx-auto pt-5 broadcast-popup-content form-theme">

                    <div className="col-8 mx-auto pt-5">
                        <div className="transfer-head-profile">
                            <ul id="listing-transfer-team" className="list-members  ul-global-list  list-member-transfer-selected height-inherit-important">
                                <li className='border-0'>
                                    <div>
                                        {/* <div className="profile-image logo-size-60 logo-size-md-60" style={{ backgroundImage:  `url(${profileSelected.logo_url})`  }}></div> */}
                                        <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={profileSelected} />
                                    </div>
                                    <div className='line-height-1'>
                                        <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1">{profileSelected.name}</div>
                                        <div className="profile-labe color-theme-grey">{profileSelected.title}</div>
                                    </div>
                                </li>
                            </ul>
                            <div className="btn-close cursor-pointer" onClick={() => handleCloseTransferContent()}></div>
                        </div>


                        <div className="pt-4">

                            <div className="form-group">
                                <textarea name="message_transfer" className="form-control" placeholder=" " rows="6" autoComplete="off" value={formValues.message} onChange={(e) => pushValue(e, 'message')}  ></textarea>
                                <label className="form-label">type here</label>
                            </div>


                            <div className="btn-attachement-trigger cursor-pointer mt-n3" onClick={() => selectFile()} >add attachment</div>

                            <ul className="attachment-list-selected mt-3m text-lowercase">
                                {
                                    attachments.map((row, key) =>
                                        <li key={key}>
                                            <div className="filename">{row.name}</div><div className="btn-remove-attachment" onClick={(e) => removeAttachment(e, key)}></div>
                                        </li>
                                    )
                                }
                            </ul>

                        </div>

                    </div>

                    <input type="file" name="transfer-attachment" id="transfer_attachment" className="invisible" readOnly onChange={(e) => previewAttachment(e)} />

                    <div className="button-group-boolean col-md-5 mt-4  mx-auto ">
                        <button type="button" className="btn-theme-black" onClick={() => setSectionType('selection')}>cancel</button>
                        <button type="button" id="btn-submit-transfer" className="btn-theme-black button-has-loader" onClick={() => submitTransferProper()}>submit</button>
                    </div>

                </div>
            }


            {
                sectionType === 'selection' &&
                <div className="col-md-10 mx-auto pt-5 broadcast-popup-content">

                    <div className="transfer-head">
                        <div className="font-gotham-bold fs-20 fs-md-22 text-center">transfer chat</div>
                        {/* <div className="btn-close cursor-pointer" onClick={ () => handleCloseTransferContent() }></div> */}
                    </div>

                    <div className="form-theme  checkbox-radio-default checkbox-radio-check-icon ">

                        <div>

                            <div className="text-theme-green mt-4">transfer to</div>

                            <div className="form-group">
                                <Dropdown>
                                    <Dropdown.Toggle variant="dropdown-icon dropdown-right" className="w-100 text-left px-0">{listTypeName}</Dropdown.Toggle>
                                    <Dropdown.Menu className="w-100">
                                        <Dropdown.Item onClick={() => handleViewType('department', 'departments')}>departments</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleViewType('team', 'team members')}>team members</Dropdown.Item>
                                    </Dropdown.Menu>

                                </Dropdown>
                            </div>


                            {
                                listType === 'team' ?
                                    <div>
                                        {loadingTeams === true && <div className="content-loader animate"></div>}
                                        {
                                            Object.keys(listTeams).length > 0 ?
                                                <ul id="listing-transfer-team" className="list-members ul-global-list  mt-2">
                                                    {
                                                        Object.entries(listTeams).map(([key, row]) =>
                                                            <li key={key} data-keyword={row.name}>
                                                                <div>
                                                                    {/* <div className="profile-image logo-size-60 logo-size-md-60" style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url) }}>{ Utilities.showHideInitial(row.logo_url, row.name) }</div> */}
                                                                    <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={row} />
                                                                </div>
                                                                <div className='line-height-1'>
                                                                    <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1">{row.name}</div>
                                                                    <div className="profile-label color-theme-grey">{row.title}</div>
                                                                </div>
                                                                <div>
                                                                    <input type="radio" name="transferteam" value={row.account_id} onChange={(e) => pushValue(e, 'transferteam', row)} checked={transferTeamID === parseInt(row.account_id)} />
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                                :
                                                <div className={`text-center text-lowercase no-record-label mb-5 ${loadingTeams === true ? 'd-none' : ''}`}>{TitlesLabels.general.noTeamMemberAvailable}</div>
                                        }
                                    </div>
                                    :
                                    <div>
                                        {loadingDepartments === true && <div className="content-loader animate"></div>}
                                        {
                                            Object.keys(listDepartments).length > 0 ?
                                                <ul id="listing-transfer-department" className="list-department-transfer mt-2">
                                                    {
                                                        Object.entries(listDepartments).map(([key, row]) =>
                                                            <li key={key} data-keyword={row.department_name} className='border-bottom border-theme-gray py-3'>
                                                                <div className="dept-name font-gotham-book fs-18 fs-md-18">{row.department_name}</div>
                                                                <input type="radio" name="transferdepartment" value={row.group_id} onChange={(e) => pushValue(e, 'transferdepartment', row)} checked={transferDeptID === parseInt(row.group_id || 0)} />
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                                :
                                                <div className={`text-center text-lowercase no-record-label mb-5 ${loadingDepartments === true ? 'd-none' : ''}`}>{TitlesLabels.general.noDepartmentAvailable}</div>
                                        }
                                    </div>
                            }


                        </div>

                        {
                            ((loadingTeams === false || loadingDepartments === false) && (Object.keys(listDepartments).length > 0 || Object.keys(listTeams).length > 0)) &&
                            <div className="button-group-boolean col-md-5 mx-auto mt-5">
                                <button type="button" className="btn-theme-black" onClick={() => handleCloseTransferContent()}>cancel</button>
                                <button id="btn-transfer-chat" type="button" className="btn-theme-black" onClick={() => submitSelection()}>continue</button>
                            </div>
                        }


                    </div>
                </div>

            }

        </div>
    )
}

export default ChatTransfer;
