import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";
//import jQuery from 'jquery';
//import AppleLogin from 'react-apple-login';
import { FacebookProvider, LoginButton } from "react-facebook";
//import InstagramLogin from 'react-instagram-login';

//import Utilities from '../helpers/Utilities';
import { showHeader, showFooter, pageTitle } from '../actions';

import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';
import PagesAuthentication from '../helpers/PagesAuthentication';
import SessionUtilities from '../helpers/SessionUtilities';

const SocialConnect = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const siteTitle = process.env.REACT_APP_site_title;
    const imgFolder = process.env.REACT_APP_img_folder;

    const social_gogle_client_id = process.env.REACT_APP_social_google_client_id;
    const social_gogle_plugin_name = process.env.REACT_APP_social_google_plugin_name;

    //const social_apple_plugin_name = process.env.REACT_APP_social_apple_plugin_name; 
    /* const social_apple_client_id = process.env.REACT_APP_social_apple_client_id; 
    const social_apple_redirect_url = process.env.REACT_APP_social_apple_redirect_url;  */

    const social_facebook_app_id = process.env.REACT_APP_social_facebook_app_id;
    //const social_facebook_redirect_url = process.env.REACT_APP_social_facebook_redirect_url; 

    /* const social_instagram_app_id = '577509640826258'; //process.env.REACT_APP_social_instagram_app_id; 
    const social_instagram_redirect_url = '';//process.env.REACT_APP_social_instagram_redirect_url;  */


    //const localstorage_social_signup_connect = process.env.REACT_APP_session_signup_social_connect; 

    const classSocialWrap = `mb-3 cursor-pointer position-relative overflow-hidden`;
    const defaultProfileDetail = { type: '0', name: '', first_name: '', last_name: '', email: '', imageUrl: '', token: '', app_id: '' };
    let useDetailTemp = {};

    const [useDetail, setUserDetail] = useState(defaultProfileDetail);
    const [isHuawei, setIsHuawei] = useState(false);



    const properRedirect = () => {
        let redirectUrl = UrlSlugs.personalInfo;
        const session_signupCampaign = SessionUtilities.getSignupCampaign();
        if (Object.keys(session_signupCampaign).length > 0) {
            redirectUrl = `/${UrlSlugs.signup_campaignPersonalInfo}`;
        }

        history.push(redirectUrl);
    }

    const redirectToInfoPage = (dataProvied = {}) => {
        setUserDetail(dataProvied);
        PagesAuthentication.setSignupSocialDetail(dataProvied);
        //localStorage.setItem(  localstorage_social_signup_connect, JSON.stringify(dataProvied) );

        properRedirect();
    }

    // 1: GOOGLE, 2: APPLE, 3 : FACEBOOK, 4 : INSTAGRAM
    /// GOOGLE 1 ///////////////////////////////////////////
    const google_onSuccess = (response) => {
        //console.log('Success:', response);

        useDetailTemp = {
            ...useDetail,
            type: '1',
            name: response.profileObj.name ?? '',
            first_name: response.profileObj.givenName ?? '',
            last_name: response.profileObj.familyName ?? '',
            email: response.profileObj.email ?? '',
            imageUrl: response.profileObj.imageUrl ?? '',
            token: response.profileObj.email ?? '', //response.accessToken ?? '',
            app_id: response.googleId ?? ''
        };

        redirectToInfoPage(useDetailTemp);
    };

    const google_onFailure = (response) => {
        //console.log('Failed:', response);
        //Utilities.messagePopup('Error', TitlesLabels.general.couldNotFetchData )
    };


    /// APPLE 2 //////////////////////////////////////////////////////////////////////////////////////
    const apple_onSuccess = (response) => {
        //console.log('Success:', response);
    }


    /// INSTAGRAM 4 //////////////////////////////////////////////////////////////////////////////////////
    const instagram_onSuccess = (response) => {
        //console.log('Success:', response);
    }

    const instagram_onFailure = (response) => {
        //console.log('Failed:', response);
        //Utilities.messagePopup('Error', TitlesLabels.general.couldNotFetchData )
    };


    /// FACEBOOK 3 //////////////////////////////////////////////////////////////////////////////////////
    const facebook_onSuccess = (response) => {
        //console.log('Success:', response);

        useDetailTemp = {
            ...useDetail,
            type: '3',
            name: response.profile.name ?? '',
            first_name: response.profile.first_name ?? '',
            last_name: response.profile.last_name ?? '',
            email: response.profile.email ?? '',
            imageUrl: response.profile.picture.data.url ?? '',
            token: response.profile.email ?? '', //response.tokenDetail.accessToken ?? '',
            app_id: response.profile.id ?? ''
        };
        //console.log(useDetailTemp, response.tokenDetail.accessToken ?? '');
        redirectToInfoPage(useDetailTemp);
    }

    const facebook_onFailure = (response) => {
        //console.log('Failed:', response);
        //Utilities.messagePopup('Error', TitlesLabels.general.couldNotFetchData )
    };



    /// BEONCHAt //////////////////////////////////////////////////////////////////////////////////////
    const handleClick_beonchat = () => {
        //localStorage.setItem(  localstorage_social_signup_connect, JSON.stringify(defaultProfileDetail) );
        // PagesAuthentication.setSignupSocialDetail(defaultProfileDetail);
        PagesAuthentication.deleteSignupSocialDetail();
        properRedirect();
    }


    useEffect(() => {

        dispatch(pageTitle(TitlesLabels.siteTitles.personalInformation));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                clientId: social_gogle_client_id,
                plugin_name: social_gogle_plugin_name
            })
        });

        // CHECK BROWER IF HAUWEI
        const agentBrowser = (window.navigator.appVersion ?? '').toLowerCase();
        let position = agentBrowser.search("huaweibrowser");
        setIsHuawei(parseInt(position) > 0 ? true : false);

        // DELETE SOCIAL CONNECT 
        PagesAuthentication.deleteSignupSocialDetail();

    }, []);


    return (
        <div className='signup-page with-footer centerialized   text-lowercase fs-18'>

            <div className='signup-wrap text-center'>
                <SignupHeader title="personal account" />

                <div className='labels mt-5'>choose one</div>

                <div className='d-flex justify-content-center mt-5'>
                    <ul className='text-left fs-20  checkbox-radio-default'>

                        {
                            !isHuawei &&
                            <li className='d-flex align-items-center'>
                                <GoogleLogin
                                    clientId={social_gogle_client_id}
                                    buttonText="Login"
                                    render={renderProps => (
                                        <div className={classSocialWrap} onClick={renderProps.onClick} disabled={renderProps.disabled} ><img src={`${imgFolder}/icons/social/outline-blue-circle-google.svg`} className="mr-3" width={40} />google</div>
                                    )}
                                    onSuccess={google_onSuccess}
                                    onFailure={google_onFailure}
                                    cookiePolicy={"single_host_origin"}
                                />
                            </li>

                        }

                        <li className='d-flex align-items-center'>

                            <FacebookProvider appId={social_facebook_app_id}>
                                <LoginButton
                                    className="bg-transparent border-0 px-0"
                                    scope="email"
                                    onCompleted={facebook_onSuccess}
                                    onError={facebook_onFailure}
                                >
                                    <div className={classSocialWrap}><img src={`${imgFolder}/icons/social/outline-blue-circle-facebook.svg`} className="mr-3" width={40} />facebook</div>
                                </LoginButton>
                            </FacebookProvider>
                        </li>

                        <li className='d-flex align-items-center'>
                            <div className={classSocialWrap} onClick={() => handleClick_beonchat()}><img src={`${imgFolder}/icons/social/outline-blue-circle-beonchat.svg`} className="mr-3" width={40} />{siteTitle.toLowerCase()}</div>
                        </li>
                    </ul>

                </div>
            </div>


            <div className='pb-5 pt-5 pt-md-0'>
                <Link to={`/${UrlSlugs.signup_accountType}`} className='fs-18'>{TitlesLabels.general.exitWithoutSaving}</Link>
            </div>


        </div>
    )
}

export default SocialConnect;