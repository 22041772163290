import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Utilities from '../../helpers/Utilities';
import { passImageUrl as passImageUrlAction } from '../../actions';
import ApiUrls from '../../helpers/ApiUrls';

const PreviewImage = () => {

    const dispatch = useDispatch();
    const passImageUrl = useSelector(state => state.passImageUrl);

    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const [imgUrl, setImgUrl] = useState('');
    const [imgThumbUrl, setImgThumbUrl] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [imageLoading, setImageLoading] = useState(true);
    const [currentPageSlug, setCurrentPageSlug] = useState('');

    const handleClosePreviewImage = () => {
        setShowPopup(false);
        dispatch(passImageUrlAction(''));
    }

    const showThumbnail = () => {
        setImgUrl(imgThumbUrl);
    }

    useEffect(() => {

        if (!Utilities.isEmpty(passImageUrl)) {

            let lastvalue = '';
            if (typeof passImageUrl.split('/').pop() === 'undefined') {
                lastvalue = DEFAUL_AVATAR;

                setShowPopup(true);
                setImageLoading(true);
                setImgThumbUrl(lastvalue);
                setImgUrl(lastvalue);

                return false;
            }

            lastvalue = passImageUrl.split('/').slice(-1).pop();

            if (!Utilities.isEmpty(lastvalue)) {

                setShowPopup(true);
                setImageLoading(true);
                setImgThumbUrl(passImageUrl);

                let passImageUrlTemp = passImageUrl.replace("thumb_", "");
                passImageUrlTemp = passImageUrlTemp.replace("_thumb", "");

                setImgUrl(passImageUrlTemp);

            }

            let segment = Utilities.getUrlSegments()
            if (segment.length > 0) {

                const slugFirst = segment.shift().toString().toLowerCase();
                setCurrentPageSlug(slugFirst === 'chat-conversation' ? true : false);

            }
        }

    }, [passImageUrl]);

    return (

        <Modal id="popup-preview-image" show={showPopup} onHide={handleClosePreviewImage} animation={false} aria-labelledby="contained-modal-title-vcenter" className="popup-preview-image" centered size="md" contentClassName='bg-transparent border-0' backdropClassName='popup-preview-image-backdrop'>
            <Modal.Body className="p-0">

                {!Utilities.isEmpty(imgUrl) ?
                    <>
                        <img src={imgUrl} width="100%" className={imageLoading ? 'd-none' : ''} onLoad={() => setImageLoading(false)} onError={() => showThumbnail()} ></img>

                        {
                            currentPageSlug &&
                            <div className="w-100 btn-option d-flex align-items-center justify-content-center mt-3">
                                <a href={`${ApiUrls.awsDownloadImage}${imgUrl}`} className="btn-icon btn-download" title="download"></a>
                            </div>
                        }


                    </>
                    :
                    <div className="content-loader animate"></div>
                }

                {imageLoading && <div className="content-loader animate"></div>}


            </Modal.Body>
        </Modal>
    )
}

export default PreviewImage;
