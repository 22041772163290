import axios from 'axios';
import ApiUrls from './ApiUrls';
import SessionUtilities from './SessionUtilities';

const API_PLUS_TOKEN = process.env.REACT_APP_chat_api_token;

export const sendAppReports = async (formData = {}) => {
    let responseDetail = {}

    try {
        const responseURL = await axios.post(ApiUrls.sendingEmailAppReports, formData);
        if (parseInt(responseURL.data.status) === 1) {
            responseDetail = { ...responseDetail, ...responseURL.data };
        }

    } catch (e) {

    }

    return responseDetail;
}


export const sendVerificationCode = async (formData = {}) => {
    let responseDetail = {}

    try {
        //console.log( ApiUrls.sendingProfileSignupVerification, formData );
        //console.log('sendVerificationCode');
        //console.log(ApiUrls.sendingProfileSignupVerification);
        //console.log(formData);

        const responseURL = await axios.post(ApiUrls.sendingProfileSignupVerification, formData);
        if (parseInt(responseURL.status) === 201) {
            responseDetail = responseURL.data;
        }

    } catch (e) {

    }

    return responseDetail;
}

export const sendEmailGeneral = async (formData = {}) => {
    let responseDetail = {}
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    
    try {
        formData = { ...formData, ...{ api_token: TOKEN_SESSION.toString() } };

        //console.log('sendEmailGeneral');
        //console.log(ApiUrls.sendingEmailSystem);
        //console.log(formData);

        const responseURL = await axios.post(ApiUrls.sendingEmailSystem, formData);
        //console.log(responseURL);
        if (parseInt(responseURL.status) === 201) {
            responseDetail = responseURL.data;
        }

    } catch (e) {

    }

    return responseDetail;
}



export const sendContact = async (passval = {}) => {
    let responseDetail = {};
    const TOKEN_SESSION = "030723553bff7"; //SessionUtilities.getSessionToken();

    if (Object.entries(passval).length > 0) {
        try {
            passval = { ...passval, ...{ account_id: '0', api_token: TOKEN_SESSION.toString() } };
            
            //console.log('sendContact');
            //console.log(ApiUrls.sendingEmailGeneral);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.sendingEmailGeneral, passval);
            if (parseInt(responseURL.status) === 201) {
                responseDetail = responseURL.data;
                //console.log(responseDetail);
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}