import axios from 'axios';
import ApiUrls from './ApiUrls';


export const searchAreaCityByName = async (keyword = "") => {
    let responseDetail = [];

    if (keyword != "") {
        try {
            const responseURL = await axios.post(ApiUrls.cityAreaSearhByName, { keyword: keyword });
            if (parseInt(responseURL.status) === 200) {
                if (parseInt(responseURL.data.status) === 1) {
                    responseDetail = responseURL.data.list;
                }
            }

        } catch (e) {

        }
    }

    return responseDetail;
}


export const getAddressbyAreaId = async (area_id = 0) => {
    let responseDetail = {};

    if (parseInt(area_id) > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.areaLocationByAreaId, { id: area_id });
            if (parseInt(responseURL.status) === 200) {
                if (parseInt(responseURL.data.status) === 1) {
                    responseDetail = responseURL.data.detail;
                }
            }

        } catch (e) {

        }
    }

    return responseDetail;
}


export const getAddressbyCityId = async (city_id = 0) => {
    let responseDetail = {};

    if (parseInt(city_id) > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.citySearchByCityId, { id: city_id });
            if (parseInt(responseURL.status) === 200) {
                if (parseInt(responseURL.data.status) === 1) {
                    responseDetail = responseURL.data.detail;
                }
            }

        } catch (e) {

        }
    }

    return responseDetail;
}



export const getLocationByCoordinates = async (requestLatitude = '', requestLongitude = '') => {
    let responseDetail = {};

    requestLatitude = requestLatitude.toString();
    requestLongitude = requestLongitude.toString();

    if (requestLatitude !== '' && requestLongitude !== '') {
        try {
            //console.log(ApiUrls.getLocationByCoordinates, { latitude : requestLatitude, longtitude :  requestLongitude});
            const responseURL = await axios.post(ApiUrls.getLocationByCoordinates, { latitude: requestLatitude, longtitude: requestLongitude });
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = responseURL.data.result;
            }


        } catch (e) {

        }
    }

    return responseDetail;
}


export const getCoordinatesByLocation = async (requestLocation = '') => {
    let responseDetail = {};

    if (requestLocation !== '') {
        try {
            const responseURL = await axios.post(ApiUrls.getCoordinatesByLocation, { location_name: requestLocation });
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = responseURL.data.result[0];
            }


        } catch (e) {

        }
    }

    return responseDetail;
}