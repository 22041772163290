import React from 'react';

const DirectorySearchBackNavigation = (props) => {
    return <div className="state-navigation-wrap  pb-3">
        <div className="btn-back cursor-pointer" onClick={() => props.handleClick()}></div>
        <div className=" ml-3">{props.children}{props.text ?? ''}</div>
    </div>

}


export default DirectorySearchBackNavigation;