import React, { useState, useEffect } from "react";
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import jQuery from 'jquery';
import { useDispatch } from 'react-redux';

import Utilities from "../../helpers/Utilities";
import TitlesLabels from "../../helpers/TitlesLabels";


import { useHistory } from 'react-router-dom';
import UrlSlugs from "../../helpers/UrlSlugs";
import PagesAuthentication from "../../helpers/PagesAuthentication";
import { dailyGlancePremiumDailyAds, dailyGlancePremiumDailyAdsReadStatus, submitGlanceContacForm, submitGlanceEventsForm, submitGlanceFeedbackForm, submitGlanceNewsAttachments, submitGlanceNewsForm } from "../../helpers/QueryDailyGlance";
import FieldInput from "../../components/FieldInput";
import DatePickerSelection from "../../components/popups/DatePickerSelection";
import TimePickerSelection from "../../components/popups/TimePickerSelection";
import SessionUtilities from "../../helpers/SessionUtilities";
import CommonProfileLogo from "../../components/common/CommonProfileLogo";
import { showDirectoryProfilePopup, showLoader, viewMenuPricePopup } from "../../actions";
import QueryMasterSearchUtlities from "../../helpers/QueryMasterSearchUtlities";
import { gettingPremiumDailyAds } from "../../helpers/UtilitiesDailyGlance";

const API_TOKEN = process.env.REACT_APP_chat_api_token;
const ERROR_MESG = process.env.REACT_APP_message_error_process;
const IMG_PATH = process.env.REACT_APP_img_folder;
let GLOBAL_PASSVAL = {}, GLOBAL_RESPONSE = {}, GLOBAL_STATUS = 0;



export const DailyGlanceDateSelectionPopup = (props) => {

    const showPopup = props.showPopup ?? false;
    const dateList = props.dateList ?? [];
    const dateSelected = props.dateSelected ?? {};

    const handleClickDate = (dateChoosen = {}) => {
        if (Object.keys(dateChoosen).length > 0) {
            props.handleClickDate(dateChoosen);
        }

    }

    return <Modal id="popup-manage-notice-dates-option" show={showPopup} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme "  >
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body className='pb-5 pt-0 mt-n3 px-5 text-lowercase'>

            <div className="font-gotham-bold fs-25 text-center border-bottom pb-4">select date</div>

            <ul className="ul-global-listing cursor-pointer font-gotham-book fs-18 list-has-check">
                {
                    Object.entries(dateList).map(([key, row]) =>
                        <li className={`border-bottom 
                                        ${!Utilities.isEmpty(dateSelected.date ?? '') && dateSelected.date === row.date ? 'selected' : ''}`}
                            onClick={() => handleClickDate(row)}
                            key={key}
                        >
                            {row.date_name}</li>
                    )
                }
            </ul>


        </Modal.Body>
    </Modal>

}


export const DailyGlanceMainPopup = (props) => {

    const history = useHistory();

    const showPopup = props.showPopup ?? false;
    const typeName = props.typeName ?? false;
    const dateYYmmdd = props.selectedDate ?? '';
    const selectedCategory = props.selectedCategory ?? {};
    const listCategories = props.listCategories ?? [];
    const totalPageEvent = props.totalPageEvent ?? [];
    const totalRegistered = props.totalRegistered ?? [];
    const totalPageFeatured = props.totalPageFeatured ?? [];


    const [showNewsSubmissionPopup, setShowNewsSubmissionPopup] = useState(false);
    const [showPublicEventPopup, setShowPublicEventPopup] = useState(false);
    const [showContactFormPopup, setShowContactFormPopup] = useState(false);
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);



    const handleClickEventFeatured = (type = 1) => {
        type = parseInt(type);
        if (Utilities.isEmpty(dateYYmmdd)) { return false; }

        if (type === 1) {
            history.push(`/${UrlSlugs.notificationDailyGlanceEvents}/${dateYYmmdd}`);
        } else if (type === 2) {
            history.push(`/${UrlSlugs.notificationDailyGlanceSignUps}/${dateYYmmdd}`);
        } else if (type === 3) {
            history.push(`/${UrlSlugs.notificationDailyGlanceFeatured}/${dateYYmmdd}`);
        }

    }


    const handleClickCategorySelection = (detail = {}) => {
        if (Object.keys(detail).length > 0) {
            props.handleClickCategorySelection(detail);
        }
    }

    const handleClickAction = (action = 0) => {
        action = parseInt(action);

        // NEWS SUBMISSION
        if (action === 1) {
            handleClickNewSubmissionPopup(true);

            // PUBLIC EVENT
        } else if (action === 2) {
            handleClickPublicEvenPopup(true);

            // CONTACT
        } else if (action === 3) {
            handleClickContactPopup(true);

            // FEEDBACk
        } else if (action === 4) {
            handleClickFeedbackPopup(true);

        }

        if (action >= 1 && action <= 4) {
            props.handleClosePopUp();
        }
    }


    const handleClickNewSubmissionPopup = (boolAction = false) => {
        setShowNewsSubmissionPopup(boolAction);
    }

    const handleClickPublicEvenPopup = (boolAction = false) => {
        setShowPublicEventPopup(boolAction);
    }

    const handleClickContactPopup = (boolAction = false) => {
        setShowContactFormPopup(boolAction);
    }

    const handleClickFeedbackPopup = (boolAction = false) => {
        setShowFeedbackPopup(boolAction);
    }


    return (
        <>
            <Modal id="popup-daily-galnce" show={showPopup} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="lg" dialogClassName="popup-daily-galnce modal-dialog-theme-black"  >
                <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
                <Modal.Body className='pb-5 pt-0 mt-n3 px-5 text-lowercase font-gotham-book fs-18 fs-md-14'>

                    <ul className="row font-gotham-bold fs-20 fs-md-16 mb-2">

                        {
                            Object.entries(listCategories).map(([key, row]) =>
                                <li key={key} className="col-md-6 mb-4" onClick={() => handleClickCategorySelection(row)}>
                                    <span className={`border-radius cursor-pointer ${parseInt(selectedCategory.category_id ?? -2) === parseInt(row.category_id ?? 0) ? 'px-3 py-2 bg-white text-black' : ''}`}>
                                        {parseInt(row.category_id ?? -1) === -1 ? 'today' : (row.category_name ?? '')}</span>
                                </li>
                            )
                        }

                        {
                            totalPageEvent > 0 &&
                            <li className="col-md-6 mb-4" onClick={() => handleClickEventFeatured(1)}  >
                                <span className={`border-radius cursor-pointer ${typeName === 'events' ? 'px-3 py-2 bg-white text-black' : ''}`}>{TitlesLabels.general.events ?? ''}</span>
                            </li>
                        }

                        {
                            totalRegistered > 0 &&
                            <li className="col-md-6 mb-4" onClick={() => handleClickEventFeatured(2)}  >
                                <span className={`border-radius cursor-pointer ${typeName === 'signups' ? 'px-3 py-2 bg-white text-black' : ''}`}>{TitlesLabels.general.signUps ?? ''}</span>
                            </li>
                        }

                        {
                            totalPageFeatured > 0 &&
                            <li className="col-md-6 mb-4" onClick={() => handleClickEventFeatured(3)}  >
                                <span className={`border-radius cursor-pointer ${typeName === 'featured' ? 'px-3 py-2 bg-white text-black' : ''}`}>{TitlesLabels.general.featuredArticles ?? ''}</span>
                            </li>
                        }


                    </ul>


                    <div className="py-3 border-bottom border-theme-grey cursor-pointer" onClick={() => handleClickAction(1)}>
                        <div className="fs-20 fs-md-16 font-gotham-bold">news submission</div>
                        <div>a community event that people should know</div>
                    </div>

                    <div className="py-3 border-bottom border-theme-grey cursor-pointer" onClick={() => handleClickAction(2)}>
                        <div className="fs-20 fs-md-16 font-gotham-bold">public event</div>
                        <div>a community event that people should know</div>
                    </div>

                    <div className="py-3 border-bottom border-theme-grey cursor-pointer" onClick={() => handleClickAction(3)}>
                        <div className="fs-20 fs-md-16 font-gotham-bold">contact</div>
                        <div>our general chat</div>
                    </div>

                    <div className="py-3 border-bottom border-theme-grey cursor-pointer" onClick={() => handleClickAction(4)}>
                        <div className="fs-20 fs-md-16 font-gotham-bold">feedback</div>
                        <div>we welcome complaints and compliments</div>
                    </div>


                </Modal.Body>
            </Modal>

            <DailyGlanceNewsFormPopup showPopup={showNewsSubmissionPopup} handleClosePopUp={handleClickNewSubmissionPopup} />
            <DailyGlanceEventFormPopup showPopup={showPublicEventPopup} handleClosePopUp={handleClickPublicEvenPopup} />
            <DailyGlanceContactFormPopup showPopup={showContactFormPopup} handleClosePopUp={handleClickContactPopup} />
            <DailyGlanceFeedbackFormPopup showPopup={showFeedbackPopup} handleClosePopUp={handleClickFeedbackPopup} />
        </>
    )

}



/////////////////////////////////////////////////////////////////////
//  PREMIUM ADS SETTING
/////////////////////////////////////////////////////////////////////

export const DailyGlancePremiumAdsPopup = (props) => {

    const dispatch = useDispatch();

    const [showPremiumAdsPopup, setShowPremiumAdsPopup] = useState(false);
    const [premiumDailyAdsDetail, setPremiumDailyAdsDetail] = useState({});

    const handleClosePremiumAdsPopup = async () => {

        // UPDATE DB
        setShowPremiumAdsPopup(false);
        await markAsReadAds();
    }


    const markAsReadAds = async () => {
        console.log('updated read status');
        const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
        await dailyGlancePremiumDailyAdsReadStatus({ group_id: (premiumDailyAdsDetail.group_id ?? 0).toString(), account_id: profileLoggedin.account_id ?? 0 },);

    }



    const checkingGettingPremiumAdsTodayIfAny = async () => {

        let currentDate = moment().format(Utilities.dateGlanceFormatStr); // '2023-09-28';
        const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();

        //currentDate = '2024-06-05';

        const premiumDailyAdsRes = await dailyGlancePremiumDailyAds({ date_request: currentDate.toString(), account_id: profileLoggedin.account_id ?? 0 },);
        if (parseInt(premiumDailyAdsRes.status ?? 0) === 1) {
            setPremiumDailyAdsDetail(premiumDailyAdsRes.detail);
            setShowPremiumAdsPopup(true);
        }
    }


    const handleClickMenu = async (actionValue = 0) => {
        actionValue = parseInt(actionValue);
        let showProfileBool = false;

        if (actionValue === 1) {
            window.open(premiumDailyAdsDetail.website_link.toString(), '_blank');

        } else if (actionValue === 2) {
            if (!Utilities.isEmpty(premiumDailyAdsDetail.menu_link)) {
                dispatch(viewMenuPricePopup({ file_url: premiumDailyAdsDetail.menu_link.toString(), new_tab: 0 }));
            } else {
                showProfileBool = true;
            }

        } else if (actionValue === 3) {

            if (!Utilities.isEmpty(premiumDailyAdsDetail.external_link)) {
                window.open(premiumDailyAdsDetail.external_link.toString(), '_blank');

            }


        } else if (actionValue === 4) {
            showProfileBool = true;

        }

        if (actionValue > 0) {
            await markAsReadAds();
        }

        if (showProfileBool) {
            //console.log(premiumDailyAdsDetail, premiumDailyAdsDetail.company_id);
            dispatch(showLoader(1));
            const profileDetail = await QueryMasterSearchUtlities.getProfileDetail(premiumDailyAdsDetail.company_id ?? 0);
            dispatch(showDirectoryProfilePopup(profileDetail.list[(premiumDailyAdsDetail.company_id ?? 0).toString()]));
        }
    }


    useEffect(async () => {
        await checkingGettingPremiumAdsTodayIfAny();

    }, []);


    return <Modal id="popup-manage-glance-premium-ads" show={showPremiumAdsPopup} onHide={handleClosePremiumAdsPopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme " backdrop="static" keyboard={false} contentClassName="overflow-hidden">
        <Modal.Header closeButton className="border-bottom-0 bg-black text-white">
            <div className="d-flex align-items-center justify-content-center pt-4 pb-3 w-100 pl-4 text-lowercase">
                <div className=" text-center">
                    <CommonProfileLogo classAttr={`profile-image fs-14-important logo-size-90 logo-size-md-90 mx-auto`} profileDetail={{ name: premiumDailyAdsDetail.company_name ?? '', logo_url: premiumDailyAdsDetail.company_logo ?? '' }} preventOnclick={true} />
                    <div className="mt-2 font-gotham-bold fs-20 fs-md-20">{premiumDailyAdsDetail.company_name ?? ''}</div>
                </div>
            </div>
        </Modal.Header>
        <Modal.Body className='pt-4 px-0 pb-0 text-lowercase '>

            <div className="text-center px-4 pb-3">

                <img src={premiumDailyAdsDetail.banner_image ?? ''} className="mb-3 ads-premium-banner" />
                <div className="fs-17 fs-md-17 mb-3">{premiumDailyAdsDetail.description ?? ''}</div>
            </div>

            <div className="bg-gray py-4 px-4">
                <ul className="ul-premium-ads-menu d-flex align-items-center justify-content-around fs-15 fs-md-15 text-center">
                    {
                        !Utilities.isEmpty(premiumDailyAdsDetail.website_link) &&
                        <li onClick={() => handleClickMenu(1)}>
                            <div className="menu-icon icon-website-globe mx-auto mb-2"></div>
                            <div>website</div>
                        </li>
                    }

                    {
                        parseInt(premiumDailyAdsDetail.catelog_status ?? 0) === 1 &&
                        <li onClick={() => handleClickMenu(2)}>
                            <div className="menu-icon icon-specials  mx-auto mb-2"></div>
                            <div>specials</div>
                        </li>
                    }

                    {
                        parseInt(premiumDailyAdsDetail.flag_type ?? 0) > 0 &&
                        <li onClick={() => handleClickMenu(3)}>
                            <div className="menu-icon icon-pricelist mx-auto mb-2"></div>
                            <div>pricelist</div>
                        </li>
                    }

                    <li onClick={() => handleClickMenu(4)}>
                        <div className="menu-icon icon-profile mx-auto mb-2"></div>
                        <div>profile</div>
                    </li>
                </ul>
            </div>

        </Modal.Body>
    </Modal>

}



/////////////////////////////////////////////////////////////////////
//  NEWS FORM
/////////////////////////////////////////////////////////////////////
export const DailyGlanceNewsFormPopup = (props) => {

    const showPopup = props.showPopup ?? false;
    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    const button_name = 'btn-submit-glance-news-form';
    const maxCharacters = 200;

    const dateFormatted = Utilities.currentDateFormatted();
    const timeFormatted = Utilities.returnTimeFormat();

    const defaultFormValues = { title: '', date: dateFormatted, time: timeFormatted, description: '' };

    const [uploadList, setUploadList] = useState([]);

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [showDatePickerPopup, setShowDatePickerPopup] = useState(false);
    const [showTimePickerPopup, setShowTimePickerPopup] = useState(false);

    const [requestDate, setRequestDate] = useState(new Date());
    const [requestTime, setRequestTime] = useState(timeFormatted);

    const handleClosePopUp = () => {
        props.handleClosePopUp();
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'news_description') {

            const charsCounter = Utilities.charactersCounter(value);
            if (parseInt(charsCounter) > maxCharacters) {
                Utilities.messagePopup('error', `Only ${maxCharacters} characters allowed!`);
                return false;
            }

            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ description: value } };

        } else if (fieldName === 'news_title') {
            formValuesTemp = { ...formValuesTemp, ...{ title: value } };
        }

        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);

    }

    const handleHoldDateTime = (typeName = 'date') => {
        if (typeName === 'date') {
            setShowDatePickerPopup(true);
        } else {
            setShowTimePickerPopup(true);
        }
    }


    const handleDateChange = (date = '') => {
        if (!Utilities.isEmpty(date)) {

            const dateRequestFormatted = Utilities.currentDateFormatted(date);
            setFormValues({ ...formValues, ...{ date: dateRequestFormatted } });

            setRequestDate(date);
            setShowDatePickerPopup(false);
        }

    }

    const handleTimeChange = (timeProvided = '') => {
        setFormValues({ ...formValues, ...{ time: timeProvided } });
        setRequestTime(timeProvided);
        setShowTimePickerPopup(false);
    }



    const handleSubmit = async () => {

        let errorCounter = 0;
        const fieldsChecker = ['title', 'date', 'time', 'description'];

        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {
                if (Utilities.isEmpty(value)) {
                    Utilities.addFormGroupClass(`news_${key}`);
                    errorCounter++;
                }
            }
        });


        if (Object.keys(uploadList).length === 0) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.imagesMandatory ?? '');
            errorCounter++;
        }

        if (errorCounter > 0) { return false; }

        let feed_image = '';
        let requestDate = formValues.date;
        let requestTime = requestDate.toString() + ' ' + formValues.time.toString() + ':00';

        requestTime = moment(new Date(requestTime.toString())).format('HH:mm:ss').toLocaleString();
        requestDate = moment(new Date(requestDate.toString())).format('YYYY-MM-DD').toLocaleString();


        // UPLOAD IMAGES
        const formImagesData = new FormData();
        Object.entries(uploadList).map(([key, row]) => {
            formImagesData.append('attachments[]', row.values);
        });

        Utilities.elementDisabledStatus(button_name, true);
        GLOBAL_RESPONSE = await submitGlanceNewsAttachments(formImagesData);
        if (parseInt(GLOBAL_RESPONSE.status ?? 0) === 1) {
            feed_image = GLOBAL_RESPONSE.file_names ?? '';
        }

        if (Utilities.isEmpty(feed_image)) {
            Utilities.messagePopup('error', TitlesLabels.general.couldNotUploadFile ?? '');
            return false;
        }

        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        GLOBAL_PASSVAL = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: (profileLoggedin.account_id ?? 0).toString(),
            account_type: (profileLoggedin.account_type ?? 0).toString(),
            account_department_id: (profileLoggedin.group_id ?? 0).toString(),
            date: requestDate ?? '',
            time: requestTime ?? '',
            title: formValues.title ?? '',
            description: formValues.description ?? '',
            image_list: feed_image ?? '',
        }

        GLOBAL_RESPONSE = await submitGlanceNewsForm(GLOBAL_PASSVAL);
        Utilities.elementDisabledStatus(button_name, false);

        if (Object.keys(GLOBAL_RESPONSE).length === 0) {
            Utilities.messagePopup('error', ERROR_MESG);
            return false;
        }

        GLOBAL_STATUS = parseInt(GLOBAL_RESPONSE.success ?? 0);
        if (GLOBAL_STATUS === 0) {
            Utilities.messagePopup('error', GLOBAL_RESPONSE.errorMessage ?? '');
            return false;
        }

        Utilities.messagePopup('success', GLOBAL_RESPONSE.successMessage ?? '');

        resetForm();
    }

    const resetForm = () => {
        setFormValues(defaultFormValues);
        setUploadList([]);
    }

    const handleClickRemoveAttachment = (paramKey = -1) => {
        paramKey = parseInt(paramKey);
        let uploadListTemp = [];

        if (paramKey >= 0) {
            Object.entries(uploadList).map(([key, row]) => {
                if (parseInt(key) != parseInt(paramKey)) {
                    uploadListTemp.push(row);
                }
            });
        }

        setUploadList(uploadListTemp);
    }


    const previewImage = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            const values = e.target.files[0];


            var reader = new FileReader();
            reader.onload = function (e) {
                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }


                    let uploadListTem = [...uploadList];
                    uploadListTem.push({ values: values, image: e.target.result });
                    setUploadList(uploadListTem);
                    jQuery(elem).val('');
                }
            };

            reader.readAsDataURL(values);


        } else {
            jQuery(elem).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    useEffect(() => {
        resetForm();

        return (() => {
            resetForm();
        });

    }, [])


    return (
        <>
            <Modal id="popup-daily-galnce-news-form" show={showPopup} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="popup-daily-galnce-contact-form modal-dialog-theme"  >
                <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4 modal-heade">news form</Modal.Header>
                <Modal.Body className='pb-5 pt-4 mt-n2 px-5 text-lowercase font-gotham-book fs-14 fs-md-14 form-theme'>

                    <div className="mb-3">submit an news that you would like us to list. acceptance of your submission for publication is at the sole discretion of the editorial committee</div>


                    <FieldInput inputName="news_title" inputLabel="title" inputValue={formValues.title} pushValue={pushValue} checkerType="general" />

                    <div className='row'>

                        <div className='col-md-6' onClick={() => handleHoldDateTime('date')}>
                            <FieldInput inputName="news_date" inputLabel="date" inputValue={formValues.date} pushValue={pushValue} checkerType="" readOnly={true} />
                        </div>
                        <div className='col-md-6' onClick={() => handleHoldDateTime('time')}>
                            <FieldInput inputName="news_time" inputLabel="time" inputValue={formValues.time} pushValue={pushValue} checkerType="" readOnly={true} />
                        </div>

                    </div>

                    <div className="form-group  border-0 active">
                        <textarea id="news_description" name="news_description" className="form-control  px-0 text-tranform-initial" rows="8" value={formValues.description} onChange={(e) => pushValue(e, 'news_description')} placeholder={`type here (max ${maxCharacters} characters)`} autoFocus></textarea>
                        <label className="form-label px-0">description</label>
                    </div>

                    <ul class="glance-attachents-inputs">
                        {
                            Object.entries(uploadList ?? []).map(([key, row]) =>
                                <li key={key} className={`${key > 0 ? ' ml-4' : ''}`} style={{ backgroundImage: `url('${row.image ?? ''}')` }}>
                                    <img src={`${IMG_PATH}/icons/red/circle-remove.svg`} width={30} className="btn-remove-icon" onClick={() => handleClickRemoveAttachment(key)} />
                                </li>
                            )
                        }
                        {
                            Object.keys(uploadList).length < 5 &&
                            <li className="ml-4 text-center d-flex align-items-center justify-content-center color-theme-grey">
                                <input type="file" readOnly onChange={(e) => previewImage(e)} />
                                <div>
                                    <img src={`${IMG_PATH}/icons/gray/outline-upload.svg`} width={25} />
                                    <div className="fs-13 fs-md-13 mt-1">upload a file</div>
                                </div>
                            </li>
                        }

                    </ul>

                    <div className="button-group-boolean  mt-4">
                        <button type="button" className="btn-theme-black" onClick={handleClosePopUp}>cancel</button>
                        <button id="btn-submit-glance-news-form" type="button" className="btn-theme-black button-has-loader" onClick={handleSubmit}>submit</button>
                    </div>


                </Modal.Body>

            </Modal>

            <DatePickerSelection showPopUp={showDatePickerPopup} selectedDate={requestDate} handleClosePopUp={handleDateChange} />
            <TimePickerSelection showPopUp={showTimePickerPopup} providedTime={requestTime} handleClosePopUp={handleTimeChange} />
        </>
    )
}


/////////////////////////////////////////////////////////////////////
//  EVENTS FORM
/////////////////////////////////////////////////////////////////////
export const DailyGlanceEventFormPopup = (props) => {

    const showPopup = props.showPopup ?? false;
    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    const button_name = 'btn-submit-glance-news-form';
    const maxCharacters = 200;

    const dateFormatted = Utilities.currentDateFormatted();
    const timeFormatted = Utilities.returnTimeFormat();

    const defaultFormValues = { name: '', location: '', date: dateFormatted, time: timeFormatted, description: '' };

    const [uploadList, setUploadList] = useState([]);

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [showDatePickerPopup, setShowDatePickerPopup] = useState(false);
    const [showTimePickerPopup, setShowTimePickerPopup] = useState(false);

    const [requestDate, setRequestDate] = useState(new Date());
    const [requestTime, setRequestTime] = useState(timeFormatted);

    const handleClosePopUp = () => {
        props.handleClosePopUp();
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'event_description') {

            const charsCounter = Utilities.charactersCounter(value);
            if (parseInt(charsCounter) > maxCharacters) {
                Utilities.messagePopup('error', `Only ${maxCharacters} characters allowed!`);
                return false;
            }

            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ description: value } };

        } else if (fieldName === 'event_name') {
            formValuesTemp = { ...formValuesTemp, ...{ name: value } };

        } else if (fieldName === 'event_location') {
            formValuesTemp = { ...formValuesTemp, ...{ location: value } };
        }

        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);

    }

    const handleHoldDateTime = (typeName = 'date') => {
        if (typeName === 'date') {
            setShowDatePickerPopup(true);
        } else {
            setShowTimePickerPopup(true);
        }
    }


    const handleDateChange = (date = '') => {
        if (!Utilities.isEmpty(date)) {

            const dateRequestFormatted = Utilities.currentDateFormatted(date);
            setFormValues({ ...formValues, ...{ date: dateRequestFormatted } });

            setRequestDate(date);
            setShowDatePickerPopup(false);
        }

    }

    const handleTimeChange = (timeProvided = '') => {
        setFormValues({ ...formValues, ...{ time: timeProvided } });
        setRequestTime(timeProvided);
        setShowTimePickerPopup(false);
    }



    const handleSubmit = async () => {

        let errorCounter = 0;
        const fieldsChecker = ['name', 'location', 'date', 'time', 'description'];

        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {
                if (Utilities.isEmpty(value)) {
                    Utilities.addFormGroupClass(`event_${key}`);
                    errorCounter++;
                }
            }
        });


        if (Object.keys(uploadList).length === 0) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.imagesMandatory ?? '');
            errorCounter++;
        }

        if (errorCounter > 0) { return false; }

        let feed_image = '';
        let requestDate = formValues.date;
        let requestTime = requestDate.toString() + ' ' + formValues.time.toString() + ':00';

        requestTime = moment(new Date(requestTime.toString())).format('HH:mm:ss').toLocaleString();
        requestDate = moment(new Date(requestDate.toString())).format('YYYY-MM-DD').toLocaleString();


        // UPLOAD IMAGES
        const formImagesData = new FormData();
        Object.entries(uploadList).map(([key, row]) => {
            formImagesData.append('attachments[]', row.values);
        });

        Utilities.elementDisabledStatus(button_name, true);
        GLOBAL_RESPONSE = await submitGlanceNewsAttachments(formImagesData);
        if (parseInt(GLOBAL_RESPONSE.status ?? 0) === 1) {
            feed_image = GLOBAL_RESPONSE.file_names ?? '';
        }

        if (Utilities.isEmpty(feed_image)) {
            Utilities.messagePopup('error', TitlesLabels.general.couldNotUploadFile ?? '');
            return false;
        }

        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        GLOBAL_PASSVAL = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: (profileLoggedin.account_id ?? 0).toString(),
            account_type: (profileLoggedin.account_type ?? 0).toString(),
            account_department_id: (profileLoggedin.group_id ?? 0).toString(),
            date: requestDate ?? '',
            time: requestTime ?? '',
            name: formValues.name ?? '',
            location: formValues.location ?? '',
            description: formValues.description ?? '',
            image_list: feed_image ?? '',
        }

        GLOBAL_RESPONSE = await submitGlanceEventsForm(GLOBAL_PASSVAL);
        Utilities.elementDisabledStatus(button_name, false);

        if (Object.keys(GLOBAL_RESPONSE).length === 0) {
            Utilities.messagePopup('error', ERROR_MESG);
            return false;
        }

        GLOBAL_STATUS = parseInt(GLOBAL_RESPONSE.success ?? 0);
        if (GLOBAL_STATUS === 0) {
            Utilities.messagePopup('error', GLOBAL_RESPONSE.errorMessage ?? '');
            return false;
        }

        Utilities.messagePopup('success', GLOBAL_RESPONSE.successMessage ?? '');

        resetForm();
    }

    const resetForm = () => {
        setFormValues(defaultFormValues);
        setUploadList([]);
    }

    const handleClickRemoveAttachment = (paramKey = -1) => {
        paramKey = parseInt(paramKey);
        let uploadListTemp = [];

        if (paramKey >= 0) {
            Object.entries(uploadList).map(([key, row]) => {
                if (parseInt(key) != parseInt(paramKey)) {
                    uploadListTemp.push(row);
                }
            });
        }

        setUploadList(uploadListTemp);
    }


    const previewImage = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            const values = e.target.files[0];


            var reader = new FileReader();
            reader.onload = function (e) {
                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }


                    let uploadListTem = [...uploadList];
                    uploadListTem.push({ values: values, image: e.target.result });
                    setUploadList(uploadListTem);
                    jQuery(elem).val('');
                }
            };

            reader.readAsDataURL(values);


        } else {
            jQuery(elem).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    useEffect(() => {
        resetForm();

        return (() => {
            resetForm();
        });

    }, [])



    return (
        <>
            <Modal id="popup-daily-galnce-event-form" show={showPopup} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="popup-daily-galnce-contact-form modal-dialog-theme"  >
                <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4 modal-heade">event form</Modal.Header>
                <Modal.Body className='pb-5 pt-4 mt-n2 px-5 text-lowercase font-gotham-book fs-14 fs-md-14 form-theme'>

                    <div className="mb-3">submit an event that you would like us to list. acceptance of your submission for publication is at the sole discretion of the editorial committee</div>

                    <div className='row'>

                        <div className='col-md-6'>
                            <FieldInput inputName="event_name" inputLabel="event name" inputValue={formValues.name} pushValue={pushValue} checkerType="general" />
                        </div>
                        <div className='col-md-6'>
                            <FieldInput inputName="event_location" inputLabel="location" inputValue={formValues.location} pushValue={pushValue} checkerType="" />
                        </div>

                        <div className='col-md-6' onClick={() => handleHoldDateTime('date')}>
                            <FieldInput inputName="news_date" inputLabel="date" inputValue={formValues.date} pushValue={pushValue} checkerType="" readOnly={true} />
                        </div>
                        <div className='col-md-6' onClick={() => handleHoldDateTime('time')}>
                            <FieldInput inputName="event_time" inputLabel="time" inputValue={formValues.time} pushValue={pushValue} checkerType="" readOnly={true} />
                        </div>

                    </div>

                    <div className="form-group  border-0 active">
                        <textarea id="event_description" name="event_description" className="form-control  px-0 text-tranform-initial" rows="8" value={formValues.description} onChange={(e) => pushValue(e, 'event_description')} placeholder={`type here (max ${maxCharacters} characters)`} autoFocus></textarea>
                        <label className="form-label px-0">description</label>
                    </div>

                    <ul class="glance-attachents-inputs">
                        {
                            Object.entries(uploadList ?? []).map(([key, row]) =>
                                <li key={key} className={`${key > 0 ? ' ml-4' : ''}`} style={{ backgroundImage: `url('${row.image ?? ''}')` }}>
                                    <img src={`${IMG_PATH}/icons/red/circle-remove.svg`} width={30} className="btn-remove-icon" onClick={() => handleClickRemoveAttachment(key)} />
                                </li>
                            )
                        }
                        {
                            Object.keys(uploadList).length < 5 &&
                            <li className="ml-4 text-center d-flex align-items-center justify-content-center color-theme-grey">
                                <input type="file" readOnly onChange={(e) => previewImage(e)} />
                                <div>
                                    <img src={`${IMG_PATH}/icons/gray/outline-upload.svg`} width={25} />
                                    <div className="fs-13 fs-md-13 mt-1">upload a file</div>
                                </div>
                            </li>
                        }

                    </ul>

                    <div className="button-group-boolean  mt-4">
                        <button type="button" className="btn-theme-black" onClick={handleClosePopUp}>cancel</button>
                        <button id="btn-submit-glance-news-form" type="button" className="btn-theme-black button-has-loader" onClick={handleSubmit}>submit</button>
                    </div>


                </Modal.Body>

            </Modal>

            <DatePickerSelection showPopUp={showDatePickerPopup} selectedDate={requestDate} handleClosePopUp={handleDateChange} />
            <TimePickerSelection showPopUp={showTimePickerPopup} providedTime={requestTime} handleClosePopUp={handleTimeChange} />
        </>
    )
}


/////////////////////////////////////////////////////////////////////
//  CONTACT FORM
/////////////////////////////////////////////////////////////////////
export const DailyGlanceContactFormPopup = (props) => {

    const showPopup = props.showPopup ?? false;
    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    const button_name = 'btn-submit-glance-contact-form';
    const maxCharacters = 200;

    const [description, setDescription] = useState('');

    const handleClosePopUp = () => {
        clearMessage();
        props.handleClosePopUp();
    }

    const clearMessage = () => { setDescription(''); }


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'contact-description') {

            const charsCounter = Utilities.charactersCounter(value);
            if (parseInt(charsCounter) > maxCharacters) {
                Utilities.messagePopup('error', `Only ${maxCharacters} characters allowed!`);
                return false;
            }

            value = Utilities.removedEmojis(value);
            setDescription(value);
        }

        Utilities.removeFormGroupClass(fieldName);

    }


    const handleSubmit = async () => {

        const descValue = Utilities.removedEmojis(description);

        if (Utilities.isEmpty(descValue)) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.descriptionIsRequired);
            return false;
        }

        const charsCounter = Utilities.charactersCounter(descValue);
        if (parseInt(charsCounter) > maxCharacters) {
            Utilities.messagePopup('error', `Only ${maxCharacters} characters allowed!`);
            return false;
        }

        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        GLOBAL_PASSVAL = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: (profileLoggedin.account_id ?? 0).toString(),
            account_type: (profileLoggedin.account_type ?? 0).toString(),
            account_department_id: (profileLoggedin.group_id ?? 0).toString(),
            message: description ?? ''
        }


        Utilities.elementDisabledStatus(button_name, true);
        GLOBAL_RESPONSE = await submitGlanceContacForm(GLOBAL_PASSVAL);
        Utilities.elementDisabledStatus(button_name, false);

        if (Object.keys(GLOBAL_RESPONSE).length === 0) {
            Utilities.messagePopup('error', ERROR_MESG);
            return false;
        }

        GLOBAL_STATUS = parseInt(GLOBAL_RESPONSE.success ?? 0);
        if (GLOBAL_STATUS === 0) {
            Utilities.messagePopup('error', GLOBAL_RESPONSE.errorMessage ?? '');
            return false;
        }

        Utilities.messagePopup('success', GLOBAL_RESPONSE.successMessage ?? '');

        clearMessage();
    }


    useEffect(() => {
        clearMessage();

        return (() => {
            clearMessage();
        });

    }, [])

    return <Modal id="popup-daily-galnce-contact-form" show={showPopup} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="popup-daily-galnce-contact-form modal-dialog-theme"  >
        <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4 modal-heade">contact form</Modal.Header>
        <Modal.Body className='pb-5 pt-4 mt-n2 px-5 text-lowercase font-gotham-book fs-14 fs-md-14 form-theme'>

            <div>contact us with any queries that you may have regarding @atglance</div>

            <div>
                <div className="form-group mt-3 border-0">
                    <textarea id="contact-description" name="contact-description" className="form-control  px-0 text-tranform-initial" rows="8" value={description} onChange={(e) => pushValue(e, 'contact-description')} placeholder=" " autoFocus></textarea>
                    <label className="form-label px-0">description</label>
                </div>

                <div className="text-center fs-md-14">
                    <div className="text-color-gray mb-1">{maxCharacters} characters</div>
                    <div><span className="cursor-pointer text-color-blue mt-3" onClick={() => clearMessage()}>clear</span></div>

                </div>
            </div>

            <div className="button-group-boolean  mt-4">
                <button type="button" className="btn-theme-black" onClick={handleClosePopUp}>cancel</button>
                <button id="btn-submit-glance-contact-form" type="button" className="btn-theme-black button-has-loader" onClick={handleSubmit}>submit</button>
            </div>
        </Modal.Body>

    </Modal>
}



/////////////////////////////////////////////////////////////////////
//  FEEDBACK FORM
/////////////////////////////////////////////////////////////////////
export const DailyGlanceFeedbackFormPopup = (props) => {

    const showPopup = props.showPopup ?? false;
    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    const button_name = 'btn-submit-glance-feedback-form';
    const maxCharacters = 200;

    const dateFormatted = Utilities.currentDateFormatted();
    const timeFormatted = Utilities.returnTimeFormat();

    const defaultFormValues = { subject: '', date: dateFormatted, time: timeFormatted, description: '' };

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [showDatePickerPopup, setShowDatePickerPopup] = useState(false);
    const [showTimePickerPopup, setShowTimePickerPopup] = useState(false);

    const [requestDate, setRequestDate] = useState(new Date());
    const [requestTime, setRequestTime] = useState(timeFormatted);

    const handleClosePopUp = () => {
        props.handleClosePopUp();
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'feedback_description') {

            const charsCounter = Utilities.charactersCounter(value);
            if (parseInt(charsCounter) > maxCharacters) {
                Utilities.messagePopup('error', `Only ${maxCharacters} characters allowed!`);
                return false;
            }

            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ description: value } };

        } else if (fieldName === 'feedback_subject') {
            formValuesTemp = { ...formValuesTemp, ...{ subject: value } };
        }

        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);

    }

    const handleHoldDateTime = (typeName = 'date') => {
        if (typeName === 'date') {
            setShowDatePickerPopup(true);
        } else {
            setShowTimePickerPopup(true);
        }
    }


    const handleDateChange = (date = '') => {
        if (!Utilities.isEmpty(date)) {

            const dateRequestFormatted = Utilities.currentDateFormatted(date);
            setFormValues({ ...formValues, ...{ date: dateRequestFormatted } });

            setRequestDate(date);
            setShowDatePickerPopup(false);
        }

    }

    const handleTimeChange = (timeProvided = '') => {
        setFormValues({ ...formValues, ...{ time: timeProvided } });
        setRequestTime(timeProvided);
        setShowTimePickerPopup(false);
    }


    const handleSubmit = async () => {

        let errorCounter = 0;
        const fieldsChecker = ['subject', 'date', 'time', 'description'];

        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {
                if (Utilities.isEmpty(value)) {
                    Utilities.addFormGroupClass(`feedback_${key}`);
                    errorCounter++;
                }
            }
        });

        if (errorCounter > 0) { return false; }

        let requestDate = formValues.date;
        let requestTime = requestDate.toString() + ' ' + formValues.time.toString() + ':00';

        requestTime = moment(new Date(requestTime.toString())).format('HH:mm:ss').toLocaleString();
        requestDate = moment(new Date(requestDate.toString())).format('YYYY-MM-DD').toLocaleString();

        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        GLOBAL_PASSVAL = {
            api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
            account_id: (profileLoggedin.account_id ?? 0).toString(),
            account_type: (profileLoggedin.account_type ?? 0).toString(),
            account_department_id: (profileLoggedin.group_id ?? 0).toString(),
            date: requestDate ?? '',
            time: requestTime ?? '',
            subject: formValues.subject ?? '',
            message: formValues.description ?? ''
        }


        Utilities.elementDisabledStatus(button_name, true);
        GLOBAL_RESPONSE = await submitGlanceFeedbackForm(GLOBAL_PASSVAL);
        Utilities.elementDisabledStatus(button_name, false);

        if (Object.keys(GLOBAL_RESPONSE).length === 0) {
            Utilities.messagePopup('error', ERROR_MESG);
            return false;
        }

        GLOBAL_STATUS = parseInt(GLOBAL_RESPONSE.success ?? 0);
        if (GLOBAL_STATUS === 0) {
            Utilities.messagePopup('error', GLOBAL_RESPONSE.errorMessage ?? '');
            return false;
        }

        Utilities.messagePopup('success', GLOBAL_RESPONSE.successMessage ?? '');

        setFormValues(defaultFormValues);
    }



    return (
        <>
            <Modal id="popup-daily-galnce-feedback-form" show={showPopup} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="popup-daily-galnce-feedback-form modal-dialog-theme"  >
                <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4 modal-heade">feedback form</Modal.Header>
                <Modal.Body className='pb-5 pt-4 mt-n2 px-5 text-lowercase font-gotham-book fs-14 fs-md-14 form-theme'>

                    <div className="mb-3">submit any complaints or compliments that you wish to share with us</div>


                    <FieldInput inputName="feedback_subject" inputLabel="subject" inputValue={formValues.subject} pushValue={pushValue} checkerType="general" />

                    <div className='row'>

                        <div className='col-md-6' onClick={() => handleHoldDateTime('date')}>
                            <FieldInput inputName="feedback_date" inputLabel="date" inputValue={formValues.date} pushValue={pushValue} checkerType="" readOnly={true} />
                        </div>
                        <div className='col-md-6' onClick={() => handleHoldDateTime('time')}>
                            <FieldInput inputName="feedback_time" inputLabel="time" inputValue={formValues.time} pushValue={pushValue} checkerType="" readOnly={true} />
                        </div>

                    </div>

                    <div className="form-group  border-0 active">
                        <textarea id="feedback_description" name="feedback_description" className="form-control  px-0 text-tranform-initial" rows="8" value={formValues.description} onChange={(e) => pushValue(e, 'feedback_description')} placeholder={`type here (max ${maxCharacters} characters)`} autoFocus></textarea>
                        <label className="form-label px-0">description</label>
                    </div>


                    <div className="button-group-boolean  mt-4">
                        <button type="button" className="btn-theme-black" onClick={handleClosePopUp}>cancel</button>
                        <button id="btn-submit-glance-feedback-form" type="button" className="btn-theme-black button-has-loader" onClick={handleSubmit}>submit</button>
                    </div>

                </Modal.Body>

            </Modal>

            <DatePickerSelection showPopUp={showDatePickerPopup} selectedDate={requestDate} handleClosePopUp={handleDateChange} />
            <TimePickerSelection showPopUp={showTimePickerPopup} providedTime={requestTime} handleClosePopUp={handleTimeChange} />

        </>
    )
}