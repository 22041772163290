import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { canvasPreview } from '../../helpers/imageCropper/canvasPreview';
import { useDebounceEffect } from '../../helpers/imageCropper/useDebounceEffect';

import Utilities from '../../helpers/Utilities';

import { showLoader } from '../../actions';

const ImageCropperPopup = (props) => {

    const dispatch = useDispatch();
    const imageSelected = props.imageSelected ?? '';

    const MAX_WIDTH = 800;
    const MAX_HEIGHT = 800;
    const MIME_TYPE = "image/jpeg";
    const QUALITY = 0.3;

    const isMobileDevice = Utilities.isMobileByScreenSize();
    const imageHeight = isMobileDevice ? 300 : 400;

    const [imgSrc, setImgSrc] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState(); //
    const [completedCrop, setCompletedCrop] = useState('');
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState(1); //16 / 9, 1


    const applyImage = () => {
        if (!previewCanvasRef.current) {
            throw new Error('Crop canvas does not exist')
        }

        dispatch(showLoader(1));
        previewCanvasRef.current.toBlob((blob) => {
            if (!blob) {
                throw new Error('Failed to create blob')
            }

            //console.log(blob);
            //console.log(URL.createObjectURL(blob));
            //props.handleClosePopUp(blob, URL.createObjectURL(blob));

            dispatch(showLoader(0));
            compressBlob(blob);

            /*if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current)
            }
            blobUrlRef.current = URL.createObjectURL(blob);*/


            //hiddenAnchorRef.current!.href = blobUrlRef.current
            //hiddenAnchorRef.current!.click()
        });

    }

    const compressBlob = (file) => {
        dispatch(showLoader(1));
        const blobURL = URL.createObjectURL(file);
        const img = new Image();
        img.src = blobURL;

        img.onerror = function () {
            URL.revokeObjectURL(this.src);
            // Handle the failure properly
            console.log("Cannot load image");
            dispatch(showLoader(0));
        };

        img.onload = function () {
            document.getElementById("display-compress").innerHTML = '';
            URL.revokeObjectURL(this.src);
            const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
            const canvas = document.createElement("canvas");
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            canvas.toBlob(
                (blob) => {
                    // Handle the compressed image. es. upload or save in local state
                    displayInfo('Original file', file);
                    displayInfo('Compressed file', blob);

                    console.log(file, blob);

                    props.handleClosePopUp(blob, URL.createObjectURL(blob));
                    dispatch(showLoader(0));
                },
                MIME_TYPE,
                QUALITY
            );
            document.getElementById("display-compress").append(canvas);
        };
    }

    const calculateSize = (img, maxWidth, maxHeight) => {
        let width = img.width;
        let height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
        }
        return [width, height];
    }

    // Utility functions for demo purpose

    const displayInfo = (label, file) => {
        const p = document.createElement('p');
        p.innerText = `${label} - ${readableBytes(file.size)}`;
        document.getElementById('display-compress').append(p);
    }

    const readableBytes = (bytes) => {
        const i = Math.floor(Math.log(bytes) / Math.log(1024)),
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
    }

    const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    const onImageLoad = (e) => {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    useEffect(() => {
        setImgSrc(imageSelected);
        setCompletedCrop('');
        setCrop('');
        setAspect(1);

        setTimeout(() => {
            setAspect(undefined)
        }, 500);

        /* if (imgRef.current) {
            const { width, height } = imgRef.current
            setAspect(1)
            setCrop(centerAspectCrop(width, height, 1))
        } */
    }, [props]);


    useDebounceEffect(async () => {
        if (
            completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current
        ) {
            // We use canvasPreview as it's much faster than imgPreview.
            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                scale,
                rotate,
            )
        }
    }, 100, [completedCrop, scale, rotate]);

    return (

        <Modal id="popup-image-cropper" show={props.showPopup} onHide={() => applyImage()} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme" contentClassName='overflow-hidden'  >
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4">Image Cropper</Modal.Header>
            <Modal.Body className='px-0'>


                <div style={{ height: imageHeight, maxHeight: imageHeight }} className='position-relative text-center bg-gray' >
                    {!!imgSrc && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                height={imageHeight}
                                src={imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}
                </div>

                {
                    !!completedCrop &&
                    <div className='d-none'>
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width / 2,
                                height: completedCrop.height / 2,
                            }}
                        />
                    </div>
                }

                <div id="display-compress" className='d-nones'></div>
                <div className="text-center py-3 bg-white position-relative">
                    <button type="button" className=" btn-theme-black  col-10 col-md-5 mx-auto" onClick={() => applyImage()} >apply</button>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default ImageCropperPopup;
