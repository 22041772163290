import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';

import PagesAuthentication from '../../helpers/PagesAuthentication';
import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, reloadDepartments, switchProfile as switchAccount, showDepartmentSettings as showDepartmentSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';
import CommonProfileLogo from '../../components/common/CommonProfileLogo';


const LinkedDepartments = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const unsuccessfullAccessMessage = Utilities.labelUsernamePasswordNotMatched;

    const [loginDepartment, setLoginDepartment] = useState({});
    const [activeDepartment, setActiveDepartment] = useState({});
    const [listDepartments, setListDepartments] = useState([]);
    const [companyDetail, setCompanyDetail] = useState({});
    const [departmentSelected, setDepartmentSelected] = useState({});
    const [showEmpty, setShowEmpty] = useState(false);
    const [contentLoaderClass, setContentLoaderClass] = useState('animate');

    const [ipAddress, setIpAddress] = useState('');
    const [departmentActive, setDepartmentActive] = useState([]);

    const [loginResponseMessage, setLoginResponseMessage] = useState('');

    const [showLinkPopup, setShowLinkPopup] = useState(false);
    const handleCloseLinkPopup = () => setShowLinkPopup(false);


    let defaultFormValues = {
        department_id: 0,
        department_email: '',
        department_password: ''
    }

    const [formValues, setFormValues] = useState(defaultFormValues);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'department_email') {
            formValuesTemp = { ...formValuesTemp, ...{ department_email: value } };
        } else if (fieldName === 'department_password') {
            formValuesTemp = { ...formValuesTemp, ...{ department_password: value } };
        }

        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);
    }


    const triggerSetting = () => {
        dispatch(showDepartmentSettingsReducer(new Date()));
    }


    const getDepartmentIdsAccessibleByIP = async (dataRequest = {}) => {

        let departmetAccess = {};

        const requestURL = ApiUrls.departmentAccessAccessible;
        const responseFetch = await axios.post(requestURL, dataRequest);
        //if ( responseFetch.statusText.toLowerCase()==='ok' ){

        if (parseInt(responseFetch.data.status) === 1) {
            departmetAccess = responseFetch.data.detail;

            if (Object.keys(departmetAccess).length > 0) {
                setDepartmentActive(departmetAccess.department_ids_linked);
            } else {
                setDepartmentActive([]);
            }
        } else {
            setDepartmentActive([]);
        }
        //}

        return departmetAccess;
    }


    const getDepartments = async (deptDetail = {}) => {

        if (Object.keys(deptDetail).length > 0) {

            setContentLoaderClass('animate');
            setListDepartments({});

            await getDepartmentIdsAccessibleByIP(deptDetail);

            await axios
                .post(ApiUrls.departmentListByAccountID, { id: deptDetail.account_id })
                .then(response => {

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus === 1) {
                            if (Utilities.isEmpty(dataResponse.departments)) {
                                setShowEmpty(true);
                            } else {
                                setShowEmpty(false);
                                setListDepartments(dataResponse.departments);
                            }
                        }
                    }

                    setContentLoaderClass('');
                })
                .catch((err) => {
                    setShowEmpty(true);
                    setContentLoaderClass('');
                });
        }

    }

    const getIpDetails = async () => {
        const res = await axios.get(ApiUrls.externalIPgeolocationDB);
        const ipAddress = res.data.IPv4;
        return ipAddress;
    }


    const getDepartmentAccess = async (dataRequest = {}) => {

        let allowAccess = 0;

        const requestURL = ApiUrls.departmentGetAccess;
        const responseFetch = await axios.post(requestURL, dataRequest);
        //console.log(requestURL, dataRequest, responseFetch);

        //if ( responseFetch.statusText.toLowerCase()==='ok' ){

        if (parseInt(responseFetch.data.status ?? 0) === 1) {
            if (parseInt(departmentSelected.group_id ?? -1) === parseInt(responseFetch.data.detail.group_id || 0)) {
                allowAccess = 1;
            }
        }
        //}

        return allowAccess;
    }



    const linkUnlinkDepartmentProper = async (action = 'link', deptIDs = '') => {

        action = action.toLowerCase();
        deptIDs = deptIDs.toString();

        if (Utilities.isEmpty(deptIDs)) {
            deptIDs = departmentSelected.group_id.toString();
        }

        const dataRequest = {
            account_id: companyDetail.account_id.toString(),
            department_id: loginDepartment.group_id.toString(),
            ip_address: ipAddress.toString(),
            department_ids_request: deptIDs.toString()
        };

        const dataRequestReducer = {
            timestamp: Date.now(),
            account_id: companyDetail.account_id.toString(),
            department_id: loginDepartment.group_id.toString(),
            ip_address: ipAddress.toString(),
        };

        if (action === 'link') {

            let errorCounter = 0;
            const fieldsChecker = ['department_password'];

            Object.entries(formValues).map(([key, value]) => {
                if (fieldsChecker.includes(key)) {
                    if (Utilities.isEmpty(value)) {
                        //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                        Utilities.addFormGroupClass(key);
                        errorCounter++;
                    }
                }
            });

            if (errorCounter > 0) { return false; }

            const button_name = 'btn-link-department';
            Utilities.elementDisabledStatus(button_name, true);

            const credentail = { email: formValues.department_email, password: formValues.department_password };

            let allowAccess = await getDepartmentAccess(credentail);
            allowAccess = parseInt(allowAccess);

            if (allowAccess === 0) {
                setLoginResponseMessage(unsuccessfullAccessMessage);
                setTimeout(() => { setLoginResponseMessage(''); }, 1500);

                Utilities.elementDisabledStatus(button_name, false);

            } else {

                Utilities.elementDisabledStatus(button_name, true);


                await axios
                    .post(ApiUrls.departmentAccessLink, dataRequest)
                    .then(response => {

                        //console.log(response);
                        if (response.status === 200) {
                            const dataResponse = response.data;
                            const dataStatus = parseInt(dataResponse.status);

                            if (dataStatus === 1) {
                                getDepartmentIdsAccessibleByIP(dataRequestReducer);
                                dispatch(reloadDepartments(dataRequestReducer));
                            }
                        }

                        handleCloseLinkPopup();
                        Utilities.elementDisabledStatus(button_name, false);

                    })
                    .catch((err) => {
                        Utilities.elementDisabledStatus(button_name, false);

                        setLoginResponseMessage(unsuccessfullAccessMessage);
                        setTimeout(() => { setLoginResponseMessage(''); }, 1500);
                    });

            }


        } else if (action === 'unlink') {

            await axios
                .post(ApiUrls.departmentAccessUnlink, dataRequest)
                .then(response => {

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus === 1) {
                            dispatch(reloadDepartments(dataRequestReducer));
                            getDepartmentIdsAccessibleByIP(dataRequestReducer);

                            if (parseInt(deptIDs) === parseInt(activeDepartment.group_id || 0)) {

                                // RETURN BACK MASTER DEPARTMENT
                                localStorage.setItem(
                                    process.env.REACT_APP_session_department_active,
                                    JSON.stringify(loginDepartment)
                                );

                                dispatch(switchAccount(Date.now()));
                                history.push(`/${UrlSlugs.departmentDashboard}`);

                            }
                        }
                    }

                })
                .catch((err) => {

                });
        }
    }

    const linkUnlinkDepartment = (deptDetail = {}) => {

        const deptID = parseInt(deptDetail.group_id);
        setDepartmentSelected(deptDetail);

        // LINK
        if (!departmentActive.includes(deptID)) {

            let defaultFormValues = {
                department_id: deptID,
                department_email: deptDetail.department_email,
                department_password: ''
            }

            setFormValues(defaultFormValues);
            setShowLinkPopup(true);

            // UNLINK
        } else {

            confirmAlert({
                title: '',
                message: TitlesLabels.confirmMessages.wishToUnlinkDepartment,
                overlayClassName: 'normal-font-style',
                buttons: [
                    { label: 'No', onClick: () => { } },
                    {
                        label: 'Yes',
                        onClick: () => {
                            linkUnlinkDepartmentProper('unlink', deptID);

                        }
                    }
                ],
            });
        }

    }

    useEffect(async () => {

        dispatch(pageTitle(TitlesLabels.siteTitles.linkedDepartments));
        dispatch(showHeader(5));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        let ipAddress = await getIpDetails();
        setIpAddress(ipAddress);

        let companyDetail = {};
        let sessionCompanyDetail = localStorage.getItem(process.env.REACT_APP_session_company_detail);
        if (sessionCompanyDetail !== null) {
            companyDetail = JSON.parse(sessionCompanyDetail);
            setCompanyDetail(companyDetail);
        }

        let loginDetail = {};
        let sessionLoginDetail = localStorage.getItem(process.env.REACT_APP_session_login);
        if (sessionLoginDetail !== null) {
            loginDetail = JSON.parse(sessionLoginDetail);
            setLoginDepartment(loginDetail);
        }

        let activeDetail = {};
        let sessionActiveDetail = localStorage.getItem(process.env.REACT_APP_session_department_active);
        if (sessionActiveDetail !== null) {
            activeDetail = JSON.parse(sessionActiveDetail);
            setActiveDepartment(activeDetail);
        }

        if (
            Object.keys(companyDetail).length > 0 &&
            Object.keys(loginDetail).length > 0 &&
            !Utilities.isEmpty(ipAddress)
        ) {

            const dataRequest = {
                account_id: companyDetail.account_id.toString(),
                department_id: loginDetail.group_id.toString(),
                ip_address: ipAddress.toString()
            };

            getDepartments(dataRequest);
        }


    }, []);


    return (
        <div className="departments-page">


            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div>{TitlesLabels.settings}</div>
                            <div className="current">{TitlesLabels.navigations.departments}</div>
                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>

            </div>


            <div className="main-content">
                <div className="container pt-4">


                    {
                        !Utilities.isEmpty(contentLoaderClass) &&
                        <ul className="listing-profiles-preloader mt-4">
                            {
                                [...Array(12).keys()].map((key) =>
                                    <li key={key}>
                                        <div className="profile-image"></div>
                                        <div></div>
                                        <div></div>
                                    </li>
                                )
                            }
                        </ul>
                    }



                    {
                        Object.entries(listDepartments).length > 0 &&
                        <ul id="listing-departments" className={`global-listing-profiles max-height-fit  my-4 ${Object.keys(listDepartments).length === 0 ? 'd-none' : ''} `}>
                            {
                                Object.entries(listDepartments).map(([key, row]) =>
                                    parseInt(loginDepartment.group_id) !== parseInt(row.group_id) &&
                                    <li key={key} data-keyword={row.department_name} className={`cursor-pointer ${departmentActive.includes(parseInt(row.group_id)) ? '' : 'opacity-025'}`} onClick={() => linkUnlinkDepartment(row)}>
                                        <Link to="#" className="profile-wrap">
                                            <div className="profile-image-wrap">
                                                <CommonProfileLogo classAttr={`profile-image logo-size-100 logo-size-md-85 mx-auto`} profileDetail={companyDetail} />
                                                {/* <div className={`profile-image ${ Utilities.classHideInitialBg(companyDetail.logo_url) } `} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(companyDetail.logo_url) })` }}>{ Utilities.showHideInitial(companyDetail.logo_url, companyDetail.company_name) }</div> */}
                                            </div>
                                            <div className="profile-name notranslate font-gotham-bold mt-2 fs-18 color-theme-dark">{row.department_name}</div>
                                        </Link>
                                    </li>

                                )
                            }
                        </ul>
                    }

                    {showEmpty && <div className="text-center text-lowercase no-record-label">{Utilities.labelDepartmentAvailable}</div>}


                </div>
            </div>



            <Modal show={showLinkPopup} onHide={handleCloseLinkPopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-medium">
                <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline  pt-4 text-lowercase border-bottom-0">link department</Modal.Header>
                <Modal.Body className='pt-4'>

                    <form className="form-theme text-lowercase col-10 mx-auto px-4" autoComplete="off">

                        <div className="form-group ">
                            <input type="text" name="department_email" className="form-control" placeholder=" " value={formValues.department_email} onChange={(e) => pushValue(e, 'department_email')} autoComplete="off" disabled ></input>
                            <label className="form-label">email</label>
                        </div>

                        <div className="form-group ">
                            <input type="password" name="department_password" className="form-control" placeholder=" " value={formValues.department_password} onChange={(e) => pushValue(e, 'department_password')} autoComplete="new-password"></input>
                            <label className="form-label">confirm password</label>
                        </div>

                        <div className="text-center pt-3 color-theme-red">{loginResponseMessage}</div>

                        <div className="mt-4  mb-4">
                            <button id="btn-link-department" type="button" className="btn-theme-black text-lowercase button-has-loader" onClick={() => linkUnlinkDepartmentProper('link')}>link</button>
                        </div>


                    </form>


                </Modal.Body>
            </Modal>


        </div>
    )
}

export default LinkedDepartments;