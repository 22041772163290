import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { reloadAccountsDB } from '../../actions';

import UrlSlugs from '../../helpers/UrlSlugs';

const NotificationDivert = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    /* const dummySender = {
        data : { 
            sender_logo : `https://s3.ap-south-1.amazonaws.com/image.directory.beonchat/beonchat_dev/team_member/372/logo/logo_1660645626.png`,
            sender_name : 'charlie puth',
            sender_account_title : 'web developer',
            notify_flag : 13
        }
    } */

    const [showPopup, setShowPopup] = useState(false);
    const [notiItems, setNotiItems] = useState({});

    const handleClosePopUp = () => {
        setShowPopup(false);
        setNotiItems([]);

        localStorage.removeItem(process.env.REACT_APP_session_divert_notification);

        dispatch(reloadAccountsDB(2));
        history.push(`/${UrlSlugs.dashboard}`);
    }

    useEffect(() => {

        if (Object.entries(props.notificationItem ?? {}).length > 0) {
            setNotiItems(props.notificationItem);
            setShowPopup(true);
        }

        return () => {
            setShowPopup(false);
            setNotiItems([]);
        }

    }, [props.notificationItem]);


    return (

        Object.keys(notiItems).length > 0 &&
        <Modal id="popup-notification-divert" show={showPopup} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" >
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4">divert alert</Modal.Header>
            <Modal.Body className="px-5">

                <div className="font-gotham-light-14 text-lowercase text-center pb-4">

                    <div className="logo-size-100 logo-size-md-100 mx-auto" style={{ backgroundImage: `url(${notiItems.data.sender_logo})` }}></div>
                    <div className="mt-2">
                        <div className="font-gotham-bold fs-18 fs-md-18 notransla te ellipsis-1">{notiItems.data.sender_name}</div>
                        <div className="font-gotham-book fs-15 fs-md-15 ellipsis-1 color-theme-grey">{notiItems.data.sender_account_title}</div>
                    </div>

                    <div className="mt-4 mb-2 font-gotham-book fs-18 fs-md-18">
                        <div>{`has ${parseInt(notiItems.data.notify_flag || 0) === 13 ? 'diverted' : 'removed'} his/her work page`}</div>
                        <div>conversation to your profile</div>
                    </div>

                </div>

            </Modal.Body>
        </Modal>
    );
}

export default NotificationDivert;
