import React from 'react';
//import { useEffect } from 'react';

const LinkCopiedTooltip = (props) => {

    const showStatus = props.boolShow ?? false;
    const styleAttr = props.styleAttr ?? {};
    const textStr = props.text ?? `link copied!`;

    //const [ showStatus, setShowStatus ] = useState(props.boolShow??false);

    /*const showHideToolTip = () => {
        console.log(showStatus);
        if( showStatus ){
            setTimeout( () => {
                setShowStatus(!showStatus);
            }, 1000);
           
        }
    }

    useEffect( () => {
        showHideToolTip();
    }, [props.boolShow]);*/

    return (
        showStatus ? <div className={`link-copied font-gotham-book ${props.extraClass ?? ''}`} style={styleAttr}>{textStr}</div> : <></>
    )
}

export default LinkCopiedTooltip;