import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import TitlesLabels from '../../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';
import UrlSlugs from '../../helpers/UrlSlugs';
import CommonProfileLogo from '../../components/common/CommonProfileLogo';

const SpecialPermissions = ({ match }) => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const tabSelection = { 1: 'menu access', 2: 'notifications access', 3: 'management access' };

    const [tabSelected, setTabSelected] = useState(1);
    const [listProfileMenu, setListProfileMenu] = useState([]);
    const [listProfileNotification, setListProfileNotification] = useState([]);
    const [listProfileAccess, setListProfileAccess] = useState([]);

    const [adminProfile, setAdminProfile] = useState({});

    const [isProcessing, setIsProcessing] = useState(false);
    const [memberIDsMenu, setMemberIDsMenu] = useState([]);
    const [memberIDsNotification, setMemberIDsNotification] = useState([]);
    const [memberIDsAccess, setMemberIDsAccess] = useState([]);

    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }


    const toggleActiveClassMenu = (e, member) => {
        let memberIDsTemp = memberIDsMenu;
        let id = member.account_id.toString();

        !(memberIDsTemp.includes(id)) ? memberIDsTemp.push(id) : (memberIDsTemp = [...Utilities.removeValueInArray(memberIDsTemp, id)]);
        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);

        if (memberIDsTemp.length === 0) {
            memberIDsTemp.push(adminProfile.id.toString());
        }

        setMemberIDsMenu(memberIDsTemp);
    }

    const toggleActiveClassNotification = (e, member) => {
        let memberIDsTemp = memberIDsNotification;
        let id = member.account_id.toString();

        !(memberIDsTemp.includes(id)) ? memberIDsTemp.push(id) : (memberIDsTemp = [...Utilities.removeValueInArray(memberIDsTemp, id)]);
        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);

        if (memberIDsTemp.length === 0) {
            memberIDsTemp.push(adminProfile.id.toString());
        }

        setMemberIDsNotification(memberIDsTemp);
    }

    const toggleActiveClassAccess = (e, member) => {
        let memberIDsTemp = memberIDsAccess;
        let id = member.account_id.toString();

        !(memberIDsTemp.includes(id)) ? memberIDsTemp.push(id) : (memberIDsTemp = [...Utilities.removeValueInArray(memberIDsTemp, id)]);
        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);

        setMemberIDsAccess(memberIDsTemp);
    }


    const getMembers = async (companyID = 0) => {

        if (parseInt(companyID) > 0) {
            setIsProcessing(true);
            await axios
                .post(ApiUrls.companyTeamList, { account_id: companyID })
                .then(response => {
                    setIsProcessing(false);
                    if (response.status === 200) {
                        const dataResponse = response.data;

                        if (parseInt(dataResponse.status) === 1) {

                            let profileListMenu = [], profileListAccess = [], profileMenuActiveArray = [], profileNotificationActiveArray = [], profileAccessActiveArray = [];
                            let menuActiveCounter = 0, notifcationActiveCounter = 0, profileAdmin = {};


                            Object.entries(dataResponse.list).map(([id, memberdetail]) => {


                                if (parseInt(memberdetail.team_is_admin) === 1) {
                                    profileAdmin = Utilities.formatProfileDetail(memberdetail);
                                    setAdminProfile(profileAdmin);
                                } else {
                                    profileListMenu = [...profileListMenu, Utilities.formatProfileDetail(memberdetail)];
                                }


                                if (memberdetail.hasOwnProperty('team_menu_permission')) {
                                    if (parseInt(memberdetail.team_menu_permission) === 1) {
                                        profileMenuActiveArray.push(memberdetail.account_id.toString());
                                        menuActiveCounter++;
                                    }

                                }
                                if (memberdetail.hasOwnProperty('team_notification_permission')) {
                                    if (parseInt(memberdetail.team_notification_permission) === 1) {
                                        profileNotificationActiveArray.push(memberdetail.account_id.toString());
                                        notifcationActiveCounter++;
                                    }
                                }
                                if (memberdetail.hasOwnProperty('team_is_admin')) {

                                    if (parseInt(memberdetail.team_is_admin) === 2) {
                                        profileAccessActiveArray.push(memberdetail.account_id.toString());
                                    }

                                    if (parseInt(memberdetail.team_is_admin) !== 1) {
                                        profileListAccess = [...profileListAccess, Utilities.formatProfileDetail(memberdetail)];
                                    }
                                }

                            });

                            profileListMenu.sort(Utilities.fieldSorter(['name']));
                            profileListMenu.sort(Utilities.fieldSorter(['name']));
                            profileListAccess.sort(Utilities.fieldSorter(['name']));

                            if (Object.keys(profileAdmin).length > 0) {
                                if (menuActiveCounter === 0) {
                                    profileMenuActiveArray.push(profileAdmin.account_id.toString());
                                }
                                if (notifcationActiveCounter === 0) {
                                    profileNotificationActiveArray.push(profileAdmin.account_id.toString());
                                }
                            }

                            setMemberIDsMenu(profileMenuActiveArray);
                            setMemberIDsNotification(profileNotificationActiveArray);
                            setMemberIDsAccess(profileAccessActiveArray);

                            setListProfileMenu(profileListMenu);
                            setListProfileNotification(profileListMenu);
                            setListProfileAccess(profileListAccess);
                        }

                    }
                })
                .catch((err) => {
                    setIsProcessing(false);
                    setListProfileMenu([]);
                    setListProfileNotification([]);
                });
        }
    }


    const submitAccess = async (typeName = '') => {
        if (Utilities.isEmpty(typeName)) { return false; }

        const button_name = `btn-submit-${typeName}-access`;

        let selectedProfileIds = '', selectedProfileIdsFiltered = [];
        if (typeName === 'menu') {
            let listProfileMenuMerged = [...listProfileMenu];
            listProfileMenuMerged.push(adminProfile);

            listProfileMenuMerged.map(profile => {
                selectedProfileIdsFiltered.push(memberIDsMenu.includes(profile.account_id.toString()) ? profile.account_id + '|1' : profile.account_id + '|0');
            });

        } else if (typeName === 'notification') {
            let listProfileNotificationMerged = [...listProfileNotification];
            listProfileNotificationMerged.push(adminProfile);

            listProfileNotificationMerged.map(profile => {
                selectedProfileIdsFiltered.push(memberIDsNotification.includes(profile.account_id.toString()) ? profile.account_id + '|1' : profile.account_id + '|0');
            });

        } else if (typeName === 'management') {
            listProfileAccess.map(profile => {
                selectedProfileIdsFiltered.push(memberIDsAccess.includes(profile.account_id.toString()) ? profile.account_id + '|2' : profile.account_id + '|0');
            });
        }

        selectedProfileIds = selectedProfileIdsFiltered.join(',');

        if (!Utilities.isEmpty(selectedProfileIds)) {

            Utilities.elementDisabledStatus(button_name, true);
            await axios
                .post(ApiUrls.teamMenuNotificationAccessUpdate, { type_name: typeName, account_ids: selectedProfileIds })
                .then(response => {
                    Utilities.elementDisabledStatus(button_name, false);

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus === 1) {
                            //Utilities.messagePopup('success', TitlesLabels.alertMessages.specialPermissionsUpdated );
                            Utilities.messagePopup('success', typeName + ' access ' + TitlesLabels.alertMessages.successfullyUpdated);

                        } else {
                            Utilities.messagePopup('error', errorMsg);
                        }

                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }

                })
                .catch((err) => {
                    Utilities.messagePopup('error', errorMsg);
                    Utilities.elementDisabledStatus(button_name, false);
                });

        }

    }

    const adminListHtml = () => {
        return <>
            <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={adminProfile} />
            <div className='line-height-1'>
                <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1">{adminProfile.name}</div>
                <div className="profile-position font-gotham-book fs-16 fs-md-16 color-theme-grey">{adminProfile.team_position}</div>
            </div>
        </>
    }

    useEffect(async () => {

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.specialPermissions}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        let reqAccountID = match.params.mid ?? 0;
        if (!Utilities.isEmpty(reqAccountID)) {
            reqAccountID = atob(reqAccountID);

            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
            if (accountsList !== null) {
                accountsList = JSON.parse(accountsList);
                accountsList = Object.values(accountsList);

                const profileDetail = accountsList.filter(profile => parseInt(profile.account_id) === parseInt(reqAccountID))[0] ?? {};
                if (Object.keys(profileDetail).length > 0) {

                    const profileCompany = Utilities.workToCompany(profileDetail);
                    await getMembers(profileCompany.account_id);
                }
            }
        }


    }, []);

    return (
        <div className="special-message-info-page chat-template-messages-content">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => triggerSetting()}>{TitlesLabels.settings}</div>
                            <div>{TitlesLabels.navigations.profile}</div>
                            <div className="current">{TitlesLabels.navigations.specialPermissions}</div>

                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()}></div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="main-content">
                <div className="container">


                    <div className="col-md-11 mx-auto  text-lowercase team-members-page pb-5">

                        <div className="form-col-3 font-gotham-book fs-18 mb-4 mt-3">
                            {
                                Object.entries(tabSelection).map(([key, tabName]) =>
                                    <div key={key} className={`cursor-pointer py-3 text-center ${parseInt(key) === tabSelected ? 'font-gotham-bold border-bottom border-theme-colored border-theme-width-2' : ''}`} onClick={() => setTabSelected(parseInt(key))}>{tabName}</div>
                                )
                            }
                        </div>


                        {
                            isProcessing === true &&
                            <ul className="ul-global-list">
                                {
                                    [...Array(6 + (Math.floor(Math.random() * 3))).keys()].map((key) =>
                                        <li key={key} className="global-preloader  py-2">
                                            <div className="logo-size-60 bg-gray logo-size-md-60 border-0"></div>
                                            <div>
                                                <div className="bg-gray col-4 pt-3 border-radius-loader"></div>
                                                <div className="mt-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                            </div>
                                            <div className='d-flex justify-content-end'>
                                                <div className="bg-gray col-8 pt-3 border-radius-loader"></div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        }

                        {
                            tabSelected === 1 &&
                            <>
                                <ul className="listing-profiles listing-members ul-global-list list-has-check">
                                    {
                                        Object.keys(adminProfile).length > 0 &&
                                        <li className={`${((memberIDsMenu.includes(adminProfile.id.toString())) ? 'selected' : '')}`} onClick={(e) => toggleActiveClassMenu(e, adminProfile)}>
                                            {adminListHtml()}
                                        </li>
                                    }
                                    {
                                        Object.entries(listProfileMenu).map(([key, row]) =>
                                            <li key={key} className={`${((memberIDsMenu.includes(row.id.toString())) ? 'selected' : '')}`} onClick={(e) => toggleActiveClassMenu(e, row)}>
                                                <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={row} />
                                                <div className='line-height-1'>
                                                    <div className="profile-name  font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1">{row.name}</div>
                                                    <div className="profile-position font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.team_position}</div>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>

                                {
                                    Object.keys(listProfileMenu).length > 0 &&
                                    <div className="button-group-boolean col-md-5 mx-auto pt-5 mt-5">
                                        <Link to={`/${UrlSlugs.dashboard}`} className="btn-theme-black">cancel</Link>
                                        <button id="btn-submit-menu-access" type="button" className="btn-theme-black button-has-loader" onClick={() => submitAccess('menu')}>save</button>
                                    </div>
                                }

                            </>
                        }

                        {
                            tabSelected === 2 &&
                            <>
                                <ul className="listing-profiles listing-members  ul-global-list list-has-check">
                                    {
                                        Object.keys(adminProfile).length > 0 &&
                                        <li className={`${((memberIDsNotification.includes(adminProfile.id.toString())) ? 'selected' : '')}`} onClick={(e) => toggleActiveClassNotification(e, adminProfile)}>
                                            {adminListHtml()}
                                        </li>
                                    }
                                    {
                                        Object.entries(listProfileNotification).map(([key, row]) =>
                                            <li key={key} className={`${((memberIDsNotification.includes(row.id.toString())) ? 'selected' : '')}`} onClick={(e) => toggleActiveClassNotification(e, row)}>
                                                <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={row} />
                                                <div className='line-height-1'>
                                                    <div className="profile-name font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1">{row.name}</div>
                                                    <div className="profile-position font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.team_position}</div>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>

                                {
                                    Object.keys(listProfileNotification).length > 0 &&
                                    <div className="button-group-boolean col-md-5 mx-auto pt-5 mt-5">
                                        <Link to={`/${UrlSlugs.dashboard}`} className="btn-theme-black">cancel</Link>
                                        <button id="btn-submit-notification-access" type="button" className="btn-theme-black button-has-loader" onClick={() => submitAccess('notification')}>save</button>
                                    </div>
                                }

                            </>
                        }

                        {
                            tabSelected === 3 &&
                            <>
                                <ul className="listing-profiles listing-members  ul-global-list list-has-check">
                                    {
                                        Object.keys(adminProfile).length > 0 &&
                                        <li className="opacity-05 cursor-notallow">
                                            {adminListHtml()}
                                        </li>
                                    }
                                    {
                                        Object.entries(listProfileAccess).map(([key, row]) =>
                                            parseInt(row.team_is_admin) !== 1 &&
                                            <li key={key} className={`${((memberIDsAccess.includes(row.id.toString())) ? 'selected' : '')}`} onClick={(e) => toggleActiveClassAccess(e, row)}>
                                                <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={row} />
                                                <div className='line-height-1'>
                                                    <div className="profile-name font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1">{row.name}</div>
                                                    <div className="profile-position font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.team_position}</div>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>

                                {
                                    Object.keys(listProfileAccess).length > 0 &&
                                    <div className="button-group-boolean col-md-5 mx-auto pt-5 mt-5">
                                        <Link to={`/${UrlSlugs.dashboard}`} className="btn-theme-black">cancel</Link>
                                        <button id="btn-submit-management-access" type="button" className="btn-theme-black button-has-loader" onClick={() => submitAccess('management')}>save</button>
                                    </div>
                                }

                            </>
                        }

                    </div>

                </div>
            </div>
        </div>
    )
}

export default SpecialPermissions;