import SessionUtilities from "./SessionUtilities";
//import { isMobile } from 'react-device-detect';
import UrlSlugs from "./UrlSlugs";
import Utilities from "./Utilities";

const isMobile = Utilities.isMobileByScreenSize();

export const directToPersonalInfo = [44];

export const redirectToDownloadAppFromCampaign = (historyReact) => {


    // CHECK IF THERE IS CAMPAIN -> redirect to signup company information
    const signupCampaignDetail = SessionUtilities.getSignupCampaign();

    if (Object.keys(signupCampaignDetail).length > 0) {

        // SHOW DONWLOAD POPUP
        SessionUtilities.setSignupCampaignDownload(1);

        const campaign_pagetype = parseInt(signupCampaignDetail.type ?? 0);

        if (campaign_pagetype == 2) {

            if (isMobile) {
                historyReact.push(`/${UrlSlugs.campaign_welcomeMessage}`);

            } else {
                historyReact.push(`/${UrlSlugs.dashboard}`);
            }

        } else {

            // REMOVE COMPAIGN 
            SessionUtilities.deleteSignupCampaign();

            if (isMobile) {
                historyReact.push(`/${UrlSlugs.campaign_downloadApp}`);
            } else {

                historyReact.push(`/${UrlSlugs.dashboard}`);
            }

        }

    }

}


export const redirectToDownloadAppFromCampaignCompanySignup = (historyReact) => {

    if (isMobile) {
        historyReact.push(`/${UrlSlugs.campaign_referral}`);
    } else {

        // SHOW DONWLOAD POPUP
        SessionUtilities.setSignupCampaignDownload(2);

        historyReact.push(`/${UrlSlugs.dashboard}`);
    }

}
