import axios from 'axios';
import ApiUrls from './ApiUrls';


export const getProfileList = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            //console.log('getProfileList');
            //console.log(ApiUrls.bizchatConversationListShare);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.bizchatConversationListShare, passval);
            if (parseInt(responseURL.status) === 201) {
                responseDetail = responseURL.data.result.conversation_share_list ?? {};
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}

export const submitSendMessage = async (passval = {}) => {
    let responseDetail = {}

    if (Object.keys(passval).length > 0) {
        try {
            console.log('submitSendMessage');
            console.log(ApiUrls.bizchatSendDeptBulkMessage);
            console.log(passval);

            const responseURL = await axios.post(ApiUrls.bizchatSendDeptBulkMessage, passval);
            responseDetail = { ...responseDetail, ...responseURL.data };

        } catch (e) {

        }
    }

    return responseDetail;
}
