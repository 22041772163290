import React, { useEffect, useState } from 'react';
import moment from 'moment';

import Utilities from '../helpers/Utilities';

import ChatConversation from '../components/ChatConversation';

const ChatSearch = (props) => {

    const FS_DBCOLLECTION = props.fsCollection;

    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [conversID, setConversID] = useState(0);
    const [ownerIdConversation, setOwnerIdConversation] = useState(0);
    const [presentConversations, setPresentConversations] = useState([]);
    const [currentConversationDetail, setCurrentConversationDetail] = useState({});
    const conversationLimit = 15;

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
        }
    }

    const getConversations = (keyword = '') => {

        const CONVERS_LIST = currentConversationDetail;
        keyword = keyword.toString();

        if (Object.entries(CONVERS_LIST).length > 0 && Object.entries(FS_DBCOLLECTION).length > 0 && !Utilities.isEmpty(keyword)) {

            //let unsubsribe;
            let conversationTemp = [];

            let CONVERS_ID = CONVERS_LIST.convers_id.toString();
            const dbCollection = FS_DBCOLLECTION.collection(CONVERS_ID);

            if (parseInt(CONVERS_ID) > 0) {

                let pageQuery = '';
                const conv_date_details = CONVERS_LIST.conv_date_details;

                if (parseInt(conv_date_details.clear_flag || 0) === 1) {
                    let conv_end_date = conv_date_details.conv_end_date;

                    conv_end_date = moment.utc(conv_end_date);
                    conv_end_date = moment(conv_end_date).local().valueOf(); // unix() / will remove remove some values

                    pageQuery = dbCollection.where("date", ">", parseInt(conv_end_date)).orderBy("date", "desc").limit(conversationLimit);

                } else {
                    pageQuery = dbCollection.where("message_type", "==", 1)
                        //.where('message', '>=', keyword).where('message', '<=', keyword)
                        .limit(conversationLimit);
                }


                pageQuery.get().then(function (snapshot) {
                    const messages_list_reverse = snapshot.docChanges();//.reverse();

                    messages_list_reverse.forEach(function (query) {
                        let messages_data = query.doc.data();
                        const messageValue = messages_data.message.toString().toLowerCase();
                        if (!Utilities.isEmpty(messageValue)) {

                            if (messageValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                                messages_data = { doc_id: query.doc.id.toString(), ...messages_data };
                                conversationTemp.push(messages_data);
                            }
                        }
                    });

                    setPresentConversations(conversationTemp);
                });


            }


        }
    }

    const showMessagesFromSearch = (docDateId = 0) => {
        props.toggleSearchProfile();
        props.showMessagesFromSearch(docDateId);
    }

    useEffect(() => {
        const conversationDetail = props.conversationDetail;
        setCurrentConversationDetail(conversationDetail);
        setConversID(parseInt(conversationDetail.convers_id || 0));

        const OWNERID = parseInt(conversationDetail.owner_account_id);
        setOwnerIdConversation(OWNERID);

    }, [props]);


    useEffect(() => {

        const delayDebounceFn = setTimeout(() => {
            getConversations(defaultSearchValue);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);

    }, [defaultSearchValue]);



    return (
        <div className={`chat-conversation-search  ${(props.showSearchProfile === 0 ? 'd-none' : '')} `}>

            {/*  <div className="profile-search-wrap">
                <div className="profile-search-content">
                    <div><input type="text" name="input_name" id="input_name" className="input-keyword" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="search" autoFocus ></input></div>
                    <div></div>
                    <div><span className="text-color-link cursor-pointer text-size-small" onClick={ () => resetSearchValue() }>clear</span></div>
                    <div><span className="ion-ios7-close-empty btn-close-search"  onClick={ () => props.toggleSearchProfile() } ></span></div>
                </div>
            </div> */}

            <div className="content-subnav-wrap profile-search-wrap  py-4">
                <div className="container px-4">
                    <div className="profile-search-wrap bg-white d-flex align-items-center form-theme  w-100 border-radius">
                        <div className='form-group mb-0 border-0  w-100 '>
                            <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} autoFocus placeholder="type here" autoComplete='off' />
                        </div>
                        {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => resetSearchValue()}>clear</span></div>}
                    </div>

                </div>
            </div>


            <div className="chat-conversation--history px-5">
                <ul className="chat-conversation--history-messages checkbox-radio-default checkbox-radio-check-icon">
                    <ChatConversation presentConversations={presentConversations} ownerIdConversation={ownerIdConversation} currentConversationDetail={currentConversationDetail} conversID={conversID} handleViewFormDetail="" messagesSelectedDetail="" bolUncheckSelectedMessages="" bolClearSelectedMessages="" isSearch={true} showMessagesFromSearch={showMessagesFromSearch} requestProfile="" />
                </ul>
            </div>

        </div>
    )
}

export default ChatSearch;
