import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import jQuery from 'jquery';
import * as RM from 'ramda';

import QueryUtilities from '../../helpers/QueryUtilities';
import QueryChatUtilities from '../../helpers/QueryChatUtilities';
import SessionUtilities from '../../helpers/SessionUtilities';
import ChatUtilities from '../../helpers/ChatUtilities';
import Utilities from '../../helpers/Utilities';
import UrlSlugs from '../../helpers/UrlSlugs';
import PagesAuthentication from '../../helpers/PagesAuthentication';


import { passConversDetail, passChatProfile, passPushNotificationDetail as passPushNotificationDetailReducer } from '../../actions';


const NotificationItem = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const passConversID = useSelector(state => state.passConversID);

    const [notiItems, setNotiItems] = useState([]);
    const [selectedKey, setSelectedkey] = useState(-1);

    const excludedOptionArray = ['block_contact', 'unblock_contact'];
    const chatGroupOptionArray = ['group_add_remove', 'group_member_add_remove'];
    const chatCommunicationRequestArray = ['request_user_communication', 'request_user_communication_accepted', 'request_user_communication_rejected'];


    const provideAction = async (key = -1, itemDetail = {}, directStatus = 0) => {

        if (Object.entries(itemDetail).length > 0) {

            const notificationDetail = itemDetail.data;

            setSelectedkey(key);

            if (notificationDetail.hasOwnProperty('notify_flag') && parseInt(notificationDetail.notify_flag || 0) === 14) {
                return false;
            }


            if (notificationDetail.hasOwnProperty('receiver_account_id')) {

                const typeName = notificationDetail.type.toString();
                const departmentId = parseInt(notificationDetail.receiver_group_id) || -1;


                // GROUP CHAT
                if (notificationDetail.hasOwnProperty('group_id')) {

                    const segment = Utilities.getUrlSegments();
                    const teamId = atob(segment[1]);

                    let conversationId = parseInt(passConversID) > 0 ? passConversID : atob(segment[2]).split('-')[0];
                    conversationId = parseInt(conversationId);

                    let currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail(conversationId.toString());

                    if (notificationDetail.hasOwnProperty('members_list') && typeName === 'group_member_add_remove') {

                        const memberListArray = notificationDetail.members_list.split(',');

                        //let accessStatus = 1;
                        if (!(memberListArray.includes(teamId.toString()))) {
                            Utilities.messagePopup('warning', Utilities.labelCouldNoSendNotMemberGroup.toString());
                            jQuery('.chat-compose-wrap').addClass('d-none');
                            //accessStatus = 0;
                        } else {
                            jQuery('.chat-compose-wrap').removeClass('d-none');
                        }


                        /* const passValues = {
                            api_token: TOKEN.toString(),
                            account_id: teamId.toString(),
                            convers_id: conversationId.toString(),
                            flag: "2"
                        }; */

                        const passValues = {
                            api_token: TOKEN_SESSION.toString(), // TOKEN.toString(),
                            account_id: teamId.toString(),
                            account_type: "4",
                            convers_id: conversationId.toString(),
                        };

                        const conversDetail = await QueryChatUtilities.getConversationDetail(passValues);
                        if (!RM.isEmpty(conversDetail)) {

                            if (parseInt(conversDetail.success) === 1) {
                                currentConversationDetailTemp = conversDetail.result.conversation_details ?? {};
                                ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 'noti group 1');
                            }
                        }
                    }


                    const profileInfoTemp = {
                        name: currentConversationDetailTemp.name,
                        category: currentConversationDetailTemp.title,
                        logo_url: currentConversationDetailTemp.logo,
                        img: currentConversationDetailTemp.logo,
                        account_id: currentConversationDetailTemp.account_id,
                    }

                    dispatch(passChatProfile(profileInfoTemp));

                    dispatch(passConversDetail(currentConversationDetailTemp));


                    if (typeName === 'bizchat_group_removed' && parseInt(notificationDetail.sender_account_id) !== parseInt(teamId)) {
                        history.push(`/${UrlSlugs.internalChat}/${btoa(parseInt(teamId))}`);
                        Utilities.messagePopup('warning', Utilities.labelGroupChatDeletedByAdmin.toString());

                        return false;
                    }

                    return false;

                }


                // NORMAL CHAT
                const accountDetail = SessionUtilities.getProfileDetail(parseInt(notificationDetail.receiver_account_id), parseInt(departmentId));

                if (Object.entries(accountDetail).length > 0) {

                    localStorage.setItem(
                        process.env.REACT_APP_session_login,
                        JSON.stringify(accountDetail)
                    );

                    const receiverProfileDetail = await QueryUtilities.getProfileDetail(parseInt(notificationDetail.sender_account_id));

                    if (!RM.isEmpty(receiverProfileDetail)) {

                        const contactRequestValues = ChatUtilities.contactRequest(accountDetail, receiverProfileDetail, 0, 0);

                        if (!excludedOptionArray.includes(typeName)) {

                            const conversationDetailRes = await QueryUtilities.getConversationDetail(contactRequestValues.url, contactRequestValues.values);
                            const conversation = conversationDetailRes.result.conversation_details ?? {};

                            if (Object.entries(conversation).length > 0) {
                                removeItem(key);

                                let chatPassValue = ChatUtilities.encryptChatDetail(conversation);

                                ChatUtilities.saveAccountsConversationDetail(conversation, 'noti normal chat 1');
                                history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation.account_id))}/${chatPassValue}`);
                            }

                        } else {

                            if (parseInt(directStatus) === 1) {

                                const conversationDetailRes = await QueryUtilities.getConversationDetail(contactRequestValues.url, contactRequestValues.values);
                                const conversation = conversationDetailRes.result.conversation_details ?? {};

                                if (Object.entries(conversation).length > 0) {
                                    ChatUtilities.saveAccountsConversationDetail(conversation, 'noti normal chat 2');
                                    dispatch(passConversDetail(conversation));
                                }

                            }
                        }
                    }

                }

            }

        }
    }

    const removeItem = (index = -1) => {

        if (index > -1) {
            let notiItemsTemp = [...notiItems];
            delete notiItemsTemp[index];
            notiItemsTemp = notiItemsTemp.filter(function (element) {
                return element !== undefined;
            });
            setNotiItems(notiItemsTemp);
        }
    }

    const addTimeFormatted = (detail = {}) => {

        if (typeof detail.data.timestamp !== 'undefined' && detail.data.hasOwnProperty('timestamp')) {
            let localTime = moment.utc(detail.data.timestamp);
            let dateFormatted = moment(new Date(localTime)).format(Utilities.timeFormatStr.toString()).toLocaleString();

            detail = {
                ...detail,
                data: { ...detail.data, timeFormatted: dateFormatted }
            };
        }

        return detail;
    }

    //  0 = delete, 1= text, 2 = image, 3 = voice, 4= video, 5= docs, 6 = pdf, 7 other application, 8 = links, 9 = location
    const formatMessage = (detail = {}) => {

        let messageHtml = '';
        const fileTypes = [3, 4, 5, 6, 7];

        if (!RM.isEmpty(detail)) {

            const dataDetail = detail.data;
            const messageType = parseInt(dataDetail.msg_type || 0);

            const messageValue = dataDetail.message.toString();
            const labelTitle = dataDetail.title.toString();

            messageHtml = labelTitle;

            if (messageType === 2) {
                messageHtml = <div className="d-flex align-items-center">
                    <img src={messageValue} width="40" />
                    <div className="ellipsis-1 ml-2">{labelTitle}</div>
                </div>

            } else if (fileTypes.includes(messageType)) {
                messageHtml = <div className="noti-with-icon file-icon ellipsis-2">{labelTitle}</div>

            } else if (messageType === 8) {
                messageHtml = <div className="noti-with-icon link-icon ellipsis-2">{labelTitle}</div>

            } else if (messageType === 9) {
                messageHtml = <div className="noti-with-icon location-icon ellipsis-2">{labelTitle}</div>

            } else {
                messageHtml = Utilities.replaceNewlineToSpace(labelTitle);

            }

        }

        return messageHtml;
    }

    useEffect(() => {
        const segment = Utilities.getUrlSegments();

        let urlSlug = segment.shift();
        urlSlug = typeof urlSlug !== 'undefined' ? urlSlug.toString().toLowerCase() : '';

        if (urlSlug === 'chat-conversation') {
            setNotiItems([]);
        }

    }, [window.location.pathname]);


    useEffect(async () => {
        let segment = Utilities.getUrlSegments();
        const encryptedParam = segment.slice(-1).pop();
        let urlSlug = segment.shift();
        //const TOKEN_SESSION = SessionUtilities.getSessionToken();

        urlSlug = typeof urlSlug !== 'undefined' ? urlSlug.toString().toLowerCase() : '';

        setSelectedkey(-1);

        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        let loggedinProfileDetail = {}, currentConversationDetailTemp = {}, passParams = {}, conversationDetailResponse = {}, conversa_id = '0';
        let notificationDetail = {}, typeName = '', profileChatConversation = {};

        if (loginProfileInfo !== null) {
            loginProfileInfo = JSON.parse(loginProfileInfo);

            loggedinProfileDetail = Utilities.formatProfileDetail(loginProfileInfo);
        }

        const pushNotificationItem = props.notificationItem ?? {};
        //console.log(pushNotificationItem);


        const autoreplayOptionArray = ['autoreplay_activated', 'autoreplay_deactivated', 'link_profile_autoreplay_activated', 'link_profile_autoreplay_deactivated'];

        // SHOW NOTIFICATION ITEM

        // AUTO LOGOUT
        if (Object.entries(pushNotificationItem).length > 0) {

            notificationDetail = pushNotificationItem.data;
            typeName = notificationDetail.type.toString();

            if (typeName === 'logout_all_devices') {
                PagesAuthentication.logoutUser();
                history.push('/');
            }
        }


        if (urlSlug !== 'chat-conversation') {
            if (Object.entries(pushNotificationItem).length > 0) {

                notificationDetail = pushNotificationItem.data;
                typeName = notificationDetail.type.toString();


                if (autoreplayOptionArray.includes(typeName)) {
                    return false;
                }


                if (notificationDetail.hasOwnProperty('group_id') && chatGroupOptionArray.includes(typeName)) {

                    segment = Utilities.getUrlSegments();
                    const teamId = atob(segment[1]);

                    if (parseInt(notificationDetail.sender_account_id) === parseInt(teamId)) {
                        return false;
                    }
                }

                if (notificationDetail.hasOwnProperty('group_id') && chatCommunicationRequestArray.includes(typeName)) {
                    dispatch(passPushNotificationDetailReducer(notificationDetail));
                    return false;
                }

                let notiItemsTemp = [...notiItems];

                let notiItemTemp = addTimeFormatted(pushNotificationItem);
                notiItemsTemp.push(notiItemTemp);

                setNotiItems(notiItemsTemp);

            }

        } else {

            //console.log(passConversID, pushNotificationItem);

            if (parseInt(passConversID) > 0 && pushNotificationItem.data.hasOwnProperty('group_id')) {

                notificationDetail = pushNotificationItem.data;
                typeName = notificationDetail.type.toString();

                if (parseInt(passConversID) === parseInt(notificationDetail.group_id || 0)) {

                    if (autoreplayOptionArray.includes(typeName)) {

                        const replyStatus = ['autoreplay_activated', 'link_profile_autoreplay_activated'].includes(typeName) ? 1 : 0;

                        //currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail( passConversID ) ?? {};
                        conversationDetailResponse = await ChatUtilities.getBeonchatConversationDetail(passConversID);
                        currentConversationDetailTemp = conversationDetailResponse.result.beonchat_conversation ?? {};

                        if (Object.entries(currentConversationDetailTemp).length > 0) {

                            let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                            if (divertReplyInfo !== null) {
                                divertReplyInfo = JSON.parse(divertReplyInfo);

                                divertReplyInfo = { ...divertReplyInfo, auto_replay_status: replyStatus.toString() };
                                localStorage.setItem(
                                    process.env.REACT_APP_session_divert_reply,
                                    JSON.stringify(divertReplyInfo)
                                );
                            }

                            currentConversationDetailTemp = {
                                ...currentConversationDetailTemp,
                                auto_replay_status: {
                                    ...currentConversationDetailTemp.auto_replay_status,
                                    replay_message: notificationDetail.default_replay_message ?? '',
                                    replay_status: replyStatus.toString()
                                }
                            };

                            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp, 'noti reply status');
                            dispatch(passConversDetail(currentConversationDetailTemp));

                        }
                    }


                    provideAction(-1, pushNotificationItem, 1);

                    if (notificationDetail.hasOwnProperty('msg_type')) {

                        const msgType = parseInt(notificationDetail.msg_type || 0);
                        const msgTypeNames = notificationDetail.type ?? '';
                        const splitUrlParam = atob(encryptedParam).toString().split('-');

                        if (
                            ChatUtilities.allMessageTypesNamesArray.includes(msgTypeNames) ||
                            ChatUtilities.restrictionMessageTypesArray.includes(msgType) ||
                            chatCommunicationRequestArray.includes(msgTypeNames)
                        ) {

                            conversa_id = (notificationDetail.group_id ?? passConversID).toString();

                            currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail(conversa_id);
                            let flagId = currentConversationDetailTemp.hasOwnProperty('flag') ? currentConversationDetailTemp.flag : 0;
                            flagId = parseInt(flagId);

                            if (flagId === 6) {
                                passParams = {
                                    api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                                    account_id: (loggedinProfileDetail.account_id ?? 0).toString(),
                                    group_id: currentConversationDetailTemp.id.toString(),
                                    convers_id: conversa_id.toString(),
                                }

                                conversationDetailResponse = await QueryChatUtilities.getBeonchatGroupsConversationDetail(passParams);

                            } else {

                                // FROM Internal Chat
                                if (splitUrlParam.length === 4) {
                                    conversationDetailResponse = {};

                                    // normal chat
                                } else {
                                    passParams = {
                                        api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                                        //account_id: notificationDetail.receiver_account_id.toString(),
                                        //account_type: notificationDetail.receiver_account_type.toString(),
                                        account_id: (loggedinProfileDetail.account_id ?? 0).toString(),
                                        account_type: (loggedinProfileDetail.account_type ?? 0).toString(),
                                        convers_id: conversa_id.toString(),
                                    }

                                    conversationDetailResponse = await QueryChatUtilities.getBeonchatConversationDetail(passParams);
                                }


                            }


                            if (Object.keys(conversationDetailResponse).length > 0) {
                                profileChatConversation = ![6].includes(flagId) ?
                                    (conversationDetailResponse.result.beonchat_conversation ?? conversationDetailResponse.result.conversation_details) :
                                    (conversationDetailResponse.result.company_broadcast_conversation_details ?? conversationDetailResponse.result.company_group_conversation_details);

                                ChatUtilities.saveAccountsConversationDetail(profileChatConversation, 'notification item');

                                if (![2].includes(parseInt(flagId))) { // ! GROUP CHAT
                                    dispatch(passConversDetail(profileChatConversation));
                                }
                            }

                        }
                    }
                }
            }

        }

    }, [props.notificationItem]);


    useEffect(() => {



        let timer = setInterval(() => {
            if (Object.entries(RM.isNil(notiItems) ? {} : notiItems).length > 0) {
                let notiItemsTemp = [...notiItems];
                notiItemsTemp.pop();
                setNotiItems(notiItemsTemp);
            }
        }, (4 * 1000));

        return () => {
            clearInterval(timer);
            setSelectedkey(-1);
        }

    }, [notiItems]);


    return (
        <div className="push-notification-content text-lowercase">
            <ul className="push-notifcation-list">
                {
                    Object.entries(notiItems).map(([key, item]) =>
                        <li key={key} className={`fade-in font-gotham-book fs-15 fs-md-14 ${parseInt(selectedKey) === parseInt(key) ? 'processing' : ''} `}>
                            <div className="cursor-pointer" onClick={() => provideAction(key, item)}>
                                <div className="d-flex align-items-center px-2">
                                    <img src={`${process.env.REACT_APP_img_folder}/logo/logo-icon.svg`} width="20" />
                                    <div className="ml-2">
                                        <div className="ellipsis-1  font-gotham-bold fs-18 fs-md-18">{process.env.REACT_APP_site_title.toLowerCase()}</div>
                                    </div>
                                </div>
                                <div className="pt-3  px-2">
                                    <div className="mb-2 ellipsis-1 font-gotham-bold">{item.data.sender_name}</div>
                                    {/* <div className="mb-2 ellipsis-1">{item.notification.title}</div> */}
                                    <div className="ellipsis-2 color-theme-grey">{formatMessage(item)}</div>
                                </div>
                            </div>
                            <div className="push-notifcation-time color-theme-grey">{item.data.timeFormatted}</div>
                            <div className="btn-remove" onClick={() => removeItem(parseInt(key))}></div>
                        </li>
                    )
                }

                {/* <li className={`fade-in font-gotham-book fs-15 fs-md-14`}>
                        <div className="cursor-pointer">
                            <div className="d-flex align-items-center px-2">
                                <img src={`${process.env.REACT_APP_img_folder}/logo/logo-icon.svg`} width="20" />
                                <div className="ml-2">
                                    <div className="ellipsis-1 font-gotham-bold fs-18 fs-md-18">{process.env.REACT_APP_site_title.toLowerCase()}</div>
                                </div>
                            </div>
                            <div className="pt-3  px-2">
                                <div className="mb-2 ellipsis-1 font-gotham-bold">sam smith</div>
                                <div className="ellipsis-2 color-theme-grey">message recieved</div>
                            </div>
                        </div>
                        <div className="push-notifcation-time color-theme-grey">10:52pm</div>
                        <div className="btn-remove"></div>
                    </li> */}

            </ul>
        </div>
    )
}

export default NotificationItem;
