import axios from 'axios';
import ApiUrls from './ApiUrls';


export const getCurrentCredentailEmailValidation = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.securityEmailValidation, passval);
            if ( parseInt(responseURL.status)===200 ){
                responseDetail = responseURL.data;
            }
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const updateCredentailEmail = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.securityEmailUpdate, passval);
            if ( parseInt(responseURL.status)===200 ){
                responseDetail = responseURL.data;
            }
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const checkCredentailPasswordValidity = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.securityPasswordValidation, passval);
            if ( parseInt(responseURL.status)===200 ){
                responseDetail = responseURL.data;
            }
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const updateCredentailPassword = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.securityPasswordUpdate, passval);
            if ( parseInt(responseURL.status)===200 ){
                responseDetail = responseURL.data;
            }
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const checkCredentailMobileValidity = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.securityMobileValidation, passval);
            if ( parseInt(responseURL.status)===200 ){
                responseDetail = responseURL.data;
            }
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}

export const updateCredentailMobile = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {
            const responseURL = await axios.post(  ApiUrls.securityMobileUpdate, passval);
            if ( parseInt(responseURL.status)===200 ){
                responseDetail = responseURL.data;
            }
            
        
        } catch (e) {
            
        }
    }

    return responseDetail;
}


export const updateMobilesendVerification = async ( passval = {}  ) =>{
    let responseDetail = {};
    
    if ( Object.keys(passval).length>0 ){
        try {

            //console.log('updateMobilesendVerification');
            //console.log(ApiUrls.updateMobilesendVerification);
            //console.log(passval);
          
            const responseURL = await axios.post(  ApiUrls.updateMobilesendVerification, passval);
            //console.log(responseURL);
            //if ( parseInt(responseURL.status)===200 ){
                responseDetail = responseURL.data;
            //}
            
        
        } catch (e) {
            
        }
    }
    return responseDetail;
}

