import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { booleanShowWelcomeVideoAnimation } from '../../actions';

const WelcomeAnimatedLoader = () => {

    const dispatch = useDispatch();
    const pathVideosFolder = process.env.REACT_APP_videos_folder;

    const videoEl = useRef(null);
    const [showModal, setShowModal] = useState(true);

    const handleClosePopUp = () => {
        setShowModal(false);
        dispatch(booleanShowWelcomeVideoAnimation(false));
    }

    const attemptPlay = () => {
        videoEl &&
            videoEl.current &&
            videoEl.current.play().catch(error => {
                console.error("Error attempting to play", error);
            });
    };

    useEffect(() => {
        attemptPlay();
        setTimeout(() => { handleClosePopUp() }, (6 * 1000));
    }, [])

    return (

        <Modal id="popup-welcome-animated-video" show={showModal} onHide={handleClosePopUp} backdrop={`static`} keyboard={false} animation={false} scrollable={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme  " contentClassName="bg-black" backdropClassName="bg-black opacity-1-important">
            <Modal.Body className='overflow-hidden' style={{ height: `80vh` }}>

                <video id="video-welcome-loader" className='video-welcome-loader' width="100%" height="800" playsInline loop muted controls={false} ref={videoEl}>
                    <source src={`${pathVideosFolder}/beonchat-welcome-loader.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>


            </Modal.Body>
        </Modal>
    )
}

export default WelcomeAnimatedLoader;
