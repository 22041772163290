import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
//import jQuery from 'jquery';

import Utilities from '../../helpers/Utilities';


const VideoPopup = (props) => {

    const props_showPopup = props.showPopup ?? false;
    const props_youtubeCode = props.youtubeCode ?? '';

    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [iframeIsLoading, setIframeIsLading] = useState(true);


    const handleClosePopUp = () => {
        props.handleClosePopUp();
    }

    const handleOnLoadIFrame = (boolStatus = false) => {
        setIframeIsLading(boolStatus);
    }

    useEffect(() => {

        if (!Utilities.isEmpty(props_youtubeCode)) {
            //console.log(`https://www.youtube.com/embed/${props_youtubeCode}?autoplay=1`);
            setYoutubeUrl(`https://www.youtube.com/embed/${props_youtubeCode}?autoplay=1`);

        }
    }, [props]);

    return (

        <Modal id="popup-watch-video" show={props_showPopup} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="lg" contentClassName="bg-theme-green border-0" backdropClassName="modal-backdrop-gray- opacity-1-important">
            <Modal.Header closeButton className="border-0 popup-watch-video-header"></Modal.Header>
            <Modal.Body className="p-0 bg-theme-green font-gotham-medium text-center fs-20  popup-watch-video-body  overflow-hidden" style={{ minHeight: 400 }}>


                <iframe id="iframe-youtube-video" loading="lazy" width="100.5%" height="450" style={{ marginLeft: -2 }} src={youtubeUrl} onLoad={() => handleOnLoadIFrame(false)} class={`popup-watch-video-iframe  ${iframeIsLoading ? 'opacity-0' : ''}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>


            </Modal.Body>
        </Modal>

    )
}

export default VideoPopup;
