import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Utilities from '../../helpers/Utilities';

import { showPopupVideo as showPopupVideoReducer } from '../../actions';

const Videos = () => {

    const dispatch = useDispatch();
    const showPopupVideo = useSelector(state => state.showPopupVideo);

    const [showPopup, setShowPopup] = useState(false);
    const [requestVideo, setRequestVideo] = useState('');

    const handleClosePopUp = () => {
        setShowPopup(false);
        dispatch(showPopupVideoReducer(''));
    }

    useEffect(() => {
        //setRequestVideo('https://www.img.jazenet.com/images/master.resources//uploads/jazenet/tutorial_videos/home.mp4');
        if (!Utilities.isEmpty(showPopupVideo)) {
            setRequestVideo(showPopupVideo);
            setShowPopup(true);
        }


    }, [showPopupVideo])


    return (

        <Modal id="popup-watch-video" show={showPopup} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="lg" contentClassName="border-0 bg-transparent" >
            <Modal.Body className="p-0 bg-transparent">

                {
                    !Utilities.isEmpty(requestVideo) &&
                    <video width="100%" height="450" controls autoPlay={true} >
                        <source src={requestVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }

            </Modal.Body>
        </Modal>
    )
}

export default Videos;
