import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios'
import jQuery from 'jquery';
import moment from 'moment';
import { blockWordsAndEmailsValidation, checkEmailExistance, checkMobileNumberExistance, firstLastNameExistanceBasicProfile } from '../../helpers/VerificationUtilities';

import { showLoader, showHeader, showFooter, pageTitle, showPopupTermsConditions } from '../../actions';
import CitySearchPopup from '../../components/popups/CitySearch';
import Utilities from '../../helpers/Utilities';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

import FieldInput from '../../components/FieldInput';
import MobileSelection from '../../components/MobileSelection';
import SignupHeader from '../../layout/SignupHeader';
import { savePersonalTempInfo } from '../../helpers/QuerySignup';
import SessionUtilities from '../../helpers/SessionUtilities';
import CampaignLayout from './CampaignLayout';



const CampaignPersonalInfo = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const defaultLocation = Utilities.defaultLocation();
    const siteTitle = process.env.REACT_APP_site_title ?? '';
    const errorMsg = process.env.REACT_APP_message_error_process;

    let globalFormValuesTemp = {};

    let defaultFormValues = {
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        mobile_phonecode: defaultLocation.country_ext ?? '',
        country_id: defaultLocation.country_id,
        state_id: defaultLocation.state_id, // dubai
        country_code: defaultLocation.country_id,
        city_id: defaultLocation.city_id ?? '', // dubai
        city_name: defaultLocation.city_name ?? ''
    }

    const connect_password = process.env.REACT_APP_social_connect_password + Utilities.generateSignupCode();

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [showCityPopup, setShowCityPopup] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [buttonSubmitStatus, setButtonSubmitStatus] = useState('');
    const [countryList, setCountryList] = useState([]);

    const [sessionLocation, setSessionLocation] = useState({});
    const [sessionSocialConnectDetail, setSessionSocialConnectDetail] = useState({});

    const handleClosePopUp = () => setShowCityPopup(false);
    const handleShowPopUp = () => setShowCityPopup(true);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0;

        if (fieldName === 'first_name') {
            formValuesTemp = { ...formValuesTemp, ...{ first_name: value } };
        } else if (fieldName === 'last_name') {
            formValuesTemp = { ...formValuesTemp, ...{ last_name: value } };
        } else if (fieldName === 'email') {
            formValuesTemp = { ...formValuesTemp, ...{ email: value } };

        } else if (fieldName === 'country_code') {
            formValuesTemp = { ...formValuesTemp, ...{ country_code: value } };
        } else if (fieldName === 'country_id') {
            formValuesTemp = { ...formValuesTemp, ...{ country_id: value } };
        } else if (fieldName === 'state_id') {
            formValuesTemp = { ...formValuesTemp, ...{ state_id: value } };
        } else if (fieldName === 'city_id') {
            formValuesTemp = { ...formValuesTemp, ...{ city_id: value } };
        } else if (fieldName === 'city_name') {
            formValuesTemp = { ...formValuesTemp, ...{ city_name: value } };
        } else if (fieldName === 'mobile_number') {

            let mobile_number = value.replace(' ', '');
            if (!Utilities.isEmpty(mobile_number) && !Utilities.contactNumberValidate(mobile_number, 1, 6, 9).status) {
                Utilities.addFormGroupClass(fieldName);
                errorCounter++;
            }

            formValuesTemp = { ...formValuesTemp, ...{ mobile_number: parseInt(mobile_number) || '' } };
        }

        if (errorCounter === 0) {
            Utilities.removeFormGroupClass(fieldName);
        }

        setFormValues(formValuesTemp);
    }


    const passCityDetail = (passValue) => {
        globalFormValuesTemp = { ...formValues, ...{ city_id: passValue.id, city_name: passValue.city_name, state_id: passValue.state_id, country_id: passValue.country_id } };
        setFormValues(globalFormValuesTemp);
        handleClosePopUp();

        Utilities.removeFormGroupClass('city_name');
    }

    const pushTerms = () => {
        let acceptTermsTemp = (acceptTerms) ? false : true;
        setAcceptTerms(acceptTermsTemp);
    }

    const changeCountryCode = (passValues) => {
        globalFormValuesTemp = { ...formValues, ...{ mobile_phonecode: passValues.phonecode, country_code: passValues.id } };
        setFormValues(globalFormValuesTemp);
    }

    const submitForm = async (e) => {

        e.preventDefault();
        jQuery('#form-personal-info .error').removeClass('error');
        dispatch(showLoader(0));

        let errorCounter = 0, responseChecker = false;
        let formFieldsRequired = formValues;


        Object.entries(formFieldsRequired).map(([key, value]) => {

            if (Utilities.isEmpty(value) && !['email'].includes(key)) {
                Utilities.addFormGroupClass(key);
                errorCounter++;
            }

            if (key === 'email') {
                if (!Utilities.isEmpty(value) && !Utilities.isEmail(value)) {
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            } else if (['first_name', 'last_name'].includes(key)) {
                responseChecker = Utilities.inputCharacterChecker(value, 'general');
                if (responseChecker === false) {
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            }
        });



        if (!Utilities.contactNumberValidate(formValues.mobile_number, 0, 6, 9)) {
            Utilities.addFormGroupClass('mobile_number');
            errorCounter++;
        }



        if (errorCounter > 0) { return false; }

        if (acceptTerms === false) {
            errorCounter++;
            Utilities.messagePopup('Error', Utilities.labelNotCheckedTermsConditions);
            return false;
        }



        const utcTime = Utilities.currentUTCTime();
        const curretTime = moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());

        let pincode = Utilities.generateSignupCode();
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ create_date: curretTime, update_date: curretTime, pincode: pincode } };


        if (Object.keys(formValuesTemp).length > 0) {

            if (Object.keys(sessionLocation).length > 0) {
                formValuesTemp = { ...formValuesTemp, ...{ geo_city_id: sessionLocation.city_id, geo_city_name: sessionLocation.city_name.toString().toLowerCase() } };
            }


            // Checking email address if exist in database
            if (!Utilities.isEmpty(formValues.email)) {

                dispatch(showLoader(1));
                const responseEmailCheckingDB = await checkEmailExistance(formValues.email.toString());
                dispatch(showLoader(0));

                if (Object.keys(responseEmailCheckingDB).length > 0) {
                    if (parseInt(responseEmailCheckingDB.exist) === 1) {

                        Utilities.messagePopup('Error', Utilities.labelEmailAddressAlreadyExist);
                        Utilities.addFormGroupClass('email');
                        return false;
                    }
                }
            } else {

                dispatch(showLoader(1));
                const responseFirstLastNameCheckingDB = await firstLastNameExistanceBasicProfile(formValues.first_name, formValues.last_name);
                dispatch(showLoader(0));

                const currentProfileCounter = parseInt(responseFirstLastNameCheckingDB.counter) + 1,
                    currentProfileCounterFormatted = currentProfileCounter.toString().padStart(2, '0');


                // Generating email by combine f_name and l_name
                // custom email : first_name+last_name+01@beonchat.com
                const proposeEmailName = `${formValues.first_name.trim().replaceAll(" ", '')}${formValues.last_name.trim().replaceAll(" ", '')}`,
                    proposeEmail = `${proposeEmailName}${currentProfileCounterFormatted}@${siteTitle.toLowerCase()}.com`;

                formValuesTemp = { ...formValuesTemp, email: proposeEmail };
            }


            // RESTRICTED WORDS VALIDATION
            // TYPES : 1=>email, 2=>name, 3=>keywords
            let wordsRequestArray = [];
            wordsRequestArray.push(formValuesTemp.email);
            wordsRequestArray.push(formValuesTemp.first_name);
            wordsRequestArray.push(formValuesTemp.last_name);

            dispatch(showLoader(1));
            const responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('1,2', wordsRequestArray);
            dispatch(showLoader(0));

            if (parseInt(responseRestrictedValidationsDB.status) === 0) {
                Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_nameEmail);
                return false;
            }

            // END RESTRICTED WORDS VALIDATION



            // Checking mobile number if exist in database

            dispatch(showLoader(1));
            const responseMobileCheckingDB = await checkMobileNumberExistance(formValues.country_code, formValues.mobile_number);
            dispatch(showLoader(0));

            if (Object.keys(responseMobileCheckingDB).length > 0) {
                if (parseInt(responseMobileCheckingDB.exist) === 1) {

                    Utilities.messagePopup('Error', Utilities.labelMobileNumberAlreadyExist);
                    Utilities.addFormGroupClass('mobile_number');
                    return false;
                }
            }

            if (parseInt(sessionSocialConnectDetail.type ?? 0) > 0) {
                formValuesTemp = { ...formValuesTemp, password: connect_password };
            }


            PagesAuthentication.setSignupPersonalInfo(formValuesTemp);


            submitPersonalInfo(formValuesTemp);


        }

    }

    const submitPersonalInfo = async (formValuesTemp = {}) => {

        const session_signupCampaign = SessionUtilities.getSignupCampaign();
        if (Object.keys(formValuesTemp ?? {}).length > 0 && Object.keys(session_signupCampaign ?? {}).length > 0) {

            // campaign_category: 1 - campaign, 2 - referral
            const passval = {
                ...formValuesTemp,
                campaign_id: session_signupCampaign.id ?? 0,
                campaign_category: session_signupCampaign.category ?? 0
            }

            const saveDetailRes = await savePersonalTempInfo(passval);
            if (parseInt(saveDetailRes.status) === 1) {
                properRedirect();

            } else {
                Utilities.messagePopup('Error', errorMsg);
            }

        }

    }


    const properRedirect = () => {

        let redirectUrl = UrlSlugs.signup_campaignPersonalPassword;
        const session_signupConnect = PagesAuthentication.getSignupSocialDetail();

        if (Object.keys(session_signupConnect).length > 0) {
            redirectUrl = UrlSlugs.signup_personalCondition;
        }

        history.push(`/${redirectUrl}`);
    }



    const getCountries = async () => {
        let formValuesTemp = formValues, sessionLocationTemp = {};

        await axios
            .post(ApiUrls.country, {})
            .then(response => {
                if (response.status === 200) {
                    setCountryList(response.data);

                    Object.entries(response.data).map(([key, row]) => {
                        if (parseInt(row.id) === parseInt(defaultFormValues.country_code)) {

                            sessionLocationTemp = PagesAuthentication.getDeviceLocation();

                            if (Object.keys(sessionLocationTemp).length > 0) {
                                formValuesTemp = { ...formValuesTemp, ...{ mobile_phonecode: row.phonecode.toString(), city_name: sessionLocationTemp.city_name ?? '', city_id: sessionLocationTemp.city_id ?? 0 } };
                            }
                        }
                    });

                    setFormValues(formValuesTemp);

                }
            })
            .catch((err) => {

            });
    }

    const setupSocialFill = () => {

        let session_social_connect = PagesAuthentication.getSignupSocialDetail();
        if (Object.keys(session_social_connect).length > 0) {
            if (parseInt(session_social_connect.type) > 0) {

                globalFormValuesTemp = {
                    ...formValues,
                    first_name: session_social_connect.first_name,
                    last_name: session_social_connect.last_name,
                    email: session_social_connect.email
                }

            }

            setSessionSocialConnectDetail(session_social_connect);
        }

        return globalFormValuesTemp;
    }

    useEffect(async () => {

        dispatch(pageTitle(TitlesLabels.siteTitles.personalAccount));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        PagesAuthentication.checkLoginStatus();
        await getCountries();

        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...setupSocialFill() };
        setFormValues(formValuesTemp);

        let session_location = PagesAuthentication.getDeviceLocation();
        if (Object.keys(session_location).length > 0) {
            setSessionLocation(session_location);
        }

    }, [])

    return (
        <CampaignLayout classContent={`d-flex align-items-lg-end`} classWrap={``}>

            <div className='py-lg-4 mx-n3'>

                <div className='text-center font-gotham-bold fs-20 mb-3 mb-4'>create a personal account</div>

                <form id="form-personal-info" className="text-lowercase form-theme form-theme-dark text-left" autoComplete="off">

                    <input type="hidden" id="input_country_code" name="country_code" value={formValues.country_code} onChange={(e) => pushValue(e, 'country_code')} readOnly ></input>
                    <input type="hidden" id="input_city" name="city_id" value={formValues.city_id} onChange={(e) => pushValue(e, 'city_id')} readOnly ></input>
                    <input type="hidden" id="input_state" name="state_id" value={formValues.state_id} onChange={(e) => pushValue(e, 'state_id')} readOnly ></input>
                    <input type="hidden" id="input_country" name="country_id" value={formValues.country_id} onChange={(e) => pushValue(e, 'country_id')} readOnly ></input>



                    <FieldInput inputName="first_name" inputLabel="First Name" inputValue={formValues.first_name} pushValue={pushValue} checkerType="general" classPlaceholder={`bg-transparent`} />

                    <FieldInput inputName="last_name" inputLabel="Last Name" inputValue={formValues.last_name} pushValue={pushValue} checkerType="general" classPlaceholder={`bg-transparent`} />

                    <div className="form-group optional">
                        {
                            parseInt(sessionSocialConnectDetail.type ?? 0) > 0 ?
                                <input type="email" id="input-email" name="email" className="form-control" placeholder=" " value={formValues.email} disabled autoComplete="new-password" ></input>
                                :
                                <input type="email" id="input-email" name="email" className="form-control" placeholder=" " value={formValues.email} onKeyDown={(e) => Utilities.onKeyDownPreventSpace(e)} onChange={(e) => pushValue(e, 'email')} autoComplete="new-password" ></input>
                        }

                        <label className="form-label bg-transparent">Email Address</label>
                        <div className="optional-label bg-transparent">Optional</div>
                    </div>

                    <MobileSelection inputValue={formValues.mobile_number} countryListProvided={countryList} inputLabel="Mobile Number" countryCodeProvided={formValues.country_code} inputListingId="list-country-current" inputName="mobile_number" pushValue={pushValue} changeCountryCode={changeCountryCode} minNumber={6} maxNumber={9} dropDownClass={`show-arrow-important`} mobileNumberWidth={`100%`} />



                    <div className="form-group" onClick={() => handleShowPopUp()}>
                        <input type="text" id="input_city_name" name="city_name" className="form-control text-lowercase cursor-pointer" placeholder=" " disabled value={formValues.city_name} onChange={(e) => pushValue(e, 'city_name')}></input>
                        <label className="form-label bg-transparent">City of Residence</label>
                    </div>

                </form>


                <div className='pb-4 checkbox-radio-default checkbox-radio-check-icon d-flex fs-14 fs-md-14 justify-content-center text-left text-lowercase'>
                    <div className='pt-1'>
                        <input type="checkbox" name="terms" checked={acceptTerms} onChange={() => pushTerms()}  ></input>
                    </div>
                    <div>
                        <span className="ml-2">I accept {process.env.REACT_APP_site_title}</span>
                        <span className="labels ml-1 cursor-pointer" onClick={() => dispatch(showPopupTermsConditions(true))}>terms & conditions, </span>
                        <span className="labels ml-1 cursor-pointer" onClick={() => dispatch(showPopupTermsConditions(UrlSlugs.page_privacyPolicy))}>privacy policy, </span>
                        <span className="labels ml-1 cursor-pointer" onClick={() => dispatch(showPopupTermsConditions(UrlSlugs.page_disclaimer))}>disclaimer, </span>
                        <span className="labels ml-1 cursor-pointer" onClick={() => dispatch(showPopupTermsConditions(UrlSlugs.page_enduserLicense))}>end user license agreement</span>
                    </div>
                </div>

                <button type="submit" id="btn-submit-personal-info" className="btn-theme-green text-lowercase button-has-loader" onClick={(e) => submitForm(e)} disabled={buttonSubmitStatus} >Next</button>
            </div>


            <CitySearchPopup showCityPopup={showCityPopup} handleClosePopUp={handleClosePopUp} passCityDetail={passCityDetail} />

        </CampaignLayout>
    )
}

export default CampaignPersonalInfo;