import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { booleanShowCompanyGroupShortcut, showLoader } from '../../actions';
import { getGroupListByTeamMembersListId } from '../../helpers/QueryChatGroups';

import PagesAuthentication from '../../helpers/PagesAuthentication';
import TitlesLabels from '../../helpers/TitlesLabels';
import QueryChatUtilities from '../../helpers/QueryChatUtilities';
import ChatUtilities from '../../helpers/ChatUtilities';
import UrlSlugs from '../../helpers/UrlSlugs';
import SessionUtilities from '../../helpers/SessionUtilities';
import Utilities from '../../helpers/Utilities';

const CompanyGroupShortcut = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const boolShowCompanyGroupShortcut = useSelector(state => state.booleanShowCompanyGroupShortcut);

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    const loginUserDetail = PagesAuthentication.getUserLoggedinDetail();

    const [resultList, setResultList] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showLoadingList, setShowLoadingList] = useState(false);


    const handleClosePopUp = () => {
        setShowPopup(false);
        dispatch(booleanShowCompanyGroupShortcut(false));
    }

    const gettingGroupListByTeamMembersListId = async () => {

        setShowLoadingList(true);
        const responseGroupsList = await getGroupListByTeamMembersListId({ account_id: loginUserDetail.account_id });
        setShowLoadingList(false);

        if (parseInt(responseGroupsList.status) === 1) {
            setResultList(responseGroupsList.list ?? []);
        }

    }

    const showChat = async (groupDetail = {}) => {


        if (Object.keys(groupDetail).length === 0) { return false; }

        const flagId = 6;
        const passval = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: loginUserDetail.account_id.toString(),
            group_id: groupDetail.group_id.toString(),
            convers_id: groupDetail.conversa_id.toString(),
        }

        handleClosePopUp();

        dispatch(showLoader(1));
        const conversationDetailResponse = await QueryChatUtilities.getBeonchatGroupsConversationDetail(passval);
        dispatch(showLoader(0));

        if (Object.keys(conversationDetailResponse).length === 0) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGetConversationDetails);
            return false;
        }

        if (parseInt(conversationDetailResponse.success) === 0) {
            Utilities.messagePopup('error', conversationDetailResponse.errorMessage);
            return false;

        }

        /* const profileChatConversation = flagId !== 6 ? 
                conversationDetailResponse.result.beonchat_conversation :
                    conversationDetailResponse.result.company_broadcast_conversation_details; */

        const profileChatConversation = flagId !== 6 ? 
                (conversationDetailResponse.result.beonchat_conversation ?? conversationDetailResponse.result.conversation_details) :
                 ( conversationDetailResponse.result.company_broadcast_conversation_details ?? conversationDetailResponse.result.company_group_conversation_details );
                
        const passValues = ChatUtilities.encryptChatDetail(profileChatConversation);
        ChatUtilities.saveAccountsConversationDetail(profileChatConversation, 'dashboard');


        history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(profileChatConversation.account_id))}/${passValues}`);

    }


    /*  useEffect( () => {
         gettingGroupListByTeamMembersListId();
     }, []); */

    useEffect(() => {

        if ((boolShowCompanyGroupShortcut ?? false) === true) {
            gettingGroupListByTeamMembersListId();
            setShowPopup(true);
        }

    }, [boolShowCompanyGroupShortcut]);


    return (

        <Modal id="popup-group-shorcut" show={showPopup} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme"  >
            <Modal.Header closeButton className="border-bottom-0">
                <h5 className="text-uppercase text-center w-100 mb-2 mt-4 pl-4" >
                    <div className=' d-flex align-items-center justify-content-center rounded-circle bg-black mx-auto' style={{ width: 70, height: 70 }}>
                        <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-person-signal.svg`} style={{ width: `fit-content` }} />
                    </div>
                </h5>
            </Modal.Header>
            <Modal.Body className="px-5  pb-5">

                {
                    (!showLoadingList && (resultList ?? []).length > 0) &&
                    <ul className="google-language-list font-gotham-bold fs-18">
                        {
                            Object.entries(resultList ?? []).map(([key, row]) =>
                                <li key={key} onClick={() => showChat(row)} >{row.group_name ?? ''}</li>
                            )
                        }
                    </ul>
                }


                <ul className="google-language-list notranslate">
                    {
                        showLoadingList &&
                        [...Array(6 + (Math.floor(Math.random() * 3))).keys()].map((key) => <li key={key}><div className={`py-2 bg-gray col-${Math.floor(Math.random() * 3) + 7} pt-3 border-radius-loader blinking`}></div></li>)
                    }
                </ul>

                {
                    (!showLoadingList && resultList.length === 0) &&
                    <div className='fs-18 fs-md-18 text-center pt-3 pb-2 text-gray'>{TitlesLabels.general.noRecordFound}</div>
                }



            </Modal.Body>
        </Modal>
    )
}

export default CompanyGroupShortcut;
