import React from 'react';

const DirectorySearchLoaderCategories = (props) => {
    return (
        <div className={`container pt-3 ${props.classAttr ?? ''}`}>
            <ul className="list-categories global-preloader">
                {
                    [...Array(12).keys()].map((key) =>
                        <li key={key}>
                            <div className="category-image"></div>
                            <div className="font-gotham-book-14 ellipsis-2 line-height-1-2 pt-2 mb-1"><div className="height-15 bg-gray col-10 mx-auto  py-2 border-radius-loader"></div></div>
                            <div className="font-gotham-light-12"><div className="height-12 bg-gray col-8 mx-auto   py-2 border-radius-loader"></div></div>
                        </li>
                    )
                }

            </ul>
        </div>
    )

}


export default DirectorySearchLoaderCategories;