import React, { useEffect } from 'react';
import * as RM from 'ramda';

import DirectorySearchNoRecord from './DirectorySearchNoRecord';
import DirectorySearchProfileTemplate from './DirectorySearchProfileTemplate';
import Utilities from '../../helpers/Utilities';
import DirectorySearchBackNavigation from './DirectorySearchBackNavigation';


const DirectorySearchProfileListing = (props) => {

    let listProfiles = props.listProfiles ?? [];
    const locationTabId = props.locationTabId ?? [];
    const searchCategoryId = props.searchCategoryId ?? 0;
    const searchCategoryDetail = props.searchCategoryDetail ?? {};

    const enableSortDistance = props.enableSortDistance ?? false;
    //console.log(props.enableSortDistance);

    if (enableSortDistance === true) {
        listProfiles = Utilities.sortCalculateDistance(listProfiles, 1);
        //console.log(`distance sort: true`);
    } else {

        let list = listProfiles;
        /* if (list.length > 0) {
            if ('name' in list[0]) {
                const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                list = RM.sort(byName, list);
            }

            listProfiles = list;
        } */
        //console.log(`distance sort: false`);
    }


    const handleClickCategoryBack = () => {
        props.handleClickCategoryBack();
    }



    if (listProfiles.length === 0) {
        return <DirectorySearchNoRecord />
    } else {
        return (
            <>
                {
                    Object.keys(searchCategoryDetail).length > 0 && <DirectorySearchBackNavigation handleClick={handleClickCategoryBack} text={searchCategoryDetail.cate_name ?? ''} />
                }
                <ul className="profile-listing" >
                    {Object.entries(listProfiles).map(([key, row]) => <DirectorySearchProfileTemplate keyId={key} profile={row} locationTabId={locationTabId} highlightKeywordInResults={props.highlightKeywordInResults} previewProfileImage={props.previewProfileImage} handleClickProfile={props.handleClickProfile} />)}
                </ul>
            </>
        )


    }
}


export default DirectorySearchProfileListing;