import ApiUrls from './ApiUrls';
import axios from 'axios';
import PagesAuthentication from './PagesAuthentication';
//import * as RM from 'ramda';

class SessionUtilities {


     /////////////////////////////////////////////////////////////////////////////////////////////////////
     setSignupGlanceStatus = (status = '0') => {
        status = parseInt(status ?? 0);

        sessionStorage.setItem(
            process.env.REACT_APP_notification_glance_signup_followed_glance_status,
            JSON.stringify(status)
        );
    }

    getSignupGlanceStatus = () => {
        const status = sessionStorage.getItem(process.env.REACT_APP_notification_glance_signup_followed_glance_status) ?? '0';
        return status.toString();
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    setLocalSessionMenuSettingsStatus = (menuSettingRes = {}) => {
        if (Object.keys(menuSettingRes).length > 0) {
            localStorage.setItem(
                process.env.REACT_APP_session_divert_reply,
                JSON.stringify(menuSettingRes.home_menu_list)
            );
        }
    }

    getLocalSessionMenuSettingsStatus = () => {
        let detailArrayObject = {};
        let sessionDetail = localStorage.getItem(process.env.REACT_APP_session_divert_reply) ?? {};
        if (sessionDetail !== null && Object.keys(sessionDetail).length > 0) {
            detailArrayObject = JSON.parse(sessionDetail);
        }

        return detailArrayObject;
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////
    setSessionToken = (tokenVal = '') => {
        localStorage.setItem(
            process.env.REACT_APP_session_token,
            tokenVal
        );
    }

    getSessionToken = () => {
        return localStorage.getItem(process.env.REACT_APP_session_token) ?? '';
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    setSessionLocation = (locationValues = {}) => {
        sessionStorage.setItem(
            process.env.REACT_APP_session_location,
            JSON.stringify(locationValues)
        );
    }

    getSessionLocation = () => {
        let locationValues = {};
        let userLocation = sessionStorage.getItem(process.env.REACT_APP_session_location) ?? {};
        if (userLocation !== null && Object.keys(userLocation).length > 0) {
            locationValues = JSON.parse(userLocation);
        }

        return locationValues;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    setUserLocation = (locationValues = {}) => {
        localStorage.setItem(
            process.env.REACT_APP_user_location,
            JSON.stringify(locationValues)
        );
    }

    getUserLocation = () => {
        let locationValues = {};
        let userLocation = localStorage.getItem(process.env.REACT_APP_user_location) ?? {};
        if (userLocation !== null && Object.keys(userLocation).length > 0) {
            locationValues = JSON.parse(userLocation);
        }

        return locationValues;
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////
    getAllTeamMembersLinkedFromMaster = () => {
        let listUsers = [];
        const masterDetail = PagesAuthentication.getMasterUserDetailDetail(),
            masterAccountId = parseInt(masterDetail.account_id ?? 0);
        const associatedAccounts = PagesAuthentication.getUsersAssociated();

        if (Object.keys(associatedAccounts).length > 0) {
            Object.entries(associatedAccounts).map(([acctid, acctdetail]) => {
                if (acctdetail.hasOwnProperty('company_own_status')) {

                    const linkAccountId = parseInt(acctdetail.company_own_status ?? 0);
                    if (linkAccountId === 1) {
                        //listUsers.push({...acctdetail, account_id : acctdetail.account_id.toString() });
                        listUsers.push(acctdetail);
                    }
                }

            });
        }

        /*  console.log(listUsers);
 
         const listUsersSort = RM.ascend(RM.compose(RM.toLower, RM.prop('account_id')));
         listUsers = RM.sort(listUsersSort, listUsers);
 
         console.log(listUsers); */

        return listUsers;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    getSignupGeneratedCompanyId = () => {
        return parseInt(sessionStorage.getItem(process.env.REACT_APP_session_signup_companyGeneratedId) ?? 0);
    }

    setSignupGeneratedCompanyId = (value = 0) => {
        value = parseInt(value);
        sessionStorage.setItem(
            process.env.REACT_APP_session_signup_companyGeneratedId,
            JSON.stringify(value)
        );
    }

    removeSignupGeneratedCompanyId = () => {
        sessionStorage.removeItem(process.env.REACT_APP_session_signup_companyGeneratedId);
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////

    getSignupWelcomeMessage = () => {
        return parseInt(localStorage.getItem(process.env.REACT_APP_session_welcome_mesage) ?? 0);
    }

    setSignupWelcomeMessage = (value = 0) => {
        value = parseInt(value);
        localStorage.setItem(
            process.env.REACT_APP_session_welcome_mesage,
            JSON.stringify(value)
        );
    }

    removeSignupWelcomeMessage = () => {
        localStorage.removeItem(process.env.REACT_APP_session_welcome_mesage);
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////
    getRefferalType = () => {
        return parseInt(localStorage.getItem(process.env.REACT_APP_session_signup_campaign_type) ?? 0);
    }

    setRefferalType = (value = 0) => {
        value = parseInt(value);
        localStorage.setItem(
            process.env.REACT_APP_session_signup_campaign_type,
            JSON.stringify(value)
        );
    }

    removeRefferalType = () => {
        localStorage.removeItem(process.env.REACT_APP_session_signup_campaign_type);
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////
    setSignupCampaignDownload = (value = 0) => {
        value = parseInt(value);
        localStorage.setItem(
            process.env.REACT_APP_session_campaign_popup,
            JSON.stringify(value)
        );
    }

    getSignupCampaignDownload = () => {
        return parseInt(localStorage.getItem(process.env.REACT_APP_session_campaign_popup) ?? 0);
    }

    removeSignupCampaignDownload = () => {
        localStorage.removeItem(process.env.REACT_APP_session_campaign_popup);
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////

    /* removeWelcomeMessage = () => {
        localStorage.removeItem( process.env.REACT_APP_session_welcome_mesage);
    } */

    getFromLoginStatus = () => {
        return parseInt(localStorage.getItem(process.env.REACT_APP_session_from_login_status) ?? 1);
    }

    setFromLoginStatus = (value = 0) => {
        value = parseInt(value);
        localStorage.setItem(
            process.env.REACT_APP_session_from_login_status,
            JSON.stringify(value)
        );
    }

    getSignupCampaign = () => {
        let detail = {};

        let detailTemp = sessionStorage.getItem(process.env.REACT_APP_session_signup_campaign_detail);
        if (detailTemp !== null) {
            detail = JSON.parse(detailTemp);
        }

        return detail;
    }

    deleteSignupCampaign = () => {
        sessionStorage.removeItem(process.env.REACT_APP_session_signup_campaign_detail);
    }

    setSignupCampaign = (detail = {}) => {
        if (Object.keys(detail).length > 0) {
            sessionStorage.setItem(
                process.env.REACT_APP_session_signup_campaign_detail,
                JSON.stringify(detail)
            );
        }
    }

    getDashboadLoadedStatus = () => {
        return parseInt(sessionStorage.getItem(process.env.REACT_APP_sesion_dashboard_loaded) || 0);
    }

    setDirectoryLocation = (locationDetail = {}) => {
        if (Object.keys(locationDetail).length > 0) {
            sessionStorage.setItem(
                process.env.REACT_APP_session_directory_location,
                JSON.stringify(locationDetail)
            );
        }

    }

    removeDirectoryLocation = () => {
        sessionStorage.removeItem(process.env.REACT_APP_session_directory_location);
    }

    getDirectoryLocation = () => {
        let defaultLocationTemp = {};

        let directoryLocation = sessionStorage.getItem(process.env.REACT_APP_session_directory_location);
        if (directoryLocation !== null) {
            defaultLocationTemp = JSON.parse(directoryLocation);

        }

        return Object.keys(defaultLocationTemp).length === 0 ? null : defaultLocationTemp;
    }

    setDashboadLoadedStatus = (status = 0) => {
        status = parseInt(status);
        sessionStorage.setItem(
            process.env.REACT_APP_sesion_dashboard_loaded,
            JSON.stringify(status)
        );
    }

    getDashboadLoadedListTemp = () => {
        let returns = [];

        let listProvided = sessionStorage.getItem(process.env.REACT_APP_sesion_dashboard_templist);
        if (listProvided !== null) {
            listProvided = JSON.parse(listProvided);

            if (listProvided.length > 0) {
                returns = listProvided;
            }
        }

        return returns;
    }

    setDashboadLoadedListTemp = (list = []) => {
        sessionStorage.setItem(
            process.env.REACT_APP_sesion_dashboard_templist,
            JSON.stringify(list)
        );
    }



    getDashboadInternalLoadedStatus = () => {
        return parseInt(sessionStorage.getItem(process.env.REACT_APP_sesion_dashboardinternal_loaded) || 0);
    }

    setDashboadInternalLoadedStatus = (status = 0) => {
        status = parseInt(status);
        sessionStorage.setItem(
            process.env.REACT_APP_sesion_dashboardinternal_loaded,
            JSON.stringify(status)
        );
    }

    getDashboadLoadedInternalListTemp = () => {
        let returns = [];

        let listProvided = sessionStorage.getItem(process.env.REACT_APP_sesion_dashboardinternal_templist);
        if (listProvided !== null) {
            listProvided = JSON.parse(listProvided);

            if (listProvided.length > 0) {
                returns = listProvided;
            }
        }

        return returns;
    }

    setDashboadLoadedInternalListTemp = (list = []) => {
        sessionStorage.setItem(
            process.env.REACT_APP_sesion_dashboardinternal_templist,
            JSON.stringify(list)
        );
    }


    getDashboadInternalListSelectedProfile = () => {
        let returns = {};

        let listProvided = sessionStorage.getItem(process.env.REACT_APP_sesion_dashboardinternal_profile_selected);

        if (listProvided !== null) {
            listProvided = JSON.parse(listProvided);

            if (Object.keys(listProvided).length > 0) {
                returns = listProvided;
            }
        }
        return returns;
    }

    setDashboadInternalListSelectedProfile = (list = {}) => {
        sessionStorage.setItem(
            process.env.REACT_APP_sesion_dashboardinternal_profile_selected,
            JSON.stringify(list)
        );
    }



    sessionUpdateAccountsFromDB = async (overwrite_login = 0) => {

        overwrite_login = parseInt(overwrite_login);

        //const API_URL = process.env.REACT_APP_api_url;
        //console.log('get data from database...');

        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {

                const account_id = Object.values(accountsList)[0].account_id;

                await axios
                    .post(ApiUrls.userGetByCredentail, { account_id: account_id })
                    .then(response => {
                        const resStatus = response.status;

                        if (resStatus === 200) {
                            const resData = response.data;
                            const resStatus = parseInt(resData.status);
                            const resLoginType = parseInt(resData.logintype);

                            if (resStatus === 1) {

                                // SO IT WILL NOT OVERWRITE CURRENT
                                if (overwrite_login === 1) {
                                    localStorage.setItem(
                                        process.env.REACT_APP_session_login,
                                        JSON.stringify(resData.detail)
                                    );
                                }

                                localStorage.setItem(
                                    process.env.REACT_APP_session_logintype,
                                    JSON.stringify(resLoginType)
                                );

                                localStorage.setItem(
                                    process.env.REACT_APP_session_internal,
                                    JSON.stringify(resData.internal)
                                );

                                localStorage.setItem(
                                    process.env.REACT_APP_session_login_started,
                                    Date.now()
                                );

                                // console.log(resData.accounts);

                                if (resLoginType === 1) {

                                    localStorage.setItem(
                                        process.env.REACT_APP_session_accounts,
                                        JSON.stringify(resData.accounts)
                                    );

                                } else if (resLoginType === 2) {

                                    localStorage.setItem(
                                        process.env.REACT_APP_session_department_active,
                                        JSON.stringify(resData.detail)
                                    );

                                    localStorage.setItem(
                                        process.env.REACT_APP_session_company_detail,
                                        JSON.stringify(resData.company_detail)
                                    );

                                    localStorage.setItem(
                                        process.env.REACT_APP_session_company_departments,
                                        JSON.stringify(resData.departments)
                                    );

                                }

                            }
                        }

                    })
                    .catch((err) => {

                    });
            }
        }
    }

    getProfileDetail = (reqProfileID = 0, departmentId = -1) => {

        let profileDetail = {};

        if (reqProfileID > 0) {
            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);

            if (accountsList !== null) {
                accountsList = JSON.parse(accountsList);

                if (Object.keys(accountsList).length > 0) {

                    if (reqProfileID.toString() in accountsList) {
                        profileDetail = accountsList[reqProfileID];

                        // if have department
                        let profileDepartment = {};

                        if (departmentId > -1) {

                            if (profileDetail.hasOwnProperty('departments')) {

                                if (departmentId === 0) {
                                    profileDepartment = profileDetail.departments[0];

                                } else {

                                    for (const departmentKey in profileDetail.departments) {
                                        const department = profileDetail.departments[departmentKey];

                                        if (department.group_id && department.hasOwnProperty('group_id') && parseInt(department.group_id) === parseInt(departmentId)) {
                                            profileDepartment = department;
                                        }
                                    }

                                }
                            }

                            profileDetail = profileDepartment;
                        }

                    }

                }
            }
        }

        return profileDetail;
    }


    updateCompanyDetail = (companyDetailRequest = {}) => {
        if (Object.keys(companyDetailRequest).length > 0) {
            console.log(companyDetailRequest);
            const companyAccountId = parseInt(companyDetailRequest.account_id);
            let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);

            if (accountsList !== null) {
                accountsList = JSON.parse(accountsList);

                let companyDetailTemp = {}, accountsListLoop = {}, accountTypeLoop = '', companyIdLoop = 0;
                Object.entries(accountsList).map(([key, row]) => {
                    accountTypeLoop = parseInt(row.account_type);

                    if (accountTypeLoop !== 4) {
                        accountsListLoop[key] = row;
                    } else {
                        if (row.hasOwnProperty('company_detail')) {
                            companyDetailTemp = row.company_detail;
                            companyIdLoop = parseInt(companyDetailTemp.account_id);

                            if (companyAccountId === companyIdLoop) {
                                row = { ...row, company_detail: { ...companyDetailTemp.company_detail, ...companyDetailRequest } };
                                accountsListLoop[key] = row;
                            } else {
                                accountsListLoop[key] = row;
                            }
                        }
                    }
                });

                localStorage.setItem(
                    process.env.REACT_APP_session_accounts,
                    JSON.stringify(accountsListLoop)
                );

            }
        }
    }
}

export default new SessionUtilities();

