import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';
import * as RM from 'ramda';

import Utilities from './../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import PagesAuthentication from './../../helpers/PagesAuthentication';

import { showLanguageSelectionPopup, changeCityPopup as changeCityPopupAction, toggleAutomatedReply, showMainSettings as showMainSettingsReducer, toggleDepartmentDefaultMessage as toggleDepartmentDefaultMessageReducer, toggleDepartmentForms as toggleDepartmentFormsReducer, passConversList as passConversListReducer, showLoader as showLoaderReducer, toggleMenuPriceList, booleanShowCompanyGroupShortcut } from '../../actions';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';
import QueryUtilities from '../../helpers/QueryUtilities';
import { getReceiverAccountAndDeptReceiverIds } from '../../helpers/QueryDiary';
import CommonProfileLogo from '../common/CommonProfileLogo';
import { getCompanyBranchesByMasterId } from '../../helpers/QueryCompanyBranch';
import { checkPermissionToRegisterCompany } from '../../helpers/QueryCompanySignup';
import CompanyCreationRequestPopup from './CompanyCreationRequestPopup';
import SessionUtilities from '../../helpers/SessionUtilities';


const SettingProfilePopup = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    const errorMsg = process.env.REACT_APP_message_error_process;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const siteTitle = process.env.REACT_APP_site_title;

    const reloadProfile = useSelector(state => state.reloadProfile);
    const passConversList = useSelector(state => state.passConversList);
    const showMainSettings = useSelector(state => state.showMainSettings);

    const [listAccounts, setListAccounts] = useState([]);
    const [linkedAccounts, setLinkedAccounts] = useState([]);

    const [activeSlide, setActiveSlide] = useState(0);

    const [sliderCounterArr, setSliderCounterArr] = useState([]);
    const [sliderCounterLinkedArr, setSliderCounterrLinkedArr] = useState([]);

    const [loggedinAccount, setLoggedinAccount] = useState({});
    const [allowShareQRCode, setAllowShareQRCode] = useState(false);

    const [mainSettingsStatus, setMainSettingsStatus] = useState(false);
    const [divertReplyInfo, setDivertReplyInfo] = useState({});

    const [hasAssignee, setHasAssignee] = useState([]);

    const [slideProfiles, setSlideProfiles] = useState(false);
    const [slideLinkedProfiles, setSlideLinkedProfiles] = useState(false);

    const [showPopup, setShowPopup] = useState(false);
    const [showCreateCompanyOption, setShowCreateCompanyOption] = useState(false);

    //const [ requestCompanyCreationStatus , setRequestCompanyCreationStatus ] = useState(0);
    const [showCompanyRequestPopup, setShowCompanyRequestPopup] = useState(false);



    const handleClosePopup = () => {
        setShowPopup(false);
        setMainSettingsStatus(false);
        dispatch(showMainSettingsReducer(''));
        setShowCreateCompanyOption(false);
    }

    const sliderNext = (type = 'profiles') => {
        let num = activeSlide + 1,
            sliderSelected = type === 'profiles' ? sliderCounterArr : sliderCounterLinkedArr;

        if (num > 0 && sliderSelected.includes(num)) {
            setActiveSlide(num);
        }
    }

    const sliderPrev = (type = 'profiles') => {
        let num = activeSlide - 1,
            sliderSelected = type === 'profiles' ? sliderCounterArr : sliderCounterLinkedArr;

        if (num >= 0 && sliderSelected.includes(num)) {
            setActiveSlide(num);
        }
    }

    const menuProductButtonHtml = (profile = {}) => {
        let htmlReturn = <></>
        const team_is_admin = parseInt(profile.team_is_admin ?? 0);
        const team_menu_permission = parseInt(profile.team_menu_permission ?? 0);

        if ([1].includes(team_is_admin) || [1].includes(team_menu_permission)) {
            htmlReturn = <li onClick={() => { handleClosePopup(); dispatch(toggleMenuPriceList(profile)); }}>
                <div className={`menu-icon menuprice-icon`}></div>
                <div>menu / pricelist management</div>
            </li>
        }

        //console.log(profile);
        return htmlReturn;
    }

    const notificationManagementHtml = (profile = {}) => {
        let htmlReturn = <></>
        const team_is_admin = parseInt(profile.team_is_admin ?? 0);
        const team_notification_permission = parseInt(profile.team_notification_permission ?? 0);

        if ([1].includes(team_is_admin) || [1].includes(team_notification_permission)) {
            htmlReturn = <li onClick={() => handleOnClickSettingNavigations('notification-management')}>
                <div className={`menu-icon bell-icon`}></div>
                <div>notification management</div>
            </li>
        }
        return htmlReturn
    }


    const userMenu = (profile) => {
        const account_id = parseInt(profile.account_id || 0);
        const account_id_encoded = btoa(account_id);
        const account_type = parseInt(profile.account_type || 0);

        let menuHtml = '';
        let team_is_admin = 0;

        if (profile.hasOwnProperty('team_is_admin')) {
            team_is_admin = parseInt(profile.team_is_admin || 0);
        }

        if (account_type === -1) {
            menuHtml = <>
                <Link to={`/${UrlSlugs.companyBranch_informatioDetail}/${btoa(profile.id ?? 0)}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.manageCompany}</Link>
            </>
        } else if (account_type === 1) {
            menuHtml = <>
                <Link to={`/${UrlSlugs.settings_manageProfile}`} onClick={() => handleClosePopup()}>{TitlesLabels.siteTitles.managePersonalProfile}</Link>
                <Link to={`/${UrlSlugs.settings_manageProfileSecurity}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.security}</Link>
            </>


        } else if (account_type === 3) {
            menuHtml = <>
                <Link to={`/${UrlSlugs.settings_manageProfileCompany}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.manageCompanyProfile}</Link>
                <Link to={`/${UrlSlugs.settings_manageDepartments}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.departments}</Link>
                <Link to={`/${UrlSlugs.settings_manageTeam}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.teamMembers}</Link>
            </>

        } else if (account_type === 4) {

            if ([1, 2].includes(team_is_admin)) {
                menuHtml = <>
                    <Link to={`/${UrlSlugs.settings_manageWorkProfile}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.manageWorkProfile}</Link>
                    <Link to={`/${UrlSlugs.settings_manageProfileCompany}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.manageCompanyProfile}</Link>
                    <Link to={`/${UrlSlugs.settings_manageDepartments}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.departments}</Link>
                    <Link to={`/${UrlSlugs.settings_manageTeam}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.teamMembers}</Link>
                    {/* <div className="cursor-pointer" onClick={ () => { handleClosePopup(); dispatch( toggleMenuPriceList( profile ) );  }}>menu/products</div> */}
                    {[1].includes(team_is_admin) && <Link to={`/${UrlSlugs.settings_specialPermissions}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.specialPermissions}</Link>}
                    <Link to={`/${UrlSlugs.settings_manageCompanyGroups}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.siteTitles.companyGroups}</Link>
                    <Link to={`/${UrlSlugs.settings_manageGotoLinks}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.siteTitles.gotoLinks}</Link>
                </>
            } else {
                menuHtml = <>
                    <Link to={`/${UrlSlugs.settings_manageWorkProfile}/${account_id_encoded}`} onClick={() => handleClosePopup()}>{TitlesLabels.navigations.manageWorkProfile}</Link>
                </>
            }
        }

        return menuHtml;
    }

    const subProfiles = (profile) => {

        let subProfilesTemp = [];
        if (parseInt(profile.account_type) === 4) {
            if (profile.hasOwnProperty('departments')) {
                subProfilesTemp = [...subProfilesTemp, profile];

                Object.entries(profile.departments).map(([key, row]) => {
                    if (parseInt(row.group_id || 0) > 0 && parseInt(row.flag || 0) === 5) {

                        let departmentTemp = {
                            ...row,
                            logo: nameByAccountType(profile).logo,
                            name: row.department_name,
                            type: 3
                        }

                        subProfilesTemp = [...subProfilesTemp, departmentTemp];
                    }
                });
            }
        }

        return subProfilesTemp;
    }

    const teamAssigned = (profile) => {

        let returns = { indicator: false, assign: false };
        if (profile.hasOwnProperty('account_type')) {
            const account_type = parseInt(profile.account_type || 0);

            if (account_type === 4) {
                const account_flag = parseInt(profile.flag || 0);
                let indicator = false;

                if (account_flag === 4) {
                    if (hasAssignee.includes(profile.account_id.toString())) {
                        indicator = true;
                    }
                    returns = { ...returns, indicator: indicator, assign: true };
                }
            }
        }

        return returns;
    }


    const setupProfiles = async () => {

        // ASSOCIATED ACCOUNTS
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {

                let accountMain = [], accountSub = [], accountIDs = [], accountAll = [], accountProfileAll = [], accountLinkedAll = [], loopName = '';

                let accountListTemp = Utilities.sortAccounts(accountsList);

                accountListTemp.forEach(acctdetail => {

                    const formattedDetails = Utilities.formatProfileDetail(acctdetail);

                    if (parseInt(acctdetail.account_type) === 1) {
                        accountMain = formattedDetails;
                    } else {
                        accountSub = [...accountSub, formattedDetails];
                    }

                    if (parseInt(acctdetail.account_type) === 4) {
                        accountIDs.push(acctdetail.account_id);
                    }
                });

                accountAll.push(accountMain);

                const companyBranchesRes = await getCompanyBranchesByMasterId({ master_account_id: accountMain.account_id });
                if (parseInt(companyBranchesRes.status) === 1) {

                    if (Object.keys(companyBranchesRes.list).length > 0) {
                        Object.entries(companyBranchesRes.list).map(([key, row]) => {
                            //if ( ! Utilities.isEmpty(row.branch_company_id??'') ){
                            loopName = (row.company_info ?? '') === '' ? '' : row.company_info.company_name;
                            accountSub.push({
                                id: row.id ?? '',
                                account_id: accountMain.account_id ?? 0,
                                account_type: `-${accountMain.account_type}`,
                                name: loopName,
                                company_name: loopName,
                                company_detail: row.company_info ?? {}
                            });
                            //}
                        });
                    }
                }


                // INCLUDE BRANCH
                //console.log(accountSub);

                if (accountSub.length > 0) {
                    if ('company_name' in accountSub[0]) {
                        //const byName = RM.ascend(RM.prop('company_name')); key senstive
                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('company_name')));
                        accountSub = RM.sort(byName, accountSub);
                    }

                    accountAll = RM.concat(accountAll, accountSub);
                }



                Object.entries(accountAll).map(([key, row]) => {
                    if (parseInt(row.account_type ?? 0) > 0) {
                        accountLinkedAll.push(row);
                    }
                });

                Object.entries(accountAll).map(([key, row]) => {
                    if (parseInt(row.account_type) === 4) {
                        //if ( parseInt(row.company_detail.is_branch??0)===0 ){
                        if (parseInt(row.company_detail.branch_status ?? -1) === 0) {
                            accountProfileAll.push(row);
                        }
                    } else {
                        accountProfileAll.push(row);
                    }
                });

                //console.log(accountProfileAll);
                //console.log(accountLinkedAll);


                accountProfileAll = Utilities.sliceIntoChunks(accountProfileAll, 3);
                const sliderCounterAppTemp = [...Array(Object.entries(accountProfileAll).length).keys()];

                setSliderCounterArr(sliderCounterAppTemp);
                setListAccounts(accountProfileAll);

                accountLinkedAll = Utilities.sliceIntoChunks(accountLinkedAll, 3);
                const sliderCounterLinkedAppTemp = [...Array(Object.entries(accountLinkedAll).length).keys()];

                setSliderCounterrLinkedArr(sliderCounterLinkedAppTemp);
                setLinkedAccounts(accountLinkedAll);




                if (accountIDs.length > 0) {
                    await axios
                        .post(ApiUrls.linkProfilesAssignedStatusByIDs, { account_ids: accountIDs.join(',').toString() })
                        .then(response => {

                            if (response.status === 200) {
                                const dataResponse = response.data;
                                const dataStatus = parseInt(dataResponse.status);

                                if (dataStatus === 1) {
                                    setHasAssignee(dataResponse.ids);
                                }
                            }
                        })
                        .catch((err) => { });
                }
            }

        }
    }

    const submitAppointmentStatus = async (appointment_status = 0) => {

        const passValues = {
            api_token: TOKEN_SESSION.toString(),    //TOKEN.toString(),
            account_id: loggedinAccount.account_id.toString(),
            flag: appointment_status.toString()
        }


        //console.log('submitAppointmentStatus');
        //console.log(ApiUrls.profileActivateTeamAppointmentStatus);
        //console.log(passValues);

        dispatch(showLoaderReducer(1));

        let statusLabel = parseInt(appointment_status) === 1 ? 'activated' : 'de-activated';

        await axios
            .post(ApiUrls.profileActivateTeamAppointmentStatus, passValues)
            .then(response => {

                dispatch(showLoaderReducer(0));

                if (parseInt(response.status) === 201) {
                    if (parseInt(response.data.success) === 1) {

                        let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                        if (divertReplyInfo !== null) {

                            divertReplyInfo = JSON.parse(divertReplyInfo);
                            divertReplyInfo = {
                                ...divertReplyInfo,
                                appointment_status: appointment_status.toString()
                            };

                            localStorage.setItem(
                                process.env.REACT_APP_session_divert_reply,
                                JSON.stringify(divertReplyInfo)
                            );

                            dispatch(passConversListReducer(divertReplyInfo));

                            Utilities.messagePopup('success', response.data.successMessage);
                            Utilities.messagePopup('success', `appointment request has been ${statusLabel} successfully.`);
                        }

                        handleClosePopup();
                        history.push(`/${UrlSlugs.dashboard}`);

                    } else {
                        Utilities.messagePopup('success', response.data.errorMessage);
                    }

                } else {
                    Utilities.messagePopup('error', errorMsg);
                }

            })
            .catch((err) => {
                Utilities.messagePopup('error', errorMsg);
                dispatch(showLoaderReducer(0));
            });

    }

    const handleAppointmentStatus = () => {

        let statusLabel = 'activate';
        let appointment_status = 1;

        if (divertReplyInfo.hasOwnProperty('appointment_status') && parseInt(divertReplyInfo.appointment_status || 0) === 1) {
            statusLabel = 'de-activate';
            appointment_status = 0;
        }

        confirmAlert({
            title: '',
            message: `are you sure to wish to ${statusLabel} appointment request?`,
            overlayClassName: 'normal-font-style',
            buttons: [
                { label: 'No', onClick: () => { } },
                {
                    label: 'yes',
                    onClick: () => { submitAppointmentStatus(appointment_status); }
                }
            ]
        });
    }

    const handleRestrictAccessStatus = () => {
        let statusLabel = 'activate';
        let restrict_status = 1;

        if (divertReplyInfo.hasOwnProperty('restrict_access_status') && parseInt(divertReplyInfo.restrict_access_status || 0) === 1) {
            statusLabel = 'de-activate';
            restrict_status = 0;
        }

        confirmAlert({
            title: '',
            message: `are you sure to wish to ${statusLabel} restrict access?`,
            overlayClassName: 'normal-font-style',
            buttons: [
                { label: 'No', onClick: () => { } },
                {
                    label: 'yes',
                    onClick: () => {
                        submitRestrictAccessStatus(restrict_status);

                    }
                }
            ]
        });
    }

    const submitRestrictAccessStatus = async (restrict_status = 0) => {

        const passValues = {
            api_token: TOKEN_SESSION.toString(),    // TOKEN.toString(),
            account_id: loggedinAccount.account_id.toString(),
            flag: restrict_status.toString()
        }

        //console.log('submitRestrictAccessStatus');
        //console.log(ApiUrls.profileActivateTeamRestrictAccess);
        //console.log(passValues);

        dispatch(showLoaderReducer(1));

        let statusLabel = parseInt(restrict_status || 0) === 1 ? 'enabled' : 'removed';

        await axios
            .post(ApiUrls.profileActivateTeamRestrictAccess, passValues)
            .then(response => {

                dispatch(showLoaderReducer(0));

                if (parseInt(response.status) === 201) {
                    if (parseInt(response.data.success) === 1) {

                        let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                        if (divertReplyInfo !== null) {

                            divertReplyInfo = JSON.parse(divertReplyInfo);
                            divertReplyInfo = {
                                ...divertReplyInfo,
                                restrict_access_status: restrict_status.toString()
                            };


                            localStorage.setItem(
                                process.env.REACT_APP_session_divert_reply,
                                JSON.stringify(divertReplyInfo)
                            );

                            dispatch(passConversListReducer(divertReplyInfo));
                            Utilities.messagePopup('success', `restrict access successfully ${statusLabel}`);
                        }

                        handleClosePopup();
                        history.push(`/${UrlSlugs.dashboard}`);

                    } else {
                        Utilities.messagePopup('success', response.data.errorMessage);
                    }

                } else {
                    Utilities.messagePopup('error', errorMsg);
                }
            })
            .catch((err) => {
                Utilities.messagePopup('error', errorMsg);
                dispatch(showLoaderReducer(0));
            });

    }



    const logoutuser = () => {
        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wantToLogout,
            overlayClassName: 'normal-font-style',
            buttons: [
                { label: 'No', onClick: () => { } },
                {
                    label: 'yes',
                    onClick: () => {
                        handleClosePopup();
                        PagesAuthentication.logoutUser();
                        history.push('/');
                    }
                }
            ]
        });
    }

    const nameByAccountType = (profile) => {

        let name = profile.name ?? '', logo = profile.img ?? '', label = '';
        const accountType = (profile.account_type ?? '0').toString();

        label = Utilities.accountTypeCustomLabels(profile);

        if (['-1', '4'].includes(accountType)) {
            if (profile.hasOwnProperty('company_detail')) {
                name = profile.company_detail.hasOwnProperty('company_display_name') ? profile.company_detail.company_display_name : profile.company_detail.company_name;
                logo = Utilities.formatProfileDetail(profile, 1).img;

                if (accountType === '-1') {
                    label = profile.company_detail.hasOwnProperty('category_name') ? profile.company_detail.category_name : '';
                    logo = profile.company_detail.hasOwnProperty('company_logo') ? profile.company_detail.logo : '';
                }
            }
        }



        logo = typeof logo.split('/').pop() !== 'undefined' ? logo : DEFAUL_AVATAR;

        return { name: name, logo: logo, label: label };
    }


    const handleOnClickSettingNavigations = async (actionName = '') => {
        actionName = actionName.toLowerCase();

        if (actionName === 'automated-reply') {
            handleClosePopup();
            dispatch(toggleAutomatedReply(new Date()));

        } else if (actionName === 'divert') {
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_divertProfiles_assign}/${btoa(loggedinAccount.account_id)}`);

        } else if (actionName === 'default-message') {
            handleClosePopup();
            dispatch(toggleDepartmentDefaultMessageReducer(new Date()));

        } else if (actionName === 'form') {
            handleClosePopup();
            dispatch(toggleDepartmentFormsReducer(new Date()));

        } else if (actionName === 'special-message') {
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_specialMessages}`);

        } else if (actionName === 'document-library') {
            handleClosePopup();
            history.push(`/${UrlSlugs.documents}`);

        } else if (actionName === 'qrcode') {
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_shareQrCode}`);

        } else if (actionName === 'calendar-diary') {
            handleClosePopup();
            history.push(`/${UrlSlugs.calendarDiary}`);

        } else if (actionName === 'profiles') {
            setSlideProfiles(true);
            setActiveSlide(0);

        } else if (actionName === 'linked-accounts') {
            setSlideLinkedProfiles(true);
            setActiveSlide(0);

        } else if (actionName === 'create-company') {

            // DELETE CAMPAIN DETAIL IF AVAILABLE TO AVOID COMFLICTS
            SessionUtilities.deleteSignupCampaign();
            SessionUtilities.removeSignupCampaignDownload();


            let requestCompanyCreationStatus = await checkingPermissionToRegisterCompany();
            requestCompanyCreationStatus = requestCompanyCreationStatus.toString();

            // 1 : send request, 2 : create comp, 3 : request already
            if (["0", "1"].includes(requestCompanyCreationStatus)) {

                confirmAlert({
                    title: 'alert',
                    message: TitlesLabels.alertMessages.warningMessageCompanyCreation,
                    overlayClassName: 'normal-font-style warning-message',
                    buttons: [
                        { label: 'No', onClick: () => { setShowCompanyRequestPopup(false); } },
                        { label: 'yes', onClick: () => { setShowCompanyRequestPopup(true); } }
                    ]
                });

                return false;

            } else if (["3"].includes(requestCompanyCreationStatus)) {
                Utilities.messagePopup('info', TitlesLabels.alertMessages.companyRequestAlreadySent ?? '');
                return false;
            }

            handleClosePopup();
            history.push(`/${UrlSlugs.signup_companyInformation}`);

        } else if (actionName === 'create-company-multi') {
            handleClosePopup();
            history.push(`/${UrlSlugs.companyBranch_informatioDetail}`);


        } else if (actionName === 'location') {
            handleClosePopup();
            dispatch(changeCityPopupAction(Utilities.cityLocationLabel));

        } else if (actionName === 'languages') {
            handleClosePopup();
            dispatch(showLanguageSelectionPopup(Date.now()));

        } else if (actionName === 'important-information') {
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_importantInformation}`);

        } else if (actionName === 'bizcom-assist') {
            handleClosePopup();
            history.push(`/${UrlSlugs.settings_bizcomAssist}`);

        } else if (actionName === 'company-group-shortcut') {
            handleClosePopup();
            dispatch(booleanShowCompanyGroupShortcut(true));

        } else if (actionName === 'notification-management') {

            let linkProvided = UrlSlugs.settings_followersNotices;
            if (loggedinAccount.hasOwnProperty('follow_status')) {
                if (parseInt(loggedinAccount.follow_status ?? 0) >= 1) {
                    linkProvided = UrlSlugs.settings_notificationManagement;
                }
            }

            handleClosePopup();
            history.push(`/${linkProvided}`);

        } else if (actionName === 'department-bulk-message') {

            handleClosePopup();
            history.push(`/${UrlSlugs.deepartment_bulkMessage}`);

        }/* else if ( actionName==='talk-to-us' ){
            window.open( Utilities.talkToUsUrl, '_blank');

        }else if ( actionName==='bizcom247' ){
            window.open( Utilities.talkToUsUrl, '_blank');
        } */
    }


    const checkingPermissionToRegisterCompany = async () => {
        let returns = '0';
        const masterProfileSession = PagesAuthentication.getMasterUserDetailDetail();

        if (Object.keys(masterProfileSession).length > 0) {
            const passval = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: (masterProfileSession.account_id ?? '0').toString()
            }
            returns = await checkPermissionToRegisterCompany(passval);
        }

        return returns;

    }


    const handleCloseCompanyCreationPopUp = async () => {
        setShowCompanyRequestPopup(false);
    }

    const handleSubmitCompanyCreationPopUp = async () => {
        setShowCompanyRequestPopup(false);
        handleClosePopup();
    }


    const setupMenuSettings = () => {
        const menu_setting = SessionUtilities.getLocalSessionMenuSettingsStatus() ?? {};

        if (Object.keys(menu_setting).length > 0) {
            setDivertReplyInfo(menu_setting);
            //SessionUtilities.setLocalSessionMenuSettingsStatus(menu_setting);
        } 

    }

    useEffect(() => {

        setupMenuSettings();

        return () => {
            setShowPopup(false);
            setMainSettingsStatus(false);
            dispatch(showMainSettingsReducer(''));
            setSlideProfiles(false);
            setSlideLinkedProfiles(false);
            setActiveSlide(0);
            setLoggedinAccount({});
            setShowCreateCompanyOption(false);
        };

    }, []);



    useEffect(() => {
        setupProfiles();

    }, [reloadProfile]);

    useEffect(() => {

        setupProfiles();
        setActiveSlide(0);

        if (Object.keys(passConversList).length > 0) {
            setupMenuSettings();
        }

    }, [passConversList]);



    useEffect(() => {

        setSlideProfiles(false);
        setSlideLinkedProfiles(false);
        setActiveSlide(0);
        setShowCompanyRequestPopup(false);

        if (!Utilities.isEmpty(showMainSettings.toString())) {
            setShowPopup(true);
            setMainSettingsStatus(true);

            // CHECKING COMPANY CREATION
            //checkingPermissionToRegisterCompany();


        } else {
            setShowPopup(false);
            setMainSettingsStatus(false);
        }


        /* setShowPopup(true);
        setMainSettingsStatus(true); */

        let allowShareQR = false;
        let loginPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_login);

        if (loginPersonalInfo !== null) {
            loginPersonalInfo = JSON.parse(loginPersonalInfo);

            setLoggedinAccount(loginPersonalInfo);

            if (parseInt(loginPersonalInfo.account_type) === 1) {
                allowShareQR = true;

            } else {
                /* if ( loginPersonalInfo.hasOwnProperty('team_is_admin') ){
                    const team_is_admin = loginPersonalInfo.team_is_admin;
    
                    if ( parseInt(team_is_admin)===1 && parseInt(loginPersonalInfo.flag)===4 ){
                        allowShareQR = true;
                    }
                } */

                if (loginPersonalInfo.hasOwnProperty('company_detail')) {
                    allowShareQR = true;
                }
            }
        }

        setAllowShareQRCode(allowShareQR);

        setupMenuSettings();

        return () => {
            setLoggedinAccount({});
        }

    }, [showMainSettings]);



    return (

        mainSettingsStatus === true &&
        <>
            <Modal id="popup-setting-profiles" show={showPopup} onHide={handleClosePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="lg" dialogClassName="popup-setting-profiles modal-dialog-theme-black" backdropClassName="modal-backdrop-gray" keyboard={true}>
                <Modal.Header closeButton className='border-0' style={{ paddingBottom: 8 }}></Modal.Header>
                <Modal.Body className="px-4 pt-5 pb-0 position-relative overflow-hidden mt-n5">

                    <div className="text-lowercase">

                        <section className={`row mx-auto font-gotham-book fs-18  px-0 ${(slideProfiles === true || slideLinkedProfiles === true) ? 'setting-section-profile' : ''}`} >

                            <div className='col-md-6'>

                                <div className="font-gotham-bold fs-20 pb-4 w-100 pl-2">{(loggedinAccount.group_id ?? 0) > 0 ? 'department' : Utilities.accountTypeLabels(loggedinAccount.account_type ?? 0)}</div>

                                <ul className="settings-inline-navigations">

                                    {/************ WORK ACCOUNT ************/}
                                    {
                                        (loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type) === 4 && !(loggedinAccount.hasOwnProperty('group_id'))) &&
                                        <>
                                            <li className='has-checked' onClick={() => handleAppointmentStatus()}>
                                                <div className={`menu-icon calendar-icon ${(divertReplyInfo.hasOwnProperty('appointment_status') && parseInt(divertReplyInfo.appointment_status || 0) === 1) ? 'active-menu' : ''}`}></div>
                                                <div>appointment request</div>
                                            </li>

                                            <li className='has-checked' onClick={() => handleOnClickSettingNavigations('automated-reply')}>
                                                <div className={`menu-icon reply-icon ${(divertReplyInfo.hasOwnProperty('auto_replay_status') && parseInt(divertReplyInfo.auto_replay_status || 0) === 1) ? 'active-menu' : ''}`}></div>
                                                <div>automated reply</div>
                                            </li>

                                            <li className='has-checked' onClick={() => handleOnClickSettingNavigations('divert')}>
                                                <div className={`menu-icon divert-icon ${(divertReplyInfo.hasOwnProperty('divert_status') && parseInt(divertReplyInfo.divert_status || 0) === 1) ? 'active-menu' : ''}`}></div>
                                                <div>divert</div>
                                            </li>

                                            <li className='has-checked' onClick={() => handleRestrictAccessStatus()}>
                                                <div className={`menu-icon lock-icon ${(divertReplyInfo.hasOwnProperty('restrict_access_status') && parseInt(divertReplyInfo.restrict_access_status || 0) === 1) ? 'active-menu' : ''}`}></div>
                                                <div>restrict access</div>
                                            </li>

                                        </>
                                    }

                                    {/************ PERSONAL ACCOUNT ************/}
                                    {
                                        (loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type) === 1) &&
                                        <>
                                            <li className='has-checked' onClick={() => handleOnClickSettingNavigations('automated-reply')}>
                                                <div className={`menu-icon reply-icon ${(divertReplyInfo.hasOwnProperty('auto_replay_status') && parseInt(divertReplyInfo.auto_replay_status || 0) === 1) ? 'active-menu' : ''}`}></div>
                                                <div>automated reply</div>
                                            </li>
                                        </>
                                    }

                                    {/************ SWITCH TO DEPARTMENT ************/}
                                    {
                                        (loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type) === 4 && loggedinAccount.hasOwnProperty('group_id')) &&
                                        <>
                                            <li className='has-checked' onClick={() => handleOnClickSettingNavigations('default-message')}>
                                                <div className={`menu-icon comment-favorite-icon ${(divertReplyInfo.hasOwnProperty('depart_message') && parseInt(divertReplyInfo.depart_message.depart_message_status || 0) === 1) ? 'active-menu' : ''}`}></div>
                                                <div>default message</div>
                                            </li>

                                            <li className='has-checked' onClick={() => handleOnClickSettingNavigations('form')}>
                                                <div className={`menu-icon form-icon ${(divertReplyInfo.hasOwnProperty('depart_form') && parseInt(divertReplyInfo.depart_form.depart_form_status || 0) === 1) ? 'active-menu' : ''}`}></div>
                                                <div>form</div>
                                            </li>
                                        </>
                                    }

                                    <li className='has-checked' onClick={() => handleOnClickSettingNavigations('special-message')}>
                                        <div className={`menu-icon special-messages-icon  ${(divertReplyInfo.hasOwnProperty('save_msg_status') && parseInt(divertReplyInfo.save_msg_status || 0) === 1) ? 'active-menu' : ''}`}></div>
                                        <div>saved frequent message</div>
                                    </li>

                                    <li className='has-checked' onClick={() => handleOnClickSettingNavigations('document-library')}>
                                        <div className={`menu-icon document-library-icon ${(divertReplyInfo.hasOwnProperty('document_status') && parseInt(divertReplyInfo.document_status || 0) === 1) ? 'active-menu' : ''}`}></div>
                                        <div>document library</div>
                                    </li>

                                    <li className='has-checked' onClick={() => handleOnClickSettingNavigations('calendar-diary')}>
                                        <div className={`menu-icon calendar-diary-icon ${(divertReplyInfo.hasOwnProperty('appointment_status') && parseInt(divertReplyInfo.appointment_status || 0) === 1) ? 'active-menu' : ''}`}></div>
                                        <div>appointments / reservations</div>
                                    </li>



                                    {/************ WORK ACCOUNT ************/}
                                    {
                                        (loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type) === 4 && !(loggedinAccount.hasOwnProperty('group_id'))) &&
                                        <>
                                            {menuProductButtonHtml(loggedinAccount)}
                                            {notificationManagementHtml(loggedinAccount)}
                                        </>
                                    }

                                    {
                                        allowShareQRCode &&
                                        <li onClick={() => handleOnClickSettingNavigations('qrcode')}>
                                            <div className="menu-icon qr-icon"></div>
                                            <div>qr code</div>
                                        </li>
                                    }

                                    {
                                        ((divertReplyInfo.hasOwnProperty('group_info') && parseInt(divertReplyInfo.group_info.group_info_status || 0) === 1) && !loggedinAccount.hasOwnProperty('group_id')) &&
                                        <li onClick={() => handleOnClickSettingNavigations('company-group-shortcut')}>
                                            <div className={`menu-icon people-signal`}></div>
                                            <div>group shortcut</div>
                                        </li>
                                    }



                                    {/************ SWITCH TO DEPARTMENT ************/}
                                    {
                                        (loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type) === 4 && loggedinAccount.hasOwnProperty('group_id')) &&
                                        <li onClick={() => handleOnClickSettingNavigations('department-bulk-message')}>
                                            <div className={`menu-icon horn-icon`}></div>
                                            <div>bulk message</div>
                                        </li>
                                    }

                                </ul>

                            </div>


                            <div className='col-md-6 pl-5'>
                                <div className="font-gotham-bold fs-20 pb-4 w-100 pl-2">system settings</div>

                                <ul className={`settings-inline-navigations ${loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type) === 4 ? '' : 'setting-personal'}`}>

                                    <li onClick={() => handleOnClickSettingNavigations('profiles')}>
                                        <div className="menu-icon user-icon"></div>
                                        <div>profiles</div>
                                    </li>

                                    <li onClick={() => handleOnClickSettingNavigations('linked-accounts')}>
                                        <div className="menu-icon linked-profiles-icon"></div>
                                        <div>linked accounts</div>
                                    </li>

                                    <li onClick={() => handleOnClickSettingNavigations('location')}>
                                        <div className="menu-icon location-icon"></div>
                                        <div>location</div>
                                    </li>

                                    <li onClick={() => handleOnClickSettingNavigations('languages')}>
                                        <div className="menu-icon languages-icon"></div>
                                        <div>languages</div>
                                    </li>

                                    {
                                        (loggedinAccount.hasOwnProperty('account_type') && parseInt(loggedinAccount.account_type) === 1) &&
                                        <>
                                            <li onClick={() => setShowCreateCompanyOption(!showCreateCompanyOption)}>
                                                <div className="menu-icon building-icon"></div>
                                                <div>create company</div>
                                            </li>
                                            {
                                                showCreateCompanyOption &&
                                                <>
                                                    <li className='py-0' onClick={() => handleOnClickSettingNavigations('create-company')}>
                                                        <div className="menu-icon"></div>
                                                        <div>single company</div>
                                                    </li>

                                                    <li className='py-0' onClick={() => handleOnClickSettingNavigations('create-company-multi')}>
                                                        <div className="menu-icon"></div>
                                                        <div>multi branch company</div>
                                                    </li>
                                                </>
                                            }

                                        </>
                                    }


                                    {/* <li onClick={ () => handleOnClickSettingNavigations('important-information') }>
                                        <div className="menu-icon important-info-icon"></div>
                                        <div>importantinformation</div>
                                    </li> */}

                                    <li onClick={() => handleOnClickSettingNavigations('bizcom-assist')}>
                                        <div className="menu-icon bizcom-assist-icon"></div>
                                        <div>{siteTitle} assist</div>
                                    </li>

                                    {/* <li onClick={ () => handleOnClickSettingNavigations('talk-to-us') }>
                                        <div className="menu-icon globe-icon"></div>
                                        <div>talk to us</div>
                                    </li>

                                    <li onClick={ () => handleOnClickSettingNavigations('bizcom247') }>
                                        <div className="menu-icon comment-icon"></div>
                                        <div>bizcom 247</div>
                                    </li> */}

                                </ul>



                            </div>


                            <div className=' mt-5 position-relative  w-100'>
                                <div className="logout-user-wrap font-gotham-book fs-16 d-flex align-items-center justify-content-center text-center">
                                    <div className="logout-user-nav" onClick={() => logoutuser()}>
                                        <div className="menu-icon power-icon mr-1"></div>
                                        <div>logout</div>
                                    </div>
                                </div>
                            </div>

                        </section>


                        <section className={`font-gotham-book fs-14-important profile-navigation-content text-black ${slideProfiles === true ? 'opened' : ''}`}>

                            {
                                slideProfiles === true &&
                                <>

                                    <div className="font-gotham-bold fs-25 text-center pb-4 pt-4 mt-2 mb-3 w-100 position-relative">
                                        profiles
                                        <div className="btn-close" onClick={() => setSlideProfiles(false)}></div>
                                    </div>

                                    {
                                        parseInt(Object.keys(listAccounts).length) > 1 &&
                                        <div className="theme-slider-arrows px-5">
                                            <div className="slide-arrow">
                                                <div className={`slide-arrow-left ${parseInt(activeSlide) === 0 ? 'opacity-025' : ''}`} onClick={() => sliderPrev(`profiles`)}></div>
                                                <div className={`slide-arrow-right ${parseInt(sliderCounterArr.slice(-1)[0]) === parseInt(activeSlide) ? 'opacity-025' : ''} `} onClick={() => sliderNext(`profiles`)}></div>
                                            </div>
                                        </div>
                                    }

                                    <div className="profile-list-wrap col-10 mx-auto position-relative pt--4 overflow-hidden font-gotham-ligh-14">
                                        {
                                            Object.entries(listAccounts).map(([keyAcct, listAccountPage]) =>
                                                <ul key={keyAcct} className={`list-profile-linked  text-center fade-in ${parseInt(activeSlide) === parseInt(keyAcct) ? 'active' : ''}`}>
                                                    {
                                                        Object.entries(listAccountPage).map(([key, row]) =>
                                                            <li key={key}>

                                                                <div className="dropdown-linked-profile cursor-auto">
                                                                    {/*  <div className={`logo-size-100 fs-22-important mx-auto mb-3`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage( nameByAccountType(row).logo ) })`, backgroundColor : Utilities.bgcolorHideInitialBg( nameByAccountType(row).logo )  }}>{ Utilities.showHideInitial( nameByAccountType(row).logo ,  row.name ) }</div> */}
                                                                    <CommonProfileLogo classAttr={`logo-size-100 fs-22-important mx-auto mb-3`} profileDetail={{ ...row, img: nameByAccountType(row).logo }} />
                                                                    <div className="font-gotham-bold fs-18 px-3 ellipsis-1">{nameByAccountType(row).name}</div>
                                                                    <div className="font-gotham-book fs-15  px-3 pt-1 ellipsis-1 color-theme-grey">{nameByAccountType(row).label}</div>

                                                                    <div className="list-subprofile-linked mt-3">
                                                                        {userMenu(row)}
                                                                    </div>

                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            )
                                        }
                                    </div>
                                </>
                            }

                        </section>


                        <section className={`font-gotham-book fs-14-important  profile-navigation-content text-black ${slideLinkedProfiles === true ? 'opened' : ''} `}>

                            {
                                slideLinkedProfiles === true &&
                                <>
                                    <div className="font-gotham-bold fs-25 text-center pb-4 pt-4 mt-2 mb-3 w-100 position-relative">
                                        linked accounts
                                        <div className="btn-close" onClick={() => setSlideLinkedProfiles(false)}></div>
                                    </div>

                                    {
                                        parseInt(Object.keys(linkedAccounts).length) > 1 &&
                                        <div className="theme-slider-arrows px-5">
                                            <div className="slide-arrow">
                                                <div className={`slide-arrow-left ${parseInt(activeSlide) === 0 ? 'opacity-025' : ''}`} onClick={() => sliderPrev(`linked`)}></div>
                                                <div className={`slide-arrow-right ${parseInt(sliderCounterLinkedArr.slice(-1)[0]) === parseInt(activeSlide) ? 'opacity-025' : ''} `} onClick={() => sliderNext(`linked`)}></div>
                                            </div>
                                        </div>
                                    }


                                    <div className="profile-list-wrap col-10 mx-auto position-relative  overflow-hidden font-gotham-ligh-14">
                                        {
                                            Object.entries(linkedAccounts).map(([keyAcct, listAccountPage]) =>
                                                <ul key={keyAcct} className={`list-profile-linked text-center fade-in ${parseInt(activeSlide) === parseInt(keyAcct) ? 'active' : ''}`}>
                                                    {
                                                        Object.entries(listAccountPage).map(([key, row]) =>
                                                            !['-1'].includes(row.account_type.toString()) &&
                                                            <li key={key}>

                                                                <div className="dropdown-linked-profile cursor-auto">
                                                                    {/* <div className={`logo-size-100 fs-22-important mx-auto mb-3`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage( nameByAccountType(row).logo ) })`, backgroundColor : Utilities.bgcolorHideInitialBg( nameByAccountType(row).logo )  }}>{ Utilities.showHideInitial( nameByAccountType(row).logo ,  row.name ) }</div> */}
                                                                    <CommonProfileLogo classAttr={`logo-size-100 fs-22-important mx-auto mb-3`} profileDetail={{ ...row, img: nameByAccountType(row).logo }} />
                                                                    <div className="font-gotham-bold fs-18 px-3 ellipsis-1">{nameByAccountType(row).name}</div>
                                                                    <div className="font-gotham-book fs-15  px-3 pt-1 ellipsis-1 color-theme-grey">{nameByAccountType(row).label}</div>
                                                                    {
                                                                        parseInt(row.account_type) !== 1 &&
                                                                        <div className="list-subprofile-linked mt-3">
                                                                            {
                                                                                Object.entries(subProfiles(row)).map(([key, row]) =>
                                                                                    <div key={key} className="profile-content">
                                                                                        <div className="profile-name">{parseInt(row.type) === 3 ? row.name : Utilities.accountTypeLabels(row.type)}</div>
                                                                                        {teamAssigned(row).indicator === true && <div className="btn-linked-assigned"></div>}
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    }

                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            )
                                        }
                                    </div>
                                </>
                            }

                        </section>

                    </div>

                </Modal.Body>
            </Modal>


            <CompanyCreationRequestPopup showPopup={showCompanyRequestPopup} handleClosePopUp={handleCloseCompanyCreationPopUp} handleSubmit={handleSubmitCompanyCreationPopUp} />
        </>

    );
}

export default SettingProfilePopup;
