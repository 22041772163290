import axios from 'axios';
import ApiUrls from './ApiUrls';




export const getRefferalDetailByAccountId = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            //console.log( ApiUrls.getRefferalDetailByAccountId , passval );
            const responseURL = await axios.post(ApiUrls.getRefferalDetailByAccountId, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}

export const generateGetOwnReferralLink = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.generateGetOwnReferralLink, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}



export const getReferralDetail = async (passval = {}) => {
    let responseDetail = { status: -1 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.getReferralDetail, passval);
            //console.log(responseURL);

            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const updateClicksReferral = async (passval = {}) => {
    let responseDetail = { status: -1 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.refferalUpdateClicksAccount, passval);


            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}