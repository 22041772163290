import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import axios from 'axios';
//import { Dropdown } from 'react-bootstrap';

import Utilities from '../helpers/Utilities';
import PagesAuthentication from '../helpers/PagesAuthentication';
import CitySearchPopup from './../components/popups/CitySearch';
import { uploadProfileLogo } from '../helpers/AWSUpload';
import ApiUrls from '../helpers/ApiUrls';
import TitlesLabels from '../helpers/TitlesLabels';

import { reloadProfile, showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer, showPopupDeleteAccount as showPopupDeleteAccountReducer } from '../actions';

import FieldInput from '../components/FieldInput';
import { blockWordsAndEmailsValidation } from '../helpers/VerificationUtilities';
//import MobileSelection from '../components/MobileSelection';


const ProfileForm = () => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const fieldsChecker = ['first_name', 'last_name', 'country_id', 'state_id', 'country_code', 'city_id', 'mobile_number'];

    let defaultFormValues = {
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        country_id: 0,
        state_id: 0, // dubai
        country_code: 0,
        city_id: '', // dubai
        city_name: '',
    }

    const [accountCounter, setAccountCounter] = useState(0);

    const [formValues, setFormValues] = useState(defaultFormValues);

    const [showCityPopup, setShowCityPopup] = useState(false);
    const [countryList, setCountryList] = useState([]);

    const [cityName, setCityName] = useState(defaultFormValues.city_name);

    const [profileLogo, setProfileLogo] = useState('');
    const [personalAccount, setPersonalAccount] = useState({});
    const [profileLogoValue, setProfileLogoValue] = useState('');

    const [defaultFlag, setDefaultFlag] = useState(''); //process.env.REACT_APP_country_iso);
    const [defaultCountryCode, setDefaultCountryCode] = useState(''); //process.env.REACT_APP_country_ext);

    const handleClosePopUp = () => setShowCityPopup(false);
    const handleShowPopUp = () => setShowCityPopup(true);

    //const [ defaultSearchValue, setDefaultSearchValue ] = useState('');

    const [showMobileMsg, setShowMobileMsg] = useState(false);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0;

        if (fieldName === 'first_name') {
            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ first_name: value } };
        } else if (fieldName === 'last_name') {
            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ last_name: value } };
        } else if (fieldName === 'email') {
            formValuesTemp = { ...formValuesTemp, ...{ email: value } };
        } else if (fieldName === 'country_code') {
            formValuesTemp = { ...formValuesTemp, ...{ country_code: value } };
        } else if (fieldName === 'country_id') {
            formValuesTemp = { ...formValuesTemp, ...{ country_id: value } };
        } else if (fieldName === 'state_id') {
            formValuesTemp = { ...formValuesTemp, ...{ state_id: value } };
        } else if (fieldName === 'city_id') {
            formValuesTemp = { ...formValuesTemp, ...{ city_id: value } };
        } else if (fieldName === 'city_name') {
            formValuesTemp = { ...formValuesTemp, ...{ city_name: value } };
        }

        if (errorCounter === 0) {
            //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
            Utilities.removeFormGroupClass(fieldName);
        }

        setFormValues(formValuesTemp);
    }


    const selectImage = () => {
        jQuery('input#input_select_file').trigger('click');
    }

    const passCityDetail = (passValue) => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ city_id: passValue.id, city_name: passValue.city_name, state_id: passValue.state_id, country_id: passValue.country_id } };
        setFormValues(formValuesTemp);
        handleClosePopUp();

        setCityName(passValue.city_name);
    }


    const previewImage = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            var reader = new FileReader();
            reader.onload = function (e) {

                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setProfileLogo(e.target.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setProfileLogoValue(e.target.files[0]);

        } else {
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    const updateProfile = async () => {

        const button_name = 'btn-update-detail';
        Utilities.elementDisabledStatus(button_name, false);

        let loginPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginPersonalInfo !== null) {

            const formData = new FormData();

            loginPersonalInfo = JSON.parse(loginPersonalInfo);
            let profileLoggedinTemp = personalAccount;

            let errorCounter = 0, responseChecker = false;
            Object.entries(formValues).map(([key, value]) => {
                if (fieldsChecker.includes(key)) {
                    if (Utilities.isEmpty(value)) {
                        //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                        Utilities.addFormGroupClass(key);
                        errorCounter++;
                    }
                }

                if (['first_name', 'last_name'].includes(key)) {
                    responseChecker = Utilities.inputCharacterChecker(value, 'general');
                    if (responseChecker === false) {
                        // jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                        Utilities.addFormGroupClass(key);
                        errorCounter++;
                    }
                }

            });


            if (errorCounter > 0) { return false; }

            formData.append('account_id', personalAccount.account_id);

            Object.entries(formValues).map(([key, row]) => {
                if (fieldsChecker.includes(key)) {
                    formData.append(key, row);
                }
            });


            // RESTRICTED WORDS VALIDATION
            // TYPES : 1=>email, 2=>name, 3=>keywords
            let wordsRequestArray = [];
            wordsRequestArray.push(formValues.first_name);
            wordsRequestArray.push(formValues.last_name);

            Utilities.elementDisabledStatus(button_name, true);
            const responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('2', wordsRequestArray);
            Utilities.elementDisabledStatus(button_name, false);

            if (parseInt(responseRestrictedValidationsDB.status) === 0) {
                Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_name);
                return false;
            }

            // END RESTRICTED WORDS VALIDATION


            const logo_value = document.getElementById('input_select_file').value;
            if (!Utilities.isEmpty(logo_value) && !Utilities.isEmpty(profileLogoValue)) {

                Utilities.elementDisabledStatus(button_name, true);

                const formLogoData = new FormData();
                formLogoData.append('account_id', personalAccount.account_id);
                formLogoData.append('file', profileLogoValue);

                const responseUpload = await uploadProfileLogo(formLogoData);
                Utilities.elementDisabledStatus(button_name, false);

                if (parseInt(responseUpload.status) === 1) {
                    profileLoggedinTemp = { ...profileLoggedinTemp, profile_logo: responseUpload.file_detail.filename, logo_url: responseUpload.file_detail.url_thumb };

                    setProfileLogo(responseUpload.file_detail.url_thumb);
                }
            }


            Utilities.elementDisabledStatus(button_name, true);

            axios
                .post(ApiUrls.profileUdpatePersonalBasic, formData)
                .then(response => {

                    Utilities.elementDisabledStatus(button_name, false);

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus > 0) {
                            Utilities.messagePopup('success', TitlesLabels.alertMessages.profileUpdatedSuccessfully);

                            profileLoggedinTemp = {
                                ...profileLoggedinTemp,
                                first_name: formValues.first_name,
                                last_name: formValues.last_name,
                                country_id: formValues.country_id,
                                state_id: formValues.state_id,
                                country_code: formValues.country_code,
                                city_id: formValues.city_id,
                                mobile_number: formValues.mobile_number
                            };


                            if (parseInt(personalAccount.account_id) === parseInt(loginPersonalInfo.account_id)) {
                                localStorage.setItem(
                                    process.env.REACT_APP_session_login,
                                    JSON.stringify(profileLoggedinTemp)
                                );
                            }

                            setFormValues({ ...profileLoggedinTemp, password: '', password_confirm: '' });


                            // UPDATE PROFILE IN LIST 
                            Utilities.updateProfileInAccountList(profileLoggedinTemp);
                            dispatch(reloadProfile(Date.now()));
                        }

                    } else {
                        Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotUpdateProfile);
                    }
                })
                .catch((err) => {
                    Utilities.messagePopup('error', errorMsg);
                    Utilities.elementDisabledStatus(button_name, false);
                });
        }

    }

    const getCountries = () => {
        axios
            .post(ApiUrls.country, {})
            .then(response => {
                if (response.status === 200) {
                    setCountryList(response.data);
                }
            })
            .catch((err) => { });
    }

    const getCityByID = (id, setup = 0) => {
        if (parseInt(id) > 0) {
            axios
                .post(ApiUrls.cityDetailById, { id: id })
                .then(response => {
                    if (response.status === 200) {
                        if (parseInt(setup) === 1) {
                            setCityName(response.data.city_name);
                        }
                    }
                })
                .catch((err) => { });
        }
    }


    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }


    const handleClickAccountDeactive = () => {

        if (accountCounter > 1) {
            Utilities.messagePopup('warning-account-deactivate no-title', TitlesLabels.general.deactivateAccountWarning);
        } else {
            dispatch(showPopupDeleteAccountReducer(personalAccount))
        }
    }



    useEffect(async () => {
        if (parseInt(countryList.length) > 0) {
            Object.entries(countryList).map(([key, row]) => {
                if (parseInt(formValues.country_code) === parseInt(row.id)) {
                    setDefaultFlag(row.country_short_code.toLowerCase());
                    setDefaultCountryCode(row.phonecode);
                }
            });
        }
    }, [countryList]);


    useEffect(() => {
        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.managePersonalProfile}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        getCountries(1);

        // ASSOCIATED ACCOUNTS
        let accountCounters = 0
        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {

                Object.entries(accountsList).map(([acctid, acctdetail]) => {
                    const account_type = parseInt(acctdetail.account_type);
                    if (account_type === 1) {

                        setPersonalAccount(acctdetail);

                        let accountProfile = Utilities.formatProfileDetail(acctdetail);
                        setProfileLogo(accountProfile.logo_url);

                        getCityByID(parseInt(accountProfile.city_id), 1);

                        accountProfile = { ...accountProfile, password: '', password_confirm: '' };
                        setFormValues(accountProfile);
                    }

                    accountCounters++;
                });
            }
        }

        setAccountCounter(accountCounters);

    }, []);



    return (
        <div className="profile-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => triggerSetting()}>{TitlesLabels.settings}</div>
                            <div>{TitlesLabels.general.profiles}</div>
                            <div className="current">{TitlesLabels.navigations.managePersonalProfile}</div>
                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-person-remove" onClick={() => handleClickAccountDeactive()}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>

                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content">
                <div className="container pt-3">

                    <input type="file" id="input_select_file" name="file" className="invisible" accept={Utilities.attributeImageAcceptExtension} readOnly onChange={(e) => previewImage(e)} ></input>

                    <form id="form-personal-info" className="col-md-9 mx-auto pt-1 text-lowercase form-theme" autoComplete="off">

                        <input type="hidden" id="input_country_code" name="country_code" value={formValues.country_code} onChange={(e) => pushValue(e, 'country_code')} readOnly ></input>
                        <input type="hidden" id="input_city" name="city_id" value={formValues.city_id} onChange={(e) => pushValue(e, 'city_id')} readOnly ></input>
                        <input type="hidden" id="input_state" name="state_id" value={formValues.state_id} onChange={(e) => pushValue(e, 'state_id')} readOnly ></input>
                        <input type="hidden" id="input_country" name="country_id" value={formValues.country_id} onChange={(e) => pushValue(e, 'country_id')} readOnly ></input>

                        <div>


                            <div className='text-center'>
                                <div className={`avatar-preview border logo-size-200 logo-size-sm-200 mx-auto`} style={{ backgroundImage: `url(${profileLogo})` }} >
                                </div>

                                <div className='profile-logo-change'>
                                    <button type="button" className="btn-select-file bg-theme-green font-gotham-bold border-radius text-white outline-none border-0 fs-16 mx-auto mt-3 py-2 px-4" onClick={() => selectImage()}>change</button>
                                    <div className="fs-13-important text-center pt-3 color-theme-grey">{Utilities.labelUploadSpecs}</div>
                                </div>

                            </div>

                            <div className="pt-4"></div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <FieldInput inputName="first_name" inputLabel="First Name" inputValue={formValues.first_name} pushValue={pushValue} checkerType="general" />
                                </div>
                                <div className='col-md-6'>
                                    <FieldInput inputName="last_name" inputLabel="Last Name" inputValue={formValues.last_name} pushValue={pushValue} checkerType="general" />
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-group ">
                                        <input type="email" name="email" className="form-control" placeholder=" " value={formValues.email} disabled autoComplete="off" ></input>
                                        <label className="form-label">Email</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group active ">
                                        <div className="d-flex form-group-mobile opacity-05 cursor-notallow">
                                            <div className="flag-code">
                                                <span className={`flag-icon flag-icon-${defaultFlag} mr-2`}></span>
                                                <span className="fs-14">{!Utilities.isEmpty(defaultCountryCode) ? '+' + defaultCountryCode : ''}</span>
                                            </div>
                                            <input type="number" name="mobile_number" className="form-control" placeholder=" " value={formValues.mobile_number} disabled autoComplete="off"  ></input>
                                        </div>
                                        <label className="form-label">Mobile Number</label>
                                    </div>

                                    {(!Utilities.isEmpty(showMobileMsg) && !Utilities.isEmpty(formValues.mobile_number)) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{showMobileMsg}</div>}

                                </div>

                                <div className='col-md-6'>
                                    <div className="form-group" onClick={() => handleShowPopUp()}>
                                        <input type="text" id="input_city_name" name="city_name" className="form-control text-lowercase cursor-auto" placeholder=" " value={cityName} onChange={(e) => pushValue(e, 'city_name')}></input>
                                        <label className="form-label">City of Residence</label>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="button-wrap text-lowercase text-center mt-5 pb-5">
                            <div className="mb-3">

                                <div className="col-md-6 mx-auto">
                                    <button type="button" id="btn-update-detail" className=" btn-theme-black text-lowercase button-has-loader" onClick={() => updateProfile()} >Update</button>
                                </div>

                            </div>
                        </div>

                    </form>

                </div>
            </div>

            <CitySearchPopup showCityPopup={showCityPopup} handleClosePopUp={handleClosePopUp} passCityDetail={passCityDetail} />

        </div>
    )
}

export default ProfileForm;