import React from 'react';
import { Link } from 'react-router-dom';
import UrlSlugs from '../../helpers/UrlSlugs';


const BusinessBoostCompetition = (props) => {

    const showStatus = props.showStatus ?? false;
    const propsCounter = props.counter ?? 0;

    return (
        showStatus ?
            <div className='boost-competition-wrap  text-white'>
                <div className='container py-3 boost-competition-container'>
                    <div className='py-1'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='font-gotham-medium fs-17 fs-md-17'>business boost competition</div>
                            <Link to={`/${UrlSlugs.competition_businessBoost}`} className='text-white fs-14 fs-md-15'>click here</Link>
                        </div>
                        <div className='fs-14 fs-md-15 d-flex align-items-center justify-content-between'>
                            <div>refer a new business sign up & stand a chance to win <span className="font-gotham-bold">R100 000</span></div>
                            <div>draw chances : <span className='ml-2'>{propsCounter}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            : <></>
    )
}

export default BusinessBoostCompetition;