import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import jQuery from 'jquery';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';

import { toggleDepartmentForms as toggleDepartmentFormsReducer, passConversList } from '../../actions';

import DepartmentFormSamples from './DepartmentFormSamples';
import SessionUtilities from '../../helpers/SessionUtilities';

const DepartmentSettingForms = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const toggleDepartmentForms = useSelector(state => state.toggleDepartmentForms);

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const defaultFormValues = { type: 0 };

    const [showPopup, setShowPopup] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formOriginalValues, setFormOriginalValues] = useState(defaultFormValues);
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [accountDetail, setAccountDetail] = useState({});
    const [showEditButton, setShowEditButton] = useState(false);
    const [loginType, setLoginType] = useState(0);
    const [loginDetail, setLoginDetail] = useState({});

    const [formStatusHold, setFormStatusHold] = useState({});
    const [showFormPopUp, setShowFormPopUp] = useState(false);
    const handleCloseFormPopUp = () => setShowFormPopUp(false);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'type') {
            value = parseInt(formValues.type) === parseInt(value) ? 0 : value;
            formValuesTemp = { ...formValuesTemp, ...{ type: value } };
        }

        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);

    }



    const handleClosePopup = () => {
        setShowPopup(false);
        dispatch(toggleDepartmentFormsReducer(''));
    }

    const handleCancel = () => {
        setIsEdit(false);
        setShowEditButton(true);

        setFormValues(formOriginalValues);
    }

    const getDetail = () => {

        let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
        if (divertReplyInfo !== null) {

            divertReplyInfo = JSON.parse(divertReplyInfo);

            let formValuesTemp = {
                ...formValues,
                type: parseInt(divertReplyInfo.hasOwnProperty('depart_form') ? (divertReplyInfo.depart_form.depart_form_type || 0) : 0),
            };
            setFormValues(formValuesTemp);
            setFormOriginalValues(formValuesTemp);
        }
    }


    const submitInfo = async (formValuesTemp = formValues) => {

        if (parseInt(accountDetail.account_id) > 0) {

            const departmentId = accountDetail.hasOwnProperty('group_id') ? accountDetail.group_id.toString() : '0';
            const TOKEN_SESSION = SessionUtilities.getSessionToken();
            const passValues = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: accountDetail.account_id.toString(),
                group_id: departmentId.toString(),
                flag: formValuesTemp.type.toString(),
            }

            //console.log('submitInfo');
            //console.log(ApiUrls.profileDepartmentFormStatus);
            //console.log(passValues);

            const button_name = 'btn-submit-department-form';

            Utilities.elementDisabledStatus(button_name, true);

            await axios
                .post(ApiUrls.profileDepartmentFormStatus, passValues)
                .then(response => {

                    Utilities.elementDisabledStatus(button_name, false);

                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {


                            let divertReplyInfo = localStorage.getItem(process.env.REACT_APP_session_divert_reply);
                            if (divertReplyInfo !== null) {

                                divertReplyInfo = JSON.parse(divertReplyInfo);
                                divertReplyInfo = {
                                    ...divertReplyInfo,
                                    depart_form: {
                                        ...divertReplyInfo.depart_form,
                                        depart_form_status: parseInt(formValuesTemp.type) === 0 ? '0' : '1',
                                        depart_form_type: formValuesTemp.type.toString()
                                    }
                                };


                                localStorage.setItem(
                                    process.env.REACT_APP_session_divert_reply,
                                    JSON.stringify(divertReplyInfo)
                                );

                                dispatch(passConversList(divertReplyInfo));

                                Utilities.messagePopup('success', response.data.successMessage);

                            }

                            setShowPopup(false);

                            //Utilities.messagePopup('success', response.data.successMessage);

                            history.push(`/${UrlSlugs.dashboard}`);

                        } else {
                            Utilities.messagePopup('error', response.data.errorMessage);
                        }
                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }
                })
                .catch((err) => {
                    Utilities.elementDisabledStatus(button_name, false);
                    Utilities.messagePopup('error', errorMsg);
                });

        }

    }


    const handleFormType = (detail) => {
        const type = parseInt(detail.value);

        if (type === 0) {
            setupFormType(detail);
        } else {

            if (parseInt(formValues.type) === type && loginType === 1 && (parseInt(loginDetail.account_type || 0) === 1 || parseInt(loginDetail.team_is_admin || 0) === 1)) {
                let formValuesTemp = { ...formValues, ...{ type: 0 } };
                setFormValues(formValuesTemp);
            } else {
                setFormStatusHold(detail);
                setShowFormPopUp(true);
            }

        }
    }

    const setupFormType = (detail = {}) => {

        let formValuesTemp = formValues;
        let formStatusHoldTemp = formStatusHold;

        if (Object.keys(detail).length > 0) {
            formStatusHoldTemp = detail;
        }

        formValuesTemp = { ...formValuesTemp, ...{ type: parseInt(formStatusHoldTemp.value) } };
        setFormValues(formValuesTemp);
        setShowFormPopUp(false);

    }


    useEffect(() => {

        setShowEditButton(false);

        if (!Utilities.isEmpty(toggleDepartmentForms)) {

            let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if (loginBasicInfo !== null) {
                loginBasicInfo = JSON.parse(loginBasicInfo);
                setLoginDetail(loginBasicInfo);

                getDetail();
                setShowPopup(true);
                setAccountDetail(loginBasicInfo);

                if (loginBasicInfo.hasOwnProperty('team_is_admin') && parseInt(loginBasicInfo.team_is_admin || 0) === 1) {
                    setShowEditButton(true);
                }

            }

        } else {
            setShowPopup(false);
        }

        // CHECK LOGIN TYPE
        const loginTypeStorage = localStorage.getItem(process.env.REACT_APP_session_logintype);
        const sesstionLoginType = JSON.parse(loginTypeStorage);

        if (sesstionLoginType !== null) {
            setLoginType(parseInt(sesstionLoginType));
        }

        return () => {
            setLoginType(0);
        }

    }, [toggleDepartmentForms]);



    return (
        <>
            <Modal id="popup-department-form-setting" show={showPopup} onHide={handleClosePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" keyboard={true}>
                <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4">forms</Modal.Header>
                <Modal.Body className="pt-2 pb-5">

                    <div className="col-11 px-3 mx-auto  form-theme">

                        <div className={`pb-4 ${parseInt(loginType) === 1 ? ((isEdit === true && showEditButton === false) ? '' : (parseInt(loginDetail.team_is_admin ?? 0) === 0 ? '' : 'content-disabled')) : ''} `} style={{ zIndex: 11 }}>

                            <ul className="checkbox-radio-default checkbox-radio-check-icon mb-4 font-gotham-bold fs-18">
                                {
                                    Object.entries(Utilities.formTypes()).map(([key, row]) =>
                                        <li key={key} className={`d-flex align-items-center justify-content-between py-3  ${parseInt(key) !== (Utilities.formTypes().length - 1) ? 'border-bottom' : ''} `} onClick={() => handleFormType(row)} >
                                            <div>{row.label}</div>
                                            <div className="content-disabled" style={{ zIndex: 1 }}><input type="radio" checked={parseInt(row.value) === parseInt(formValues.type) ? 1 : 0} value={parseInt(row.value)} onChange={(e) => pushValue(e, 'type')} /></div>
                                        </li>
                                    )
                                }
                            </ul>

                        </div>

                        {
                            (loginType === 1 && parseInt(loginDetail.team_is_admin ?? 0) === 1 && isEdit === true && showEditButton === false) &&
                            <div className="button-group-boolean">
                                <button type="button" className=" btn-theme-black" onClick={() => handleCancel()}>cancel</button>
                                <button type="button" id="btn-submit-department-form" className=" btn-theme-black button-has-loader" onClick={() => submitInfo()}>save</button>
                            </div>

                        }

                        {
                            (loginType === 1 && parseInt(loginDetail.team_is_admin ?? 0) === 1 && showEditButton === true) &&
                            <button type="button" className=" btn-theme-black button-has-loader" onClick={() => { setIsEdit(true); setShowEditButton(false); }}>edit</button>
                        }

                    </div>


                </Modal.Body>
            </Modal>


            <DepartmentFormSamples handleCloseFormPopUp={handleCloseFormPopUp} showFormPopUp={showFormPopUp} formTypeDetail={formStatusHold} setupFormType={setupFormType} />
        </>
    )
}

export default DepartmentSettingForms;
