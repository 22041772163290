import React, { useEffect, useState } from 'react';
import jQuery from 'jquery';
import Utilities from '../helpers/Utilities';

const TimePicker = (props) => {

    //const format = 12;

    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [timeFormat, setTimeFormat] = useState('');

    const updatePassTime = () => {

        let timerOut = setTimeout(() => {
            const currentHour = jQuery('#timepicker__hour').val();
            const currentMinute = jQuery('#timepicker__minute').val();
            const currentFormat = jQuery('#timepicker__format').val();

            const returnFormat = `${currentHour}:${currentMinute} ${currentFormat}`;
            props.setRequestTime(returnFormat);

            clearTimeout(timerOut);
        }, 50);
    }

    const updateHour = (hourTemp = 0) => {
        hourTemp = parseInt(hourTemp.toString().length) === 1 ? '0' + hourTemp : hourTemp;
        setHour(hourTemp);

        updatePassTime();
    }

    const updateMinute = (minuteTemp = 0) => {
        minuteTemp = parseInt(minuteTemp.toString().length) === 1 ? '0' + minuteTemp : minuteTemp;
        setMinute(minuteTemp);

        updatePassTime();
    }

    const updateFormat = (formatRequest = '') => {
        setTimeFormat(formatRequest);
        updatePassTime();
    }

    const strPad = (num = 0) => {
        return num.toString().padStart(2, '0');
    }


    useEffect(() => {
        const currentFormattedTime = Utilities.returnTimeFormat();
        const hourTime = currentFormattedTime.split(' ')[0];

        setHour(hourTime.split(':')[0]);
        setMinute(hourTime.split(':')[1]);

        setTimeFormat(currentFormattedTime.split(' ')[1]);

    }, []);


    return (
        <div className="form-theme-timepicker">
            <div className="form-theme-timepicker--content">
                <div>
                    <select id="timepicker__hour" onChange={(e) => updateHour(e.target.value)} >
                        {
                            [...Array(13).keys()].map(inc => <option value={strPad(inc)} selected={strPad(inc) === hour.toString() ? true : false}>{strPad(inc)}</option>)
                        }
                    </select>
                </div>
                <div>
                    <select id="timepicker__minute" onChange={(e) => updateMinute(e.target.value)}>
                        {
                            [...Array(60).keys()].map(min => <option value={strPad(min)} selected={strPad(min) === minute.toString() ? true : false}>{strPad(min)}</option>)
                        }
                    </select>
                </div>
                <div>
                    <select id="timepicker__format" onChange={(e) => updateFormat(e.target.value)}>
                        {
                            ['am', 'pm'].map(format => <option value={strPad(format)} selected={strPad(format) === timeFormat.toString() ? true : false}>{strPad(format)}</option>)
                        }
                    </select>
                </div>
            </div>
        </div>
    )
}

export default TimePicker;
