import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { showHeader as dispatchShowHeader, showFooter as dispatchShowFooter } from '../actions';
import Utilities from '../helpers/Utilities';
import PagesAuthentication from '../helpers/PagesAuthentication';
import UrlSlugs from '../helpers/UrlSlugs';
import { getCompanyBranchesByMasterId } from '../helpers/QueryCompanyBranch';


const QuickLinksAccess = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const loginProfileDetail = PagesAuthentication.getUserLoggedinDetail() ?? {};
    const loginAccountType = parseInt(loginProfileDetail.account_type ?? 0);

    const redirecDashboard = () => {
        history.push(`/${UrlSlugs.dashboard}`);
    };

    const redirectManageBranch = async () => {

        const masterProfileDetail = await PagesAuthentication.getMasterUserDetailDetail();
        const companyBranchesRes = await getCompanyBranchesByMasterId({ master_account_id: masterProfileDetail.account_id });

        if (parseInt(companyBranchesRes.status) === 1) {

            if ((companyBranchesRes.list ?? []).length > 0) {

                const firstBranchDetail = companyBranchesRes.list[0];
                history.push(`/${UrlSlugs.companyBranch_manageBranch}/${btoa(firstBranchDetail.id)}`);

            } else {
                redirecDashboard();
            }
        } else {
            redirecDashboard();
        }


    }

    useEffect(() => {

        const segment = Utilities.getUrlSegments();
        if (segment.length > 0) {

            const slugFirst = segment.pop().toString().toLowerCase();

            if (slugFirst === 'manage-branch') {
                if (loginAccountType === 4) {
                    redirectManageBranch();

                } else {
                    redirecDashboard();
                }
            }
        }


        dispatch(dispatchShowHeader(0));
        dispatch(dispatchShowFooter(0));

    }, [window.location.pathname]);

    return false;

}

export default QuickLinksAccess;