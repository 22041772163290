import { confirmAlert } from 'react-confirm-alert';
import { checkingFollowDailyGlanceStatus, dailyGlanceCompaniesExtraDetail, dailyGlancePremiumDailyAds } from "./QueryDailyGlance";
import TitlesLabels from "./TitlesLabels";
import QueryMasterSearchUtlities from "./QueryMasterSearchUtlities";
import { showDirectoryProfilePopup, showLoader, viewMenuPricePopup } from "../actions";
import { handleClickAds } from "../pages/DailyGlance/DailyGlanceArticleWidgets";
import PagesAuthentication from "./PagesAuthentication";
import Utilities from './Utilities';
import ApiUrls from './ApiUrls';
import axios from 'axios';


let globalDispatch = '', passValues = {}, result_Db = {};
const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();

export const confirmFollowing = async (detail = {}) => {
    if (Object.keys(detail).length > 0) {

        const adsCompanyId = parseInt(detail.ads_company_id ?? 0);

        passValues = {
            account_id: profileLoggedin.account_id.toString(),
            company_id: adsCompanyId.toString(),
            status: "1"
        };

        QueryMasterSearchUtlities.followingStatusUpdate(passValues);
        handleClickAdverstisement(detail, 0, globalDispatch);
    }
}


export const handleClickAdverstisement = async (detail = {}, confirmStatus = 1, dispatch) => {
    confirmStatus = parseInt(confirmStatus);
    globalDispatch = dispatch;


    if (Object.keys(detail).length > 0) {
        const adsFlag = parseInt(detail.ads_flag ?? 0);
        const adsCompanyId = parseInt(detail.ads_company_id ?? 0);

        if (confirmStatus === 1) {
            const isFollowing = await checkingFollowDailyGlanceStatus(adsCompanyId);
            if (!isFollowing) {

                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.confirmMessages.wishToFollowCompany,
                    overlayClassName: 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => handleClickAdverstisement(detail, 0, globalDispatch) },
                        { label: 'continue', onClick: () => confirmFollowing(detail) }
                    ]
                });


                return false;
            }
        }


        if (adsFlag === 1) {

            if (adsCompanyId > 0) {
                globalDispatch(showLoader(1));
                result_Db = await QueryMasterSearchUtlities.getProfileDetail(adsCompanyId);
                globalDispatch(showDirectoryProfilePopup(result_Db.list[adsCompanyId.toString()]));

            }

        } else if (adsFlag === 2) {

            passValues = {
                company_id: adsCompanyId,
                account_id: profileLoggedin.account_id.toString(),
                search_type: 3, // directory search
                types: 'menu-price-detail'
            };

            globalDispatch(showLoader(1));
            result_Db = await dailyGlanceCompaniesExtraDetail(passValues);
            globalDispatch(showLoader(0));

            const menu_detail = result_Db.menu_price_detail ?? {};

            if (Object.keys(menu_detail).length > 0) {

                const fileUrl = menu_detail.file_url ?? '';
                if (Utilities.isEmpty(fileUrl)) { return false; }

                globalDispatch(viewMenuPricePopup({ file_url: fileUrl, new_tab: 1 }));

            }

        } else {
            handleClickAds(detail, globalDispatch);
        }

    }


}
