import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showHeader, showFooter, pageTitle } from '../actions';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';
import SessionUtilities from '../helpers/SessionUtilities';


const CompanyAccount = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(pageTitle(TitlesLabels.siteTitles.businessAccount));
        dispatch(showHeader(0));
        dispatch(showFooter(0));


        // REMOVE WELCOME MESSAGE
        SessionUtilities.removeSignupWelcomeMessage();


    }, []);

    return (
        <div className='signup-page with-footer centerialized   text-lowercase fs-18'>

            <div className='signup-wrap text-center'>
                <SignupHeader title="business account" />


                <div className="col-sm-7 mx-auto pt-3 pt-md-4 px-4 px-sm-0">

                    <div className=" text-size-medium mb-4 text-center ">
                        <div className='color-theme-red font-gotham-bold mb-1'>please note</div>
                        <div className='color-theme-grey-darker font-gotham-book fs-17 fs-md-16'>company accounts can only be created by existing users who have a personal account</div>
                    </div>

                    <div className='text-center font-gotham-bold'>
                        <div className='mb-4'>if you do not have a personal account, please click this button to create one</div>
                        <Link to={`/${UrlSlugs.signup_socialConnect}`} className="btn-theme-green text-lowercase button-has-loader col-sm-9 mx-auto">create account</Link>
                        <div className='py-md-1'></div>

                        <div className='my-4 position-relative'>
                            <span className='bg-white px-2'>or</span>
                            <div className='border-top position-absolute w-100' style={{ zIndex: -1, top: `45%` }}></div>
                        </div>

                        <div className='mb-4'>click below to sign in if you are an existing user</div>
                        <Link t to={`/${UrlSlugs.signup_personalLogin}`} className="btn-theme-black text-lowercase button-has-loader col-sm-9 mx-auto">Sign In</Link>
                    </div>


                </div>

            </div>

            <div className='pb-5  pt-5 pt-md-0'>
                <Link to={`/${UrlSlugs.signup_accountType}`}>{TitlesLabels.general.exitWithoutSaving}</Link>
            </div>

        </div>
    )
}

export default CompanyAccount;