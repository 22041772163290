import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import jQuery from 'jquery';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';

import Utilities from '../helpers/Utilities';
//import PagesAuthentication from '../helpers/PagesAuthentication';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, showLoader as showLoaderReducer } from '../actions';

import MapBox from './../components/MapBox';
import SignupHeader from '../layout/SignupHeader';
import MapSearchPopup from '../components/popups/MapSearchPopup';

const CompanyAddress = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const utilityLocation = Utilities.defaultLocation();
    const select_labels = Utilities.selectLocationLabels;

    const defaultLocation = {
        country_id: utilityLocation.country_id,
        country_name: utilityLocation.country_name,

        state_id: utilityLocation.state_id,
        state_name: utilityLocation.state_name,

        city_id: utilityLocation.city_id,
        city_name: utilityLocation.city_name,

        area_id: utilityLocation.area_id ?? 0,
        area_name: utilityLocation.area_name ?? select_labels.area,
    };

    let defaultFormValues = {
        country_id: defaultLocation.country_id,
        state_id: defaultLocation.state_id,
        city_id: defaultLocation.city_id,
        area_id: defaultLocation.area_id,
        street_name: defaultLocation.street_name ?? '',
        street_number: defaultLocation.street_number ?? '',
        building_name: '',
        building_number: '',
        postal_code: defaultLocation.postal_code ?? '',
        latitude: defaultLocation.latitude ?? 0,
        longitude: defaultLocation.longitude ?? 0,
        address_visibility_status: 0
    }

    let defaultFormLocationValues = {
        ...defaultLocation,
        country_id: defaultLocation.country_id,
        state_id: defaultLocation.state_id,
        city_id: defaultLocation.city_id,
        area_id: defaultLocation.area_id,
    }

    const coordinatesInitial = { lat: defaultLocation.latitude ?? 0, lng: defaultLocation.longitude ?? 0 };

    const [coordinates, setCoordinates] = useState(coordinatesInitial);
    const [mapLocationProvided, setMapLocationProvided] = useState('');
    const [mapLocationAction, setMapLocationAction] = useState(2);  // 1 - from city/suburb, 2 - by coordinates
    const [mapLocationActionPopup, setMapLocationActionPopup] = useState(3);


    const selectCountryRef = useRef();
    const selectStateRef = useRef();
    const selectCityRef = useRef();
    const selectAreaRef = useRef();

    const [formValues, setFormValues] = useState(defaultFormValues);

    const [defaultLocationValues, setDefaultLocationValues] = useState(defaultLocation);
    const [locationFormValues, setLocationFormValues] = useState(defaultFormLocationValues);

    //const [ accountID , setAccountID] = useState(0);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    const [showMapSearchPopup, setShowMapSearchPopup] = useState(false);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let formValuesTemp = formValues;

        const allowFields = ['country_id', 'state_id', 'city_id', 'area_id'];

        //if ( fieldName==='country_id' || fieldName==='state_id' || fieldName==='city_id' || fieldName==='area_id'){
        if (allowFields.includes(fieldName.toString())) {

            //setTimeout( () => {
            //const inputElem = jQuery('input[name="'+fieldName+'"]');
            let locationFormValuesTemp = locationFormValues;

            //if (inputElem.length>0){
            //let value = inputElem.val();
            let value = e.value;

            if (fieldName === 'country_id') {

                getStateByCountryID(e.value);

                const countrySelected = !Utilities.isEmpty(value) ? countryList.filter(country => parseInt(country.value) === parseInt(value))[0]['label'] ?? '' : '';
                locationFormValuesTemp = { ...locationFormValuesTemp, ...{ country_id: e.value, country_name: countrySelected, state_id: 0, state_name: select_labels.state, city_id: 0, city_name: select_labels.city, area_id: 0, area_name: select_labels.area } };


                /* selectStateRef.current.select.clearValue();
                selectCityRef.current.select.clearValue();
                selectAreaRef.current.select.clearValue(); */

            } else if (fieldName === 'state_id') {

                getCityByStateID(value);
                locationFormValuesTemp = { ...locationFormValuesTemp, ...{ state_id: e.value, state_name: e.label, city_name: select_labels.city, area_id: 0, area_name: select_labels.area } };


                /* selectCityRef.current.select.clearValue();
                selectAreaRef.current.select.clearValue(); */


            } else if (fieldName === 'city_id') {

                getAreByCityID(value);
                locationFormValuesTemp = { ...locationFormValuesTemp, ...{ city_id: e.value, city_name: e.label, area_id: 0, area_name: select_labels.area } };

                //console.log(locationFormValuesTemp);
                //selectAreaRef.current.select.clearValue();

                const stateNameSelected = !Utilities.isEmpty(locationFormValues.state_id) ? stateList.filter(state => parseInt(state.value) === parseInt(locationFormValues.state_id))[0]['label'] ?? '' : '';
                const cityNameSelected = !Utilities.isEmpty(value) ? cityList.filter(city => parseInt(city.value) === parseInt(value))[0]['label'] ?? '' : '';
                if (!Utilities.isEmpty(cityNameSelected)) {
                    setMapLocationProvided(stateNameSelected + ',' + cityNameSelected);
                    setMapLocationAction(1);
                }



            } else if (fieldName === 'area_id') {
                locationFormValuesTemp = { ...locationFormValuesTemp, ...{ area_id: e.value, area_name: e.label } };

                const cityNameSelected = !Utilities.isEmpty(locationFormValues.city_id) ? cityList.filter(city => parseInt(city.value) === parseInt(locationFormValues.city_id))[0]['label'] ?? '' : '';
                const areaNameSelected = !Utilities.isEmpty(value) ? areaList.filter(area => parseInt(area.value) === parseInt(value))[0]['label'] ?? '' : '';

                if (!Utilities.isEmpty(areaNameSelected)) {
                    setMapLocationProvided(cityNameSelected + ',' + areaNameSelected);
                    setMapLocationAction(1);
                }
            }

            setDefaultLocationValues(locationFormValuesTemp);
            setLocationFormValues(locationFormValuesTemp);
            //}

            //}, 50);

        } else {
            let value = e.target.value;

            if (fieldName === 'street_name') {
                formValuesTemp = { ...formValuesTemp, ...{ street_name: value } };
            } else if (fieldName === 'street_number') {
                formValuesTemp = { ...formValuesTemp, ...{ street_number: value } };
            } else if (fieldName === 'building_name') {
                formValuesTemp = { ...formValuesTemp, ...{ building_name: value } };
            } else if (fieldName === 'building_number') {
                formValuesTemp = { ...formValuesTemp, ...{ building_number: value } };
            } else if (fieldName === 'postal_code') {
                formValuesTemp = { ...formValuesTemp, ...{ postal_code: value } };
            } else if (fieldName === 'address_visibility_status') {
                value = parseInt(formValuesTemp.address_visibility_status) === 1 ? 0 : 1;
                formValuesTemp = { ...formValuesTemp, ...{ address_visibility_status: value } };
            }
            setFormValues(formValuesTemp);


            if (!Utilities.isEmpty(fieldName) && ['address_visibility_status'] && value === 1) {
                confirmAlert({
                    title: 'warning',
                    message: TitlesLabels.alertMessages.companyAddressVisibilityWarningMessage,
                    overlayClassName: 'normal-font-style warning',
                    buttons: [
                        { label: 'close', onClick: () => confirmAddressVisibility(0) },
                        { label: 'continue', onClick: () => confirmAddressVisibility(1) }
                    ],
                    onClickOutside: () => confirmAddressVisibility(0),
                    onKeypressEscape: () => confirmAddressVisibility(0),
                });
            }
        }

        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);

    }

    const confirmAddressVisibility = (status = 0) => {
        setFormValues({ ...formValues, address_visibility_status: status });
    }

    const getCoordinatesByComplereLocation = async () => {

        let addressArray = [];
        //addressArray.push(formValues.building_number);
        addressArray.push((formValues.building_name ?? '').trim());
        addressArray.push((formValues.street_name ?? '').trim());
        //addressArray.push(mapLocationProvided);

        addressArray.push((defaultLocationValues.area_name ?? '').trim());
        addressArray.push((defaultLocationValues.city_name ?? '').trim());
        addressArray.push((defaultLocationValues.state_name ?? '').trim());
        addressArray.push((defaultLocationValues.country_name ?? '').trim());

        addressArray = addressArray.filter(Boolean);
        const address = addressArray.join(', ');

        setMapLocationProvided(address);
        setMapLocationAction(1);
    }


    const submitAddress = () => {

        let formValuesTemp = formValues;

        const locationFields = ['country_id', 'state_id', 'city_id', 'area_id'];
        const requiredFields = ['country_id', 'state_id', 'city_id'];

        locationFields.map(key => {
            const inputElem = jQuery('input[name="' + key + '"]');
            if (inputElem.length > 0) {
                let value = inputElem.val();
                value = (Utilities.isEmpty(value)) ? 0 : value;
                if (key === 'country_id') {
                    formValuesTemp = { ...formValuesTemp, ...{ country_id: value } };
                } else if (key === 'state_id') {
                    formValuesTemp = { ...formValuesTemp, ...{ state_id: value } };
                } else if (key === 'city_id') {
                    formValuesTemp = { ...formValuesTemp, ...{ city_id: value } };
                } else if (key === 'area_id') {
                    formValuesTemp = { ...formValuesTemp, ...{ area_id: value } };

                    //console.log(defaultLocationValues.area_name);
                    if (parseInt(value) === 0) {

                        if (Utilities.isEmpty(defaultLocationValues.area_name)) {
                            formValuesTemp = { ...formValuesTemp, ...{ area_name: defaultLocationValues.area_name } };
                        } else {
                            if (defaultLocationValues.area_name !== select_labels.area) {
                                formValuesTemp = { ...formValuesTemp, ...{ area_name: defaultLocationValues.area_name } };
                            }
                        }
                    }
                }
            }
        });


        let errorCounter = 0;
        requiredFields.map(key => {
            const value = jQuery('input[name="' + key + '"]').val();
            if (Utilities.isEmpty(value)) {
                //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(key);
                errorCounter++;
            }
        });

        if (errorCounter > 0) { return false; }

        const utcTime = Utilities.currentUTCTime();
        const curretTime = moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());

        formValuesTemp = { ...formValuesTemp, ...{ create_date: curretTime, update_date: curretTime } };

        if (Object.keys(formValuesTemp).length > 0) {

            sessionStorage.setItem(
                process.env.REACT_APP_session_signup_companyaddress,
                JSON.stringify(formValuesTemp)
            );

            history.push(`/${UrlSlugs.signup_companyLogo}`);
        }

    }


    // COUTNRIES
    const getCountries = async () => {
        await axios
            .post(ApiUrls.country, {})
            .then(response => {
                if (response.status === 200) {
                    let optionList = [];
                    Object.entries(response.data).map(([key, row]) => (
                        optionList = [...optionList, { value: row.id, label: row.country_name }]
                    ));
                    setCountryList(optionList);
                }
            })
            .catch((err) => {

            });
    }

    // STATES
    const getStateByCountryID = async (country_id = 0) => {

        if (country_id > 0) {
            await axios
                .post(ApiUrls.stateList, { id: 0, country_id: country_id })
                .then(response => {
                    if (response.status === 200) {
                        let optionList = [];
                        Object.entries(response.data).map(([key, row]) => (
                            optionList = [...optionList, { value: row.id, label: row.state_name }]
                        ));
                        setStateList(optionList);
                    }
                })
                .catch((err) => {

                });
        }
    }

    // CITIES
    const getCityByStateID = async (state_id = 0) => {

        if (state_id > 0) {
            await axios
                .post(ApiUrls.cityList, { id: 0, state_id: state_id })
                .then(response => {
                    if (response.status === 200) {
                        let optionList = [];
                        Object.entries(response.data).map(([key, row]) => (
                            optionList = [...optionList, { value: row.id, label: row.city_name }]
                        ));
                        setCityList(optionList);
                    }
                })
                .catch((err) => {

                });
        }
    }

    // AREA / SUBURB
    const getAreByCityID = async (city_id = 0) => {

        if (city_id > 0) {
            await axios
                .post(ApiUrls.areaList, { id: 0, city_id: city_id })
                .then(response => {
                    if (response.status === 200) {
                        let optionList = [];
                        Object.entries(response.data).map(([key, row]) => (
                            optionList = [...optionList, { value: row.area_id, label: row.area_name }]
                        ));
                        setAreaList(optionList);
                    }
                })
                .catch((err) => {

                });
        }
    }


    const handleCoordinatesRequest = (coodinatesResponse = coordinatesInitial) => {
        let formValuesTemp = { ...formValues, ...{ latitude: coodinatesResponse.lat.toString(), longitude: coodinatesResponse.lng.toString() } };
        setFormValues(formValuesTemp);

        /* if ( coodinatesResponse.hasOwnProperty('change_selection') ){
            if ( coodinatesResponse.change_selection===true ){
                
                formValuesTemp = { ...formValuesTemp, }

                selectStateRef.current.select.clearValue();
                selectCityRef.current.select.clearValue();
                selectAreaRef.current.select.clearValue();

                setTimeout( function (){
                    const newLocation = {
                        ...defaultLocationValues, 
                        city_id : coodinatesResponse.city_id,
                        city_name : coodinatesResponse.city_name,
                        state_id : coodinatesResponse.state_id,
                        state_name :coodinatesResponse.state_name
                    }
                    console.log(newLocation);
                    setDefaultLocationValues(newLocation);
                }, 1000 );
               
                
            }
        } */


    }


    const handleMapChanges = async (locationArray = {}, coordinates = {}) => {

        setShowMapSearchPopup(false);

        //console.log(locationArray);
        if (Object.keys(locationArray).length > 0 && Object.keys(coordinates).length > 0) {


            let profileCompanyTemp = {
                ...formValues,
                country_id: locationArray.country_id ?? 0,
                state_id: locationArray.state_id ?? 0,
                city_id: locationArray.city_id ?? 0,
                area_id: locationArray.area_id ?? 0,

                street_name: locationArray.street_name ?? '',
                street_number: locationArray.street_number ?? '',
                building_name: '',
                building_number: '',
                postal_code: locationArray.postal_code ?? '',

                latitude: locationArray.latitude ?? parseFloat(coordinates.lat || 0),
                longitude: locationArray.longitude ?? parseFloat(coordinates.lng || 0),
            };

            /* selectCountryRef.current.select.clearValue();
            selectStateRef.current.select.clearValue();
            selectCityRef.current.select.clearValue();
            selectAreaRef.current.select.clearValue(); */


            const defaultLocationValuesTemp = {
                ...defaultLocationValues,

                country_name: locationArray.country_name ?? select_labels.country,
                state_name: locationArray.state_name ?? select_labels.state,
                city_name: locationArray.city_name ?? select_labels.city,
                area_name: locationArray.area_name ?? select_labels.area,

                country_id: profileCompanyTemp.country_id,
                state_id: profileCompanyTemp.state_id,
                city_id: profileCompanyTemp.city_id,
                area_id: profileCompanyTemp.area_id,
            }


            dispatch(showLoaderReducer(1));

            await getStateByCountryID(profileCompanyTemp.country_id);
            await getCityByStateID(profileCompanyTemp.state_id);
            await getAreByCityID(profileCompanyTemp.city_id);

            dispatch(showLoaderReducer(0));


            setFormValues(profileCompanyTemp);


            // CHANGE MAP
            setMapLocationAction(0);
            const coordinatesValue = { lat: parseFloat(coordinates.lat || 0), lng: parseFloat(coordinates.lng || 0) };

            let timer = setTimeout(function () {
                setCoordinates(coordinatesValue);
                setMapLocationAction(3);

                setLocationFormValues(defaultLocationValuesTemp);
                setDefaultLocationValues(defaultLocationValuesTemp);
                clearTimeout(timer);
            }, 1000);


        }
    }

    const handleCloseMapSearchPopup = () => {
        setShowMapSearchPopup(false);
    }


    useEffect(() => {

        dispatch(pageTitle(TitlesLabels.siteTitles.companyAddress));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        //PagesAuthentication.getUserLoggedin();

        //dispatch( showLoaderReducer(1) );
        setTimeout(() => {
            setShowMapSearchPopup(true);
            //dispatch( showLoaderReducer(0) );
        }, 1500);


        getCountries();
        getStateByCountryID(defaultLocation.country_id);
        getCityByStateID(defaultLocation.state_id);
        getAreByCityID(defaultLocation.city_id);


        setTimeout(() => {
            const utilityLocationTemp = Utilities.defaultLocation();

            const defaultFormValuesTemp = {
                ...defaultFormValues,
                country_id: utilityLocationTemp.country_id,
                state_id: utilityLocationTemp.state_id,
                city_id: utilityLocationTemp.city_id,
                area_id: utilityLocationTemp.area_id,

                street_name: utilityLocationTemp.street_name ?? '',
                street_number: utilityLocationTemp.street_number ?? '',
                postal_code: utilityLocationTemp.postal_code ?? '',
                latitude: utilityLocationTemp.latitude ?? 0,
                longitude: utilityLocationTemp.longitude ?? 0,
            }

            setFormValues(defaultFormValuesTemp);

            if (parseInt(defaultFormValuesTemp.area_id) === 0) {

                let suburbName = select_labels.area;

                if (!Utilities.isEmpty(utilityLocationTemp.area_name ?? '')) {
                    if ((utilityLocationTemp.area_name ?? '') !== select_labels.area) {
                        suburbName = utilityLocationTemp.area_name;
                    }
                }

                const locationFormValuesTemp = { ...defaultLocationValues, area_name: 0, area_name: suburbName };

                setDefaultLocationValues(locationFormValuesTemp);
                setLocationFormValues(locationFormValuesTemp);
            }

        }, 3000);


        if (!Utilities.isEmpty(defaultLocation.state_name) && !Utilities.isEmpty(defaultLocation.city_name)) {

            setMapLocationProvided(defaultLocation.city_name + ',' + defaultLocation.state_name + ',' + defaultLocation.country_name);
            setMapLocationAction(1);
            setMapLocationActionPopup(1);
        }


    }, []);


    return (
        <div className='signup-page with-footer centerialized centerialized-mobile  text-lowercase fs-18 px-4 px-md-0'>

            <div className='signup-wrap text-center pt-md-5'>
                <SignupHeader title="Company Address" />


                <form className="form-theme  text-left pt-5" autoComplete="off">

                    <div className="mb-4 pb-2 content-disabled position-relative" onClick={() => setShowMapSearchPopup(true)}>
                        <MapBox
                            coordinatesAttributes={coordinates}
                            handleCoordinatesRequest={handleCoordinatesRequest}
                            mapMarkerDraggableAttribues={false}
                            mapMarkerDraggableGetLocation={true}
                            markerTooltip={TitlesLabels.general.dragTheMarkerToSelectlocation}
                            mapLocationActionAttributes={mapLocationAction}
                            mapLocationProvidedAttributes={mapLocationProvided} />
                    </div>


                    <div className="form-col-2 gap-none">

                        <div className="form-group active">
                            <Select options={countryList} ref={selectCountryRef} id="country_id" name="country_id" onChange={(e) => pushValue(e, 'country_id')} placeholder={`${select_labels.country}`} defaultValue={{ value: defaultLocationValues.country_id, label: defaultLocationValues.country_name }} value={{ value: defaultLocationValues.country_id, label: defaultLocationValues.country_name }} className="boostrap-select2" />
                            <label className="form-label">Country</label>
                        </div>

                        <div className="form-group active">
                            <Select options={stateList} ref={selectStateRef} name="state_id" placeholder={`${select_labels.state}`} onChange={(e) => pushValue(e, 'state_id')} defaultValue={{ value: defaultLocationValues.state_id, label: defaultLocationValues.state_name }} value={{ value: defaultLocationValues.state_id, label: defaultLocationValues.state_name }} className="boostrap-select2" />
                            <label className="form-label">State or Province</label>
                        </div>

                        <div className="form-group active">
                            <Select options={cityList} ref={selectCityRef} name="city_id" placeholder={`${select_labels.city}`} onChange={(e) => pushValue(e, 'city_id')} defaultValue={{ value: defaultLocationValues.city_id, label: defaultLocationValues.city_name }} value={{ value: defaultLocationValues.city_id, label: defaultLocationValues.city_name }} className="boostrap-select2" />
                            <label className="form-label">City or Town</label>
                        </div>

                        <div className="form-group optional active">
                            <Select options={areaList} ref={selectAreaRef} name="area_id" placeholder={`${select_labels.area}`} onChange={(e) => pushValue(e, 'area_id')} defaultValue={{ value: defaultLocationValues.area_id, label: defaultLocationValues.area_name }} value={{ value: defaultLocationValues.area_id, label: defaultLocationValues.area_name }} className="boostrap-select2" />
                            <label className="form-label">Suburb</label>
                            <div className="optional-label">Optional</div>
                        </div>

                        <div className="form-group optional">
                            <input type="text" name="street_name" className="form-control" placeholder=" " autoComplete="off" value={formValues.street_name} onChange={(e) => pushValue(e, 'street_name')} onBlur={() => getCoordinatesByComplereLocation()}></input>
                            <label className="form-label">Street Name</label>
                            <div className="optional-label">Optional</div>
                        </div>


                        <div className="form-group optional">
                            <input type="text" name="street_number" className="form-control" placeholder=" " autoComplete="off" value={formValues.street_number} onChange={(e) => pushValue(e, 'street_number')} ></input>
                            <label className="form-label">Street No.</label>
                            <div className="optional-label">Optional</div>
                        </div>

                        <div className="form-group optional">
                            <input type="text" name="building_name" className="form-control" placeholder=" " autoComplete="off" value={formValues.building_name} onChange={(e) => pushValue(e, 'building_name')} onBlur={() => getCoordinatesByComplereLocation()} ></input>
                            <label className="form-label">building or complex name</label>
                            <div className="optional-label">Optional</div>
                        </div>

                        <div className="form-group optional">
                            <input type="text" name="building_number" className="form-control" placeholder=" " autoComplete="off" value={formValues.building_number} onChange={(e) => pushValue(e, 'building_number')} ></input>
                            <label className="form-label">Apt, Office/Unit No.</label>
                            <div className="optional-label">Optional</div>
                        </div>

                        <div className="form-group optional">
                            <input type="text" name="postal_code" className="form-control" placeholder=" " autoComplete="off" value={formValues.postal_code} onChange={(e) => pushValue(e, 'postal_code')} ></input>
                            <label className="form-label">Postal Code</label>
                            <div className="optional-label">Optional</div>
                        </div>

                        <div className="form-group d-flex align-items-center">
                            <div className="form-control text-blue-placehoder">show full address</div>
                            <input type="checkbox" className="checkbox-onoff" name="address_visibility_status" value={formValues.address_visibility_status} checked={formValues.address_visibility_status === 1} onChange={(e) => pushValue(e, 'address_visibility_status')}></input>
                        </div>

                    </div>



                    <div className="button-wraptext-center mt-5">
                        <button type="button" className="btn-theme-black col-4 mx-auto" onClick={() => submitAddress()} >next</button>

                    </div>

                </form>

            </div>

            <div className='pb-5 pt-5 pt-md-0'>
                <div className="mt-4"><Link to={`/${UrlSlugs.signup_companyCondition}`}>{TitlesLabels.general.exitWithoutSaving}</Link></div>
            </div>

            <MapSearchPopup showPopup={showMapSearchPopup} handleClosePopUp={handleCloseMapSearchPopup} title={TitlesLabels.general.companyAddress} applyMapChanges={handleMapChanges} allowSubmssion={true} mapLocationAction={mapLocationActionPopup} />

        </div>
    )
}

export default CompanyAddress;