import React, { useEffect, useState } from 'react';
import jQuery from 'jquery';
import { Link, useHistory } from 'react-router-dom';

import { Dropdown } from 'react-bootstrap';

import TitlesLabels from '../../helpers/TitlesLabels';
import UrlSlugs from '../../helpers/UrlSlugs';
import SessionUtilities from '../../helpers/SessionUtilities';


const BreadcrumbsGlance = (props) => {

    const history = useHistory();
    const props_status = parseInt(props.status ?? 1);

    const [showNav, setShowNav] = useState(1);

    const handleCickViewGlance = () => {
        resetNav();
        history.push(`/${UrlSlugs.notificationDailyGlance}`);
    }

    const handleClickButton = () => {
        resetNav();
        jQuery('.dashboad-page').toggleClass('blocked');
    }


    const resetNav = () => {
        SessionUtilities.setSignupGlanceStatus('0');
    }

    useEffect(() => {
        jQuery('.main-content, .main-header, .footer-app').click(() => {
            resetNav();
            jQuery('.dashboad-page').removeClass('blocked');
            setShowNav(1);
        });

        return () => {
            setShowNav(1);
        }

    }, []);

    useEffect(() => {

        setShowNav(props_status);
        if (props_status === 3) {
            setTimeout(() => {
                jQuery('.dashboad-page').addClass('blocked');
                jQuery('.dropdown-nav-glance').trigger('click');
            }, 500);

        }

    }, [props_status]);

    return (
        <>
            {
                showNav === 1 ?
                    <div className="btn-icon btn-glance-icon" title={TitlesLabels.general.atAGlance.toLowerCase()} onClick={() => handleCickViewGlance()}></div>
                    :

                    <Dropdown className="dropdown-glance-selection">
                        <Dropdown.Toggle variant="dropdown-user-current dropdown-nav-glance btn-icon-wrap" id="dropdown-glance">
                            <div className="btn-icon btn-glance-icon" title={TitlesLabels.general.atAGlance.toLowerCase()} onClick={() => handleClickButton()}></div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropdown-menu-glance'>
                            <Dropdown.Item className={`dropdown-item-glance bg-white`}>
                                <div className="text-center font-gotham-bold my-4 py-2 fs-20 px-5">
                                    <div className='text-theme-green'>lenasia residents</div>
                                    <div className='mt-2 mb-3'>
                                        <div className='mb-2'>
                                            we are excited to announce the<br />launch of our in-app digital<br />newspaper that can be found here
                                        </div>

                                        <div className='d-flex justify-content-center my-4'>
                                            <div className='d-flex justify-content-center'>
                                                <div class="fs-32 title-glance  d-flex align-items-center line-height-1 position-relative" style={{ letterSpacing: '-1.3px;' }}>
                                                    <span class="font-myriad-regular fs-60">@</span><span class="text-theme-green">a</span>glance
                                                </div>
                                                <div className="ml-n2"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-circle.svg`} alt="logo" width="50" /></div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='mb-1'>
                                        <button type="button" className=" btn-theme-black py-3" onClick={() => handleCickViewGlance()}>view</button>
                                    </div>
                                </div>
                            </Dropdown.Item>
                        </Dropdown.Menu>

                    </Dropdown>
            }

        </>
    )

}

export default BreadcrumbsGlance;
