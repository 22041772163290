import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { askPermissionToReceiveNotifications } from '../../components/firebase/PushNotification';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import jQuery from 'jquery';
import CryptoJS from 'crypto-js';
import { isMobile } from 'react-device-detect';

import { showHeader, showFooter, pageTitle, globalNotificationCounter as globalNotificationCounterReducer, booleanShowWelcomeVideoAnimation } from '../../actions';
import PagesAuthentication from '../../helpers/PagesAuthentication';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';
import { sendEmailGeneral } from '../../helpers/SendingEmails';
import { loginDetailTemplate } from '../../helpers/EmailTemplates';

import ForgotPassword from '../../components/popups/ForgotPassword';
import PasswordInput from '../../components/PasswordInput';
import { video_links } from '../../helpers/Constants';
import LoginInputSelection from './LoginInputSelection';
import LoginConnect from './LoginConnect';
import Footer from '../../layout/login/Footer';
import { checkPasswordExistanceCompanyBulkCredential } from '../../helpers/VerificationUtilities';
import SessionUtilities from '../../helpers/SessionUtilities';
//import SessionUtilities from '../helpers/SessionUtilities';


const siteTitle = process.env.REACT_APP_site_title;

const Login = ({ match }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    let marginDeduct = 80, globalNavNameCurrent = 'home';;

    const initialMessage = Utilities.labelInvalidCredentialDetail;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    //const TOKEN_SESSION = SessionUtilities.getSessionToken();
    //const NODE_ENV = process.env.NODE_ENV;

    const loginUserDetail = PagesAuthentication.getUserLoggedinDetail() ?? {};

    const defaultLocation = Utilities.defaultLocation();

    const [formValues, setFormValues] = useState({ account_id: '0', email: '', password: '', fcm_token: '', utc_date: '', brower_id: '', phone_code: defaultLocation.country_id.toString(), phone_number: '' });

    const [defaultResMessage, setDefaultResMessage] = useState({ status: false, msg: initialMessage });
    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const [loginType, setLoginType] = useState(2); // 0: selection, 1: email, 2: mobile

    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
    const [showExtendedInfoPopup, setShowExtendedInfoPopup] = useState(false);

    const [extendedInfoBgColor, setExtendedInfoBgColor] = useState('black');

    const [showMovileNav, setShowMovileNav] = useState(false);
    const [mobileSlidePage, setMobileSlidePage] = useState(1);
    const [mobileSlideStop, setMobileSlideStop] = useState(0); // number if last active slide

    const [popupVideoUrl, setPopupVideoUrl] = useState('');
    const [iframeIsLoading, setIframeIsLading] = useState(false);
    const [extenedInfoContent, setExtenedInfoContent] = useState('');

    let intervalSlide, i = 1,
        extenedInfoArray = {
            'section-why': <div className='extension-info-detail'>
                <p><span className='font-gotham-bold'>{siteTitle}</span> is the first part of an integrated and consolidated transactional ecosystem that connects buyers and sellers to search, find and communicate with each other seamlessly and instantly.</p>
                <p>it recognises that forms of communication are evolving and instant messaging is now the preferred mode of communication.</p>
                <p>it is simple to use and allows end users and the smallest and simplest of businesses to onboard within minutes.</p>
                <p>there is no mass communication tool of its kind globally, and it is revolutionary in the ease with which it connects transacting parties.</p>
                <p>it is embracing of all professionals, all business sizes, and professional services whether they are local, regional, national, or global.</p>
                <p>as an smme, it provides astounding ease to become both digitalised and contactable.</p>
                <p>for larger businesses, it provides for multiple and more targeted points of connectivity.</p>
            </div>,

            'section-consumer': <div className='extension-info-detail'>
                <p>existing customers or potential ones are driven by one fundamental need which is hassle free instant communication to a vendor to inquire, order, book or complain. <span className='font-gotham-bold'>{siteTitle}</span> allows you that.</p>
                <p>like personal instant messaging, business communication should be embraced as part of modern living and done “on the go”. it should not be endless waiting for a call operator or bots</p>
                <p>most importantly, <span className='font-gotham-bold'>{siteTitle}</span> is uncompromising on protecting end users from unsolicited ads and spamming. the right to connect to a business lay exclusively with the consumer and receiving of advertising material is only permitted if they follow a company. they reserve the right to unfollow a company at any time.</p>
                <p>while <span className='font-gotham-bold'>{siteTitle}</span> allows for of both your personal and professional profiles within the same app, it keeps them separate and switches seamlessly between them.</p>

            </div>,

            'section-business': <div className='extension-info-detail'>
                <p>allows a business to customise their profile based on their needs, whether it is a home based business or a multinational organisation.</p>
                <p>no tech sophistication required to create, maintain and manage profile</p>
                <p>business can be found by name, category or keyword.</p>
                <p>large businesses can create direct contact to particular departments or employee customised forms for reservations, appointments, complaints</p>
                <p>targeted marketing to followers gives your business global reach</p>
                <p>a separate internal chat system between company employees</p>
            </div>
        };


    const mobileSlider = () => {
        /* ... logic ... */

        //console.log(i);
        setMobileSlidePage(i);

        if (i >= 7) i = 0;
        if (i < 7) i++;
        // else clearInterval(intervalSlide);

    }


    const handleShowLoginPopUp = () => {

        if (Object.keys(loginUserDetail).length > 0) {
            history.push(`/${UrlSlugs.dashboard}`);
            return false;
        }

        setFormValues({
            ...formValues,
            email: '',
            password: '',
            phone_number: ''
        });
        setLoginType(2);
        setShowLoginPopup(true);

        sessionStorage.removeItem(process.env.REACT_APP_session_login_company_bulk_credential);

        // Utilities.rootFilterBlur(true);
    }

    const handleCloseLoginPopUp = () => {
        setShowLoginPopup(false);
        // Utilities.rootFilterBlur(false);
    }


    const handleShowExtendedInfoPopup = (bgColor = 'black', contentKey = '') => {
        setExtendedInfoBgColor(bgColor);
        setShowExtendedInfoPopup(true);

        if (!Utilities.isEmpty(contentKey)) {
            setExtenedInfoContent(extenedInfoArray[contentKey]);
        }

    }

    const handleCloseExtendedInfoPopup = () => {
        setShowExtendedInfoPopup(false);
    }

    const handleForgotPasswordClosePopUp = () => {
        setForgotPasswordPopup(false);
        // Utilities.rootFilterBlur(false);
    }

    const handleForgotPasswordShowPopUp = () => {
        handleCloseLoginPopUp();

        setForgotPasswordPopup(true);
        // Utilities.rootFilterBlur(true);
    }


    const handleShowVideoPopUp = (videoUrl = '') => {
        setShowVideoPopup(true);
        setIframeIsLading(true);
        setPopupVideoUrl(videoUrl);

        setMobileSlideStop(mobileSlidePage);

        /* setTimeout( () => {
            document.getElementById('video-app-player').addEventListener('ended',videoPopupHandler,false);
        }, 1000); */

        // Utilities.rootFilterBlur(true);
    }

    /*  const videoPopupHandler = (e) => {
         // What you want to do after the event
         handleCloseVideoPopUp();
     } */

    const handleOnLoadIFrame = (boolStatus = false) => {
        setIframeIsLading(boolStatus);
    }

    const handleCloseVideoPopUp = () => {
        setShowVideoPopup(false);
        setMobileSlideStop(0);
        // Utilities.rootFilterBlur(false);
    }


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'email') {
            setFormValues({ ...formValues, ...{ email: value } });
        } else if (fieldName === 'password') {
            setFormValues({ ...formValues, ...{ password: value } });
        } else if (fieldName === 'phone_number') {
            setFormValues({ ...formValues, ...{ phone_number: parseInt(value) || '' } });
        }
    }


    const showResMessageWarning = () => {
        let timer = setTimeout(() => {
            setDefaultResMessage({ status: false, msg: '' });
            clearTimeout(timer);
        }, 2000);
    }

    const submitForm = async (e) => {
        e.preventDefault();

        const btn_name = 'btn-login-user';
        Utilities.elementDisabledStatus(btn_name, false);

        setDefaultResMessage({ status: 0, msg: errorMsg });

        let usernameTemp = parseInt(loginType) === 2 ? formValues.phone_number : formValues.email;
        const userAccountId = parseInt(formValues.account_id ?? 0);


        if (userAccountId === 0) {
            if (Utilities.isEmpty(usernameTemp) || Utilities.isEmpty(formValues.password)) {
                setDefaultResMessage({ status: true, msg: Utilities.labelUsernamePasswordMandatory });
                showResMessageWarning();
                Utilities.elementDisabledStatus(btn_name, false);

                return false;
            }
        }


        Utilities.elementDisabledStatus(btn_name, true);

        const generatedToken = Utilities.generateToken().toString();
        const windowNavigator = window.navigator,
            credentialPasval = {
                ...formValues,
                login_choose: loginType.toString(),
                browser_name: windowNavigator.appVersion.toLowerCase(),
                token_request: generatedToken
            };


        //console.log(ApiUrls.userGetByCredentail, credentialPasval);
        await axios
            .post(ApiUrls.userGetByCredentail, credentialPasval)
            .then(async (response) => {
                const resStatus = response.status;

                Utilities.elementDisabledStatus(btn_name, false);

                if (resStatus === 200) {
                    const resData = response.data;
                    const resStatus = parseInt(resData.status);
                    const resLoginType = parseInt(resData.logintype);

                    if (resStatus === 1) {

                        if (parseInt(resData.detail.status) === 1) {

                            dispatch(booleanShowWelcomeVideoAnimation(true));

                            // TEMPORATY REMOVED BY ADD IN DASHBOARD and DEPARTMENT
                            //localStorage.removeItem(process.env.REACT_APP_fs_fcm_token);

                            // REMOVE WELCOME MESSAGE
                            localStorage.removeItem(process.env.REACT_APP_session_welcome_mesage);

                            SessionUtilities.setSessionToken(generatedToken);

                            localStorage.setItem(
                                process.env.REACT_APP_session_logintype,
                                JSON.stringify(resLoginType)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login,
                                JSON.stringify(resData.detail)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_started,
                                Date.now()
                            );

                            // Utilities.rootFilterBlur(false);
                            jQuery('body').removeClass('mb-0');

                            if (resLoginType === 1) {

                                localStorage.setItem(
                                    process.env.REACT_APP_session_accounts,
                                    JSON.stringify(resData.accounts)
                                );

                                // SENDENG EMAIl LOGIN SECURITY
                                if (parseInt(resData.new_device) === 1) {
                                    let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_session_location);
                                    if (sessionLocation !== null) {
                                        sessionLocation = JSON.parse(sessionLocation);
                                    }

                                    let emailData = { ...resData.detail, location: { ...sessionLocation } };

                                    Utilities.elementDisabledStatus(btn_name, true);
                                    await sendEmailGeneral({
                                        api_token: generatedToken.toString(),
                                        account_id: (PagesAuthentication.getUserLoggedinDetail().account_id ?? '').toString(),
                                        to_email: formValues.email.toString(),
                                        subject: TitlesLabels.general.security_alert.toString(),
                                        message: (await loginDetailTemplate(emailData)).toString()
                                    });
                                    Utilities.elementDisabledStatus(btn_name, false);
                                }

                                history.push(`/${UrlSlugs.dashboard}`);

                            } else if (resLoginType === 2) {

                                localStorage.setItem(
                                    process.env.REACT_APP_session_department_active,
                                    JSON.stringify(resData.detail)
                                );


                                localStorage.setItem(
                                    process.env.REACT_APP_session_company_detail,
                                    JSON.stringify(resData.company_detail)
                                );

                                localStorage.setItem(
                                    process.env.REACT_APP_session_company_departments,
                                    JSON.stringify(resData.departments)
                                );

                                history.push(`/${UrlSlugs.departmentDashboard}`);

                            }

                        } else {
                            setDefaultResMessage({ status: true, msg: Utilities.labelAccountNotActive });
                            showResMessageWarning();
                        }

                    } else if (resStatus === 2) {
                        setDefaultResMessage({ status: true, msg: Utilities.labelCouldNotLoginCompanyAccount });
                        showResMessageWarning();

                    } else {

                        // CHECK IF PASSWORD IS EXIST IN `master_bulk_company_credential_list`
                        const companyBulkCredentialRes = await checkPasswordExistanceCompanyBulkCredential({ password: formValues.password ?? '' });

                        if (Object.keys(companyBulkCredentialRes).length > 0) {

                            if (parseInt(companyBulkCredentialRes.status) === 1) {

                                sessionStorage.setItem(
                                    process.env.REACT_APP_session_login_company_bulk_credential,
                                    JSON.stringify(companyBulkCredentialRes.detail)
                                );

                                history.push(`/${UrlSlugs.signup_socialConnect}`);

                            } else {
                                setDefaultResMessage({ status: true, msg: initialMessage });
                                showResMessageWarning();
                            }

                        } else {
                            setDefaultResMessage({ status: true, msg: initialMessage });
                            showResMessageWarning();
                        }


                    }

                } else {
                    setDefaultResMessage({ status: true, msg: initialMessage });
                    showResMessageWarning();
                }

            })
            .catch((err) => {
                setDefaultResMessage({ status: true, msg: initialMessage });
                showResMessageWarning();

                Utilities.elementDisabledStatus(btn_name, false);
            });


    }


    const udpateFormValues = async () => {

        const timeUTC = moment.utc();
        let formValuesTemp = formValues;

        let fcmToken = await askPermissionToReceiveNotifications();
        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString();
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex);

        if (Utilities.isEmpty(fcmToken)) {
            const fcmTokenTemp = localStorage.getItem(process.env.REACT_APP_fs_fcm_token);
            fcmToken = JSON.parse(fcmTokenTemp) || '';

        } else {
            localStorage.setItem(
                process.env.REACT_APP_fs_fcm_token,
                JSON.stringify(fcmToken)
            );
        }

        formValuesTemp = {
            ...formValuesTemp,
            ...{
                fcm_token: fcmToken.toString(),
                utc_date: dateFormatted.toString(),
                brower_id: browserUniqueID.toString(),
            }
        };
        setFormValues(formValuesTemp);
    }

    const pushValuePhonecode = (providedValue = '') => {
        setFormValues({ ...formValues, ...{ phone_code: providedValue } });
    }


    const loginFormHtml = () => {
        return <div className="mt-n4 col-lg-10 mx-auto color-theme-grey fs-18">
            <div className="mb-4 text-center">
                <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-circle.svg`} className="login-logo-popup" alt="logo" /></Link>
            </div>
            <form className="login-form mx-auto  text-lowercase form-theme form-theme-dark" autoComplete="off">

                <div className="mt-4">
                    <LoginInputSelection loginType={loginType} formValues={formValues} pushValue={pushValue} pushValuePhonecode={pushValuePhonecode} setLoginView={setLoginType} classNameInputLabelAttr="bg-transparent" classNameInputAttr="text-white" />
                </div>

                <PasswordInput inputName="password" inputLabel="Password" inputClass="text-center" inputLabelClass="text-center bg-transparent" inputValue={formValues.password} pushValue={pushValue} />

                <div className={`process-message text-center text-danger ${defaultResMessage.status === true ? '' : 'invisible'}`}>{defaultResMessage.status === true ? defaultResMessage.msg : initialMessage}</div>


                <div className="button-wrap text-lowercase text-center mt-3">

                    <div className="mb-3"><button type="submit" id="btn-login-user" className="btn-theme-green button-has-loader col-sm-8 mx-auto" onClick={(e) => submitForm(e)}>login</button></div>
                    <div className="mb-2"><span className='cursor-pointer' onClick={() => handleForgotPasswordShowPopUp()}>forgot id or password?</span></div>

                    <div className="social-links-login d-flex justify-content-center my-3">
                        <LoginConnect loginSocialDetail={loginSocialDetail} />
                    </div>

                    <div>new user? <Link to={`/${UrlSlugs.signup_accountType}`}><span className="fs-18 labels">sign up</span></Link></div>

                </div>

            </form>
        </div>
    }



    const loginSocialDetail = (dataProvided = {}, status = 0) => {

        if (Object.keys(dataProvided).length === 0) {
            setDefaultResMessage({ status: true, msg: TitlesLabels.alertMessages.loginInvalidCredential });
            hideLoginWarningMessage();
            return false;
        }

        // IF NO SOCIAL BUT HAS CREDENTIAL
        if (parseInt(status) === 2) {
            setDefaultResMessage({ status: true, msg: TitlesLabels.alertMessages.loginAlreadyWithPassword });

            setFormValues({ ...formValues, ...{ account_id: dataProvided.account_id ?? '', email: dataProvided.email ?? '' } });
            setLoginType(1);
            hideLoginWarningMessage();

            return false;
        }

        // IF HAVE SOCIAL CREDENTIAL
        setFormValues({ ...formValues, ...{ account_id: dataProvided.account_id ?? '', email: dataProvided.app_access_token ?? '' } });
        jQuery('#btn-login-user').trigger('click');
    }


    const listNavigation = <ul>
        <li><a href="#home" className="active" onClick={() => scrollToSection('home')}>home</a></li>
        <li><a href="#why" onClick={() => scrollToSection('why')}>about</a></li>
        <li><a href="#consumer" onClick={() => scrollToSection('consumer')}>consumer</a></li>
        <li><a href="#business" onClick={() => scrollToSection('business')}>business</a></li>
        <li><a href="#contact" onClick={() => scrollToSection('contact')}>contact</a></li>
    </ul>

    const learnMoreHtml = <div className='bg-transparent font-montserrat-bold fs-25 fs-md-24-important width-inherit-important' style={{ letterSpacing: -1.5 }}>learn more</div>

    const headerHtml = (extraClass = '') => {
        return (
            <header className={`${(extraClass ?? '') === '' ? '' : extraClass} header-login px-4 px-sm-5 d-flex align-items-center justify-content-between`}>
                <div className="logo-wrap">
                    <a href="#home"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-light.svg`} alt="logo" width="170" onClick={() => scrollToSection('home')} /></a>
                </div>
                <div className="  d-none d-md-flex align-items-center fs-14 ">
                    <nav id="main-navigation" className="main-navigation mr-lg-5">
                        {listNavigation}
                    </nav>
                    {
                        !isMobile &&
                        <div className="d-none d-lg-block">
                            <div className="btn-theme-black" style={{ width: 180, height: 55 }} onClick={() => handleShowLoginPopUp()}>
                                {Object.keys(loginUserDetail).length > 0 ? 'dashboard' : 'sign in / up'}
                            </div>
                        </div>
                    }

                </div>

                <div className='d-md-none'><img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-mobile-nav.svg`} className="cursor-pointer" onClick={() => setShowMovileNav(true)} /></div>

            </header>
        )
    }


    const sectionTitleHtml = (text = '', extraClass = '') => {
        return <h2 className={`heading-title font-montserrat-bold mb-4 fs-25 ${extraClass}`} style={{ letterSpacing: -1 }}>{text}</h2>
    }

    const sectionSubTitleHtml = (text = '', extraClass = '') => {
        return <h3 className={`font-montserrat-bold color-theme-green fs-30 mb-5 ${extraClass}`} style={{ letterSpacing: -1.4 }} dangerouslySetInnerHTML={{ __html: text }}></h3>
    }


    const hideLoginWarningMessage = () => {
        setTimeout(() => {
            setDefaultResMessage({ ...defaultResMessage, status: false });
        }, 5000);
    }

    const scrollToSection = (hashName = '') => {

        setShowMovileNav(false);

        if (hashName === '') { return false; }
        hashName = hashName.replace('#', '');
        hashName = hashName.replace('-info', '');
        hashName = hashName.replace('section-', '');

        const mainNavigationElem = jQuery('#main-navigation');

        setTimeout(() => {
            mainNavigationElem.find('a').removeClass('active');
            mainNavigationElem.find('a[href="#' + hashName + '"]').addClass('active');
            jQuery('section.section-content').removeClass(`show-extented-info`);
        }, 800)

    }


    const changeContentHeight = () => {
        const heghtSectionBased = jQuery('.login-container-wrapper').height(),
            browserWith = jQuery(window).width();

        let counterInc = 0, heightArr = [];

        if (browserWith < 768) {
            marginDeduct = -5;
        }

        if (browserWith < 992) {
            jQuery('.section-content').css({ height: 'inherit' });
            return false;
        }

        if (heghtSectionBased > 850) {
            jQuery('.section-content').css({ height: heghtSectionBased + 10 });
        }

        jQuery('section.section-content, section.login-content').each(function () {
            counterInc++;
            if (counterInc > 1) {
                heightArr.push(heghtSectionBased);
            }

            jQuery(this).attr('data-top', counterInc === 1 ? 0 : heightArr.reduce((partialSum, a) => partialSum + a, 0));
        });
    }


    const SectionPaddingYHtml = () => {
        return <div className="py-lg-0 py-sm-4"></div>
    }


    const activateNavigation = () => {

        const sections = jQuery('section'),
            nav = jQuery('.main-navigation'),
            windowBrowser = jQuery(window),
            cur_pos = windowBrowser.scrollTop();

        if (nav.length === 0) { return false; }

        sections.each(function () {
            let top = jQuery(this).offset().top - marginDeduct,
                bottom = top + jQuery(this).outerHeight() - 10;

            if (cur_pos >= top && cur_pos <= bottom) {
                let sectionName = jQuery(this).attr('id');
                sectionName = sectionName.replace('-info', '');
                sectionName = sectionName.replace('section-', '');

                nav.find('a').removeClass('active');
                nav.find('a[href="#' + sectionName + '"]').addClass('active');

                if (globalNavNameCurrent !== sectionName) {
                    jQuery('section.section-content').removeClass(`show-extented-info`);
                }

                globalNavNameCurrent = sectionName;
            }
        });
    }


    const toggleExtendednfo = (idName = '') => {

        if (!Utilities.isEmpty(idName)) {
            idName = idName.toLowerCase();

            jQuery(`#${idName}`).closest('section.section-content').siblings('section.section-content').removeClass(`show-extented-info`);
            jQuery(`#${idName}`).closest('section.section-content').toggleClass(`show-extented-info`);

        }
    }

    useEffect(async () => {

        let reqID = match.params.id;
        if (typeof reqID !== 'undefined') {
            reqID = atob(reqID);

            if (parseInt(reqID) > 0) {
                sessionStorage.setItem(process.env.REACT_APP_session_jazenet_request_profileid, reqID);
            }
        }

        udpateFormValues();

    }, [match.params.id]);


    useEffect(async () => {


        // REDIRECT to BEONCHAT SITE / TEMPORARY
        /* if ( NODE_ENV==='production' ){
            window.location.href = UrlSlugs.loginExternal;
            return false;
        } */

        //console.log( Utilities.generateSignupCode() );


        dispatch(pageTitle(TitlesLabels.siteTitles.login));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        PagesAuthentication.checkLoginStatus();

        udpateFormValues();

        dispatch(globalNotificationCounterReducer(0));

        const windowBrowser = jQuery(window), btnScrollTopElem = jQuery('.btn-arrow-toTop');

        btnScrollTopElem.click(() => {
            jQuery('html, body').stop().animate({
                'scrollTop': 0
            }, 1000);
        });

        windowBrowser.on('scroll', function () {
            let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
            let bodyHeight = parseInt(parseInt(document.body.scrollHeight) / 2) - 300;

            if (currentScroll > bodyHeight) {
                btnScrollTopElem.removeClass('d-none');
            } else {
                btnScrollTopElem.addClass('d-none');
            }

            //console.log(currentScroll);
            activateNavigation();
            //changeContentHeight();
        });



        const hashProvided = window.location.hash;
        if (hashProvided !== '') {
            setTimeout(() => {
                scrollToSection(hashProvided);
            }, 800);
        }

        jQuery(window).resize(function () {
            changeContentHeight();
        });


        setTimeout(() => {
            changeContentHeight();
        }, 500);


        // removed because of browwer scroll
        jQuery('html,body').addClass('overflow-x-hidden');

        isMobile && jQuery('body').addClass('zoom-out');

        intervalSlide = setInterval(mobileSlider, 2000);

        sessionStorage.removeItem(process.env.REACT_APP_session_login_company_bulk_credential);

        setTimeout(() => {
            const defaultLocationTemp = Utilities.defaultLocation();
            setFormValues({ ...formValues, phone_code: defaultLocationTemp.country_id.toString() });
        }, 2000);

    }, []);


    return (
        <>
            <div className="login-page">

                {headerHtml('header-fixed')}

                <section id="section-home" className="login-content">
                    <div id="home" className='section-header-hidden'></div>
                    <div></div>

                    <HomeLogin handleShowVideoPopUp={handleShowVideoPopUp} mobileSlidePage={mobileSlidePage} mobileSlideStop={mobileSlideStop} />

                    <div className="d-block d-lg-none pt-0 pt-md-5 pb-5 px-4 px-md-0">
                        <div className="text-center pt-2 pt-sm-4 pb-2 mb-2 pb-sm-2 mb-sm-5">
                            <div className="mobile-wrapper mx-auto d-none d-md-block">
                                <img src={`${process.env.REACT_APP_img_folder}/bg-images/slider-mobile/slide-${mobileSlidePage}.webp`} width={355} className="position-relative" />
                            </div>
                            <div className="mobile-wrapper d-md-none text-center pb-4 mb-3 d-flex justify-content-center">
                                <div className='mobile-img-wrapper'>
                                    <img src={`${process.env.REACT_APP_img_folder}/bg-images/slider-mobile/slide-${mobileSlidePage}.webp`} className="w-100 position-relative" />
                                </div>
                            </div>

                        </div>

                        <div className="headline-title font-montserrat-bold fs-35 text-center text-white col-sm-7 px-0 mx-auto">
                            <div className="line-height-1-2" style={{ letterSpacing: '-1.4px' }}><span className="color-theme-green">the business directory and chat platform</span> connecting people and business</div>
                        </div>

                        <div className={`btn-front-options  d-flex align-items-center justify-content-center pt-2 pt-sm-4 mt-2`}>
                            <div className='cursor-pointer' onClick={() => handleShowVideoPopUp(video_links.beonchat_new)}>
                                <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-circle-play.svg`} width={40} />
                                <span className='font-gotham-bold fs-18 text-white ml-3 text-lowercase'>understanding {siteTitle}</span>
                            </div>
                        </div>

                        <div className="mt-4   text-center tap-connect font-gotham-book d-flex justify-content-center">
                            <div>
                                <div className="mb-2 text-theme-gray fs-16 mb-4">available on</div>
                                <AppLinkHtml />
                            </div>
                        </div>

                    </div>
                </section>



                <section id="section-why" className="section-content py-5 position-relative">
                    <div id="why" className='section-header-hidden'></div>
                    <div className='section-content-backdrop' onClick={() => toggleExtendednfo('section-why')}></div>

                    <div id="section-why-information" className='section-extended-detail d-flex align-items-center bg-black text-white  fs-16 fs-md-16 font-gotham-book'>
                        <img className='btn-extend-close' src={`${process.env.REACT_APP_img_folder}/icons/white/outline-cross-border.svg`} width="20" onClick={() => toggleExtendednfo('section-why')} />
                        {extenedInfoArray['section-why']}
                    </div>

                    <div className="login-container container">

                        <SectionPaddingYHtml />

                        <div className="text-center text-lg-left">{sectionSubTitleHtml(`making transactions effortless for<br />business and consumers`)}</div>

                        <div className="pt-lg-3"></div>
                        <ul className="about-content-points row text-center mt-5 pt-4 fs-18 font-gotham-book d-lg-flex px-5 px-sm-0">
                            <li>
                                <ListingColumnsContentStyle>
                                    <img src={`${process.env.REACT_APP_img_folder}/icons/black/circle-black-dot-connected.svg`} width="70" />
                                    <p className="line-height-1-5 px-lg-4 mt-sm-2 mt-lg-4 pt-3">the easiest way for buyers and sellers to search, find and talk to each other</p>
                                </ListingColumnsContentStyle>
                            </li>
                            <li>
                                <ListingColumnsContentStyle>
                                    <img src={`${process.env.REACT_APP_img_folder}/icons/black/circle-black-profile-list.svg`} width="70" />
                                    <p className="line-height-1-5 px-lg-4 mt-sm-2 mt-lg-4 pt-3">thousands of business and service providers listed in your area and across the world</p>
                                </ListingColumnsContentStyle>
                            </li>
                            <li>
                                <ListingColumnsContentStyle>
                                    <img src={`${process.env.REACT_APP_img_folder}/icons/black/cirlce-black-phone-access.svg`} width="70" />
                                    <p className="line-height-1-5 px-lg-4 mt-sm-2 mt-lg-4 pt-3">free, easy to set up and use. available on both android and ios</p>
                                </ListingColumnsContentStyle>
                            </li>
                            <li>
                                <ListingColumnsContentStyle>
                                    <img src={`${process.env.REACT_APP_img_folder}/icons/black/circle-black-spam.svg`} width="70" />
                                    <p className="line-height-1-5 px-lg-4 mt-sm-2 mt-lg-4 pt-3">an ad and spam free platform that protects both buyers and sellers</p>
                                </ListingColumnsContentStyle>
                            </li>
                        </ul>

                        <SectionPaddingYHtml />

                    </div>


                    {/******  SECTION NAVIGATION *******/}
                    <div className="d-flex align-items-center justify-content-center  w-100 position-absolute  bottom-0  mt-3 mb-lg-5 pb-5">
                        <div className='section-navigation-slide'>
                            <div className="d-none d-lg-flex" onClick={() => toggleExtendednfo('section-why')} ><img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-arrow-list.svg`} /></div>
                            <div className="d-lg-none border-0" onClick={() => handleShowExtendedInfoPopup('black', 'section-why')} ><img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-arrow-list.svg`} /></div>

                            <div className="d-none d-lg-flex bg-transparent width-inherit-important" onClick={() => toggleExtendednfo('section-why')} >{learnMoreHtml}</div>
                            <div className="d-lg-none bg-transparent width-inherit-important" onClick={() => handleShowExtendedInfoPopup('black', 'section-why')}>{learnMoreHtml}</div>
                        </div>
                    </div>
                    {/******  SECTION NAVIGATION *******/}


                </section>



                <section id="section-consumer" className="section-content py-5 section-bg bgcolor-dark position-relative">
                    <div id="consumer" className='section-header-hidden'></div>
                    <div className='section-content-backdrop' onClick={() => toggleExtendednfo('section-consumer')}></div>

                    <div id="section-consumer-information" className='section-extended-detail d-flex align-items-center bg-white  fs-16 fs-md-16 font-gotham-book'>
                        <img className='btn-extend-close' src={`${process.env.REACT_APP_img_folder}/icons/black/outline-cross-border.svg`} width="20" onClick={() => toggleExtendednfo('section-consumer')} />
                        {extenedInfoArray['section-consumer']}
                    </div>

                    <div className="login-container container color-theme-white ">



                        <div className="row">
                            <div className="col-lg-5  d-flex d-lg-flex flex-column  justify-content-between  position-relative">

                                <div>

                                    <div className="text-center d-block d-lg-none">
                                        <img src={`${process.env.REACT_APP_img_folder}/bg-images/hand-with-mobile-landscape.png`} width="100%" className="d-none d-md-block" />
                                        <img src={`${process.env.REACT_APP_img_folder}/bg-images/hand-with-mobile.png`} width="100%" className="d-md-none" />

                                        <div className="text-center col-sm-10 mx-auto px-0  mt-5">
                                            {sectionTitleHtml(`consumers and end users`)}
                                            {sectionSubTitleHtml(`effortlessly find and chat with businesses in your area and beyond`)}
                                        </div>

                                    </div>

                                    <div className="d-none d-lg-block">
                                        {sectionTitleHtml(`consumers and end users`)}
                                        {sectionSubTitleHtml(`effortlessly find and chat with businesses in your area and beyond`)}
                                    </div>


                                    <ul className="listing-points  fs-18 mt-5 font-gotham-book px-4 px-sm-0">
                                        <li className="mb-sm-4 pb-2 d-block d-lg-flex text-center text-lg-left">
                                            <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-timestamp.svg`} width="77" className="mr-lg-4" />
                                            <ListingPointsContentStyle>
                                                <p className="line-height-1-5">fast, hassle-free connectivity to sellers and service providers to ask questions, place orders, make reservations, or voice complaints.</p>
                                            </ListingPointsContentStyle>
                                        </li>
                                        <li className="mb-sm-4 pb-2 d-block d-lg-flex text-center text-lg-left">
                                            <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-people-message.svg`} width="77" className="mr-lg-4" />
                                            <ListingPointsContentStyle>
                                                <p className="line-height-1-5">chat directly with a department or employee within a company</p>
                                            </ListingPointsContentStyle>
                                        </li>
                                        <li className="mb-sm-4 pb-2 d-block d-lg-flex text-center text-lg-left">
                                            <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-envelop-protection.svg`} width="77" className="mr-lg-4" />
                                            <ListingPointsContentStyle>
                                                <p className="line-height-1-5">ad free and in built protection from being spammed by businesses</p>
                                            </ListingPointsContentStyle>
                                        </li>
                                        <li className="d-block d-lg-flex text-center text-lg-left">
                                            <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-people-connecting.svg`} width="77" className="mr-lg-4" />
                                            <ListingPointsContentStyle>
                                                <p className="line-height-1-5">allows for both personal and business profiles in the same app but keeps chats separate and permits seamless switching between them.</p>
                                            </ListingPointsContentStyle>
                                        </li>
                                    </ul>
                                </div>


                                {/******  SECTION NAVIGATION *******/}
                                <div className="d-flex align-items-center justify-content-center  justify-content-lg-start  w-100 mt-3 mt-lg-5 pl-lg-2">
                                    <div className='section-navigation-slide'>
                                        <div className="d-none d-lg-flex" onClick={() => toggleExtendednfo('section-consumer')} ><img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-arrow-list.svg`} /></div>
                                        <div className="d-lg-none border-0" onClick={() => handleShowExtendedInfoPopup(`white`, 'section-consumer')} ><img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-arrow-list.svg`} /></div>

                                        <div className="d-none d-lg-flex bg-transparent width-inherit-important" onClick={() => toggleExtendednfo('section-consumer')} >{learnMoreHtml}</div>
                                        <div className="d-lg-none bg-transparent width-inherit-important" onClick={() => handleShowExtendedInfoPopup('white', 'section-consumer')}>{learnMoreHtml}</div>

                                    </div>
                                </div>
                                {/******  SECTION NAVIGATION *******/}

                            </div>

                            <div className="col-lg-7 text-center text-lg-right d-none d-lg-flex align-items-center justify-content-end">
                                <img src={`${process.env.REACT_APP_img_folder}/bg-images/hand-with-mobile.png`} width="90%" />
                            </div>

                        </div>


                    </div>
                </section>




                <section id="section-business" className="section-content py-5 position-relative">
                    <div id="business" className='section-header-hidden'></div>
                    <div className='section-content-backdrop' onClick={() => toggleExtendednfo('section-business')}></div>

                    <div id="section-business-information" className='section-extended-detail d-flex align-items-center bg-black text-white  fs-16 fs-md-16 font-gotham-book'>
                        <img className='btn-extend-close' src={`${process.env.REACT_APP_img_folder}/icons/white/outline-cross-border.svg`} width="20" onClick={() => toggleExtendednfo('section-business')} />
                        {extenedInfoArray['section-business']}
                    </div>

                    <div className="login-container container ">



                        <div className="row">

                            <div className="col-lg-7 text-center text-lg-left d-flex align-items-center justify-content-start">
                                <img src={`${process.env.REACT_APP_img_folder}/bg-images/hand-with-mobile-2.png`} className="d-none d-lg-block" width="90%" />
                                <img src={`${process.env.REACT_APP_img_folder}/bg-images/hand-with-mobile-2-landscape.png`} className="d-none d-md-block d-lg-none" width="100%" />
                                <img src={`${process.env.REACT_APP_img_folder}/bg-images/hand-with-mobile-2.png`} className="d-md-none" width="100%" />
                            </div>


                            <div className="col-lg-5  d-block d-lg-flex d-flex flex-column  justify-content-between">

                                <div>

                                    <div className="text-center d-block d-lg-none mt-5">
                                        <div className="text-center col-sm-10 mx-auto px-0">
                                            {sectionTitleHtml(`businesses and professionals`)}
                                            {sectionSubTitleHtml(`get discovered by more customers than ever before`)}
                                        </div>
                                    </div>

                                    <div className="d-none d-lg-block">
                                        {sectionTitleHtml(`businesses and professionals`)}
                                        {sectionSubTitleHtml(`get discovered by more customers than ever before`)}
                                    </div>

                                    <ul className="listing-points  fs-18 mt-5 font-gotham-book px-4 px-sm-0">
                                        <li className="mb-sm-4 pb-2 d-block d-lg-flex text-center text-lg-left">
                                            <img src={`${process.env.REACT_APP_img_folder}/icons/black/outline-buildings.svg`} width="77" className="mr-lg-4" />
                                            <ListingPointsContentStyle>
                                                <p className="line-height-1-5">inclusive of all businesses regardless of size, type or location</p>
                                            </ListingPointsContentStyle>
                                        </li>
                                        <li className="mb-sm-4 pb-2 d-block d-lg-flex text-center text-lg-left">
                                            <img src={`${process.env.REACT_APP_img_folder}/icons/black/outline-free-hand.svg`} width="77" className="mr-lg-4" />
                                            <ListingPointsContentStyle>
                                                <p className="line-height-1-5">free to list and easy to use. user friendly set up to customize your business profile</p>
                                            </ListingPointsContentStyle>
                                        </li>
                                        <li className="mb-sm-4 pb-2 d-block d-lg-flex text-center text-lg-left">
                                            <img src={`${process.env.REACT_APP_img_folder}/icons/black/outlint-found-magnify.svg`} width="77" className="mr-lg-4" />
                                            <ListingPointsContentStyle>
                                                <p className="line-height-1-5">allows your business to be found by name, category or keyword</p>
                                            </ListingPointsContentStyle>
                                        </li>
                                        <li className="mb-sm-4 pb-2 d-block d-lg-flex text-center text-lg-left">
                                            <img src={`${process.env.REACT_APP_img_folder}/icons/black/outline-people-target.svg`} width="77" className="mr-lg-4" />
                                            <ListingPointsContentStyle>
                                                <   p className="line-height-1-5">advertising to targeted clients</p>
                                            </ListingPointsContentStyle>
                                        </li>
                                        <li className="d-block d-lg-flex text-center text-lg-left">
                                            <img src={`${process.env.REACT_APP_img_folder}/icons/black/people-echange-messages.svg`} width="77" className="mr-lg-4" />
                                            <ListingPointsContentStyle>
                                                <p className="line-height-1-5">a separate internal chat platform for employees.</p>
                                            </ListingPointsContentStyle>
                                        </li>
                                    </ul>
                                </div>


                                {/******  SECTION NAVIGATION *******/}
                                <div className="d-flex align-items-center justify-content-center  justify-content-lg-start w-100 mt-3 mt-lg-5">
                                    <div className='section-navigation-slide'>
                                        <div className="d-none d-lg-flex" onClick={() => toggleExtendednfo('section-business')}><img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-arrow-list.svg`} /></div>
                                        <div className="d-lg-none border-0" onClick={() => handleShowExtendedInfoPopup('black', 'section-business')} ><img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-arrow-list.svg`} /></div>
                                        <div onClick={() => handleShowVideoPopUp(video_links.beonchat_business_professional)}><img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-box-play-video.svg`} /></div>

                                        <div className="d-none d-lg-flex bg-transparent width-inherit-important" onClick={() => toggleExtendednfo('section-business')} >{learnMoreHtml}</div>
                                        <div className="d-lg-none bg-transparent width-inherit-important" onClick={() => handleShowExtendedInfoPopup('black', 'section-business')}>{learnMoreHtml}</div>

                                    </div>
                                </div>
                                {/******  SECTION NAVIGATION *******/}

                            </div>

                        </div>


                    </div>
                </section>


                <div className="btn-arrow-toTop d-none"></div>

            </div>

            <Footer />


            <div className={`mobile-navigation-main px-5 ${showMovileNav ? 'open' : ''}`}>
                <div className="logo-wrap  d-flex align-items-center justify-content-between">
                    <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-light.svg`} alt="logo" width="130" onClick={() => scrollToSection('home')} /></Link>
                    <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-cross-border.svg`} className="cursor-pointer" onClick={() => setShowMovileNav(false)} />
                </div>

                <div className='mobile-navigation-links text-white d-flex align-self-center justify-content-center w-100 font-gotham-book'>
                    {listNavigation}
                </div>

                <div></div>
            </div>

            <ForgotPassword forgotPasswordPopup={forgotPasswordPopup} handleForgotPasswordClosePopUp={handleForgotPasswordClosePopUp} />


            <Modal id="popup-watch-video" show={showVideoPopup} onHide={handleCloseVideoPopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="lg" contentClassName="bg-theme-green border-0" backdropClassName="modal-backdrop-gray- opacity-1-important">
                <Modal.Header closeButton className="border-0 popup-watch-video-header"></Modal.Header>
                <Modal.Body className="p-0 bg-theme-green font-gotham-medium text-center fs-20  popup-watch-video-body  overflow-hidden">

                    {
                        (popupVideoUrl || video_links.beonchat_youtube).split('youtube.').length > 1 ?
                            <iframe width="100.5%" height="450" style={{ marginLeft: -2 }} src={`${popupVideoUrl || video_links.beonchat_youtube}?autoplay=1`} onLoad={() => handleOnLoadIFrame(false)} class={`popup-watch-video-iframe  ${iframeIsLoading ? 'opacity-0' : ''}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            :
                            <video id="video-app-player" width="100.5%" style={{ marginLeft: -2, objectFit: 'fill' }} controls autoplay="">
                                <source src={`${popupVideoUrl || video_links.beonchat_youtube}`} type="video/mp4" />
                            </video>
                    }

                </Modal.Body>
            </Modal>



            <Modal id="popup-app-extended-info" show={showExtendedInfoPopup} onHide={handleCloseExtendedInfoPopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered className='overflow-hidden' contentClassName={`border-0 ${extendedInfoBgColor === 'black' ? 'bg-black' : ''}`} backdropClassName={` ${extendedInfoBgColor === 'black' ? 'modal-backdrop-gray' : ''}`} dialogClassName="align-items-end mw-100 mx-0 h-100">
                <Modal.Body className={`pb-5  pt-5 px-md-5  fs-18 fs-md-18 line-height-1-4 modal-body text-lowercase ${extendedInfoBgColor === 'black' ? 'text-white' : ''}`}>
                    {extenedInfoContent}
                </Modal.Body>
            </Modal>



            <Modal id="popup-app-login" show={showLoginPopup} onHide={handleCloseLoginPopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered contentClassName="bg-black popup-app-login" backdropClassName="modal-backdrop-gray">
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body className="pb-5 pt-3 px-md-5 text-center fs-20 line-height-1-2 modal-body">

                    {loginFormHtml()}

                </Modal.Body>
            </Modal>



        </>
    )
}

export default Login;



const AppLinkHtml = () => {
    return (
        <>
            <ul className="d-none d-sm-flex">
                <li className="mr-3 mr-lg-4">
                    <a href={UrlSlugs.link_playStore} target="_blank" className="btn-app-download"><img src={`${process.env.REACT_APP_img_folder}/icons/white/google-play-logo.svg`} width="24" />
                        <span className="ml-2 font-gotham-bold fs-14 text-white">{TitlesLabels.general.appStore_googlePlay}</span>
                    </a>
                </li>
                <li className="mr-3 mr-lg-4">
                    <a href={UrlSlugs.link_appStore} target="_blank" className="btn-app-download"><img src={`${process.env.REACT_APP_img_folder}/icons/white/apple-store-logo.svg`} width="24" />
                        <span className="ml-2 font-gotham-bold fs-14 text-white">{TitlesLabels.general.appStore_appleStore}</span>
                    </a>
                </li>
                <li>
                    <a href={UrlSlugs.link_appGallery} target="_blank" className="btn-app-download"><img src={`${process.env.REACT_APP_img_folder}/icons/white/huawei-store-logo.svg`} width="24" />
                        <span className="ml-2 font-gotham-bold fs-14 text-white">{TitlesLabels.general.appStore_huaweiGallery}</span>
                    </a>
                </li>
            </ul>

            <ul className="d-flex justify-content-center just d-sm-none">
                <li className="mr-3">
                    <a href={UrlSlugs.link_playStore} target="_blank" style={{ width: 'inherit' }}>
                        <img src={`${process.env.REACT_APP_img_folder}/icons/white/circle-outline-google-store.svg`} width="37" />
                    </a>
                </li>
                <li className="mr-3">
                    <a href={UrlSlugs.link_appStore} target="_blank" style={{ width: 'inherit' }}>
                        <img src={`${process.env.REACT_APP_img_folder}/icons/white/circle-outline-apple-store.svg`} width="37" />
                    </a>
                </li>
                <li>
                    <a href={UrlSlugs.link_appGallery} target="_blank" style={{ width: 'inherit' }}>
                        <img src={`${process.env.REACT_APP_img_folder}/icons/white/circle-outline-huawei-store.svg`} width="37" />
                    </a>
                </li>
            </ul>

        </>
    )
}

const ListingColumnsContentStyle = (props) => {
    return <>
        <div className={`pb-5 px-0 col-md-5 mx-auto d-block d-lg-none ${props.classAttr ?? ''}`}>{props.children}</div>
        <div className={`pb-5 d-none d-lg-block ${props.classAttr ?? ''}`}>{props.children}</div>
    </>
}

const ListingPointsContentStyle = (props) => {
    return <>
        <div className={`pb-5 pb-lg-0 px-0 col-md-9 mx-auto mt-4 d-block d-lg-none ${props.classAttr ?? ''}`}>{props.children}</div>
        <div className={`d-none d-lg-block ${props.classAttr ?? ''}`}>{props.children}</div>
    </>
}



const HomeLogin = (props) => {

    const mobileStyle = { height: '95%', top: 20 };

    return (
        <div className="login-container-wrapper d-none d-lg-flex align-items-center  overflow-hidden- position-relative ">

            {/* <div  className="logo-head-middle"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-circle.svg`} alt="logo" width="550"/></div> */}
            <div className="mobile-handling">
                <div className="mobile-lg-wrapper">
                    <img src={`${process.env.REACT_APP_img_folder}/bg-images/slider-mobile/slide-${parseInt(props.mobileSlideStop ?? 0) === 0 ? props.mobileSlidePage : props.mobileSlideStop}.webp`} className="position-relative" style={mobileStyle} />
                </div>
            </div>

            <div className="login-container container d-lg-flex align-items-center pt-4 pt-sm-5 pt-lg-3 py-lg-5 position-relative z-index-1">
                <div className="row  w-100 mx-lg-0 ">


                    <div className="d-lg-block col-lg-6 left-content pl-lg-0 pr-lg-5">

                        <div className="mt-lg-5 p-lg-5"></div>

                        <div className="headline-title-wrap text-white position-relative pb-5">
                            <div className={`headline-title font-montserrat-bold fs-40 ${!isMobile ? 'pr-lg-5' : ''}`} style={{ letterSpacing: '-1.4px' }}>
                                <div className="line-height-1-5"><span className="color-theme-green">the business directory and chat platform</span> connecting people and business</div>
                            </div>
                        </div>

                        <div className={`btn-front-options d-none d-lg-flex`}>
                            <div className='cursor-pointer' onClick={() => props.handleShowVideoPopUp(video_links.beonchat_new)}>
                                <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-circle-play.svg`} width={40} />
                                <span className='font-gotham-bold fs-18 text-white ml-3 text-lowercase'>understanding {siteTitle}</span>
                            </div>
                        </div>

                        <div className="download-links tap-connect pt-4 mt-5 pb-4  text-white d-none d-lg-block">
                            <div className="mb-3">available on</div>
                            <AppLinkHtml />
                        </div>

                    </div>

                    <div className="col-lg-6 row mx-0 pt-2 pt-sm-5 pt-lg-0 px-0">
                        <div className="col-3 d-lg-none position-relative">

                            <div className="btn-device-watch" onClick={() => props.handleShowVideoPopUp()}><span className="fs-22">watch<br />now</span></div>

                            <div className="position-absolute tap-connect">
                                <div className="mb-2 text-theme-gray">available on</div>
                                <AppLinkHtml />
                            </div>

                        </div>

                        <div className="mx-auto col-9 px-0 px-lg-4">

                            <div className="d-lg-none">
                                <div className="text-center">
                                    <Link to="/"><img src={`${process.env.REACT_APP_img_folder}/bg-images/slider-mobile/slide-${props.mobileSlidePage}.webp`} className="mobile-hand" alt="logo" width="100%" /></Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}