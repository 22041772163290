import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import axios from 'axios';
import * as RM from 'ramda';

import { switchProfile as switchAccount } from '../actions';

import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import HeaderChat from './HeaderChat';
import CommonProfileLogo from '../components/common/CommonProfileLogo';

const HeaderDepartment = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    //let imgPlusLogo = `<div style="background-image:url('${process.env.REACT_APP_img_folder}/logo/logo-plus.svg')" class="logo-img logo-plus"></div>`;
    //let imgBizLogo = `<div style="background-image:url('${process.env.REACT_APP_img_folder}/logo/logo-light.svg')" class="logo-img" ></div>`;
    let imgBizLogo = `<img src="${process.env.REACT_APP_img_folder}/logo/logo-light.svg"  class="logo-img" />`;
    const headerType = parseInt(props.headerType ?? 1);

    const [ isInnerPage, setIsInnerPage ] = useState(false);
    const [ loginDepartment, setLoginDepartment ] = useState({});
    const [ activeProfile, setActiveProfile ] = useState({});
    const [ companyDetail, setCompanyDetail ] = useState({});
    const [ departmentList, setDepartmentList ] = useState({});
    
    const reloadDepartments = useSelector( state => state.reloadDepartments);

    const provideLoginProfileDetail = () => {
        
        let loginDetail = {};
        let sessionLoginDetail = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( sessionLoginDetail!==null ){
            loginDetail = JSON.parse(sessionLoginDetail);
            setLoginDepartment(loginDetail);
        }

        let activeDepartment = localStorage.getItem(process.env.REACT_APP_session_department_active);
        if ( activeDepartment!==null ){
            activeDepartment = JSON.parse(activeDepartment);
            setActiveProfile(activeDepartment);
        }

        let companyDetail = localStorage.getItem(process.env.REACT_APP_session_company_detail);
        if ( companyDetail!==null ){
            companyDetail = JSON.parse(companyDetail);
            setCompanyDetail(companyDetail);
        }

        let departmentList = localStorage.getItem(process.env.REACT_APP_session_company_departments);
        if ( departmentList!==null ){
            departmentList = JSON.parse(departmentList);
            departmentList = Utilities.toNormalArrayObject(departmentList);

            if ( departmentList.length>0 ){
                if ( 'name' in departmentList[0] ){
                    const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                    departmentList = RM.sort(byName, departmentList);
                }
            }

            setDepartmentList(departmentList);
        }
    }

    const switchDepartment = ( deptDetail = {} ) => {
        
        localStorage.setItem(
            process.env.REACT_APP_session_department_active,
            JSON.stringify(deptDetail)
        );
       
        setActiveProfile(deptDetail);

        dispatch( switchAccount(Date.now()) );
        history.push(`/${ UrlSlugs.departmentDashboard }`);

    }

    const getDepartments = ( passDetail = {} ) => {

        const dataRequest = {
            account_id : passDetail.account_id.toString(),
            department_id : passDetail.department_id.toString(),
            ip_address : passDetail.ip_address.toString(),
        };

        axios
        .post( ApiUrls.departmentAccessGetDepartments , dataRequest )
        .then( response => {

            if ( response.status===200 ){
                const dataResponse = response.data;
                //const dataStatus = parseInt(dataResponse.status);
                
                //if ( dataStatus===1 ){
                    localStorage.setItem(
                        process.env.REACT_APP_session_company_departments,
                        JSON.stringify(dataResponse.departments)
                    );
                    provideLoginProfileDetail();
               // }
            }

        })
        .catch( (err) => {

        }); 
        
    }

    useEffect( () => {
        provideLoginProfileDetail();

    }, [] );


    useEffect( async () => {
        if ( !Utilities.isEmpty(reloadDepartments)){
            getDepartments( reloadDepartments );
        }
    }, [reloadDepartments]);


    // UPDATED WHEN GO BACK TO DASHBOARD
    useEffect( () => {

        const segment = Utilities.getUrlSegments();

        let urlSlug = segment.shift();
        if ( typeof urlSlug!=='undefined' ){
            urlSlug = urlSlug.toString().toLowerCase();
        }

        if ( [...Utilities.mainPages].includes(urlSlug) ){
            setIsInnerPage(false);
        }else{
            setIsInnerPage(true);
        }


    }, [ window.location.pathname ]);

    return (
        <>
            <header className="main-header main-header-department text-lowercase">
            <div className={`main-header-content ${ isInnerPage ? 'main-header-insdie-pages' : ''}`}>

                    <div className="main-logo text-left"><Link to={`/${ UrlSlugs.departmentDashboard }`} dangerouslySetInnerHTML={{ __html: imgBizLogo }}></Link></div>

                    <div className="user-navigate">
                        <div className="profile-selection-list">

                            <Dropdown className="dropdown-user-selection">
                                <Dropdown.Toggle variant={`dropdown-user-current d-flex align-items-center justify-content-star ${ Object.keys(departmentList).length===0 ? 'cursor-auto' : ''}`} id="dropdown-basic">

                                    <CommonProfileLogo classAttr={`avatar-image fs-22-important ${ isInnerPage ? 'logo-size-60 logo-size-md-70' : 'logo-size-110 logo-size-md-90' } bg-black`} profileDetail={{...activeProfile, name : companyDetail.company_name }}  />

                                    <div className="user-current-navigate line-height-1-2  position-relative text-left pl-3">
                                        <div className={ `header-profile-label ellipsis-1 notranslate font-gotham-medium  text-white  ${ isInnerPage ? 'fs-20 fs-md-22' : 'fs-20 fs-md-26' }` }>{companyDetail.company_name}</div>
                                        <div className={ `header-profile-name color-theme-grey font-gotham-book ellipsis-1 ${ isInnerPage ? 'fs-20 fs-md-16': 'fs-24 fs-md-20' }` }>{activeProfile.name}</div>

                                        {
                                            (Object.keys(departmentList??{}).length>1 && isInnerPage) && 
                                                <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-arrow-down.svg`} width={20} className='position-absolute top-0 end-0 mr-n4 mt-1' />
                                        }
                                    </div>


                                </Dropdown.Toggle>  
                                
                                {
                                    Object.keys(departmentList).length>0 && 
                                        <Dropdown.Menu >
                                            <div className="dropdown-item-toggle">
                                                <div className={`department-list font-gotham-book`}>

                                                    {
                                                        Object.entries(departmentList).map( ( [key, deptDetail] ) =>
                                                            <Dropdown.Item key={key} className={ `department-name ${ parseInt(deptDetail.group_id)===parseInt(activeProfile.group_id) ? 'current-item' : '' }`} onClick={ () => switchDepartment(deptDetail) }>
                                                                <div className="user-name notranslate">{deptDetail.name}</div>
                                                                {/* <span className="counter"><em>1</em></span> */}
                                                            </Dropdown.Item>
                                                        )
                                                    }
                                    
                                                </div>
                                            </div>

                                        </Dropdown.Menu>
                                }
                                
                            </Dropdown>

                        </div>

                     
                    </div>

                    {/* <div className="logo-sub"><div dangerouslySetInnerHTML={{ __html: imgPlusLogo }}></div></div> */}

            </div>

            </header>
                                
            {
                [3].includes( parseInt(headerType) ) &&
                <HeaderChat headerType={headerType} />
            }
            
        </>
        
    )
}

export default HeaderDepartment;