import axios from 'axios';
import ApiUrls from './ApiUrls';
import PagesAuthentication from './PagesAuthentication';


export const leaveFromGroups = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {

            //console.log('leaveFromGroups');
            //console.log(ApiUrls.companyBroadcastLeaveGroup);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.companyBroadcastLeaveGroup, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}

export const companyGroupsList = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {

            //console.log('companyGroupsList');
            //console.log(ApiUrls.companyBroadcastCompanyGroupsList);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.companyBroadcastCompanyGroupsList, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);


        } catch (e) {
            PagesAuthentication.logoutDirectly();
        }
    }

    return responseDetail;
}

export const companyGroupsListBizchat = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {
            // console.log('companyGroupsListBizchat');
            //console.log(ApiUrls.companyBroadcastCompanyGroupsListBizchat);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.companyBroadcastCompanyGroupsListBizchat, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}


export const conversationTeamMembersList = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {

            /* console.log('conversationTeamMembersList');
            console.log(ApiUrls.companyBroadcastConversationsTeamMembersList);
            console.log(passval); */

            const responseURL = await axios.post(ApiUrls.companyBroadcastConversationsTeamMembersList, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}


export const getMembersGroups = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {

            /* console.log('getMembersGroups');
            console.log(ApiUrls.companyBroadcastConversationMembersList);
            console.log(passval); */

            const responseURL = await axios.post(ApiUrls.companyBroadcastConversationMembersList, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}

export const joinInvitationResponse = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {

            /* console.log('joinInvitationResponse');
            console.log(ApiUrls.companyBroadcastAcceptRejectGroupRequest);
            console.log(passval); */

            const responseURL = await axios.post(ApiUrls.companyBroadcastAcceptRejectGroupRequest, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}


export const groupRemoveMembers = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {
            /* console.log('groupRemoveMembers');
            console.log(ApiUrls.companyGroupRemoveMembers);
            console.log(passval); */

            const responseURL = await axios.post(ApiUrls.companyGroupRemoveMembers, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}


export const groupSuspendMembers = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {
            /*  console.log('groupSuspendMembers');
             console.log(ApiUrls.companyGroupSuspendMembers);
             console.log(passval); */

            const responseURL = await axios.post(ApiUrls.companyGroupSuspendMembers, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}


export const getTeamMembersByCompanyId = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {

           /*  console.log('getTeamMembersByCompanyId');
            console.log(ApiUrls.companyBroadcastAdminMembersList);
            console.log(passval); */

            const responseURL = await axios.post(ApiUrls.companyBroadcastAdminMembersList, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}

export const createSubmitGroup = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {

            /* console.log('createSubmitGroup');
            console.log(ApiUrls.companyBroadcastCompanyCreateGroups);
            console.log(passval); */

            const responseURL = await axios.post(ApiUrls.companyBroadcastCompanyCreateGroups, passval);
            responseDetail = responseURL.data;

            //console.log(responseDetail);


        } catch (e) {

        }
    }

    return responseDetail;
}

export const updateSubmitGroup = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {

            /* console.log('updateSubmitGroup');
            console.log(ApiUrls.companyBroadcastCompanyUpdateGroups);
            console.log(passval); */

            const responseURL = await axios.post(ApiUrls.companyBroadcastCompanyUpdateGroups, passval);
            responseDetail = responseURL.data;

            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}

export const deleteGroup = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.companyBroadcastCompanyDeleteGroups, passval);
            responseDetail = responseURL.data;


        } catch (e) {

        }
    }

    return responseDetail;
}


export const joinOpenGroup = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {

            //console.log('joinOpenGroup');
            //console.log(ApiUrls.companyBroadcastJoinOpenGroup);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.companyBroadcastJoinOpenGroup, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}

export const requestToJoin = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.companyBroadcastCompanyGroupsSendRequest, passval);
            responseDetail = responseURL.data;


        } catch (e) {

        }
    }

    return responseDetail;
}

export const joinWithPasscode = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.companyBroadcastCompanyGroupsJoinWithPasscode, passval);
            responseDetail = responseURL.data;


        } catch (e) {

        }
    }

    return responseDetail;
}


export const removeJoinRequest = async (passval = {}) => {
    let responseDetail = {};

    //console.log('removeJoinRequest');
    //console.log(ApiUrls.companyBroadcastCompanyGroupsRemoveJoinRequest);
    //console.log(passval);

    if (Object.keys(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.companyBroadcastCompanyGroupsRemoveJoinRequest, passval);
            responseDetail = responseURL.data;
            //console.log(responseDetail);

        } catch (e) {

        }
    }

    return responseDetail;
}


export const getGroupListByTeamMembersListId = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.companyGroupsGetGroupListByTeamMembersListId, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;
}
