import React, { useEffect, useState } from 'react';
import {  useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TitlesLabels from '../../helpers/TitlesLabels';
import { pageTitle, showFooter, showHeader } from '../../actions';

import UrlSlugs from '../../helpers/UrlSlugs';
import WelcomeMessage from '../../components/popups/WelcomeMessage';
import PagesAuthentication from '../../helpers/PagesAuthentication';


const CampaignWelcomeMessage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [ showPopup, setShowPopup ] = useState(true);
    const [ loggedInProfile, setLoggedInProfile ] = useState({});
    
    const handleClosePopUp = ( action = 0 ) => {
        action = parseInt(action);

        if ( action===0 ){
            history.push(`/${UrlSlugs.campaign_downloadApp}`);
            
        }else{
            history.push(`/${UrlSlugs.signup_companyInformation}`);

        }


        setShowPopup(false);
    }

    useEffect( () => {
        dispatch( pageTitle( TitlesLabels.siteTitles.welcome ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        const loggedInProfileSession = PagesAuthentication.getUserLoggedinDetail();
        if ( Object.keys(loggedInProfileSession??{}).length>0 ){
            setLoggedInProfile(loggedInProfileSession);

        }else{
           history.push(`/${UrlSlugs.login}`);
        }
       

    }, [] );

    return (
        <WelcomeMessage showPopup={showPopup} handleClosePopup={handleClosePopUp} profileLoggedin={loggedInProfile} />
    )
}

export default CampaignWelcomeMessage;