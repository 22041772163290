import axios from 'axios';
import ApiUrls from './ApiUrls';

export const getProfilesByCityId = async (city_id = 0, area_id = 0) => {
    let response = {};
    if (parseInt(city_id) > 0) {
        try {
            const passval = { city_id: city_id.toString(), area_id: area_id };
            //console.log(passval, ApiUrls.searchContactsByCityId);
            const profileDetailRes = await axios.post(ApiUrls.searchContactsByCityId, passval);
            response = profileDetailRes.data;

        } catch (e) {

        }

    }
    return response;
}
