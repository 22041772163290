import React, { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Utilities from '../../helpers/Utilities';
import QueryMasterSearchUtlities from '../../helpers/QueryMasterSearchUtlities';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import UrlSlugs from '../../helpers/UrlSlugs';
import SessionUtilities from '../../helpers/SessionUtilities';

import { showLoader as showLoaderPopup, showDirectoryProfilePopup  } from "../../actions";

const DirectoryNewRegisteredInfo = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    // const defaultLocation = Utilities.defaultLocation();
    const defaultLocation = SessionUtilities.getUserLocation() ?? {};

    const currentCityId = props.cityId ?? (defaultLocation.city_id ?? 0);
    const currentAreaId = props.areaId ?? (defaultLocation.area_id ?? 0);

    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    let { type } = useParams();

    const [companyIdPremium, setCompanyIdPremium] = useState(0);
    const [totalCounter, setTotalCounter] = useState(0);
    const [premiumLogo, setPremiumLogo] = useState('');
    const [showLoader, setShowLoader] = useState(true);

    const getNewRegisteredProfiles = async () => {
        const city_id = parseInt(currentCityId);
        const area_id = parseInt(currentAreaId);

        if (city_id > 0 && parseInt(profileLoggedin.account_id ?? 0) > 0) {
            setShowLoader(true);
            const TOKEN_SESSION = SessionUtilities.getSessionToken();
            const passval = {
                api_token: TOKEN_SESSION.toString(),    //API_TOKEN.toString(),
                account_id: profileLoggedin.account_id.toString(),
                city_id: city_id.toString(),
                area_id: (area_id ?? '0').toString(),
                date_time: Utilities.getMomentCurrentDateTime()
            }

            //console.log(passval);
            const profileCounterResponse = await QueryMasterSearchUtlities.getNewRegisteredCounter(passval);
            //console.log(profileCounterResponse);
            if (Object.keys(profileCounterResponse).length > 0) {
                setTotalCounter(profileCounterResponse.result.search_home_details.company_count ?? 0);
                setPremiumLogo(profileCounterResponse.result.search_home_details.premium_ads_details.banner_image_path ?? '');
                setCompanyIdPremium(profileCounterResponse.result.search_home_details.premium_ads_details.company_id ?? 0);
            }
            setShowLoader(false);
        }

    }

    const handleShowProfile = async () => {
        const profileId = parseInt(companyIdPremium);
        if (profileId > 0) {
            dispatch(showLoaderPopup(1));
            const profileDetail = await QueryMasterSearchUtlities.getProfileDetail(profileId);
            dispatch(showLoaderPopup(0));
            dispatch(showDirectoryProfilePopup(profileDetail.list[profileId.toString()]));
        }
    }


    const handleRedirectRegsiteredNew = () => {
        let matchShowStatus = type ?? btoa(1);
        history.push(`/${UrlSlugs.page_directoryRegisteredNew}/${matchShowStatus}`);
    }

    useEffect(() => {
        getNewRegisteredProfiles();

    }, [currentCityId, currentAreaId]);

    return (
        showLoader ?
            <div className='content-loader animate'></div>
            :

            <div className='font-gotham-medium fs-22 fs-md-20 text-center'>
                {
                    parseInt(totalCounter) > 0 &&
                    <>
                        <div>new business registrations</div>
                        <div>in your area for the last month</div>
                        <div className='font-gotham-bold fs-70 fs-50 color-theme-green'>{totalCounter}</div>
                        <div className='font-gotham-medium fs-14 fs-md-14 cursor-pointer color-theme-green ' onClick={() => handleRedirectRegsiteredNew()}>click here to view</div>
                    </>
                }

                {
                    !Utilities.isEmpty(premiumLogo) &&
                    <img src={premiumLogo} width={250} className='mt-2 cursor-pointer' onClick={() => handleShowProfile()} />
                }

            </div>


    )

}

export default memo(DirectoryNewRegisteredInfo);


