import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Utilities from '../../helpers/Utilities';
import { showPopupSocialMediaLibrary as showPopupSocialMediaLibraryReducer } from '../../actions';
import TitlesLabels from '../../helpers/TitlesLabels';

export const ComanySocialLinksManage = (props) => {

    const dispatch = useDispatch();
    const props_socialLinksValues = props.socialLinksValue ?? { ...Utilities.socialLinkValues };
    const pros_showAddButton = props.showAddButton ?? false;

    let socialLinksValues = { ...Utilities.socialLinkValues },
        socialKeyValue = { key: '', value: '', custom: '0' },
        socialLogoUrl = `${process.env.REACT_APP_img_folder}/icons/black`;

    const popupSocialMediaLibraryStatus = useSelector(state => state.showPopupSocialMediaLibrary);

    const [socialLinks, setSocialLinks] = useState(socialLinksValues);
    const [socialLinksDetail, setSocialLinksDetail] = useState(socialKeyValue);
    const [showPopup, setShowPopup] = useState(false);

    const submitCompanySocial = () => {
        props.submitCompanySocial(socialLinks);
    }

    const handleClosePopup = () => {
        setShowPopup(false);
    }

    const handleClickSocial = (key = '', val = '', custom = '0') => {
        key = key.toLowerCase();

        setSocialLinksDetail({ ...socialKeyValue, key: key.toString(), value: val.toString(), custom: custom.toString() });
        setShowPopup(true);
    }

    const handleClickSubmitDetail = (detail = socialKeyValue) => {

        if (!Utilities.isEmpty(detail.key ?? '') && !Utilities.isEmpty(detail.value ?? '')) {

            const socialKey = detail.key.toLowerCase();
            let socialLinkTemp = { ...socialLinks };

            if (socialLinksDetail.custom == "1") {
                socialLinkTemp = {};
                Object.entries(socialLinks).map(([k, v]) => {
                    if (k.toLowerCase() != socialLinksDetail.key.toLowerCase()) {
                        socialLinkTemp[k] = v;
                    }
                });
            }

            socialLinkTemp[socialKey] = detail.value ?? '';
            setSocialLinks({ ...socialLinkTemp });
        }

        setShowPopup(false);
    }

    const handleClickAdd = () => {
        handleClickSocial('', '', '1');
    }

    const init = () => {
        setSocialLinks({ ...socialLinksValues, ...props_socialLinksValues });
    }

    useEffect(() => {
        init();

    }, [props_socialLinksValues]);


    useEffect(() => {
        const popupStatus = popupSocialMediaLibraryStatus ?? false;
        if (popupStatus) {
            handleClickSocial('', '', '1');
            dispatch(showPopupSocialMediaLibraryReducer(false));
        }

    }, [popupSocialMediaLibraryStatus]);


    return (
        <>
            <div id="form-company-social">

                <ul className='company-social-links text-center text-lowercase'>
                    {
                        Object.entries(socialLinks).map(([key, sVal]) =>
                            <li className={`
                                    cursor-pointer 
                                    ${Utilities.isEmpty(sVal) ? '' : 'active'}
                                    ${!socialLinksValues.hasOwnProperty(key) ? 'custom' : ''}
                                `} onClick={() => handleClickSocial(key, sVal, socialLinksValues.hasOwnProperty(key) ? '0' : '1')}>
                                <div className='logo-social' style={{ backgroundImage: `url(${socialLogoUrl}/outline-circle-social-${key}.svg)` }}>
                                    {Utilities.socialLinkNamesLogoFormatter(key)}
                                </div>
                                <div className='ellipsis-1'>{Utilities.socialLinkNamesFormatter(key)}</div>
                            </li>
                        )
                    }
                </ul>

                <div className={`${pros_showAddButton ? ' button-group-boolean col-md-8' : 'col-md-5'} mt-5 pt-4 mx-auto `}>
                    {
                        pros_showAddButton && <button type="button" className=" btn-theme-black" onClick={() => handleClickAdd()}>add</button>
                    }
                    <button type="button" className="btn-theme-black" onClick={() => submitCompanySocial()}>submit</button>
                </div>

            </div>

            <SocialLinkFormPopup showPopup={showPopup} handleClosePopup={handleClosePopup} detail={socialLinksDetail} handleClickSubmitDetail={handleClickSubmitDetail} />
        </>

    );
}


export const SocialLinkFormPopup = (props) => {
    const showPopup = props.showPopup,
        handleClosePopup = props.handleClosePopup,
        detail = props.detail;

    const [socialLinksDetail, setSocialLinksDetail] = useState(detail);

    const handleClickSubmit = () => {
        const inputPlatform = 'social_links_platform',
            inputUrl = 'social_links_url';

        Utilities.removeFormGroupClass(inputPlatform);
        Utilities.removeFormGroupClass(inputUrl);

        let errorCounter = 0;
        if (Utilities.isEmpty(socialLinksDetail.key)) {
            Utilities.addFormGroupClass(inputPlatform);
            errorCounter++;
        } else {

            const hasSpecialCharsBool = Utilities.checkSpecialChar(socialLinksDetail.key);
            const wordsCounter = Utilities.wordsCounter(socialLinksDetail.key);

            if (wordsCounter >= 2 || hasSpecialCharsBool) {
                Utilities.addFormGroupClass(inputPlatform);
                errorCounter++;
            }

        }

        if (Utilities.isEmpty(socialLinksDetail.value)) {
            Utilities.addFormGroupClass(inputUrl);
            errorCounter++;
        } else {

            if (detail.custom.toString() == "1") {
                if (!Utilities.isValidURL(socialLinksDetail.value)) {
                    Utilities.addFormGroupClass(inputUrl);
                    errorCounter++;
                    Utilities.messagePopup('error', TitlesLabels.alertMessages.opsInvalidUrl);
                }
            } else {

                const hasSpecialCharsBool = Utilities.checkSocialSpecialChar(socialLinksDetail.value);
                const wordsCounter = Utilities.wordsCounter(socialLinksDetail.value);

                if (wordsCounter >= 2 || hasSpecialCharsBool) {
                    Utilities.addFormGroupClass(inputUrl);
                    errorCounter++;
                    Utilities.messagePopup('error', TitlesLabels.alertMessages.invalidUsername);
                }
            }

        }

        if (errorCounter > 0) { return false; }
        props.handleClickSubmitDetail(socialLinksDetail);
    }



    const init = () => {
        setSocialLinksDetail({ ...detail });
    }

    useEffect(() => {
        init();

    }, [detail]);

    return (
        <Modal id="popup-social-links-form" show={showPopup} onHide={handleClosePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme" keyboard={true}>
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4">add social media link</Modal.Header>
            <Modal.Body className="pt-4 pb-5">

                <div className="col-11 mx-auto  form-theme">

                    <div className={`form-group ${socialLinksDetail.custom == "0" ? 'opacity-05' : ''}`}>
                        <input type="text" className="form-control text-lowercase" name="social_links_platform" placeholder=" " autoComplete="off" disabled={socialLinksDetail.custom == "0" ? true : false} value={socialLinksDetail.key ?? ''} onChange={(e) => {
                            setSocialLinksDetail({ ...socialLinksDetail, ...{ key: e.target.value } });
                            Utilities.removeFormGroupClass('social_links_platform');
                        }}  ></input>
                        <label className="form-label">platform</label>
                    </div>


                    <div className="form-group">
                        {
                            (detail.custom ?? '0') == '0' &&
                            <span style={{ position: 'absolute', top: '8px', left: '13px' }}>/</span>
                        }

                        <input type="text" className={`form-control text-lowercase ${(detail.custom ?? '0') == '0' ? 'pl-4' : ''}`} name="social_links_url" placeholder=" " autoComplete="off" value={socialLinksDetail.value ?? ''} onChange={(e) => {
                            setSocialLinksDetail({ ...socialLinksDetail, ...{ value: e.target.value } });
                            Utilities.removeFormGroupClass('social_links_url');
                        }} ></input>
                        <label className="form-label bg-white">{(detail.custom ?? '0') == '0' ? 'username' : 'link'}</label>
                    </div>

                    <div className="button-group-boolean mt-5">
                        <button type="button" id="btn-submit-cancel" className="btn-theme-black button-has-loader" onClick={handleClosePopup}>cancel</button>
                        <button type="button" id="btn-submit-submit" className="btn-theme-black button-has-loader" onClick={() => handleClickSubmit()}>submit</button>
                    </div>

                </div>

            </Modal.Body>
        </Modal>
    )
}