import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jQuery from "jquery";
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';


import { askPermissionToReceiveNotifications } from '../components/firebase/PushNotification';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import { sendVerificationCode } from '../helpers/SendingEmails';

import PagesAuthentication from '../helpers/PagesAuthentication';
import { showLoader, showHeader, showFooter, pageTitle } from '../actions';
import { checkEmailExistance, checkMobileNumberExistance, updateEmailMobileCredentail } from '../helpers/VerificationUtilities';
import SignupHeader from '../layout/SignupHeader';
import SessionUtilities from '../helpers/SessionUtilities';
import { redirectToDownloadAppFromCampaign } from '../helpers/UtilitiesCampaign';
import MobileSelection from '../components/MobileSelection';
import { followGlanceCompany, sendJazenetBeonchatMessage } from '../helpers/QueryPersonalSignup';
//import { updateClicksAccount } from '../helpers/QueryCampaign';



const AccountVerification = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN = process.env.REACT_APP_chat_api_token;

    const defaultLocation = Utilities.defaultLocation();
    const msgSendError = TitlesLabels.alertMessages.couldNotSendCodeTryAgain;
    const notificationValuesDefault = { fcm_token: '', utc_date: '', brower_id: '' };

    let defaultFormValues = {
        email_address: '',
        phonecode_country_id: defaultLocation.country_id ?? 0,
        mobile_phonecode: defaultLocation.country_ext ?? '',
        mobile_number: '',
    }

    const inputPincodeStyle = { width: 65, height: 74 };

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [personalInfo, setPersonalInfo] = useState({});
    const [changeVerification, setChangeVerification] = useState(false);
    const [verificationType, setVerificationType] = useState(2);

    const [emailAddress, setEmailAddress] = useState('');
    const [passwordUser, setPasswordUser] = useState('');
    const [accountID, setAccountID] = useState(0);
    const [countDown, setCountDown] = useState('05:00');
    const [showResendButton, setShowResendButton] = useState(false);

    const [timerInterval, setTimerInterval] = useState('');
    const [notificationValues, setNotificationValues] = useState(notificationValuesDefault);

    const [countryList, setCountryList] = useState([]);
    const [showMobileMsg, setShowMobileMsg] = useState(false);
    const [showEmailMsg, setShowEmailMsg] = useState(false);

    let globalRecordTimer = '';

    const messagePinPopup = (title = '', message = '') => {

        confirmAlert({
            title: title,
            message: message,
            overlayClassName: title.toLowerCase(),
            buttons: [
                {
                    label: 'Close',
                    onClick: () => {
                        jQuery('.codes-input input').val('');
                        document.getElementById('code_1').focus();
                    }
                }
            ]
        });
    }

    const timerCountDown = (action = 1) => {

        let countDownTemp = '05:00'; //countDown;
        if (action === 0) {
            stopTimer();
        }

        let timer_split = countDownTemp.split(":");
        let min_split = parseInt(timer_split[0]);
        let sec_split = parseInt(timer_split[1]);

        globalRecordTimer = setInterval(function () {

            sec_split = parseInt((sec_split === 0) ? 60 : sec_split);
            sec_split--;

            let sec_str = sec_split.toString();
            if (sec_str.length === 1) { sec_str = '0' + sec_str; }

            if (sec_split === 59) { min_split--; }

            let min_str = min_split.toString();
            if (min_str.length === 1) { min_str = '0' + min_str; }

            let timer_init = min_str + ':' + sec_str;
            setCountDown(timer_init);

            if (timer_init === '00:00') {
                stopTimer();
                clearInterval(globalRecordTimer);
                setShowResendButton(true);
                //Utilities.messagePopup('error', TitlesLabels.alertMessages.titlesUpSendOtherCode );
            }

            if (action === 0) {
                clearInterval(timerInterval);
                clearInterval(globalRecordTimer);
            }

        }, 1000);

        if (action === 0) {
            clearInterval(timerInterval);
            clearInterval(globalRecordTimer);
        } else {
            setTimerInterval(globalRecordTimer);
        }
    }

    const codeFocus = (e, type = '') => {
        let value = e.target.value;
        if (!Utilities.isEmpty(value)) {
            jQuery(e.target).next('input').val('').focus();
        }
        if (type === 'last') {
            jQuery('input#dummy').focus();
            verifyCodes();
        }
    }

    const codeBack = (e) => {
        const key = parseInt(e.keyCode || e.charCode);

        if (key === 8) {
            jQuery(e.target).prev('input').val('').focus();
        }
    }

    const codeReset = (e) => {
        jQuery(e.target).val('');
    }

    const stopTimer = () => {
        setCountDown('00:00');
        clearInterval(timerInterval);
        clearInterval(globalRecordTimer);
    }

    const resendPincodeConfirm = () => {
        confirmAlert({
            title: 'warning',
            message: TitlesLabels.confirmMessages.areYouSureResendCode,
            overlayClassName: 'normal-font-style warning',
            buttons: [
                { label: 'no', onClick: () => { } },
                { label: 'yes', onClick: () => resendPincode() }
            ]
        });

    }

    const resendPincode = async (hideLoaderBool = false) => {


        stopTimer();
        timerCountDown(0);


        jQuery('.codes-input input').val('');
        document.getElementById('code_1').focus();

        const pincode = Utilities.generateSignupCode();
        const request_id = accountID;

        const button_name = 'btn-resend-code';
        Utilities.elementDisabledStatus(button_name, false);

        dispatch(showLoader(0));

        if (request_id > 0) {

            const personalInfoUpdated = PagesAuthentication.getSignupPersonalInfo();
            const TOKEN_SESSION = SessionUtilities.getSessionToken();

            /* const passvalVerification = {
                 api_token: TOKEN.toString(),
                 flag: "1",
                 account_id: request_id.toString(),
                 name: personalInfoUpdated.first_name + ' ' + personalInfoUpdated.last_name,
                 email: personalInfoUpdated.email,
                 phone_number: '+' + personalInfoUpdated.mobile_phonecode.toString() + personalInfoUpdated.mobile_number.toString(),
                 code: pincode.toUpperCase()
             }; */

            const passvalVerification = {
                api_token: TOKEN_SESSION.toString(),
                account_id: request_id.toString(),
                phone_number: personalInfoUpdated.mobile_phonecode.toString() + personalInfoUpdated.mobile_number.toString(),
                verif_code: pincode.toUpperCase()
            };

            !hideLoaderBool && dispatch(showLoader(1));
            Utilities.elementDisabledStatus(button_name, true);

            const responSendingVeification = await sendVerificationCode(passvalVerification);
            !hideLoaderBool && dispatch(showLoader(0));
            Utilities.elementDisabledStatus(button_name, false);

            setShowResendButton(false);

            if (Object.keys(responSendingVeification).length === 0) {
                Utilities.messagePopup('error', msgSendError); //msgSendError
                return false;
            }

            if (parseInt(responSendingVeification.success) === 1) {
                Utilities.messagePopup('success', TitlesLabels.alertMessages.successSentVeificationCode);
                timerCountDown();
            } else {
                Utilities.messagePopup('Error', responSendingVeification.errorMessage);
            }


        }
    }

    const sendJazenetMessage = async (profile, redirect = false) => {
        dispatch(showLoader(1));
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        const passval = { api_token: TOKEN_SESSION.toString(), account_id: profile.account_id.toString() };

        await sendJazenetBeonchatMessage(passval);
        await followGlanceCompany(profile.account_id.toString(), profile.city_id.toString());
        dispatch(showLoader(0));

        if (redirect) {
            history.push(`/${UrlSlugs.dashboard}`);
        }
    }


    const loginUser = async () => {

        if (!Utilities.isEmpty(emailAddress) && !Utilities.isEmpty(passwordUser)) {
            dispatch(showLoader(1));

            const windowNavigator = window.navigator;
            const generatedToken = Utilities.generateToken().toString();  //SessionUtilities.getSessionToken();  //

            await axios
                .post(ApiUrls.userGetByCredentail, {
                    ...notificationValues,
                    email: emailAddress,
                    password: passwordUser,
                    browser_name: windowNavigator.appVersion.toLowerCase(),
                    token_request: generatedToken
                })
                .then(async response => {

                    dispatch(showLoader(0));
                    const resStatus = response.status;

                    if (resStatus === 200) {
                        const resData = response.data;
                        const resLoginType = parseInt(resData.logintype);

                        if (resData.status === 1) {

                            if (parseInt(resData.detail.status) === 1) {

                                SessionUtilities.setSessionToken(generatedToken);

                                localStorage.setItem(
                                    process.env.REACT_APP_session_logintype,
                                    JSON.stringify(resLoginType)
                                );

                                localStorage.setItem(
                                    process.env.REACT_APP_session_login,
                                    JSON.stringify(resData.detail)
                                );

                                localStorage.setItem(
                                    process.env.REACT_APP_session_login_started,
                                    Date.now()
                                );

                                localStorage.setItem(
                                    process.env.REACT_APP_session_accounts,
                                    JSON.stringify(resData.accounts)
                                );


                                SessionUtilities.setSignupWelcomeMessage(1);

                                // CHECK IF THERE IS CAMPAIN -> redirect to signup company information
                                const signupCampaignDetail = SessionUtilities.getSignupCampaign();

                                if (Object.keys(signupCampaignDetail ?? {}).length > 0) {
                                    const campaign_pagetype = parseInt(signupCampaignDetail.type ?? 0);
                                    if (campaign_pagetype > 0) {

                                        if (campaign_pagetype == 1) {
                                            localStorage.setItem(process.env.REACT_APP_session_welcome_mesage, 1);
                                        }

                                        await sendJazenetMessage(resData.detail, false);
                                        redirectToDownloadAppFromCampaign(history);

                                        return false;
                                    }
                                }

                                sendJazenetMessage(resData.detail, true);
                                SessionUtilities.removeSignupCampaignDownload();
                                //localStorage.setItem( process.env.REACT_APP_session_welcome_mesage, 1 );
                                //sendJazenetMessage( resData.detail );


                            } else {
                                Utilities.messagePopup('error', Utilities.labelAccountNotActive);
                            }
                        } else {
                            Utilities.messagePopup('error', errorMsg);
                        }
                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }

                })
                .catch((err) => {
                    Utilities.messagePopup('error', errorMsg);
                    dispatch(showLoader(0));
                });
        }
    }

    const verifyCodes = async () => {

        if (showResendButton) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.titlesUpSendOtherCode);
            return false;
        }

        const request_id = accountID;
        let input_codes = '';
        jQuery('.codes-input input').each(function () {
            input_codes += jQuery(this).val();
        });

        if (!Utilities.isEmpty(input_codes)) {

            dispatch(showLoader(1));
            const generatedToken = SessionUtilities.getSessionToken(); //Utilities.generateToken().toString();

            await axios
                .post(ApiUrls.userVerifyCode, { id: request_id, pincode: input_codes, token_request: generatedToken })
                .then(response => {
                    const msgSendError = TitlesLabels.alertMessages.incorrectCode;

                    dispatch(showLoader(0));

                    if (response.status === 200) {

                        const returnStatus = parseInt(response.data);
                        if (returnStatus > 0) {



                            // DIRECT LOGIN if was signup by social
                            const socialSignupConnect = PagesAuthentication.getSignupSocialDetail();

                            if (Object.keys(socialSignupConnect).length > 0) {
                                if (parseInt(socialSignupConnect.type ?? 0) > 0) {

                                    stopTimer();
                                    loginUser();
                                    return false;
                                }
                            }

                            confirmAvatarCreation('', TitlesLabels.confirmMessages.wishToAddProfileImage);

                        } else {
                            messagePinPopup('error', msgSendError);
                        }

                    } else {
                        messagePinPopup('error', msgSendError);
                    }

                })
                .catch((err) => {
                    Utilities.messagePopup('error', errorMsg);
                    dispatch(showLoader(0));
                });
        }
    }

    const confirmAvatarCreation = (title = '', message = '') => {
        stopTimer();

        confirmAlert({
            title: title,
            message: message,
            buttons: [
                { label: 'No', onClick: () => { loginUser() } },
                {
                    label: 'Yes',
                    onClick: () => {
                        stopTimer();
                        history.push(UrlSlugs.uploadLogo);
                    }
                }
            ]
        });
    }

    const updateNotificationValues = async () => {

        const timeUTC = moment.utc();
        let formValuesTemp = notificationValues;

        let fcmToken = await askPermissionToReceiveNotifications();
        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString();
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex);

        if (Utilities.isEmpty(fcmToken)) {
            const fcmTokenTemp = localStorage.getItem(process.env.REACT_APP_fs_fcm_token);
            fcmToken = JSON.parse(fcmTokenTemp) || '';

        } else {
            localStorage.setItem(
                process.env.REACT_APP_fs_fcm_token,
                JSON.stringify(fcmToken)
            );
        }

        formValuesTemp = {
            ...formValuesTemp,
            ...{
                fcm_token: fcmToken.toString(),
                utc_date: dateFormatted.toString(),
                brower_id: browserUniqueID.toString(),
            }
        };
        setNotificationValues(formValuesTemp);

    }

    const handleOnClickChange = (actionName = '') => {

        if (actionName === 'change') {
            setChangeVerification(true);

        } else if (actionName === 'back') {
            setChangeVerification(false);
        }

        // reset fields
        setVerificationType(2);
        setFormValues(defaultFormValues);

    }

    const handleClickResentVerification = async () => {
        setChangeVerification(false);

        if (verificationType === 1) {

            // Checking email address if exist in database
            dispatch(showLoader(1));
            const responseEmailCheckingDB = await checkEmailExistance(formValues.email_address.toString());
            dispatch(showLoader(0));

            if (Object.keys(responseEmailCheckingDB).length > 0) {
                if (parseInt(responseEmailCheckingDB.exist) === 1) {
                    Utilities.messagePopup('Error', Utilities.labelEmailAddressAlreadyExist);
                    return false;
                }
            }


            // Update Email adress
            dispatch(showLoader(1));
            const responseEmailUpdateDB = await updateEmailMobileCredentail({
                account_id: personalInfo.account_id.toString(),
                email_address: formValues.email_address.toString(),
                type: 1
            });
            dispatch(showLoader(0));

            if (Object.entries(responseEmailUpdateDB).length === 0) {
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotProcessRequest);
                return false;
            }

            if (parseInt(responseEmailUpdateDB.status) === 0) {
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotProcessRequest);
                return false;
            }

            setEmailAddress(formValues.email_address.toString());

            const personalInfoTemp = { ...personalInfo, ...{ email: formValues.email_address.toString() } };
            PagesAuthentication.setSignupPersonalInfo(personalInfoTemp);

            setPersonalInfo(personalInfoTemp);


        } else if (verificationType === 2) {

            // Checking mobile number if exist in database
            dispatch(showLoader(1));
            const responseMobileCheckingDB = await checkMobileNumberExistance(formValues.phonecode_country_id, formValues.mobile_number);
            dispatch(showLoader(0));

            if (Object.keys(responseMobileCheckingDB).length > 0) {
                if (parseInt(responseMobileCheckingDB.exist) === 1) {
                    Utilities.messagePopup('Error', Utilities.labelMobileNumberAlreadyExist);
                    return false;
                }
            }

            // Update mobile number
            dispatch(showLoader(1));
            const responseMobileUpdateDB = await updateEmailMobileCredentail({
                account_id: personalInfo.account_id.toString(),
                phonecode_country_id: formValues.phonecode_country_id.toString(),
                mobile_number: formValues.mobile_number.toString(),
                type: 2
            });
            dispatch(showLoader(0));

            if (Object.entries(responseMobileUpdateDB).length === 0) {
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotProcessRequest);
                return false;
            }

            if (parseInt(responseMobileUpdateDB.status) === 0) {
                Utilities.messagePopup('Error', TitlesLabels.alertMessages.couldNotProcessRequest);
                return false;
            }


            const personalInfoTemp = {
                ...personalInfo,
                country_code: formValues.phonecode_country_id.toString(),
                mobile_phonecode: formValues.mobile_phonecode.toString(),
                mobile_number: formValues.mobile_number.toString()
            };
            PagesAuthentication.setSignupPersonalInfo(personalInfoTemp);

            setPersonalInfo(personalInfoTemp);

        }


        resendPincode();
    }

    const changeCountryCode = (passValues) => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, phonecode_country_id: passValues.id, mobile_phonecode: passValues.phonecode ?? '' };
        setFormValues(formValuesTemp);

    }



    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0;

        if (fieldName === 'email_address') {

            setShowEmailMsg('');
            if (!Utilities.isEmpty(value) && !Utilities.isEmail(value)) {
                //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(fieldName);
                setShowEmailMsg(TitlesLabels.general.emailAddressInvalid);
                errorCounter++;
            }

            value = value.replace(' ', '');
            formValuesTemp = { ...formValuesTemp, ...{ email_address: value, mobile_number: '' } };

        } else if (fieldName === 'mobile_number') {

            let mobile_number = value.replace(' ', '');
            setShowMobileMsg('');
            if (!Utilities.isEmpty(mobile_number) && !Utilities.contactNumberValidate(mobile_number, 1).status) {
                setShowMobileMsg(Utilities.contactNumberValidate(mobile_number, 1).msg);
                //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(fieldName);
                errorCounter++;
            }

            formValuesTemp = { ...formValuesTemp, ...{ mobile_number: parseInt(value) | '', email_address: '' } };
        }

        if (errorCounter === 0) {
            //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
            Utilities.removeFormGroupClass(fieldName);
        }

        setFormValues(formValuesTemp);
    }

    const verificationOptionHtml = () => {

        if (verificationType === 1) {
            return <>
                <div className="fs-16 pb-2">please enter new email address</div>
                <div className="form-group">
                    <input type="email" id="input-email" name="email_address" className="form-control text-center" placeholder=" " autoComplete="new-password" value={formValues.email_address} onChange={(e) => pushValue(e, 'email_address')}  ></input>
                    <label className="form-label">Email</label>
                </div>

                {(!Utilities.isEmpty(showEmailMsg) && !Utilities.isEmpty(formValues.email_address)) && <div className="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">{showEmailMsg}</div>}
            </>

        } else if (verificationType === 2) {
            return <>
                <div className="fs-16 pb-2">please enter new mobile number</div>
                <div>
                    <MobileSelection inputValue={formValues.mobile_number} countryListProvided={countryList} inputLabel="Mobile Number" countryCodeProvided={formValues.phonecode_country_id} inputListingId="landline-input-list" inputName="mobile_number" pushValue={pushValue} changeCountryCode={changeCountryCode} alignment={`center`} >
                    </MobileSelection>
                </div>
            </>
        }
    }

    const getCountries = async () => {
        await axios
            .post(ApiUrls.country, {})
            .then(response => {
                if (response.status === 200) {
                    setCountryList(response.data);
                }
            })
            .catch((err) => {

            });
    }

    const formattedEmailMobileText = (type = 1) => {

        let mobileNumberHidden = [], emailHidden = [];

        let mobileNumber = personalInfo.hasOwnProperty('mobile_number') ? personalInfo.mobile_number : '';
        let emailAddress = personalInfo.hasOwnProperty('email') ? personalInfo.email : '';

        if (type === 1 && !Utilities.isEmpty(mobileNumber)) {
            [...Array(mobileNumber.length).keys()].map(num => {
                mobileNumberHidden.push(parseInt(num) < (parseInt(mobileNumber.length) - 4) ? 'x' : mobileNumber[num]);
            });

            return mobileNumberHidden.join('');

        } else if (type === 2 && !Utilities.isEmpty(emailAddress)) {

            [...Array(emailAddress.split('@')[0].length - 1).keys()].map(num => {
                emailHidden.push('x');
            });

            return emailAddress.split('')[0] + emailHidden.join('') + '@' + emailAddress.split('@')[1];
        }

    }

    useEffect(async () => {

        dispatch(pageTitle(TitlesLabels.siteTitles.digitVerification));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        PagesAuthentication.checkLoginStatus();

        //const signupCampaignDetail = SessionUtilities.getSignupCampaign();
        //console.log(signupCampaignDetail);
        //redirectToDownloadAppFromCampaign( history );

        updateNotificationValues();

        stopTimer();
        timerCountDown(0);
        timerCountDown();



        // APPLY SIGNUP PERSONAL
        const session_signupPersonalInfo = PagesAuthentication.getSignupPersonalInfo();
        if (Object.keys(session_signupPersonalInfo).length > 0) {

            setEmailAddress(session_signupPersonalInfo.email);
            setAccountID(session_signupPersonalInfo.account_id);
            setPasswordUser(session_signupPersonalInfo.password);

            setPersonalInfo(session_signupPersonalInfo);

            await getCountries();

            let defaultFormValuesTemp = {
                email_address: session_signupPersonalInfo.email ?? '',
                phonecode_country_id: session_signupPersonalInfo.country_id ?? 0,
                mobile_phonecode: session_signupPersonalInfo.mobile_phonecode ?? '',
                mobile_number: session_signupPersonalInfo.mobile_number ?? '',
            }

            setFormValues(defaultFormValuesTemp);


        }


        return () => {
            stopTimer();
        }

    }, []);

    return (
        <div className='signup-page with-footer centerialized   text-lowercase'>

            <div className='signup-wrap signup-wrap-verification position-relative h-100 d-flex align-items-center pb-5 justify-content-center  px-4 p-md-0'>

                <div>
                    <SignupHeader title={changeVerification === false ? 'Account Verification' : 'change verification details'} />

                    <div className='py-3'></div>

                    {
                        changeVerification === false ?
                            <>
                                <div className='col-md-6- mx-auto color-theme-grey'>
                                    Please enter 4-digit verification code<br />
                                    sent your mobile number +{personalInfo.mobile_phonecode ?? ''}{formattedEmailMobileText(1)}
                                </div>

                                <div className='py-2'></div>

                                <div className="login-form  text-lowercase form-theme ">

                                    <input type="text" id="dummy" className="opacity-0" readOnly ></input>

                                    <div className="codes-wrap py-4 mt-n4">
                                        <div className="codes-input">
                                            <input type="text" id="code_1" name="code[]" onKeyUp={(e) => codeFocus(e)} onClick={(e) => codeReset(e)} autoFocus autoComplete="new-password" className='border-0 bg-gray text-center mr-2 fs-25' style={inputPincodeStyle}></input>
                                            <input type="text" name="code[]" onKeyDown={(e) => codeBack(e)} onKeyUp={(e) => codeFocus(e)} onClick={(e) => codeReset(e)} autoComplete="new-password" className='border-0 bg-gray text-center mr-2 fs-25' style={inputPincodeStyle}></input>
                                            <input type="text" name="code[]" onKeyDown={(e) => codeBack(e)} onKeyUp={(e) => codeFocus(e)} onClick={(e) => codeReset(e)} autoComplete="new-password" className='border-0 bg-gray text-center mr-2 fs-25' style={inputPincodeStyle}></input>
                                            <input type="text" name="code[]" onKeyDown={(e) => codeBack(e)} onKeyUp={(e) => codeFocus(e, 'last')} onClick={(e) => codeReset(e)} autoComplete="new-password" className='border-0 bg-gray text-center fs-25' style={inputPincodeStyle} ></input>
                                        </div>
                                    </div>


                                    <div className='text-center color-theme-grey fs-16'>
                                        {
                                            !showResendButton ?
                                                <div id="timer" className="timer-wrap">{countDown}</div>
                                                :
                                                <button type="button" id="btn-resend-code" className="btn-theme-black button-has-loader   mx-auto" onClick={() => resendPincode(true)}>resend</button>
                                        }

                                    </div>
                                </div>

                                <div className='position-absolute w-100 bottom-0 start-0'>
                                    <div className='color-theme-warning'>didn’t receive a code? <span className=' color-theme-green cursor-pointer' onClick={() => resendPincodeConfirm()}>resend</span></div>
                                    <div className="cursor-pointer" onClick={() => handleOnClickChange('change')}>change phone number</div>
                                </div>

                            </>
                            :
                            <>
                                <div className="login-form change-verification-option pb-4 mx-auto  text-lowercase form-theme">

                                    {verificationOptionHtml()}

                                    <div className="pt-5">
                                        <button type="submit" id="btn-submit-change-verification" className="btn-theme-black button-has-loader col-10 mx-auto" onClick={() => handleClickResentVerification()}>save</button>
                                        <div className="pt-3 text-center">
                                            <span className="color-theme-green cursor-pointer" onClick={() => handleOnClickChange('back')}>back</span>
                                        </div>
                                    </div>

                                </div>
                            </>
                    }



                </div>

            </div>

            <div className='pb-5 pt-5 pt-md-0'>
                <Link to={`/${UrlSlugs.signup_persoanlInfo}`} onClick={() => stopTimer()}>{TitlesLabels.general.exitWithoutSaving}</Link>
            </div>

        </div>
    )
}

export default AccountVerification;