import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import TitlesLabels from '../../helpers/TitlesLabels';
import { pageTitle, showFooter, showHeader } from '../../actions';
import {  generateGetOwnReferralLink, getRefferalDetailByAccountId } from '../../helpers/QueryReferral';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import Utilities from '../../helpers/Utilities';
import SessionUtilities from '../../helpers/SessionUtilities';
import LinkCopiedTooltip from '../../components/common/LinkCopiedTooltip';



const BusinessCompetition = () => {

    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const siteTitle = process.env.REACT_APP_site_title;

    const [ showProcessing, setShowProcessing ] = useState(false);
    const [ referralLink, setReferralLink ] = useState('');
    const [ masterDetail, setMasterDetail ] = useState({});
    const [ showShareTooltip , setShowShareTooltip ] = useState(false);

   
    const generateReferralLink = async () => {

        const masterAcountId = parseInt(masterDetail.account_id??0);
        let companyId = 0;

        if ( parseInt(masterAcountId)===0 ){
            return false;
        }

        const teamLinked = SessionUtilities.getAllTeamMembersLinkedFromMaster();
        if ( teamLinked.length>0 ){
            companyId = parseInt(teamLinked[0].team_company_id??0);
        }

        const button_name = 'btn-generate-referral';

        Utilities.elementDisabledStatus(button_name, true );

        const passval = {
            account_id :  masterAcountId.toString(),
            company_id : companyId.toString(),
            campaign_id : '0'
        };

        const ersponseDB = await generateGetOwnReferralLink(passval),
            status = parseInt(ersponseDB.status??0);

        Utilities.elementDisabledStatus(button_name, false );

        if ( status>0 ){
            setReferralLink(ersponseDB.link);

        }else{
            Utilities.messagePopup('error', errorMsg );  
        }

    }

        
    const checkExistingReferralLink = async ( masterAcountId = 0 ) => {
        const passval = {
            account_id :  masterAcountId.toString()
        };

        setShowProcessing(true);
        const responsodeDB = await getRefferalDetailByAccountId(passval),
                linkResponse = responsodeDB.link??'';
        setShowProcessing(false);

        setReferralLink( linkResponse );

    }


    const handleClickCopyLink = () => {
        Utilities.copyContent('sort_link_url');
        setShowShareTooltip(true);
        setTimeout( () => {
            setShowShareTooltip(false);
        }, 1000);
    }


    useEffect( () => {
        dispatch( pageTitle( TitlesLabels.siteTitles.businessBoostCompetition ) );
        dispatch( showHeader(1) );
        dispatch( showFooter(0) );
        
        const masterAccountSession = PagesAuthentication.getMasterUserDetailDetail();
        if ( Object.keys(masterAccountSession??{}).length>0 ){

            setMasterDetail(masterAccountSession);
            checkExistingReferralLink( masterAccountSession.account_id );

        }

        
       
    }, [] );

    return (
        <div className="boost-competition-page">

             <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                        <li>
                            <div className="current">{ TitlesLabels.siteTitles.businessBoostCompetition }</div>
                           
                          </li>
                         <li className="btn-option"></li>
                     </ul>
                </div>
            </div>


            <div className="main-content">
                <div className="container pt-5">
                    
                    
                        <div className='boost-box-wrap  p-4 mx-auto  text-white'>
                            <div className='boost-box-content mx-auto pl-4 pr-5 pt-4 mt-3 fs-18 fs-md-18'>
                                    <div className='font-gotham-bold fs-31 fs-md-30 line-height-1'>
                                        <div>business</div>
                                        <div>boost</div>
                                    </div>

                                    <div className='mt-3 text-lowercase'>as an existing business registered on {siteTitle}, you will receive a double ticket for every business you refer to that signs up during our competion which is offering a lucky winner</div>

                                    <div className='color-theme-green-3 fs-60  fs-md-50 font-gotham-bold'>R100 000</div>

                                    <div className='fs-14 fs-md-14 color-theme-grey'>t's & c's apply</div>

                                    <div className='mt-3' style={{ minHeight: 50 }}>
                                        {
                                            showProcessing ?
                                                 <div className='content-loader animate opacity-05'></div>
                                             :

                                                    Utilities.isEmpty(referralLink) ?
                                                        <button type='button' id="btn-generate-referral" className='btn-theme-green button-has-loader' style={{ height: 50}} onClick={ () => generateReferralLink() }>generate referral link</button>
                                                    :
                                                    <>
                                                        <input id="sort_link_url" type="text" className="d-none" value={referralLink} />
                                                        <button type="button" className='btn-theme-green bg-white cursor-auto position-relative' style={{ height: 50, color : 'black'}} onClick={ () => handleClickCopyLink() } >
                                                            {referralLink}
                                                            <LinkCopiedTooltip boolShow={showShareTooltip} styleAttr={{ left: '42%' }} />
                                                        </button>
                                                        <div className='text-center color-theme-green fs-13 fs-md-14 font-gotham-bold mt-3'>please copy and forward this link to your referral parties</div>
                                                    </>
                                        }
                                       
                                    </div>
                            </div>
                        </div>


                </div>
            </div>
       </div>
    )
}

export default BusinessCompetition;