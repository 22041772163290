import axios from 'axios';
import ApiUrls from './ApiUrls';

export const getCompanyNewRegistered = async (passval = {}) => {

    let responseDetail = [];

    if (Object.keys(passval).length > 0) {

        try {
            //console.log('getCompanyNewRegistered');
            //console.log(ApiUrls.getCompanyRegisteredNew);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.getCompanyRegisteredNew, passval);

            if (parseInt(responseURL.data.success ?? 0) === 1) {
                responseDetail = Object.keys(responseURL.data.result.comp_monthly_reg_list).length > 0 ? responseURL.data.result.comp_monthly_reg_list : [];
            }


        } catch (e) { }
    }

    return responseDetail;

}