import React, { useEffect, useState } from "react";
import moment from 'moment';
import Utilities from "../../helpers/Utilities";
import SessionUtilities from "../../helpers/SessionUtilities";
import { DailyGlanceDateSelectionPopup } from "./DailyGlancePopups";


const HeaderDailyGlanceBlack = (props) => {

    const defaultLocation = Utilities.defaultLocation();
    const imagePath = process.env.REACT_APP_img_folder;


    const [currentLocation, setCurrentLocation] = useState(defaultLocation);
    const [currentDateTime, setCurrentDateTime] = useState('');
    const [currentDateTitle, setCurrentDateTitle] = useState('');


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // INITIAL
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const init = () => {
        let userLocation = SessionUtilities.getUserLocation() ?? {};
        if (Object.keys(userLocation).length > 0) {
            setCurrentLocation(userLocation);
        }

        const currentDateTimeTemp = moment().format(Utilities.dateTimeServerStr),
            currentDateTitleTemp = moment().format(Utilities.dateTimeGlanceLabelStr);


        setCurrentDateTime(currentDateTimeTemp);
        setCurrentDateTitle(currentDateTitleTemp);;
    }

    useEffect(() => {

        init();

    }, []);

    return (
        <>
            <div className="header-daily-glance-dark bg-black text-lowercase py-3 header-daily-glance">
                <div className="container d-flex align-items-center justify-content-center py-1">
                    <div className="text-white font-gotham-bold">

                        <div className="d-flex align-center justify-content-center">
                            <div className="text-right">
                                <div className="d-flex align-items-center justify-content-end fs-18 pb-2">
                                    <div className="mt-n4 pb-2">
                                        lenasia
                                        {/* { currentLocation.area_name ? currentLocation.area_name : currentLocation.city_name } */}
                                    </div>

                                    <div className="ml-2 pl-1"><img src={`${imagePath}/logo/logo-circle.svg`} alt="logo" width="65" /></div>
                                </div>
                                <div className="fs-35 title-glance  d-flex align-items-center line-height-1 mt-n5" style={{ letterSpacing: `-1.3px` }}>
                                    <span className="font-myriad-regular fs-76">@</span>
                                    <span className="text-theme-green">a</span>
                                    glance
                                </div>
                            </div>
                        </div>

                        {
                            !Utilities.isEmpty(currentDateTitle ?? '') &&
                            <div className="fs-16 font-gotham-book text-center ">
                                <span>{currentDateTitle ?? ''}</span>

                            </div>
                        }

                    </div>
                </div>
            </div>
        </>
    )

}

export default HeaderDailyGlanceBlack;