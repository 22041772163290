import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import jQuery from 'jquery';

import { showHeader, showFooter, pageTitle } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';
import Utilities from '../helpers/Utilities';

import { appliedNewPasswordChange, forgotPasswordAccessKeyExistance } from '../helpers/VerificationUtilities';
import PasswordInput from '../components/PasswordInput';
import SignupHeader from '../layout/SignupHeader';

const PasswordNew = ({ match }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const defaultFormValues = {
        new_password: '',
        confirm_new_password: ''
    }

    const [verifyingCode, setVerifyingCode] = useState(true);
    const [formValues, setFormValues] = useState(defaultFormValues);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let formValuesTemp = formValues;
        let value = e.target.value;

        if (fieldName === 'new_password') {
            formValuesTemp = { ...formValuesTemp, ...{ new_password: value } };

        } else if (fieldName === 'confirm_new_password') {
            formValuesTemp = { ...formValuesTemp, ...{ confirm_new_password: value } };
        }

        setFormValues(formValuesTemp);
        jQuery('[name="' + fieldName + '"]').closest('.form-group').removeClass('error');

    }


    const submitPassword = async () => {

        let errorCounter = 0;
        const fieldsChecker = ['new_password', 'confirm_new_password'];
        const accountKeyId = match.params.id;

        const button_name = 'btn-submit-new-password';
        Utilities.elementDisabledStatus(button_name, false);


        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {

                if (Utilities.isEmpty(value)) {
                    jQuery('[name="' + key + '"]').closest('.form-group').addClass('error');
                    errorCounter++;
                }
            }
        });

        if (!Utilities.isEmpty(formValues.new_password)) {

            const passwordValidation = Utilities.passwordValidation(formValues.new_password);
            if (passwordValidation.response === false) {
                jQuery('[name="new_password"]').closest('.form-group').addClass('error');
                return false;
            }

            if (formValues.new_password !== formValues.confirm_new_password) {
                Utilities.messagePopup('Error', Utilities.labelPasswordConfirmNotSame);
                return false;
            }

        }

        if (errorCounter > 0) { return false; }

        Utilities.elementDisabledStatus(button_name, true);
        const responseInformation = await appliedNewPasswordChange({ access_key: accountKeyId.toString(), new_password: formValues.new_password.toString() });
        Utilities.elementDisabledStatus(button_name, false);

        if (Object.keys(responseInformation).length > 0) {

            const status = parseInt(responseInformation.status)
            if (status === 1) {

                confirmAlert({
                    title: 'success',
                    message: TitlesLabels.alertMessages.profileUpdatedSuccessfully,
                    overlayClassName: `success`,
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => {
                                history.push(`/`);
                            }
                        }
                    ],
                    onClickOutside: () => { history.push(`/`) },
                    onKeypressEscape: () => { history.push(`/`) },

                });

            } else {
                Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotUpdatePassword);
            }


        } else {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotUpdatePassword);
        }

    }


    const validateAccountKey = async () => {
        const accountKeyId = match.params.id;

        if (Utilities.isEmpty(accountKeyId)) {
            retunToHome();

        } else {

            const responseInformation = await forgotPasswordAccessKeyExistance(accountKeyId.toString());
            if (Object.keys(responseInformation).length > 0) {

                const status = parseInt(responseInformation.status)
                if (status === 1) {
                    setVerifyingCode(false);

                } else {
                    retunToHome();
                }

            } else {
                retunToHome();
            }

        }

    }


    const retunToHome = () => {
        confirmAlert({
            title: 'error',
            message: TitlesLabels.alertMessages.opsInvalidUrl,
            overlayClassName: `error`,
            buttons: [
                {
                    label: 'Close',
                    onClick: () => {
                        history.push(`/`);
                    }
                }
            ],
            onClickOutside: () => { history.push(`/`) },
            onKeypressEscape: () => { history.push(`/`) },

        });

    }

    useEffect(() => {
        dispatch(pageTitle(TitlesLabels.siteTitles.newPassword));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        validateAccountKey();
        //setVerifyingCode(false);

    }, []);

    return (

        <div className='signup-page with-footer centerialized   text-lowercase fs-18'>

            {
                verifyingCode === true ?
                    <div className=" d-flex justify-content-center align-items-center fs-18">
                        <div className="text-center">
                            <div className="content-loader animate"></div>
                            <div className="text-gray">Verifying codes, please wait...</div>
                        </div>
                    </div>
                    :

                    <>
                        <div className='signup-wrap text-center'>
                            <SignupHeader title="new password" />


                            <div id="form-new-password" className="form-theme  mt-5 col-md-8 mx-auto text-left" autoComplete="off">

                                <div>
                                    <PasswordInput inputName="new_password" inputLabel="New Password" inputValue={formValues.new_password} inputLabelClass={`text-center`} inputClass={`text-center`} pushValue={pushValue} showChecker={true} />
                                </div>

                                <div>
                                    <PasswordInput inputName="confirm_new_password" inputLabel="Confirm Password" inputClass={`text-center`} inputLabelClass={`text-center`} inputValue={formValues.confirm_new_password} pushValue={pushValue} />
                                </div>

                                <div className="button-wrap text-lowercase text-center mt-5">
                                    <div className="mb-3">
                                        <button type="button" id="btn-submit-new-password" className="btn-theme-black text-lowercase button-has-loader" onClick={() => submitPassword()}>submit</button>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div className='pb-5'>
                            <Link to="/">home page</Link>
                        </div>


                    </>

            }

        </div>

    )
}

export default PasswordNew;