import axios from 'axios';
import ApiUrls from './ApiUrls';


export const getCompanyBranchesByMasterId = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.getCompanyBranchesByMasterId, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;
}


export const getCompanyBranchesByIds = async (idsImplode = '') => {
    let responseDetail = { status: 0 };

    if (idsImplode !== '') {

        try {
            const responseURL = await axios.post(ApiUrls.getCompanyBranchesByIds, { ids: idsImplode });
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;
}


export const getCompaniesByAccountIds = async (idsImplode = '', showAdmin = 0) => {
    let responseDetail = { status: 0 };

    if (idsImplode !== '') {

        try {
            const responseURL = await axios.post(ApiUrls.getCompaniesByAccountIds, { account_ids: idsImplode, showAdmin: showAdmin });
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;
}


export const saveCompanyBranchDetail = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.saveCompanyBranchDetail, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;
}

export const saveCompanyDetailFromBranch = async (formData) => {
    let responseDetail = { status: 0 };

    try {
        const responseURL = await axios.post(ApiUrls.companySubmitCompanyDetail, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        if (parseInt(responseURL.status) === 200) {
            responseDetail = { ...responseDetail, ...responseURL.data };
        }

    } catch (e) { }

    return responseDetail;
}


export const saveCompanyAdminDetailFromBranch = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.profileSignupTeamDetail, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;
}


export const addCompanyIdToMasterById = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.addCompanyIdToMasterById, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;
}


export const copyDefaultBranchLogoToCompany = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.keys(passval).length > 0) {

        try {
            const responseURL = await axios.post(ApiUrls.awsCopyDefaultBranchLogoToCompany, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = { ...responseDetail, ...responseURL.data };
            }

        } catch (e) { }
    }

    return responseDetail;
}




