import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import Utilities from '../../helpers/Utilities';

import { viewMenuPricePopup as viewMenuPricePopupReducer } from '../../actions';
import ApiUrls from '../../helpers/ApiUrls';
import TitlesLabels from '../../helpers/TitlesLabels';


const MenuPricePreview = () => {

    const dispatch = useDispatch();

    const viewMenuPricePopup = useSelector(state => state.viewMenuPricePopup);

    const [showPopup, setShowPopup] = useState(false);
    const [menuDetail, setMenuDetail] = useState({});

    const handleClosePopup = () => {
        setShowPopup(false);
        dispatch(viewMenuPricePopupReducer({}));
    }



    useEffect(() => {

        if (Object.entries(viewMenuPricePopup).length > 0) {



            if (viewMenuPricePopup.hasOwnProperty('file_url')) {
                //Utilities.openNewTab(viewMenuPricePopup.file_url);

                if (Utilities.validate_image_ext(viewMenuPricePopup.file_url)) {

                    setMenuDetail(viewMenuPricePopup);
                    setShowPopup(true);

                } else {
                    //window.open(viewMenuPricePopup.file_url, '_blank');

                    const isNewTab = parseInt(viewMenuPricePopup.new_tab ?? 1);
                    if (isNewTab === 1) {
                        Utilities.openNewTab(viewMenuPricePopup.file_url);
                        return false;
                    }

                    setMenuDetail(viewMenuPricePopup);
                    setShowPopup(true);

                }

            }
        }

    }, [viewMenuPricePopup]);


    return (

        <Modal id="popup-menu-price-preview" show={showPopup} onHide={handleClosePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="lg" dialogClassName="modal-dialog-theme-" keyboard={true} contentClassName="border-0 overflow-hidden">
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline pt-4">
                {
                    Object.entries(viewMenuPricePopup).length > 0 ?
                        (parseInt(viewMenuPricePopup.type ?? 0) === 0 ? TitlesLabels.general.preview ?? '' : Utilities.menuProductOptionLabel[parseInt(viewMenuPricePopup.type)].toString())
                        :
                        Utilities.menuProductOptionLabel[1].toString()
                }
            </Modal.Header>
            <Modal.Body className="p-0 line-height-0">
                {
                    (Object.entries(viewMenuPricePopup).length > 0 && viewMenuPricePopup.hasOwnProperty('file_url')) &&

                        Utilities.validate_image_ext(viewMenuPricePopup.file_url) ?
                        <>
                            <img src={menuDetail.file_url} width="100%" />
                            <div className="w-100 btn-option d-flex align-items-center justify-content-center my-3">
                                <a href={`${ApiUrls.awsDownloadImage}${menuDetail.file_url}`} className="btn-icon btn-download" style={{ filter: `invert(1)` }} title="download"></a>
                            </div>
                        </>

                        :
                        <iframe height="650" width="100%" src={`${menuDetail.file_url}#toolbar=1&view=Fill`}></iframe>
                }

            </Modal.Body>
        </Modal>
    )
}

export default MenuPricePreview;

