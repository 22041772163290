import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';

import PagesAuthentication from '../../helpers/PagesAuthentication';
import UrlSlugs from '../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';
import TitlesLabels from '../../helpers/TitlesLabels';
import CommonProfileLogo from '../../components/common/CommonProfileLogo';

const ManageTeam = ({ match }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [listProfiles, setListProfiles] = useState([]);
    const [companyProfile, setCompanyProfile] = useState([]);
    const [profileDetail, setProfileDetail] = useState([]);
    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [showEmpty, setShowEmpty] = useState(false);
    const [contentLoaderClass, setContentLoaderClass] = useState('animate');

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }


    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getMembers = (accountID) => {

        if (parseInt(accountID) > 0) {
            setContentLoaderClass('animate');
            setListProfiles([]);

            axios
                .post(ApiUrls.teamList, { company_id: accountID })
                .then(response => {

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus === 1) {
                            if (Utilities.isEmpty(dataResponse.list)) {
                                setShowEmpty(true);
                            } else {
                                setShowEmpty(false);

                                let memberList = [];
                                Object.entries(dataResponse.list).map(([id, memberdetail]) => {
                                    memberList = [...memberList, Utilities.formatProfileDetail(memberdetail)];
                                });

                                setListProfiles(memberList);
                            }
                        } else {
                            setShowEmpty(true);
                        }
                    }

                    setContentLoaderClass('');
                })
                .catch((err) => {
                    setShowEmpty(true);
                    setContentLoaderClass('');
                });
        }

    }

    const setupProfileForMainSearch = () => {
        //dispatch( passProfile(profileDetail) );
        //dispatch( showMainSearch(2) );

        sessionStorage.setItem(
            process.env.REACT_APP_session_manage_team_current_company_detail,
            JSON.stringify(profileDetail)
        );

        history.push(`/${UrlSlugs.page_directorySearch}/${btoa(2)}`);
    }

    const triggerSetting = () => {
        dispatch(showMainSettingsReducer(new Date()));
    }

    const handleProfileViewEdit = (profile = {}) => {

        if (Object.keys(profile).length > 0) {
            history.push(`/${UrlSlugs.settings_team_edit}/${btoa(profileDetail.account_id)}/${btoa(profile.account_id)}`);
        }
    }

    useEffect(() => {
        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.teamMembers}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();


    }, [])

    useEffect(() => {

        let reqAccountID = match.params.mid;
        if (typeof reqAccountID !== 'undefined') {
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            if (reqAccountID > 0) {

                // ASSOCIATED ACCOUNTS
                let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                if (accountsList !== null) {
                    accountsList = JSON.parse(accountsList);

                    if (Object.keys(accountsList).length > 0) {

                        Object.entries(accountsList).map(([acctid, acctdetail]) => {
                            const account_id = parseInt(acctdetail.account_id);
                            if (account_id === reqAccountID) {

                                let accountProfile = Utilities.formatProfileDetail(acctdetail);
                                setProfileDetail(accountProfile);

                                let profileCompanyTemp = Utilities.workToCompany(accountProfile);
                                setCompanyProfile(profileCompanyTemp);

                                getMembers(profileCompanyTemp.account_id);
                            }
                        });

                    }

                }

            }
        }

        // /setupProfileForMainSearch();

    }, [match.params.mid]);

    return (
        <div className="team-members-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => triggerSetting()}>{TitlesLabels.settings}</div>
                            <div>{TitlesLabels.general.profiles}</div>
                            {companyProfile.hasOwnProperty('name') && <div>{companyProfile.name}</div>}
                            <div className="current">{TitlesLabels.navigations.teamMembers}</div>
                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-plus" onClick={() => setupProfileForMainSearch()}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()}></div>

                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content">

                {
                    Object.keys(listProfiles ?? []).length > 0 &&
                    <div className="content-subnav-wrap profile-search-wrap  py-4">
                        <div className="container px-5">
                            <div className="profile-search-wrap bg-white d-flex align-items-center form-theme  w-100 border-radius">
                                <div className='form-group mb-0 border-0  w-100 '>
                                    <input type="text" name="input_name" id="input_name" className="form-input input-search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} placeholder="type here" autoComplete='off' />
                                </div>
                                {!Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={() => resetSearchValue()}>clear</span></div>}
                            </div>

                        </div>
                    </div>
                }

                {!Utilities.isEmpty(contentLoaderClass) && <div className="content-subnav-wrap profile-search-wrap  py-4"></div>}

                <div className="container">

                    {
                        !Utilities.isEmpty(contentLoaderClass) &&
                        <ul className="ul-global-list px-5 mt-4">
                            {
                                [...Array(6).keys()].map((key) =>
                                    <li key={key} className="global-preloader  py-2">
                                        <div className="logo-size-60 bg-gray logo-size-md-60 border-0"></div>
                                        <div>
                                            <div className="bg-gray col-4 pt-3 border-radius-loader"></div>
                                            <div className="mt-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    }


                    <ul id="listing-profiles" className={`listing-profiles ul-global-list text-lowercase  mt-4 px-5 ${Object.keys(listProfiles).length === 0 ? 'd-none' : ''} `}>
                        {
                            Object.entries(listProfiles).map(([key, row]) =>
                                <li key={key} data-keyword={row.name} className="cursor-pointer" onClick={() => handleProfileViewEdit(row)}>

                                    <div className="profile-image-wrap">
                                        {/* <div className={`profile-image logo-size-60 logo-size-md-60`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(row.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo_url) }}>{ Utilities.showHideInitial(row.logo_url, row.name) }</div> */}
                                        <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={row} />
                                    </div>

                                    <div className='line-height-1'>
                                        <div className="profile-name font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1 notranslate" data-name={row.name}>{row.name} </div>
                                        <div className="profile-label font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.team_position}</div>
                                    </div>

                                </li>
                            )
                        }
                    </ul>

                    {showEmpty && <div className="text-center text-lowercase no-record-label mt-5">{Utilities.labelTeamAvailable}</div>}


                </div>
            </div>
        </div>
    )
}

export default ManageTeam;