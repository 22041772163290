import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import TitlesLabels from '../helpers/TitlesLabels';
import PagesAuthentication from '../helpers/PagesAuthentication';
import Utilities from '../helpers/Utilities';
import moment from 'moment';
import jQuery from 'jquery';

import CityAreaSearch from './popups/CityAreaSearch';
import { pageTitle, passImageUrl, showFooter, showHeader, showLoader } from '../actions';
import { getCompanyNewRegistered } from '../helpers/QueryDirectoryRegisteredNew';
import CommonProfileLogo from './common/CommonProfileLogo';
import QueryMasterSearchUtlities from '../helpers/QueryMasterSearchUtlities';
import MainSearchProfile from './popups/MainSearchProfile';
import SessionUtilities from '../helpers/SessionUtilities';



const DirectoryRegisteredNew = ({ match }) => {

    const dispatch = useDispatch();

    const API_TOKEN = process.env.REACT_APP_chat_api_token
    const defaultLocation = Utilities.defaultLocation();
    const loginUserDetail = PagesAuthentication.getUserLoggedinDetail();
    let { type } = useParams();
    let companyListGroupDate = [];

    const [show, setShow] = useState(false);
    const [showCityPopup, setShowCityPopup] = useState(false);

    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [currentLocation, setCurrentLocation] = useState(defaultLocation);
    const [gettingProfilesProcessing, setGettingProfilesProcessing] = useState(false);
    const [listProfiles, setListProfiles] = useState([]);

    const [profileSelected, setProfileSelected] = useState({});
    const [profileShowStatus, setProfileShowStatus] = useState(1);

    const [companyNamesKeyword, setCompanyNamesKeyword] = useState([]);

    const handleClosePopUp = () => setShowCityPopup(false);
    const handleShowPopUp = () => setShowCityPopup(true);

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        searchFilter('');

        if (jQuery('#input_keyword').length > 0) {
            document.getElementById('input_keyword').focus();
        }
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#profile-listing-contact > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);


        });
    }

    const searchContentKeywordHtml = () => {
        return <ul className="keyword-option form-theme bg-white w-100 border-radius">
            <li>
                <div className='form-group mb-0 border-0  w-100'>
                    <input type="text" id="input_keyword" className="form-input input-keyword bg-white border-radius w-100" placeholder="search" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} autoComplete="off" ></input>
                </div>
            </li>
            <li></li>

            {
                !Utilities.isEmpty(defaultSearchValue) &&
                <li><button type="button" className="btn-icon btn-clear-input" onClick={() => resetSearchValue()} title="clear"><span className="ion-ios7-close-empty"></span></button>  </li>
            }

        </ul>
    }

    const passCityDetail = async (cityDetail = {}) => {
        if (Object.entries(cityDetail).length > 0) {

            let defaultLocationTemp = defaultLocation;

            let userLocation = localStorage.getItem(process.env.REACT_APP_user_location);
            if (userLocation !== null) {
                defaultLocationTemp = JSON.parse(userLocation);
            }

            defaultLocationTemp = {
                ...defaultLocationTemp,
                country_name: cityDetail.country_name,
                state_name: cityDetail.state_name,
                city_name: cityDetail.city_name,
                area_name: cityDetail.area_name ?? '',

                country_id: cityDetail.country_id,
                state_id: cityDetail.state_id,
                city_id: cityDetail.city_id,
                area_id: cityDetail.area_id ?? '0',
            };
            setCurrentLocation(defaultLocationTemp);
            await gettingCompanyList(defaultLocationTemp.city_id);
        }

        handleClosePopUp();
    }


    const gettingCompanyList = async (cityId = 0, areaId = 0) => {

        companyListGroupDate = [];
        areaId = parseInt(areaId ?? 0);
        cityId = parseInt(cityId);

        const companyNamesKeywordLoop = [];
        setListProfiles([]);

        if (cityId > 0) {
            setGettingProfilesProcessing(true);
            const TOKEN_SESSION = SessionUtilities.getSessionToken();
            const passval = {
                api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
                account_id: (loginUserDetail.account_id ?? 0).toString(),
                city_id: cityId.toString(),
                area_id: areaId.toString(),
            }

            const conpanyRes = await getCompanyNewRegistered(passval);

            if (conpanyRes.length > 0) {

                setListProfiles(conpanyRes);

                let loopRegDate = '', name = '';

                Object.entries(conpanyRes).map(([key, row]) => {
                    loopRegDate = row.reg_date ?? '';
                    name = row.name ?? '';
                    name = name.trim().toLowerCase();

                    if (!Utilities.isEmpty(loopRegDate)) {
                        if (!companyListGroupDate.includes(loopRegDate)) {
                            companyListGroupDate.push(loopRegDate);
                            companyNamesKeywordLoop[loopRegDate] = [name];
                        } else {
                            companyNamesKeywordLoop[loopRegDate] = [...companyNamesKeywordLoop[loopRegDate], name];
                        }


                    }
                });

                setCompanyNamesKeyword(companyNamesKeywordLoop);
                //console.log(companyNamesKeywordLoop);
            }

        }

        setGettingProfilesProcessing(false);
    }

    const loaderProfileHtml = () => {
        return (
            <ul className="dashboard-list-preloader">
                {
                    [...Array(20).keys()].map((key) =>
                        <li key={key}>
                            <div className="profile-image"></div>
                            <div></div>
                            <div></div>
                        </li>
                    )
                }

            </ul>
        )
    }

    const previewProfileImage = (logoUrl) => {
        if (!Utilities.isEmpty(logoUrl)) {
            dispatch(passImageUrl(logoUrl));
        }
    }

    const profileListingHtml = (key, row) => {

        let dateRow = '', dateStr = '', loopRegDate = row.reg_date ?? '', dateChange = '', dateChangeArr = [], letterKeyword = [];


        let name = row.name ?? '';
        name = name.trim().toLowerCase();

        if (!companyListGroupDate.includes(loopRegDate)) {
            companyListGroupDate.push(loopRegDate);

            letterKeyword = companyNamesKeyword[loopRegDate];

            dateChangeArr = loopRegDate.split('-');
            dateChange = `${dateChangeArr[2]}-${dateChangeArr[1]}-${dateChangeArr[0]}`;

            dateStr = moment(dateChange).format('DD MMMM').toLocaleString();

            const sameDay = moment(dateChange).isSame(moment(), 'day');
            if (sameDay) {
                dateStr = 'today';
            }

            dateRow = <li data-date={loopRegDate} className="letter-row" data-keyword={letterKeyword.join(' ')}>
                <span>{dateStr}</span>
            </li>

        }


        const listRow = <li data-keyword={name} onClick={() => handleShow(row)} >
            <div className="profile-wrap">
                <div className="profile-image-wrap">
                    <CommonProfileLogo classAttr={`profile-image fs-14-important logo-size-60 logo-size-md-60`} profileDetail={{ ...row, name: name }} handlePreviewProfileImage={previewProfileImage} preventOnclick={true} />
                </div>
                <div>
                    <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1" data-name={name}>{name}</div>
                    {(row.hasOwnProperty('category') && row.category !== '') && <div className="profile-label color-theme-grey">{row.category}</div>}
                </div>

                <div className="color-theme-grey">
                    <div className="profile-address">{row.location ?? ''}</div>
                </div>

                <div className="hidden-detail d-none">
                    <div className="name notranslate">{name}</div>
                    <div className="logo">{row.logo_url}</div>
                    <div className="address">{row.location ?? ''}</div>
                </div>

            </div>
        </li>


        return <>{dateRow} {listRow}</>;
    }

    const handleShow = async (profile = {}) => {

        setProfileSelected({});

        if (Object.keys(profile).length > 0) {

            dispatch(showLoader(1));
            const profileDetail = await QueryMasterSearchUtlities.getProfileDetail(profile.account_id ?? 0);
            setProfileSelected(profileDetail.list[profile.account_id.toString()]);
            setShow(true);
            //dispatch( showLoader(0) );
        }

    }

    const handleClose = () => {
        setProfileSelected({});
        setShow(false);
    }

    const closeSearchBox = (totallyClose = false) => {
        setShow(false);
    }

    useEffect(() => {

        dispatch(pageTitle(TitlesLabels.siteTitles.directoryBusiness));

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);
        sesstionLoginType = parseInt(sesstionLoginType);

        dispatch(showHeader(sesstionLoginType === 2 ? 5 : 1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        const directoryLocation = SessionUtilities.getDirectoryLocation();
        if (directoryLocation !== null) {
            setCurrentLocation(directoryLocation);
            gettingCompanyList(directoryLocation.city_id, directoryLocation.area_id ?? '0');

        } else {
            gettingCompanyList(currentLocation.city_id, currentLocation.area_id ?? '0');
        }


        setProfileShowStatus(3); // always company directory popup
        //setProfileShowStatus(atob(type));



    }, []);



    return (
        <>
            <div className='directory-location-option-wrap d-flex justify-content-center text-lowercase'>
                <div className="cursor-pointer position-fixed zindex-11 text-white fs-20 fs-md-22" onClick={() => handleShowPopUp()}>
                    <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-map-pin.svg`} width={25} className="mr-2" />
                    {currentLocation.area_name ? currentLocation.area_name : currentLocation.city_name}
                </div>
            </div>

            <div className="main-contact-search text-lowercase sticky-navigation">

                <div className="header-contact-search border-0">
                    <div className="container-contact-search nobreadcrumbs">

                        <div className="container">
                            <div className='directory-search-btn-option d-flex justify-content-between'>
                                {searchContentKeywordHtml()}

                                <div className="btn-option  pl-3">
                                    <div className="btn-icon  btn-map-pin" title="change city" onClick={() => handleShowPopUp()}></div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>



                <div id="contact-listing-wrap">
                    <div className="container pt-3 pb-5">
                        {
                            gettingProfilesProcessing ?
                                loaderProfileHtml()
                                :
                                <>
                                    {
                                        Object.entries(listProfiles).length === 0 ?
                                            <div className="text-center text-lowercase no-record-label  no-chats-label " >{Utilities.labelNoFindResults}</div>
                                            :
                                            <div id="contact-listing" className="contact-listing search-result-list">
                                                <ul id="profile-listing-contact" className="profile-listing">
                                                    {Object.entries(listProfiles).map(([key, row]) => profileListingHtml(key, row))}
                                                </ul>
                                            </div>
                                    }
                                </>
                        }
                    </div>
                </div>

                <CityAreaSearch showCityPopup={showCityPopup} handleClosePopUp={handleClosePopUp} passCityDetail={passCityDetail} />

                <MainSearchProfile handleShowProfilePopup={show} handleCloseProfilePopup={handleClose} profileSelected={profileSelected} showStatus={profileShowStatus} closeSearchBox={closeSearchBox} />

            </div>
        </>
    )
}

export default DirectoryRegisteredNew;
