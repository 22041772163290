import React from 'react';


const DirectorySearchKeywordsTemplate = (props) => {

    return <li key={props.detail.word ?? ''} onClick={() => props.handleClick(props.detail)}>
        <span dangerouslySetInnerHTML={{ __html: props.highlightKeywordInResults(props.detail.word ?? '') }}></span> <span className="counter ml-5">{props.detail.counter ?? 0}</span>
    </li>

}


export default DirectorySearchKeywordsTemplate;