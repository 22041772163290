import React, { useState } from 'react';
import DirectorySearchLoaderProfile from './DirectorySearchLoaderProfile';
import DirectorySearchBackNavigation from './DirectorySearchBackNavigation';
import DirectorySearchProfileListing from './DirectorySearchProfileListing';
import Utilities from '../../helpers/Utilities';
import DirectorySearchNoRecord from './DirectorySearchNoRecord';
import DirectorySearchKeywordsTemplate from './DirectorySearchKeywordsTemplate';
import DirectorySearchItemTemplate from './DirectorySearchItemTemplate';
import { getCounterKeywordAllSuburb, getCounterKeywordByArea, getCounterKeywordByLocation, getKeywordsByCitiesIds } from '../../helpers/QueryDirectorySearchKeywords';
import { getPofilesByIds } from '../../helpers/QueryDirectorySearch';


const DirectorySearchKeywordsStatesCities = (props) => {

    let listResultProfiles = [], dataRequest = {}, resDb_listResult = {}, list_keywords = [], profile_count = 0, profile_ids = '';

    const listStates = props.listResult.list_states ?? [];
    const profileCount = props.listResult.profile_count ?? 0;
    const profileIds = props.listResult.profile_ids ?? 0;

    const countryId = parseInt(props.countryid ?? 0);
    const stateId = parseInt(props.stateid ?? 0);
    const cityId = parseInt(props.cityid ?? 0);
    const areaId = parseInt(props.areaid ?? 0);
    const typeId = parseInt(props.typeid ?? 0);
    const keyword = (props.keyword ?? '').toString();
    const enableSortDistance = props.enableSortDistance ?? false;

    const defaultLocation = {
        country_id: countryId.toString(),
        state_id: stateId.toString(),
        city_id: cityId.toString(),
        area_id: areaId.toString(),
    }

    const [steps, setSteps] = useState(0);

    const [boolShowCities, setBoolShowCities] = useState(false);
    const [boolShowSuburbs, setBoolShowSuburbs] = useState(false);
    const [boolShowKeywords, setBoolShowKeywords] = useState(false);
    const [boolShowProfileResult, setBoolShowProfileResult] = useState(false);
    const [boolShowLoading, setBoolShowLoading] = useState(false);

    const [detailState, setStateDetail] = useState({});
    const [detailCity, setCityDetail] = useState({});
    const [detailArea, setAreaDetail] = useState({});
    const [detailKeyword, setDetailKeyword] = useState({});

    const [totalKeywordState, setTotalKeywordState] = useState(0);
    const [totalKeywordCity, setTotalKeywordCity] = useState(0);
    const [totalKeywordSuburb, setTotalKeywordSuburb] = useState(0);

    const [listResultCities, setListResultCities] = useState([]);
    const [listResultSuburbs, setListResultSuburbs] = useState([]);
    const [listResulProfiles, setListResulProfiles] = useState([]);
    const [listResultKeywords, setListResultKeywords] = useState([]);


    const gettingAllKeywordsState = async () => {
        setSteps(1);

        setBoolShowCities(false);
        setBoolShowSuburbs(false);
        setBoolShowProfileResult(false);
        setBoolShowKeywords(true);

        setStateDetail({});
        setCityDetail({});
        setAreaDetail({});

        dataRequest = {
            ...defaultLocation,
            type: typeId.toString(),
            keyword: keyword.toString(),
            location_type: "4"
        };
        ///console.log(dataRequest);

        list_keywords = [];
        setBoolShowLoading(true);
        resDb_listResult = await getCounterKeywordByLocation(dataRequest);
        setBoolShowLoading(false);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                list_keywords = resDb_listResult.list ?? [];
                profile_count = resDb_listResult.profile_count ?? 0;
                profile_ids = resDb_listResult.profile_ids ?? '';
            }
        }
        setListResultKeywords(list_keywords);
        //console.log(list_keywords);
    }


    const gettingCounterCities = async (stateDetail = {}) => {


        dataRequest = {
            ...defaultLocation,
            city_id: (stateDetail.city_ids ?? '').toString(),
            type: typeId.toString(),
            keyword: keyword.toString(),
            location_type: "3"
        };

        //console.log(dataRequest);
        let list_cities = [], all_keywords = [];

        setBoolShowCities(false);
        setBoolShowSuburbs(false);
        setBoolShowKeywords(false);
        setBoolShowProfileResult(false);

        setBoolShowLoading(true);
        resDb_listResult = await getKeywordsByCitiesIds(dataRequest);
        setBoolShowLoading(false);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                list_cities = resDb_listResult.list ?? [];
                profile_count = resDb_listResult.profile_count ?? 0;
                profile_ids = resDb_listResult.profile_ids ?? '';
                all_keywords = resDb_listResult.all_keywords ?? [];
            }
        }

        setStateDetail({ ...stateDetail, all_keywords: all_keywords });
        setListResultCities(list_cities);
        setBoolShowCities(true);

        setSteps(3);

    }


    const gettingSuburbsCounter = async (cityinfo = {}) => {

        //console.log(cityinfo);
        setCityDetail(cityinfo);

        let list_suburbs = [], all_keywords = [];

        dataRequest = {
            ...defaultLocation,
            state_id: (cityinfo.state_id ?? 0).toString(),
            city_id: (cityinfo.city_id ?? 0).toString(),
            type: typeId.toString(),
            keyword: keyword.toString(),
            location_type: "3"
        };

        setBoolShowCities(false);

        setBoolShowLoading(true);
        resDb_listResult = await getCounterKeywordAllSuburb(dataRequest);
        setBoolShowLoading(false);


        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                list_suburbs = resDb_listResult.list ?? [];
                profile_count = resDb_listResult.profile_count ?? 0;
                profile_ids = resDb_listResult.profile_ids ?? '';
                all_keywords = resDb_listResult.all_keywords ?? []
            }
        }

        setCityDetail({ ...cityinfo, all_keywords: all_keywords });
        setListResultSuburbs(list_suburbs);
        setBoolShowSuburbs(true);
        setSteps(6);
    }


    const gettingDisplayAllWords = (step = 0, allkeyword = [], keywordsCounter = 0, keyswordsProfileids = '') => {
        keywordsCounter = parseInt(keywordsCounter);
        setSteps(step);

        if (keywordsCounter > 0) {
            if (step === 4) {
                setBoolShowCities(false);
                setBoolShowKeywords(true);

            } else if (step === 7) {
                setBoolShowSuburbs(false);
                setBoolShowKeywords(true);
            }

            setListResultKeywords(allkeyword);
            setTotalKeywordState(keywordsCounter);

        }
    }



    const handleClickGetProfiles = async (detail = {}) => {


        const profileIdsAvailable = (detail.profile_ids ?? '').toString();
        if (Utilities.isEmpty(profileIdsAvailable)) {
            return false;
        }

        setDetailKeyword(detail);

        dataRequest = {
            account_ids: profileIdsAvailable.toString(),
            account_type: 3
        }
        listResultProfiles = [];

        setBoolShowKeywords(false);
        setBoolShowProfileResult(false);

        setBoolShowLoading(true);
        resDb_listResult = await getPofilesByIds(dataRequest);
        setBoolShowLoading(false);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listResultProfiles = resDb_listResult.list ?? [];
            }
        }

        setListResulProfiles(listResultProfiles);
        setBoolShowProfileResult(true);


        if (steps === 1) {
            setSteps(2);

        } else if (steps === 4) {
            setSteps(5);
        } else if (steps === 7) {
            setSteps(8);
        }

        //console.log(steps);
    }


    const gettingKeywordsArea = async (areaDetail = {}) => {
        setSteps(7);

        let list_keywords = [];

        dataRequest = {
            ...defaultLocation,
            state_id: (detailState.state_id ?? 0).toString(),
            city_id: (detailCity.city_id).toString(),
            area_id: (areaDetail.area_id ?? 0).toString(),
            type: "8",
            keyword: keyword.toString(),
            location_type: 2
        }

        setBoolShowLoading(true);
        resDb_listResult = await getCounterKeywordByArea(dataRequest);
        setBoolShowLoading(false);


        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                list_keywords = resDb_listResult.list ?? [];
                profile_count = resDb_listResult.profile_count ?? 0;
                profile_ids = resDb_listResult.profile_ids ?? '';
            }
        }
        setBoolShowSuburbs(false);
        setBoolShowKeywords(true);
        setListResultKeywords(list_keywords);
    }


    const handleClickBack = () => {
        //console.log(steps);

        if (steps === 1) {
            setBoolShowKeywords(false);
            setSteps(0);

        } else if (steps === 2) {
            setBoolShowKeywords(true);
            setSteps(1);

        } else if (steps === 3) {
            setBoolShowCities(false);
            setSteps(0);

        } else if (steps === 4) {
            setBoolShowKeywords(false);
            setBoolShowCities(true);
            setSteps(3);

        } else if (steps === 5) {
            setBoolShowKeywords(true);
            setSteps(4);

        } else if (steps === 6) {
            setBoolShowCities(true);
            setBoolShowSuburbs(false);
            setSteps(3);

        } else if (steps === 7) {
            setBoolShowSuburbs(true);
            setBoolShowKeywords(false);
            setSteps(6);

        } else if (steps === 8) {
            setBoolShowKeywords(true);
            setSteps(7);

        }

        setBoolShowLoading(false);
        setBoolShowProfileResult(false);

    }



    if (listStates.length === 0) {
        return <DirectorySearchNoRecord />

    } else {

        return (
            <div className='tab-list-states'>

                {/* -------------------- states listing -------------------- */}
                {
                    (!boolShowLoading && !boolShowCities && !boolShowSuburbs && !boolShowKeywords && !boolShowProfileResult) &&
                    <ul className="states-listing" >
                        <li onClick={() => gettingAllKeywordsState()}>
                            <div>
                                <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                <div><span className="counter fs-16 fs-md-14">{profileCount}</span></div>
                            </div>
                        </li>
                        {
                            Object.entries(listStates).map(([key, row]) =>
                                <li key={key} onClick={() => gettingCounterCities(row)}>
                                    <DirectorySearchItemTemplate name={row.state_name ?? ''} counter={row.counter ?? '0'} /></li>
                            )
                        }
                    </ul>
                }



                {/* -------------------- city listing -------------------- */}
                {
                    (!boolShowLoading && boolShowCities && !boolShowSuburbs && !boolShowKeywords && !boolShowProfileResult) &&
                    <>
                        <DirectorySearchBackNavigation handleClick={handleClickBack} text={detailState.state_name} />
                        <ul className="states-listing" >
                            <li onClick={() => gettingDisplayAllWords(4, detailState.all_keywords ?? [], detailState.counter ?? 0, detailState.profile_ids ?? '')}>
                                <div>
                                    <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                    <div><span className="counter fs-16 fs-md-14">{detailState.counter ?? 0}</span></div>
                                </div>
                            </li>
                            {
                                Object.entries(listResultCities).map(([key, row]) =>
                                    <li key={key} onClick={() => gettingSuburbsCounter(row)}>
                                        <DirectorySearchItemTemplate name={row.city_name ?? ''} counter={row.counter ?? '0'} /></li>
                                )
                            }
                        </ul>

                    </>

                }


                {/* -------------------- suburb listing -------------------- */}
                {
                    (!boolShowLoading && !boolShowCities && boolShowSuburbs && !boolShowKeywords && !boolShowProfileResult) &&
                    <>
                        <DirectorySearchBackNavigation
                            handleClick={handleClickBack}
                            text={`
                                    ${detailCity.city_name ?? ''},
                                    ${detailState.state_name ?? ''}
                                `}
                        />

                        <ul className="states-listing" >
                            <li onClick={() => gettingDisplayAllWords(7, detailCity.all_keywords ?? [], detailCity.counter ?? 0, detailCity.profile_ids ?? '')}>
                                <div>
                                    <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                    <div><span className="counter fs-16 fs-md-14">{detailCity.counter ?? 0}</span></div>
                                </div>
                            </li>
                            {
                                Object.entries(listResultSuburbs).map(([key, row]) =>
                                    <li key={key} onClick={() => gettingKeywordsArea(row)} >
                                        <DirectorySearchItemTemplate name={row.area_name ?? ''} counter={row.counter ?? '0'} /></li>
                                )
                            }
                        </ul>
                    </>


                }


                {/* -------------------- words listing -------------------- */}
                {
                    (!boolShowLoading && !boolShowCities && !boolShowSuburbs && boolShowKeywords && !boolShowProfileResult) &&
                    <>
                        <DirectorySearchBackNavigation
                            handleClick={handleClickBack}
                            text={`
                                    ${detailKeyword.word ?? Utilities.labelShowAll}
                                    ${(detailArea.area_name ?? '') !== '' ? ', ' + detailArea.area_name : ''}
                                    ${(detailCity.city_name ?? '') !== '' ? ', ' + detailCity.city_name : ''}
                                    ${(detailState.state_name ?? '') !== '' ? ', ' + detailState.state_name : ''}
                                `}
                        />
                        <ul className="states-listing" >
                            {
                                steps === 1 ?
                                    <li onClick={() => handleClickGetProfiles({ profile_ids: profileIds })}>
                                        <div>
                                            <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                            <div><span className="counter fs-16 fs-md-14">{profileCount ?? 0}</span></div>
                                        </div>
                                    </li>
                                    :
                                    steps === 4 ?
                                        <li onClick={() => handleClickGetProfiles({ profile_ids: detailState.profile_ids })}>
                                            <div>
                                                <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                                <div><span className="counter fs-16 fs-md-14">{detailState.counter ?? 0}</span></div>
                                            </div>
                                        </li>
                                        :
                                        steps === 7 ?
                                            <li onClick={() => handleClickGetProfiles({ profile_ids: detailCity.profile_ids })}>
                                                <div>
                                                    <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                                    <div><span className="counter fs-16 fs-md-14">{detailCity.counter ?? 0}</span></div>
                                                </div>
                                            </li>
                                            :
                                            <></>

                            }
                            <li style={{ minHeight: 0, padding: 0 }}></li>
                        </ul>
                        <ul className="list-keywords" >
                            {
                                Object.entries(listResultKeywords).map(([key, row]) =>
                                    <DirectorySearchKeywordsTemplate highlightKeywordInResults={props.highlightKeywordInResults} detail={row} handleClick={handleClickGetProfiles} />
                                )
                            }
                        </ul>
                    </>

                }






                {/* -------------------- profile listing -------------------- */}
                {
                    (!boolShowLoading && !boolShowCities && !boolShowSuburbs && !boolShowKeywords && boolShowProfileResult) &&
                    <>
                        <DirectorySearchBackNavigation
                            handleClick={handleClickBack}
                            text={`
                                ${detailKeyword.word ?? Utilities.labelShowAll}
                                ${(detailArea.area_name ?? '') !== '' ? ', ' + detailArea.area_name : ''}
                                ${(detailCity.city_name ?? '') !== '' ? ', ' + detailCity.city_name : ''}
                                ${(detailState.state_name ?? '') !== '' ? ', ' + detailState.state_name : ''}
                                `} />
                        <DirectorySearchProfileListing listProfiles={listResulProfiles} locationTabId={2} highlightKeywordInResults={props.highlightKeywordInResults} previewProfileImage={props.previewProfileImage} handleClickProfile={props.handleClickProfile} enableSortDistance={enableSortDistance} />
                    </>
                }





                {/* -------------------- loader -------------------- */}
                {boolShowLoading && <DirectorySearchLoaderProfile />}


            </div>
        )
    }

}


export default DirectorySearchKeywordsStatesCities;