import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import {   useParams } from 'react-router-dom';
import moment from 'moment';

import TitlesLabels from "../../helpers/TitlesLabels";
import { pageTitle, showDirectoryProfilePopup, showLoader  } from "../../actions";

import DailyGlanceEventsFeaturedLayout from "./DailyGlanceEventsFeaturedLayout";
import { DailyGlanceFeaturedListLoader, RegisterProfileItemTemplate } from "./DailyGlanceArticleWidgets";
import NoRecordAvailable from "../../components/common/NoRecordAvailable";
import PagesAuthentication from "../../helpers/PagesAuthentication";
import Utilities from "../../helpers/Utilities";
import { getCompanyNewRegistered } from "../../helpers/QueryDirectoryRegisteredNew";
import QueryMasterSearchUtlities from "../../helpers/QueryMasterSearchUtlities";
import SessionUtilities from "../../helpers/SessionUtilities";



const DailyGlanceSignupsProfiles = () => {

    const dispatch = useDispatch();
    const { dateYYmmdd } = useParams();


    const API_TOKEN = process.env.REACT_APP_chat_api_token

    const profileLoggedin = PagesAuthentication.getUserLoggedinDetail();
    const defaultLocation = Utilities.defaultLocation();
    const currentCityId = defaultLocation.city_id??0;
    const currentAreaId =  defaultLocation.area_id??0;

    let result_Db = '';
    let companyListGroupDate = [];


    const [ showListLoader , setShowListLoader ] = useState(true);
    const [ listProfiles, setListProfiles ] = useState([]);
    const [ dateSelected , setDateSelected ] = useState('');
    const [ companyNamesKeyword, setCompanyNamesKeyword ] = useState([]);
    const [ signupsAdsImage , setSignupsAdsImage ] = useState({});
    

    const getingProfileList = async () => {

         if ( currentCityId>0 && parseInt(profileLoggedin.account_id??0)>0 ){
            const TOKEN_SESSION = SessionUtilities.getSessionToken();
            const passval = {
                api_token: TOKEN_SESSION.toString(), //API_TOKEN.toString(),
                account_id: profileLoggedin.account_id.toString(),
                city_id: (currentCityId??0).toString(),
                area_id: (currentAreaId??0).toString()
            }
            
            setShowListLoader(true);
            result_Db = await getCompanyNewRegistered(passval);
            setShowListLoader(false);

            if ( Object.keys(result_Db).length>0 ){
                setListProfiles(result_Db??[]);
            }
            
       }
    }


    const handleShowProfile = async ( profile = {} ) => {


        if ( Object.keys(profile).length>0 ){
            dispatch( showLoader(1) );
            const profileDetail = await QueryMasterSearchUtlities.getProfileDetail(profile.account_id ?? 0);
            dispatch( showDirectoryProfilePopup(profileDetail.list[profile.account_id.toString()]) );
          
        }
        
    }


    const profileListingHtml = ( key, row ) => {

        let dateRow = '', dateStr = '', loopRegDate = row.reg_date??'', dateChange = '', dateChangeArr = [], letterKeyword = [];
        

        let name = row.name ?? '';
        name = name.trim().toLowerCase();

        if ( ! companyListGroupDate.includes(loopRegDate) ){
            companyListGroupDate.push(loopRegDate);

            letterKeyword = companyNamesKeyword[loopRegDate];

            dateChangeArr = loopRegDate.split('-');
            dateChange = `${dateChangeArr[2]}-${dateChangeArr[1]}-${dateChangeArr[0]}`;

            dateStr = moment(dateChange).format('DD MMMM').toLocaleString(); 

            const sameDay = moment(dateChange).isSame(moment(), 'day');
            if ( sameDay ){
                dateStr = 'today';
            }

            dateRow = <li className="d-block pt-4 border-0 pb-1 "><span className="color-theme-grey fs-13 fs-md-14">{ dateStr }</span></li>

        }

        return   <>{ dateRow } <RegisterProfileItemTemplate detail={row} dateSelected={dateSelected.date??''} handleShowProfile={handleShowProfile} /> </>

    }


    useEffect( async () => {
        
        dispatch( pageTitle(`${TitlesLabels.general.signUps}`) );
        getingProfileList();

        try {
            let dateYYmmddParam = atob(dateYYmmdd);
            setDateSelected(dateYYmmddParam);

        } catch(e) {
      
        }


    }, [] );



    return (
        <>
            <DailyGlanceEventsFeaturedLayout  menuTitle={TitlesLabels.general.signUps}  typeName="signups">
                <div className="col-lg-10 mx-auto">
                {
                    showListLoader ?
                        <DailyGlanceFeaturedListLoader />
                    :
                    <>
                        {
                            Object.keys(listProfiles).length===0 ?
                                <NoRecordAvailable />
                            :
                                <div className="list-article-height-auto">
                                    <ul   className="ul-global-list ul-dglance-list cursor-pointer text-lowercase font-gotham-book fs-14 fs-md-14">
                                    {  
                                        Object.entries(listProfiles).map( ([key, row] ) => profileListingHtml( key, row)  )
                                    }
                                    
                                    </ul>
                                </div>
                        }
                    </>
                }
                </div>
            </DailyGlanceEventsFeaturedLayout>
        </>
            
    )
}

export default DailyGlanceSignupsProfiles;