import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';

import Utilities from '../../helpers/Utilities';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import TitlesLabels from '../../helpers/TitlesLabels';

import {  showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer} from '../../actions';
import CommonProfileLogo from '../../components/common/CommonProfileLogo';


const ManageDepartments = ({match}) => {

    const dispatch = useDispatch();

    const [ listDepartments, setListDepartments ] = useState([]);
    const [ companyProfile, setCompanyProfile ] = useState([]);
    const [ profileDetail, setProfileDetail ] = useState([]);
    const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [ showEmpty, setShowEmpty ] = useState(false);
    const [ contentLoaderClass, setContentLoaderClass ] = useState('animate');

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if ( fieldName==='search-keyword'){
            setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = ( keyword = '' ) => {
        keyword = ( ! Utilities.isEmpty(keyword) ) ? keyword.toLowerCase() : '';

        jQuery('#listing-departments > li').filter(function (){
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase(); 
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);
            
            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }

    const resetSearchValue = () => {
        setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const getDepartments = async ( accountID ) => {

        if ( parseInt(accountID)>0 ){
            await axios
                .post( ApiUrls.departmentListByAccountID , { id : accountID } )
                .then( response => {

                    if ( response.status===200 ){
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);
                        
                        if ( dataStatus===1 ){
                            if ( Utilities.isEmpty(dataResponse.departments) ){
                                setShowEmpty(true);
                            }else{
                                setShowEmpty(false);
                                setListDepartments(dataResponse.departments);
                            }
                        }
                    }

                    setContentLoaderClass('');
                })
                .catch( (err) => {
                    setShowEmpty(true);
                    setContentLoaderClass('');
                }); 
        }

    }

    const triggerSetting = () => {
        dispatch( showMainSettingsReducer( new Date() ));
    }

    useEffect( () => {

        let reqAccountID = match.params.mid;
        if ( typeof reqAccountID!=='undefined' ){
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            if ( reqAccountID>0 ){

                // ASSOCIATED ACCOUNTS
                let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                if ( accountsList!==null ){
                    accountsList = JSON.parse(accountsList);

                    if ( Object.keys(accountsList).length>0 ){

                        Object.entries(accountsList).map( ( [acctid, acctdetail] ) => {
                            const account_id = parseInt(acctdetail.account_id);
                            if (account_id===reqAccountID){

                                let accountProfile = Utilities.formatProfileDetail(acctdetail);
                                setProfileDetail(accountProfile);

                                let profileCompanyTemp = Utilities.workToCompany(accountProfile);
                                setCompanyProfile(profileCompanyTemp);

                                getDepartments(profileCompanyTemp.account_id);

                            }
                        });
                        
                    }

                }
                
            }
        }

    }, [match.params.mid] );

    useEffect( () => {

        PagesAuthentication.getUserLoggedin();

        dispatch( pageTitle(`${ TitlesLabels.settings } | ${ TitlesLabels.siteTitles.manageDepartments }`) );

        dispatch( showHeader(1) );
        dispatch( showFooter(0) );
        
    }, []);

    return (
        <div className="departments-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                     <ul className="breadcrumbs-nav">
                         <li>
                            <div className='cursor-pointer' onClick={ () => triggerSetting()}>{ TitlesLabels.settings }</div>
                            <div>{ TitlesLabels.general.profiles }</div>
                            { companyProfile.hasOwnProperty('name') && <div>{companyProfile.name}</div> }
                            <div  className="current">{ TitlesLabels.navigations.departments }</div>
                             
                         </li>
                         <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={ () => dispatch( showPopupQuickLinksReducer(true) ) } title={ TitlesLabels.general.quickLinks }></div>
                            <Link to={`/${ UrlSlugs.settings_department_add }/${ btoa(profileDetail.account_id) }`} className="btn-icon btn-plus"></Link>
                            <div className="btn-icon btn-gear" onClick={ () => triggerSetting() } title={ TitlesLabels.settings } ></div>

                         </li>
                     </ul>
                </div>

            </div>

            <div className="main-content">

                {
                     Object.keys(listDepartments ?? []).length>0 &&
                     <div className="content-subnav-wrap profile-search-wrap  py-4">
                        <div className="container px-5">
                            <div className="profile-search-wrap bg-white d-flex align-items-center form-theme  w-100 border-radius">
                                <div className='form-group mb-0 border-0  w-100 '>
                                    <input type="text" name="input_name" id="input_name" className="form-input input-search" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="type here" autoComplete='off' />
                                </div>
                                { ! Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={ () => resetSearchValue() }>clear</span></div> }
                            </div>
    
                        </div>
                    </div>
                }

                 {   ! Utilities.isEmpty(contentLoaderClass) && <div className="content-subnav-wrap profile-search-wrap  py-4"></div> }


                <div className="container pt-4">
                    
                        { 
                            ! Utilities.isEmpty(contentLoaderClass) &&
                                <ul className="listing-profiles-preloader mt-3">
                                {
                                    [...Array(12).keys()].map( ( key ) =>
                                        <li key={key}>
                                            <div className="profile-image"></div>
                                            <div></div>
                                            <div></div>
                                        </li>
                                    )
                                }
                                </ul>
                        }
                
                        

                        <ul id="listing-departments" className={`global-listing-profiles mt-3  ${ Object.keys(listDepartments).length===0 ? 'd-none' : '' } `}>
                        {
                            Object.entries(listDepartments).map( ([key, row] ) =>
                                <li key={key} data-keyword={row.department_name} className="cursor-pointer">
                                    <Link to={ `/${ UrlSlugs.settings_department_edit}/${btoa(profileDetail.account_id)}/${btoa(row.group_id)}`} className="profile-wrap">
                                        {/* <div className={`profile-image logo-size-100 logo-size-md-85 mx-auto`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(companyProfile.logo) })` , backgroundColor : Utilities.bgcolorHideInitialBg(companyProfile.logo) }}>{  Utilities.showHideInitial( companyProfile.logo, companyProfile.company_name) }</div> */}
                                        <CommonProfileLogo classAttr={`profile-image logo-size-100 logo-size-md-85 mx-auto`} profileDetail={{ ...companyProfile, name : companyProfile.company_name }}  />
                                        <div className="profile-name notranslate font-gotham-bold mt-2 fs-18 color-theme-dark" data-name={row.department_name}>{row.department_name}</div>
                                    </Link>
                                </li>
                            )
                        }
                        </ul>

                        { showEmpty && <div className="text-center text-lowercase no-record-label mt-5">{ Utilities.labelDepartmentAvailable }</div>  }
                </div>
            </div>

       </div>
    )
}

export default ManageDepartments;