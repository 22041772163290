import axios from 'axios';
import ApiUrls from './ApiUrls';
import PagesAuthentication from './PagesAuthentication';
import UtilitiesHelpes from './../helpers/Utilities';


export const getCounterByDates = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.calendarGetCounterByDates, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }


        } catch (e) {

        }
    }

    return responseDetail;
}


export const getAppointmentsByDate = async (passval = {}) => {
    let responseDetail = {};

    if (Object.keys(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.calendarGetAppointmentsByDate, passval);
            if (parseInt(responseURL.status) === 200 && parseInt(responseURL.data.status) > 0) {
                responseDetail = responseURL.data.list;
            }

        } catch (e) {

        }
    }

    return responseDetail;
}


export const getAppointmentsByIDs = async (providedIDs = '') => {
    let responseDetail = {};

    if (providedIDs !== '') {
        try {
            const responseURL = await axios.post(ApiUrls.calendarGetAppointmentsByIds, { ids: providedIDs.toString() });
            if (parseInt(responseURL.status) === 200 && parseInt(responseURL.data.status) > 0) {
                responseDetail = responseURL.data.list;
            }


        } catch (e) {

        }
    }

    return responseDetail;
}

export const getReceiverAccountAndDeptReceiverIds = () => {
    let receiverAccountIdsArray = [], receiverDepartmentIdsArray = [];

    const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
    const sesstionLoginType = JSON.parse(loginType);

    let sesstionLoginTypeTemp = 1;
    if (sesstionLoginType !== null) {
        sesstionLoginTypeTemp = parseInt(sesstionLoginType);
    }

    // GET LOGIN USER
    const userLoggedinDetail = PagesAuthentication.getUserLoggedinDetail();

    if (sesstionLoginTypeTemp === 1) {
        receiverAccountIdsArray.push(userLoggedinDetail.account_id.toString());//+'|'+( userLoggedinDetail.hasOwnProperty('group_id') ? userLoggedinDetail.group_id.toString() : '0' ) );

        const usersAssociated = PagesAuthentication.getUsersAssociated() ?? {};
        Object.entries(usersAssociated).map(([key, pdetail]) => {
            /* if ( parseInt(userLoggedinDetail.account_id)!==parseInt(pdetail.account_id) ){
                receiverAccountIdsArray.push( pdetail.account_id.toString() );
            } */

            if (parseInt(userLoggedinDetail.account_id) === parseInt(pdetail.account_id)) {
                if (pdetail.hasOwnProperty('departments')) {
                    receiverDepartmentIdsArray.push('0');

                    Object.entries(pdetail.departments).map(([keyDept, deptDetail]) => {
                        if (deptDetail.hasOwnProperty('group_id')) {
                            if (parseInt(deptDetail.group_id) > 0) {
                                receiverDepartmentIdsArray.push(deptDetail.group_id);
                            }
                        }
                    });
                }
            }
        });

    } else {
        receiverAccountIdsArray.push(0);

        if (userLoggedinDetail.hasOwnProperty('group_id')) {

            const departmentActive = localStorage.getItem(process.env.REACT_APP_session_department_active);
            if (departmentActive !== null) {
                const departmentActiveArray = JSON.parse(departmentActive);

                if (Object.keys(departmentActiveArray).length > 0) {
                    if (departmentActiveArray.hasOwnProperty('group_id')) {
                        receiverDepartmentIdsArray.push(departmentActiveArray.group_id);
                    }
                }
            }
        }
    }

    /* if ( userLoggedinDetail.hasOwnProperty('group_id') ){

        const departmentActive = localStorage.getItem(process.env.REACT_APP_session_department_active);
        if ( departmentActive!==null ){
            const departmentActiveArray = JSON.parse(departmentActive);

            if ( Object.keys(departmentActiveArray).length>0 ){
                if ( departmentActiveArray.hasOwnProperty('group_id') ){
                    receiverDepartmentIdsArray.push(departmentActiveArray.group_id);
                }
            }
        }
    }else{
    
        const usersAssociated = PagesAuthentication.getUsersAssociated() ?? {};
        Object.entries(usersAssociated).map( ([key, pdetail]) => {
            if ( parseInt(userLoggedinDetail.account_id)!==parseInt(pdetail.account_id) ){
                receiverAccountIdsArray.push( pdetail.account_id.toString() );

                if ( pdetail.hasOwnProperty('departments') ){
                    receiverDepartmentIdsArray.push('0');

                    Object.entries(pdetail.departments).map( ([keyDept, deptDetail]) => {
                        if ( deptDetail.hasOwnProperty('group_id') ){
                            if ( parseInt(deptDetail.group_id)>0 ){
                                receiverDepartmentIdsArray.push(deptDetail.group_id);
                            }
                        }
                    });
                }
            }
        });
    } */

    receiverAccountIdsArray = UtilitiesHelpes.arrayUnique(receiverAccountIdsArray);
    receiverDepartmentIdsArray = UtilitiesHelpes.arrayUnique(receiverDepartmentIdsArray);

    //console.log({ receiverAccountIdsArray : receiverAccountIdsArray, receiverDepartmentIdsArray : receiverDepartmentIdsArray });
    return { receiverAccountIdsArray: receiverAccountIdsArray, receiverDepartmentIdsArray: receiverDepartmentIdsArray };
}