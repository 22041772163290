import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as RM from 'ramda';

import Utilities from './../helpers/Utilities';
import { globalProfile } from '../actions';
import UrlSlugs from '../helpers/UrlSlugs';
import PagesAuthentication from '../helpers/PagesAuthentication';
import TitlesLabels from '../helpers/TitlesLabels';

const BreadcrumbProfile = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const [listAccounts, setListAccounts] = useState([]);
    const [notificationTotalCounter, setNotificationTotalCounter] = useState(-1);
    const [accountKeyExpanded, setAccountKeyExpanded] = useState(-1);
    const [showLabel, setShowLabel] = useState(true);

    const expandList = (key) => {
        key = (parseInt(key) === parseInt(accountKeyExpanded)) ? -1 : key;
        setAccountKeyExpanded(parseInt(key));
    }

    const extractDetail = (key, row) => {

        let htmlReturn = '', htmlReturnSub = '';
        let companyDetail = {}, accountlabel = '';

        if (parseInt(row.account_type) === 4) {
            //accountlabel = row.hasOwnProperty('account_title') ? row.account_title : 'team member';

            companyDetail = row.hasOwnProperty('company_detail') ? row.company_detail : {};
            accountlabel = parseInt(row.team_is_admin ?? 0) === 1 ? TitlesLabels.general.admin : TitlesLabels.general.team_member;

            htmlReturn = <div className={`item-user-selection dropdown-item ${parseInt(accountKeyExpanded) === parseInt(key) ? 'expanded' : ''}`} onClick={() => expandList(key)}>
                <div className={`profile-logo`} style={{ backgroundImage: `url(${Utilities.showHideProfileImage(companyDetail.logo_url)})`, borderRightColor: Utilities.bgcolorHideInitialBg(companyDetail.logo_url) }}>{Utilities.showHideInitial(companyDetail.logo_url, row.name)}</div>
                <div className='line-height-1-2'>
                    <div className="user-label ellipsis-1">{companyDetail.name}</div>
                    <div className="ellipsis-1 color-theme-grey">{accountlabel}</div>
                </div>
                {parseInt(row.count) > 0 && <span className="counter counter-styled"><em>{Utilities.counterMore(row.count)}</em></span>}
            </div>


            Object.entries(row.child_account_list).map(([keySub, rowSub]) => {

                htmlReturnSub =
                    <>
                        {htmlReturnSub}
                        <Dropdown.Item key={keySub} className={`item-user-selection profile-subaccounts  ${parseInt(accountKeyExpanded) === parseInt(key) ? '' : 'd-none'}`} onClick={() => switchProfile(rowSub)} >
                            <div></div>
                            <div className='line-height-1-2'>
                                <div className="user-name ellipsis-1">{rowSub.name}</div>
                            </div>
                            {parseInt(rowSub.count) > 0 && <span className="counter"><em>{Utilities.counterMore(rowSub.count)}</em></span>}
                        </Dropdown.Item>
                    </>

            });

            //htmlReturn = <div key={key} className="profile-group-wrapper">{ htmlReturn } { htmlReturnSub }</div>

        } else {

            accountlabel = row.hasOwnProperty('title_name') ? row.title_name : Utilities.accountTypeLabels(row.account_type);

            htmlReturn = <div className="item-user-selection" onClick={() => switchProfile(row)}>
                <div className={`profile-logo`} style={{ backgroundImage: `url(${Utilities.showHideProfileImage(row.logo_url)})`, backgroundColor: Utilities.bgcolorHideInitialBg(row.logo_url) }}>{Utilities.showHideInitial(row.logo_url, row.name)}</div>
                <div className='line-height-1-2'>
                    <div className="user-label ellipsis-1">{accountlabel}</div>
                    <div className="ellipsis-1">{row.name}</div>
                </div>
                {parseInt(row.count) > 0 && <span className="counter counter-styled"><em>{Utilities.counterMore(row.count)}</em></span>}
            </div>

        }

        htmlReturn = <div key={key} className="profile-group-wrapper">{htmlReturn} {htmlReturnSub}</div>

        return htmlReturn;
    }

    const switchProfile = (profile) => {
        console.log(profile);
        dispatch(globalProfile(profile));
        history.push(`/${UrlSlugs.dashboard}`);
    }

    useEffect(() => {

        let notification_list = props.allConversationResults.home_conv_account_list ?? [];
        if (notification_list.length > 0) {

            let listNotificationsTemp = {},
                listProfiles = [];

            notification_list.forEach(val => {
                listNotificationsTemp[(val.id ?? '0').toString()] = (val.count ?? '0').toString();
            });

            let accountsList = PagesAuthentication.getUsersAssociated() ?? {};
            if (Object.keys(accountsList).length > 0) {


                Object.entries(accountsList).map(([key, profile]) => {
                    const loop_account_id = profile.account_id.toString(),
                        loop_account_type = profile.account_type.toString();

                    let profile_company_id = loop_account_id;
                    let team_department_id = '';
                    let counter = 0, counterTotal = 0, subProfiles = [];

                    if (loop_account_type == "4") {
                        profile_company_id = profile.company_detail.account_id;
                        team_department_id = profile.hasOwnProperty('group_id') ? profile.group_id : loop_account_id;
                    }


                    if (!Utilities.isEmpty(team_department_id)) {
                        team_department_id = `_${team_department_id}`;
                    }

                    let keyProfile = `${profile_company_id}${team_department_id}`;

                    if (listNotificationsTemp.hasOwnProperty(keyProfile)) {
                        counter = listNotificationsTemp[keyProfile];
                    }



                    if (loop_account_type == "1") {
                        if (counter > 0) {
                            listProfiles.push({
                                ...profile,
                                count: counter.toString(),
                                child_account_list: []
                            });
                        }

                    } else {

                        (profile.departments ?? []).forEach(proDetail => {

                            team_department_id = '';
                            profile_company_id = proDetail.account_id;

                            if (proDetail.account_type.toString() == "4") {
                                profile_company_id = proDetail.company_detail.account_id;
                                team_department_id = proDetail.hasOwnProperty('group_id') ? proDetail.group_id : proDetail.account_id;
                            }

                            if (!Utilities.isEmpty(team_department_id)) {
                                team_department_id = `_${team_department_id}`;
                            }

                            let keyProfileDept = `${profile_company_id}${team_department_id}`;
                            const count = (listNotificationsTemp.hasOwnProperty(keyProfileDept) ? (listNotificationsTemp[keyProfileDept] ?? '0') : '0');

                            counterTotal = parseInt(counterTotal) + parseInt(count);

                            if (count > 0) {
                                subProfiles.push({
                                    ...proDetail,
                                    count: count.toString(),
                                });
                            }
                        });

                        if (counterTotal > 0) {
                            listProfiles.push({
                                ...profile,
                                count: counterTotal.toString(),
                                child_account_list: subProfiles
                            });
                        }
                    }


                });

                /* const homeConnAccountList = props.allConversationResults.home_conv_account_list ?? [];

                let accountArrangedByCompany = [], keys = '';
                Object.entries(accountsList).map(([key, account]) => {
                    accountArrangedByCompany[key] = account;
                    if (parseInt(account.account_type) === 4) {
                        keys = `${account.team_company_id.toString()}_${key.toString()}`;
                        accountArrangedByCompany[keys] = account;
                    }
                });

                let accountSub = [];
                let notificationDetail = {};
                let notificationListTemp = [];
                let accountCompaniesKey = Object.keys(accountArrangedByCompany);


                for (const notification_account_key of homeConnAccountList) {

                    let accountLabel = 'team member';
                    notificationDetail = accountArrangedByCompany[notification_account_key.toString()] ?? {};

                    if (accountCompaniesKey.includes(notification_account_key.toString())) {

                        const accountDetail = accountArrangedByCompany[notification.account_id.toString()];
                        const team_is_admin = parseInt(accountDetail.team_is_admin);

                        let accountLabel = team_is_admin === 1 ? 'admin' : 'team member';
                        accountSub.push({ ...notificationDetail, account_title: accountLabel });

                    } else {

                        notificationListTemp.push({ ...notificationDetail, account_title: accountLabel });
                    } */


                /* const account_type = parseInt(notification.account_type);

                let accountLabel = 'team member';
                if (account_type === 3) {

                    if (typeof accountArrangedByCompany[notification.account_id.toString()] !== 'undefined') {

                        const accountDetail = accountArrangedByCompany[notification.account_id.toString()];
                        const team_is_admin = parseInt(accountDetail.team_is_admin);

                        if (team_is_admin === 1) {
                            accountLabel = 'admin';
                        }
                    }

                    accountSub.push({ ...notification, account_title: accountLabel });;

                } else if (account_type === 1) {
                    notificationListTemp.push({ ...notification, account_title: accountLabel });
                } */
                /* }

                if (accountSub.length > 0) {
                    if ('name' in accountSub[0]) {
                        const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('name')));
                        accountSub = RM.sort(byName, accountSub);
                    }
                    notificationListTemp = RM.concat(notificationListTemp, accountSub);
                }
                notification_list = notificationListTemp; */

            }

            setListAccounts(listProfiles);
            setNotificationTotalCounter(parseInt(props.allConversationResults.home_conv_count ?? 0));

        } else {
            setNotificationTotalCounter(0);
        }

    }, [props.allConversationResults]);


    useEffect(() => {
        const showLabelTemp = props.showLabel;
        if (typeof showLabelTemp !== 'undefined') {
            setShowLabel(showLabelTemp);
        }
    }, [props.showLabel]);


    return (
        <>
            {
                parseInt(notificationTotalCounter) > 0 &&
                <Dropdown className="dropdown-user-selection">
                    <Dropdown.Toggle variant="dropdown-user-current btn-icon-wrap" id="dropdown-basic">
                        <div className="btn-icon btn-comment" title="new messages">
                            {(parseInt(notificationTotalCounter) > 0) && <span className="counter">{Utilities.counterMore(notificationTotalCounter)}</span>}
                        </div>
                        {showLabel === true && <span className="ml-2">new messages</span>}
                    </Dropdown.Toggle>

                    {
                        (parseInt(notificationTotalCounter) > 0) &&
                        <Dropdown.Menu >
                            {showLabel === false && <div className="dropdown-header pt-3 pb-2">new messages</div>}
                            {
                                Object.entries(listAccounts).map(([key, row]) => extractDetail(key, row))
                            }

                        </Dropdown.Menu>
                    }
                </Dropdown>


            }

            {
                parseInt(notificationTotalCounter) === -1 && <div className="btn-icon btn-comment opacity-05 processing cursor-wait" title="new messages"></div>
            }

        </>

    )
}

export default BreadcrumbProfile;