import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
//import { useHistory, useParams } from 'react-router-dom';
import jQuery from 'jquery';
import axios from 'axios';
//import { Modal } from 'react-bootstrap';
import moment from 'moment';

import PagesAuthentication from '../helpers/PagesAuthentication';
import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import ChatUtilities from '../helpers/ChatUtilities';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';

import DepartmentForms from '../components/popups/DepartmentForms';

import { showHeader, showFooter, pageTitle, passDocumentDetail, passConversationMessage } from '../actions';
import CommonProfileLogo from '../components/common/CommonProfileLogo';
import DashboardProfileSearch from '../components/common/DashboardProfileSearch';
import DepartmentChatConfirm from '../components/popups/DepartmentChatConfirm';
import SessionUtilities from '../helpers/SessionUtilities';



const Departments = ({ match }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    //console.log( useParams() );
    //const { id } = useParams();
    //console.log(id);

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;

    const [loggedInProfile, setLoggedInProfile] = useState({});
    const [listDepartments, setListDepartments] = useState([]);
    const [departmentDetail, setDepartmentDetail] = useState([]);
    const [companyProfile, setCompanyProfile] = useState([]);
    //const [ defaultSearchValue, setDefaultSearchValue ] = useState('');
    const [showEmpty, setShowEmpty] = useState(false);
    const [contentLoaderClass, setContentLoaderClass] = useState('animate');

    const [showFormPopUp, setShowFormPopUp] = useState(false);
    const handleCloseFormPopUp = () => setShowFormPopUp(false);

    // MODAL
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (department) => {

        department = {
            ...department,
            contact_number: department.contact_number === 'null' ? '' : department.contact_number
        };
        setDepartmentDetail(department);
        setShow(true);
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            //setDefaultSearchValue(value);
            searchFilter(value);
        }
    }

    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-departments > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }



    const resetSearchValue = () => {
        //setDefaultSearchValue('');
        document.getElementById('input_name').focus();
        searchFilter('');
    }

    const toggleMainSearch = (action = 0) => {
        //dispatch( showMainSearch(action) );
        history.push(`/${UrlSlugs.page_directorySearch}/${btoa(action)}`);
    }

    const getDepartments = (accountID) => {

        if (parseInt(accountID) > 0) {

            // GET COMPANY PROFILE
            axios
                .post(ApiUrls.profilegetByID, { id: accountID })
                .then(response => {

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus === 1) {

                            const company_detail = Utilities.formatProfileDetail(dataResponse.detail);
                            setCompanyProfile(company_detail);

                            dispatch(pageTitle(`${TitlesLabels.siteTitles.departments} | ${dataResponse.detail.company_name}`));
                        }
                    }
                })
                .catch((err) => {

                });


            // GET DEPARTMENTS
            axios
                .post(ApiUrls.departmentListByAccountID, { id: accountID, status: 1, show_number: 1 })
                .then(response => {

                    if (response.status === 200) {
                        const dataResponse = response.data;
                        const dataStatus = parseInt(dataResponse.status);

                        if (dataStatus === 1) {
                            if (Utilities.isEmpty(dataResponse.departments)) {
                                setShowEmpty(true);
                            } else {
                                setShowEmpty(false);
                                setListDepartments(dataResponse.departments);
                            }
                        } else {
                            setShowEmpty(true);
                        }
                    }

                    setContentLoaderClass('');
                })
                .catch((err) => {
                    setShowEmpty(true);
                    setContentLoaderClass('');
                });

        }

    }

    const handleConfirmAction = async (form_status = 0) => {
        form_status = parseInt(form_status);

        if (companyProfile.hasOwnProperty('account_id') && departmentDetail.hasOwnProperty('group_id')) {

            let docid = match.params.docid;
            if (typeof docid !== 'undefined') {

                const passValues = {
                    account_id: parseInt(companyProfile.account_id || 0),
                    convers_detail: {},
                    document_id: parseInt(atob(docid)),
                    department_id: parseInt(departmentDetail.group_id || 0)
                }

                dispatch(passDocumentDetail(passValues));
                handleClose();

                return false;
            }


            if (form_status === 0) {

                const button_name = 'btn-chat-department';
                const conversationDetail = await getConversationDetail();
                const conversation = conversationDetail.data.result.conversation_details ?? {};

                Utilities.elementDisabledStatus(button_name, false);

                if (Object.entries(conversation).length > 0) {
                    let chatPassValue = ChatUtilities.encryptChatDetail(conversation);

                    ChatUtilities.saveAccountsConversationDetail(conversation, 'page - department list');
                    history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation.account_id))}/${chatPassValue}`);
                }

                //history.push(`/${ UrlSlugs.chat }/${ btoa( companyProfile.account_id ) }/3/${ btoa(departmentDetail.group_id) }`);

            } else {
                setShowFormPopUp(true);
            }
        }

        setShow(false);
    }


    const getConversationDetail = async () => {

        let conversationDetail = {};
        if (companyProfile.hasOwnProperty('account_id') && departmentDetail.hasOwnProperty('group_id')) {
            const contactRequestValues = ChatUtilities.contactRequest(loggedInProfile, companyProfile, departmentDetail.group_id);
            conversationDetail = await axios.post(contactRequestValues.url, contactRequestValues.values);
        }

        return conversationDetail;
    }

    const handeFormSubmit = async (formDetail) => {

        if (companyProfile.hasOwnProperty('account_id') && departmentDetail.hasOwnProperty('group_id')) {

            const button_name = 'btn-submit-form';
            if (Object.keys(formDetail).length > 0) {

                Utilities.elementDisabledStatus(button_name, true);

                const conversationDetail = await getConversationDetail();
                if (Object.keys(conversationDetail).length === 0) {
                    Utilities.elementDisabledStatus(button_name, false);
                    return false;
                }

                let form_status = parseInt(departmentDetail.form_status || 0);

                let subject = '';
                if (form_status === 3) {
                    subject = formDetail.subject.toString();
                }

                let name = '';
                if (form_status === 1 || form_status === 2) {
                    name = formDetail.name.toString();
                }

                let no_people = '';
                if (form_status === 2) {
                    no_people = formDetail.no_people.toString();
                }

                let requestDate = formDetail.date;
                let requestTime = requestDate.toString() + ' ' + formDetail.time.toString() + ':00';

                requestTime = moment(new Date(requestTime.toString())).format('HH:mm:ss').toLocaleString();
                requestDate = moment(new Date(requestDate.toString())).format('YYYY-MM-DD').toLocaleString();

                const CONVERSATION_DETAIL = conversationDetail.data.result;

                const conversation_list = CONVERSATION_DETAIL.conversation_details;
     
                const CONVERS_DATA = ChatUtilities.encryptChatDetail(conversation_list);

                const conversIDGenerated = conversation_list.convers_id.toString();

                const account_department_id = loggedInProfile.hasOwnProperty('group_id') ? loggedInProfile.group_id.toString() : '0';


                const company_id = parseInt(conversation_list.account_company_id) === 0 ? departmentDetail.account_id : conversation_list.account_company_id;
                const TOKEN_SESSION = SessionUtilities.getSessionToken();
                const passValue = {
                    api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                    account_id: loggedInProfile.account_id.toString(),
                    account_type: loggedInProfile.account_type.toString(),
                    company_id: company_id.toString(), //conversation_list.account_company_id.toString(), //departmentDetail.account_id.toString(),
                    convers_id: conversIDGenerated.toString(),
                    department_id: departmentDetail.group_id.toString(),
                    flag: form_status.toString(), // 1 : appointment, 2 : reservation, 3 : complaints
                    name: name,
                    subject: subject,
                    date: requestDate.toString(),
                    time: requestTime.toString(),
                    no_people: no_people,
                    description: formDetail.remarks.toString(),
                    account_department_id: account_department_id.toString()
                }

               // console.log('bizchatRequestAppointmentComplaint');
               // console.log(ApiUrls.bizchatRequestAppointmentComplaint);
               // console.log(passValue);


                await axios
                    .post(ApiUrls.bizchatRequestAppointmentComplaint, passValue)
                    .then(response => {

                        Utilities.elementDisabledStatus(button_name, false);

                        if (parseInt(response.status) === 201) {
                            if (parseInt(response.data.success) === 1) {
                                ChatUtilities.saveAccountsConversationDetail(conversation_list);
                                history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation_list.account_id))}/${CONVERS_DATA}`);

                                Utilities.messagePopup('success', response.data.successMessage);
                                handleCloseFormPopUp();

                            } else {
                                Utilities.messagePopup('error', response.data.errorMessage);
                            }
                        }
                    })
                    .catch((err) => {
                        Utilities.messagePopup('error', errorMsg);
                        Utilities.elementDisabledStatus(button_name, false);

                    });


            } else {

                const button_name = 'btn-chat-department';
                Utilities.elementDisabledStatus(button_name, true);

                const conversationDetail = await getConversationDetail();
                const conversation = conversationDetail.data.result.conversation_details ?? {};

                Utilities.elementDisabledStatus(button_name, false);

                if (Object.entries(conversation).length > 0) {
                    let chatPassValue = ChatUtilities.encryptChatDetail(conversation);

                    ChatUtilities.saveAccountsConversationDetail(conversation, 'page - department list');
                    history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation.account_id))}/${chatPassValue}`);
                }

                //history.push(`/${ UrlSlugs.chat }/${ btoa( companyProfile.account_id ) }/3/${ btoa(departmentDetail.group_id) }`);
            }
        }
    }


    useEffect(() => {

        let reqID = match.params.id;
        if (typeof reqID !== 'undefined') {
            reqID = atob(reqID);

            if (parseInt(reqID) > 0) {
                setContentLoaderClass('animate');
                setListDepartments([]);

                getDepartments(reqID);
            }
        }

        let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginBasicInfo !== null) {
            loginBasicInfo = JSON.parse(loginBasicInfo);
            setLoggedInProfile(loginBasicInfo);
        }

    }, [match.params.id]);


    useEffect(() => {
        PagesAuthentication.getUserLoggedin();
        let loginType = parseInt(localStorage.getItem(process.env.REACT_APP_session_logintype) ?? 1);

        dispatch(showHeader(loginType === 2 ? 5 : 1));
        dispatch(showFooter(0));

        dispatch(passConversationMessage({}));

    }, []);


    return (
        <div className="departments-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className="cursor-pointer" onClick={() => toggleMainSearch(1)}>{TitlesLabels.navigations.search}</div>
                            {companyProfile.hasOwnProperty('company_name') && <div>{companyProfile.company_name}</div>}
                            <div className="current">{TitlesLabels.navigations.departments}</div>
                        </li>
                        <li className="btn-option"></li>
                    </ul>
                </div>
            </div>

            <div className="main-content">
                {
                    Object.keys(listDepartments ?? []).length > 0 &&
                    <div className="content-subnav-wrap profile-search-wrap  py-4">
                        <div className="container px-5">
                            <div className="profile-search-wrap bg-white d-flex align-items-center form-theme  w-100 border-radius">
                                <DashboardProfileSearch pushValue={pushValue} resetSearchValue={resetSearchValue} />
                                {/* <div className='form-group mb-0 border-0  w-100 '>
                                    <input type="text" name="input_name" id="input_name" className="form-input input-search" value={ defaultSearchValue }  onChange={ (e) => pushValue( e, 'search-keyword') }  placeholder="type here" autoComplete='off' />
                                </div>
                                { ! Utilities.isEmpty(defaultSearchValue) && <div><span className="color-theme-green cursor-pointer fs-16 px-3" onClick={ () => resetSearchValue() }>clear</span></div> } */}
                            </div>

                        </div>
                    </div>
                }


                {!Utilities.isEmpty(contentLoaderClass) && <div className="content-subnav-wrap profile-search-wrap  py-4"></div>}

                <div className="container pt-4">

                    {
                        !Utilities.isEmpty(contentLoaderClass) &&
                        <ul className="listing-profiles-preloader mt-4">
                            {
                                [...Array(12).keys()].map((key) =>
                                    <li key={key}>
                                        <div className="profile-image"></div>
                                        <div></div>
                                        <div></div>
                                    </li>
                                )
                            }
                        </ul>
                    }


                    {
                        Object.entries(listDepartments).length > 0 &&
                        <ul id="listing-departments" className={`global-listing-profiles max-height-fit  my-4 ${Object.keys(listDepartments).length === 0 ? 'd-none' : ''} `}>
                            {
                                Object.entries(listDepartments).map(([key, row]) =>
                                    <li key={key} data-keyword={row.department_name} onClick={() => handleShow(row)} className="cursor-pointer">
                                        <div className="profile-wrap">
                                            <div className="profile-image-wrap">
                                                {/* <div className={`profile-image logo-size-100 logo-size-md-85 mx-auto`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(Utilities.formatProfileDetail(companyProfile).img) })`, backgroundColor: Utilities.bgcolorHideInitialBg( Utilities.formatProfileDetail(companyProfile).img )  }}>{ Utilities.showHideInitial( Utilities.formatProfileDetail(companyProfile).img , companyProfile.name) }</div> */}

                                                <CommonProfileLogo classAttr={`profile-image logo-size-100 logo-size-md-85 mx-auto`} profileDetail={companyProfile} />
                                            </div>
                                            {/* <div className={`profile-image bg-black-theme`}>{ Utilities.showHideInitial( Utilities.formatProfileDetail(companyProfile).img , companyProfile.name) }</div>
                                                    </div>  */}
                                            <div className="profile-name notranslate font-gotham-bold mt-2 fs-18 color-theme-dark" data-name={row.department_name}>{row.department_name}</div>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    }

                    {showEmpty && <div className="text-center text-lowercase no-record-label mt-4">{TitlesLabels.general.noDepartmentAvailable}</div>}


                </div>
            </div>


            <DepartmentChatConfirm show={show} handleClose={handleClose} companyProfile={companyProfile} departmentDetail={departmentDetail} handleConfirmAction={handleConfirmAction} />

            <DepartmentForms departmentDetail={departmentDetail} handeFormSubmit={handeFormSubmit} handleCloseFormPopUp={handleCloseFormPopUp} showFormPopUp={showFormPopUp} action="create" timeStamp={new Date()} />


        </div>
    )
}

export default Departments;