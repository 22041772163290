import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';

import { passChatMessageText } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';
import SessionUtilities from '../helpers/SessionUtilities';


const ChatTemplateMessages = (props) => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    const errorMsg = process.env.REACT_APP_message_error_process;

    const wordsLimit = 100;
    const passInternalProfile = useSelector(state => state.passInternalProfile);
    const defaultValue = { title: '', template: '' };

    let globalLoginDetail = {};

    const [formValues, setFormValues] = useState(defaultValue);
    const [templateList, setTemplateList] = useState([]);
    const [loginDetail, setLoginDetail] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [formAction, setFormAction] = useState('');
    const [templateDetail, setTemplateDetail] = useState({});
    const [rowSelected, setRowSelected] = useState(-1);
    const [templateIDs, setTemplateIDs] = useState([]);
    const [showDeleteOption, setShowDeleteOption] = useState(false);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'title') {
            formValuesTemp = { ...formValuesTemp, ...{ title: value } };
        } else if (fieldName === 'template') {

            const wordsCounter = Utilities.wordsCounter(value);
            if (parseInt(wordsCounter) > wordsLimit) {
                Utilities.messagePopup('error', `Only ${wordsLimit} words is allowed!`);
                return false;
            }
            formValuesTemp = { ...formValuesTemp, ...{ template: value } };
        }
        setFormValues(formValuesTemp);
    }

    const toggleTemplateInput = (e, row) => {
        let templateIDsTemp = templateIDs;
        let id = row.group_id.toString();

        (!templateIDsTemp.includes(id)) ? templateIDsTemp.push(id) : (templateIDsTemp = [...Utilities.removeValueInArray(templateIDsTemp, id)]);
        templateIDsTemp = Utilities.arrayUnique(templateIDsTemp);

        setTemplateIDs(templateIDsTemp);
    }

    const addEditForm = (action, detailTemplate = {}) => {


        if (action === 'add') {
            setFormValues(defaultValue);
            props.showTemplateBackOption(1);

        } else if (action === 'edit') {
            setTemplateDetail(detailTemplate);

            const reqValues = { template: detailTemplate.message, title: detailTemplate.title };
            setFormValues(reqValues);

            props.showTemplateBackOption(2);
        }

        setFormAction(action);
    }

    const reloadList = () => {
        let timer = setTimeout(() => {
            getTemplates();
            clearTimeout(timer);
        }, 100);
    }


    const getTemplates = async () => {

        let account_id = globalLoginDetail.account_id ?? 0;

        if (Object.keys(loginDetail).length > 0) {
            account_id = loginDetail.account_id;
        }

        const passValue = {
            api_token: TOKEN_SESSION.toString(),
            account_id: account_id.toString()
        }

        if (parseInt(account_id) > 0) {

           await  axios
                .post(ApiUrls.bizchatGetTemplateMessage, passValue)
                .then(response => {

                    setIsLoading(false);

                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {

                            if (Object.keys(response.data.result ?? {}).length > 0) {
                                const templatesSaved = response.data.result.menu_saved_message_list ?? [];
                                setTemplateList(templatesSaved);

                                setFormAction('');
                                setShowDeleteOption(false);
                                setTemplateIDs([]);
                                setRowSelected(-1);

                            } else {
                                setTemplateList([]);
                            }
                        } else {
                            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGetTemplates);
                        }

                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    Utilities.messagePopup('error', errorMsg);
                });

        }
    }

    const sendMessage = (message) => {
        if (!Utilities.isEmpty(message)) {
            dispatch(passChatMessageText(message));
        }
    }

    const sendOnceOff = () => {
        const message = formValues.template;
        if (!Utilities.isEmpty(message)) {
            dispatch(passChatMessageText(message));
        }
    }

    const toggleSelected = (key) => {
        if (showDeleteOption === false) {
            let rowSelectedTemp = (key === rowSelected) ? -1 : key;
            setRowSelected(rowSelectedTemp);
        }
    }

    useEffect(() => {
        if (Object.keys(passInternalProfile).length > 0) {
            globalLoginDetail = passInternalProfile;
            setLoginDetail(passInternalProfile);
            getTemplates();

        } else {
            let loginBasicInfo = localStorage.getItem(process.env.REACT_APP_session_login);
            if (loginBasicInfo !== null) {
                loginBasicInfo = JSON.parse(loginBasicInfo);
                globalLoginDetail = loginBasicInfo;
                setLoginDetail(loginBasicInfo);
                getTemplates();
            }
        }

    }, []);



    useEffect(() => {

        const formTemplateAction = props.formTemplateAction;
        if (parseInt(formTemplateAction) === 0) {

            setFormAction('');
            setShowDeleteOption(false);
            setTemplateIDs([]);
            setRowSelected(-1);

            reloadList();
        }

    }, [props.formTemplateAction]);


    return (
        <div className="chat-template-messages-content text-lowercase pt-2">
            {
                isLoading === false ?

                    <Fragment>

                        {
                            Utilities.isEmpty(formAction) &&
                            <section>

                                <ul className="template-list-option">
                                    {
                                        Object.entries(templateList).map(([key, row]) =>
                                            <li key={key} className={`${rowSelected === key ? 'selected' : ''}`}>

                                                <div className="temp-wrap" onClick={() => toggleSelected(key)}>
                                                    <div className="temp-title fs-16 fs-md-16">{row.title}</div>
                                                    <div className={`temp-msg fs-14 fs-md-14 ${rowSelected === key ? 'd-block' : ''} `}>{row.message}</div>
                                                </div>

                                                {
                                                    rowSelected === key &&
                                                    <div className="button-group-boolean mt-3">
                                                        <button type="button" className="btn-theme-black" onClick={() => addEditForm('edit', row)}>edit</button>
                                                        <button type="button" className="btn-theme-black" onClick={() => sendMessage(row.message)}>send</button>
                                                    </div>
                                                }

                                                {
                                                    showDeleteOption &&
                                                    <div className="deletion-wrap checkbox-radio-default checkbox-radio-check-icon">
                                                        <input type="checkbox" checked={`${((templateIDs.includes(row.group_id.toString())) ? 'checked' : '')}`} onChange={(e) => toggleTemplateInput(e, row)} />
                                                    </div>
                                                }

                                            </li>
                                        )
                                    }
                                </ul>


                                {
                                    Object.entries(templateList).length === 0 &&
                                    <div className="text-center text-lowercase no-record-label">{Utilities.labelMessageAvailable}</div>
                                }

                            </section>
                        }


                        {
                            !Utilities.isEmpty(formAction) &&
                            <section className="form-theme">

                                <div className="form-group ">
                                    <input type="text" name="title" className="form-control" placeholder=" " autoComplete="off" value={formValues.title} onChange={(e) => pushValue(e, 'title')} ></input>
                                    <label className="form-label">Title</label>
                                </div>

                                <div className="form-group">
                                    <textarea className="form-control scroll-none" placeholder=" " rows="6" autoComplete="off" value={formValues.template} onChange={(e) => pushValue(e, 'template')} ></textarea>
                                    <label className="form-label">Type here</label>
                                </div>
                                <div className="text-right"><span className="text-blue">maximum {wordsLimit} words</span></div>


                                <div className="pt-4">
                                    <button type="button" className="btn-theme-black" onClick={() => sendOnceOff()}>send once off</button>
                                </div>

                            </section>
                        }


                    </Fragment>

                    :
                    <div className="content-loader animate"></div>
            }

        </div>
    )
}

export default ChatTemplateMessages;
