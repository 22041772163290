import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

//import PagesAuthentication from '../helpers/PagesAuthentication';
import { showHeader as dispatchShowHeader, showFooter as dispatchShowFooter, showLoader as dispatchShowLoader } from '../actions';
import Utilities from '../helpers/Utilities';
import UrlSlugs from '../helpers/UrlSlugs';
import QueryUtilities from '../helpers/QueryUtilities';
import PagesAuthentication from '../helpers/PagesAuthentication';
import SessionUtilities from '../helpers/SessionUtilities';
import { getCampainDetail, updateClicksAccount } from '../helpers/QueryCampaign';
import TitlesLabels from '../helpers/TitlesLabels';
import { getReferralDetail, updateClicksReferral } from '../helpers/QueryReferral';
import { directToPersonalInfo } from '../helpers/UtilitiesCampaign';
import { checkingFollowDailyGlanceStatus } from '../helpers/QueryDailyGlance';


const DirectAccess = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    let loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
    if (loginType !== null) {
        loginType = JSON.parse(loginType);
        loginType = parseInt(loginType);
    } else {
        loginType = 1;
    }

    const redirectDashboard = () => {
        let urlDashboard = loginType === 2 ? `/${UrlSlugs.departmentDashboard}` : `/${UrlSlugs.dashboard}`;
        history.push(urlDashboard);
    }


    const getCompanyDetailByTeamAccountId = async (account_id = 0) => {
        account_id = parseInt(account_id);

        if (account_id > 0) {
            dispatch(dispatchShowLoader(1));
            const responseProfileDetail = await QueryUtilities.getProfileDetail(account_id, 1);
            dispatch(dispatchShowLoader(0));

            if (Object.keys(responseProfileDetail).length === 0) {
                redirectDashboard();
                return false;
            }

            if (responseProfileDetail.hasOwnProperty('company_detail')) {
                history.push(`/${UrlSlugs.teamMembers}/${btoa(responseProfileDetail.company_detail.account_id)}/${btoa(account_id)}`); // companyid/teamid

            } else {
                redirectDashboard();
            }

        } else {
            redirectDashboard();
        }
    }

    const campaignProper = async (campaign_id_encoded = '', campaign_type = 1) => {


        if (!Utilities.isEmpty(campaign_id_encoded)) {
            campaign_type = parseInt(campaign_type);

            const passvalCampaign = {
                campaign_id_encoded: campaign_id_encoded.toString(),
            };


            let campaignDB = {};

            if (campaign_type === 1) {
                campaignDB = await getCampainDetail(passvalCampaign);
            } else {
                campaignDB = await getReferralDetail(passvalCampaign);
            }

            // SET COMPAIGN (1),  REFERRAL (2)
            SessionUtilities.setRefferalType(campaign_type);


            const campaignDetail = campaignDB,
                campaignStatus = parseInt(campaignDB.status ?? -1);




            let linkCounter = 0;
            if (campaignStatus === -1) {
                console.log('not exist!');
                linkCounter++;

            } else if (campaignStatus === 0) {
                console.log('expired!');
                linkCounter++;
            }

            if (linkCounter > 0) {
                confirmAlert({
                    title: 'Error',
                    message: TitlesLabels.alertMessages.linkInvalid,
                    overlayClassName: 'error',
                    buttons: [
                        {
                            label: 'Close',
                            onClick: () => {
                                history.push(`/${UrlSlugs.login}`);
                            }
                        }
                    ],
                    onClickOutside: () => {
                        history.push(`/${UrlSlugs.login}`);
                    },
                    onKeypressEscape: () => {
                        history.push(`/${UrlSlugs.login}`);
                    },
                });

                return false;
            }


            const pageType = parseInt(campaignDetail.page ?? 2);

            if (Object.keys(campaignDetail).length > 0 && pageType > 0) {


                // LOGOUT // clear sessions
                PagesAuthentication.logoutUser();

                // UPDATE COUNTER
                if (campaign_type === 1) {
                    await updateClicksAccount({ campaign_id: campaignDetail.id });

                } else {
                    await updateClicksReferral({ referral_id: campaignDetail.id });

                }


                SessionUtilities.setSignupCampaignDownload(1);


                // SAVING COMPAIN ID
                SessionUtilities.setSignupCampaign({ id: campaignDetail.id, type: pageType, category: campaign_type });


                // REDIRECT TO SIGNIP PAGE

                if (pageType === 1) {
                    //history.push(`/${UrlSlugs.signup_socialConnect}`);

                    let redirectPersonalUrl = UrlSlugs.signup_campaignPersonalInfo;
                    history.push(`/${redirectPersonalUrl}`);

                } else if (pageType === 2) {
                    //history.push(`/${UrlSlugs.signup_companyAccount}`);
                    history.push(`/${UrlSlugs.signup_campaignPersonalOption}`);

                } else {
                    history.push(`/${UrlSlugs.login}`);
                }

            } else {
                history.push(`/${UrlSlugs.login}`);
            }

        }

    }

    useEffect(async () => {

        dispatch(dispatchShowHeader(0));
        dispatch(dispatchShowFooter(0));

        //const allowAccessArr = ['profile_reset_password'];
        //PagesAuthentication.getUserLoggedin();


        const segment = Utilities.getUrlSegments();
        const segmentName = segment[0].toLowerCase(), segmentEncrypted = (segment[1] ?? '').toString();
        let segmentDecrypted = [], decryptedSlug = '', urlRequest = '';


        if (segmentName !== 'direct-access') {
            redirectDashboard();
            return false;
        }

        if (!Utilities.isEmpty((segmentEncrypted ?? ''))) {

            segmentDecrypted = atob(segmentEncrypted).toString().split('/');
            decryptedSlug = segmentDecrypted[0].toLowerCase();
            // console.log(decryptedSlug, segmentDecrypted);

            if (decryptedSlug === 'comp_group_poup') {
                history.push(`/${UrlSlugs.companyGroups}/${btoa(segmentDecrypted[1])}/${btoa(segmentDecrypted[2])}`); // companyid/groupid

            } else if (decryptedSlug === 'team_connect_poup') {
                await getCompanyDetailByTeamAccountId(segmentDecrypted[1]);

            } else if (decryptedSlug === 'profile_reset_password') {

                //https://beonchat.com/app/index.php/password/new/63678968f4f22cee04c5a8abf6d3f81e
                //https://beonchat.com/password/new/63678968f4f22cee04c5a8abf6d3f81e

                urlRequest = `/${UrlSlugs.passwordNewReset}/${segmentDecrypted[1]}`;
                history.push(urlRequest);

            } else if (decryptedSlug === 'comp_connect_poup') {

                // http://localhost:3002/direct-access/Y29tcF9jb25uZWN0X3BvdXAvMTA2/cornerhouse

                let urlDashboard = loginType === 2 ? `/${UrlSlugs.departmentDashboardDirectConnect}` : `/${UrlSlugs.dashboardDirectConnect}`;
                history.push(`${urlDashboard}/${btoa(segmentDecrypted[1])}`);


                // NOTICE ARTICLE
            } else if (decryptedSlug === 'notifi_news_feed_detail') {

                const articleId = segmentDecrypted[1] ?? '',
                    categoryId = segmentDecrypted[2].replace('g_', '') ?? '',
                    articleDate = segmentDecrypted[3] ?? '';

                if (!Utilities.isEmpty(articleId) && !Utilities.isEmpty(categoryId) && !Utilities.isEmpty(articleDate)) {
                    const artileLink = `/${UrlSlugs.notificationDailyGlanceArticle}/${btoa(articleId)}/${btoa(articleDate ?? '')}`;
                    history.push(artileLink);

                }



                // CAMPAIGN
            } else if (decryptedSlug === 'cg') {
                campaignProper(segmentDecrypted[1], 1);


                // REFERRAL
            } else if (decryptedSlug === 'cr') {
                campaignProper(segmentDecrypted[1], 2);


                // GLANCE
            } else if (decryptedSlug === 'glance_link') {

                PagesAuthentication.getUserLoggedin();

                const glanceStatus = await checkingFollowDailyGlanceStatus();
                if (glanceStatus) {
                    history.push(`/${UrlSlugs.notificationDailyGlance}`);
                } else {

                    let urlDashboard = loginType === 2 ? `/${UrlSlugs.departmentDashboardDirectConnect}` : `/${UrlSlugs.dashboardDirectConnect}`;
                    const dailyGlanceCompanyId = process.env.REACT_APP_notification_followed_company_id ?? '0';

                    if (dailyGlanceCompanyId != '0') {
                        history.push(`${urlDashboard}/${btoa(dailyGlanceCompanyId)}`);
                    } else {
                        redirectDashboard();
                    }

                }

                // REDIRECT TO DASHBOARD
            } else {
                redirectDashboard();
            }

        } else {
            redirectDashboard();
        }



    }, []);

    return (<></>)
}

export default DirectAccess;