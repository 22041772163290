import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import TitlesLabels from '../../helpers/TitlesLabels';
import PagesAuthentication from '../../helpers/PagesAuthentication';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer, showDepartmentSettings as showDepartmentSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../actions';


const ImportantInformation = () => {

    const dispatch = useDispatch();

    const [keySelected, setKeySelected] = useState(0);

    const messageTitle = `business account activation`;
    const messageTemp = `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).`;

    const infoMessages = [
        {
            title: messageTitle,
            message: messageTemp
        },
        {
            title: messageTitle,
            message: messageTemp
        },
        {
            title: messageTitle,
            message: messageTemp
        }
    ]

    const triggerSetting = () => {

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);

        if (sesstionLoginType !== null) {
            sesstionLoginType = parseInt(sesstionLoginType);

            if (sesstionLoginType === 2) {
                dispatch(showDepartmentSettingsReducer(new Date()));
            } else {
                dispatch(showMainSettingsReducer(new Date()));
            }

        } else {
            dispatch(showMainSettingsReducer(new Date()));
        }

    }

    const expandContent = (key = -1) => {
        setKeySelected(key === keySelected ? -1 : key);
    }

    useEffect(() => {

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.importantInformation}`));

        let headerType = 1;
        let loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        if (loginType !== null) {
            loginType = JSON.parse(loginType);
            loginType = parseInt(loginType);
            headerType = loginType === 2 ? 5 : 1;
        }

        dispatch(showHeader(headerType));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

    }, []);

    return (
        <div className="important-information-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div>{TitlesLabels.settings}</div>
                            <div className="current">{TitlesLabels.navigations.importantInformation}</div>

                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>

            </div>


            <div className="main-content">
                <div className="container">

                    <section className="col-8 mx-auto">

                        <ul className="text-lowercase fs-16">
                            {
                                infoMessages.map((content, key) =>
                                    <li key={key}>
                                        <div className="font-gotham-medium-18 pl-3 pr-2 d-flex align-items-center justify-content-between cursor-pointer py-3" onClick={() => expandContent(key)}>
                                            <div>{content.title}</div>
                                            <div className="btn-option"><div className={`btn-icon ${key === keySelected ? 'btn-arrow-up' : 'btn-arrow-down'}`}></div></div>
                                        </div>
                                        <div className={`pb-4 px-3 ${key !== keySelected ? 'd-none' : ''}`}>{content.message}</div>
                                    </li>
                                )
                            }
                        </ul>

                    </section>

                </div>
            </div>
        </div>
    )
}

export default ImportantInformation;