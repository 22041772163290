import React, { useState } from 'react';
import DirectorySearchNoRecord from './DirectorySearchNoRecord';
import Utilities from '../../helpers/Utilities';
import DirectorySearchKeywordsTemplate from './DirectorySearchKeywordsTemplate';
import DirectorySearchLoaderProfile from './DirectorySearchLoaderProfile';
import DirectorySearchBackNavigation from './DirectorySearchBackNavigation';
import DirectorySearchProfileListing from './DirectorySearchProfileListing';
import { getPofilesByIds } from '../../helpers/QueryDirectorySearch';
import DirectorySearchItemTemplate from './DirectorySearchItemTemplate';
import { getCounterKeywordByArea, getCounterKeywordByLocation } from '../../helpers/QueryDirectorySearchKeywords';


const DirectorySearchKeywordsSuburbs = (props) => {

    let listResultProfiles = [], dataRequest = {}, resDb_listResult = {};
    const listArea = props.listResult.list_area ?? [];
    const profileCount = props.listResult.profile_count ?? 0;
    const navText = Utilities.labelShowAll;

    const countryId = parseInt(props.countryid ?? 0);
    const stateId = parseInt(props.stateid ?? 0);
    const cityId = parseInt(props.cityid ?? 0);
    const areaId = parseInt(props.areaid ?? 0);
    const typeId = parseInt(props.typeid ?? 0);
    const keyword = (props.keyword ?? '').toString();
    const enableSortDistance = props.enableSortDistance ?? false;

    const defaultLocation = {
        country_id: countryId.toString(),
        state_id: stateId.toString(),
        city_id: cityId.toString(),
        area_id: areaId.toString(),
    }

    const [steps, setSteps] = useState(0);
    const [boolShowProfileResult, setBoolShowProfileResult] = useState(false);
    const [boolShowKeywords, setBoolShowKeywords] = useState(false);
    const [boolShowLoading, setBoolShowLoading] = useState(false);

    const [headerNavigationText, setHeaderNavigationText] = useState(navText);

    const [detailArea, setAreaDetail] = useState({});
    const [detailKeyword, setDetailKeyword] = useState({});

    const [listResulProfiles, setListResulProfiles] = useState([]);
    const [listResultKeywords, setListResultKeywords] = useState([]);


    let profile_count = 0, profileIds = '', list_keywords = [];

    const handleClickGetProfiles = async (detail = {}) => {

        const profileIdsAvailable = (detail.profile_ids ?? '').toString();
        if (Utilities.isEmpty(profileIdsAvailable)) {
            return false;
        }

        setDetailKeyword(detail);

        setBoolShowLoading(true);
        setBoolShowProfileResult(false);
        setBoolShowKeywords(false);

        setSteps(3);


        dataRequest = {
            account_ids: profileIdsAvailable.toString(),
            account_type: 3
        }

        resDb_listResult = await getPofilesByIds(dataRequest);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listResultProfiles = resDb_listResult.list ?? [];
            }
        }

        setListResulProfiles(listResultProfiles);
        setBoolShowLoading(false);
        setBoolShowProfileResult(true);
        setHeaderNavigationText(navText);

    }


    const gettingAllKeywords = async () => {
        setSteps(2);

        setAreaDetail({});
        setDetailKeyword({});

        dataRequest = {
            country_id: countryId.toString(),
            state_id: stateId.toString(),
            city_id: "0",
            area_id: areaId.toString(),
            type: "8",
            keyword: keyword.toString(),
            location_type: 3
        }

        setBoolShowLoading(true);
        resDb_listResult = await getCounterKeywordByLocation(dataRequest);
        setBoolShowLoading(false);
        setBoolShowKeywords(true);


        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                list_keywords = resDb_listResult.list ?? [];
                profile_count = resDb_listResult.profile_count ?? 0;
                profileIds = resDb_listResult.profile_ids ?? '';
            }
        }

        setListResultKeywords(list_keywords);

    }

    const gettingKeywords = async (areaDetail = {}) => {

        setSteps(1);
        setAreaDetail(areaDetail);

        dataRequest = {
            ...defaultLocation,
            area_id: (areaDetail.area_id ?? 0).toString(),
            type: "8",
            keyword: keyword.toString(),
            location_type: 2
        }

        //console.log(dataRequest);
        setBoolShowLoading(true);
        resDb_listResult = await getCounterKeywordByArea(dataRequest);
        setBoolShowLoading(false);
        setBoolShowKeywords(true);


        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                list_keywords = resDb_listResult.list ?? [];
                profile_count = resDb_listResult.profile_count ?? 0;
                profileIds = resDb_listResult.profile_ids ?? '';
            }
        }

        setListResultKeywords(list_keywords);
    }


    const handleClickBack = () => {

        if (steps === 1) {
            setBoolShowKeywords(false);
            setSteps(0);

        } else if (steps === 2) {
            setBoolShowKeywords(false);
            setSteps(0);

        } else if (steps === 3) {
            setBoolShowKeywords(true);
            setBoolShowProfileResult(false);
            setSteps(1);
        }

        setBoolShowLoading(false);
        setBoolShowProfileResult(false);
    }



    if (listArea.length === 0) {
        return <DirectorySearchNoRecord />

    } else {
        return (
            <div className='tab-list-states'>


                {/* -------------------- suburb listing -------------------- */}
                {
                    (!boolShowProfileResult && !boolShowLoading && !boolShowKeywords) &&
                    <ul className="states-listing" >
                        <li onClick={() => gettingAllKeywords()}>
                            <div>
                                <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                <div><span className="counter fs-16 fs-md-14">{profileCount}</span></div>
                            </div>
                        </li>
                        {
                            Object.entries(listArea).map(([key, row]) =>
                                <li key={key} onClick={() => gettingKeywords(row)}>
                                    <DirectorySearchItemTemplate name={row.area_name ?? ''} counter={row.counter ?? '0'} /></li>
                            )
                        }
                    </ul>

                }


                {/* -------------------- words listing -------------------- */}
                {
                    (!boolShowProfileResult && !boolShowLoading && boolShowKeywords) &&
                    <>
                        <DirectorySearchBackNavigation handleClick={handleClickBack} text={detailArea.area_name ?? Utilities.labelShowAll} />
                        {
                            (steps === 1 && Object.keys(detailArea).length > 0) &&
                            <ul className="states-listing" >
                                <li onClick={() => handleClickGetProfiles({ profile_ids: detailArea.profile_ids ?? '' })}>
                                    <div>
                                        <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                        <div><span className="counter fs-16 fs-md-14">{detailArea.counter ?? 0}</span></div>
                                    </div>
                                </li>
                                <li style={{ minHeight: 0, padding: 0 }}></li>
                            </ul>
                        }
                        <ul className="list-keywords" >

                            {
                                Object.entries(listResultKeywords).map(([key, row]) =>
                                    <DirectorySearchKeywordsTemplate highlightKeywordInResults={props.highlightKeywordInResults} detail={row} handleClick={handleClickGetProfiles} />
                                )
                            }
                        </ul>
                    </>

                }


                {/* -------------------- profile listing -------------------- */}
                {
                    (boolShowProfileResult && !boolShowLoading && !boolShowKeywords) &&
                    <>
                        <DirectorySearchBackNavigation handleClick={handleClickBack} text={`${detailKeyword.word ?? Utilities.labelShowAll} ${(detailArea.area_name ?? '') !== '' ? ', ' + detailArea.area_name : ''}`} />
                        <DirectorySearchProfileListing listProfiles={listResulProfiles} locationTabId={2} highlightKeywordInResults={props.highlightKeywordInResults} previewProfileImage={props.previewProfileImage} handleClickProfile={props.handleClickProfile} enableSortDistance={enableSortDistance} />
                    </>
                }


                {/* -------------------- loader -------------------- */}
                {boolShowLoading && <DirectorySearchLoaderProfile />}


            </div>
        )
    }
}


export default DirectorySearchKeywordsSuburbs;