import React, { useEffect, useState, } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jQuery from 'jquery';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { showHeader, showFooter, pageTitle, applyChangeLocation as applyChangeLocationReducer, showLoader as showLoaderReducer, showPopupQuickLinks as showPopupQuickLinksReducer, showMainSettings as showMainSettingsReducer, passImageUrl } from '../actions';
import { getCategoriesByKeyword, getCategoryCompanyCounters, getCountriesProfiles, getMainCounters, getPersonalProfilesByEmail, getProfileByLocation, getStatesByCountry, getSuburbListByCity } from '../helpers/QueryDirectorySearch';

import Utilities from '../helpers/Utilities';
import PagesAuthentication from '../helpers/PagesAuthentication';


import TitlesLabels from '../helpers/TitlesLabels';
import SessionUtilities from '../helpers/SessionUtilities';

import CityAreaSearch from './popups/CityAreaSearch';
import MainSearchProfile from './popups/MainSearchProfile';
import DirectorySearchLoaderProfile from './DirectorySearch/DirectorySearchLoaderProfile';
import DirectorySearchNoRecord from './DirectorySearch/DirectorySearchNoRecord';
import DirectorySearchProfileListing from './DirectorySearch/DirectorySearchProfileListing';
import DirectorySearchProfilesSuburbsByCity from './DirectorySearch/DirectorySearchProfilesSuburbsByCity';
import DirectorySearchProfilesStatesCities from './DirectorySearch/DirectorySearchProfilesStatesCities';
import DirectorySearchProfilesCountries from './DirectorySearch/DirectorySearchProfilesCountries';
import { getCounterKeywordAllCountries, getCounterKeywordAllStates, getCounterKeywordAllSuburb, getCounterKeywordByArea, getCounterKeywordByLocation } from '../helpers/QueryDirectorySearchKeywords';
import UrlSlugs from '../helpers/UrlSlugs';
import DirectoryNewRegisteredInfo from './common/DirectoryNewRegisteredInfo';
import DirectorySearchLoaderCategories from './DirectorySearch/DirectorySearchLoaderCategories';
import DirectorySearchCategories from './DirectorySearch/DirectorySearchCategories';
import DirectorySearchKeywordsListing from './DirectorySearch/DirectorySearchKeywordsListing';
import DirectorySearchKeywordsSuburbs from './DirectorySearch/DirectorySearchKeywordsSuburbs';
import DirectorySearchKeywordsStatesCities from './DirectorySearch/DirectorySearchKeywordsStatesCities';
import DirectorySearchKeywordsCountries from './DirectorySearch/DirectorySearchKeywordsCountries';


const DirectorySearchNew = ({ match }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const defaultLocation = Utilities.defaultLocation();
    const applyChangeLocation = useSelector(state => state.applyChangeLocation);

    let counterLocationDefault = { local: 0, city: 0, national: 0, international: 0 };
    let counterTypeDefault = { company: 0, profile: 0, personal: 0, category: 0, keyword: 0, email: 0 };

    const [boolShowMainResult, setBoolShowMainResult] = useState(false);

    const { transcript, finalTranscript, resetTranscript } = useSpeechRecognition();
    const [counterLocation, setCounterLocation] = useState(counterLocationDefault);
    const [counterProfiles, setCounterProfiles] = useState(counterTypeDefault);

    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [defaultSearchType, setDefaultSearchType] = useState(3); // 3-company,4-work,5-category,6-keywords,7-email,8-profilenames

    const [showLocationTab, setShowLocationTab] = useState(false);
    const [boolShowMainProcessing, setBoolShowMainProcessing] = useState(false);

    const [defaultLocationType, setDefaultLocationType] = useState(1);

    const [searchCategoryId, setSearchCategoryId] = useState(0);
    const [searchCategoryDetail, setSearchCategoryDetail] = useState({});
    const [currentLocation, setCurrentLocation] = useState(defaultLocation);
    const [directorySearchType, setDirectorySearchType] = useState(1);
    const [profileShowType, setProfileShowType] = useState(1);
    const [enableSortDistance, setEnableSortDistance] = useState(false);
    const [listeningStatus, setListeningStatus] = useState(false);

    const [profileLoggedin, setProfileLoggedin] = useState({});
    const [profileSelected, setProfileSelected] = useState({});

    const [globalAudioStatus, setGlobalAudioStatus] = useState(false);
    const [manageTeamCompanyDetail, setManageTeamCompanyDetail] = useState({});

    const [showCityPopup, setShowCityPopup] = useState(false);
    const [showProfilePopup, setShowProfilePopup] = useState(false);


    // RESULT LIST
    const [listResulProfiles, setListResulProfiles] = useState([]);
    const [listResulSuburbsByCityProfiles, setListResulSuburbsByCityProfiles] = useState({});
    const [listResultStatesByCountryProfiles, setListResultStatesByCountryProfiles] = useState({});
    const [listResultCountriesProfiles, setListResultCountriesProfiles] = useState({});


    // RESULT KEYWORDS
    const [listResultKeywords, setListResultKeywords] = useState({});
    const [listResultKeywordsSuburbs, setListResultKeywordsSuburbs] = useState({});
    const [listResultKeywordsStates, setListResultKeywordsStates] = useState({});
    const [listResultKeywordsCountries, setListResultKeywordsCountries] = useState({});


    // RESULT CATEGORIES
    const [listResultCategories, setListResultCategories] = useState([]);



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // MAIN SEARCH PROPER -  3-company,4-work,5-category,6-keywords,7-email,8-profilenames
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const mainSearch = async (requestLocation = currentLocation, searchType = defaultSearchType, categoryDetail = searchCategoryDetail) => {

        if (!Utilities.isEmpty(defaultSearchValue)) {
            if (parseInt(requestLocation.area_id) === 0) {
                // //console.log('// switch to city as default location');
                setDefaultLocationType(2); // switch to city as default location
            }

            let category_search_status = 0;
            if (Object.keys(categoryDetail).length > 0 && parseInt(categoryDetail.group_id ?? 0) === 0) {
                category_search_status = 1;
            }

            setListResulProfiles([]);
            setBoolShowMainResult(false);
            setShowLocationTab(false);
            setBoolShowMainProcessing(true);

            if (category_search_status === 1) {
                searchType = 3;
                await gettingCategoryCompanyCounters(requestLocation, categoryDetail);
            } else {
                await gettingMainCounters(requestLocation, searchType, categoryDetail);
            }


            if ([1, 3, 4, 6].includes(searchType)) {
                await gettingMainResultByLocation(requestLocation, searchType, defaultLocationType, categoryDetail);

            } else if ([5].includes(searchType)) {
                await gettingCategoryListResult();

            } else if ([7].includes(searchType)) {
                await gettingMainResultByLocation(requestLocation, searchType, defaultLocationType, categoryDetail);

            }

            let showLocationTabStatus = true;
            if (searchType === 5) {
                showLocationTabStatus = false;

            } else if (searchType == 7) {
                showLocationTabStatus = false;
            }


            setShowLocationTab(showLocationTabStatus);
            setBoolShowMainResult(true);
            setBoolShowMainProcessing(false);

        } else {
            setBoolShowMainResult(false);
            setBoolShowMainProcessing(false);

        }

    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // GETTING CATEGORY COMPANY PROFILES
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const gettingCategoryCompanyCounters = async (requestLocation = currentLocation, categoryDetail = searchCategoryDetail) => {
        let dataRequest = {},
            resDb_listResult = {},
            categoryId = parseInt(categoryDetail.group_id ?? 0),
            directorySearchKeyword = defaultSearchValue;

        if (Object.keys(categoryDetail).length > 0 && categoryId === 0) {
            directorySearchKeyword = categoryDetail.cate_name ?? defaultSearchValue;
        }

        dataRequest = {
            country_id: (requestLocation.country_id ?? '0').toString(),
            state_id: (requestLocation.state_id ?? '0').toString(),
            city_id: (requestLocation.city_id ?? '0').toString(),
            area_id: (requestLocation.area_id ?? '0').toString(),
            category_id: "0",
            keyword: directorySearchKeyword.toString(),
        }

        resDb_listResult = await getCategoryCompanyCounters(dataRequest)
        ////console.log(resDb_listResult);

        if (parseInt(resDb_listResult.status ?? 0) === 1) {
            setCounterLocation({
                ...counterLocationDefault,
                local: resDb_listResult.counter_location.area ?? 0,
                city: resDb_listResult.counter_location.city ?? 0,
                national: resDb_listResult.counter_location.state ?? 0,
                international: resDb_listResult.counter_location.country ?? 0,
            });
        }


    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // GETTING MAIN RESULTS
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const gettingMainResultByLocation = async (requestLocation = currentLocation, searchType = defaultSearchType, locationType = defaultLocationType, categoryDetail = searchCategoryDetail) => {

        const areaId = parseInt(requestLocation.area_id ?? 0);
        let resDb_listResult = {},
            profile_count = 0,
            dataRequest = {},
            profileIds = "",
            listResultProfiles = [],
            listResulAreasByCityProfiles = [],
            listResulStateByCountryProfiles = [],
            listResulCountriesProfiles = [],
            listResultKeywords = [],
            listResultKeywordsAllSuburbs = [],
            category_search_status = 0,
            categoryId = parseInt(categoryDetail.group_id ?? 0),
            directorySearchKeyword = defaultSearchValue;


        if (Utilities.isEmpty(defaultSearchValue)) { return false; }

        if (Object.keys(categoryDetail).length > 0 && categoryId === 0) {
            category_search_status = 1;
            directorySearchKeyword = categoryDetail.cate_name ?? defaultSearchValue;
        }

        if ([1, 3, 4].includes(searchType)) {

            dataRequest = {
                country_id: (requestLocation.country_id ?? '0').toString(),
                state_id: (requestLocation.state_id ?? '0').toString(),
                city_id: (requestLocation.city_id ?? '0').toString(),
                area_id: (requestLocation.area_id ?? '0').toString(),
                type: searchType.toString(),
                category_id: categoryId.toString(),
                keyword: directorySearchKeyword.toString(),
                category_search: category_search_status.toString()
            }

            //console.log(dataRequest);


            // SUBURB or AREA LISTING
            if (locationType === 1) {
                //console.log(`main search: profile list suburb`);

                resDb_listResult = await getProfileByLocation(dataRequest);
                if (Object.keys(resDb_listResult).length > 0) {
                    if (parseInt(resDb_listResult.count ?? 0) > 0) {
                        listResultProfiles = resDb_listResult.list ?? [];
                    }
                }

                setListResulProfiles(listResultProfiles);


            } else if (locationType === 2) {

                // CHEKCING IF SUBURB/AREA has value

                // profile listing
                if (areaId === 0) {
                    //console.log(`main search: profile list cities`);
                    dataRequest = { ...dataRequest, area_id: "0" }
                    //console.log(dataRequest);
                    resDb_listResult = await getProfileByLocation(dataRequest);
                    if (Object.keys(resDb_listResult).length > 0) {
                        if (parseInt(resDb_listResult.count ?? 0) > 0) {
                            listResultProfiles = resDb_listResult.list ?? [];
                        }
                    }

                    setListResulProfiles(listResultProfiles);

                    // suburbs listing
                } else {
                    //console.log(`main search: suburb list`);
                    ////console.log(dataRequest);
                    resDb_listResult = await getSuburbListByCity(dataRequest);
                    if (Object.keys(resDb_listResult).length > 0) {
                        if (parseInt(resDb_listResult.count ?? 0) > 0) {
                            listResulAreasByCityProfiles = resDb_listResult.list ?? [];
                            profile_count = resDb_listResult.profile_count ?? 0;
                            profileIds = resDb_listResult.profile_ids ?? '';
                        }
                    }

                    setListResulSuburbsByCityProfiles({ area_list: listResulAreasByCityProfiles, profile_count: profile_count, profile_ids: profileIds });
                }


            } else if (locationType === 3) {
                //console.log(`main search: state list`);
                ////console.log(dataRequest);
                resDb_listResult = await getStatesByCountry(dataRequest);
                if (Object.keys(resDb_listResult).length > 0) {
                    if (parseInt(resDb_listResult.count ?? 0) > 0) {
                        listResulStateByCountryProfiles = resDb_listResult.list ?? [];
                        profile_count = resDb_listResult.profile_count ?? 0;
                        profileIds = resDb_listResult.profile_ids ?? '';
                    }
                }

                setListResultStatesByCountryProfiles({ state_list: listResulStateByCountryProfiles, profile_count: profile_count, profile_ids: profileIds });

            } else if (locationType === 4) {
                //console.log(`main search: internation list`);
                resDb_listResult = await getCountriesProfiles(dataRequest);
                if (Object.keys(resDb_listResult).length > 0) {
                    if (parseInt(resDb_listResult.count ?? 0) > 0) {
                        listResulCountriesProfiles = resDb_listResult.list ?? [];
                        profile_count = resDb_listResult.profile_count ?? 0;
                        profileIds = resDb_listResult.profile_ids ?? '';
                    }
                }

                setListResultCountriesProfiles({ country_list: listResulCountriesProfiles, profile_count: profile_count, profile_ids: profileIds });
            }

            //}else if ( [5].includes(searchType) ){


        } else if ([6].includes(searchType)) {


            dataRequest = {
                country_id: (requestLocation.country_id ?? '0').toString(),
                state_id: (requestLocation.state_id ?? '0').toString(),
                city_id: (requestLocation.city_id ?? '0').toString(),
                area_id: (requestLocation.area_id ?? '0').toString(),
                type: "8",
                keyword: defaultSearchValue.toString(),
            }



            // SUBURB or AREA LISTING
            if (locationType === 1) {
                //console.log(`main search: keyword list suburb`);
                resDb_listResult = await getCounterKeywordByArea(dataRequest);
                if (Object.keys(resDb_listResult).length > 0) {
                    if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                        listResultKeywords = resDb_listResult.list ?? [];
                        profile_count = resDb_listResult.profile_count ?? 0;
                        profileIds = resDb_listResult.profile_ids ?? '';
                    }
                }

                setListResultKeywords({ list_words: listResultKeywords, profile_count: profile_count, profile_ids: profileIds });

            } else if (locationType === 2) {

                // CHEKCING IF SUBURB/AREA has value

                // profile listing
                if (areaId === 0) {
                    //console.log(`main search: keyword list cities`);

                    dataRequest = { ...dataRequest, location_type: "2" }

                    resDb_listResult = await getCounterKeywordByLocation(dataRequest);
                    if (Object.keys(resDb_listResult).length > 0) {
                        if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                            listResultKeywords = resDb_listResult.list ?? [];
                            profile_count = resDb_listResult.profile_count ?? 0;
                            profileIds = resDb_listResult.profile_ids ?? '';
                        }
                    }

                    setListResultKeywords({ list_words: listResultKeywords, profile_count: profile_count, profile_ids: profileIds });


                    // suburbs listing
                } else {
                    //console.log(`main search: suburb list`);
                    dataRequest = { ...dataRequest, location_type: "2" };
                    ////console.log(dataRequest);
                    resDb_listResult = await getCounterKeywordAllSuburb(dataRequest);
                    if (Object.keys(resDb_listResult).length > 0) {
                        if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                            listResultKeywordsAllSuburbs = resDb_listResult.list ?? [];
                            profile_count = resDb_listResult.profile_count ?? 0;
                            profileIds = resDb_listResult.profile_ids ?? '';
                        }
                    }

                    setListResultKeywordsSuburbs({ list_area: listResultKeywordsAllSuburbs, profile_count: profile_count, profile_ids: profileIds });
                }


            } else if (locationType === 3) {

                dataRequest = { ...dataRequest, location_type: "3" }
                resDb_listResult = await getCounterKeywordAllStates(dataRequest);
                if (Object.keys(resDb_listResult).length > 0) {
                    if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                        listResultKeywords = resDb_listResult.list ?? [];
                        profile_count = resDb_listResult.profile_count ?? 0;
                        profileIds = resDb_listResult.profile_ids ?? '';
                    }
                }

                setListResultKeywordsStates({ list_states: listResultKeywords, profile_count: profile_count, profile_ids: profileIds });

            } else if (locationType === 4) {
                //console.log(`main search: internation list`);

                let all_keywords = [];
                resDb_listResult = await getCounterKeywordAllCountries(dataRequest);
                if (Object.keys(resDb_listResult).length > 0) {
                    if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                        listResultKeywords = resDb_listResult.list ?? [];
                        profile_count = resDb_listResult.profile_count ?? 0;
                        profileIds = resDb_listResult.profile_ids ?? '';
                        all_keywords = resDb_listResult.all_keywords ?? [];
                    }
                }

                setListResultKeywordsCountries({ country_list: listResultKeywords, profile_count: profile_count, profile_ids: profileIds, all_keywords: all_keywords });
            }


        } else if ([7].includes(searchType)) {

            dataRequest = { keyword: defaultSearchValue.toString() }
            resDb_listResult = await getPersonalProfilesByEmail(dataRequest);
            if (Object.keys(resDb_listResult).length > 0) {
                if (parseInt(resDb_listResult.profile_count ?? 0) > 0) {
                    listResultProfiles = resDb_listResult.list ?? [];
                    profile_count = resDb_listResult.profile_count ?? 0;
                    profileIds = resDb_listResult.profile_ids ?? '';
                }
            }

            setListResulProfiles(listResultProfiles);

        }

    }




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HANDLE - CLICK WHEN CATEGORY SELECTED
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleClickCategoryDetail = async (detail = {}) => {
        if (Object.keys(detail).length === 0) { return false; }
        const categoryId = parseInt(detail.group_id ?? 0);

        setSearchCategoryDetail(detail);
        setSearchCategoryId(categoryId);


        // SWITCH TO COMPANY TAB
        setDefaultSearchType(3);
        await mainSearch(currentLocation, 3, detail);

    }






    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // GETTING MAIN COUNTERS
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const gettingMainCounters = async (requestLocation = currentLocation, searchType = defaultSearchType, categoryDetail = searchCategoryDetail) => {

        if (Utilities.isEmpty(defaultSearchValue)) { return false; }
        const categoryId = parseInt(categoryDetail.group_id ?? 0);

        const dataRequest = {
            country_id: (requestLocation.country_id ?? '0').toString(),
            state_id: (requestLocation.state_id ?? '0').toString(),
            city_id: (requestLocation.city_id ?? '0').toString(),
            area_id: (requestLocation.area_id ?? '0').toString(),
            type: searchType.toString(),
            location_type: defaultLocationType.toString(),
            keyword: defaultSearchValue.toString(),
            category_id: categoryId.toString(),
            counter_status: "1",
            counter_location_status: "1"
        }
        ////console.log('counters param:');
        ////console.log(dataRequest);

        const res_mainCounters = await getMainCounters(dataRequest);
        ////console.log(res_mainCounters);
        if (parseInt(res_mainCounters.status ?? 0) === 1) {

            setCounterProfiles({
                ...counterLocationDefault,
                company: res_mainCounters.counter_type.company ?? 0,
                profile: res_mainCounters.counter_type.profile ?? 0,
                personal: res_mainCounters.counter_type.personal ?? 0,
                category: res_mainCounters.counter_type.category ?? 0,
                keyword: res_mainCounters.counter_type.keyword ?? 0,
                email: res_mainCounters.counter_type.email ?? 0,
            });

            setCounterLocation({
                ...counterLocationDefault,
                local: res_mainCounters.counter_location.area ?? 0,
                city: res_mainCounters.counter_location.city ?? 0,
                national: res_mainCounters.counter_location.state ?? 0,
                international: res_mainCounters.counter_location.country ?? 0,
            })
        }

    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // GETTING CATEGORIES BY KEYWORDS
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const gettingCategoryListResult = async () => {

        if (Utilities.isEmpty(defaultSearchValue)) { return false; }
        let dataRequest = { keywords: defaultSearchValue.toString() },
            resDb_listResult = {};

        resDb_listResult = await getCategoriesByKeyword(dataRequest);
        if (Object.keys(resDb_listResult).length > 0) {
            if (Object.keys(resDb_listResult.list ?? []).length > 0) {
                setListResultCategories(resDb_listResult.list);
            }

        }

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // CHANGE LOCATION
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleCloseCityPopUp = () => setShowCityPopup(false);
    const handleClickChangeLocation = () => setShowCityPopup(true);

    const handleClickCitySelected = async (cityDetail = {}) => {

        handleCloseCityPopUp();
        //setSearchCategoryId(0);
        //setSearchCategoryDetail({});

        if (Object.entries(cityDetail).length > 0) {

            let defaultLocationTemp = defaultLocation;

            let userLocation = SessionUtilities.getUserLocation();
            if (Object.keys(userLocation).length > 0) {
                defaultLocationTemp = userLocation;
            }

            defaultLocationTemp = {
                ...defaultLocationTemp,
                country_name: cityDetail.country_name,
                state_name: cityDetail.state_name,
                city_name: cityDetail.city_name,
                area_name: cityDetail.area_name ?? "",

                country_id: cityDetail.country_id,
                state_id: cityDetail.state_id,
                city_id: cityDetail.city_id,
                area_id: cityDetail.area_id ?? "0",
            };

            //console.log(`change location: ${defaultLocationTemp}`);
            SessionUtilities.setDirectoryLocation(defaultLocationTemp);
            setCurrentLocation(defaultLocationTemp);
            setListResultCategories([]);

            await mainSearch(defaultLocationTemp);

        }
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HANDLE CLICK - SORT BY DISTANCE
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleClickSortDistance = () => {
        if (Utilities.isEmpty(defaultSearchValue)) {
            return false;
        }

        const status = !enableSortDistance;
        setEnableSortDistance(status);
        //console.log('distance sorting...' + status);
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HANDLE CLICK - REDIRECT SEARCH CONTACT
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleClickRedirectToContactSearch = () => {
        history.push(`/${UrlSlugs.page_contactSearch}/${btoa(profileShowType)}`);
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HANDLE KEY-UP SEARCH INPUT
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
        }
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // RESET SEARCH VALUES
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleClickResetSearchValue = (type = 3) => {

        setDefaultSearchValue('');
        setListeningStatus(false);
        setSearchCategoryId(0);
        setSearchCategoryDetail({});

        resetTranscript();

        if (jQuery('#input_keyword').length > 0) {
            document.getElementById('input_keyword').focus();
        }
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // CHANGING SEARCH TYPE : 3-company,4-work,5-category,6-keywords,7-email,8-profilenames
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleClickChangeSearchType = async (type = 3) => {
        setDefaultSearchType(type);
        setSearchCategoryId(0);
        setSearchCategoryDetail({});
        await mainSearch(currentLocation, type);
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // CHANGING SEARCH LOCATION : 1-local,2-cities,3-national/state,4-international
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleClickChangeSwitchLocationType = async (type = 1) => {

        setDefaultLocationType(type);
        setBoolShowMainResult(false);

        setShowLocationTab([5, 7].includes(type) ? false : true);

        if ([1, 3, 4, 6].includes(defaultSearchType)) {
            setBoolShowMainProcessing(true);

            await gettingMainResultByLocation(currentLocation, defaultSearchType, type);

            setBoolShowMainResult(true);
            setBoolShowMainProcessing(false);

        }

    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // OBSERVE HANDLE MICROPHONE
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleClickMicrophone = async () => {
        if (globalAudioStatus === true) {
            handleClickResetSearchValue();
            setListeningStatus(true);

            SpeechRecognition.startListening();
        } else {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.microphoneIsNotDetected);
        }

    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // INPUT SEARCH HTML
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const searchContentKeywordHtml = () => {
        return <ul className="keyword-option form-theme bg-white w-100 border-radius">
            <li>
                <div className='form-group mb-0 border-0  w-100'>
                    <input type="text" id="input_keyword" className="form-input input-keyword bg-white border-radius w-100" placeholder="search" value={defaultSearchValue} autoComplete="off"
                        onChange={(e) => { pushValue(e, 'search-keyword'); }} autoFocus ></input>
                </div>
            </li>

            <li><button id="btn-main-search-mic" type="button" className={`btn-icon btn-mic ${(listeningStatus === true) ? 'active' : ''}`} onClick={() => handleClickMicrophone()}></button></li>
            <li><button type="button" className="btn-icon btn-clear-input" title="clear" onClick={() => handleClickResetSearchValue()} ><span className="ion-ios7-close-empty"></span></button>  </li>

        </ul>
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // LCOADER PROFILE HTML
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const loaderProfileHtml = () => {
        return <DirectorySearchLoaderProfile classAttr={defaultSearchType === 5 ? 'mt-5' : ''} />
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HIGLIHTED SEARCH HTML
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const highlightKeywordInResults = (name = '') => {
        const keyword = jQuery('#input_keyword').val().toLowerCase();
        return Utilities.isEmpty(keyword) ? name : name.toLowerCase().replace(keyword, `<span class="keyword-string">${keyword}</span>`);
    }




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HANDLE - SHOW PROFILE POPUP
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleClickProfile = async (profile) => {
        setProfileSelected(profile);
        setShowProfilePopup(true);
    }

    const handleCloseProfilePopup = () => {
        setProfileSelected({});
        setShowProfilePopup(false);
    }

    const closeSearchBox = (totallyClose = false) => {
        setEnableSortDistance(false);
        setShowProfilePopup(false);
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HANDLE - BACK FROM CATEGORY
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleClickCategoryBack = () => {
        setShowLocationTab(false);
        setDefaultSearchType(5);
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // NO RESULT HTML
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const noRecordFoundHtmlTyping = () => {
        let keyword = defaultSearchValue;
        if (defaultSearchValue.length === 1 && keyword.length >= 3) {
            return <DirectorySearchNoRecord iconClass={``} text={Utilities.labelNoFindResults} />
        }

        if (keyword.length > 1 && keyword.length < 3) {
            return <DirectorySearchNoRecord iconClass={`warning-icon`} text={Utilities.labelKeywordThreeCharacters} />
        }

        return <></>
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // OBSERVE SLUG PAGES
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        let matchShowStatus = match.params.type ?? btoa(1);
        matchShowStatus = parseInt(atob(matchShowStatus));

        setProfileShowType(matchShowStatus);

        if (parseInt(matchShowStatus) > 0) {


            let searchTypeStatus = 1;
            let segment = Utilities.getUrlSegments();
            let urlSlug = segment.shift();

            urlSlug = typeof urlSlug !== 'undefined' ? urlSlug.toString().toLowerCase() : '';

            if (urlSlug === 'dashboard') {
                PagesAuthentication.updateDirectorySearch(parseInt(matchShowStatus));
                searchTypeStatus = matchShowStatus;

            } else if (urlSlug === 'settings') {
                searchTypeStatus = 2;
            } else {
                searchTypeStatus = PagesAuthentication.getDirectorySearchType() ?? matchShowStatus;
            }

            //console.log(`DirectorySearchType: ${searchTypeStatus}`);
            setDirectorySearchType(searchTypeStatus);

            if (searchTypeStatus === 2) {
                setDefaultSearchType(7);
            } else if (searchTypeStatus === 4) {
                setDefaultSearchType(3);
            }

            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    setGlobalAudioStatus(true);

                }).catch(err => {
                    setGlobalAudioStatus(false);
                });

        }

        // LOGGEDIN ACCOUNTS
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginProfileInfo !== null) {
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if (Object.keys(loginProfileInfo).length > 0) {
                const profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setProfileLoggedin(profileLoggedinTemp);
            }
        }

    }, [match.params.type]);




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // INITIAL
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        dispatch(pageTitle(TitlesLabels.siteTitles.directorySearch));

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);
        sesstionLoginType = parseInt(sesstionLoginType);

        dispatch(showHeader(sesstionLoginType === 2 ? 5 : 1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();


        let userLocation = SessionUtilities.getUserLocation() ?? {};
        if (Object.keys(userLocation).length > 0) {
            setCurrentLocation(userLocation);
            SessionUtilities.setDirectoryLocation(userLocation);
        }


        let manageTeamCompanyDetail = sessionStorage.getItem(process.env.REACT_APP_session_manage_team_current_company_detail) ?? {};
        if (Object.keys(manageTeamCompanyDetail).length > 0) {
            manageTeamCompanyDetail = JSON.parse(manageTeamCompanyDetail);
        }

        setManageTeamCompanyDetail(manageTeamCompanyDetail);


        return () => {
            setDefaultSearchValue('');
            setDefaultSearchType(3);
            setDefaultLocationType(1);
            setListeningStatus(false);
            setShowLocationTab(false);
            setEnableSortDistance(false);
            setBoolShowMainProcessing(false);
            setGlobalAudioStatus(false);
            setBoolShowMainResult(false);
            setCurrentLocation(defaultLocation);
            setCounterLocation(counterLocationDefault);
            setCounterProfiles(counterTypeDefault);
            setSearchCategoryId(0);
            setSearchCategoryDetail({});

            setListResulProfiles([]);
            setListResultCategories([]);

            resetTranscript();
        }

    }, []);



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // OBSERVE KEYUP INPUT SEARCH
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        let delayDebounceFn = '';
        //setSearchCategoryId(0);
        //setSearchCategoryDetail({});
        setListResultCategories([]);

        if (!Utilities.isEmpty(defaultSearchValue) && defaultSearchValue.length >= 3) {
            delayDebounceFn = setTimeout(() => {
                mainSearch();
            }, 1000);

        } else {

            if (defaultSearchValue.length < 3) {
                setShowLocationTab(false);
                setCounterLocation(counterLocationDefault);
                setCounterProfiles(counterTypeDefault);
                setBoolShowMainResult(false);
            }
        }

        return () => {
            clearTimeout(delayDebounceFn);
            setBoolShowMainProcessing(false);
            setBoolShowMainResult(false);
        };

    }, [defaultSearchValue]);




    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // OBSERVE - Preview Logo or Images
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const previewProfileImage = (logoUrl) => {
        if (!Utilities.isEmpty(logoUrl)) {
            dispatch(passImageUrl(logoUrl));
        }
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // OBSERVE CITY/AREA CHANGE
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        let applyChangeLocationTemp = applyChangeLocation;
        if (!Utilities.isEmpty(applyChangeLocationTemp)) {

            let userLocation = SessionUtilities.getUserLocation() ?? {};
            if (Object.keys(userLocation).length > 0) {
                setCurrentLocation(userLocation);
                SessionUtilities.setDirectoryLocation(userLocation);
            }
            dispatch(applyChangeLocationReducer(''));
        }

    }, [applyChangeLocation]);



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // OBSERVE MICROPHONE
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        setDefaultSearchValue(transcript);
        if (finalTranscript !== '') {
            setListeningStatus(false);
        }

    }, [transcript, finalTranscript]);



    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
        //Utilities.messagePopup('Error', 'Speech Recognition is not supported by your browwer!');
        jQuery('#btn-main-search-mic').addClass('d-none');
        //return null;
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HTML SECTION
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <div className='directory-location-option-wrap d-flex justify-content-center text-lowercase'>
                <div className="cursor-pointer position-fixed zindex-11 text-white fs-20 fs-md-22" onClick={() => handleClickChangeLocation()}>
                    <img src={`${process.env.REACT_APP_img_folder}/icons/white/outline-map-pin.svg`} width={25} className="mr-2" />
                    {currentLocation.area_name ? currentLocation.area_name : currentLocation.city_name}
                </div>
            </div>

            <div className={`main-search-component  sticky-navigation`}>
                <div className={`search-header with-navigation ${defaultSearchType === 1 ? 'zindex-11-important' : ''}`}>


                    {/* ------------- if team member search profile or not ------------- */}
                    <div className="text-lowercase ">
                        {
                            [2, 4].includes(directorySearchType) ?
                                <div className="breadcrumbs">
                                    <div className="container breadcrumbs-content">
                                        <ul className="breadcrumbs-nav">
                                            <li>
                                                <div className='cursor-pointer' onClick={() => dispatch(showMainSettingsReducer(new Date()))}>{TitlesLabels.settings}</div>
                                                <div>{TitlesLabels.general.profiles}</div>
                                                {manageTeamCompanyDetail.hasOwnProperty('company_detail') && <div>{manageTeamCompanyDetail.company_detail.name ?? ''}</div>}
                                                <div className="current">
                                                    {directorySearchType === 2 ? TitlesLabels.navigations.teamMembers : TitlesLabels.navigations.add}
                                                </div>
                                            </li>
                                            <li className="btn-option">
                                                <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                                                <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))}></div>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                                :
                                <div className='breadcrumbs border-0'>
                                    <div className='container'>

                                        <div className='directory-search-btn-option d-flex justify-content-between'>
                                            {searchContentKeywordHtml()}

                                            <div className="btn-option pl-3">
                                                <div className={`btn-icon btn-nearby ${enableSortDistance === false ? 'opacity-05' : ''} `} onClick={() => handleClickSortDistance()} title="nearby location"></div>
                                                <div className="btn-icon btn-contact" title="directory search" onClick={() => handleClickRedirectToContactSearch()}></div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                        }


                        <div className='search-header-row-2'>
                            <div className='container px-5'>
                                {
                                    [2, 4].includes(directorySearchType) ?
                                        searchContentKeywordHtml()
                                        :
                                        <ul className="profile-type-option fs-18">
                                            <li className={(defaultSearchType === 3 && Object.keys(searchCategoryDetail).length === 0 ? 'active' : '')} onClick={() => handleClickChangeSearchType(3)}>
                                                Company {(parseInt(counterProfiles.company) > 0) && <span></span>}</li>
                                            <li className={(defaultSearchType === 4 ? 'active' : '')} onClick={() => handleClickChangeSearchType(4)}>
                                                Profiles {(parseInt(counterProfiles.profile) > 0) && <span></span>}</li>

                                            {
                                                directorySearchType === 3 &&
                                                <>
                                                    <li className={(defaultSearchType === 5 || Object.keys(searchCategoryDetail).length > 0 ? 'active' : '')} onClick={() => handleClickChangeSearchType(5)}>
                                                        Category {(parseInt(counterProfiles.category) > 0) && <span></span>}</li>
                                                    <li className={(defaultSearchType === 6 ? 'active' : '')} onClick={() => handleClickChangeSearchType(6)}>
                                                        Keywords {(parseInt(counterProfiles.keyword) > 0) && <span></span>}</li>
                                                </>
                                            }
                                        </ul>
                                }
                            </div>

                        </div>

                    </div>

                    {/* ------------- if team member search profile or not ------------- */}


                    {
                        // if not category tab selected
                        (![5].includes(parseInt(defaultSearchType)) && !Utilities.isEmpty(defaultSearchValue)) &&

                        <div className={`search-header-row-3`}>
                            <div className='container text-lowercase'>
                                {
                                    [2].includes(directorySearchType) &&
                                    <ul className="profile-type-option color-real-black fs-18  position-relative zindex-1">
                                        <li className={(defaultSearchType === 7 ? 'active' : 'text-black-important')} onClick={() => handleClickChangeSearchType(7)}>
                                            Email {(parseInt(counterProfiles.email) > 0) && <span></span>}</li>
                                        <li className={(defaultSearchType === 1 ? 'active' : 'text-black-important')} onClick={() => handleClickChangeSearchType(1)}>
                                            Profiles {(parseInt(counterProfiles.personal) > 0) && <span></span>}</li>
                                    </ul>
                                }
                            </div>
                        </div>
                    }



                </div>

                {
                    Utilities.isEmpty(defaultSearchValue) &&
                    <div className='search-result-content'>
                        <div className='container d-flex   justify-content-center' style={{ paddingTop: `10%` }}>
                            <DirectoryNewRegisteredInfo cityId={currentLocation.city_id ?? 0} areaId={currentLocation.area_id ?? 0} />
                        </div>
                    </div>
                }


                <div className={`search-result-content`}>

                    {/* ------------- location tab ----------------------- */}
                    {
                        showLocationTab &&
                        <div className={`${directorySearchType === 2 ? 'mt-3' : 'position-fixed location-option-tab'} ${defaultSearchType === 5 ? 'bg-white' : ''} zindex-1 w-100 `}>
                            <div className="container">
                                <ul className={`result-option ${parseInt(currentLocation.area_id ?? 0) > 0 ? 'city-result-option' : ''} position-relative fs-18 text-lowercase ${directorySearchType === 2 ? 'mb-0' : ''} ${defaultSearchType === 5 ? 'category-view' : ''} `}>

                                    {
                                        parseInt(currentLocation.area_id ?? 0) > 0 &&
                                        <li className={(defaultLocationType === 1 ? 'active' : '')} onClick={() => handleClickChangeSwitchLocationType(1)}>
                                            Local {(counterLocation.local > 0) && <><span>({counterLocation.local})</span><span className='counter-indicator'></span></>}</li>
                                    }

                                    <li className={(defaultLocationType === 2 ? 'active' : '')} onClick={() => handleClickChangeSwitchLocationType(2)}>
                                        {parseInt(currentLocation.area_id ?? 0) > 0 ? 'City' : 'Local'}
                                        {(counterLocation.city > 0) && <><span>({counterLocation.city})</span><span className='counter-indicator'></span></>}</li>

                                    <li className={(defaultLocationType === 3 ? 'active' : '')} onClick={() => handleClickChangeSwitchLocationType(3)}>
                                        National {(counterLocation.national > 0) && <><span>({counterLocation.national})</span><span className='counter-indicator'></span></>}</li>

                                    <li className={(defaultLocationType === 4 ? 'active' : '')} onClick={() => handleClickChangeSwitchLocationType(4)}>
                                        International {(counterLocation.international > 0) && <><span>({counterLocation.international})</span><span className='counter-indicator'></span></>}</li>
                                </ul>


                            </div>
                        </div>
                    }
                    {/* ------------- location tab ----------------------- */}


                    {boolShowMainProcessing && ([5].includes(defaultSearchType) ? <DirectorySearchLoaderCategories /> : loaderProfileHtml())}

                    {!boolShowMainProcessing && noRecordFoundHtmlTyping()}


                    <div className={`search-result-list fs-18 fs-md-16`}>
                        <section className="container">


                            {/* ------------- company and works profiles ----------------------- */}
                            {
                                (!boolShowMainProcessing && boolShowMainResult && (!Utilities.isEmpty(defaultSearchValue) && defaultSearchValue.length >= 3)) &&
                                <>
                                    {
                                        [1, 3, 4].includes(defaultSearchType) &&
                                        <>
                                            {
                                                [1].includes(defaultLocationType) ?
                                                    <DirectorySearchProfileListing
                                                        listProfiles={listResulProfiles}
                                                        locationTabId={defaultLocationType}
                                                        searchCategoryId={searchCategoryId}
                                                        searchCategoryDetail={searchCategoryDetail}
                                                        enableSortDistance={enableSortDistance}
                                                        highlightKeywordInResults={highlightKeywordInResults}
                                                        previewProfileImage={previewProfileImage}
                                                        handleClickProfile={handleClickProfile}
                                                        handleClickCategoryBack={handleClickCategoryBack}
                                                    />
                                                    :
                                                    [2].includes(defaultLocationType) ?
                                                        (parseInt(currentLocation.area_id ?? 0) > 0 ?
                                                            <DirectorySearchProfilesSuburbsByCity
                                                                countryid={currentLocation.country_id ?? 0}
                                                                stateid={currentLocation.state_id ?? 0}
                                                                cityid={currentLocation.city_id ?? 0}
                                                                areaid={currentLocation.area_id ?? 0}
                                                                typeid={defaultSearchType}
                                                                keyword={defaultSearchValue}
                                                                listResult={listResulSuburbsByCityProfiles}
                                                                searchCategoryId={searchCategoryId}
                                                                searchCategoryDetail={searchCategoryDetail}
                                                                enableSortDistance={enableSortDistance}
                                                                highlightKeywordInResults={highlightKeywordInResults}
                                                                previewProfileImage={previewProfileImage}
                                                                handleClickProfile={handleClickProfile}
                                                                handleClickCategoryBack={handleClickCategoryBack}
                                                            />
                                                            :
                                                            <DirectorySearchProfileListing
                                                                listProfiles={listResulProfiles}
                                                                locationTabId={defaultLocationType}
                                                                searchCategoryId={searchCategoryId}
                                                                searchCategoryDetail={searchCategoryDetail}
                                                                enableSortDistance={enableSortDistance}
                                                                highlightKeywordInResults={highlightKeywordInResults}
                                                                previewProfileImage={previewProfileImage}
                                                                handleClickProfile={handleClickProfile}
                                                                handleClickCategoryBack={handleClickCategoryBack}
                                                            />
                                                        )
                                                        :
                                                        [3].includes(defaultLocationType) ?
                                                            <DirectorySearchProfilesStatesCities
                                                                countryid={currentLocation.country_id ?? 0}
                                                                stateid={currentLocation.state_id ?? 0}
                                                                cityid={currentLocation.city_id ?? 0}
                                                                areaid={currentLocation.area_id ?? 0}
                                                                typeid={defaultSearchType}
                                                                keyword={defaultSearchValue}
                                                                listResult={listResultStatesByCountryProfiles}
                                                                searchCategoryId={searchCategoryId}
                                                                searchCategoryDetail={searchCategoryDetail}
                                                                enableSortDistance={enableSortDistance}
                                                                highlightKeywordInResults={highlightKeywordInResults}
                                                                previewProfileImage={previewProfileImage}
                                                                handleClickProfile={handleClickProfile}
                                                                handleClickCategoryBack={handleClickCategoryBack}
                                                            />
                                                            :
                                                            [4].includes(defaultLocationType) ?
                                                                <DirectorySearchProfilesCountries
                                                                    countryid={currentLocation.country_id ?? 0}
                                                                    stateid={currentLocation.state_id ?? 0}
                                                                    cityid={currentLocation.city_id ?? 0}
                                                                    areaid={currentLocation.area_id ?? 0}
                                                                    typeid={defaultSearchType}
                                                                    keyword={defaultSearchValue}
                                                                    listResult={listResultCountriesProfiles}
                                                                    searchCategoryId={searchCategoryId}
                                                                    searchCategoryDetail={searchCategoryDetail}
                                                                    enableSortDistance={enableSortDistance}
                                                                    highlightKeywordInResults={highlightKeywordInResults}
                                                                    previewProfileImage={previewProfileImage}
                                                                    handleClickProfile={handleClickProfile}
                                                                    handleClickCategoryBack={handleClickCategoryBack}
                                                                />
                                                                :
                                                                <></>
                                            }
                                        </>
                                    }

                                    {
                                        [5].includes(defaultSearchType) &&
                                        <DirectorySearchCategories
                                            countryid={currentLocation.country_id ?? 0}
                                            stateid={currentLocation.state_id ?? 0}
                                            cityid={currentLocation.city_id ?? 0}
                                            areaid={currentLocation.area_id ?? 0}
                                            typeid={defaultSearchType}
                                            searchCategoryId={searchCategoryId}
                                            keyword={defaultSearchValue}
                                            categoryList={listResultCategories}
                                            enableSortDistance={enableSortDistance}
                                            previewProfileImage={previewProfileImage}
                                            handleClickProfile={handleClickProfile}
                                            handleClickCategoryDetail={handleClickCategoryDetail}
                                        />
                                    }

                                    {
                                        [6].includes(defaultSearchType) &&
                                        <>
                                            {
                                                [1].includes(defaultLocationType) ?
                                                    <DirectorySearchKeywordsListing
                                                        listResults={listResultKeywords}
                                                        locationTabId={defaultLocationType}
                                                        enableSortDistance={enableSortDistance}
                                                        highlightKeywordInResults={highlightKeywordInResults}
                                                        previewProfileImage={previewProfileImage}
                                                        handleClickProfile={handleClickProfile}
                                                    />
                                                    :
                                                    [2].includes(defaultLocationType) ?
                                                        (parseInt(currentLocation.area_id ?? 0) > 0 ?
                                                            <DirectorySearchKeywordsSuburbs
                                                                countryid={currentLocation.country_id ?? 0}
                                                                stateid={currentLocation.state_id ?? 0}
                                                                cityid={currentLocation.city_id ?? 0}
                                                                areaid={currentLocation.area_id ?? 0}
                                                                typeid={defaultSearchType}
                                                                keyword={defaultSearchValue}
                                                                listResult={listResultKeywordsSuburbs}
                                                                enableSortDistance={enableSortDistance}
                                                                highlightKeywordInResults={highlightKeywordInResults}
                                                                previewProfileImage={previewProfileImage}
                                                                handleClickProfile={handleClickProfile}
                                                            />
                                                            :
                                                            <DirectorySearchKeywordsListing
                                                                listResults={listResultKeywords}
                                                                locationTabId={defaultLocationType}
                                                                enableSortDistance={enableSortDistance}
                                                                highlightKeywordInResults={highlightKeywordInResults}
                                                                previewProfileImage={previewProfileImage}
                                                                handleClickProfile={handleClickProfile}
                                                            />
                                                        )
                                                        :
                                                        [3].includes(defaultLocationType) ?
                                                            <DirectorySearchKeywordsStatesCities
                                                                countryid={currentLocation.country_id ?? 0}
                                                                stateid={currentLocation.state_id ?? 0}
                                                                cityid={currentLocation.city_id ?? 0}
                                                                areaid={currentLocation.area_id ?? 0}
                                                                typeid={defaultSearchType}
                                                                keyword={defaultSearchValue}
                                                                listResult={listResultKeywordsStates}
                                                                enableSortDistance={enableSortDistance}
                                                                highlightKeywordInResults={highlightKeywordInResults}
                                                                previewProfileImage={previewProfileImage}
                                                                handleClickProfile={handleClickProfile}
                                                            />
                                                            :
                                                            [4].includes(defaultLocationType) ?
                                                                <DirectorySearchKeywordsCountries
                                                                    countryid={currentLocation.country_id ?? 0}
                                                                    stateid={currentLocation.state_id ?? 0}
                                                                    cityid={currentLocation.city_id ?? 0}
                                                                    areaid={currentLocation.area_id ?? 0}
                                                                    typeid={defaultSearchType}
                                                                    keyword={defaultSearchValue}
                                                                    listResult={listResultKeywordsCountries}
                                                                    enableSortDistance={enableSortDistance}
                                                                    highlightKeywordInResults={highlightKeywordInResults}
                                                                    previewProfileImage={previewProfileImage}
                                                                    handleClickProfile={handleClickProfile}
                                                                />
                                                                :
                                                                ''

                                            }

                                        </>

                                    }

                                    {
                                        [7].includes(defaultSearchType) &&
                                        <DirectorySearchProfileListing
                                            listProfiles={listResulProfiles}
                                            locationTabId={defaultLocationType}
                                            searchCategoryId={searchCategoryId}
                                            searchCategoryDetail={searchCategoryDetail}
                                            highlightKeywordInResults={highlightKeywordInResults}
                                            previewProfileImage={previewProfileImage}
                                            handleClickProfile={handleClickProfile}
                                            handleClickCategoryBack={handleClickCategoryBack}
                                        />
                                    }

                                </>
                            }


                        </section>

                    </div>

                </div>


                <MainSearchProfile handleShowProfilePopup={showProfilePopup} handleCloseProfilePopup={handleCloseProfilePopup} profileSelected={profileSelected} showStatus={profileShowType} closeSearchBox={closeSearchBox} />

                <CityAreaSearch showCityPopup={showCityPopup} handleClosePopUp={handleCloseCityPopUp} passCityDetail={handleClickCitySelected} />
            </div>
        </>
    )
}

export default DirectorySearchNew;
