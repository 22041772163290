import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TitlesLabels from '../../../helpers/TitlesLabels';
import { pageTitle, showFooter, showHeader, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../../actions';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import Utilities from '../../../helpers/Utilities';
import UrlSlugs from '../../../helpers/UrlSlugs';
import FieldInput from '../../../components/FieldInput';
import QueryUtilities from '../../../helpers/QueryUtilities';
import { getGotoLinkCompanies } from '../../../helpers/QueryGotoLinks';
import ManageGotoLinksSelectionPopup from './ManageGotoLinksSelectionPopup';
import SessionUtilities from '../../../helpers/SessionUtilities';


const TOKEN = process.env.REACT_APP_chat_api_token;
const errorMsg = process.env.REACT_APP_message_error_process;

const ManageGotoLinksAddCompany = ({ match }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const usersAssociated = PagesAuthentication.getUsersAssociated();
    const masterDetail = PagesAuthentication.getMasterUserDetailDetail();


    let defaultValues = { display_name: '', category_name: '' };
    let dataDb_result = {}, dataParams = {};


    const [formValues, setFormValues] = useState(defaultValues);
    const [workProfile, setWorkProfile] = useState({});
    const [companyProfile, setCompanyProfile] = useState({});

    const [selectedCompanyDetail, setSelectedCompanyDetail] = useState({});
    const [selectedCategoryDetail, setSelectedCategoryDetail] = useState({});

    const [boolShowOptionPopup, setBoolShowOptionPopup] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);


    const handleOptonClosePopup = () => setBoolShowOptionPopup(false);

    const handelClickRedirectGoto = () => {
        history.push(`/${UrlSlugs.settings_manageGotoLinks}/${btoa(workProfile.account_id ?? 0)}`);
    }


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'display_name') {
            formValuesTemp = { ...formValuesTemp, ...{ display_name: value } };
        } else if (fieldName === 'category_name') {
            formValuesTemp = { ...formValuesTemp, ...{ category_name: value } };
        }

        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);

    }


    const gettingCompanyDetail = async (compid = 0) => {
        compid = parseInt(compid);
        if (compid === 0) { return false; }

        setShowProcessing(true);
        const resCompanyProfile = await QueryUtilities.getProfileDetail(compid);


        if (Object.keys(resCompanyProfile).length > 0) {
            setSelectedCompanyDetail(resCompanyProfile);
            setFormValues({ ...formValues, display_name: resCompanyProfile.company_display_name });
        } else {
            handelClickRedirectGoto();
        }

        setShowProcessing(false);

    }


    const handleClickOption = (groupDetail = {}) => {

        if (Object.keys(groupDetail).length > 0) {
            setSelectedCategoryDetail(groupDetail);
            setFormValues({ ...formValues, category_name: groupDetail.title ?? '' });
            Utilities.removeFormGroupClass(`category_name`);
        }

        handleOptonClosePopup();
    }


    const handleClickClearCategory = () => {
        setFormValues({ ...formValues, category_name: '' });
        setSelectedCategoryDetail({ ...selectedCategoryDetail, group_id: '0', title: '' })
    }


    // add company
    const handleClickSubmit = async () => {

        let errorCounter = 0;
        const fieldsChecker = ['display_name'];

        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {
                if (Utilities.isEmpty(value)) {
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            }
        });

        if (errorCounter > 0) { return false; }

        //if ( Object.keys(selectedCategoryDetail).length===0 ){return false; }

        //flag : 0 = list, 1 : add, 2 : update 3 : delete
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        dataParams = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (masterDetail.account_id ?? 0).toString(),
            company_id: (companyProfile.account_id ?? 0).toString(),
            flag: "1",
            group_id: "0",
            link_account_id: (selectedCompanyDetail.account_id ?? 0).toString(),
            link_account_name: formValues.display_name ?? '',
            category_id: (selectedCategoryDetail.group_id ?? 0).toString(),
        }

        const button_name = `btn-submit-add`;

        Utilities.elementDisabledStatus(button_name, true);
        dataDb_result = await getGotoLinkCompanies(dataParams);
        Utilities.elementDisabledStatus(button_name, false);

        if (Object.keys(dataDb_result).length > 0) {
            handelClickRedirectGoto();
        } else {
            Utilities.messagePopup('error', errorMsg);
        }
    }


    useEffect(() => {

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.gotoLinks}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

    }, []);


    useEffect(async () => {

        let paramWorkProfileId = match.params.wordid ?? '';
        let paramSelectedCompanyid = match.params.selectedCompanyId ?? '';

        if (!Utilities.isEmpty(paramSelectedCompanyid)) {
            paramSelectedCompanyid = parseInt(atob(paramSelectedCompanyid));
            await gettingCompanyDetail(paramSelectedCompanyid);

        }

        if (!Utilities.isEmpty(paramWorkProfileId)) {
            paramWorkProfileId = parseInt(atob(paramWorkProfileId));

            let companyProfileTemp = {}, userLoginDetailTemp = {};
            Object.entries(usersAssociated).map(([acctid, acctdetail]) => {
                if (paramWorkProfileId === parseInt(acctdetail.account_id ?? 0)) {
                    userLoginDetailTemp = Utilities.formatProfileDetail(acctdetail);
                    companyProfileTemp = Utilities.workToCompany(userLoginDetailTemp);
                }
            });


            setWorkProfile(userLoginDetailTemp);
            setCompanyProfile(companyProfileTemp);
        }



    }, [match.params.selectedCompanyId]);



    return (
        <div className="manage-goto-links ">

            <div className="breadcrumbs breadcrumbs-chat">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => dispatch(showMainSettingsReducer(new Date()))}>{TitlesLabels.settings}</div>
                            {companyProfile.hasOwnProperty('name') && <div>{companyProfile.name}</div>}
                            <div className="cursor-pointer" onClick={() => handelClickRedirectGoto()}>{TitlesLabels.siteTitles.gotoLinks}</div>
                            <div className="current cursor-pointer">{TitlesLabels.navigations.add}</div>

                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>
            </div>


            <div className="main-content">
                <div className="container py-5">

                    {
                        showProcessing ?
                            <div className="content-loader animate"></div>
                            :
                            <form className='col-lg-9 mx-auto text-lowercase form-theme' autoComplete="off">


                                <div className='text-center fs-25'>
                                    <div className={`avatar-preview border logo-size-200 logo-size-sm-200 mx-auto`} style={{ backgroundImage: `url(${selectedCompanyDetail.logo_url ?? ''})` }} ></div>

                                    <div className='font-gotham-bold line-height-1-2 mt-4 col-lg-8 mx-auto'>{selectedCompanyDetail.company_display_name ?? (selectedCompanyDetail.name ?? '')}</div>
                                    <div className='color-theme-grey'>{selectedCompanyDetail.category_name ?? ''}</div>
                                </div>


                                <div className='mt-4'>
                                    <FieldInput inputName="display_name" inputLabel="company display name" inputValue={formValues.display_name} pushValue={pushValue} checkerType="general" boolAutoFocus={true} />


                                    <div className="form-group optional">
                                        <div onClick={() => setBoolShowOptionPopup(true)} >
                                            <input type="text" name="category_name" className="form-control" placeholder=" " autoComplete="off" value={formValues.category_name} readOnly ></input>
                                            <label className="form-label">category</label>
                                        </div>
                                        {
                                            !Utilities.isEmpty(formValues.category_name) &&
                                            <div className="optional-label cursor-pointer" onClick={() => handleClickClearCategory()}>remove</div>
                                        }

                                    </div>

                                </div>


                                <div className="button-group-boolean pt-5 mt-4 col-md-6 mx-auto">
                                    <button type="button" className="btn-theme-black " onClick={() => handelClickRedirectGoto()}>cancel</button>
                                    <button type="button" id="btn-submit-add" className={`btn-theme-black button-has-loader`} onClick={() => handleClickSubmit()}>submit</button>
                                </div>

                            </form>
                    }

                </div>
            </div>

            <ManageGotoLinksSelectionPopup showPopup={boolShowOptionPopup} handleClosePopUp={handleOptonClosePopup} handleClickOption={handleClickOption} workCompanyDetail={companyProfile} />

        </div>
    )
}

export default ManageGotoLinksAddCompany;