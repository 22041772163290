

import React from 'react';
import TitlesLabels from '../../helpers/TitlesLabels';

const NoRecordAvailable = (props) => {
    return (
        <div className="text-center text-lowercase no-record-label mt-5">{TitlesLabels.general.noRecordAvailable}</div>
    )
}

export default NoRecordAvailable;