import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

import TitlesLabels from '../../../helpers/TitlesLabels';
import { pageTitle, showFooter, showHeader, showMainSettings as showMainSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer } from '../../../actions';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import Utilities from '../../../helpers/Utilities';
import UrlSlugs from '../../../helpers/UrlSlugs';
import { getGotoLinkCompanies } from '../../../helpers/QueryGotoLinks';
import CommonProfileLogo from '../../../components/common/CommonProfileLogo';
import ManageGotoLinksSelectionPopup from './ManageGotoLinksSelectionPopup';
import FieldInput from '../../../components/FieldInput';
import SessionUtilities from '../../../helpers/SessionUtilities';


const defaultMesage = <div>goto links allow a company to create a shortcut to another related business, branch or division associated with your business.<br />as examples, a multi branch business may list its other branches, a shopping centre may list its stores or a multi divisional type of business may list its others divisions.<br />you may list them under different headings (areas, store categories, etc).</div>

const ManageGotoLinks = ({ match }) => {

    const dispatch = useDispatch();

    const usersAssociated = PagesAuthentication.getUsersAssociated();
    const masterDetail = PagesAuthentication.getMasterUserDetailDetail();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;

    let dataDb_result = {}, dataParams = {};
    let defaultValues = { display_name: '', category_name: '' };
    let defaultCategoryValues = { category_id: '', category_name: '' };

    const [workProfile, setWorkProfile] = useState({});
    const [companyProfile, setCompanyProfile] = useState({});
    const [selectedProfile, setSelectedProfile] = useState({});
    const [showProcessing, setShowProcessing] = useState(false);
    const [boolShowOptionPopup, setBoolShowOptionPopup] = useState(false);
    const [boolShowCategoriesPopup, setBoolShowCategoriesPopup] = useState(false);

    const [allLinkedCompanies, setAllLinkedCompanies] = useState([]);
    const [allHeadings, setAllHeadings] = useState([]);

    const [formValues, setFormValues] = useState(defaultValues);
    const [selectedCategoryDetail, setSelectedCategoryDetail] = useState(defaultCategoryValues);


    const handleOptonClosePopup = () => setBoolShowOptionPopup(false);
    const handleClikMenu = () => setBoolShowOptionPopup(true);

    const handleCategoriesClosePopup = () => setBoolShowCategoriesPopup(false);


    const handleClickCategory = (groupDetail = {}) => {
        if (Object.keys(groupDetail).length > 0) {
            //console.log(groupDetail);
            setSelectedCategoryDetail({ ...selectedCategoryDetail, category_id: groupDetail.group_id ?? '0', category_name: groupDetail.title ?? '' })
            setFormValues({ ...formValues, category_name: groupDetail.title ?? '' });
            Utilities.removeFormGroupClass(`category_name`);

        }

        handleCategoriesClosePopup();
    }


    const handleClickClearCategory = () => {
        setFormValues({ ...formValues, category_name: '' });
        setSelectedCategoryDetail({ ...selectedCategoryDetail, category_id: '0', category_name: '' })
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'display_name') {
            formValuesTemp = { ...formValuesTemp, ...{ display_name: value } };
        } else if (fieldName === 'category_name') {
            formValuesTemp = { ...formValuesTemp, ...{ category_name: value } };
        }

        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);

    }


    const handleClickSubmitForm = async () => {

        let errorCounter = 0;
        const fieldsChecker = ['display_name'];

        Object.entries(formValues).map(([key, value]) => {
            if (fieldsChecker.includes(key)) {
                if (Utilities.isEmpty(value)) {
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            }
        });

        if (errorCounter > 0) { return false; }

        //if ( Object.keys(selectedCategoryDetail).length===0 ){return false; }

        //flag : 0 = list, 1 : add, 2 : update 3 : delete
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        dataParams = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (masterDetail.account_id ?? 0).toString(),
            company_id: (companyProfile.account_id ?? 0).toString(),
            flag: "2",
            group_id: (selectedProfile.group_id ?? 0).toString(),
            link_account_id: (selectedProfile.company_id ?? 0).toString(),
            link_account_name: formValues.display_name ?? '',
            category_id: (selectedCategoryDetail.category_id ?? 0).toString(),
        }

        //console.log(dataParams);
        const button_name = `btn-submit-add`;

        Utilities.elementDisabledStatus(button_name, true);
        dataDb_result = await getGotoLinkCompanies(dataParams);
        Utilities.elementDisabledStatus(button_name, false);

        if (Object.keys(dataDb_result).length > 0) {
            setAllLinkedCompanies(dataDb_result.company_list ?? []);
            setAllHeadings(dataDb_result.category_list ?? []);

            setSelectedProfile({});
        } else {
            Utilities.messagePopup('error', errorMsg);
        }

    }


    //flag : 0 = list, 1 : add, 2 : update 3 : delete
    const gettingGotoLinksList = async (workCompanyDetail = companyProfile, action = 0, groupid = 0) => {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        dataParams = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (masterDetail.account_id ?? 0).toString(),
            company_id: (workCompanyDetail.account_id ?? 0).toString(),
            flag: action.toString(),
            group_id: groupid.toString(),
            link_account_id: "",
            link_account_name: "",
            category_id: ""
        }

        setShowProcessing(true);
        dataDb_result = await getGotoLinkCompanies(dataParams);
        setShowProcessing(false);


        if (Object.keys(dataDb_result).length > 0) {
            setAllLinkedCompanies(dataDb_result.company_list ?? []);
            setAllHeadings(dataDb_result.category_list ?? []);

        } else {
            setAllLinkedCompanies([]);
            setAllHeadings([]);
        }

        setSelectedProfile({});
    }


    const listProfilesHtml = (headingDetail = {}) => {
        const headingId = (headingDetail.group_id ?? 0).toString();
        const filteredProfiles = allLinkedCompanies.filter(profile => profile.category_id.toString() === headingId.toString());
        if (Object.keys(filteredProfiles).length > 0) {

            return (
                <>
                    <div className={`font-gotham-bold fs-20 ${headingId > 0 ? 'mt-4 pt-2' : ''}`}>{headingDetail.title ?? ''}</div>
                    <ul className={`listing-profiles ul-global-list cursor-pointer`}>
                        {
                            Object.entries(filteredProfiles).map(([key, row]) =>
                                <li key={key} data-keyword={row.company_display_name} onClick={() => handleClickProfile(row)} >
                                    <div className="profile-image-wrap">
                                        <CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={{ ...row, name: row.company_name, logo_url: row.company_logo }} />
                                    </div>
                                    <div className='line-height-1'>
                                        <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1" data-name={row.company_display_name}>{row.company_display_name} </div>
                                        <div className="profile-label font-gotham-book fs-16 fs-md-16 color-theme-grey">{row.company_category_name}</div>
                                        <div className="profile-label font-gotham-book fs-14 fs-md-14 color-theme-grey mt-1">{row.company_location}</div>
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                </>
            )
        }

    }


    const handleClickProfile = (profileDetail = {}) => {
        setSelectedProfile(profileDetail);
        setSelectedCategoryDetail({ ...selectedCategoryDetail, category_id: profileDetail.category_id ?? '0', category_name: profileDetail.category_name ?? '' })
        setFormValues({ ...formValues, display_name: profileDetail.company_display_name ?? '', category_name: profileDetail.category_name ?? '' });

    }


    const confirmDeletion = () => {
        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wishToDelete,
            buttons: [
                { label: 'No', onClick: () => { } },
                { label: 'Yes', onClick: () => gettingGotoLinksList(companyProfile, 3, selectedProfile.group_id ?? 0) }
            ]
        });
    }


    useEffect(() => {

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.gotoLinks}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));

        PagesAuthentication.getUserLoggedin();

        return () => {
            setBoolShowOptionPopup(false);
        }

    }, []);



    useEffect(() => {

        let reqAccountID = match.params.wordid || "0";
        if (reqAccountID !== "0") {
            reqAccountID = atob(reqAccountID);
            reqAccountID = parseInt(reqAccountID);

            let companyProfileTemp = {}, userLoginDetailTemp = {};

            Object.entries(usersAssociated).map(([acctid, acctdetail]) => {
                if (reqAccountID === parseInt(acctdetail.account_id ?? 0)) {
                    userLoginDetailTemp = Utilities.formatProfileDetail(acctdetail);
                    companyProfileTemp = Utilities.workToCompany(userLoginDetailTemp);
                }
            });

            setWorkProfile(userLoginDetailTemp);
            setCompanyProfile(companyProfileTemp);

            gettingGotoLinksList(companyProfileTemp);

        }

    }, [match.params.wordid]);



    return (
        <div className="manage-goto-links ">

            <div className="breadcrumbs breadcrumbs-chat">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className='cursor-pointer' onClick={() => dispatch(showMainSettingsReducer(new Date()))}>{TitlesLabels.settings}</div>
                            {companyProfile.hasOwnProperty('name') && <div>{companyProfile.name}</div>}
                            <div className="current cursor-pointer" onClick={() => setSelectedProfile({})}>{TitlesLabels.siteTitles.gotoLinks}</div>

                        </li>
                        <li className="btn-option">
                            {
                                Object.keys(selectedProfile).length > 0 ?
                                    <div className="btn-icon btn-trash" onClick={() => confirmDeletion()}></div>
                                    :
                                    <>
                                        <div className="btn-icon btn-menu-two" onClick={() => handleClikMenu()} title={TitlesLabels.general.manage} ></div>
                                        <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                                        <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>
                                    </>
                            }

                        </li>
                    </ul>
                </div>
            </div>


            <div className="main-content">
                <div className="container py-5">

                    {
                        Object.keys(selectedProfile).length > 0 ?
                            <>
                                <form className='col-lg-9 mx-auto text-lowercase form-theme' autoComplete="off">

                                    <div className='text-center fs-25'>
                                        <div className={`avatar-preview border logo-size-200 logo-size-sm-200 mx-auto`} style={{ backgroundImage: `url(${selectedProfile.company_logo ?? ''})` }} ></div>

                                        <div className='font-gotham-bold line-height-1-2 mt-4 col-lg-8 mx-auto'>{selectedProfile.company_display_name ?? (selectedProfile.company_name ?? '')}</div>
                                        <div className='color-theme-grey'>{selectedProfile.company_category_name ?? ''}</div>
                                    </div>


                                    <div className='mt-4'>
                                        <FieldInput inputName="display_name" inputLabel="company display name" inputValue={formValues.display_name} pushValue={pushValue} checkerType="general" boolAutoFocus={true} />


                                        <div className="form-group optional">
                                            <div onClick={() => setBoolShowCategoriesPopup(true)} >
                                                <input type="text" name="category_name" className="form-control" placeholder=" " autoComplete="off" value={formValues.category_name} readOnly ></input>
                                                <label className="form-label">category</label>
                                            </div>
                                            {
                                                !Utilities.isEmpty(formValues.category_name) &&
                                                <div className="optional-label cursor-pointer" onClick={() => handleClickClearCategory()}>remove</div>
                                            }
                                        </div>

                                    </div>


                                </form>

                                <div className="button-group-boolean pt-5 mt-4 col-md-5 mx-auto">
                                    <button type="button" className="btn-theme-black " onClick={() => setSelectedProfile({})}>cancel</button>
                                    <button type="button" id="btn-submit-add" className={`btn-theme-black button-has-loader`} onClick={() => handleClickSubmitForm()}>submit</button>
                                </div>
                            </>

                            :
                            <div className='col-lg-9 mx-auto'>

                                {
                                    showProcessing ?
                                        <ul className="ul-global-listing">
                                            {
                                                [...Array(6).keys()].map((key) =>
                                                    <li key={key} className="global-preloader  py-2">
                                                        <div className="bg-gray col-5 pt-3 border-radius-loader"></div>
                                                        <div className="ml-2 bg-gray col-3 pt-3  border-radius-loader"></div>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                        :
                                        <>
                                            {

                                                Object.keys(allLinkedCompanies).length === 0 ?
                                                    defaultMesage

                                                    :

                                                    <ul className='text-lowercase'>
                                                        {
                                                            Object.entries(allHeadings ?? []).map(([key, row]) =>
                                                                <li key={key} className='border-0' >
                                                                    {listProfilesHtml(row)}
                                                                </li>
                                                            )
                                                        }

                                                    </ul>

                                            }
                                        </>
                                }
                            </div>
                    }

                </div>
            </div>


            <ManageGotoLinksOptionPopup showPopup={boolShowOptionPopup} handleClosePopUp={handleOptonClosePopup} workProfile={workProfile} />
            <ManageGotoLinksSelectionPopup showPopup={boolShowCategoriesPopup} handleClosePopUp={handleCategoriesClosePopup} handleClickOption={handleClickCategory} workCompanyDetail={companyProfile} />

        </div>
    )
}

export default ManageGotoLinks;



const ManageGotoLinksOptionPopup = (props) => {

    const history = useHistory();
    const workProfile = props.workProfile ?? {};

    const handleClickMenu = (actionType = 0) => {
        actionType = parseInt(actionType);

        if (actionType === 1) {
            props.handleClosePopUp();

            if (Object.keys(workProfile).length > 0) {
                sessionStorage.setItem(
                    process.env.REACT_APP_session_manage_team_current_company_detail,
                    JSON.stringify(workProfile ?? {})
                );

                history.push(`/${UrlSlugs.page_directorySearch}/${btoa(4)}`);

            }


        } else if (actionType === 2) {
            history.push(`/${UrlSlugs.settings_manageGotoLinksHeadings}/${btoa(props.workProfile.account_id ?? 0)}`);
        }
    }




    return <Modal id="popup-manage-gotolink-option" show={props.showPopup ?? false} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme "  >
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body className='px-5'>


            <ul className='cursor-pointer font-gotham-bold fs-18 text-center mt-n4 mb-5'>
                <li className='py-3 border-bottom' onClick={() => handleClickMenu(1)}>add company</li>
                <li className='py-3' onClick={() => handleClickMenu(2)}>manage headings</li>
            </ul>


        </Modal.Body>
    </Modal>
}







