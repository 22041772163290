import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import jQuery from 'jquery';

import TitlesLabels from '../../../helpers/TitlesLabels';
import PagesAuthentication from '../../../helpers/PagesAuthentication';
import UrlSlugs from '../../../helpers/UrlSlugs';

import { showHeader, showFooter, pageTitle, showMainSettings as showMainSettingsReducer } from '../../../actions';
import FieldInput from '../../../components/FieldInput';
import Utilities from '../../../helpers/Utilities';
import { notificationDescriptionLimit, submitSendNotification, uploadNoticeFile } from '../../../helpers/FollowersNotices';

import SidebarFollowers from './SidebarFollowers';
import SessionUtilities from '../../../helpers/SessionUtilities';

const NotificationForm = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const API_TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const notificationDescLimit = notificationDescriptionLimit;

    let defaultFormValues = {
        title: '',
        description: ''
    }

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [userLoginDetail, setUserLoginDetail] = useState({});
    const [logoNotice, setLogoNotice] = useState('');
    const [logoNoticeValue, setLogoNoticeValue] = useState('');
    const [showFollowers, setShowFollowers] = useState(false);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if (fieldName === 'title') {
            formValuesTemp = { ...formValuesTemp, ...{ title: value } };
        } else if (fieldName === 'description') {
            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ description: value } };
        }

        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);
        setFormValues(formValuesTemp);
    }

    const selectFile = () => {
        jQuery('input#input_select_file').trigger('click');
    }

    const previewFile = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            var reader = new FileReader();
            reader.onload = function (e) {

                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setLogoNotice(e.target.result);

                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setLogoNoticeValue(e.target.files[0]);

        } else {
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }

    }

    const handleSelectedMembers = async (memberIds = []) => {

        if (memberIds.length === 0) {
            setShowFollowers(false);

        } else {

            const button_name = `btn-notice-send`;
            let fileUploadResponse = {}, fileNameUploaded = '';

            const imageValue = document.getElementById('input_select_file').value;
            if (!Utilities.isEmpty(imageValue)) {
                const formData = new FormData();
                formData.append('api_token', TOKEN_SESSION.toString());
                formData.append('account_id', userLoginDetail.account_id.toString());
                formData.append('company_id', userLoginDetail.company_detail.account_id.toString());
                formData.append('file', logoNoticeValue);

                Utilities.elementDisabledStatus(button_name, true);
                fileUploadResponse = await uploadNoticeFile(formData);
                Utilities.elementDisabledStatus(button_name, false);

                if (Object.keys(fileUploadResponse).length === 0) {
                    Utilities.messagePopup('error', errorMsg);
                    return false;
                }

                if (parseInt(fileUploadResponse.success) === 1) {
                    fileNameUploaded = fileUploadResponse.result.notification_image_result.file_name ?? '';
                }
            }

            const passval = {
                api_token: TOKEN_SESSION.toString(),    //API_TOKEN.toString(),
                account_id: userLoginDetail.account_id.toString(),
                company_id: userLoginDetail.company_detail.account_id.toString(),
                title: formValues.title.toString(),
                description: formValues.description.toString(),
                image_path: fileNameUploaded.toString(),
                members_list: memberIds.join(',').toString()
            };


            Utilities.elementDisabledStatus(button_name, true);
            const sendNotificationResponse = await submitSendNotification(passval);
            Utilities.elementDisabledStatus(button_name, false);

            if (Object.keys(sendNotificationResponse).length === 0) {
                Utilities.messagePopup('error', errorMsg);
                return false;
            }

            if (parseInt(sendNotificationResponse.success) === 1) {
                Utilities.messagePopup('success', TitlesLabels.alertMessages.notiticationHasBeenSent); //sendNotificationResponse.successMessage
                history.push(`/${UrlSlugs.settings_notificationManagement}`);

            } else {
                Utilities.messagePopup('success', sendNotificationResponse.errorMessage);
            }


        }
    }

    const validateForm = async () => {

        setShowFollowers(false);
        let errorCounter = 0;

        /* const imageValue = document.getElementById('input_select_file').value;
        if ( Utilities.isEmpty(imageValue) ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.imageMandatory );
            return false;
        } */

        Object.entries(formValues).map(([key, value]) => {
            if (['title', 'description'].includes(key)) {
                if (Utilities.isEmpty(value)) {
                    //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            }
        });

        const charCounter = Utilities.wordsCounter(formValues.description);
        if (parseInt(charCounter) > notificationDescLimit) {
            Utilities.messagePopup('error', `Only ${notificationDescLimit} words are allowed!`);
            errorCounter++;
            return false;
        }

        if (errorCounter > 0) { return false; }

        setShowFollowers(true);
    }

    const handleClearInput = () => {
        setFormValues(defaultFormValues);
    }

    useEffect(() => {

        PagesAuthentication.getUserLoggedin();

        const userLoginDetailTemp = PagesAuthentication.getUserLoggedinDetail();
        if (parseInt(userLoginDetailTemp.account_type) !== 4) {
            history.push(`/${UrlSlugs.dashboard}`);
            return false;
        } else {

            // RETURN to activation page
            if (userLoginDetailTemp.hasOwnProperty('company_detail')) {
                const followStaus = parseInt(userLoginDetailTemp.company_detail.follow_status ?? 0);

                if (followStaus === 0) {
                    history.push(`/${UrlSlugs.settings_followersNotices}`);
                    return false;

                } else if (followStaus === 2) {
                    history.push(`/${UrlSlugs.settings_notificationManagement}`);
                }
            }
        }

        setUserLoginDetail(userLoginDetailTemp);

        dispatch(pageTitle(`${TitlesLabels.navigations.notificationManagement}`));
        dispatch(showHeader(1));
        dispatch(showFooter(0));


    }, []);

    return (
        <div className="notication-form-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <Link to={`/${UrlSlugs.settings_notificationManagement}`}>{TitlesLabels.navigations.notificationManagement}</Link>
                            <div className="current">{TitlesLabels.navigations.sendNotification}</div>

                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-group-people"></div>
                            <div className="btn-icon btn-gear" onClick={() => dispatch(showMainSettingsReducer(new Date()))} title={TitlesLabels.settings} ></div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content position-relative">
                <div className="container pt-5 px-5">

                    {showFollowers && <SidebarFollowers handleSelectedMembers={handleSelectedMembers} />}


                    <input type="file" id="input_select_file" name="file" className="invisible" accept={Utilities.attributeImageAcceptExtension} readOnly onChange={(e) => previewFile(e)} ></input>
                    <div className="row form-theme document-form-popup">

                        <div className="col-md-6">
                            <div className="global-image-preview bg-gray document-upload-preview" style={{ backgroundImage: `url(${logoNotice})`, height: 350 }}>
                                {Utilities.isEmpty(logoNotice) && <div className="document-file-icon">upload a file</div>}
                            </div>
                            <div className='profile-logo-change text-center'>
                                <button type="button" className="btn-select-file font-gotham-bold bg-theme-green border-radius text-white outline-none border-0 fs-16 mx-auto mt-3 py-2" style={{ width: 100 }} onClick={() => selectFile()}>select file</button>

                                <div className="fs-13-important text-center pt-3 color-theme-grey">{Utilities.labelUploadSpecs}</div>
                            </div>

                        </div>

                        <div className="col-md-6">
                            <FieldInput inputName="title" inputLabel="Title" inputValue={formValues.title} pushValue={pushValue} checkerType="general" />

                            <div className="form-group border-0">
                                <textarea name="description" className="form-control" rows="10" placeholder=" " value={formValues.description} onChange={(e) => pushValue(e, 'description')}></textarea>
                                <label className="form-label">type here</label>
                            </div>

                            <div className="text-center fs-13 fs-md-13 mt-n2">
                                <div className='color-theme-grey'>maximum {Utilities.number_format(notificationDescLimit)} words</div>
                                <div><span className='cursor-pointer color-theme-green' onClick={() => handleClearInput()}>clear</span></div>
                            </div>


                            {
                                !showFollowers &&
                                <div className="my-3 pt-3">
                                    <button type="button" className=" btn-theme-black" onClick={() => validateForm()}>send</button>
                                </div>
                            }
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default NotificationForm;