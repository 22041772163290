import React, { useState, useEffect  } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import CommonProfileLogo from '../../components/common/CommonProfileLogo';
import { getCompaniesByAccountIds } from '../../helpers/QueryCompanyBranch';
import TitlesLabels from '../../helpers/TitlesLabels';
import UrlSlugs from '../../helpers/UrlSlugs';
import Utilities from '../../helpers/Utilities';
import PagesAuthentication from '../../helpers/PagesAuthentication';


const CompanyBranchList = ( props ) => {

    const history = useHistory();

    const propsMainBranchDetail = Object.keys(props.mainBranchDetail??{}).length>0 ? props.mainBranchDetail.list[0] : {};
    const propsLoginDetail = props.loginDetail?? PagesAuthentication.getMasterUserDetailDetail()  ;
    const propsBranchId = parseInt(props.branchId??0);
    const companyBranchesIds = propsMainBranchDetail.branch_company_id??'';

    
    const [ preLoader, setPreLoader] = useState(false);
    const [ brancList, setBrancList] = useState([]);

    const headerColumnHtml = <li className='pb-4 font-gotham-bold color-theme-dark'>
                                <div></div>
                                <div>company name</div>
                                <div>landline</div>
                                <div>mobile</div>
                                <div>location</div>
                                <div>admin detail</div>
                                <div></div>
                            </li>

    const getBranchList = async () => {
        if ( ! Utilities.isEmpty(companyBranchesIds) ){
            
            setPreLoader(true);
            const responseCompanyBranch = await getCompaniesByAccountIds( companyBranchesIds, 1 );
            setPreLoader(false);

            if ( parseInt(responseCompanyBranch.status??0)===1 ){
                if ( responseCompanyBranch.list.length>0 ){
                    setBrancList( responseCompanyBranch.list );
                }
            }
        }
    }


    const handleManage = ( actionName = '', detailObject = {} ) => {

        if ( Object.keys(detailObject).length>0 && ! Utilities.isEmpty(actionName) ){

            const teamAdminAccountId = detailObject.admin_detail.account_id??0,
                teamAdminAccountIdEncoded = btoa(teamAdminAccountId);
            
            if ( parseInt(teamAdminAccountId)===0 ){
                return false;
            }

            if ( actionName===`profile`){
                history.push(`/${ UrlSlugs.settings_manageProfileCompany }/${ teamAdminAccountIdEncoded }/${btoa(propsBranchId)}`);

            }else if ( actionName===`departments`){
                history.push(`/${ UrlSlugs.settings_manageDepartments }/${ teamAdminAccountIdEncoded }`);

            }else if ( actionName===`teams`){
                history.push(`/${ UrlSlugs.settings_manageTeam }/${ teamAdminAccountIdEncoded }`);

            }else if ( actionName===`permissions`){
                history.push(`/${ UrlSlugs.settings_specialPermissions }/${ teamAdminAccountIdEncoded }`);

            }else if ( actionName===`groups`){
                history.push(`/${ UrlSlugs.settings_manageCompanyGroups }/${ teamAdminAccountIdEncoded }`);

            }
        }

    }


    useEffect( () => {

        if ( Object.keys(propsMainBranchDetail).length>0 ){

            if ( ! Utilities.isEmpty(companyBranchesIds) ){
                getBranchList();

            }else{
                history.push(`/${ UrlSlugs.companyBranch_companyForm }/${ btoa(propsBranchId) }`);
            }
        }
    }, [propsMainBranchDetail]);


    return (
    
            <>
               
                { 
                    ( !preLoader && brancList.length>0 ) && 
                    <ul  className="branch-listing-columns font-gotham-book fs-16 fs-md-16 color-theme-grey mb-5">
                        { headerColumnHtml }
                        {
                            Object.entries(brancList).map( ([key, row ]) => 
                                parseInt(row.status)===1 &&
                                 <li key={key} data-keyword={row.name} className="align-items-center">
                                    <div><CommonProfileLogo classAttr={`profile-image logo-size-60 logo-size-md-60`} profileDetail={row} /></div>
                                    <div className='line-height-1'>
                                        <div className="profile-name notranslate font-gotham-bold fs-20 fs-sm-20 mb-1 ellipsis-1 color-theme-black text-black" data-name={row.name}>{row.branch_name??row.name} </div>
                                        <div className="profile-label">{row.category_name??''}</div>
                                    </div>
                                    <div>{row.company_landline??''}</div>
                                    <div>{row.company_phone??''}</div>
                                    <div>{row.location??''}</div>
                                    <div dangerouslySetInnerHTML={{ __html: Object.keys(row.admin_detail??{}).length===0 ? '' : `${row.admin_detail.name??''}<br />${row.admin_detail.team_position??''}`  }}></div>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="dropdown-icon  dots-menu py-1"></Dropdown.Toggle>
                                            <Dropdown.Menu className='fs-16 fs-md-16' align="right" >
                                                <Dropdown.Item onClick={ () => handleManage(`profile`, row ) }>manage company profile</Dropdown.Item>
                                                <Dropdown.Item onClick={ () => handleManage(`departments`, row ) }>departments</Dropdown.Item>
                                                <Dropdown.Item onClick={ () => handleManage(`teams`, row ) }>team members</Dropdown.Item>
                                                <Dropdown.Item onClick={ () => handleManage(`permissions`, row ) }>special permissions</Dropdown.Item>
                                                <Dropdown.Item onClick={ () => handleManage(`groups`, row ) }>groups</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                }
                
                { 
                    preLoader &&
                    <ul className="ul-global-list branch-preloader">
                    { headerColumnHtml }
                    {
                        [...Array(5).keys()].map( ( key ) =>
                            <li key={key} className="global-preloader  py-2">
                                <div className="logo-size-60 bg-gray logo-size-md-60 border-0"></div>
                                <div> 
                                    <div className="bg-gray col-10 pt-3 border-radius-loader"></div>
                                    <div className="mt-2 bg-gray col-8 pt-3  border-radius-loader"></div>
                                </div>
                                <div className="bg-gray pt-3 border-radius-loader"></div>
                                <div className="bg-gray pt-3 border-radius-loader"></div>
                                <div className="bg-gray pt-3 border-radius-loader"></div>
                                <div className="bg-gray pt-3 border-radius-loader"></div>
                                <div className="bg-gray pt-3 border-radius-loader"></div>
                            </li> 
                        )
                    }
                    </ul>
                }

                { ( !preLoader && brancList.length===0 ) && <div className="text-center text-lowercase no-record-label mt-4">{ TitlesLabels.general.noBranchAvailable }</div> }
            </>
        
    )
}

export default CompanyBranchList;