import React, { useState } from 'react';
import DirectorySearchNoRecord from './DirectorySearchNoRecord';
import Utilities from '../../helpers/Utilities';
import DirectorySearchItemTemplate from './DirectorySearchItemTemplate';
import DirectorySearchLoaderProfile from './DirectorySearchLoaderProfile';
import DirectorySearchBackNavigation from './DirectorySearchBackNavigation';
import DirectorySearchProfileListing from './DirectorySearchProfileListing';
import { getCitiesByState, getPofilesByIds, getStatesByCountry, getSuburbListByCity } from '../../helpers/QueryDirectorySearch';

const DirectorySearchProfilesCountries = (props) => {

    let listResultProfiles = [], dataRequest = {}, resDb_listResult = {};

    const countryList = props.listResult.country_list ?? [];
    const profileCount = props.listResult.profile_count ?? 0;
    const profileAllIds = props.listResult.profile_ids ?? '';

    const countryId = parseInt(props.countryid ?? 0);
    const stateId = parseInt(props.stateid ?? 0);
    const cityId = parseInt(props.cityid ?? 0);
    const areaId = parseInt(props.areaid ?? 0);
    const typeId = parseInt(props.typeid ?? 0);
    const searchCategoryId = parseInt(props.searchCategoryId ?? 0);
    const searchCategoryDetail = props.searchCategoryDetail ?? {};
    const keyword = (props.keyword ?? '').toString();
    const enableSortDistance = props.enableSortDistance ?? false;

    const categoryName = searchCategoryId > 0 ? (searchCategoryDetail.cate_name ?? '' !== '' ? searchCategoryDetail.cate_name + ', ' : '') : '';

    let category_search_status = 0;
    if (Object.keys(searchCategoryDetail).length > 0 && searchCategoryId === 0) {
        category_search_status = 1;
    }

    const defaultLocation = {
        country_id: countryId.toString(),
        state_id: stateId.toString(),
        city_id: cityId.toString(),
        area_id: areaId.toString(),
        category_search: category_search_status.toString()
    }

    const [steps, setSteps] = useState(0);


    const [boolShowProfileResult, setBoolShowProfileResult] = useState(false);
    const [boolShowLoading, setBoolShowLoading] = useState(false);
    const [boolShowStateResult, setBoolShowStateResult] = useState(false);
    const [boolShowCityResult, setBoolShowCityResult] = useState(false);
    const [boolShowSuburbsResult, setBoolShowSuburbsResult] = useState(false);

    const [listResulProfiles, setListResulProfiles] = useState([]);
    const [listResulStates, setListResulStates] = useState([]);
    const [listResulCities, setListResulCities] = useState([]);
    const [listResultSuburbs, setListResultSuburbs] = useState([]);
    const [headerNavigationText, setHeaderNavigationText] = useState(Utilities.labelShowAll);

    const [countrySelected, setCountrySelected] = useState({});
    const [stateSelected, setStateSelected] = useState({});
    const [citySelected, setCitySelected] = useState({});
    const [areaSelected, setAreaSelected] = useState({});

    const [profileCountCountry, setProfileCountCountry] = useState(0);
    const [profileCountState, setProfileCountState] = useState(0);
    const [profileCountCity, setProfileCountCity] = useState(0);
    const [profileCountSuburb, setProfileCountSuburb] = useState(0);


    const gettingProfiles = async (step = steps, profileIds = profileAllIds, navText = Utilities.labelShowAll) => {

        //console.log(`step: ${step}`);
        if (step === 0) {
            setCountrySelected({});
            setStateSelected({});
            setCitySelected({});

            setBoolShowStateResult(false);
            setBoolShowCityResult(false);

        } else if (step === 1) {
            setCitySelected({});
            setBoolShowCityResult(false);

        } else if ([2, 3, 4, 5].includes(step)) {
            setBoolShowCityResult(false);

        } else if ([6].includes(step)) {
            setBoolShowSuburbsResult(false);
        }

        setSteps(step);

        setBoolShowLoading(true);
        setBoolShowProfileResult(false);

        dataRequest = {
            account_ids: profileIds.toString(),
            account_type: typeId.toString(),
        }

        resDb_listResult = await getPofilesByIds(dataRequest);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listResultProfiles = resDb_listResult.list ?? [];
            }
        }

        setListResulProfiles(listResultProfiles);
        setBoolShowLoading(false);
        setBoolShowProfileResult(true);
        setHeaderNavigationText(navText);

    }


    const gettingStatesByCountry = async (countryDetail = {}) => {

        setProfileCountCountry(countryDetail.counter ?? 0);
        setCountrySelected(countryDetail);

        setStateSelected({});
        setCitySelected({});

        setListResulStates([]);
        setListResulCities([]);

        setBoolShowLoading(true);

        let listStates = [];
        dataRequest = {
            ...defaultLocation,
            country_id: (countryDetail.country_id ?? '0').toString(),
            type: typeId.toString(),
            category_id: searchCategoryId.toString(),
            keyword: keyword.toString(),
        }

        resDb_listResult = await getStatesByCountry(dataRequest);
        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listStates = resDb_listResult.list ?? [];
            }
        }


        setListResulStates(listStates);
        setBoolShowStateResult(true);
        setBoolShowCityResult(false);
        setBoolShowLoading(false);
    }


    const gettingCitiesByStates = async (stateDetail = {}) => {

        setSteps(2);

        setProfileCountState(stateDetail.counter ?? 0);
        setStateSelected(stateDetail);
        setCitySelected({});
        setListResulCities([]);

        let listCities = [];

        dataRequest = {
            ...defaultLocation,
            country_id: (countrySelected.country_id ?? 0).toString(),
            state_id: (stateDetail.state_id ?? 0).toString(),
            type: typeId.toString(),
            category_id: searchCategoryId.toString(),
            keyword: keyword.toString(),
            include_area: "1",
        }

        setBoolShowStateResult(false);
        setBoolShowCityResult(false);
        setBoolShowLoading(true);
        setBoolShowProfileResult(false);

        resDb_listResult = await getCitiesByState(dataRequest);

        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                listCities = resDb_listResult.list ?? [];
            }
        }

        setListResulCities(listCities);
        setBoolShowCityResult(true);
        setBoolShowLoading(false);

    }


    const gettingSuburbProfiles = async (suburbdetail = {}) => {
        setAreaSelected(suburbdetail);
        await gettingProfiles(5, suburbdetail.profile_ids ?? '', `${suburbdetail.area_name ?? ''}, ${citySelected.city_name ?? ''}, ${stateSelected.state_name ?? ''}, ${countrySelected.country_name ?? ''}`);
    }


    const gettingSuburbsList = async (citydetail = {}) => {
        setSteps(4);
        setCitySelected(citydetail);
        setProfileCountCity(citydetail.counter ?? 0);

        let suburbList = [];

        dataRequest = {
            ...defaultLocation,
            country_id: (countrySelected.country_id ?? 0).toString(),
            state_id: (stateSelected.state_id ?? 0).toString(),
            city_id: (citydetail.city_id ?? 0).toString(),
            area_id: "0",
            type: typeId.toString(),
            category_id: searchCategoryId.toString(),
            keyword: keyword.toString()
        }

        setBoolShowLoading(true);
        resDb_listResult = await getSuburbListByCity(dataRequest);
        if (Object.keys(resDb_listResult).length > 0) {
            if (parseInt(resDb_listResult.count ?? 0) > 0) {
                suburbList = resDb_listResult.list ?? [];

            }
        }

        setListResultSuburbs(suburbList);
        setBoolShowCityResult(false);
        setBoolShowSuburbsResult(true);
        setBoolShowLoading(false);
    }


    const handleClickBack = () => {
        //console.log(steps);

        if (steps === 0) {
            setBoolShowStateResult(false);
            setBoolShowCityResult(false);

        } else if (steps === 1) {
            setBoolShowStateResult(true);
            setBoolShowCityResult(false);
            setSteps(0);

        } else if (steps === 2) {
            setBoolShowStateResult(true);
            setBoolShowCityResult(false);
            setSteps(0);

        } else if ([3].includes(steps)) {
            setBoolShowCityResult(true);
            setSteps(2);

        } else if ([4, 5].includes(steps)) {
            setBoolShowCityResult(true);
            setBoolShowSuburbsResult(false);
            setSteps(2);

        } else if ([6].includes(steps)) {
            setBoolShowSuburbsResult(true);
            setSteps(5);

        }

        setBoolShowLoading(false);
        setBoolShowProfileResult(false);
    }


    const handleClickCategoryBack = () => {
        props.handleClickCategoryBack();
    }


    return (
        <div className='tab-list-states'>


            {/* -------------------- country listing -------------------- */}
            {
                (!boolShowProfileResult && !boolShowStateResult && !boolShowCityResult && !boolShowLoading && !boolShowSuburbsResult) &&
                <>
                    {
                        Object.keys(countryList).length === 0 ?
                            <DirectorySearchNoRecord />
                            :
                            <>
                                {
                                    Object.keys(searchCategoryDetail).length > 0 && <DirectorySearchBackNavigation handleClick={handleClickCategoryBack} text={searchCategoryDetail.cate_name ?? ''} />
                                }
                                <ul className="countries-listing">
                                    <li onClick={() => gettingProfiles(0)}>
                                        <div>
                                            <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                            <div><span className="counter fs-16 fs-md-14">{profileCount}</span></div>
                                        </div>
                                    </li>
                                    {
                                        Object.entries(countryList).map(([key, row]) =>
                                            parseInt(row.counter) > 0 && <li key={key} onClick={() => gettingStatesByCountry(row)}><DirectorySearchItemTemplate name={row.country_name ?? ''} counter={row.counter ?? '0'} /></li>
                                        )
                                    }

                                </ul>
                            </>
                    }
                </>
            }

            {/* -------------------- state listing -------------------- */}
            {
                (!boolShowProfileResult && boolShowStateResult && !boolShowCityResult && !boolShowLoading && !boolShowSuburbsResult) &&
                <>
                    <DirectorySearchBackNavigation handleClick={handleClickBack} text={countrySelected.country_name ?? headerNavigationText}>
                        {categoryName}
                    </DirectorySearchBackNavigation>

                    <ul className="states-listing">
                        <li onClick={() => gettingProfiles(1, countrySelected.profile_ids ?? '', countrySelected.country_name ?? Utilities.labelShowAll)}>
                            <div>
                                <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                <div><span className="counter fs-16 fs-md-14">{profileCountCountry}</span></div>
                            </div>
                        </li>
                        {
                            Object.entries(listResulStates).map(([key, row]) =>
                                parseInt(row.counter) > 0 && <li key={key} onClick={() => gettingCitiesByStates(row)}><DirectorySearchItemTemplate name={row.state_name ?? ''} counter={row.counter ?? '0'} /></li>
                            )
                        }
                    </ul>
                </>
            }


            {/* -------------------- city listing -------------------- */}
            {
                (!boolShowProfileResult && !boolShowStateResult && boolShowCityResult && !boolShowLoading && !boolShowSuburbsResult) &&
                <>
                    <DirectorySearchBackNavigation handleClick={handleClickBack} text={`${stateSelected.state_name ?? ''}, ${countrySelected.country_name}`}>
                        {categoryName}
                    </DirectorySearchBackNavigation>

                    <ul className="states-listing">
                        <li onClick={() => gettingProfiles(3, stateSelected.profile_ids ?? '', `${Utilities.labelShowAll}, ${stateSelected.state_name ?? ''}, ${countrySelected.country_name}`)}>
                            <div>
                                <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                <div><span className="counter fs-16 fs-md-14">{profileCountState}</span></div>
                            </div>
                        </li>
                        {
                            Object.entries(listResulCities).map(([key, row]) =>
                                parseInt(row.counter) > 0 && <li key={key} onClick={() => gettingSuburbsList(row)}><DirectorySearchItemTemplate name={row.city_name ?? ''} counter={row.counter ?? '0'} /></li>
                            )
                        }
                    </ul>
                </>
            }


            {/* -------------------- suburb listing -------------------- */}
            {
                (!boolShowProfileResult && !boolShowStateResult && !boolShowCityResult && !boolShowLoading && boolShowSuburbsResult) &&
                <>
                    <DirectorySearchBackNavigation handleClick={handleClickBack} text={`${citySelected.city_name ?? ''}, ${stateSelected.state_name ?? ''}, ${countrySelected.country_name}`}>
                        {categoryName}
                    </DirectorySearchBackNavigation>

                    <ul className="states-listing">
                        <li onClick={() => gettingProfiles(6, citySelected.profile_ids ?? '', `${Utilities.labelShowAll}, ${citySelected.city_name ?? ''}, ${stateSelected.state_name ?? ''}, ${countrySelected.country_name}`)}>
                            <div>
                                <div className='font-gotham-bold'>{Utilities.labelShowAll}</div>
                                <div><span className="counter fs-16 fs-md-14">{profileCountCity}</span></div>
                            </div>
                        </li>
                        {
                            Object.entries(listResultSuburbs).map(([key, row]) =>
                                parseInt(row.counter) > 0 &&
                                <li key={key} onClick={() => gettingProfiles(6, row.profile_ids ?? '', `${row.area_name ?? ''},  ${citySelected.city_name ?? ''}, ${stateSelected.state_name ?? ''}, ${countrySelected.country_name}`)}>
                                    <DirectorySearchItemTemplate name={row.area_name ?? ''} counter={row.counter ?? '0'} />
                                </li>
                            )
                        }
                    </ul>
                </>
            }



            {/* -------------------- profile listing -------------------- */}
            {
                (boolShowProfileResult && !boolShowLoading && !boolShowCityResult) &&
                <>
                    <DirectorySearchBackNavigation handleClick={handleClickBack} text={headerNavigationText}>
                        {categoryName}
                    </DirectorySearchBackNavigation>
                    <DirectorySearchProfileListing listProfiles={listResulProfiles} locationTabId={5} highlightKeywordInResults={props.highlightKeywordInResults} previewProfileImage={props.previewProfileImage} handleClickProfile={props.handleClickProfile} enableSortDistance={enableSortDistance} />
                </>
            }


            {/* -------------------- loader -------------------- */}
            {boolShowLoading && <DirectorySearchLoaderProfile />}

        </div>
    )

}


export default DirectorySearchProfilesCountries;