import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import axios from 'axios'
import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';

import { showPopupCompanyCategory as showPopupCompanyCategoryReducer, passCompanyCategoryDetail as passCompanyCategoryDetailReducer } from '../../actions';
import TitlesLabels from '../../helpers/TitlesLabels';
import SessionUtilities from '../../helpers/SessionUtilities';

const CompanyCategorySearch = () => {

    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const maxLetterCategory = 20;

    const showPopupCompanyCategory = useSelector(state => state.showPopupCompanyCategory);

    const [showPopUp, setShowPopUp] = useState(false);
    const [startedSearch, setStartedSearch] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [defaultValue, setdefaultValue] = useState('');
    const [keywordValue, setKeywordValue] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [profileDetail, setProfileDetail] = useState({});

    const handleClosePopUp = () => {
        setShowPopUp(false);
        setdefaultValue('');
        setKeywordValue('');
        setCategoryList([]);
        setStartedSearch(false);
    }

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;
        let cateogryLetterCounter = 0;

        if (fieldName === 'category-name') {
            cateogryLetterCounter = Utilities.charactersCounter(value);
            if (cateogryLetterCounter <= maxLetterCategory) {
                setdefaultValue(value);
            }
        }
    }

    const searchCategory = async (category_group_id = 0) => {

        setShowProcessing(false);

        const button_name = 'from-group-city';
        Utilities.elementDisabledStatus(button_name, false);

        let keyword = document.getElementById('input_category_keyword').value ?? '';

        if (typeof keyword !== 'undefined' && parseInt(keyword.length) > 2 && Object.entries(profileDetail).length > 0) {

            Utilities.elementDisabledStatus(button_name, true);

            setShowProcessing(true);
            setStartedSearch(false);

            let response = {};
            try {
                const TOKEN_SESSION = SessionUtilities.getSessionToken();
                const passValue = {
                    api_token: TOKEN_SESSION.toString(),    //TOKEN.toString(),
                    account_id: profileDetail.account_id.toString(),
                    keyword: keyword.toString(),
                    category_id: category_group_id.toString()
                };

                
                const urlRequest = ApiUrls.profileGetCategoryList;
                //console.log(urlRequest, passValue);
                const responseDetailRes = await axios.post(urlRequest, passValue);
                response = responseDetailRes.data;
                setShowProcessing(false);

            } catch (e) {
                setShowProcessing(false);
                setCategoryList([]); return false;
            }

            setShowProcessing(false);

            if (Object.entries(response).length === 0) { setCategoryList([]); return false; }
            if (parseInt(response.success) === 0) { setCategoryList([]); return false; }

            setCategoryList(response.result.company_category_list);
            setStartedSearch(true);

            Utilities.elementDisabledStatus(button_name, false);
        }

    }


    const replaceCategoryName = (providedName = '') => {

        let returnName = providedName;
        if (!Utilities.isEmpty(providedName)) {
            providedName = providedName.toLowerCase();
            returnName = providedName.replace(keywordValue.toLowerCase(), '<span class="keyword-string">' + keywordValue.toLowerCase() + '</span>');
        }

        return returnName;
    }

    const provideSubmitCategory = (val = {}) => {

        if (Object.entries(val).length === 0) {
            val = {
                category_id: '0',
                category_name: keywordValue.toString(),
                parent_name: ""
            }

            dispatch(passCompanyCategoryDetailReducer(val));
            handleClosePopUp();

        } else {

            if (val.hasOwnProperty('child_status') && parseInt(val.child_status || 0) === 1) {
                searchCategory(parseInt(val.category_id));
                setdefaultValue(val.category_name);

            } else {
                dispatch(passCompanyCategoryDetailReducer(val));
                handleClosePopUp();
            }
        }


    }


    useEffect(() => {

        setStartedSearch(false);
        if (Utilities.isEmpty(keywordValue)) {
            setCategoryList([]);
            setShowProcessing(false);
            return false;
        }

        const delayDebounceFn = setTimeout(() => {
            // Send Axios request here
            if (!Utilities.isEmpty(keywordValue)) {
                searchCategory();
            }

        }, 1000);

        return () => {
            clearTimeout(delayDebounceFn);
        };

    }, [keywordValue]);

    useEffect(() => {

        if (!Utilities.isEmpty(showPopupCompanyCategory)) {
            setShowPopUp(showPopupCompanyCategory !== false ? true : false);
            dispatch(showPopupCompanyCategoryReducer(''));
        }

    }, [showPopupCompanyCategory]);

    useEffect(() => {

        // LOGGEDIN ACCOUNTS
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginProfileInfo !== null) {
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if (Object.keys(loginProfileInfo).length > 0) {
                const profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setProfileDetail(profileLoggedinTemp);
            }
        }

        return () => {
            setKeywordValue('');
            setdefaultValue('');
            setStartedSearch(false);
        };
    }, []);

    return (

        <Modal id="popup-company-category-selection" show={showPopUp} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" >
            <Modal.Header closeButton className=" text-center d-inline border-bottom-0 pt-3"></Modal.Header>
            <Modal.Body >


                <div className="popup-company-category-selection  text-lowercase form-theme mt-n5 pt-1 mx-4 pb-4">
                    <div className='font-gotham-bold fs-25 text-center text-center mb-4'>{TitlesLabels.general.businessType}</div>

                    <div id="from-group-city" className="form-group button-has-loader">
                        <input type="text" id="input_category_keyword" className="form-control text-lowercase" placeholder=" " value={defaultValue} onChange={(e) => { setKeywordValue(e.target.value); pushValue(e, 'category-name') }} autoComplete="off" autoFocus ></input>
                        <label className="form-label">Search</label>
                    </div>


                    <div className="pb-4">

                        {
                            (categoryList.length > 0 && showProcessing === false) &&
                            <ul className="hover-list popup--list-autolflow category-list mb-4 font-gotham-book fs-14 fs-md-14">
                                {
                                    Object.entries(categoryList).map(([key, row]) =>
                                        <li key={key} className="py-2 border-bottom line-height-1-2 cursor-pointer px-2 li-min-height d-flex align-items-center" onClick={() => provideSubmitCategory(row)}>
                                            <div>
                                                <div className="fs-18 fs-md-17" dangerouslySetInnerHTML={{ __html: replaceCategoryName(row.category_name) }}></div>
                                                <div className="color-theme-grey" dangerouslySetInnerHTML={{ __html: replaceCategoryName(row.parent_name) }}></div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        }

                        {
                            (categoryList.length === 0 && showProcessing === false) &&
                            <div className="text-center text-color-gray">{Utilities.labelNoFindResults}</div>
                        }

                        {
                            showProcessing === true &&
                            <div className="global-preloader">
                                <ul className="popup--list-autolflow category-list mb-4 ">
                                    {
                                        [...Array(6).keys()].map((key) =>
                                            <li key={key} className="py-2 border-bottom d-flex align-items-center li-min-height ">
                                                <div className='w-100'>
                                                    <div className="py-2 bg-gray col-8 mb-1 border-radius-loader"></div>
                                                    <div className="py-2 bg-gray col-6 border-radius-loader"></div>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        }

                    </div>

                    {
                        ((typeof categoryList.length === 'undefined' || categoryList.length === 0) && startedSearch === true) &&
                        <button type="button" className="btn-theme-black" onClick={() => provideSubmitCategory({})}>add business type</button>
                    }


                </div>

            </Modal.Body>
        </Modal>
    )
}

export default CompanyCategorySearch;
