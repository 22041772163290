import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import TitlesLabels from '../../helpers/TitlesLabels';

import { showHeader, showFooter, pageTitle, shareQRCode, showMainSettings as showMainSettingsReducer, showDepartmentSettings as showDepartmentSettingsReducer, showPopupQuickLinks as showPopupQuickLinksReducer, showLoader as showLoaderReducer } from '../../actions';
import CommonProfileLogo from '../../components/common/CommonProfileLogo';
import UrlSlugs from '../../helpers/UrlSlugs';
import SessionUtilities from '../../helpers/SessionUtilities';


const ShareQrcode = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    //const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;

    const [profileAccountId, setProfileAccountId] = useState(0);
    const [qrDetail, setQrDetail] = useState({});
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [showButtonOption, setShowButtonOption] = useState(false);


    const shareQRCodeInternal = (actionType = 1) => {
        // dispatch( showMainSearch(1) );
        history.push(`/${UrlSlugs.page_directorySearch}/${btoa(1)}`);
        dispatch(shareQRCode(parseInt(actionType)));
    }

    const triggerSetting = () => {

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);

        if (sesstionLoginType !== null) {
            sesstionLoginType = parseInt(sesstionLoginType);

            if (sesstionLoginType === 2) {
                dispatch(showDepartmentSettingsReducer(new Date()));
            } else {
                dispatch(showMainSettingsReducer(new Date()));
            }

        } else {
            dispatch(showMainSettingsReducer(new Date()));
        }

    }


    const loaderHtml = <li className="global-preloader">
        <div className="logo-size-60 bg-gray logo-size-md-70 border-0"></div>
        <div>
            <div className="bg-gray col-4 pt-3 border-radius-loader"></div>
            <div className="mt-2 bg-gray col-3 pt-3 border-radius-loader"></div>
        </div>

        <div className="qrcode-wrap"><div className='canvas-qrcode bg-gray border-radius-loader'></div></div>

        <div className="d-flex align-items-center justify-content-between">
            <div className="bg-gray pt-5 col-3 border-radius-loader"></div>
            <div className="bg-gray pt-5 col-3 border-radius-loader"></div>
            <div className="bg-gray pt-5 col-3 border-radius-loader"></div>
        </div>
    </li>

    const htmlQRCode = (profileDetail = {}, id = 'qrcode', actionType = 1) => {
        if (Object.keys(profileDetail).length === 0) { return false; }

        return <>
            {/* <div className="logo-size-60 logo-size-md-70" style={{ backgroundImage : `url(${ profileDetail.logo || DEFAUL_AVATAR })`}}></div> */}
            <CommonProfileLogo classAttr={`logo-size-60 logo-size-md-70`} profileDetail={profileDetail} />
            <div className='line-height-1-2'>
                <div className="font-gotham-bold fs-18 ellipsis-2">{profileDetail.name}</div>
                <div className="font-gotham-book fs-18 color-theme-grey">{profileDetail.category}</div>
            </div>

            <div className="qrcode-wrap">
                <div>
                    <canvas id={id} className='canvas-qrcode border border-theme-colored border-theme-width-2 border-radius'></canvas>
                </div>
            </div>

            {
                showButtonOption &&
                <div className="qr-share-option text-lowercase text-center d-flex align-items-center justify-content-between fs-12 fs-md-12">
                    {/* <a href={profileDetail.qr_code} target="_blank" download={`${profileDetail.name}-qrcode.jpg`} className="btn-tool-download">download</a> */}
                    <div className="btn-tool-download cursor-pointer" onClick={() => getDownloadableQRCodeDetail(actionType) }>download</div>
                    <div id="btn-qr-send" className="btn-tool-send cursor-pointer" onClick={() => shareQRCodeInternal(actionType)}>sends</div>
                    <a id="btn-qr-share" href={`mailto:?subject=${profileDetail.name} - qr code&body=${profileDetail.file_path}`} className="btn-tool-share">share</a>
                </div>
            }
        </>
    }

    const getDownloadableQRCodeDetail = async (actionType = 1) => {
        const accountId = parseInt(profileAccountId ?? 0);
        if (accountId > 0) {
            const formData = {
                api_token: TOKEN_SESSION.toString(),
                account_id: accountId.toString(),
            };

            dispatch(showLoaderReducer(1));
            await axios
                .post(ApiUrls.profileGetProfileQrCodeDownload, formData)
                .then(async response => {
                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {
                            const qrcode_detail = response.data.result.profile_qr_account_details;
                            if ( actionType==2 ){
                                downloadQRCode(qrcode_detail.company_details.qr_code, qrcode_detail.company_details.file_name );
                            }else{
                                downloadQRCode(qrcode_detail.qr_code, qrcode_detail.file_name );
                            }
                        }
                    }

                })
                .catch(async (err) => { });

                dispatch(showLoaderReducer(0));
        }
    }


    const downloadQRCode = (url, name) => {
        const a = document.createElement('a')
        a.href = url
        a.download = name //url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)

    }


    const getQRCodeDetail = async (accountId = 0, counter = 0) => {
        accountId = parseInt(accountId ?? 0);

        if (accountId > 0 && counter <= 1) {
            const formData = {
                api_token: TOKEN_SESSION.toString(),
                account_id: accountId.toString(),
            };
            const qrcodeImageSize = 80;

            await axios
                .post(ApiUrls.profileGetProfileQrCode, formData)
                .then(async response => {
                    //console.log(response);
                    setLoadingStatus(false);

                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {

                            setShowButtonOption(true);

                            const qrcode_detail = response.data.result.profile_qr_account_details;
                            setQrDetail(qrcode_detail);
                            //setDisplayProfile(qrcode_detail);

                            const canvas = document.getElementById("qrcode");
                            const ctx = canvas.getContext("2d");

                            const image = new Image();
                            image.onload = function () {
                                ctx.drawImage(image, 0, 0, qrcodeImageSize, qrcodeImageSize);
                            };
                            image.src = qrcode_detail.qr_code;
                            canvas.width = qrcodeImageSize;
                            canvas.height = qrcodeImageSize;

                            if (Object.keys(qrcode_detail.company_details ?? {}).length > 0) {
                                const canvas_comp = document.getElementById("qrcode_company");
                                const ctx_comp = canvas_comp.getContext("2d");

                                const image_comp = new Image();
                                image_comp.onload = function () {
                                    ctx_comp.drawImage(image_comp, 0, 0, qrcodeImageSize, qrcodeImageSize);
                                };
                                image_comp.src = qrcode_detail.company_details.qr_code;
                                canvas_comp.width = qrcodeImageSize;
                                canvas_comp.height = qrcodeImageSize;
                            }

                        } else {
                            Utilities.messagePopup('error', response.data.errorMessage);
                        }

                    } else {
                        if (counter == 0) {
                            await getQRCodeDetail(accountId, 1);
                        } else {
                            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGenerateQrCode);
                        }


                    }

                })
                .catch(async (err) => {
                    setLoadingStatus(false);
                    if (counter == 0) {
                        await getQRCodeDetail(accountId, 1);
                    } else {
                        Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotGenerateQrCode);
                    }
                });
        }

    }


    useEffect(async () => {

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        const sesstionLoginType = JSON.parse(loginType);

        //let displayDetail = { logo_url : '', name : '', category : ''};

        let loginPersonalInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginPersonalInfo !== null) {
            loginPersonalInfo = JSON.parse(loginPersonalInfo);

            let account_id_request = loginPersonalInfo.account_id ?? 0;
            if (sesstionLoginType === 1) {
                account_id_request = loginPersonalInfo.account_id;
                if (loginPersonalInfo.hasOwnProperty('company_detail')) {
                    if (loginPersonalInfo.hasOwnProperty('group_id')) {
                        // account_id_request = loginPersonalInfo.company_detail.account_id;
                    }
                }

            } else if (sesstionLoginType === 2) {

                let companyDetail = localStorage.getItem(process.env.REACT_APP_session_company_detail);
                if (companyDetail !== null) {
                    companyDetail = JSON.parse(companyDetail);
                    //account_id_request = companyDetail.account_id;
                }
            }


            if (parseInt(account_id_request) === 0) {
                return false;
            }

            setProfileAccountId(account_id_request);
            await getQRCodeDetail(account_id_request);

        }

    }, []);


    useEffect(() => {

        PagesAuthentication.getUserLoggedin();

        dispatch(pageTitle(`${TitlesLabels.settings} | ${TitlesLabels.siteTitles.shareQrCode}`));

        const loginType = localStorage.getItem(process.env.REACT_APP_session_logintype);
        let sesstionLoginType = JSON.parse(loginType);

        if (sesstionLoginType !== null) {
            sesstionLoginType = parseInt(sesstionLoginType);

            if (sesstionLoginType === 2) {
                dispatch(showHeader(5));
            } else {
                dispatch(showHeader(1));
            }

        } else {
            dispatch(showHeader(1));
        }

        dispatch(showFooter(0));


        dispatch(showLoaderReducer(0));
        dispatch(shareQRCode(0));

    }, []);

    return (
        <div className="share-qrcode-page">

            <div className="breadcrumbs">
                <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            {/* <div className="cursor-pointer" onClick={ () => triggerSetting()}>{ TitlesLabels.settings }</div> */}
                            <div className="current">{TitlesLabels.navigations.qrCode}</div>
                        </li>
                        <li className="btn-option">
                            <div className="btn-icon btn-circle-arrow-right" onClick={() => dispatch(showPopupQuickLinksReducer(true))} title={TitlesLabels.general.quickLinks}></div>
                            <div className="btn-icon btn-gear" onClick={() => triggerSetting()} title={TitlesLabels.settings} ></div>

                        </li>
                    </ul>
                </div>

            </div>

            <div className="main-content">
                <div className="container pt-3">

                    <ul className='share-qrcode-list px-5 text-lowercase'>
                        {
                            loadingStatus ?
                                <>{loaderHtml} {loaderHtml} {loaderHtml}</>
                                :
                                <>
                                    {
                                        Object.keys(qrDetail.company_details ?? {}).length > 0 &&
                                        <li>
                                            {htmlQRCode(qrDetail.company_details, 'qrcode_company', 2)}
                                        </li>

                                    }

                                    <li>
                                        {htmlQRCode(qrDetail, 'qrcode', 1)}
                                    </li>
                                </>

                        }
                    </ul>

                </div>
            </div>
        </div>

    )
}

export default ShareQrcode;