import axios from 'axios';
import ApiUrls from './ApiUrls';

const API_PLUS_TOKEN = process.env.REACT_APP_chat_api_token;


export const firstLastNameExistanceBasicProfile = async (firstName = '', lastName = '') => {
    let responseDetail = { status: 0, counter: 0 };

    if (firstName !== '' && lastName !== '') {
        firstName = firstName.trim().toLowerCase();
        lastName = lastName.trim().toLowerCase();

        try {
            const responseURL = await axios.post(
                ApiUrls.verificationFirstLastNameExistance,
                {
                    first_name: firstName,
                    last_name: lastName
                }
            );
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = { ...responseDetail, status: parseInt(responseURL.data.status), counter: parseInt(responseURL.data.counter) };
            }

        } catch (e) {

        }
    }

    return responseDetail;
}


export const checkMobileNumberExistance = async (country_id = '', number = '', current_country_id = '', current_number = '') => {
    let responseDetail = { exist: 0 };

    country_id = country_id.toString();
    number = number.toString();

    if (country_id !== '' && number !== '') {
        try {
            const passval = {
                country_id: country_id, number: number,
                current_country_id: current_country_id, current_number: current_number
            };

            //console.log('checkMobileNumberExistance');
            //console.log(ApiUrls.verificationMobileExistance);
            //console.log(passval);

            const responseURL = await axios.post(ApiUrls.verificationMobileExistance, passval);
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = { ...responseDetail, exist: parseInt(responseURL.data.exist) };
            }

        } catch (e) {

        }
    }

    return responseDetail;
}


export const checkEmailExistance = async (emailAdress = '', emailAdress_exluded = '') => {
    let responseDetail = { exist: 0 };

    if (emailAdress !== '') {
        emailAdress = emailAdress.toString();
        emailAdress = emailAdress.toLowerCase();

        if (emailAdress_exluded !== '') {
            emailAdress_exluded = emailAdress_exluded.toString();
            emailAdress_exluded = emailAdress_exluded.toLowerCase();
        }

        try {
            const responseURL = await axios.post(ApiUrls.verificationEmailExistance, { email_address: emailAdress, email_address_excluded: emailAdress_exluded });
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = { ...responseDetail, exist: parseInt(responseURL.data.exist) };
            }

        } catch (e) {

        }
    }

    return responseDetail;
}



export const checkCompanyMobileExistance = async (code = '', number = '') => {
    let responseDetail = { exist: 0 };

    if (code !== '' && number !== '') {

        try {
            const responseURL = await axios.post(ApiUrls.verificationCompanyMobileExistance, { country_id: code, number: number });
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = { ...responseDetail, exist: parseInt(responseURL.data.exist) };
            }

        } catch (e) {

        }
    }

    return responseDetail;
}

export const checkCompanyEmailExistance = async (emailAdress = '', emailAdress_exluded = '') => {
    let responseDetail = { exist: 0 };

    if (emailAdress !== '') {
        emailAdress = emailAdress.toString();
        emailAdress = emailAdress.toLowerCase();

        if (emailAdress_exluded !== '') {
            emailAdress_exluded = emailAdress_exluded.toString();
            emailAdress_exluded = emailAdress_exluded.toLowerCase();
        }

        try {
            const responseURL = await axios.post(ApiUrls.verificationCompanyEmailExistance, { email_address: emailAdress, email_address_excluded: emailAdress_exluded });
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = { ...responseDetail, exist: parseInt(responseURL.data.exist) };
            }

        } catch (e) {

        }
    }

    return responseDetail;
}



export const generateProfileHistoryDetail = async (passValue = {}) => {
    let responseDetail = { status: '0' };

    if (Object.keys(passValue).length > 0) {
        try {
            //
            const responseURL = await axios.post(ApiUrls.prrofileLoginHistoryGenerate, passValue);
            if (responseURL.status === 200) {
                if (parseInt(responseURL.data.status ?? 0)===1) {
                    responseDetail = { ...responseURL.data, status: "1" };
                }
            }
        } catch (e) {

        }
    }

    return responseDetail;
}




export const passwordForgotSendMailSMS = async (flag = 1, values = {}) => {
    let responseDetail = {};


    if (Object.keys(values).length > 0) {
        const passval = {
            ...values,
            flag: flag.toString()
        };

        console.log('passwordForgotSendMailSMS');
        console.log(ApiUrls.profileResetPassword);
        console.log(passval);

        try {
            const responseURL = await axios.post(ApiUrls.profileResetPassword, passval);
            if (parseInt(responseURL.data.success) === 1) {
                responseDetail = responseURL.data;
            }

        } catch (e) {

        }
    }

    return responseDetail;
}


export const appliedNewPasswordChange = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.verificationForgotChangePassword, passval);
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = responseURL.data;
            }

        } catch (e) {

        }
    }

    return responseDetail;
}

export const forgotPasswordAccessKeyExistance = async (accessKey = '') => {
    let responseDetail = {};

    accessKey = accessKey.toString();

    if (accessKey !== '') {
        try {
            const responseURL = await axios.post(ApiUrls.verificationForgotPasswordAccessKeyExistance, { access_key: accessKey });
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = responseURL.data;
            }

        } catch (e) {

        }
    }

    return responseDetail;
}


export const updateEmailMobileCredentail = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            console.log(ApiUrls.profileUpdateEmailMobileCredentail, passval);
            const responseURL = await axios.post(ApiUrls.profileUpdateEmailMobileCredentail, passval);
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = responseURL.data;
            }

        } catch (e) {

        }
    }

    return responseDetail;
}


export const blockWordsAndEmailsValidation = async (types = '', namesArray = []) => {
    let responseDetail = { status: 1, names: [] };

    if (types !== '' && namesArray.length > 0) {

        types = types.toLowerCase();

        try {
            const responseURL = await axios.post(ApiUrls.blockWordsAndEmailsValidation, { types: types, names: namesArray.join(',').toLowerCase() });
            //console.log(namesArray,responseURL);
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = { ...responseDetail, status: parseInt(responseURL.data.validity), names: responseURL.data.names };
            }

        } catch (e) {

        }
    }

    return responseDetail;
}


export const checkPasswordExistanceCompanyBulkCredential = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.checkPasswordExistanceCompanyBulkCredential, passval);
            if (parseInt(responseURL.data.status) === 1) {
                responseDetail = responseURL.data;
            }

        } catch (e) {

        }
    }

    return responseDetail;
}

