import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { showPopupDeleteAccount as showPopupDeleteAccountReducer } from '../../actions';
import UrlSlugs from '../../helpers/UrlSlugs';

const ConfirmationDeleteAccount = () => {

    const dispatch = useDispatch();

    const showPopupDeleteAccountRedux = useSelector(state => state.showPopupDeleteAccount);

    const [showPopup, setShowPopup] = useState(false);

    const handleClosePopUp = () => {
        setShowPopup(false);
        dispatch(showPopupDeleteAccountReducer({}));
    }


    useEffect(() => {
        if (Object.entries(showPopupDeleteAccountRedux).length > 0) {
            setShowPopup(true);
        }

    }, [showPopupDeleteAccountRedux]);


    return (

        <Modal id="popup-delete-account-confirmation" show={showPopup} onHide={handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme"  >
            <Modal.Header closeButton className="pt-4 border-0"></Modal.Header>
            <Modal.Body className='px-5 mt-n4'>
                <div className="text-lowercase  mx-auto pb-4 text-center font-gotham-book fs-18 fs-md-16 text-gray-2">
                    <p className="line-height-1-5">deactivating your account will ensure you are no longer contactable as your profile will not be visible. should any of current contacts attempt to chat to you, they would be informed of your non availability due to your account deactivation/deletion.</p>
                    <p className="line-height-1-5">your account will be automatically deleted 30 days after deactivation and returning to bizcom subsequently would require a new sign up. any information will no longer be retrievable.</p>
                    <p className="line-height-1-5">if, however you log in within the 30 day deactivation period, your deactivation would be cancelled and your account will be resumed</p>

                    <div className="fs-18 pt-2 font-gotham-bold color-theme-pink">are you sure you wish to deactivate this account?</div>
                </div>

                <div className="button-group-boolean pt-3 pb-4 ">
                    <button type="button" className="btn-theme-black text-lowercase" onClick={() => handleClosePopUp()}>Cancel</button>
                    <Link to={`/${UrlSlugs.settings_profileDeactivate}`} className=" btn-theme-black text-lowercase" onClick={() => handleClosePopUp()}>Continue</Link>
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationDeleteAccount;
