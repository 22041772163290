import axios from 'axios';
import ApiUrls from './ApiUrls';
import SessionUtilities from './SessionUtilities';
import PagesAuthentication from './PagesAuthentication';

const API_PLUS_TOKEN = process.env.REACT_APP_chat_api_token;

class DocumentUtilities {

    copyFileToConversFolder = async (passVal = {}) => {

        let documentDetail = {};
        if (Object.entries(passVal).length > 0) {

            try {
                const passValue = passVal;
                const urlRequest = ApiUrls.documentTransferFileToConversFolder;
                const documentDetailRes = await axios.post(urlRequest, passValue);
                documentDetail = documentDetailRes.data;

            } catch (e) {
                documentDetail = {};
            }

        }
        return documentDetail;

    }

    getDocumentList = async (passVal = {}) => {

        let documentList = {};
        if (Object.entries(passVal).length > 0) {
            const TOKEN_SESSION = SessionUtilities.getSessionToken();

            passVal = {
                ...passVal,
                api_token: TOKEN_SESSION.toString() //API_PLUS_TOKEN.toString()
            };

            try {
                const passValue = passVal;
                const urlRequest = ApiUrls.bizcomDocumentsLibrary;

                //console.log('getDocumentList');
                //console.log(urlRequest);
                //console.log(passValue);

                const documentRes = await axios.post(urlRequest, passValue);
                documentList = documentRes.data;
            } catch (e) {
                PagesAuthentication.logoutDirectly();
                documentList = {};
            }

        }
        return documentList;
    }

    manageDocumentList = async (passVal = {}) => {

        let documentList = {};
        if (Object.entries(passVal).length > 0) {
            const TOKEN_SESSION = SessionUtilities.getSessionToken();

            passVal = {
                ...passVal,
                api_token: TOKEN_SESSION.toString() //API_PLUS_TOKEN.toString()
            };

            try {
                const passValue = passVal;
                const urlRequest = ApiUrls.bizcomDocumentsManage;

                console.log('manageDocumentList');
                console.log(urlRequest);
                console.log(passValue);

                const documentRes = await axios.post(urlRequest, passValue);
                documentList = documentRes.data;
            } catch (e) {
                documentList = {};
            }

        }
        return documentList;
    }

    uploadFile = async (formData) => {

        let returnResponse = {};
        try {
            const urlRequest = ApiUrls.bizcomUploadLibrary;
            const documentRes = await axios.post(urlRequest, formData);
            returnResponse = documentRes.data;

        } catch (e) {
            returnResponse = {};
        }

        return returnResponse;

    }

    saveDocumentDetail = async (passVal = {}) => {

        let returnResponse = {};
        if (Object.entries(passVal).length > 0) {
            const TOKEN_SESSION = SessionUtilities.getSessionToken();

            passVal = {
                ...passVal,
                api_token: TOKEN_SESSION.toString() //API_PLUS_TOKEN.toString()
            };

            try {
                const passValue = passVal;
                const urlRequest = ApiUrls.bizcomDocumentsLibrary;
                const documentRes = await axios.post(urlRequest, passValue);
                returnResponse = documentRes.data;

            } catch (e) {
                returnResponse = {};
            }

        }
        return returnResponse;

    }


}

export default new DocumentUtilities();