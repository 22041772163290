import React, { useEffect, useState } from 'react';
import {  useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import TitlesLabels from '../../helpers/TitlesLabels';
import { pageTitle, showFooter, showHeader } from '../../actions';

import CampaignDownloadAppPopup from '../../components/popups/CampaignDownloadAppPopup';
import UrlSlugs from '../../helpers/UrlSlugs';
import PagesAuthentication from '../../helpers/PagesAuthentication';


const CampaignDownloadApp = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [ showPopup, setShowPopup ] = useState(true);

    const handleClosePopUp = () => {
        history.push(`/${ UrlSlugs.login}`);
        setShowPopup(false);
    }

    useEffect( () => {
        dispatch( pageTitle( TitlesLabels.siteTitles.campainAppDowload ) );
        dispatch( showHeader(0) );
        dispatch( showFooter(0) );
        
        // RMEOVE ACCOUNT SESSION
        PagesAuthentication.logoutUser();

    }, [] );

    return (
        <CampaignDownloadAppPopup showPopup={showPopup} handleClosePopUp={handleClosePopUp} />
    )
}

export default CampaignDownloadApp;