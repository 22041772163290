import axios from 'axios';
import ApiUrls from './ApiUrls';


export const savePersonalTempInfo = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            //console.log(ApiUrls.submitPersonalInfoTemp , passval);
            const responseURL = await axios.post(ApiUrls.submitPersonalInfoTemp, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}
