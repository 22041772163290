import React, { useEffect, useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { getCoordinatesByLocation, getLocationByCoordinates } from './../helpers/LocationUtilities';

import TitlesLabels from '../helpers/TitlesLabels';
import Utilities from '../helpers/Utilities';

const MapBox = (props) => {

    const mapStyles = { width: '100%', height: '100%' }
    const coordinatesInitial = { lat: 0, lng: 0 };
    const markerTooltip = props.markerTooltip ?? '';

    //const mapMarkerDraggableGetLocation = props.mapMarkerDraggableGetLocation ?? false;

    const [coordinates, setCoordinates] = useState(coordinatesInitial); // lat: 25.2633, lng: 55.3087

    const mapDragend = async (propsMap, marker, e) => {
        //console.log(e.latLng.lat(), e.latLng.lng());
        let coordinatesResponse = { lat: e.latLng.lat(), lng: e.latLng.lng() };


        /* if ( mapMarkerDraggableGetLocation===true ){
            const responseLocationInformation = await getLocationByCoordinates(coordinatesResponse.lat, coordinatesResponse.lng);
            if ( Object.keys(responseLocationInformation).length>0 ){
                console.log(responseLocationInformation.database);
                const databaseLocation = responseLocationInformation.database;
                coordinatesResponse = {
                                        ...coordinatesResponse,
                                        area_id : databaseLocation.hasOwnProperty('area_id') ? databaseLocation.area_id : 0,
                                        area_name : databaseLocation.hasOwnProperty('area_name') ? databaseLocation.area_name :  '',
                                        city_id : databaseLocation.hasOwnProperty('city_id') ? databaseLocation.city_id :  0,
                                        city_name : databaseLocation.hasOwnProperty('city_name') ? databaseLocation.city_name :  '',
                                        state_id : databaseLocation.hasOwnProperty('state_id') ? databaseLocation.state_id :  0,
                                        state_name : databaseLocation.hasOwnProperty('state_name') ? databaseLocation.state_name :  '',
                                        country_id : databaseLocation.hasOwnProperty('country_id') ? databaseLocation.country_id : 0,
                                        country_name : databaseLocation.hasOwnProperty('country_name') ?databaseLocation.country_name :  '',
                                        change_selection : true
                                 };
            }
        } */

        props.handleCoordinatesRequest(coordinatesResponse);
    }

    const putMapMarkerByLocation = async (locationProvided = '') => {

        if (!Utilities.isEmpty(locationProvided)) {
            const responseLocationInformation = await getCoordinatesByLocation(locationProvided);

            if (Object.keys(responseLocationInformation).length > 0) {
                const geometryCoordinates = responseLocationInformation.geometry.location;

                const coordinatesResponse = { lat: parseFloat(geometryCoordinates.lat), lng: parseFloat(geometryCoordinates.lng) };
                setCoordinates(coordinatesResponse);
                props.handleCoordinatesRequest(coordinatesResponse);
            }
        }
    }


    useEffect(() => {

        let mapLocationActionAttributes = props.mapLocationActionAttributes ?? 2; // 1 - from city/suburb, 2 - by coordinates
        let coordinatesAttributes = props.coordinatesAttributes ?? coordinatesInitial;
        //let mapLocationProvidedAttributes = props.mapLocationProvidedAttributes ?? '';

        //console.log(props.coordinatesAttributes);
        //console.log(mapLocationActionAttributes, mapLocationProvidedAttributes, coordinatesAttributes);

        // BY CITY  OR SUBURB
        if (mapLocationActionAttributes === 1) {
            //setCoordinates(coordinatesInitial);

            // BY COORDINATES
        } else if (mapLocationActionAttributes === 2) {

            if (coordinatesAttributes.hasOwnProperty('lat') && coordinatesAttributes.hasOwnProperty('lng')) {

                let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_session_location);

                if (sessionLocation !== null) {
                    let sessionLocationTemp = JSON.parse(sessionLocation);
                    putMapMarkerByLocation(sessionLocationTemp.city_name ?? '');
                }

                if (coordinatesAttributes.lat === 0 && coordinatesAttributes.lng === 0) {

                    if (sessionLocation !== null) {
                        sessionLocation = JSON.parse(sessionLocation);

                        if (sessionLocation.latitude !== null && sessionLocation.longitude !== null) {

                            coordinatesAttributes = { ...coordinatesAttributes, lat: parseFloat(sessionLocation.latitude), lng: parseFloat(sessionLocation.longitude) };
                            setCoordinates(coordinatesAttributes);

                        } else {
                            putMapMarkerByLocation(sessionLocation.city_name ?? '');
                        }
                    }

                }
            }


            // BY DIRECT PROVIDED COORDINATES 
        } else if (mapLocationActionAttributes === 3) {

        }

    }, []);


    useEffect(async () => {

        let mapLocationActionAttributes = props.mapLocationActionAttributes ?? 2;
        let mapLocationProvidedAttributes = props.mapLocationProvidedAttributes ?? '';
        let coordinatesAttributes = props.coordinatesAttributes ?? coordinatesInitial;

        //console.log( mapLocationActionAttributes, mapLocationProvidedAttributes, coordinatesAttributes );

        if (mapLocationActionAttributes === 0) {
            setCoordinates(coordinatesInitial);

        } else if (mapLocationActionAttributes === 1) {
            setCoordinates(coordinatesInitial);
            putMapMarkerByLocation(mapLocationProvidedAttributes);


            // BY DIRECT PROVIDED COORDINATES 
        } else if (mapLocationActionAttributes === 3) {
            coordinatesAttributes = { ...coordinatesAttributes, lat: parseFloat(coordinatesAttributes.lat), lng: parseFloat(coordinatesAttributes.lng) };
            setCoordinates(coordinatesAttributes);
        }


    }, [props.mapLocationProvidedAttributes, props.mapLocationActionAttributes]);


    return (
        <>
            {
                (coordinates.lat === 0 && coordinates.lng === 0) ?
                    <div className="map-box-wrap d-flex justify-content-center align-items-center">
                        <div className="text-color-gray blinking">{TitlesLabels.general.gettingLocation}</div>
                    </div>
                    :
                    <div className="map-box-wrap">
                        <Map
                            google={props.google}
                            zoom={14}
                            style={mapStyles}
                            initialCenter={coordinates}
                        >
                            <Marker name={'Current location'} title={markerTooltip} draggable={props.mapMarkerDraggableAttribues} onDragend={mapDragend.bind(this)} />
                        </Map>
                    </div>
            }
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_google_map_key
})(MapBox);

