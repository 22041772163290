import React from 'react';

const DirectorySearchLoaderProfile = (props) => {
    return (
        <div className={`container pt-3 ${props.classAttr ?? ''}`}>
            <ul className="dashboard-list-preloader">
                {
                    [...Array(6 + (Math.floor(Math.random() * 3))).keys()].map((key) =>
                        <li key={key}>
                            <div className="profile-image"></div>
                            <div></div>
                            <div></div>
                        </li>
                    )
                }

            </ul>
        </div>
    )

}


export default DirectorySearchLoaderProfile;