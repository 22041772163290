import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from "react-google-login";
import { FacebookProvider, LoginButton } from "react-facebook";

import PagesAuthentication from '../../helpers/PagesAuthentication';
import SessionUtilities from '../../helpers/SessionUtilities';

import UrlSlugs from '../../helpers/UrlSlugs';

const siteTitle = process.env.REACT_APP_site_title;
const imgFolder = process.env.REACT_APP_img_folder;

const CampaignPersonalOptionSocialContent = () => {

    const history = useHistory();

    const social_gogle_client_id = process.env.REACT_APP_social_google_client_id;
    const social_gogle_plugin_name = process.env.REACT_APP_social_google_plugin_name;
    const social_facebook_app_id = process.env.REACT_APP_social_facebook_app_id;

    const classSocialWrap = `mb-3 cursor-pointer position-relative overflow-hidden text-white`;
    const defaultProfileDetail = { type: '0', name: '', first_name: '', last_name: '', email: '', imageUrl: '', token: '', app_id: '' };
    let useDetailTemp = {};
    const [useDetail, setUserDetail] = useState(defaultProfileDetail);

    const [isHuawei, setIsHuawei] = useState(false);

    const properRedirect = () => {
        let redirectUrl = UrlSlugs.personalInfo;
        const session_signupCampaign = SessionUtilities.getSignupCampaign();
        if (Object.keys(session_signupCampaign).length > 0) {
            redirectUrl = `/${UrlSlugs.signup_campaignPersonalInfo}`;
        }

        history.push(redirectUrl);
    }

    const redirectToInfoPage = (dataProvied = {}) => {
        setUserDetail(dataProvied);
        PagesAuthentication.setSignupSocialDetail(dataProvied);
        properRedirect();
    }

    // 1: GOOGLE, 2: APPLE, 3 : FACEBOOK, 4 : INSTAGRAM
    /// GOOGLE 1 ///////////////////////////////////////////
    const google_onSuccess = (response) => {
        //console.log('Success:', response);

        useDetailTemp = {
            ...useDetail,
            type: '1',
            name: response.profileObj.name ?? '',
            first_name: response.profileObj.givenName ?? '',
            last_name: response.profileObj.familyName ?? '',
            email: response.profileObj.email ?? '',
            imageUrl: response.profileObj.imageUrl ?? '',
            token: response.profileObj.email ?? '', //response.accessToken ?? '',
            app_id: response.googleId ?? ''
        };

        redirectToInfoPage(useDetailTemp);
    };

    const google_onFailure = (response) => {
        //console.log('Failed:', response);
        //Utilities.messagePopup('Error', TitlesLabels.general.couldNotFetchData )
    };

    /// FACEBOOK 3 //////////////////////////////////////////////////////////////////////////////////////
    const facebook_onSuccess = (response) => {
        useDetailTemp = {
            ...useDetail,
            type: '3',
            name: response.profile.name ?? '',
            first_name: response.profile.first_name ?? '',
            last_name: response.profile.last_name ?? '',
            email: response.profile.email ?? '',
            imageUrl: response.profile.picture.data.url ?? '',
            token: response.profile.email ?? '', //response.tokenDetail.accessToken ?? '',
            app_id: response.profile.id ?? ''
        };
        redirectToInfoPage(useDetailTemp);
    }

    const facebook_onFailure = (response) => { };


    /// BEONCHAt //////////////////////////////////////////////////////////////////////////////////////
    const handleClick_beonchat = () => {
        PagesAuthentication.deleteSignupSocialDetail();
        properRedirect();
    }

    useEffect(() => {

        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                clientId: social_gogle_client_id,
                plugin_name: social_gogle_plugin_name
            })
        });

        // CHECK BROWER IF HAUWEI
        const agentBrowser = (window.navigator.appVersion ?? '').toLowerCase();
        let position = agentBrowser.search("huaweibrowser");
        setIsHuawei(parseInt(position) > 0 ? true : false);

        // DELETE SOCIAL CONNECT 
        PagesAuthentication.deleteSignupSocialDetail();

    }, []);


    return (
        <div className='my-2 my-lg-5 text-center'>
            <div className='text-center font-gotham-bold fs-20 mb-3 mb-4 '>create a personal account</div>
            <div className='font-gotham-book fs-17 fs-md-16 color-theme-green'>choose one</div>


            <div className='d-flex justify-content-center mt-5'>
                <ul className='text-left fs-20  checkbox-radio-default'>

                    {
                        !isHuawei &&
                        <li className='d-flex align-items-center'>
                            <GoogleLogin
                                clientId={social_gogle_client_id}
                                buttonText="Login"
                                render={renderProps => (
                                    <div className={classSocialWrap} onClick={renderProps.onClick} disabled={renderProps.disabled} ><img src={`${imgFolder}/icons/social/outline-blue-white-circle-google.svg`} className="mr-3" width={40} />google</div>
                                )}
                                onSuccess={google_onSuccess}
                                onFailure={google_onFailure}
                                cookiePolicy={"single_host_origin"}
                            />
                        </li>

                    }

                    <li className='d-flex align-items-center'>

                        <FacebookProvider appId={social_facebook_app_id}>
                            <LoginButton
                                className="bg-transparent border-0 px-0"
                                scope="email"
                                onCompleted={facebook_onSuccess}
                                onError={facebook_onFailure}
                            >
                                <div className={classSocialWrap}><img src={`${imgFolder}/icons/social/outline-blue-white-circle-facebook.svg`} className="mr-3" width={40} />facebook</div>
                            </LoginButton>
                        </FacebookProvider>
                    </li>

                    <li className='d-flex align-items-center'>
                        <div className={classSocialWrap} onClick={() => handleClick_beonchat()}><img src={`${imgFolder}/icons/social/outline-blue-circle-beonchat.svg`} className="mr-3" width={40} />{siteTitle.toLowerCase()}</div>
                    </li>
                </ul>

            </div>


        </div>
    );
}

export default CampaignPersonalOptionSocialContent;
