import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';

import { passDocumentDetail, showLoader as showLoaderReducer, viewMenuPricePopup as viewMenuPricePopupReducer, passChatTeamProfileRestrictionDetail as passChatTeamProfileRestrictionDetailReducer } from '../../actions';

import Utilities from '../../helpers/Utilities';
import ApiUrls from '../../helpers/ApiUrls';
import UrlSlugs from '../../helpers/UrlSlugs';
import ChatUtilities from '../../helpers/ChatUtilities';
import QueryMasterSearchUtlities from '../../helpers/QueryMasterSearchUtlities';
import TitlesLabels from '../../helpers/TitlesLabels';
import QueryUtilities from '../../helpers/QueryUtilities';
import CommonProfileLogo from '../common/CommonProfileLogo';
import DepartmentChatConfirm from './DepartmentChatConfirm';
import DepartmentForms from './DepartmentForms';
import LinkCopiedTooltip from '../common/LinkCopiedTooltip';
import CompanyAppLinks from './CompanyAppLinks';
import SessionUtilities from '../../helpers/SessionUtilities';
import PagesAuthentication from '../../helpers/PagesAuthentication';

const MainSearchProfile = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;
    const TOKEN_CHAT = process.env.REACT_APP_chat_api_token;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();
    const imgFolderUrl = process.env.REACT_APP_img_folder;

    //const passProfile = useSelector( state => state.passProfile);
    const passDocumentId = useSelector(state => state.passDocumentId);
    const companySocialLimit = 4;

    let countryList = {};

    const [profileLoggedin, setProfileLoggedin] = useState({});
    const [profileSelected, setProfileSelected] = useState({});
    const [expandAboutSection, setExpandAboutSection] = useState(false);
    const [showContactProfileOption, setShowContactProfileOption] = useState(false);
    const [isProfileLinked, setIsProfileLinked] = useState(false);
    const [isConnect, setIsConnect] = useState(false);
    const [teamRestrictAccessStatus, setTeamRestrictAccessStatus] = useState(0);
    const [loginType, setLoginType] = useState(0);
    const [showShareTooltip, setShowShareTooltip] = useState(false);

    const [followingStatus, setFollowingStatus] = useState(0);

    const [showDepartmentChatConfirmPopup, setShowDepartmentChatConfirmPopup] = useState(false);
    const [showDepartmentFormPopup, setShowDepartmentFormPopup] = useState(false);
    const [showCompanyAppLinksPopup, setShowCompanyAppLinksPopup] = useState(false);

    const [showCompanySocialLinksMorePopup, setShowCompanySocialLinksMorePopup] = useState(false);
    const [companySocialLinksLimit, setCompanySocialLinksLimit] = useState({});

    const handleCloseProfilePopup = () => {
        setShowContactProfileOption(false);
        setExpandAboutSection(false);
        setIsProfileLinked(false);
        setIsConnect(false);
        setTeamRestrictAccessStatus(0);
        setLoginType(0);
        setFollowingStatus(0);

        setProfileSelected({});
        props.handleCloseProfilePopup();
    }


    const handleCloseCompanySocialLinksPopup = () => {
        setShowCompanySocialLinksMorePopup(false);
    }

    const setupProfileDetail = async (profile = {}) => {
        //setProfileSelected(profile);
        // 1 = new chat , 3 - directory search
        const searchTypeStatus = parseInt(PagesAuthentication.getDirectorySearchType() ?? 1);
        //console.log(`DirectorySearchType: ${searchTypeStatus}`);

        let profileTemp = profile;
        dispatch(showLoaderReducer(1));

        let isAccountBelongs = 0;
        if (parseInt(profileLoggedin.account_type) === 4) {
            if (parseInt(profileLoggedin.company_detail.account_id) === parseInt(profile.account_id)) {
                isAccountBelongs = 1;
            }
        }

        await getCountries();

        setIsProfileLinked(parseInt(isAccountBelongs) === 1 ? true : false);

        // TEAM MEMBER
        if (parseInt(profile.account_type) === 4) {

            if (parseInt(profile.team_contact_number_status ?? 0) === 1) {

                let contact_number_mobile = '', contact_prefix = '', phone_code = '', has_contact = 0;

                contact_number_mobile = profile.team_contact_number.replace(' ', '').trim();
                if (profile.hasOwnProperty('team_contact_number_code_id')) {
                    phone_code = profile.team_contact_number_code_id;

                    if (parseInt(phone_code) > 0) {
                        contact_prefix = '+' + countryList[phone_code]['phonecode'];
                        contact_number_mobile = contact_prefix + contact_number_mobile;
                        has_contact++;
                    }
                } else {
                    contact_number_mobile = '';
                }


                profile = {
                    ...profile,
                    has_profile_contact: has_contact,
                    contact_number_mobile: contact_number_mobile
                }

            }
        }

        //console.log(props.showStatus);

        if (props.showStatus === 2) { // 2 - form search + adding team member
            dispatch(showLoaderReducer(0));
            //dispatch( showMainSearch(0) );

            let manageTeamCompanyDetail = sessionStorage.getItem(process.env.REACT_APP_session_manage_team_current_company_detail) ?? {};
            if (Object.keys(manageTeamCompanyDetail).length > 0) {
                manageTeamCompanyDetail = JSON.parse(manageTeamCompanyDetail);
            }

            if (parseInt(manageTeamCompanyDetail.account_id ?? 0) > 0) {
                history.push(`/${UrlSlugs.settings_team_add}/${btoa(parseInt(manageTeamCompanyDetail.account_id))}/${btoa(profile.account_id)}`);
            }


            // 3 -  redirect to company go to module - add company section
        } else if (props.showStatus === 4) {
            dispatch(showLoaderReducer(0));

            let manageTeamCompanyDetail = sessionStorage.getItem(process.env.REACT_APP_session_manage_team_current_company_detail) ?? {};
            if (Object.keys(manageTeamCompanyDetail).length > 0) {
                manageTeamCompanyDetail = JSON.parse(manageTeamCompanyDetail);
            }

            if (parseInt(manageTeamCompanyDetail.account_id ?? 0) > 0) {
                history.push(`/${UrlSlugs.settings_manageGotoLinksAddCompany}/${btoa(parseInt(manageTeamCompanyDetail.account_id))}/${btoa(profile.account_id)}`);
            }

            //  3 - is contact search, show bizcomplus option
        } else {

            if (parseInt(profile.account_type) !== 3) {
                if (parseInt(profile.account_type) === 4) {

                    let team_restrict_access_status = 0;
                    if (profile.hasOwnProperty('team_restrict_access_status')) {
                        team_restrict_access_status = profile.team_restrict_access_status || 0;
                    }

                    const passval = {
                        api_token: TOKEN_SESSION.toString(),
                        account_id: profileLoggedin.account_id.toString(),
                        account_department_id: profileLoggedin.hasOwnProperty('group_id') ? profileLoggedin.group_id.toString() : '0',
                        profile_id: profile.account_id.toString(),
                        flag: searchTypeStatus === 3 ? "2" : searchTypeStatus.toString()
                    }

                    const consDetailRes = await QueryMasterSearchUtlities.requestAccessTeamCommunicationDetails(passval);
                    team_restrict_access_status = parseInt(consDetailRes.result.search_team_profile_result.restrict_status ?? team_restrict_access_status);

                    setTeamRestrictAccessStatus(team_restrict_access_status);
                    dispatch(showLoaderReducer(1));

                    await axios
                        .post(ApiUrls.profilegetByID, { id: profile.team_company_id })
                        .then(response => {
                            dispatch(showLoaderReducer(0));

                            if (response.status === 200) {
                                if (parseInt(response.data.status ?? 0) === 1) {

                                    const compData = response.data.detail;
                                    setProfileSelected({ ...profile, company_detail: compData });
                                }
                            }
                        })
                        .catch((err) => {
                            dispatch(showLoaderReducer(0));
                            setProfileSelected(profile);
                        });


                    dispatch(showLoaderReducer(0));
                } else {
                    dispatch(showLoaderReducer(0));
                    setProfileSelected(profile);
                }


            } else {

                const passVal = {
                    company_id: profile.account_id,
                    account_id: profileLoggedin.account_id,
                    search_type: props.showStatus,
                    types: 'member-external-count,department-general,department-feedback,department-count,menu-price-detail,social-accounts,following_status,company-groups-count,app-links,gotolinks-count'
                };

                //console.log(profile);            
                //console.log(passVal, ApiUrls.companyOthers);

                dispatch(showLoaderReducer(1));
                await axios
                    .post(ApiUrls.companyOthers, passVal)
                    .then(response => {

                        dispatch(showLoaderReducer(0));
                        if (response.status === 200) {

                            const companyOthers = response.data;
                            const department_count = parseInt(companyOthers.department_count || 0);
                            const department_general = companyOthers.department_general ?? {};
                            const department_feedback = companyOthers.department_feedback ?? {};
                            const member_count = parseInt(companyOthers.member_count || 0);
                            const menu_detail = companyOthers.menu_price_detail ?? {};
                            const social_accounts = companyOthers.social_accounts ?? {};
                            const app_links = companyOthers.app_links ?? {};
                            const following_status = parseInt(companyOthers.following_status ?? 0);
                            const company_groups_count = parseInt(companyOthers.company_groups_count ?? 0);
                            const company_gotolinks_count = parseInt(companyOthers.gotolinks_count ?? 0);

                            //console.log(social_accounts);
                            setFollowingStatus(following_status);

                            let isConnectTemp = 0;
                            if (companyOthers.hasOwnProperty('department_count') && department_count > 1) {
                                isConnectTemp = 1;
                            }

                            if (companyOthers.hasOwnProperty('member_count') && member_count > 0) {
                                isConnectTemp = 1;
                            }

                            if (parseInt(isConnectTemp) === 0) {
                                setIsConnect(true);
                            } else {
                                setIsConnect(false);
                            }


                            let contact_number_landline = '', contact_number_mobile = '', contact_prefix = '', phone_code = '', has_contact = 0;

                            if (profile.hasOwnProperty('mobile_number_status') && parseInt(profile.mobile_number_status) === 1) {
                                contact_number_mobile = profile.company_phone.replace(' ', '').trim();
                                if (profile.hasOwnProperty('company_phone_code')) {
                                    phone_code = profile.company_phone_code;

                                    if (parseInt(phone_code) > 0) {
                                        contact_prefix = '+' + countryList[phone_code]['phonecode'];
                                        contact_number_mobile = contact_prefix + contact_number_mobile;
                                        has_contact++;
                                    }
                                } else {
                                    contact_number_mobile = '';
                                }
                            }

                            if (profile.hasOwnProperty('landline_number_status') && parseInt(profile.landline_number_status) === 1) {
                                contact_number_landline = profile.company_landline.replace(' ', '').trim()
                                if (profile.hasOwnProperty('company_landline_code')) {

                                    phone_code = profile.company_landline_code;

                                    if (parseInt(phone_code) > 0) {
                                        contact_prefix = '+' + countryList[phone_code]['phonecode'];
                                        contact_number_landline = contact_prefix + contact_number_landline;
                                        has_contact++;
                                    }
                                } else {
                                    contact_number_landline = '';
                                }
                            }


                            if (profileTemp.hasOwnProperty('company_website') && !Utilities.isEmpty(profileTemp.company_website)) {

                                let companyWebsite = profileTemp.company_website;
                                let websiteDisplay = companyWebsite.split('/')[2];

                                if (typeof websiteDisplay === 'undefined') {
                                    websiteDisplay = companyWebsite;
                                    companyWebsite = 'http://' + websiteDisplay;
                                }

                                profileTemp = { ...profileTemp, company_website: companyWebsite, website_display: websiteDisplay };
                            }


                            let appStatus = 1, appAllLinks = `${app_links.appstore_link ?? ''}${app_links.playstore_links ?? ''}${app_links.appgalery_links ?? ''}`;
                            appAllLinks = appAllLinks.trim();

                            if (Utilities.isEmpty(appAllLinks)) {
                                appStatus = 0;
                            }

                            let socialLinksAvailable = {}, companySocialLinksLimitTemp = {}, limitCounter = 0;
                            Object.entries(social_accounts).map(([key, url]) => {
                                if (!Utilities.isEmpty(url)) {
                                    socialLinksAvailable[key] = url;
                                    limitCounter++;

                                    if (limitCounter <= companySocialLimit) {
                                        companySocialLinksLimitTemp[key] = url;
                                    }

                                }
                            });

                            setCompanySocialLinksLimit(companySocialLinksLimitTemp);

                            profileTemp = {
                                ...profileTemp,
                                member_count: member_count,
                                department_count: department_count,
                                company_groups_count: company_groups_count,
                                company_gotolinks_count: company_gotolinks_count,
                                company_department_general: department_general,
                                company_department_feedback: department_feedback,

                                has_contact: has_contact,
                                contact_number_mobile: contact_number_mobile,
                                contact_number_landline: contact_number_landline,

                                menu_detail: menu_detail,
                                social_accounts: socialLinksAvailable,
                                app_links: { ...app_links, status: appStatus }
                            };

                            //console.log(profileTemp);

                            setProfileSelected(profileTemp);
                        }
                    })
                    .catch((err) => {
                        dispatch(showLoaderReducer(0));
                    });

                dispatch(showLoaderReducer(0));
            }
        }
    }

    const toggleProfileOptionNav = () => {
        if (isConnect === true) {
            handleGeneralDepartment();

        } else {
            setShowContactProfileOption(showContactProfileOption === false ? true : false);

        }
    }


    const handleGeneralDepartment = async () => {

        closeSearchBox();

        if (!Utilities.isEmpty(passDocumentId) && passDocumentId.toString() !== '0') {
            const passValues = {
                account_id: parseInt(profileSelected.account_id),
                convers_detail: {},
                document_id: parseInt(passDocumentId),
                department_id: '0' // general
            }

            dispatch(passDocumentDetail(passValues));
            return false;
        }

        dispatch(showLoaderReducer(1));

        //console.log(parseInt(profileSelected.company_department_general.group_id ?? 0));
        const contactRequestValues = ChatUtilities.contactRequest(profileLoggedin, profileSelected, parseInt(profileSelected.company_department_general.group_id ?? 0), 0);
        const conversationDetailRes = await QueryUtilities.getConversationDetail(contactRequestValues.url, contactRequestValues.values);
        const conversation = conversationDetailRes.result.conversation_details ?? {};


        dispatch(showLoaderReducer(0));

        if (Object.entries(conversation).length > 0) {
            let chatPassValue = ChatUtilities.encryptChatDetail(conversation);

            ChatUtilities.saveAccountsConversationDetail(conversation, 'directory search - profile detail - general department');
            history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation.account_id))}/${chatPassValue}`);

        } else {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotProcessRequest);
        }

        //let chatUrl = `/${ UrlSlugs.chat }/${ btoa( profileSelected.account_id ) }/3`;
        //history.push(chatUrl);

    }


    const handleTeamProfile = async () => {

        const button_name = 'btn-confirm-chat';

        if (parseInt(teamRestrictAccessStatus) === 1) {

            /* Utilities.elementDisabledStatus(button_name, true);

            const passValuesRes = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: profileLoggedin.account_id.toString(),
                account_type: profileLoggedin.account_type.toString(),
                account_department_id: profileLoggedin.hasOwnProperty('group_id') ? profileLoggedin.group_id.toString() : '0',
                company_id: profileSelected.hasOwnProperty('company_detail') ? profileSelected.company_detail.account_id.toString() : '0',
                receiver_account_id: profileSelected.account_id.toString(),
                restrict_status: teamRestrictAccessStatus.toString()
            };


            const responseContactTeam = await QueryMasterSearchUtlities.contactRequestTeamDetail(passValuesRes);
            Utilities.elementDisabledStatus(button_name, false);

            if (Object.entries(responseContactTeam).length === 0) {
                Utilities.messagePopup('error', errorMsg);
                return false;
            }

            if (parseInt(responseContactTeam.success) === 0) {
                Utilities.messagePopup('error', responseContactTeam.errorMessage);
                return false;
            }

            const conversationStatus = parseInt(responseContactTeam.result.bizchat_team_conversation.conversation_status || 0);

            if (conversationStatus === 0) {
                dispatch(passChatTeamProfileRestrictionDetailReducer(profileSelected));
                handleCloseProfilePopup();
                return false;

            } else {

                const conversation = responseContactTeam.result.bizchat_team_conversation.conversation_list;
                let chatPassValue = ChatUtilities.encryptChatDetail(conversation);

                ChatUtilities.saveAccountsConversationDetail(conversation);
                history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation.account_id))}/${chatPassValue}`);

                closeSearchBox();
            } */

            profileSelected = {
                ...profileSelected,
                company_id: profileSelected.hasOwnProperty('company_detail') ? profileSelected.company_detail.account_id.toString() : '0'
            };

            dispatch(passChatTeamProfileRestrictionDetailReducer(profileSelected));
            handleCloseProfilePopup();
            return false;

            return false;
        }


        if (!Utilities.isEmpty(passDocumentId) && passDocumentId.toString() !== '0') {
            const passValues = {
                account_id: parseInt(profileSelected.account_id),
                convers_detail: {},
                document_id: parseInt(passDocumentId)
            }

            dispatch(passDocumentDetail(passValues));

            closeSearchBox();
            return false;
        }


        Utilities.elementDisabledStatus(button_name, true);

        const contactRequestValues = ChatUtilities.contactRequest(profileLoggedin, profileSelected, 0, 0);
        const conversationDetailRes = await QueryUtilities.getConversationDetail(contactRequestValues.url, contactRequestValues.values);
        const conversation = conversationDetailRes.result.conversation_details ?? {};

        Utilities.elementDisabledStatus(button_name, false);
        closeSearchBox();

        if (Object.entries(conversation).length > 0) {
            let chatPassValue = ChatUtilities.encryptChatDetail(conversation);

            ChatUtilities.saveAccountsConversationDetail(conversation, 'directory search - profile detail');
            history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation.account_id))}/${chatPassValue}`);

        } else {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.couldNotProcessRequest);
        }

        //let chatUrl = `/${ UrlSlugs.chat }/${ btoa( profileSelected.account_id ) }`;
        // history.push(chatUrl); 

    }


    const viewMenuProduct = () => {
        //console.log(profileSelected.menu_detail);
        //const menuDetail = profileSelected.menu_detail ?? {};

        /* if ( Object.keys(menuDetail).length>0 && menuDetail.hasOwnProperty('file_url') ){
            window.open(menuDetail.file_url, '_blank');
        } */

        // Temporary Disbaled cuz PDF is directly downloaded and no use of popup
        closeSearchBox();
        dispatch(viewMenuPricePopupReducer(profileSelected.menu_detail ?? {}));
    }


    const handleClickShareProfile = () => {

        Utilities.copyContent('profile_share_link');

        setShowShareTooltip(true);
        setTimeout(() => {
            setShowShareTooltip(false);
        }, 1000);
    }

    const htmlDirectoryProfileNavigation = () => {

        let isAccountBelongs = 0;
        if (parseInt(profileLoggedin.account_type) === 4) {
            if (parseInt(profileLoggedin.company_detail.account_id || 0) === parseInt(profileSelected.account_id)) {
                isAccountBelongs = 1;
            }
        }
        //return <></>

        return <>
            <input id="profile_share_link" type="text" className="d-none" value={`${ApiUrls.deepLink}/${btoa("comp_connect_poup/" + profileSelected.account_id)}/${Utilities.removeSpecialChar(profileSelected.name ?? '')}`} />
            <ul className='d-flex align-items-center justify-content-center fs-14 fs-md-14'>

                {
                    (profileSelected.hasOwnProperty('menu_detail') && Object.entries(profileSelected.menu_detail).length > 0 && parseInt(profileSelected.menu_detail.status || 0) === 1) &&
                    <li style={{ width: 90 }} className={`px-3 py-2 ${isAccountBelongs === 0 ? 'cursor-pointer' : 'cursor-notallow opacity-05'} `} onClick={() => isAccountBelongs === 0 && viewMenuProduct()}>
                        <img src={`${imgFolderUrl}/icons/white/outline-circle-check-list.svg`} width={39} />
                        <div className='mt-2'>{Utilities.menuProductOptionLabel[parseInt(profileSelected.menu_detail.type ?? 1)].toString()}</div>
                    </li>
                }

                <li style={{ width: 90 }} className='px-3 py-2 cursor-pointer' onClick={() => setExpandAboutSection(expandAboutSection === true ? false : true)}>
                    <img src={`${imgFolderUrl}/icons/white/outline-circle-info.svg`} width={39} />
                    <div className='mt-2'>info</div>
                </li>

                <li style={{ width: 90 }} className='px-3 py-2 cursor-pointer position-relative' onClick={() => handleClickShareProfile()}>
                    <img src={`${imgFolderUrl}/icons/white/outline-circle-paperplane.svg`} width={39} />
                    <div className='mt-2'>share</div>
                    <LinkCopiedTooltip boolShow={showShareTooltip} />
                </li>

                <li style={{ width: 90 }} className={`px-3 py-2 ${isAccountBelongs === 0 ? 'cursor-pointer' : 'cursor-notallow opacity-05'} `} onClick={() => isAccountBelongs === 0 && handleOnClickFeedback()}>
                    <img src={`${imgFolderUrl}/icons/white/outline-circle-listing.svg`} width={39} />
                    <div className='mt-2'>feedback</div>
                </li>

                {
                    parseInt(profileSelected.company_gotolinks_count ?? 0) > 0 &&
                    <li style={{ width: 90 }} className={`px-3 py-2 ${isAccountBelongs === 0 ? 'cursor-pointer' : 'cursor-notallow opacity-05'} `} onClick={() => isAccountBelongs === 0 && handleOnClickGotoLinks(profileSelected.account_id ?? 0)}>
                        <img src={`${imgFolderUrl}/icons/white/outline-circle-goto.svg`} width={39} />
                        <div className='mt-2'>goto</div>
                    </li>
                }


            </ul>
        </>
    }

    const htmlProfileNavigation = (type = 1) => {

        let isAccountBelongs = 0;
        if (parseInt(profileLoggedin.account_type) === 4) {
            if (parseInt(profileLoggedin.company_detail.account_id || 0) === parseInt(profileSelected.account_id)) {
                isAccountBelongs = 1;
            }
        }

        const encodedAccountId = btoa(profileSelected.account_id);

        let chatUrl = "#"; //`/${ UrlSlugs.chat }/${encodedAccountId}/3`;
        let departmentUrl = `/${UrlSlugs.departments}/${encodedAccountId}`;
        let teamUrl = `/${UrlSlugs.teamMembers}/${encodedAccountId}`;
        let groupsUrl = `/${UrlSlugs.companyGroups}/${encodedAccountId}`;

        if (!Utilities.isEmpty(passDocumentId) && passDocumentId.toString() !== '0') {
            chatUrl = "#";
            departmentUrl = departmentUrl.toString() + '/' + btoa(passDocumentId);
            teamUrl = teamUrl.toString() + '/' + btoa(passDocumentId);
        }

        return (
            <ul className={`profile-departments-buttons col-md-11 mx-auto fs-13 fs-md-14 font-gotham-book`}>

                {
                    parseInt(isAccountBelongs) === 0 ?
                        <>
                            {
                                (profileSelected.hasOwnProperty('menu_detail') && Object.entries(profileSelected.menu_detail).length > 0 && parseInt(profileSelected.menu_detail.status || 0) === 1) &&
                                <li>
                                    <Link to="#" onClick={() => viewMenuProduct()} >
                                        <div className='profile-option-icons menu-icon mb-2'></div>
                                        {Utilities.menuProductOptionLabel[parseInt(profileSelected.menu_detail.type ?? 1)].toString()}
                                    </Link>
                                    {/* <img src="https://s3.ap-south-1.amazonaws.com/image.directory.beonchat/beonchat_dev/bizchat/catalog_details/372/365srestaurant.png" /> */}
                                </li>
                            }

                            <li><Link to={departmentUrl} onClick={() => closeSearchBox(true)} ><div className='profile-option-icons grid-icon mb-2'></div>departments</Link></li>

                            {
                                profileSelected.hasOwnProperty('member_count') && parseInt(profileSelected.member_count || 0) > 0 &&
                                <li><Link to={teamUrl} onClick={() => closeSearchBox(true)} ><div className='profile-option-icons groups-people-icon mb-2'></div>team</Link></li>
                            }

                            {
                                profileSelected.hasOwnProperty('company_groups_count') && parseInt(profileSelected.company_groups_count || 0) > 0 &&
                                <li><Link to={groupsUrl} onClick={() => closeSearchBox(true)} ><div className='profile-option-icons groups-combine-icon mb-2'></div>groups</Link></li>
                            }

                        </>
                        :

                        <>

                            {
                                (profileSelected.hasOwnProperty('menu_detail') && Object.entries(profileSelected.menu_detail).length > 0 && parseInt(profileSelected.menu_detail.status || 0) === 1) &&
                                <li><Link to="#" className="opacity-075"><div className='profile-option-icons menu-icon mb-2'></div>{Utilities.menuProductOptionLabel[parseInt(profileSelected.menu_detail.type)].toString()}</Link></li>
                            }

                            <li><Link to="#" className="opacity-075"><div className='profile-option-icons grid-icon mb-2'></div>departments</Link></li>

                            {
                                (profileSelected.hasOwnProperty('member_count') && parseInt(profileSelected.member_count || 0) > 0) &&
                                <li><Link to="#" className="opacity-075"><div className='profile-option-icons groups-people-icon mb-2'></div>team</Link></li>
                            }

                            {
                                profileSelected.hasOwnProperty('company_groups_count') && parseInt(profileSelected.company_groups_count || 0) > 0 &&
                                <li><Link to="#" className="opacity-075"><div className='profile-option-icons groups-combine-icon mb-2'></div>groups</Link></li>
                            }


                        </>
                }

            </ul>
        )

    }

    const closeSearchBox = (totallyClose = false) => {
        handleCloseProfilePopup();

        if (typeof props.closeSearchBox !== 'undefined') {
            props.closeSearchBox(totallyClose);
        }
    }

    const getCountries = async () => {

        await axios
            .post(ApiUrls.country, {})
            .then(response => {
                if (response.status === 200) {
                    const countriesList = response.data;

                    let country_list = {};
                    Object.entries(countriesList).map(([key, row]) => {
                        country_list[row.id] = row;
                    });
                    countryList = country_list;
                }
            })
            .catch((err) => {

            });
    }

    const handleClickFollowingUpdateStatus = async (hideConfirmation = 1) => {

        const button_name = `btn-submit-following-status`;
        const followingStatusRequest = followingStatus === 0 ? 1 : 0;

        const passval = {
            account_id: profileLoggedin.account_id.toString(),
            company_id: profileSelected.account_id.toString(),
            status: followingStatusRequest.toString()
        };

        if (parseInt(hideConfirmation) === 0) {
            confirmAlert({
                title: '',
                message: TitlesLabels.confirmMessages.wishToUnfollow,
                overlayClassName: 'normal-font-style warning',
                buttons: [
                    { label: 'close' },
                    { label: 'continue', onClick: () => handleClickFollowingUpdateStatus() }
                ],
            });

            return false;
        }

        Utilities.elementDisabledStatus(button_name, true);
        const followingStatusUpdateDb = await QueryMasterSearchUtlities.followingStatusUpdate(passval);
        Utilities.elementDisabledStatus(button_name, false);

        if (Object.keys(followingStatusUpdateDb).length === 0 || parseInt(followingStatusUpdateDb.status ?? 0) === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }

        if (parseInt(followingStatusUpdateDb.status ?? 0) === 1) {
            Utilities.messagePopup('success', followingStatusRequest === 1 ? TitlesLabels.alertMessages.successfullyFollowed : TitlesLabels.alertMessages.successfullyUnFollowed);
            setFollowingStatus(followingStatusRequest);
        }

    }



    // FEEDBACK DEPARTMENT ///////////////////////////////////////////////////////////
    const handleOnClickFeedback = () => {
        setShowDepartmentChatConfirmPopup(true);
    }


    const handleOnClickGotoLinks = (profileId = 0) => {
        profileId = parseInt(profileId ?? 0);
        if (profileId > 0) {
            history.push(`/${UrlSlugs.gotoLinks}/${btoa(profileId)}`);
        }
    }


    const getDepartmentFeedbackConversationDetail = async () => {

        let conversationDetail = {};
        if (profileSelected.company_department_feedback.group_id ?? 0 > 0) {
            const contactRequestValues = ChatUtilities.contactRequest(profileLoggedin, profileSelected, parseInt(profileSelected.company_department_feedback.group_id ?? 0), 0);
            conversationDetail = await axios.post(contactRequestValues.url, contactRequestValues.values);
        }

        return await conversationDetail;
    }

    const handleDepatmentConfirmAction = async (form_status = 0) => {
        form_status = parseInt(form_status ?? 0);

        if ((profileSelected.company_department_feedback.group_id ?? 0) > 0 && form_status === 3) {

            setShowDepartmentFormPopup(true);
            handleCloseDepartmentPopup();

            /* const button_name = 'btn-chat-department';
            Utilities.elementDisabledStatus(button_name, true );

            const conversationDetail = await getDepartmentFeedbackConversationDetail();
            const conversation = conversationDetail.data.result.conversation_details ?? {};

            Utilities.elementDisabledStatus(button_name, false );


            if ( Object.entries(conversation).length>0 ){
                let chatPassValue = ChatUtilities.encryptChatDetail(conversation); 
                
                ChatUtilities.saveAccountsConversationDetail(conversation, 'directory profile - department list' );
                history.push(`/${UrlSlugs.chatConversation}/${btoa( parseInt(conversation.account_id) )}/${chatPassValue}`);

            }else{
                // show form
                setShowDepartmentFormPopup(true);
                handleCloseDepartmentPopup();
            } */

        }
    }


    const handleCloseDepartmentPopup = () => {
        setShowDepartmentChatConfirmPopup(false);
    }

    const submitDerpartmentFormDetail = async (formDetail) => {

        if ((profileSelected.company_department_feedback.group_id ?? 0) > 0) {

            const button_name = 'btn-submit-form';
            const departmentDetail = profileSelected.company_department_feedback ?? {};

            if (Object.keys(formDetail).length > 0) {

                Utilities.elementDisabledStatus(button_name, true);

                const conversationDetail = await getDepartmentFeedbackConversationDetail();
                if (Object.keys(conversationDetail).length === 0) {
                    Utilities.elementDisabledStatus(button_name, false);
                    return false;
                }

                let form_status = parseInt(departmentDetail.form_status || 0);

                let subject = '';
                if (form_status === 3) {
                    subject = formDetail.subject.toString();
                }

                let name = '';
                if (form_status === 1 || form_status === 2) {
                    name = formDetail.name.toString();
                }

                let no_people = '';
                if (form_status === 2) {
                    no_people = formDetail.no_people.toString();
                }

                let requestDate = formDetail.date;
                let requestTime = requestDate.toString() + ' ' + formDetail.time.toString() + ':00';

                requestTime = moment(new Date(requestTime.toString())).format('HH:mm:ss').toLocaleString();
                requestDate = moment(new Date(requestDate.toString())).format('YYYY-MM-DD').toLocaleString();

                const CONVERSATION_DETAIL = conversationDetail.data.result;

                const conversation_list = CONVERSATION_DETAIL.conversation_details;
                const CONVERS_DATA = ChatUtilities.encryptChatDetail(conversation_list);

                const conversIDGenerated = conversation_list.convers_id.toString();

                const account_department_id = profileLoggedin.hasOwnProperty('group_id') ? profileLoggedin.group_id.toString() : '0';


                const company_id = parseInt(conversation_list.account_company_id) === 0 ? departmentDetail.account_id : conversation_list.account_company_id;

                const passValue = {
                    api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                    account_id: profileLoggedin.account_id.toString(),
                    account_type: profileLoggedin.account_type.toString(),
                    company_id: company_id.toString(), //conversation_list.account_company_id.toString(), //departmentDetail.account_id.toString(),
                    convers_id: conversIDGenerated.toString(),
                    department_id: departmentDetail.group_id.toString(),
                    flag: form_status.toString(), // 1 : appointment, 2 : reservation, 3 : complaints
                    name: name,
                    subject: subject,
                    date: requestDate.toString(),
                    time: requestTime.toString(),
                    no_people: no_people,
                    description: formDetail.remarks.toString(),
                    account_department_id: account_department_id.toString()
                }

                await axios
                    .post(ApiUrls.bizchatRequestAppointmentComplaint, passValue)
                    .then(response => {

                        Utilities.elementDisabledStatus(button_name, false);

                        if (parseInt(response.status) === 201) {
                            if (parseInt(response.data.success) === 1) {
                                ChatUtilities.saveAccountsConversationDetail(conversation_list);
                                history.push(`/${UrlSlugs.chatConversation}/${btoa(parseInt(conversation_list.account_id))}/${CONVERS_DATA}`);

                                Utilities.messagePopup('success', response.data.successMessage);
                                handleCloseDepartmentFormPopUp();

                            } else {
                                Utilities.messagePopup('error', response.data.errorMessage);
                            }
                        }
                    })
                    .catch((err) => {
                        Utilities.messagePopup('error', errorMsg);
                        Utilities.elementDisabledStatus(button_name, false);

                    });


            } else {
                handleCloseDepartmentFormPopUp();
            }
        }


    }

    const handleCloseDepartmentFormPopUp = () => {
        setShowDepartmentFormPopup(false);
    }



    // END FEEDBACK DEPARTMENT ///////////////////////////////////////////////////////////


    // COMPANY LINKS
    const handleClickShowCompanyAppLinks = () => {
        setShowCompanyAppLinksPopup(true);
    }

    const handleClickCloseCompanyAppLinks = () => {
        setShowCompanyAppLinksPopup(false);
    }
    // END COMPANY LINKS


    useEffect(() => {

        const profileSelectedTemp = props.profileSelected ?? {};
        if (Object.keys(profileSelectedTemp).length > 0) {
            //getCountries();
            setupProfileDetail(profileSelectedTemp);

        }


        // LOGGEDIN ACCOUNTS
        let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if (loginProfileInfo !== null) {
            loginProfileInfo = JSON.parse(loginProfileInfo);

            if (Object.keys(loginProfileInfo).length > 0) {
                const profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);
                setProfileLoggedin(profileLoggedinTemp);
            }
        }


        // CHECK LOGIN TYPE
        const loginTypeStorage = localStorage.getItem(process.env.REACT_APP_session_logintype);
        const sesstionLoginType = JSON.parse(loginTypeStorage);

        if (sesstionLoginType !== null) {
            setLoginType(parseInt(sesstionLoginType));
        }

    }, [props.profileSelected]);


    /* useEffect( () => {
       setRequestDocumentId(passDocumentId.toString());
   }, [passDocumentId] ); */

    return (
        Object.keys(profileSelected).length > 0 &&
        <>
            <Modal id="main-search-profle-popup" show={props.handleShowProfilePopup} onHide={handleCloseProfilePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-dialog-theme "
                backdropClassName={`${(parseInt(props.showStatus) !== 1 && parseInt(profileSelected.account_type ?? 0) === 3) ? 'modal-backdrop-gray' : ''}`}
                contentClassName={`${(parseInt(props.showStatus) !== 1 && parseInt(profileSelected.account_type ?? 0) === 3) ? 'bg-black text-white' : ''}`} >

                <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
                <Modal.Body className='pt-0 mt-n2 overflow-hidden main-search-profle-body'>

                    <div className="profile-selected-assoc text-center text-lowercase">
                        <div className="col-md-11 mx-auto">
                            {
                                parseInt(profileSelected.account_type) === 4 ?
                                    <>
                                        <div className="pb-3 text-center mx-auto col-8 line-height-1-2">
                                            {/* <div className={`profile-team-logo mx-auto fs-22-important logo-size-200 logo-size-md-120 mx-auto`} style={{ backgroundImage:  `url(${ Utilities.showHideProfileImage(profileSelected.logo_url) })`, backgroundColor : Utilities.bgcolorHideInitialBg(profileSelected.logo_url)  }}>{ Utilities.showHideInitial(profileSelected.logo_url, profileSelected.name) }
                                                    </div> */}
                                            <CommonProfileLogo classAttr={`profile-team-logo mx-auto fs-22-important logo-size-200 logo-size-md-120 mx-auto`} profileDetail={profileSelected} />

                                            <div className="notranslate mt-2 font-gotham-bold fs-30 fs-md-22">{profileSelected.name}</div>
                                            {(profileSelected.hasOwnProperty('category') && profileSelected.category !== '') && <div className="profile-label fs-25 fs-md-18 color-theme-grey">{profileSelected.category}</div>}
                                        </div>

                                        <div className='d-flex justify-content-center mb-4'>
                                            <div className="profile-header-company d-flex align-items-center text-left pt-3 pb-2">
                                                <div className="fs-22-important logo-size-90 logo-size-md-80" style={{ backgroundImage: `url(${profileSelected.company_detail.logo_url})` }}></div>
                                                <div className="line-height-1-2 ml-3">
                                                    <div className="font-gotham-bold fs-25 fs-md-18 notranslate">{profileSelected.company_detail.name}</div>
                                                    {(profileSelected.company_detail.hasOwnProperty('category_name') && profileSelected.company_detail.category_name !== '') && <div className="profile-label fs-20 fs-md-16 color-theme-grey">{profileSelected.company_detail.category_name}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            (
                                                parseInt(profileSelected.has_profile_contact ?? 0) === 1 &&
                                                !Utilities.isEmpty(profileSelected.contact_number_mobile ?? '') &&
                                                parseInt(teamRestrictAccessStatus) === 0
                                            ) &&
                                            <div className=" text-center mt-n2 color-theme-green pb-3">{profileSelected.contact_number_mobile}</div>
                                        }

                                        {
                                            (
                                                parseInt(props.showStatus) !== 1 &&
                                                profileSelected.hasOwnProperty('team_about') &&
                                                !Utilities.isEmpty(profileSelected.team_about ?? '') &&
                                                (profileSelected.team_about ?? '') !== 'undefined' &&

                                                parseInt(teamRestrictAccessStatus) === 0

                                            ) &&
                                            <div className="py-2">


                                                <div className="text-color-blue">
                                                    <span className="cursor-pointer font-gotham-book fs-14 fs-md-14" onClick={() => setExpandAboutSection(expandAboutSection === true ? false : true)}>view profile</span>
                                                </div>

                                                {
                                                    expandAboutSection === true &&
                                                    <div>

                                                        <div id="popup-profile-about" className="white-space mt-4 mb-3 section-about fs-16 fs-md-16" dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(profileSelected.team_about) }}></div>
                                                        {/* <div className="d-flex align-items-center justify-content-center">
                                                                            <div className="about-navigation">
                                                                                <div className="btn-arrow-up" onClick={ () => Utilities.scrollTopMore('popup-profile-about') }></div>
                                                                                <div className="btn-arrow-down" onClick={ () => Utilities.scrollDownMore('popup-profile-about') }></div>
                                                                            </div>
                                                                        </div> */}
                                                    </div>
                                                }

                                            </div>
                                        }

                                        {
                                            parseInt(teamRestrictAccessStatus) === 1 &&
                                            <div className="line-height-1 font-gotham-bold fs-14 fs-md-14 my-2">
                                                <div className="btn-icon-restricted mx-auto"></div>
                                                <div className="color-theme-red mt-2 pt-1">restricted access</div>
                                            </div>
                                        }

                                    </>
                                    :
                                    <>
                                        <div className="profile-header text-center  mb-4">



                                            <CommonProfileLogo classAttr={`profile-image fs-22-important  logo-size-200 logo-size-md-120 mx-auto`} profileDetail={profileSelected} />
                                            <div className='text-center mt-3'>
                                                <div className="profile-name notranslate   font-gotham-bold fs-30 fs-md-22">{profileSelected.name}</div>
                                                {
                                                    ((profileSelected.hasOwnProperty('category') && profileSelected.category !== '') && parseInt(profileSelected.branch_status ?? 0) === 0) &&
                                                    <div className="profile-label  fs-25 fs-md-18 color-theme-grey">{profileSelected.category}</div>
                                                }
                                                {
                                                    (parseInt(profileSelected.branch_status ?? 0) > 0 && !Utilities.isEmpty(profileSelected.branch_name || '')) &&
                                                    <div className="profile-label  fs-25 fs-md-18 color-theme-grey">{profileSelected.branch_name}</div>

                                                }
                                            </div>
                                        </div>


                                        <div className="profile-address col-md-8 mx-auto text-center pb-3 font-gotham-book fs-16 fs-md-16">
                                            {
                                                profileSelected.hasOwnProperty('address_visibility_status') ?
                                                    <>
                                                        {
                                                            parseInt(profileSelected.address_visibility_status) === 1 ?
                                                                profileSelected.hasOwnProperty('address_1') &&
                                                                <>
                                                                    <div>{profileSelected.address_1}</div>
                                                                    <div>{profileSelected.state_name}, {profileSelected.country_name}</div>
                                                                </>
                                                                :
                                                                profileSelected.hasOwnProperty('address_2') && <div>{profileSelected.address_2}</div>
                                                        }
                                                    </>
                                                    :
                                                    profileSelected.hasOwnProperty('address_2') && <div>{profileSelected.address_2}</div>
                                            }
                                        </div>

                                    </>
                            }


                            {
                                (profileSelected.hasOwnProperty('has_contact') && parseInt(profileSelected.has_contact) >= 1) &&
                                <div className="d-flex align-items-center  justify-content-center  profile-contact pb-3  font-gotham-book fs-16 fs-md-16">
                                    {!Utilities.isEmpty(profileSelected.contact_number_mobile ?? '') && <div className="px-4 color-theme-green">{profileSelected.contact_number_mobile}</div>}
                                    {
                                        !Utilities.isEmpty(profileSelected.contact_number_landline ?? '') &&
                                        <div className={`px-4 color-theme-green ${!Utilities.isEmpty(profileSelected.contact_number_mobile ?? '') ? 'border-left border-theme-colored' : ''}`}>{profileSelected.contact_number_landline}</div>
                                    }
                                </div>
                            }

                            {([3].includes(parseInt(props.showStatus)) && parseInt(profileSelected.account_type) === 3) && htmlDirectoryProfileNavigation()}

                            {
                                parseInt(props.showStatus) !== 1 &&
                                <>
                                    {
                                        ([3].includes(parseInt(props.showStatus)) && parseInt(profileSelected.account_type) === 3) &&
                                        <div id="btn-submit-following-status" className={`${followingStatus == 0 ? 'btn-theme-green-outline' : 'btn-theme-green'} mt-3 mb-5 button-has-loader col-md-5 mx-auto`} onClick={() => handleClickFollowingUpdateStatus(followingStatus === 0 ? 1 : 0)}>{followingStatus == 0 ? TitlesLabels.general.follow : TitlesLabels.general.following}</div>
                                    }

                                    {
                                        profileSelected.hasOwnProperty('company_about') &&
                                        <div className="pb-3">
                                            {
                                                expandAboutSection === true &&
                                                <div>
                                                    {
                                                        !Utilities.isEmpty(profileSelected.company_about) ?
                                                            <div id="popup-profile-about" className="white-space mt-4 mb-3 section-about" dangerouslySetInnerHTML={{ __html: Utilities.removeBackSlash(profileSelected.company_about) }}></div>
                                                            :
                                                            <div className="white-space mt-4 mb-3 section-about" >no information</div>

                                                    }
                                                </div>
                                            }

                                        </div>
                                    }
                                </>
                            }

                            <div className="mb-5 pt-4 bg-white text-black profile-more-info-option">
                                <div className='position-relative'>
                                    {
                                        parseInt(profileSelected.account_type) === 3 ?

                                            parseInt(props.showStatus) === 1 ?

                                                isConnect === false ?
                                                    htmlProfileNavigation(1)
                                                    :
                                                    //  general department
                                                    <ul className=' profile-departments-buttons'>
                                                        <Link to="#" onClick={() => handleGeneralDepartment()}>
                                                            <div className='profile-option-icons arrow-cicle mb-2'></div>
                                                            connect
                                                        </Link>
                                                    </ul>

                                                :
                                                <>
                                                    <ul className="list-company-detail-option">

                                                        {
                                                            !Utilities.isEmpty(profileSelected.company_website ?? '') &&
                                                            <li><a href={profileSelected.company_website} target="_blank" className="btn-company-detail-icon  weblink-icon"></a></li>
                                                        }

                                                        {
                                                            (profileSelected.hasOwnProperty('app_links') && Object.keys(profileSelected.app_links).length > 0 && parseInt(profileSelected.app_links.status ?? 0) === 1) &&
                                                            <li><div className="btn-company-detail-icon app-link-icon" onClick={() => handleClickShowCompanyAppLinks()}></div></li>
                                                        }

                                                        {
                                                            profileSelected.hasOwnProperty('social_accounts') &&
                                                            Object.entries(companySocialLinksLimit).map(([key, url]) =>
                                                                <li className={`${!Object.keys(Utilities.socialLinkValues).includes(key) ? 'social-custom' : ''}`}>
                                                                    <li className={`${!Object.keys(Utilities.socialLinkValues).includes(key) ? 'social-custom' : ''}`}>
                                                                        <a href={Utilities.socialLink(key, url)} target="_blank" className={`btn-company-detail-icon ${key}-icon`}>
                                                                            {Utilities.socialLinkNamesLogoFormatter(key)}
                                                                        </a>
                                                                    </li>
                                                                </li>
                                                            )
                                                        }

                                                        {
                                                            (profileSelected.hasOwnProperty('social_accounts') && Object.keys(profileSelected.social_accounts ?? {}).length > companySocialLimit) &&
                                                            <li><div className='btn-company-social-more' onClick={() => setShowCompanySocialLinksMorePopup(true)}></div></li>

                                                        }
                                                    </ul>


                                                    <ul className={`list-contact-profile-option mt-4 d-flex align-items-center justify-content-center d-block }`}>

                                                        {
                                                            isProfileLinked === false ?
                                                                <li className={`mx-1 d-block`}>
                                                                    <div className="btn-nav-chat btn-chat font-gotham-black fs-25 fs-md-20 color-theme-blue-important" onClick={() => toggleProfileOptionNav()}>chat</div>
                                                                </li>
                                                                :
                                                                <li className='mx-1'><div className="btn-nav-chat btn-chat font-gotham-black opacity-075 cursor-notallow fs-25 fs-md-20 color-theme-blue-important">chat</div></li>
                                                        }

                                                        {
                                                            (!Utilities.isEmpty(profileSelected.latitude) && !Utilities.isEmpty(profileSelected.longitude)) &&
                                                            <li className='mx-1 '>
                                                                <a href={`https://maps.google.com/?q=${profileSelected.latitude},${profileSelected.longitude}`} target="_blank" className="btn-nav-chat btn-location"><img src={`${imgFolderUrl}/icons/green/map-pin.svg`} width="35%" /></a>
                                                            </li>
                                                        }

                                                    </ul>

                                                    {showContactProfileOption === true && <div className="mt-4">{htmlProfileNavigation(2)}</div>}

                                                    {
                                                        (parseInt(profileSelected.plus_status) === 1 && parseInt(loginType) === 1) &&
                                                        <div className="mt-4 pt-2">
                                                            <div onClick={() => handleCloseProfilePopup()} className="cursor-pointer btn-theme-black" >company profile</div>
                                                        </div>
                                                    }

                                                </>

                                            :

                                            <button id="btn-confirm-chat" type="button" onClick={() => handleTeamProfile()} className="btn-theme-black button-has-loader" >confirm</button>

                                    }
                                </div>
                            </div>


                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <DepartmentChatConfirm show={showDepartmentChatConfirmPopup} handleClose={handleCloseDepartmentPopup} companyProfile={profileSelected} departmentDetail={profileSelected.company_department_feedback ?? {}} handleConfirmAction={handleDepatmentConfirmAction} />

            <DepartmentForms departmentDetail={profileSelected.company_department_feedback ?? {}} handeFormSubmit={submitDerpartmentFormDetail} handleCloseFormPopUp={handleCloseDepartmentFormPopUp} showFormPopUp={showDepartmentFormPopup} action="create" timeStamp={new Date()} />

            <CompanyAppLinks showPopup={showCompanyAppLinksPopup} handleClosePopup={handleClickCloseCompanyAppLinks} accountId={profileSelected.account_id ?? 0} />

            <DirectorySeachProfileSocialPopup showPopup={showCompanySocialLinksMorePopup} handleClosePopup={handleCloseCompanySocialLinksPopup} profileSelected={profileSelected} />

        </>

    )
}

export default MainSearchProfile;




const DirectorySeachProfileSocialPopup = (props) => {
    const socialNamesAvailable = Object.keys(Utilities.socialLinkValues);
    const props_showPopup = props.showPopup,
        props_handleClosePopup = props.handleClosePopup,
        socialAccounts = props.profileSelected.hasOwnProperty('social_accounts') ? props.profileSelected.social_accounts : {};


    const [socialLinksAvailable, setSocialLinksAvailable] = useState({});

    const init = () => {

        let socialLinksAvailableTemp = {};
        Object.entries(socialAccounts).map(([key, url]) => {
            if (!Utilities.isEmpty(url)) {
                socialLinksAvailableTemp[key] = url;
            }
        });

        setSocialLinksAvailable(socialLinksAvailableTemp);
    }

    useEffect(() => {
        if (props_showPopup) {
            init();
        }

    }, [props_showPopup]);

    return (
        <Modal id="popup-profile-social-links" show={props_showPopup} onHide={props_handleClosePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme" keyboard={true}>
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4"></Modal.Header>
            <Modal.Body className="px-5 pb-5 mt-n4">

                <ul className="list-company-detail-option list-social-names overflow-hidden">
                    {
                        Object.entries(socialLinksAvailable).map(([key, url]) =>
                            <li className={`${!socialNamesAvailable.includes(key) ? 'social-custom' : ''}`}>
                                <a href={Utilities.socialLink(key, url)} target="_blank">
                                    <div className={`btn-company-detail-icon ${key}-icon`}>
                                        {Utilities.socialLinkNamesLogoFormatter(key)}
                                    </div>
                                    <div>{key}</div>
                                </a>
                            </li>
                        )
                    }

                </ul>

            </Modal.Body>
        </Modal >
    )

}