import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import TitlesLabels from '../../helpers/TitlesLabels';
import Utilities from '../../helpers/Utilities';
import { generateProfileHistoryDetail, passwordForgotSendMailSMS } from '../../helpers/VerificationUtilities';
import LoginInputSelection from '../../pages/Login/LoginInputSelection';

const ForgotPassword = (props) => {

    const defaultLocation = Utilities.defaultLocation();
    const defaultValues = { email: '', phone_code: defaultLocation.country_id.toString(), phone_ext: defaultLocation.country_ext.toString(), phone_number: '' }
    const [loginType, setLoginType] = useState(2);
    const [formValues, setFormValues] = useState(defaultValues);

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'email') {
            setFormValues({ ...formValues, ...{ email: value } });
        } else if (fieldName === 'phone_number') {
            setFormValues({ ...formValues, ...{ phone_number: parseInt(value) || '' } });
        }
    }

    const changeCountryCode = (countryDetail = {}) => {
        if (Object.keys(countryDetail).length > 0) {
            setFormValues({ ...formValues, ...{ phone_code: countryDetail.id.toString(), phone_ext: (countryDetail.phonecode ?? '').toString() } });
        }

    }




    const submitEmail = async () => {

        const generatedToken = Utilities.generateToken().toString();
        const button_name = 'btn-submit-send-email';

        let valueRequest = parseInt(loginType) === 2 ? formValues.phone_number : formValues.email;

        if (Utilities.isEmpty(valueRequest)) {
            return false;
        }

        const errorMessageText = loginType === 1 ? TitlesLabels.alertMessages.noUserFoundWithEmailAddress : TitlesLabels.alertMessages.noUserFoundWithEmailMobile;

        // SEND ACCOUNT HISTORY
        const timeUTC = moment.utc();
        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString();  //utc_date
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex); // brower_id

        const generatedLoginToken = await generateProfileHistoryDetail({
            account_id: "0",
            brower_id: browserUniqueID.toLowerCase(),
            utc_date: dateFormatted.toString(),
            token_request: generatedToken.toString(),
        });

        if (generatedLoginToken.status == "0") {
            Utilities.messagePopup('error', errorMessageText);
            return false;
        }


        const passval = {
            api_token: (generatedLoginToken.token ?? generatedToken).toString(),
            account_id: "0",
            flag: (loginType === 1 ? 1 : 2).toString(),
            email: (loginType === 1 ? formValues.email : "").toString(),
            country_code: loginType === 1 ? "" : formValues.phone_ext.toString(),
            phone_number: loginType === 1 ? "" : formValues.phone_number.toString()
        };

        Utilities.elementDisabledStatus(button_name, true);
        const responseInformation = await passwordForgotSendMailSMS(loginType, passval);
        Utilities.elementDisabledStatus(button_name, false);


        if (Object.keys(responseInformation).length > 0) {
            const status = parseInt(responseInformation.success);
            if (status === 1) {
                setFormValues(defaultValues);
                Utilities.messagePopup('success', TitlesLabels.alertMessages.successSentPasswordReset);
                props.handleForgotPasswordClosePopUp();

            } else {
                Utilities.messagePopup('error', errorMessageText);
            }


        } else {
            Utilities.messagePopup('error', errorMessageText);
        }



    }


    return (

        <Modal id="popup-forgot-password" show={props.forgotPasswordPopup} onHide={props.handleForgotPasswordClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="popup-forgot-password modal-dialog-theme" >
            <Modal.Header closeButton className={`${Utilities.modalHeaderClass}`}>forgotten password</Modal.Header>
            <Modal.Body className={`${Utilities.modalContentClass}`}>

                <div className='mt-n4'>
                    <div className='mt-3'>enter your email address and we’ll send you a link to reset your password</div>

                    <form className="form-theme text-center pt-4" autoComplete="off">


                        <LoginInputSelection loginType={loginType} formValues={formValues} pushValue={pushValue} changeCountryCode={changeCountryCode} setLoginView={setLoginType} classNameInputLabelAttr="bg-transparent" tabType={2} />

                        {/* <div className="form-group ">
                            <input type="text" name="email_address" className="form-control text-center" placeholder=" " autoFocus autoComplete="off" value={ emailAddress }  onChange={ (e) => pushValue( e, 'email_address')  } ></input>
                            <label className="form-label">email address</label>
                        </div> */}

                        <button type="button" id="btn-submit-send-email" className="btn-theme-green button-has-loader mt-5" onClick={() => submitEmail()}>reset password</button>

                    </form>
                </div>

            </Modal.Body>

        </Modal>
    )
}

export default ForgotPassword;
