import PagesAuthentication from "./PagesAuthentication";
import Utilities from "./Utilities";

export const getInternalProfiles = () => {
    let profiles = [];

    const accountAssociated = PagesAuthentication.getUsersAssociated() ?? {};

    Object.entries(accountAssociated).map(([key, pdetail]) => {
        if (parseInt(pdetail.account_type || 0) === 4) {
            if (parseInt(pdetail.team_internal_chat_status || 0) === 1) {
                profiles.push({
                    ...pdetail,
                    company_name: pdetail.hasOwnProperty('company_detail') ? (pdetail.company_detail.company_name ?? '') : ''
                });
            }
        }
    });

    return profiles;
}


export const getInternalProfileUnreadCounter = (accountList = []) => {
    let profiles = {}, loopId = '';

    if (accountList.length > 0) {
        accountList.map(prof => {
            const ids = prof.id.split('_');
            loopId = `${ids[0]}_${ids[1]}`;

            profiles[loopId] = prof.count.toString();
        });
    }


    return profiles;
}


export const getInternalConversation = async (converDetail = {}, responseConversDetail = {}) => {
    let details = {}, flag = '';
    
    if (Object.keys(converDetail).length > 0 && Object.keys(responseConversDetail).length > 0) {
        flag = converDetail.flag ?? '';

        if (!Utilities.isEmpty(flag) && ['2', '3', '5'].includes(flag)) {

            if (flag == '2') { // group
                details = responseConversDetail.result.internal_group_conversation_details ?? {};
                
            } else if (flag == '3') { // normal
                details = responseConversDetail.result.conversation_details ?? {};

            } else if (flag == '5') { // broadcast
                details = responseConversDetail.result.broadcast_conversation_details ?? {};
            }

        }
    }

    return details;
}