import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import TitlesLabels from '../../helpers/TitlesLabels';
import { pageTitle, showFooter, showHeader } from '../../actions';
import Utilities from '../../helpers/Utilities';

import CampaignPersonalOptionCompanyContent from './CampaignPersonalOptionCompanyContent';
import CampaignPersonalOptionSocialContent from './CampaignPersonalOptionSocialContent';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import CampaignLayout from './CampaignLayout';


const CampaignPersonalOption = () => {

    const dispatch = useDispatch();

    const [campaignType, setCampaignType] = useState(2);

    useEffect(() => {

        let pageSiteTitle = TitlesLabels.siteTitles.businessAccount;
        let compaignTypeTemp = 2;
        let segment = Utilities.getUrlSegments();

        if (segment.length > 0) {

            let lastSegment = segment.slice(-1)[0];
            lastSegment = lastSegment.toLowerCase();

            if (lastSegment == "campaign-personal-user") {
                pageSiteTitle = TitlesLabels.siteTitles.personalAccount;
                compaignTypeTemp = 1;
            }
        }

        setCampaignType(compaignTypeTemp);
        dispatch(pageTitle(pageSiteTitle));
        dispatch(showHeader(0));
        dispatch(showFooter(0));


        // DELETE SOCIAL CONNECT 
        PagesAuthentication.deleteSignupSocialDetail();


    }, []);

    return (
        <CampaignLayout classContent={campaignType == 1 ? 'd-flex align-items-lg-end' : ''} classWrap={campaignType == 2 ? 'h-100' : ''}>

            {campaignType == 2 && <CampaignPersonalOptionCompanyContent />}
            {campaignType == 1 && <CampaignPersonalOptionSocialContent />}

        </CampaignLayout>


    )
}

export default CampaignPersonalOption;