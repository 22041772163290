import React from 'react';
import { Modal } from 'react-bootstrap';
import UrlSlugs from '../../helpers/UrlSlugs';

const DownloadAppPopup = (props) => {


    return (

        <Modal show={props.showPopup} onHide={props.handleClosePopup} animation={false} aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton className="border-bottom-0">
                <h2 className="text-center font-gotham-bold fs-25 w-100 mt-3 text-lowercase">download app</h2>
            </Modal.Header>
            <Modal.Body className="px-5 pb-5">

                <ul className="list-company-detail-option justify-content-around mb-3 mt-2 px-4">
                    <li><a href={UrlSlugs.link_appStore} className="btn-company-detail-icon store-apple" target='_blank'></a></li>
                    <li><a href={UrlSlugs.link_playStore} className="btn-company-detail-icon store-google" target='_blank'></a></li>
                    <li><a href={UrlSlugs.link_appGallery} className="btn-company-detail-icon store-huawei" target='_blank'></a></li>
                </ul>

            </Modal.Body>

        </Modal>
    )
}

export default DownloadAppPopup;
