import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import FieldInput from '../../components/FieldInput';
import QueryUtilities from '../../helpers/QueryUtilities';

import TitlesLabels from '../../helpers/TitlesLabels';
import Utilities from '../../helpers/Utilities';
import { blockWordsAndEmailsValidation } from '../../helpers/VerificationUtilities';
import { getCompanyBranchesByIds, saveCompanyBranchDetail } from '../../helpers/QueryCompanyBranch';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import UrlSlugs from '../../helpers/UrlSlugs';
import { uploadCompanyBranchImage } from '../../helpers/AWSUpload';

import { reloadProfile, showLoader as showLoaderReducer } from '../../actions';
import MobileSelection from '../../components/MobileSelection';
import { ComanySocialLinksManage } from '../../components/common/CompanySocialLinks';



const BranchCompanyInformation = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const propsMainBranchDetail = props.mainBranchDetail ?? {};
    const propsLoginDetail = props.loginDetail ?? PagesAuthentication.getMasterUserDetailDetail();
    const propsBranchId = parseInt(props.branchId ?? 0);


    const defaultLocation = Utilities.defaultLocation();
    const limitCharacters = Utilities.companyAboutWordsLimit;
    const errorMsg = process.env.REACT_APP_message_error_process;

    let defaultFormValues = {
        company_name: '',
        company_website: '',
        category_name: '',

        company_phone: '',
        company_phone_code: defaultLocation.country_id,
        company_phone_checked: 0,

        company_landline: '',
        company_landline_code: defaultLocation.country_id,
        company_landline_checked: 0,

        company_about: '',
        company_keyword: '',
        company_social: ''
    };

    let finalSocialValues = { ...Utilities.socialLinkValues };
    let defaultInfoValues = { company_keywords: [], company_about: '' }

    const formGroupKeywordElem = jQuery('#form-group-keyword');

    const [changeLogoBool, setChangeLogoBool] = useState(false);
    const [oldCompanyAbout, setOldCompanyAbout] = useState('');

    const [formInfoValues, setFormInfoValues] = useState(defaultInfoValues);
    const [formInfoValuesOld, setFormInfoValuesOld] = useState(defaultInfoValues);

    const [formSocialValues, setFormSocialValues] = useState(finalSocialValues);
    const [formSocialValuesOld, setFormSocialValuesOld] = useState(finalSocialValues);

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [formValuesOld, setFormValuesOld] = useState(defaultFormValues);

    const [profileLogo, setProfileLogo] = useState('');
    const [profileLogoOld, setProfileLogoOld] = useState('');

    const [fileExtension, setFileExtension] = useState('');
    const [countryList, setCountryList] = useState([]);

    const [profileLogoValue, setProfileLogoValue] = useState('');
    const [showSection, setShowSection] = useState('');

    const [showButtonOption, setShowButtonOption] = useState(false);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;
        let errorCounter = 0;

        if (fieldName === 'company_name') {
            formValuesTemp = { ...formValuesTemp, ...{ company_name: value } };
        } else if (fieldName === 'company_display_name') {
            formValuesTemp = { ...formValuesTemp, ...{ company_display_name: value } };
        } else if (fieldName === 'company_email') {
            formValuesTemp = { ...formValuesTemp, ...{ company_email: value } };

        } else if (fieldName === 'category_name') {
            formValuesTemp = { ...formValuesTemp, ...{ category_name: value } };
        } else if (fieldName === 'company_website') {
            formValuesTemp = { ...formValuesTemp, ...{ company_website: value } };

        } else if (fieldName === 'company_landline') {

            let company_landline = value.replace(' ', '');
            if (!Utilities.isEmpty(company_landline) && !Utilities.contactNumberValidate(company_landline, 1).status) {
                Utilities.addFormGroupClass(fieldName);
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ company_landline: parseInt(company_landline) || '' } };

        } else if (fieldName === 'company_landline_code') {
            formValuesTemp = { ...formValuesTemp, ...{ company_landline_code: value.toString().trim() } };

        } else if (fieldName === 'company_phone') {

            let company_phone = value.replace(' ', '');
            if (!Utilities.contactNumberValidate(company_phone, 1).status) {
                Utilities.addFormGroupClass(fieldName);
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ company_phone: parseInt(company_phone) || '' } };

        } else if (fieldName === 'company_phone_code') {
            formValuesTemp = { ...formValuesTemp, ...{ company_phone_code: value.toString().trim() } };

        } else if (fieldName === 'company_phone_checked') {
            value = parseInt(formValuesTemp.company_phone_checked) === 1 ? 0 : 1;
            formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked: value } };
        } else if (fieldName === 'company_landline_checked') {

            value = parseInt(formValuesTemp.company_landline_checked) === 1 ? 0 : 1;
            formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked: value } };

        } else if (fieldName === 'company_info') {
            formValuesTemp = { ...formValuesTemp, ...{ company_info: value } };

        } else if (fieldName === 'company_keyword') {
            formValuesTemp = { ...formValuesTemp, ...{ company_keyword: value } };

        } else if (fieldName === 'company_social') {
            formValuesTemp = { ...formValuesTemp, ...{ company_social: value } };
        }

        if (errorCounter === 0) {
            Utilities.removeFormGroupClass(fieldName);
        }

        setFormValues(formValuesTemp);

        if (!Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked'] && value === 1) {
            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.mobileActivationWarningMessage,
                overlayClassName: 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmContactNumber(fieldName, 0) },
                    { label: 'continue', onClick: () => confirmContactNumber(fieldName, 1) }
                ],
                onClickOutside: () => confirmContactNumber(fieldName, 0),
                onKeypressEscape: () => confirmContactNumber(fieldName, 0),
            });
        }
    }

    const selectImage = () => {
        jQuery('input#input_select_file').trigger('click');
    }


    const previewImage = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            var reader = new FileReader();
            reader.onload = function (e) {

                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setProfileLogo(e.target.result);
                    setFileExtension(Utilities.get_file_extension(value));
                    setChangeLogoBool(true);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setProfileLogoValue(e.target.files[0]);

        } else {
            jQuery(e.target).val('');
            setFileExtension('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }



    const confirmContactNumber = (fieldName = '', value = 0) => {

        if (!Utilities.isEmpty(fieldName) && ['company_phone_checked', 'company_landline_checked']) {
            let formValuesTemp = formValues;

            if (fieldName === 'company_phone_checked') {
                formValuesTemp = { ...formValuesTemp, ...{ company_phone_checked: parseInt(value) } };

            } else if (fieldName === 'company_landline_checked') {
                formValuesTemp = { ...formValuesTemp, ...{ company_landline_checked: parseInt(value) } };
            }
            setFormValues(formValuesTemp);
        }
    }


    const getCountries = async (setupContact = {}) => {
        const countryListDb = await QueryUtilities.countryList();
        setCountryList(countryListDb);
    }


    const changeCountryCodeLandline = (passValues, fileName = '') => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_landline_code: passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }

    const changeCountryCodeMobile = (passValues, fileName = '') => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ company_phone_code: passValues.id.toString().trim() } };
        setFormValues(formValuesTemp);
    }


    /// SOCIAL ///////////////////////////////////////////////////////////
    const submitCompanySocial = (socialLinksValues = {}) => {

        setFormSocialValues({ ...socialLinksValues });
        setShowSection('');
        props.updateSocialOptionManage(false);

        let formValuesTemp = { ...formValues, company_social: '' };
        let concatSocialText = Object.values(socialLinksValues).filter(val => val !== '').join('|');
        concatSocialText = concatSocialText.trim();

        if (!Utilities.isEmpty(concatSocialText)) {
            formValuesTemp = { ...formValues, company_social: 'view' };
        }
        setFormValues(formValuesTemp);
    }


    /// ABOUT ///////////////////////////////////////////////////////////
    const cancelAboutContent = () => {
        setShowSection('');
        setFormInfoValues({ ...formInfoValues, company_about: oldCompanyAbout });
        formGroupKeywordElem.removeClass('error');
    }

    const submitCompanyAbout = () => {

        let formValuesTemp = { ...formValues, company_about: '' };

        const charCounter = Utilities.wordsCounter(formInfoValues.company_about);
        if (parseInt(charCounter) > limitCharacters) {
            Utilities.messagePopup('error', `Only ${limitCharacters} words are allowed!`);
            return false;
        }

        if (!Utilities.isEmpty(formInfoValues.company_about)) {
            formValuesTemp = { ...formValues, company_about: 'view' };
        }

        setOldCompanyAbout(formInfoValues.company_about);

        setFormValues(formValuesTemp);
        setShowSection('');
    }

    const handleShowSectionAbout = () => {
        setShowSection('company-info');
    }

    /// KEYWORD ///////////////////////////////////////////////////////////

    const pushKeywordValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let elem = jQuery(`input[name="${fieldName}"]`),
            val = elem.val();

        elem.val(val.replace(' ', ''));

        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);
    }


    const cancelKeywordContent = () => {
        setShowSection('');
        formGroupKeywordElem.removeClass('error');
    }


    const extracyCompanyKeywords = (keywordsProvided = {}) => {
        setTimeout(() => {
            const formKeywordElem = jQuery('#form-keyword-info');
            Object.entries(keywordsProvided).map(([key, keyword]) => {
                formKeywordElem.find('input[name="keyword_' + (parseInt(key) + 1) + '"]').val(keyword);
            });
        }, 200);
    }

    const setUpkeywordContent = () => {

        // USE JQUERY FOR KEYWORD ----------------------------------------
        extracyCompanyKeywords(formInfoValues.company_keywords);
        // ----------------------------------------

        setShowSection('keyword-info');
    }

    const submitCompanyKeyword = async () => {

        let keywordLoop = '', keywordJoined = [], keywordError = 0, formKeywordElem = jQuery('#form-keyword-info'),
            button_name = 'btn-submit-keywords', formValuesTemp = { ...formValues, company_keyword: '' };

        formKeywordElem.find('input').each(function () {
            keywordLoop = jQuery(this).val();
            if (!Utilities.isEmpty(keywordLoop)) {
                keywordLoop = keywordLoop.trim();

                if (!Utilities.checkSpecialChar(keywordLoop)) {
                    keywordJoined.push(keywordLoop.toLowerCase());
                    jQuery(this).closest('.form-group').removeClass('error');
                } else {
                    jQuery(this).closest('.form-group').addClass('error');
                    keywordError++;
                }
            }
        });


        if (keywordError > 0) {
            Utilities.messagePopup('Error', TitlesLabels.general.specialCharactersNotAllowed);
            return false;
        }

        if (keywordJoined.length < 3) {
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.threeKeywordRequired);
            return false;
        }

        if (keywordJoined.length >= 3) {
            let wordsRequestArray = keywordJoined;

            Utilities.elementDisabledStatus(button_name, true);
            const responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('3', wordsRequestArray);
            Utilities.elementDisabledStatus(button_name, false);

            if (parseInt(responseRestrictedValidationsDB.status) === 0) {
                Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_someKeyword);
                setFormValues(formValuesTemp);
                return false;
            }

            formValuesTemp = { ...formValues, company_keyword: 'view' };
        }

        setFormValues(formValuesTemp);
        setFormInfoValues({ ...formInfoValues, company_keywords: keywordJoined });

        setShowSection('');
    }


    const submitConfirmation = () => {

        // IF CREATE MODE
        if (propsBranchId === 0) {
            submitDetail(false);
            return false;
        }

        // CHECK DIFFERENCE /////////////////////////////////////
        let differenceCounter = 0, changeFieldsValues = {}, socialChangeCounter = 0, infoKeywordsChangeCounter = 0;

        Object.entries(formValues).map(([key, metaValue]) => {
            if (formValuesOld.hasOwnProperty(key)) {
                if (metaValue != formValuesOld[key]) {
                    differenceCounter++;
                    changeFieldsValues[key] = metaValue;
                    //console.log(key);
                }
            }
        });

        Object.entries(formSocialValues).map(([key, metaValue]) => {
            if (formSocialValuesOld.hasOwnProperty(key)) {
                if (metaValue != formSocialValuesOld[key]) {
                    differenceCounter++;
                    socialChangeCounter++;
                    //console.log(key);
                }
            }
        });

        Object.entries(formInfoValues).map(([key, metaValue]) => {
            if (formInfoValuesOld.hasOwnProperty(key)) {
                if (metaValue != formInfoValuesOld[key]) {

                    if (key === 'company_keywords') {
                        //console.log( metaValue.join('|')+'---'+formInfoValuesOld[key].join('|') );

                        if (metaValue.join('|') != formInfoValuesOld[key].join('|')) {
                            differenceCounter++;
                            infoKeywordsChangeCounter++;
                        }
                    } else {
                        //console.log(key);
                        changeFieldsValues[key] = metaValue;
                    }
                }
            }
        });



        if (socialChangeCounter > 0) {
            let socialFieldValues = Object.entries(formSocialValues).filter(val => val).join('|');
            changeFieldsValues['company_social'] = socialFieldValues;
        }

        if (infoKeywordsChangeCounter > 0) {
            let companyKeywords = formInfoValues.company_keywords.length > 0 ? formInfoValues.company_keywords.join('|') : '';
            changeFieldsValues['company_keywords'] = companyKeywords;
        }

        if (changeLogoBool) { differenceCounter++ }

        if (differenceCounter > 0) {

            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.wishToUpdateBranches,
                overlayClassName: 'normal-font-style warning',
                buttons: [
                    { label: 'yes', onClick: () => submitDetail(true, changeFieldsValues) },
                    { label: 'no', onClick: () => submitDetail(false) }
                ],
                /* onClickOutside: () => submitDetail( false),
                onKeypressEscape: () => submitDetail( false), */
            });

        } else {
            submitDetail(false);
        }
    }

    const submitDetail = async (updateBranchesBool = false, changeFieldsValues = {}) => {


        let errorCounter = 0, responseChecker = false;
        let formValuesTemp = formValues;//, formInfoValuesTemp = formInfoValues, formSocialValuesTemp = formSocialValues ;
        //console.log(formValuesTemp, formInfoValuesTemp, formSocialValuesTemp);

        const button_name = 'btn-submit-detail';
        const formData = {};

        formGroupKeywordElem.removeClass('error');

        const requiredFields = ['company_name', 'company_landline_code', 'company_phone_code', 'category_name', 'company_keyword'];
        const saveFields = ['company_name', 'company_website', 'company_landline', 'company_landline_code', 'company_phone_code', 'company_phone'];


        requiredFields.map(key => {
            const value = jQuery('input[name="' + key + '"]').val();
            if (Utilities.isEmpty(value)) {
                Utilities.addFormGroupClass(key);
                errorCounter++;
            } else {
                formData[key] = value;
            }

            if (['company_name'].includes(key)) {
                responseChecker = Utilities.inputCharacterChecker(value, 'general-companyname');
                if (responseChecker === false) {
                    Utilities.addFormGroupClass(key);
                    errorCounter++;
                }
            }
        });



        if (!Utilities.isEmpty(formValuesTemp.company_landline) && !Utilities.contactNumberValidate(formValuesTemp.company_landline)) {
            Utilities.addFormGroupClass(`company_landline`);
            errorCounter++;
        }

        if (!Utilities.contactNumberValidate(formValuesTemp.company_phone)) {
            Utilities.addFormGroupClass(`company_phone`);
            errorCounter++;
        }


        if (errorCounter > 0) { return false; }


        // RESTRICTED WORDS VALIDATION
        // TYPES : 1=>email, 2=>name, 3=>keywords
        let wordsRequestArray = [];
        wordsRequestArray.push(formValues.company_name);

        Utilities.elementDisabledStatus(button_name, true);
        let responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('2', wordsRequestArray);
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(responseRestrictedValidationsDB.status) === 0) {
            Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_name);
            Utilities.addFormGroupClass(`company_name`);
            return false;
        }
        // END RESTRICTED WORDS VALIDATION



        saveFields.map(key => {
            const value = jQuery('input[name="' + key + '"]').val();
            formData[key] = value;
        });

        const logo_value = document.getElementById('input_select_file').value;
        const timeStamp = moment().unix(), fileName = `${timeStamp}.${fileExtension}`;
        let generatedBranchId = propsBranchId;

        formData['update_branches'] = updateBranchesBool ? 1 : 0;
        formData['branch_id'] = generatedBranchId;
        formData['company_keywords'] = formInfoValues.company_keywords.length > 0 ? formInfoValues.company_keywords.join('|') : '';
        formData['company_about'] = formInfoValues.company_about;
        formData['company_phone_checked'] = parseInt(formValuesTemp.company_phone_checked || 0);
        formData['company_landline_checked'] = parseInt(formValuesTemp.company_landline_checked || 0);

        let socialFieldValues = Object.entries(formSocialValues).filter(val => val).join('|');
        formData['company_social'] = socialFieldValues;

        if (!Utilities.isEmpty(logo_value) && !Utilities.isEmpty(profileLogoValue)) {
            formData['company_logo'] = Utilities.isEmpty(fileExtension) ? '' : fileName; //profileLogoValue
        }

        formData['master_account_id'] = propsLoginDetail.account_id ?? 0;
        formData['update_fields'] = changeFieldsValues;

        dispatch(showLoaderReducer(1));
        Utilities.elementDisabledStatus(button_name, true);
        const response_companyBranchInsertion = await saveCompanyBranchDetail(formData);

        dispatch(showLoaderReducer(0));
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(response_companyBranchInsertion.status) === 0) {
            Utilities.messagePopup('error', errorMsg);
            return false;
        }

        generatedBranchId = response_companyBranchInsertion.id ?? 0;

        // RELOAD THE SETTING PROFILE POPUP
        dispatch(reloadProfile(Date.now()));


        // SETUP OLD VALUES
        setFormValuesOld(formValues);
        setFormInfoValuesOld(formInfoValues);
        setFormSocialValuesOld(formSocialValues);



        if (!Utilities.isEmpty(logo_value) && !Utilities.isEmpty(profileLogoValue)) {

            dispatch(showLoaderReducer(1));
            Utilities.elementDisabledStatus(button_name, true);

            const formLogoData = new FormData();
            formLogoData.append('account_id', formData.master_account_id);
            formLogoData.append('file', profileLogoValue);
            formLogoData.append('filename', timeStamp);
            //formLogoData.append('filename', fileName );

            formLogoData.append('update_branches', changeLogoBool ? 1 : 0);


            const responseUpload = await uploadCompanyBranchImage(formLogoData);
            Utilities.elementDisabledStatus(button_name, false);
            dispatch(showLoaderReducer(0));

            jQuery('input#input_select_file').val('');
            setProfileLogoValue('');

            setProfileLogo(responseUpload.file_detail.url ?? '');
            setProfileLogoOld(responseUpload.file_detail.url ?? '');

            if (parseInt(responseUpload.status) === 1) {


                //history.push(`/${UrlSlugs.companyBranch_manageBranch}/${btoa(generatedBranchId)}`);
                redirectSaveUpdateAction(generatedBranchId);
                return false;

            }
        }


        //history.push(`/${UrlSlugs.companyBranch_manageBranch}/${btoa(generatedBranchId)}`);
        redirectSaveUpdateAction(generatedBranchId);
    }


    const redirectSaveUpdateAction = (branchId = 0) => {

        history.push(`/${UrlSlugs.companyBranch_manageBranch}/${btoa(branchId)}`);

        if (propsBranchId === 0) {
            //history.push(`/${UrlSlugs.companyBranch_manageBranch}/${btoa(branchId)}`);
        } else {
            Utilities.messagePopup('success', TitlesLabels.alertMessages.profileUpdatedSuccessfully);
        }
    }


    const handleOnclickEditOption = (action = true) => {
        setChangeLogoBool(false);
        setShowButtonOption(action);

        if (action === false) {

            // RETURN BACK OLD VALUES
            setFormValues(formValuesOld);
            setFormInfoValues(formInfoValuesOld);
            setFormSocialValues(formSocialValuesOld);
            setProfileLogo(profileLogoOld);

            jQuery('.form-group').removeClass('error');

            getCountries({ company_landline_code: formValuesOld.company_landline_code, company_phone_code: formValuesOld.company_phone_code });
        }
    }


    const setupBranchDetail = async () => {

        if (Object.keys(propsMainBranchDetail).length > 0) {
            const responseBranchList = await getCompanyBranchesByIds(propsBranchId.toString());
            //const responseBranchList = propsMainBranchDetail;

            const companyDetail = responseBranchList.list[0].company_info ?? {};

            let socialLinksTemp = { ...formSocialValues };
            if (companyDetail.hasOwnProperty('company_social')) {

                let profileSocialLinks = companyDetail.company_social ?? {};
                Object.entries(formSocialValues).map(([skey, sval]) => {
                    socialLinksTemp[skey] = profileSocialLinks[skey] ?? '';
                });

                Object.entries(profileSocialLinks).map(([skey, sval]) => {
                    if (!formSocialValues.hasOwnProperty(skey)) {
                        socialLinksTemp[skey] = sval ?? '';
                    }
                });
            }

            setFormSocialValues(socialLinksTemp);
            setFormSocialValuesOld(socialLinksTemp);


            setProfileLogo((companyDetail.company_logo ?? '') === '' ? '' : companyDetail.logo);
            setProfileLogoOld((companyDetail.company_logo ?? '') === '' ? '' : companyDetail.logo);

            let concatSocialText = Object.values(socialLinksTemp).filter(val => val !== '').join('|');
            concatSocialText = concatSocialText.trim();

            const formValuesTemp = {
                ...formValues,
                company_name: companyDetail.company_name ?? '',
                company_website: companyDetail.company_website ?? '',
                category_name: companyDetail.category_name ?? '',

                company_phone: companyDetail.company_phone ?? '',
                company_phone_code: companyDetail.company_phone_code ?? '',
                company_phone_checked: parseInt(companyDetail.mobile_number_status || 0),

                company_landline: companyDetail.company_landline ?? '',
                company_landline_code: companyDetail.company_landline_code ?? '',
                company_landline_checked: parseInt(companyDetail.landline_number_status || 0),

                company_about: (companyDetail.company_about ?? '') === '' ? '' : 'view',
                company_keyword: (companyDetail.company_keywords ?? []).length === 0 ? '' : 'view',
                company_social: Utilities.isEmpty(concatSocialText) ? '' : 'view'
            };

            setOldCompanyAbout(companyDetail.company_about ?? '');

            //console.log(defaultCompanyAbout, companyDetail);
            setFormValues(formValuesTemp);
            setFormValuesOld(formValuesTemp);


            const formInfoValuesTemp = {
                ...formInfoValues,
                company_keywords: (companyDetail.company_keywords ?? []).length === 0 ? [] : companyDetail.company_keywords,
                company_about: companyDetail.company_about ?? ''
            };

            //console.log(formInfoValuesTemp);
            setFormInfoValues(formInfoValuesTemp);
            setFormInfoValuesOld(formInfoValuesTemp);

            extracyCompanyKeywords(formInfoValuesTemp.company_keywords);
            getCountries({ company_landline_code: formValuesTemp.company_landline_code, company_phone_code: formValuesTemp.company_phone_code });
        }



    }



    const htmlContent = () => {
        if (showSection === 'company-social') {

            return <section className="w-100 mt-4">
                <div className="container py-5">

                    <ComanySocialLinksManage submitCompanySocial={submitCompanySocial} socialLinksValue={formSocialValues} />

                </div>
            </section>

        } else if (showSection === 'company-info') {

            return <section className="col-sm-8 mx-auto pt-3 form-theme text-lowercase mt-5">

                <div id="form-company-info">

                    <div className="form-group">
                        <textarea id="company_about" name="company_about" className="form-control" rows="10" autoFocus placeholder=" " value={formInfoValues.company_about} onChange={(e) => setFormInfoValues({ ...formInfoValues, ...{ company_about: e.target.value } })}></textarea>
                        <label className="form-label">about your business</label>
                    </div>

                    <div className="d-flex align-items-center justify-content-center text-color-gray text-center fs-14-important">
                        <div>
                            <div className="text-color-blue cursor-pointer" onClick={() => setFormInfoValues({ ...formInfoValues, company_about: '' })}>clear</div>
                            <div>maximum {Utilities.number_format(limitCharacters.toString())} words</div>
                        </div>
                    </div>

                    <div className="mt-5 mb-5 col-md-8 mx-auto button-group-boolean">
                        <button type="button" className="btn-theme-black" onClick={() => cancelAboutContent()}>cancel</button>
                        <button type="button" className="btn-theme-black" onClick={() => submitCompanyAbout()}>submit</button>
                    </div>

                </div>

            </section>

        } else if (showSection === 'keyword-info') {

            return <section className="col-sm-5 mx-auto pt-3 form-theme text-lowercase mt-5">

                <div id="form-keyword-info" >
                    <div className="mb-2  font-gotham-bold fs-18 fs-md-18 pb-3">add keyword</div>

                    {
                        [1, 2, 3, 4, 5].map(key =>
                            <div key={key} className="form-group ">
                                <input type="text" name={`keyword_${key.toString()}`} className="form-control" autoFocus={key === 1 ? true : false} placeholder=" " onChange={(e) => pushKeywordValue(e, 'keyword_' + key.toString())} autoComplete="off" ></input>
                                <label className="form-label">keyword</label>
                            </div>
                        )
                    }

                    <div className="mt-5 pt-5 button-group-boolean">
                        <button type="button" className="btn-theme-black" onClick={() => cancelKeywordContent()}>cancel</button>
                        <button id="btn-submit-keywords" className="btn-theme-black button-has-loader" onClick={() => submitCompanyKeyword()}>submit</button>
                    </div>

                </div>
            </section>
        } else {

            return <>
                <input type="file" id="input_select_file" name="file" className="invisible" accept={Utilities.attributeImageAcceptExtension} readOnly={true} onChange={(e) => previewImage(e)} ></input>

                <div className="avatar-wrap avatar-md-wrap mt-n4">
                    <div className={`avatar-preview border border logo-size-200 logo-size-sm-200 mx-auto`} style={{ backgroundImage: `url(${profileLogo})` }} ></div>

                    {
                        showButtonOption ?
                            <div className='profile-logo-change text-center'>
                                <button type="button" className="btn-select-file bg-theme-green font-gotham-bold border-radius text-white outline-none border-0 fs-16 mx-auto mt-3 py-2 px-4" onClick={() => selectImage()}>{propsBranchId > 0 ? `change` : `select`}</button>
                                <div className="fs-13-important text-center pt-3 color-theme-grey">{Utilities.labelUploadSpecs}</div>
                            </div>
                            :
                            <div className="fs-13-important text-center pt-3 color-theme-grey">{Utilities.labelUploadSpecs}</div>

                    }


                </div>

                <form id="form-company-info" className={`form-theme pt-${showButtonOption === false ? '5' : '4'}`} autoComplete="off" method="POST">

                    <input type="hidden" id="input_company_phone_code" name="company_phone_code" readOnly value={formValues.company_phone_code} onChange={(e) => pushValue(e, 'company_phone_code')} ></input>
                    <input type="hidden" id="input_company_landline_code" name="company_landline_code" readOnly value={formValues.company_landline_code} onChange={(e) => pushValue(e, 'company_landline_code')} ></input>


                    <div className={`form-col-2 gap-none  ${showButtonOption === false ? 'content-disabled' : ''}`}>
                        <div>
                            <FieldInput inputName="company_name" inputLabel="Company Name" inputValue={formValues.company_name ?? ''} pushValue={pushValue} checkerType="general-companyname" />
                        </div>
                        <div>
                            <div className="form-group optional optional-category">
                                <input type="text" name="category_name" className="form-control" placeholder=" " autoComplete="off" value={formValues.category_name ?? ''} onChange={(e) => pushValue(e, 'category_name')} onKeyDown={(e) => e.key === 'Tab' && handleShowSectionAbout()}  ></input>
                                <label className="form-label">Business Type</label>
                                <div className="optional-label">e.g. airline, restaurant, etc.</div>
                            </div>
                        </div>


                        <div>
                            <MobileSelection inputValue={formValues.company_landline} countryListProvided={countryList} inputLabel="Landline Number" countryCodeProvided={formValues.company_landline_code} inputListingId="landline-input-list" inputName="company_landline" pushValue={pushValue} placeholderExtra={`(optional)`} changeCountryCode={changeCountryCodeLandline} extraClasses={`has-off-on`}>
                                <input type="checkbox" className="checkbox-onoff" value={formValues.company_landline_checked} checked={formValues.company_landline_checked === 1} onChange={(e) => pushValue(e, 'company_landline_checked')} title={TitlesLabels.general.chooseNumberRefectOnBusiness ?? ''}></input>
                            </MobileSelection>
                        </div>

                        <div>
                            <MobileSelection inputValue={formValues.company_phone} countryListProvided={countryList} inputLabel="Mobile Number" countryCodeProvided={formValues.company_phone_code} inputListingId="mobile-input-list" inputName="company_phone" pushValue={pushValue} placeholderExtra={`(mandatory)`} changeCountryCode={changeCountryCodeMobile} extraClasses={`has-off-on`}>
                                <input type="checkbox" className="checkbox-onoff" value={formValues.company_phone_checked} checked={formValues.company_phone_checked === 1} onChange={(e) => pushValue(e, 'company_phone_checked')} title={TitlesLabels.general.chooseNumberRefectOnBusiness}></input>
                            </MobileSelection>

                        </div>

                        <div>
                            <div className="form-group optional ">
                                <input type="text" name="company_website" className="form-control" placeholder=" " autoComplete="off" value={formValues.company_website} onChange={(e) => pushValue(e, 'company_website')} onKeyDown={(e) => Utilities.onKeyDownPreventSpace(e)}  ></input>
                                <label className="form-label">Company Website</label>
                                <div className="optional-label">Optional</div>
                            </div>
                        </div>




                        <div>
                            <div className="form-group content-disabled cursor-pointer cursor-pointer optional" onClick={() => handleShowSectionAbout()}>
                                <input type="text" name="company_about" className="form-control" placeholder=" " autoComplete="off" readOnly value={formValues.company_about ?? ''}></input>
                                <label className="form-label">Company Info</label>
                                <div className="optional-label">Optional</div>
                            </div>
                        </div>


                        <div>
                            <div id="form-group-keyword" className="form-group content-disabled cursor-pointer" onClick={() => setUpkeywordContent()}>
                                <input type="text" name="company_keyword" className="form-control" placeholder=" " autoComplete="off" readOnly value={formValues.company_keyword ?? ''}></input>
                                <label className="form-label">Keyword Info</label>
                            </div>
                        </div>


                        <div>
                            <div className="form-group content-disabled optional cursor-pointer" onClick={() => { setShowSection('company-social'); props.updateSocialOptionManage(true); }}>
                                <input type="text" name="company_social" className="form-control" placeholder=" " autoComplete="off" readOnly value={formValues.company_social ?? ''}></input>
                                <label className="form-label">Social Media</label>
                                <div className="optional-label">Optional</div>
                            </div>
                        </div>




                    </div>


                    <div className="button-wrap text-lowercase text-center my-5  col-md-6 mx-auto">
                        {
                            propsBranchId === 0 ?
                                <button id="btn-submit-detail" type="button" className="btn-theme-black button-has-loader" onClick={() => submitConfirmation()}>submit</button>
                                :
                                <>
                                    {
                                        showButtonOption === false ?
                                            <button type="button" className="btn-theme-black" onClick={() => handleOnclickEditOption(true)}>edit</button>
                                            :
                                            <div className="button-group-boolean">
                                                <button type="button" className="btn-theme-black" onClick={() => handleOnclickEditOption(false)} >cancel</button>
                                                <button id="btn-submit-detail" type="button" className="btn-theme-black button-has-loader" onClick={() => submitConfirmation()}>update</button>
                                            </div>
                                    }
                                </>
                        }

                    </div>

                </form>
            </>
        }

    }

    useEffect(() => {

        if (Object.keys(propsMainBranchDetail).length > 0) {
            setupBranchDetail();
            setShowButtonOption(false);
        } else {
            getCountries();
            setShowButtonOption(true);
        }

    }, [propsMainBranchDetail]);

    return (
        <section className="w-100  fs-18">
            {htmlContent()}

        </section>
    )
}

export default BranchCompanyInformation;