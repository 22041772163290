import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import MainSearchProfile from "./MainSearchProfile";

import { showDirectoryProfilePopup } from "../../actions";


const DirectorySearchProfile = () => {

    const dispatch = useDispatch();

    const showDirectoryProfilePopupSelector = useSelector(state => state.showDirectoryProfilePopup);

    const [show, setShow] = useState(false);
    const [profileSelected, setProfileSelected] = useState({});

    const handleClose = (totallyClose = false) => {
        setProfileSelected({});
        setShow(false);
        dispatch(showDirectoryProfilePopup({}));
    }


    useEffect(() => {
        if (Object.keys(showDirectoryProfilePopupSelector).length > 0) {
            setProfileSelected(showDirectoryProfilePopupSelector);
            setShow(true);
        }

    }, [showDirectoryProfilePopupSelector]);

    return (
        <MainSearchProfile handleShowProfilePopup={show} handleCloseProfilePopup={handleClose} profileSelected={profileSelected} showStatus={3} closeSearchBox={handleClose} />

    )
}

export default DirectorySearchProfile;