import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import jQuery from 'jquery';
import axios from 'axios';
import moment from 'moment';


import { askPermissionToReceiveNotifications } from '../components/firebase/PushNotification';
import CryptoJS from 'crypto-js';

import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import { uploadProfileLogo } from '../helpers/AWSUpload';

import { showHeader, showFooter, pageTitle } from '../actions';

import FieldInput from '../components/FieldInput';
//import PasswordInput from '../components/PasswordInput';
import QueryUtilities from '../helpers/QueryUtilities';
import SignupHeader from '../layout/SignupHeader';
import { blockWordsAndEmailsValidation } from '../helpers/VerificationUtilities';
import { redirectToDownloadAppFromCampaignCompanySignup } from '../helpers/UtilitiesCampaign';
import SessionUtilities from '../helpers/SessionUtilities';
import MobileSelection from '../components/MobileSelection';
import PagesAuthentication from '../helpers/PagesAuthentication';

const CompanyTeamMember = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const errorMsg = process.env.REACT_APP_message_error_process;

    let defaultFormValues = {
        team_first_name: '',
        team_last_name: '',
        team_email: '',
        //password : '',
        //password_confirm : '',

        team_department: 'management',
        team_position: 'owner',

        team_internal_chat_status: 1,
        team_is_listing: 1,
        team_external_availability: 0,

        team_contact_number_code_id: 0,
        team_contact_number: '',
        team_contact_number_status: 0,
    }

    //const defaultLocation = Utilities.defaultLocation();

    const notificationValuesDefault = {
        fcm_token: '', utc_date: '', brower_id: ''
    }

    const [notificationValues, setNotificationValues] = useState(notificationValuesDefault);

    const [formValues, setFormValues] = useState(defaultFormValues);
    const [domainName, setDomainName] = useState('');
    const [personalAccount, setPersonalAccount] = useState({});
    const [companyAccount, setCompanyAccount] = useState({});
    const [logoProfile, setLogoProfile] = useState('');
    const [logoProfileValue, setLogoProfileValue] = useState('');
    const [accountID, setAccountID] = useState(0);

    const [countries, setCountries] = useState([]);

    const selectImage = () => {
        jQuery('input#input_select_file').trigger('click');
    }

    const previewImage = (e) => {
        let value = e.target.value;
        let elem = e.target;

        if (Utilities.validate_image_ext(value)) {

            var reader = new FileReader();
            reader.onload = function (e) {

                var img = new Image();
                img.src = e.target.result;

                img.onload = function () {

                    // Check the image resolution
                    if (this.width < Utilities.minImageResolution.height || this.height < Utilities.minImageResolution.height) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.lowMsg);
                        jQuery(elem).val('');
                        return false;
                    }
                    if (this.width > Utilities.minImageResolution.width || this.height > Utilities.minImageResolution.width) {
                        Utilities.messagePopup('Error', Utilities.minImageResolution.highMsg);
                        jQuery(elem).val('');
                        return false;
                    }

                    setLogoProfileValue(e.target.result);
                    //setLogoProfile(e.target.result);
                }
            };

            reader.readAsDataURL(e.target.files[0]);
            setLogoProfile(e.target.files[0]);

        } else {
            jQuery(e.target).val('');
            Utilities.messagePopup('Error', Utilities.labelInvalidFileExtension);
        }
    }


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let errorCounter = 0, formValuesTemp = formValues;

        if (fieldName === 'team_first_name') {
            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ team_first_name: value } };
        } else if (fieldName === 'team_last_name') {
            value = Utilities.removedEmojis(value);
            formValuesTemp = { ...formValuesTemp, ...{ team_last_name: value } };
        } else if (fieldName === 'team_email') {
            formValuesTemp = { ...formValuesTemp, ...{ team_email: value } };
            /* }else if ( fieldName==='password'){
                formValuesTemp = { ...formValuesTemp, ...{ password : value } };
    
            }else if ( fieldName==='password_confirm'){
                formValuesTemp = { ...formValuesTemp, ...{ password_confirm : value } }; */

        } else if (fieldName === 'team_department') {
            formValuesTemp = { ...formValuesTemp, ...{ team_department: value } };
        } else if (fieldName === 'team_position') {
            formValuesTemp = { ...formValuesTemp, ...{ team_position: value } };

        } else if (fieldName === 'team_contact_number') {

            let team_contact_number = value.replace(' ', '');
            if (!Utilities.isEmpty(team_contact_number) && !Utilities.contactNumberValidate(team_contact_number)) {
                //jQuery('[name="'+fieldName+'"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(fieldName);
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ team_contact_number: parseInt(team_contact_number) || '' } };

        } else if (fieldName === 'team_contact_number_status') {
            value = parseInt(formValuesTemp.team_contact_number_status) === 1 ? 0 : 1;

            if (value === 1 && Utilities.isEmpty(formValues.team_contact_number || '')) {
                Utilities.messagePopup('Warning', TitlesLabels.alertMessages.mobileNumberShouldNotBeEmpty);
                //jQuery('[name="team_contact_number"]').closest('.form-group').addClass('error');
                value = 0;
                errorCounter++;
            }
            formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_status: value } };
        }


        if (errorCounter === 0) {
            //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
            Utilities.removeFormGroupClass(fieldName);
        }

        setFormValues(formValuesTemp);

        if (!Utilities.isEmpty(fieldName) && ['team_contact_number_status'] && value === 1) {
            confirmAlert({
                title: 'warning',
                message: TitlesLabels.alertMessages.mobileTeamActivationWarningMessage,
                overlayClassName: 'normal-font-style warning',
                buttons: [
                    { label: 'close', onClick: () => confirmContactNumber(fieldName, 0) },
                    { label: 'continue', onClick: () => confirmContactNumber(fieldName, 1) }
                ],
                onClickOutside: () => confirmContactNumber(fieldName, 0),
                onKeypressEscape: () => confirmContactNumber(fieldName, 0),
            });
        }
    }

    const confirmContactNumber = (fieldName = '', value = 0) => {

        if (!Utilities.isEmpty(fieldName) && ['team_contact_number_status']) {
            let formValuesTemp = formValues;

            if (fieldName === 'team_contact_number_status') {
                formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_status: parseInt(value) } };

            }
            setFormValues(formValuesTemp);
        }
    }

    const pushValueVisiblity = (fieldName) => {

        if (!Utilities.isEmpty(fieldName)) {

            let formValuesTemp = formValues;
            if (fieldName === 'team_is_listing') {
                let valueTemp = (parseInt(formValuesTemp.team_is_listing) === 0) ? 1 : 0;
                formValuesTemp = { ...formValuesTemp, ...{ team_is_listing: valueTemp } };

            } else if (fieldName === 'team_internal_chat_status') {
                let valueTemp = (parseInt(formValuesTemp.team_internal_chat_status) === 0) ? 1 : 0;
                formValuesTemp = { ...formValuesTemp, ...{ team_internal_chat_status: valueTemp } };

            } else if (fieldName === 'team_external_availability') {
                let valueTemp = (parseInt(formValuesTemp.team_external_availability) === 0) ? 1 : 0;
                formValuesTemp = { ...formValuesTemp, ...{ team_external_availability: valueTemp } };
            }

            setFormValues(formValuesTemp);
        }

    }


    const loginUser = async () => {

        const button_name = 'btn-submit-member';
        Utilities.elementDisabledStatus(button_name, true);
        const generatedToken = Utilities.generateToken().toString();

        let passValues = {
            ...notificationValues,
            account_id: parseInt(personalAccount.account_id),
            token_request: generatedToken
        };

        if (parseInt(accountID) > 0) {
            passValues = {
                ...passValues,
                login_company_id: accountID
            };
        }

        await axios
            .post(ApiUrls.userGetByCredentail, passValues)
            .then(response => {

                Utilities.elementDisabledStatus(button_name, false);

                const resStatus = response.status;
                if (resStatus === 200) {

                    const resData = response.data;
                    const resLoginType = parseInt(resData.logintype);

                    if (parseInt(resData.status) === 1) {

                        SessionUtilities.setSessionToken(generatedToken);

                        localStorage.setItem(
                            process.env.REACT_APP_session_logintype,
                            JSON.stringify(resLoginType)
                        );

                        localStorage.setItem(
                            process.env.REACT_APP_session_login,
                            JSON.stringify(resData.detail)
                        );

                        localStorage.setItem(
                            process.env.REACT_APP_session_login_started,
                            Date.now()
                        );

                        localStorage.setItem(
                            process.env.REACT_APP_session_accounts,
                            JSON.stringify(resData.accounts)
                        );

                        // CHECK iF COMPAIGN 
                        const compaignShowPopup = SessionUtilities.getSignupCampaignDownload();
                        if (parseInt(compaignShowPopup ?? 0) === 1) {
                            redirectToDownloadAppFromCampaignCompanySignup(history);
                            return false;
                        }

                        SessionUtilities.removeSignupCampaignDownload();
                        history.push(`/${UrlSlugs.dashboard}`);


                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }
                } else {
                    Utilities.messagePopup('error', errorMsg);
                }

            })
            .catch((err) => {
                Utilities.messagePopup('error', errorMsg);
                Utilities.elementDisabledStatus(button_name, false);
            });

    }


    const submitTeam = async (e) => {
        e.preventDefault();

        const button_name = 'btn-submit-member';
        Utilities.elementDisabledStatus(button_name, false);

        const logo_value = document.getElementById('input_select_file').value;

        /* if ( Utilities.isEmpty(logo_value) ){
            Utilities.messagePopup('error', TitlesLabels.alertMessages.imageMandatory );
            return false;
        } */


        if (accountID === 0) { return false; }

        const formData = new FormData();
        formData.append('company_id', accountID);
        formData.append('domain', domainName);

        let errorCounter = 0, responseChecker = false;
        //const fieldsChecker = ['team_first_name', 'team_last_name','team_email','team_department', 'team_position'];
        const fieldsOptionalChecker = ['team_contact_number', 'team_email'];

        Object.entries(formValues).map(([key, value]) => {
            if (!fieldsOptionalChecker.includes(key)) {
                if (Utilities.isEmpty(value)) {
                    //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    Utilities.addFormGroupClass(key);
                    errorCounter++;

                } else {

                    /* if (key==='team_email'){
                        if ( Utilities.checkSpecialChar(formValues.team_email)){
                            //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            Utilities.addFormGroupClass(key);
                            errorCounter++;
                        }else{
                            //jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            Utilities.removeFormGroupClass(key);
                            formData.append(key, value);
                        }

                    }else */

                    if (['team_first_name', 'team_last_name', 'team_department', 'team_position'].includes(key)) {
                        responseChecker = Utilities.inputCharacterChecker(value, 'general');
                        if (responseChecker === false) {
                            //jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                            Utilities.addFormGroupClass(key);
                            errorCounter++;
                        } else {
                            //jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                            Utilities.removeFormGroupClass(key);
                            formData.append(key, value);
                        }

                    } else {
                        //jQuery('[name="'+key+'"]').closest('.form-group').removeClass('error');
                        Utilities.removeFormGroupClass(key);
                        formData.append(key, value);
                    }
                }
            }
        });


        if (!Utilities.isEmpty(formValues.password)) {

            /* if ( formValues.password.length<=5){
                Utilities.messagePopup('Error', Utilities.labelPasswordFiveCharacters );
                return false;
            } */

            const passwordValidation = Utilities.passwordValidation(formValues.password);
            if (passwordValidation.response === false) {
                //jQuery('[name="password"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(`password`);
                return false;
            }

            if (formValues.password !== formValues.password_confirm) {
                Utilities.messagePopup('Error', Utilities.labelPasswordConfirmNotSame);
                return false;
            }

            formData.append('password', formValues.password);
        }


        let team_contact_number = formValues.team_contact_number || '';

        if (!Utilities.isEmpty(team_contact_number)) {
            if (!Utilities.contactNumberValidate(team_contact_number)) {
                //jQuery('[name="team_contact_number"]').closest('.form-group').addClass('error');
                Utilities.addFormGroupClass(`team_contact_number`);
                errorCounter++;

            } else {
                //jQuery('[name="team_contact_number"]').closest('.form-group').removeClass('error');
                Utilities.removeFormGroupClass(`team_contact_number`);
            }
        } else {
            if (parseInt(formValues.team_contact_number_status) === 1) {
                Utilities.messagePopup('Warning', TitlesLabels.alertMessages.mobileNumberShouldNotBeEmpty);
                errorCounter++;
            }
        }

        if (errorCounter > 0) { return false; }



        // RESTRICTED WORDS VALIDATION
        // TYPES : 1=>email, 2=>name, 3=>keywords
        let wordsRequestArray = [];
        wordsRequestArray.push(formValues.team_email);
        wordsRequestArray.push(formValues.team_first_name);
        wordsRequestArray.push(formValues.team_last_name);

        Utilities.elementDisabledStatus(button_name, true);
        const responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('2', wordsRequestArray);
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(responseRestrictedValidationsDB.status) === 0) {
            Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_nameEmail);
            return false;
        }

        // END RESTRICTED WORDS VALIDATION


        formData.append('team_contact_number', formValues.team_contact_number || '');
        formData.append('team_contact_number_code_id', formValues.team_contact_number_code_id || 0);
        formData.append('team_contact_number_status', formValues.team_contact_number_status || 0);

        formData.append('company_category_id', companyAccount.category_id);

        formData.append('personal_id', personalAccount.account_id);
        formData.append('country_code', personalAccount.country_code);
        formData.append('mobile_number', personalAccount.mobile_number);
        formData.append('country_id', companyAccount.country_id);
        formData.append('city_id', companyAccount.city_id);
        formData.append('area_id', companyAccount.area_id);
        formData.append('state_id', companyAccount.state_id);
        formData.append('team_internal_chat_status', formValues.team_internal_chat_status);
        formData.append('team_is_listing', formValues.team_is_listing);
        formData.append('team_external_availability', formValues.team_external_availability);

        const utcTime = Utilities.currentUTCTime();
        const curretTime = moment.utc(utcTime).format(Utilities.dateTimeServerStr.toString());
        formData.append('create_date', curretTime);


        Utilities.elementDisabledStatus(button_name, true);

        axios
            .post(ApiUrls.profileSignupTeamDetail, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(async (response) => {

                Utilities.elementDisabledStatus(button_name, false);


                if (response.status === 200) {
                    const dataResponse = response.data;

                    if (parseInt(dataResponse.status) === 2) {
                        Utilities.messagePopup('error', Utilities.labelEmailExist);

                    } else {

                        if (dataResponse.hasOwnProperty('account_id') && !Utilities.isEmpty(logo_value)) {

                            Utilities.elementDisabledStatus(button_name, true);

                            const formLogoData = new FormData();
                            formLogoData.append('account_id', dataResponse.account_id); // team_account_id
                            formLogoData.append('file', logoProfile);

                            await uploadProfileLogo(formLogoData);
                            Utilities.elementDisabledStatus(button_name, false);
                        }

                        await loginUser();

                        //dispatch( reloadAccountsDB(Date.now()) );
                        //history.push('/dashboard' );
                    }

                } else {
                    Utilities.messagePopup('error', errorMsg);
                }

            })
            .catch((err) => {
                Utilities.messagePopup('error', errorMsg);
                Utilities.elementDisabledStatus(button_name, false);
            });

    }

    const updateNotificationValues = async () => {

        const timeUTC = moment.utc();
        let formValuesTemp = notificationValues;

        let fcmToken = await askPermissionToReceiveNotifications();
        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString();
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex);

        if (Utilities.isEmpty(fcmToken)) {
            const fcmTokenTemp = localStorage.getItem(process.env.REACT_APP_fs_fcm_token);
            fcmToken = JSON.parse(fcmTokenTemp) || '';

        } else {
            localStorage.setItem(
                process.env.REACT_APP_fs_fcm_token,
                JSON.stringify(fcmToken)
            );
        }

        formValuesTemp = {
            ...formValuesTemp,
            ...{
                fcm_token: fcmToken.toString(),
                utc_date: dateFormatted.toString(),
                brower_id: browserUniqueID.toString(),
            }
        };
        setNotificationValues(formValuesTemp);

    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    const changeCountryCodeMobile = (passValues, fieldName = '') => {
        let formValuesTemp = formValues;
        formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_code_id: parseInt(passValues.id) } };
        setFormValues(formValuesTemp);
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    const getCountries = async (providedMobileCode = 0) => {
        providedMobileCode = parseInt(providedMobileCode);

        const countriesResponse = await QueryUtilities.countryList();
        if (countriesResponse.length > 0) {

            let optionList = [], formValuesTemp = formValues;
            Object.entries(countriesResponse).map(([key, row]) => {
                optionList = [...optionList, { value: row.id, label: row.country_name }];

                if (providedMobileCode > 0) {

                    if (parseInt(providedMobileCode) === parseInt(row.id)) {
                        formValuesTemp = { ...formValuesTemp, ...{ team_contact_number_code_id: parseInt(row.id) } };
                        setFormValues(formValuesTemp);
                    }
                }

            });

            setCountries(countriesResponse);
        }

    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    useEffect(async () => {

        dispatch(pageTitle(TitlesLabels.siteTitles.teamMember));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        updateNotificationValues();


        let signupCompanyInfo = sessionStorage.getItem(process.env.REACT_APP_session_signup_companyinfo);
        if (signupCompanyInfo !== null) {

            signupCompanyInfo = JSON.parse(signupCompanyInfo);
            if (Object.keys(signupCompanyInfo).length > 0) {

                const companyDomainRequest = Utilities.isEmpty(signupCompanyInfo.company_display_name) ? signupCompanyInfo.company_name : signupCompanyInfo.company_display_name;
                let domainNameTemp = Utilities.removeSpecialChar(companyDomainRequest);
                domainNameTemp = domainNameTemp.toString().toLowerCase();

                setDomainName(domainNameTemp);

            }
        }

        let signupCompanyGeneratedId = parseInt(SessionUtilities.getSignupGeneratedCompanyId() ?? 0);
        if (signupCompanyGeneratedId > 0) {
            setAccountID(signupCompanyGeneratedId);
        }


        /* let signupCompanyGeneratedId = sessionStorage.getItem(process.env.REACT_APP_session_signup_companyGeneratedId);
        if ( signupCompanyGeneratedId!==null ){
            setAccountID(signupCompanyGeneratedId);
        } */


        let signupCompanyAddress = sessionStorage.getItem(process.env.REACT_APP_session_signup_companyaddress);
        if (signupCompanyAddress !== null) {

            signupCompanyAddress = JSON.parse(signupCompanyAddress);
            if (Object.keys(signupCompanyAddress).length > 0) {
                await getCountries(signupCompanyAddress.country_id);

                setCompanyAccount({
                    country_id: signupCompanyAddress.country_id,
                    state_id: signupCompanyAddress.state_id,
                    city_id: signupCompanyAddress.city_id,
                    area_id: signupCompanyAddress.area_id,
                    category_id: '0'
                });

            }
        }


        let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
        if (accountsList !== null) {
            accountsList = JSON.parse(accountsList);

            if (Object.keys(accountsList).length > 0) {


                const personalAccountTemp = Object.values(accountsList)[0];

                let formValuesTemp = {
                    ...formValues,
                    ...{
                        team_first_name: personalAccountTemp.first_name ?? '',
                        team_last_name: personalAccountTemp.last_name ?? ''
                    }
                }

                setFormValues(formValuesTemp);
                setLogoProfileValue(personalAccountTemp.logo_url ?? '');
                setPersonalAccount(personalAccountTemp);
            }

        } else {
            PagesAuthentication.logoutUser();
            history.push(`/${UrlSlugs.logout}`);
        }

        /* else{

            accountsList = localStorage.getItem(process.env.REACT_APP_session_login_signup);
            if ( accountsList!==null ){
                accountsList = JSON.parse(accountsList);


                if ( Object.keys(accountsList).length>0 ){
                    const personalAccountTemp = accountsList;
                    let formValuesTemp = { 
                                        ...formValues,
                                        ...{
                                            team_first_name : personalAccountTemp.first_name??'',
                                            team_last_name : personalAccountTemp.last_name??''
                                        }
                                    } 
    
                    setFormValues(formValuesTemp);
                    setPersonalAccount(personalAccountTemp);
                }
            }

        }
         */
        // LOGGEDIN ACCOUNTS
        /* let loginProfileInfo = localStorage.getItem(process.env.REACT_APP_session_login);
        if ( loginProfileInfo!==null ){
            loginProfileInfo = JSON.parse(loginProfileInfo);
 
            if ( Object.keys(loginProfileInfo).length>0 ){
               let profileLoggedinTemp = Utilities.formatProfileDetail(loginProfileInfo);

               if ( profileLoggedinTemp.hasOwnProperty('company_display_name') ){
                   const companyDomainRequest = Utilities.isEmpty(profileLoggedinTemp.company_display_name) ? profileLoggedinTemp.company_name : profileLoggedinTemp.company_display_name;
                   let domainNameTemp = Utilities.removeSpecialChar(companyDomainRequest);
                   domainNameTemp = domainNameTemp.toString().toLowerCase();

                   setDomainName(domainNameTemp);
           
                   setAccountID(profileLoggedinTemp.account_id);
                   setCompanyAccount(profileLoggedinTemp);

                   let accountsList = localStorage.getItem(process.env.REACT_APP_session_accounts);
                   if ( accountsList!==null ){
                       accountsList = JSON.parse(accountsList);
           
                       if ( Object.keys(accountsList).length>0 ){
                           
                           const personalAccountTemp = Object.values(accountsList)[0];
                           let formValuesTemp = { 
                                               ...formValues,
                                               ...{
                                                   team_first_name : personalAccountTemp.first_name,
                                                   team_last_name : personalAccountTemp.last_name
                                               }
                                           } 

                           setFormValues(formValuesTemp);
                           setPersonalAccount(personalAccountTemp);

                           getCountries(companyDomainRequest.country_id);
                       }
                   }
               }
            }
        } */

        //getCountries(225);


        // REMOVE COMPAIGN whenever available, to clear
        //SessionUtilities.deleteSignupCampaign();


    }, []);

    return (
        <div className='signup-page with-footer centerialized   text-lowercase px-5 px-md-0'>

            <div className='signup-wrap position-relative h-100 d-flex align-items-center pb-5 justify-content-center'>
                <div>

                    <SignupHeader title="admin details" />

                    <form className="form-theme text-left" autoComplete="off">

                        <input type="file" id="input_select_file" name="file" className="invisible w-100" accept={Utilities.attributeImageAcceptExtension} readOnly onChange={(e) => previewImage(e)} ></input>

                        <div className='text-center'>
                            <div className="logo-size-200 mx-auto" style={{ backgroundImage: `url(${logoProfileValue})` }} ></div>

                            <div className='profile-logo-change'>
                                <button type="button" className="btn-select-file bg-theme-green font-gotham-bold border-radius text-white outline-none border-0 fs-16 mx-auto mt-3" style={{ height: 30, width: 100 }} onClick={() => selectImage()}>{Utilities.isEmpty(personalAccount.profile_logo ?? '') ? 'upload' : 'change'}</button>
                                <div className="fs-13-important text-center pt-3 color-theme-grey">{Utilities.labelUploadSpecs}</div>
                            </div>
                        </div>


                        <div className="row mt-5">
                            <div className='col-md-6'>
                                <FieldInput inputName="team_first_name" inputLabel="First Name" inputValue={formValues.team_first_name} pushValue={pushValue} checkerType="general" />
                            </div>
                            <div className='col-md-6'>
                                <FieldInput inputName="team_last_name" inputLabel="Last Name" inputValue={formValues.team_last_name} pushValue={pushValue} checkerType="general" />
                            </div>



                            <div className='col-md-6'>
                                <FieldInput inputName="team_department" inputLabel="Company Division" inputValue={formValues.team_department} pushValue={pushValue} checkerType="general" />
                            </div>

                            <div className='col-md-6'>
                                <FieldInput inputName="team_position" inputLabel="Company Position" inputValue={formValues.team_position} pushValue={pushValue} checkerType="general" />
                            </div>

                            <div className='col-md-6'>
                                <MobileSelection inputValue={formValues.team_contact_number} countryListProvided={countries} inputLabel="Work mobile number" countryCodeProvided={formValues.team_contact_number_code_id} inputListingId="mobile-input-list" inputName="team_contact_number" pushValue={pushValue} placeholderExtra={`(optional)`} changeCountryCode={changeCountryCodeMobile} extraClasses={`has-off-on pr-0`}>
                                    <input type="checkbox" className="checkbox-onoff" value={formValues.team_contact_number_status} checked={formValues.team_contact_number_status === 1} onChange={(e) => pushValue(e, 'team_contact_number_status')} ></input>
                                </MobileSelection>
                            </div>

                            <div className='col-md-12'>
                                <div className="form-group active pb-3">
                                    <div className="form-control" style={{ height: 'inherit' }}>
                                        <div className="d-md-flex mt-2 checkbox-radio-default align-items-center">
                                            <div className="align-items-center d-flex mb-2 mb-md-0"><input type="checkbox" selected name="listing" className='border-0' value={formValues.team_internal_chat_status} onChange={() => pushValueVisiblity('team_internal_chat_status')} checked={formValues.team_internal_chat_status === 1} ></input><span className="ml-2">Internal</span></div>

                                            <div className="ml-md-4 align-items-center d-flex mb-2 mb-md-0"><input type="checkbox" name="external" className='border-0' value={formValues.team_is_listing} onChange={() => pushValueVisiblity('team_is_listing')} checked={formValues.team_is_listing === 1} ></input><span className="ml-2">External</span></div>

                                            <div className="ml-md-4 align-items-center d-flex mb-2 mb-md-0"><input type="checkbox" className='border-0' name="external" value={formValues.team_external_availability} onChange={() => pushValueVisiblity('team_external_availability')} checked={formValues.team_external_availability === 1} ></input><span className="ml-2">Disable work chat</span></div>

                                        </div>
                                    </div>
                                    <label className="form-label">Allow Visibility</label>
                                </div>
                            </div>

                        </div>

                    </form>

                </div>

                <div className='signup-button position-absolute w-100 bottom-0 start-0  mb-3'>
                    <button type="button" id="btn-submit-member" className="btn-theme-black text-lowercase button-has-loader col-md-6 mx-auto" onClick={(e) => submitTeam(e)}>Submit</button>
                </div>

            </div>

            <div className='pb-5 pt-5 pt-md-0'>
                <Link to={`/${UrlSlugs.signup_companyLogo}`}>{TitlesLabels.general.exitWithoutSaving}</Link>
            </div>

        </div>
    )
}

export default CompanyTeamMember;