import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

import PagesAuthentication from '../../../helpers/PagesAuthentication';
import { getGogoLinksHeadings } from '../../../helpers/QueryGotoLinks';
import NoRecordAvailable from '../../../components/common/NoRecordAvailable';
import SessionUtilities from '../../../helpers/SessionUtilities';

const TOKEN = process.env.REACT_APP_chat_api_token;
const errorMsg = process.env.REACT_APP_message_error_process;

const ManageGotoLinksSelectionPopup = (props) => {

    const showPopup = props.showPopup ?? false;
    const workCompanyDetail = props.workCompanyDetail ?? {};

    let dataDb_result = {}, dataParams = {};

    const masterDetail = PagesAuthentication.getMasterUserDetailDetail();

    const [showProcessing, setShowProcessing] = useState(false);
    const [listCategories, setListCategories] = useState([]);

    const gettingGroupsCategories = async () => {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();
        dataParams = {
            api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
            account_id: (masterDetail.account_id ?? 0).toString(),
            company_id: (workCompanyDetail.account_id ?? 0).toString(),
            flag: "0",
            title: "",
            group_id: "0"
        }

        setShowProcessing(true);
        dataDb_result = await getGogoLinksHeadings(dataParams);
        setShowProcessing(false);

        if (Object.keys(dataDb_result ?? []).length > 0) {
            setListCategories(dataDb_result);
        } else {
            setListCategories([]);
        }

    }

    useEffect(() => {
        if (showPopup) {
            gettingGroupsCategories();
        }

    }, [showPopup]);



    return <Modal id="popup-manage-gotolink-option" show={showPopup} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered size="md" dialogClassName="modal-dialog-theme "  >
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body className='pb-5 px-5'>


            {
                showProcessing ?
                    <ul className="ul-global-listing">
                        {
                            [...Array(6).keys()].map((key) =>
                                <li key={key} className="global-preloader  py-2">
                                    <div className="bg-gray col-5 pt-3 border-radius-loader mx-auto"></div>
                                </li>
                            )
                        }
                    </ul>
                    :
                    <>
                        {
                            Object.keys(listCategories ?? []).length === 0 ?
                                <NoRecordAvailable />
                                :
                                <ul className="ul-global-listing cursor-pointer">
                                    {
                                        Object.entries(listCategories ?? []).map(([key, row]) =>
                                            <li key={key} onClick={() => props.handleClickOption(row)}>{row.title ?? ''}</li>
                                        )
                                    }

                                </ul>
                        }
                    </>

            }



        </Modal.Body>
    </Modal>
}


export default ManageGotoLinksSelectionPopup;