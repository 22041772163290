import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import jQuery from 'jquery';
import { confirmAlert } from 'react-confirm-alert';

import { showHeader, showFooter, pageTitle } from '../actions';
import PagesAuthentication from '../helpers/PagesAuthentication';
import Utilities from '../helpers/Utilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';
import PasswordInput from '../components/PasswordInput';
import CompanyCreationRequestPopup from '../components/popups/CompanyCreationRequestPopup';
import { checkPermissionToRegisterCompany } from '../helpers/QueryCompanySignup';
import ForgotPassword from '../components/popups/ForgotPassword';
import { askPermissionToReceiveNotifications } from '../components/firebase/PushNotification';
import { loginDetailTemplate } from '../helpers/EmailTemplates';
import LoginInputSelection from '../pages/Login/LoginInputSelection';
import LoginConnect from '../pages/Login/LoginConnect';
import SessionUtilities from '../helpers/SessionUtilities';
import { sendEmailGeneral } from '../helpers/SendingEmails';

const PersonalLogin = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const defaultLocation = Utilities.defaultLocation();
    const errorMsg = process.env.REACT_APP_message_error_process;
    //const TOKEN_SESSION = SessionUtilities.getSessionToken();

    const [formValues, setFormValues] = useState({
        account_id: '0', fcm_token: '', utc_date: '', brower_id: '',
        email: '', password: '', phone_code: defaultLocation.country_id.toString(), phone_number: ''
    });
    const [defaultResMessage, setDefaultResMessage] = useState({ status: false, msg: Utilities.labelUsernamePasswordNotMatched });

    const [loginType, setLoginType] = useState(2);

    const [showCompanyRequestPopup, setShowCompanyRequestPopup] = useState(false);
    const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);


    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'email') {
            setFormValues({ ...formValues, ...{ email: value } });
        } else if (fieldName === 'password') {
            setFormValues({ ...formValues, ...{ password: value } });
        } else if (fieldName === 'phone_number') {
            setFormValues({ ...formValues, ...{ phone_number: value } });
        }
    }

    const showResMessageWarning = () => {
        let timer = setTimeout(() => {
            setDefaultResMessage({ status: false, msg: '' });
            clearTimeout(timer);
        }, 2000);
    }


    const handleCloseCompanyCreationPopUp = async () => {
        setShowCompanyRequestPopup(false);
    }

    const handleSubmitCompanyCreationPopUp = async () => {
        setShowCompanyRequestPopup(false);

        PagesAuthentication.logoutUser();
        history.push('/');
    }


    const loginAccount = async () => {

        setDefaultResMessage({ status: 0, msg: errorMsg });

        const button_name = 'btn-login-user';

        let usernameTemp = parseInt(loginType) === 2 ? formValues.phone_number : formValues.email;
        const userAccountId = parseInt(formValues.account_id ?? 0);

        if (userAccountId === 0) {
            if (Utilities.isEmpty(usernameTemp) || Utilities.isEmpty(formValues.password)) {
                setDefaultResMessage({ status: true, msg: Utilities.labelUsernamePasswordMandatory });
                showResMessageWarning();

                Utilities.elementDisabledStatus(button_name, false);

                return false;
            }
        }



        Utilities.elementDisabledStatus(button_name, true);
        const generatedToken = Utilities.generateToken().toString();

        const windowNavigator = window.navigator,
            credentialPasval = {
                ...formValues,
                login_choose: loginType.toString(),
                browser_name: windowNavigator.appVersion.toLowerCase(),
                token_request: generatedToken
            };

        //const formvaluesTemp = {...formValues, check_existance : 1, login_choose : loginType.toString() };
        //console.log(ApiUrls.userGetByCredentail);
        //console.log(credentialPasval);

        await axios
            .post(ApiUrls.userGetByCredentail, credentialPasval)
            .then(async response => {

                const resStatus = response.status;

                Utilities.elementDisabledStatus(button_name, false);

                if (resStatus === 200) {
                    const resData = response.data;
                    const resStatus = parseInt(resData.status ?? 0);
                    const resLoginType = parseInt(resData.logintype);

                    if (resStatus === 1) {


                        if (Object.keys(resData.detail ?? []).length === 0) {
                            setDefaultResMessage({ status: true, msg: Utilities.labelNoProfileCreatedUsername });
                            showResMessageWarning();
                            return false;
                        }



                        if (parseInt(resData.detail.status ?? 0) === 1) {

                            SessionUtilities.setSessionToken(generatedToken);

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_signup,
                                JSON.stringify(resData.detail)
                            );


                            localStorage.setItem(
                                process.env.REACT_APP_session_logintype,
                                JSON.stringify(resLoginType)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login,
                                JSON.stringify(resData.detail)
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_login_started,
                                Date.now()
                            );

                            localStorage.setItem(
                                process.env.REACT_APP_session_accounts,
                                JSON.stringify(resData.accounts)
                            );


                            // CHECKING COMPANY CREATE PERMISSION
                            const passval = {
                                //api_token: TOKEN.toString(),
                                //account_id: (resData.detail.account_id ?? '0').toString()
                                api_token: generatedToken.toString(),
                                account_id: (resData.detail.account_id ?? '0').toString(),
                            }

                            let requestCompanyCreationStatus = await checkPermissionToRegisterCompany(passval);
                            requestCompanyCreationStatus = requestCompanyCreationStatus.toString();

                            // 1 : send request, 2 : create comp, 3 : request already
                            if (["0","1"].includes(requestCompanyCreationStatus)) {

                                confirmAlert({
                                    title: 'alert',
                                    message: TitlesLabels.alertMessages.warningMessageCompanyCreation,
                                    overlayClassName: 'normal-font-style warning-message',
                                    buttons: [
                                        { label: 'No', onClick: () => { setShowCompanyRequestPopup(false); } },
                                        { label: 'yes', onClick: () => { setShowCompanyRequestPopup(true); } }
                                    ]
                                });
                                
                                return false;

                            } else if (["3"].includes(requestCompanyCreationStatus)) {
                                Utilities.messagePopup('info', TitlesLabels.alertMessages.companyRequestAlreadySent ?? '');
                                return false;
                            }

                            // END CHECKING COMPAN Y CREATE PERMISSION


                            localStorage.setItem(process.env.REACT_APP_session_welcome_mesage, 1);

                            // SENDENG EMAIl LOGIN SECURITY
                            if (parseInt(resData.new_device) === 1) {
                                let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_session_location);
                                if (sessionLocation !== null) {
                                    sessionLocation = JSON.parse(sessionLocation);
                                }

                                let emailData = { ...resData.detail, location: { ...sessionLocation } };
                                console.log(emailData);
                                Utilities.elementDisabledStatus(button_name, true);
                                await sendEmailGeneral({
                                    api_token: generatedToken.toString(),
                                    account_id: (resData.detail.account_id ?? '0').toString(),
                                    to_email: emailData.email.toString(),
                                    subject: TitlesLabels.general.security_alert.toString(),
                                    message: (await loginDetailTemplate(emailData)).toString()
                                });
                                Utilities.elementDisabledStatus(button_name, false);

                            }


                            history.push(UrlSlugs.companyInformation);

                        } else {
                            setDefaultResMessage({ status: true, msg: Utilities.labelAccountNotActive });
                            showResMessageWarning();
                        }

                    } else if (resStatus === 2) {
                        setDefaultResMessage({ status: true, msg: Utilities.labelCouldNotLoginCompanyAccount });
                        showResMessageWarning();

                    } else {
                        setDefaultResMessage({ status: true, msg: Utilities.labelInvalidCredentialDetail });
                        showResMessageWarning();
                    }

                } else {
                    setDefaultResMessage({ status: true, msg: errorMsg });
                    showResMessageWarning();
                }

            })
            .catch((err) => {
                setDefaultResMessage({ status: true, msg: errorMsg });
                showResMessageWarning();

                Utilities.elementDisabledStatus(button_name, false);
            });


    }

    const pushValuePhonecode = (providedValue = '') => {
        setFormValues({ ...formValues, ...{ phone_code: providedValue } });
    }



    const handleForgotPasswordShowPopUp = () => {
        setForgotPasswordPopup(true);
    }
    const handleForgotPasswordClosePopUp = () => {
        setForgotPasswordPopup(false);
    }


    const loginSocialDetail = (dataProvided = {}, status = 0) => {


        if (Object.keys(dataProvided).length === 0) {
            setDefaultResMessage({ status: true, msg: TitlesLabels.alertMessages.loginInvalidCredential });
            showResMessageWarning();
            return false;
        }

        // IF NO SOCIAL BUT HAS CREDENTIAL
        if (parseInt(status) === 2) {
            setDefaultResMessage({ status: true, msg: TitlesLabels.alertMessages.loginAlreadyWithPassword });

            setFormValues({ ...formValues, ...{ account_id: dataProvided.account_id ?? '', email: dataProvided.email ?? '' } });
            setLoginType(1);
            showResMessageWarning();

            return false;
        }


        // IF HAVE SOCIAL CREDENTIAL
        setFormValues({ ...formValues, ...{ account_id: dataProvided.account_id ?? '', email: dataProvided.app_access_token ?? '' } });
        jQuery('#btn-login-user').trigger('click');

    }

    const udpateFormValues = async () => {

        const timeUTC = moment.utc();
        let formValuesTemp = formValues;

        let fcmToken = await askPermissionToReceiveNotifications();
        const dateFormatted = moment(new Date(timeUTC)).format(Utilities.dateTimeServerStr.toString()).toLocaleString();
        const browserUniqueID = CryptoJS.lib.WordArray.random(80).toString(CryptoJS.enc.Hex);

        if (Utilities.isEmpty(fcmToken)) {
            const fcmTokenTemp = localStorage.getItem(process.env.REACT_APP_fs_fcm_token);
            fcmToken = JSON.parse(fcmTokenTemp) || '';

        } else {
            localStorage.setItem(
                process.env.REACT_APP_fs_fcm_token,
                JSON.stringify(fcmToken)
            );
        }

        formValuesTemp = {
            ...formValuesTemp,
            ...{
                fcm_token: fcmToken.toString(),
                utc_date: dateFormatted.toString(),
                brower_id: browserUniqueID.toString(),
            }
        };

        setFormValues(formValuesTemp);
    }


    useEffect(() => {
        dispatch(pageTitle(TitlesLabels.siteTitles.personalLogin));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        PagesAuthentication.checkLoginStatus();
        udpateFormValues();


    }, []);

    return (
        <>

            <div className='signup-page with-footer centerialized   text-lowercase fs-18'>

                <div className='signup-wrap text-center'>
                    <SignupHeader title="sign in" />


                    <div className="col-md-10 mx-auto pt-1">

                        <div className="text-center text-size-medium">
                            <div className="mb-4">enter your email / mobile number</div>
                        </div>


                        <div className="login-form col-md-10 py-4 mx-auto  text-lowercase form-theme">

                            <div className="mt-4">
                                <LoginInputSelection loginType={loginType} formValues={formValues} pushValue={pushValue} pushValuePhonecode={pushValuePhonecode} setLoginView={setLoginType} classNameInputLabelAttr="bg-transparent" tabType={2} />
                            </div>

                            <PasswordInput inputName="password" inputLabel="Password" inputClass="text-center" inputLabelClass="text-center bg-transparent" inputValue={formValues.password} pushValue={pushValue} />

                            <div className={`color-theme-warning pt-1 text-center ${defaultResMessage.status === true ? '' : 'invisible'}`}>{defaultResMessage.msg}</div>

                            <div className="button-wrap text-lowercase text-center mt-4">
                                <div className="mb-3">
                                    <button type="button" id="btn-login-user" className="btn-theme-green text-lowercase button-has-loader col-md-10 mx-auto" onClick={() => loginAccount()}>Sign In</button>
                                </div>

                                <div className="mb-2"><span className='cursor-pointer' onClick={() => handleForgotPasswordShowPopUp()}>forgot id or password?</span></div>

                                <div className="social-links-login d-flex justify-content-center my-3">
                                    <LoginConnect loginSocialDetail={loginSocialDetail} themeName={`black`} />
                                </div>

                            </div>

                        </div>


                    </div>

                </div>

                <div className='pb-5  pt-5 pt-md-0'>
                    <Link to={`/${UrlSlugs.signup_companyAccount}`}>{TitlesLabels.general.exitWithoutSaving}</Link>
                </div>

            </div>

            <CompanyCreationRequestPopup showPopup={showCompanyRequestPopup} handleClosePopUp={handleCloseCompanyCreationPopUp} handleSubmit={handleSubmitCompanyCreationPopUp} directToChat={false} />

            <ForgotPassword forgotPasswordPopup={forgotPasswordPopup} handleForgotPasswordClosePopUp={handleForgotPasswordClosePopUp} />
        </>
    )
}

export default PersonalLogin;