import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import TitlesLabels from '../../helpers/TitlesLabels';
import Utilities from '../../helpers/Utilities';
import { generateGetOwnReferralLink } from '../../helpers/QueryReferral';
import PagesAuthentication from '../../helpers/PagesAuthentication';
import SessionUtilities from '../../helpers/SessionUtilities';
import LinkCopiedTooltip from '../common/LinkCopiedTooltip';


const CampaignReferralPopup = (props) => {

    const errorMsg = process.env.REACT_APP_message_error_process;

    const [referralLink, setReferralLink] = useState('');
    const [masterDetail, setMasterDetail] = useState({});
    const [showShareTooltip, setShowShareTooltip] = useState(false);

    const handleClickGenerate = async (masterAcountId = 0) => {

        let campaign_id = 0;

        // CHECK IF THERE IS CAMPAIN
        const signupCampaignDetail = SessionUtilities.getSignupCampaign();
        if (Object.keys(signupCampaignDetail).length > 0) {
            campaign_id = parseInt(signupCampaignDetail.id ?? 0);
        }

        let signupCompanyGeneratedId = parseInt(SessionUtilities.getSignupGeneratedCompanyId() ?? 0);
        if (signupCompanyGeneratedId > 0 && parseInt(masterAcountId) > 0 && campaign_id > 0) {

            const button_name = 'btn-generate-referral';

            Utilities.elementDisabledStatus(button_name, true);

            const passval = {
                account_id: masterAcountId.toString(),
                company_id: signupCompanyGeneratedId.toString(),
                campaign_id: campaign_id.toString()
            };

            const compaignDetailDB = await generateGetOwnReferralLink(passval),
                status = parseInt(compaignDetailDB.status ?? 0);

            Utilities.elementDisabledStatus(button_name, false);

            if (status > 0) {
                setReferralLink(compaignDetailDB.link);

            } else {
                Utilities.messagePopup('error', errorMsg);
            }
        }

    }

    const handleClickCopyLink = () => {
        Utilities.copyContent('sort_link_url');
        setShowShareTooltip(true);
        setTimeout(() => {
            setShowShareTooltip(false);
            props.handleClosePopUp();
        }, 1000);
    }


    useEffect(() => {

        const masterAccountSession = PagesAuthentication.getMasterUserDetailDetail();
        if (Object.keys(masterAccountSession ?? {}).length > 0) {
            setMasterDetail(masterAccountSession);

        }


    }, []);



    return (

        <Modal id="popup-referral-link" show={props.showPopup} onHide={props.handleClosePopUp} animation={false} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="modal-dialog-theme "  >
            <Modal.Header closeButton className="font-gotham-bold fs-25 text-center d-inline border-bottom-0 pt-4">{TitlesLabels.general.congratulations}</Modal.Header>
            <Modal.Body className="px-4 px-md-5 pb-5">

                <div className='text-center'>
                    you business is now registered and you have now been entered into our <span className='color-theme-green'>business boost</span> competition and stand a chance to win <span className='color-theme-green'>R100 000</span>. you can increase your chances of winning by referring other businesses. you will be granted an extra ticket into the draw for every company you refer that signs up.

                    <div className='mt-4 pt-2'>

                        {
                            Utilities.isEmpty(referralLink) ?
                                <button type="button" id="btn-generate-referral" className="btn-theme-green text-lowercase button-has-loader col-sm-6 mx-auto button-has-loader" onClick={() => handleClickGenerate(masterDetail.account_id)}>generate referral link</button>
                                :
                                <div className='text-center '>
                                    <input id="sort_link_url" type="text" className="d-none" value={referralLink} />
                                    <div className='font-gotham-bold fs-20 color-theme-green' >{referralLink}</div>
                                    <button type="button" className="btn-theme-green text-lowercase button-has-loader col-md-10 mx-auto  mt-3 position-relative" onClick={() => handleClickCopyLink()}>
                                        copy link
                                        <LinkCopiedTooltip boolShow={showShareTooltip} styleAttr={{ left: '37%' }} />
                                    </button>

                                    <div className='color-theme-grey mt-2'>please copy and forward this link to your referral parties</div>
                                </div>
                        }


                    </div>

                </div>

            </Modal.Body>

        </Modal>
    )
}

export default CampaignReferralPopup;
