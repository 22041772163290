import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as RM from 'ramda';


import Utilities from './../helpers/Utilities';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import { getInternalProfileUnreadCounter, getInternalProfiles } from '../helpers/InternalUtilities';

const BreadcrumbInternal = (props) => {

    const internalChatLabel = TitlesLabels.siteTitles.internalChat.toLowerCase();

    const [associatedAccountsCounter, setAssociatedAccountsCounter] = useState(-1);
    const [associatedAccounts, setAssociatedAccounts] = useState([]);
    const [internalTotalCounter, setInternalTotalCounter] = useState(0);
    const [showLabel, setShowLabel] = useState(true);


    
    const setupListing = () => {
        const allConversationResults = props.allConversationResults ?? {};
        let internalProfiles = getInternalProfiles(), internalProfilesWithTemp = [], loopId = '', loopCount = 0;

        if (Object.keys(allConversationResults).length > 0) {
            setInternalTotalCounter(parseInt(allConversationResults.home_internal_conv_count || 0));

            const profileWithUnreadMesasges = getInternalProfileUnreadCounter(allConversationResults.home_internal_conv_account_list);

            internalProfiles.map( prof => {
                loopId = `${(prof.hasOwnProperty('company_detail')) ? prof.company_detail.account_id+'_' : ''}${prof.account_id}`;
                loopCount = 0;
                if ( profileWithUnreadMesasges.hasOwnProperty(loopId) ){
                    loopCount = profileWithUnreadMesasges[loopId].toString();
                }

                internalProfilesWithTemp.push({...prof, unread_count: loopCount.toString() });
                
            });

            internalProfiles = [...internalProfilesWithTemp];

            if (internalProfiles.length > 0) {
                if ('company_name' in internalProfiles[0]) {
                    const byName = RM.ascend(RM.compose(RM.toLower, RM.prop('company_name')));
                    internalProfiles = RM.sort(byName, internalProfiles);
                }
            }
            setAssociatedAccounts(internalProfiles);
            setAssociatedAccountsCounter(internalProfiles.length);

        } else {
            setAssociatedAccountsCounter(0);
        }

    }
    
    useEffect(() => {
        setupListing();

    }, [props.allConversationResults]);


    useEffect(() => {
        const showLabelTemp = props.showLabel;
        if (typeof showLabelTemp !== 'undefined') {
            setShowLabel(showLabelTemp);
        }
    }, [props.showLabel]);


    return (
        <>
            {
                parseInt(associatedAccountsCounter) === -1 && <div className="btn-icon btn-group-people opacity-05 processing cursor-wait" title={`${internalChatLabel}`}></div>
            }

            {
                Object.keys(associatedAccounts).length > 1 ?
                    <Dropdown className="dropdown-user-selection dropdown-internal-selection">
                        <Dropdown.Toggle variant="dropdown-user-current btn-icon-wrap" >
                            <div className="btn-icon btn-group-people" title={`${internalChatLabel}`}>
                                {parseInt(internalTotalCounter) > 0 && <span className="counter">{Utilities.counterMore(internalTotalCounter)}</span>}
                            </div>
                            {showLabel === true && <span className="ml-2">{internalChatLabel}</span>}
                        </Dropdown.Toggle>

                        <Dropdown.Menu >
                            {showLabel === false && <div className="dropdown-header">{internalChatLabel}</div>}
                            {
                                Object.entries(associatedAccounts).map(([key, row]) =>
                                    <div className="profile-group-wrapper" key={key} >
                                        <Link to={`/${UrlSlugs.internalChat}/${btoa(parseInt(row.account_id))}`} className="item-user-selection dropdown-item" role="button">
                                            <div>
                                                <div className={`profile-logo`} style={{ backgroundImage: `url(${Utilities.showHideProfileImage(row.company_detail.logo_url)})`, borderRightColor: Utilities.bgcolorHideInitialBg(row.company_detail.logo_url) }} >{Utilities.showHideInitial(row.company_detail.logo_url, row.company_detail.lname)}</div>
                                                <div className='line-height-1-2'>
                                                    <div className="profile-name">{row.company_name??''}</div>
                                                    <div className="ellipsis-1">{TitlesLabels.general.internal_chat}</div>
                                                </div>
                                                {parseInt(row.unread_count??0) > 0 && <span className="counter  counter-styled"><em>{Utilities.counterMore(row.unread_count??0)}</em></span>}
                                            </div>
                                        </Link>
                                    </div>
                                )
                            }

                        </Dropdown.Menu>
                    </Dropdown>
                    :
                    Object.entries(associatedAccounts).map(([key, row]) =>
                        <Link to={`/${UrlSlugs.internalChat}/${btoa(parseInt(row.account_id))}`} key={key} role="button">
                            <div className="btn-icon btn-group-people" title={`${internalChatLabel}`}> {parseInt(internalTotalCounter) > 0 && <span className="counter">{Utilities.counterMore(internalTotalCounter)}</span>}</div>
                            {showLabel === true && <span className="ml-2">{internalChatLabel}</span>}
                        </Link>
                    )

            }

        </>
    )
}

export default BreadcrumbInternal;
