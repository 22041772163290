import axios from 'axios';
import ApiUrls from './ApiUrls';


export const getCounterKeywordByArea = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchKeywordCounterKeywordByArea, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getCounterKeywordAllSuburb = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchKeywordCounterKeywordAllSuburb, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getCounterKeywordByLocation = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchKeywordCounterAllWordsByLocation, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getCounterKeywordAllCountries = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchKeywordCounterKeywordAllCountries, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getCounterKeywordAllStates = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchKeywordCounterKeywordAllStates, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const getCounterKeywordAllCities = async (passval = {}) => {
    let responseDetail = { status: 0 };

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchKeywordCounterKeywordAllCities, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}





export const getKeywordsByCitiesIds = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.directorySearchKeywordCounterKeywordSelectedCities, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}
