import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';

import Utilities from '../helpers/Utilities';
import { showHeader, showFooter, pageTitle } from '../actions';

import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';
import { blockWordsAndEmailsValidation } from '../helpers/VerificationUtilities';

const CompanyKeywords = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let elem = jQuery(`input[name="${fieldName}"]`),
            val = elem.val();

        elem.val(val.replace(' ', ''));

        //jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        Utilities.removeFormGroupClass(fieldName);
    }

    const submitKeywords = async () => {
        let keywordLoop = '', keywordJoined = [], keywordError = 0, button_name = 'btn-submit-keywords';
        Utilities.elementDisabledStatus(button_name, false);


        jQuery('#form-company-keywords input').each(function () {
            keywordLoop = jQuery(this).val();
            if (!Utilities.isEmpty(keywordLoop)) {
                keywordLoop = keywordLoop.trim();

                if (!Utilities.checkSpecialChar(keywordLoop)) {
                    keywordJoined.push(keywordLoop.toLowerCase());
                    jQuery(this).closest('.form-group').removeClass('error');
                } else {
                    jQuery(this).closest('.form-group').addClass('error');
                    keywordError++;
                }

            }

        });


        if (keywordError > 0) {
            Utilities.messagePopup('Error', TitlesLabels.general.specialCharactersNotAllowed);
            return false;
        }

        if (keywordJoined.length === 0) {
            Utilities.messagePopup('Error', TitlesLabels.alertMessages.oneKeywordRequired);
            return false;
        }


        // RESTRICTED WORDS VALIDATION
        // TYPES : 1=>email, 2=>name, 3=>keywords
        let wordsRequestArray = keywordJoined;

        Utilities.elementDisabledStatus(button_name, true);
        const responseRestrictedValidationsDB = await blockWordsAndEmailsValidation('3', wordsRequestArray);
        Utilities.elementDisabledStatus(button_name, false);

        if (parseInt(responseRestrictedValidationsDB.status) === 0) {
            Utilities.restrictedMessage(responseRestrictedValidationsDB.names, TitlesLabels.general.restricted_someKeyword);
            return false;
        }

        // END RESTRICTED WORDS VALIDATION



        let signupCompanyAbout = sessionStorage.getItem(process.env.REACT_APP_session_signup_companyinfo_about);
        if (signupCompanyAbout !== null) {
            signupCompanyAbout = JSON.parse(signupCompanyAbout);

            if (Object.keys(signupCompanyAbout).length > 0) {
                signupCompanyAbout = { ...signupCompanyAbout, company_keywords: keywordJoined };

            }

            sessionStorage.setItem(
                process.env.REACT_APP_session_signup_companyinfo_about,
                JSON.stringify(signupCompanyAbout)
            );

        } else {
            return false;
        }


        history.push(`/${UrlSlugs.signup_companyCondition}`);

    }

    useEffect(() => {

        dispatch(pageTitle(TitlesLabels.siteTitles.keywordInformation));
        dispatch(showHeader(0));
        dispatch(showFooter(0));


    }, []);


    return (
        <div className='signup-page with-footer centerialized   text-lowercase fs-18'>

            <div className='signup-wrap text-center'>
                <SignupHeader title="Keyword Info" />

                <div className="form-theme text-left pt-4">
                    <form id="form-company-keywords" autoComplete="off">

                        {
                            [1, 2, 3, 4, 5].map(key =>
                                <div key={key} className="form-group ">
                                    <input type="text" name={`keyword_${key.toString()}`} className="form-control" placeholder=" " autoComplete="off" onChange={(e) => pushValue(e, 'keyword_' + key.toString())}  ></input>
                                    <label className="form-label">keyword</label>
                                </div>
                            )
                        }

                        <div className="button-wrap text-lowercase text-center pt-5">
                            <button id="btn-submit-keywords" type="button" className="btn-theme-black text-lowercase button-has-loader  col-md-4 mx-auto" onClick={() => submitKeywords()} >Next</button>
                        </div>

                    </form>
                </div>

            </div>

            <div className='pb-5 pt-4 pt-md-0'>
                <Link to={`/${UrlSlugs.signup_companyInformation}`}>{TitlesLabels.general.exitWithoutSaving}</Link>
            </div>


        </div>
    )
}

export default CompanyKeywords;