import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import jQuery from 'jquery';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import * as RM from 'ramda';

import Utilities from '../helpers/Utilities';
import ChatUtilities from '../helpers/ChatUtilities';
import ApiUrls from '../helpers/ApiUrls';
import UrlSlugs from '../helpers/UrlSlugs';

import { passChatProfile, passImageUrl } from '../actions';
import TitlesLabels from '../helpers/TitlesLabels';
import CommonProfileLogo from './common/CommonProfileLogo';
import SessionUtilities from '../helpers/SessionUtilities';

const ChatBroadcast = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const TOKEN = process.env.REACT_APP_chat_api_token;
    const errorMsg = process.env.REACT_APP_message_error_process;
    const DEFAUL_AVATAR = process.env.REACT_APP_default_avatar;
    const TOKEN_SESSION = SessionUtilities.getSessionToken();

    let gobalConversID = 0;
    let globalInternalDetail = {};

    const passConversID = useSelector(state => state.passConversID);
    const passInternalProfile = useSelector(state => state.passInternalProfile);

    const [conversID, setConversID] = useState(0);
    const [defaultSearchValue, setDefaultSearchValue] = useState('');
    const [internalDetail, setInternalDetail] = useState({});
    const [memberList, setMemberList] = useState([]);
    const [showEmpty, setShowEmpty] = useState(false);
    const [toggleAll, setToggleAll] = useState(false);

    const [memberIDs, setMemberIDs] = useState([]);
    const [memberAcctIDs, setMemberAcctIDs] = useState([]);

    const defaultValues = { title: '' };
    const [formValues, setFormValues] = useState(defaultValues);
    const [broadcastDetail, setBroadcastDetail] = useState({});

    const pushValue = (e, fieldName) => {
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        if (fieldName === 'search-keyword') {
            setDefaultSearchValue(value);
            searchFilter(value);

        } else if (fieldName === 'title') {
            let formValuesTemp = formValues;
            formValuesTemp = { ...formValuesTemp, ...{ title: value } };
            setFormValues(formValuesTemp);
        }
    }


    const searchFilter = (keyword = '') => {
        keyword = (!Utilities.isEmpty(keyword)) ? keyword.toLowerCase() : '';

        jQuery('#listing-profiles-member > li').filter(function () {
            jQuery(this).toggle(jQuery(this).attr('data-keyword').toLowerCase().indexOf(keyword) > -1);

            const profile_name = jQuery(this).attr('data-keyword').toString().toLowerCase();
            const profile_name_html = Utilities.isEmpty(keyword) ? profile_name : profile_name.replace(keyword, `<span class="keyword-string">${keyword}</span>`);

            jQuery(this).find('[data-name]').html(profile_name_html);
        });
    }


    const toggleMemberInput = (e, row) => {
        let memberIDsTemp = memberIDs;
        let id = row.account_id.toString();

        (!memberIDsTemp.includes(id)) ? memberIDsTemp.push(id) : (memberIDsTemp = [...Utilities.removeValueInArray(memberIDsTemp, id)]);
        memberIDsTemp = Utilities.arrayUnique(memberIDsTemp);

        setMemberIDs(memberIDsTemp);
        setToggleAll(false);
    }

    const toggleMemberAll = () => {

        if (toggleAll === true) {
            setMemberIDs([]);
        } else {
            setMemberIDs([...memberAcctIDs]);
        }

        const toggleAllTemp = toggleAll ? false : true;
        setToggleAll(toggleAllTemp);
    }


    const updateBroadcastDetail = async () => {
        const TOKEN_SESSION = SessionUtilities.getSessionToken();

        if (Utilities.isEmpty(formValues.title)) {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.broadcastNameIsRequired);
            return false;
        }

        if (parseInt(broadcastDetail.group_id || 0) === 0) {
            return false;
        }

        let teamMembersCounter = memberIDs.length;
        teamMembersCounter = parseInt(teamMembersCounter);

        if (teamMembersCounter > 0) {


            const passValues = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: internalDetail.account_id.toString(),
                company_id: internalDetail.team_company_id.toString(),
                group_id: broadcastDetail.group_id.toString(),
                convers_id: broadcastDetail.conversa_id.toString(), //conversID.toString(),
                title: formValues.title.toString(),
                members_list: memberIDs.join(',')
            }


            /* console.log('updateBroadcastDetail');
            console.log(ApiUrls.bizchatUpdateBroadcastDetails);
            console.log(passValues); */

            const button_name = 'btn-update-broadcast-detail';
            Utilities.elementDisabledStatus(button_name, true);

            await axios
                .post(ApiUrls.bizchatUpdateBroadcastDetails, passValues)
                .then(response => {
                    Utilities.elementDisabledStatus(button_name, false);

                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {
                            Utilities.messagePopup('success', response.data.successMessage);

                            let currentConversationDetailTemp = ChatUtilities.getAccountConversationDetail(conversID);

                            const title = `${teamMembersCounter} team member${teamMembersCounter > 1 ? 's' : ''} `;

                            currentConversationDetailTemp = { ...currentConversationDetailTemp, ...{ name: formValues.title.toString(), title: title } };
                            ChatUtilities.saveAccountsConversationDetail(currentConversationDetailTemp);

                            const profileInfoTemp = {
                                name: currentConversationDetailTemp.name,
                                category: currentConversationDetailTemp.title,
                                logo_url: currentConversationDetailTemp.logo,
                                img: currentConversationDetailTemp.logo
                            }


                            dispatch(passChatProfile(profileInfoTemp));

                        } else {
                            Utilities.messagePopup('error', response.data.errorMessage);
                        }
                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }

                })
                .catch((err) => {
                    Utilities.elementDisabledStatus(button_name, false);
                    Utilities.messagePopup('error', errorMsg);
                });

        } else {
            Utilities.messagePopup('error', TitlesLabels.alertMessages.noMemberSelected);
        }

    }

    const confimDelete = () => {

        confirmAlert({
            title: '',
            message: TitlesLabels.confirmMessages.wishToDeleteBroadcast,
            buttons: [
                { label: 'No', onClick: () => { } },
                { label: 'Yes', onClick: () => { deleteBroadcast() } }
            ]
        });
    }

    const deleteBroadcast = async () => {

        if (parseInt(broadcastDetail.group_id || 0) > 0) {

            const button_name = 'btn-delete-broadcast-detail';

            /*  const passValues = {
                 api_token:  TOKEN.toString(),
                 account_id: internalDetail.account_id.toString(),
                 broadcast_id: broadcastDetail.broadcast_id.toString(),
             } */

            const passValues = {
                api_token: TOKEN_SESSION.toString(), //TOKEN.toString(),
                account_id: internalDetail.account_id.toString(),
                company_id: internalDetail.team_company_id.toString(),
                group_id: broadcastDetail.group_id.toString(),
            }

            /* console.log('delete broadcast');
            console.log(ApiUrls.bizchatDeleteBroadcastDetails);
            console.log(passValues); */

            Utilities.elementDisabledStatus(button_name, true);

            await axios
                .post(ApiUrls.bizchatDeleteBroadcastDetails, passValues)
                .then(response => {

                    Utilities.elementDisabledStatus(button_name, false);

                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {
                            history.push(`/${UrlSlugs.internalChat}/${btoa(parseInt(internalDetail.account_id))}`);

                        } else {
                            Utilities.messagePopup('error', response.data.errorMessage);
                        }
                    } else {
                        Utilities.messagePopup('error', errorMsg);
                    }
                })
                .catch((err) => {
                    Utilities.elementDisabledStatus(button_name, false);
                    Utilities.messagePopup('error', errorMsg);
                });

        }
    }

    const getMembers = async (companyID, accountDetail, conversID) => {

        if (parseInt(companyID) > 0) {

            const passValues = {
                api_token: TOKEN_SESSION.toString(),
                account_id: accountDetail.account_id.toString(),
                company_id: companyID.toString(),
                convers_id: conversID.toString(),
            }

            /* console.log('getMembers');
            console.log(ApiUrls.bizchatGetBroadcastMembersDetails);
            console.log(passValues); */

            await axios
                .post(ApiUrls.bizchatGetBroadcastMembersDetails, passValues)
                .then(response => {

                    if (parseInt(response.status) === 201) {
                        if (parseInt(response.data.success) === 1) {

                            const broadcast_details = response.data.result.internal_broadcast_details.broadcast_details ?? {};
                            const members_list = response.data.result.internal_broadcast_details.members_list ?? [];

                            if (Object.keys(broadcast_details).length > 0) {

                                setFormValues({ title: broadcast_details.title.toString() });
                                setBroadcastDetail(broadcast_details);
                            }

                            if (members_list.length > 0) {

                                setMemberList(members_list);

                                // SETUP ACCOUNT IDS
                                let acctIDs = [];
                                let includedIDs = [];

                                Object.entries(members_list).map(([key, row]) => {
                                    acctIDs.push(row.account_id.toString());

                                    if (parseInt(row.status) === 1) {
                                        includedIDs.push(row.account_id.toString());
                                    }
                                });


                                setMemberAcctIDs(acctIDs);
                                setMemberIDs(includedIDs);

                            }

                        } else {
                            setShowEmpty(true);
                        }

                    } else {
                        setShowEmpty(true);
                    }

                })
                .catch((err) => {
                    setShowEmpty(true);
                });
        }

    }

    const previewProfileImage = (logoUrl) => {
        if (!Utilities.isEmpty(logoUrl)) {
            dispatch(passImageUrl(logoUrl));
        }
    }


    useEffect(() => {

        gobalConversID = parseInt(passConversID);
        setConversID(gobalConversID);

        if (Object.keys(RM.isNil(passInternalProfile) ? {} : passInternalProfile).length > 0) {
            globalInternalDetail = passInternalProfile;
            setInternalDetail(passInternalProfile);

            getMembers(passInternalProfile.team_company_id, passInternalProfile, passConversID);
        }

    }, [passInternalProfile]);


    return (

        <div className="broadcast-popup-content form-theme checkbox-radio-default checkbox-radio-check-icon form-theme">

            {
                Object.keys(memberList).length > 0 ?
                    <Fragment>

                        <div className="form-group">
                            <input type="text" className="form-control text-lowercase " placeholder=" " autoComplete="off" value={formValues.title} onChange={(e) => pushValue(e, 'title')} ></input>
                            <label className="form-label">Broadcast Name</label>
                        </div>

                        <div className="form-group border-0 bg-white mb-0 border-radius">
                            <input type="text" id="input_search_team" className="form-control text-lowercase  bg-gray-important input-search" placeholder=" " autoComplete="off" value={defaultSearchValue} onChange={(e) => pushValue(e, 'search-keyword')} ></input>
                        </div>

                        <div className="select-all-wrap mt-3">
                            <div className='font-gotham-bold fs-20 fs-16'>select all</div>
                            <div className="mr-n1"><input type="checkbox" checked={`${toggleAll ? 'checked' : ''}`} onChange={(e) => toggleMemberAll()} /></div>
                        </div>

                        <ul id="listing-profiles-member" className="list-members mt-2">
                            {
                                Object.entries(memberList).map(([key, row]) =>
                                    <li key={key} data-keyword={row.name.toLowerCase()} >
                                        <div>
                                            {/* <div className={`profile-image`} style={{ backgroundImage:  `url(${  Utilities.showHideProfileImage(row.logo) })`, backgroundColor : Utilities.bgcolorHideInitialBg(row.logo) }} onClick={ () => previewProfileImage(row.logo) }>{ Utilities.showHideInitial(row.logo, row.name) }</div> */}
                                            <CommonProfileLogo classAttr={`profile-image`} profileDetail={row} />
                                        </div>
                                        <div className='line-height-1-2'>
                                            <div className="profile-name notranslate" data-name={row.name}>{row.name}</div>
                                            <div className="profile-label">{row.title}</div>
                                        </div>
                                        <div>
                                            <input type="checkbox" checked={`${memberIDs.includes(row.account_id.toString()) ? 'checked' : ''}`} onChange={(e) => toggleMemberInput(e, row)} />
                                        </div>
                                    </li>
                                )
                            }
                        </ul>

                        <div className="team-selected-counter mt-3 text-center"> {memberIDs.length} Selected</div>

                        <div className="py-5 button-group-boolean">
                            <button type="button" id="btn-delete-broadcast-detail" className="btn-theme-black button-has-loader" onClick={() => confimDelete()}>delete</button>
                            <button type="button" id="btn-update-broadcast-detail" className="btn-theme-black button-has-loader" onClick={() => updateBroadcastDetail()}>update</button>
                        </div>

                    </Fragment>

                    :

                    (showEmpty === false ? <div className="content-loader animate"></div> : <div className="text-center text-lowercase no-record-label ">{Utilities.labelDetailAvailable}</div>)
            }


        </div>

    )
}

export default ChatBroadcast;
