import axios from 'axios';
import ApiUrls from './ApiUrls';
import QueryMasterSearchUtlities from '../helpers/QueryMasterSearchUtlities';
import SessionUtilities from './SessionUtilities';

const dailyGlanceCompanyId = process.env.REACT_APP_notification_followed_company_id ?? '0';
const appDefaultCityId = process.env.REACT_APP_city_id ?? '0';

export const sendJazenetBeonchatMessage = async (passval = {}) => {
    let responseDetail = {};

    if (Object.entries(passval).length > 0) {
        try {
            const responseURL = await axios.post(ApiUrls.bizchatCreateBizcomConversation, passval);
            if (parseInt(responseURL.status) === 200) {
                responseDetail = responseURL.data;
            }

        } catch (e) {
            console.log(e);
        }
    }
    return responseDetail;
}


export const followGlanceCompany = async (accountId = '0', cityId = '0') => {
    const dailyGlanceCompId = parseInt(dailyGlanceCompanyId ?? 0),
        defaultCityId = parseInt(appDefaultCityId ?? 0);

    accountId = parseInt(accountId ?? 0);
    cityId = parseInt(cityId ?? 0);


    if (dailyGlanceCompId > 0 && accountId > 0 && cityId > 0) {
        if (cityId === defaultCityId) {
            const passval = {
                account_id: accountId.toString(),
                company_id: dailyGlanceCompId.toString(),
                status: "1"
            };
            await QueryMasterSearchUtlities.followingStatusUpdate(passval);
            SessionUtilities.setSignupGlanceStatus('1');

            /* const followingStatusUpdateDb = QueryMasterSearchUtlities.followingStatusUpdate(passval);
            console.log(followingStatusUpdateDb);
            if (parseInt(followingStatusUpdateDb.status ?? 0) === 1) {
                SessionUtilities.setSignupGlanceStatus('1');
            } */
        }

    }
}
