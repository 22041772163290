import React, { memo, useState } from 'react';
import CommonProfileLogo from './CommonProfileLogo';

const CompanyListingSelection = (props) => {

    const listProvided = props.list ?? [];

    const [selectedProfile, setSelectedProfile] = useState({});

    const handleSelectedProfile = (profile = {}) => {

        if (Object.entries(profile).length > 0) {
            if (parseInt(selectedProfile.account_id) === parseInt(profile.account_id)) {
                setSelectedProfile({});
            } else {
                setSelectedProfile(profile);
            }

        }
    }

    const handleChoose = () => {
        props.handleSelectedReferral(selectedProfile);
    }


    return (

        Object.keys(listProvided).length > 0 &&
        <>
            <div className='px-5 scroll-none' style={{ maxHeight: 250, overflowY: `auto` }} >
                <ul className='ul-profile-selection-list'>
                    {
                        Object.entries(listProvided).map(([key, profile]) =>
                            <li className={`${parseInt(selectedProfile.account_id) === parseInt(profile.account_id) ? 'selected' : ''}`} onClick={() => handleSelectedProfile(profile)}>
                                <CommonProfileLogo classAttr={`profile-image logo-size-40 logo-size-md-60`} profileDetail={profile} />
                                <div>
                                    <div className='font-gotham-bold fs-16 fs-md-16 ellipsis-1'>{profile.name ?? ''}</div>
                                    <div className='font-gotham-book fs-13 fs-md-15 color-theme-grey ellipsis-1'>{profile.location ?? ''}</div>
                                </div>
                            </li>
                        )
                    }


                </ul>
            </div>

            {
                Object.keys(selectedProfile).length > 0 &&
                <div className="mt-5 pt-4">
                    <button type="button" className="btn-theme-black col-md-4 mx-auto" onClick={() => handleChoose()}>choose</button>
                </div>
            }

        </>

    )

}

export default memo(CompanyListingSelection);


