import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { showHeader, showFooter, pageTitle, showPopupTermsConditions } from '../actions';
import UrlSlugs from '../helpers/UrlSlugs';
import TitlesLabels from '../helpers/TitlesLabels';
import SignupHeader from '../layout/SignupHeader';
import Utilities from '../helpers/Utilities';

const AccountType = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const redirectType = (type) => {
        history.push(`/${type}`);
    }

    useEffect(() => {
        dispatch(pageTitle(TitlesLabels.siteTitles.selectType));
        dispatch(showHeader(0));
        dispatch(showFooter(0));

        Utilities.rootFilterBlur(false);

        // CLOSE TERMS POPUP WHEN IN CREATING COMPANY
        dispatch(showPopupTermsConditions(false));

    }, []);

    return (
        <div className='signup-page with-footer centerialized   text-lowercase fs-18'>

            <div className='signup-wrap text-center'>
                <SignupHeader title="account type" />

                <div className='labels mt-5'>choose one</div>

                <div className='d-flex justify-content-center mt-5'>
                    <ul className='text-left fs-20  checkbox-radio-default'>
                        <li className='d-flex align-items-center mb-3 cursor-pointer' onClick={() => redirectType(UrlSlugs.signup_socialConnect)}><input type="checkbox" className='mr-3' />personal account</li>
                        <li className='d-flex align-items-center  cursor-pointer' onClick={() => redirectType(UrlSlugs.signup_companyAccount)}><input type="checkbox" className='mr-3' />company account</li>
                    </ul>
                </div>

            </div>

            <div className='pb-5'>
                <Link to="/">{TitlesLabels.general.exitWithoutSaving}</Link>
            </div>

        </div>

    )
}

export default AccountType;