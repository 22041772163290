import React, { useEffect } from 'react';
import ContactForm from '../../components/ContactForm';
import { social_links } from '../../helpers/Constants';
import jQuery from 'jquery';
import UrlSlugs from '../../helpers/UrlSlugs';

const Footer = () => {

    const contactEmail = `contact@beonchat.com`;
    const contactNumber = `+27 69 328 2028`;

    const contactMobileHtml =  <a href={`tel:${contactEmail}`} className="text-white">{contactNumber}</a>
    const copyrightHtml =  <div>copyright &copy; {  new Date().getFullYear() }</div>
    const contactEmailHtml = <a href={`mailto:${contactEmail}`} className="text-white">{contactEmail}</a>
    const socialLinksHtml = <div className="social-app-links">
                                <a href={ social_links.youtube } target={`${ social_links.youtube!=='#' ? '_blank' : '_self' }`}><img src={`${process.env.REACT_APP_img_folder}/icons/social/white-new/circle-outline-youtube.svg`} alt="youtube"  width="35"/></a>
                                <a href={ social_links.facebook } target={`${ social_links.facebook!=='#' ? '_blank' : '_self' }`} className="ml-2"><img src={`${process.env.REACT_APP_img_folder}/icons/social/white-new/circle-outline-facebook.svg`} alt="facebook"  width="35"/></a>
                                {/* <a href={ social_links.twitter } target={`${ social_links.twitter!=='#' ? '_blank' : '_self' }`} className="ml-2"><img src={`${process.env.REACT_APP_img_folder}/icons/social/white-new/circle-outline-twitter.svg`} alt="twitter"  width="35"/></a> */}
                                <a href={ social_links.instagram } target={`${ social_links.instagram!=='#' ? '_blank' : '_self' }`} className="ml-2"><img src={`${process.env.REACT_APP_img_folder}/icons/social/white-new/circle-outline-instagram.svg`} alt="instagram"  width="35"/></a>
                             </div>


    useEffect(() => {
        
        setTimeout( () => {
            jQuery('body').addClass('mb-0');
        }, 1000);
       
    }, []);

    return (
        <section id="section-contact" className="section-content login-page py-5 bgcolor-dark text-white position-relative">  
            <div id="contact" className='section-header-hidden'></div>
            <footer className="footer  position-relative  h-100 bgcolor-dark   fs-18">
                <div className="login-container- container- px-4 px-sm-5 position-relative  h-100  d-flex flex-column justify-content-between ">
                    
                    <div className="contact-wrapper mx-auto px-3 px-sm-0 align-items-center pt-sm-5 mt-sm-4">
                        <div className="py-2 px-4 p-sm-5 pb-lg-5 bg-white btn-border-radius">
                            <div className=" pt-4 pt-sm-0 pb-3  pb-lg-3">
                                <h2 className="heading-title font-montserrat-bold mb-sm-4 fs-35 text-black text-center text-lg-left">contact</h2>
                                <div className="pt-3 mb-sm-0 mx-auto"><ContactForm messageInputRows="3" /></div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="font-gotham-book d-block d-lg-none  text-center pt-4 pt-sm-5 mt-sm-5">

                        <img src={`${process.env.REACT_APP_img_folder}/logo/logo-circle.svg`} alt="logo" width="100" className="d-none d-sm-inline"/>
                        <div className="py-4 my-2">{ socialLinksHtml }</div>
                        <div>{ contactEmailHtml }</div>
                        <div>{ contactMobileHtml }</div>
                        <div className="mt-4 pt-2 fs-14">{ copyrightHtml }</div>
                    </div>

                    <div className="row font-gotham-book fs-16 d-none d-lg-flex ">
                        <div className="col-lg-3 d-flex align-items-lg-end fs-12">
                            <div>
                                { copyrightHtml }
                                <div><a href={`${ UrlSlugs.page_termsConditions }`} target='_blank' className='text-white'>terms of use</a></div>
                                <div><a href={`${ UrlSlugs.page_privacyPolicy }`} target='_blank' className='text-white'>privacy policy</a></div>
                                <div><a href={`${ UrlSlugs.page_disclaimer }`} target='_blank' className='text-white'>disclaimer</a></div>
                                <div><a href={`${ UrlSlugs.page_enduserLicense }`} target='_blank' className='text-white'>end user license agreement</a></div>

                            </div>
                        </div>
                        <div className="col-lg-6 text-center footer-social-icons d-flex align-items-lg-end justify-content-lg-center">
                            <div className="pt-5">
                                { socialLinksHtml }

                                <div className="text-center d-flex justify-content-center mt-5">
                                    <div>{ contactEmailHtml }</div>
                                    <div className="mx-4">|</div>
                                    <div>{ contactMobileHtml }</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3  d-flex align-items-lg-end justify-content-lg-end">
                            <div className="text-center">
                                <img src={`${process.env.REACT_APP_img_folder}/logo/logo-circle.svg`} alt="logo" width="100"/>
                            </div>
                        </div>
                        
                    </div>

                    
                </div>
            </footer>
        </section>
    )
}

export default Footer;