import ChatUtilities from "./ChatUtilities";
import PagesAuthentication from "./PagesAuthentication";
import Utilities from "./Utilities";

export const checkMessageIsEligible = (membersList = '', messageType = '0', messageContent = '', conversAccessFlag = '') => {
    let boolResponse = false,
        memberListArr = !Utilities.isEmpty(membersList) ? membersList.split(',') : [];


    const userLoginDetail = PagesAuthentication.getUserLoggedinDetail();
    messageType = parseInt(messageType);
    conversAccessFlag = conversAccessFlag.toString();

    if (Object.keys(userLoginDetail).length > 0) {
        const userId = (userLoginDetail.account_id ?? '0').toString();

        if ([...Array(10).keys()].includes(messageType)) {

            if (messageType === 10) {
                boolResponse = true;

            } else {

                if (!Utilities.isEmpty(membersList)) {

                    if (membersList === "-1") {
                        boolResponse = true;
                    } else {
                        boolResponse = memberListArr.includes(userId) ? true : false;
                    }

                } else {
                    boolResponse = false;
                }
            }

        } else if (messageType === 19) {

            if (!Utilities.isEmpty(membersList)) {
                if (memberListArr.includes(userId)) {

                    if (["you joined this group with passcode", "your request to join the group was approved", "you joined this group"].includes(messageContent)) {
                        boolResponse = true;

                    } else {

                    } boolResponse = false;

                } else {
                    boolResponse = false;
                }

            } else {
                boolResponse = false;
            }

        } else {

            if (messageType === 15) {
                boolResponse = true;

            } else {

                switch (conversAccessFlag) {
                    case "1":
                        if ([17, 16, 21].includes(messageType)) {
                            boolResponse = true;
                        }
                        break;
                    case "2":
                        if ([17].includes(messageType)) {
                            boolResponse = true;
                        }
                        break;
                    case "3":
                        if ([18].includes(messageType)) {
                            boolResponse = true;
                        }
                        break;

                }
            }


        }

    }

    return boolResponse;
}



export const loadConversation = async (dbCollection = '', lastDocID = '', conversationLimit = ChatUtilities.conversationLimit) => {

    let pageQuery = '';
    lastDocID = parseInt(lastDocID);

    if (lastDocID > 0) {
        pageQuery = dbCollection.where('date', '<', lastDocID).orderBy("date", "desc").startAfter(lastDocID).limit(conversationLimit);
    } else {
        pageQuery = dbCollection.orderBy("date", "desc").limit(conversationLimit);
    }

    return await new Promise(passDetail => {
        pageQuery.get()
            .then(snapshot => {
                const messages_list_reverse = snapshot.docChanges().reverse();

                let conversationTemp = [];
                messages_list_reverse.forEach(query => {
                    let messages_data = query.doc.data();

                    messages_data = { doc_id: query.doc.id.toString(), ...messages_data };
                    conversationTemp.push(messages_data);

                });

                passDetail(conversationTemp);

            });
    });

}